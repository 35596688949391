import React from 'react'

function Support() {
  return (






    <div style={{backgroundColor:'blue'}}>Support</div>
  )
}

export default Support