// // ProtectedRoute.js
// import React from 'react';
// import { Route, Navigate } from 'react-router-dom';

// const ProtectedRoute = ({ component: Component,currentSubdomain, ...rest}) => {
//   const token = localStorage.getItem('auth_token'); // Or get the auth token from a more secure place
//   return token ? <Component {...rest} /> : <Navigate to="/login" />;
// };

// export default ProtectedRoute;


//ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
//import jwt_decode from 'jwt-decode';
import { jwtDecode } from 'jwt-decode';

const ProtectedRoute = ({ component: Component, currentSubdomain, ...rest}) => {
  const token = localStorage.getItem('auth_token'); // Or get the auth token from a more secure place



  if (!token) {
    if (currentSubdomain === 'support') {
      window.location.href = 'https://bakesoft.se/login';
      return null;
    } else {
      return <Navigate to="/login" />;
    }
  } // here we want to check if token expired and if so we want to redirect to login page
  if (token) {

    //const decodedToken = jwt_decode(token);
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    if (decodedToken.exp < currentTime) {
     localStorage.removeItem('auth_token');
     // return <Navigate to="/login" />;

   window.location.href = 'https://www.bakesoft.se/loggedout';
 // window.location.href = 'http://192.168.1.146:3000/loggedout';
    
      return null;
    }
  }








  return <Component {...rest} />;
};

export default ProtectedRoute;





