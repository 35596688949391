// import { useState, useEffect } from 'react';

// const useDeviceDetection = () => {
//   const [isIpad, setIsIpad] = useState(false);
//   const [isAndroid, setIsAndroid] = useState(false);
//   const[isWindows,setIsWindows]=useState(false)

//   useEffect(() => {
//     const userAgent = window.navigator.userAgent;
//     const isIpad = /iPad|Macintosh|Mac OS X/.test(userAgent) && !window.MSStream;
//     const isAndroid = /Android|Linux/i.test(userAgent);
//     const isWindowsLaptop = /Windows| NT/i.test(userAgent);
//     // Delay the state update to the next render cycle to ensure it doesn't conflict with initial rendering
  
//       setIsIpad(isIpad);
//       setIsAndroid(isAndroid);
//       setIsWindows(isWindowsLaptop)

//   }, []);

//   return {
//     isIpad,
//     isAndroid,
//     isWindows
//   };
// };

// export default useDeviceDetection;

import { useState, useEffect } from 'react';

const useDeviceDetection = () => {
  const [isIpad, setIsIpad] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  const [isWindows, setIsWindows] = useState(false);

  useEffect(() => {
    // Define the user agent and platform
    const userAgent = navigator.userAgent;
    const platform = navigator.platform;

    // Use touch points to determine if the device is an iPad
    const isIpad = (platform === 'MacIntel' && navigator.maxTouchPoints > 1) || /iPad/.test(userAgent);

    const isAndroid = /Android/.test(userAgent);
    const isWindows = /Windows NT/.test(userAgent);

    // Update the state based on the detection
    setIsIpad(isIpad);
    setIsAndroid(isAndroid);
    setIsWindows(isWindows);
  }, []);

  return {
    isIpad,
    isAndroid,
    isWindows,
  };
};

export default useDeviceDetection;

