import { useState } from 'react';

// Create the custom hook
function useNutritionSummary(RecepyData, getnutritionData) {
  const [nutrientSummary, setNutrientSummary] = useState({
    totalKcals: 0,
    totalCarbohydrates: 0,
    totalProtein: 0,
    totalFat: 0,
  });

  // Function to calculate the total nutrients
  function calculateTotalNutrients() {
    // Create copies of the arrays
    let myNewObjArr = [...RecepyData];
    let myNewObjArr2 = [...getnutritionData];

    // Initialize total nutrient variables
    let totalKcals = 0;
    let totalCarbohydrates = 0;
    let totalProtein = 0;
    let totalFat = 0;

    // Iterate over the RecepyData array
    myNewObjArr.forEach((data) => {
      // Find the amount of kcals for the ingredient
      let amountOfKcals = myNewObjArr2.find(
        (data2) =>
          data2.ingredid === data.ingredid && data2.nutrient === 'Energy'
      );

      if (amountOfKcals) {
        amountOfKcals = amountOfKcals.quantity / 100 * data.mangd;
        totalKcals += amountOfKcals;
      }

      // Find the amount of carbohydrates for the ingredient
      let amountOfCarbohydrates = myNewObjArr2.find(
        (data2) =>
          data2.ingredid === data.ingredid &&
          data2.nutrient === 'Carbohydrate, by difference'
      );

      if (amountOfCarbohydrates) {
        amountOfCarbohydrates = amountOfCarbohydrates.quantity / 100 * data.mangd;
        totalCarbohydrates += amountOfCarbohydrates;
      }

      // Find the amount of protein for the ingredient
      let amountOfProtein = myNewObjArr2.find(
        (data2) => data2.ingredid === data.ingredid && data2.nutrient === 'Protein'
      );

      if (amountOfProtein) {
        amountOfProtein = amountOfProtein.quantity / 100 * data.mangd;
        totalProtein += amountOfProtein;
      }

      // Find the amount of fat for the ingredient
      let amountOfFat = myNewObjArr2.find(
        (data2) =>
          data2.ingredid === data.ingredid && data2.nutrient === 'Total lipid (fat)'
      );

      if (amountOfFat) {
        amountOfFat = amountOfFat.quantity / 100 * data.mangd;
        totalFat += amountOfFat;
      }
    });

    // Set the nutrient summary
    setNutrientSummary({
      totalKcals: totalKcals.toFixed(2),
      totalCarbohydrates: totalCarbohydrates.toFixed(2),
      totalProtein: totalProtein.toFixed(2),
      totalFat: totalFat.toFixed(2),
    });
  }

  // Return the nutrientSummary object and the calculateTotalNutrients function
  return { nutrientSummary, calculateTotalNutrients };
}

export default useNutritionSummary;
