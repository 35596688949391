
import React,{useEffect,useState} from 'react'
import { useSearchParams } from 'react-router-dom';
import {useGetData,usePostData,usePutData,useDeleteData, usePostData1} from '../hooks/useGetData';
import Alert from '../functions/appAlert';
import { useNavigate } from 'react-router-dom';
import AppError from '../AppLanguage/AppError';
import useReportErr from '../hooks/useReportErr';
import './ResetPassword1.css'
import languages from '../AppLanguage/AppLanguage';

function Resetpassword() {

    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [validToken, setValidToken] = useState(true);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertCode, setAlertCode] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [getCountry,setCountry]=useState('en')
    const [getNewpass,setNewpass]=useState('')
    const [getConfirmpass,setConfirmpass]=useState('')
    const [getPass,setPass]=useState('')
    const [getEmail,setEmail]=useState('')
   


    useEffect(()=>{
        let country = localStorage.getItem('country')||'en' // this is the country code
        setCountry(country)
      },[])
      
                // if language is japanese we use the font Zen Old Mincho
                let newFontFamily=''
                if(getCountry==='ja'){
                newFontFamily= "'Zen Old Mincho', Arial, Helvetica, sans-serif"
                }else{
                newFontFamily='Arial, Helvetica, sans-serif'
                }
                document.documentElement.style.setProperty('--font-family', newFontFamily);

    // here we wil connect to the backend to check if this token is still valid

    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

   
    const navigate = useNavigate();

    
// error messages
const selectedError=AppError[localStorage.getItem('country')||'en']

// language settings
const selectedCountry = languages[getCountry];


 const{
headerText,
resetPassInputPlaceHolder,
resetPassInputConfirmPlaceHolder,
resetPassButtonText,
} = selectedCountry.resetPass || {};



const{
linkNotValid,
passwordAtleast8,
passwordNoMatch,
passwordRestored,
passwordRestoreFailure,

}=selectedCountry.confirmationMessages||{};

useEffect(() => {
 
   async function CheckValidityOfToken(){
        setSpinnerLoading(true)
       const apiKey=process.env.REACT_APP_TOCKENCHECK
        try{
            const respons = await usePostData1('checkPasswordResetToken', { token },
            apiKey);
        
            setEmail(respons.data)
            setSpinnerLoading(false)
            setValidToken(true)
                
        }catch(error){
            setSpinnerLoading(false)
            setValidToken(false)
            setAlertCode(20013)
            setAlertMessage(linkNotValid)
            setShowAlert(true)
           // navigate('/login')

        
            }
    }
    CheckValidityOfToken();

}, [token]);







const handleCloseAlert = () => {
    navigate('/login');
    return null;
    setShowAlert(false);
}

  
const RunReportErrHandler = async (errCode) => {
    setSpinnerLoading(true);
    await useReportErr('ErrReport',{errCode});
    setSpinnerLoading(false);
  };

  
  useEffect(() => {
    if (alertCode > 0) {
      RunReportErrHandler(alertCode);
    }
  }, [alertCode]);


  async function ResetHandler(){
    const password = getNewpass;
    const confirmPassword = getConfirmpass;

    if(password !== confirmPassword){
        setAlertCode(20014)
        setAlertMessage(passwordNoMatch)
        setShowAlert(true)
        return;
    }

    if(password.length < 8){
        setAlertCode(20015)
        setAlertMessage(passwordAtleast8)
        setShowAlert(true)
        return;
    }
Reset();

}

async function Reset(){
    setSpinnerLoading(true)
    const apiKey=process.env.REACT_APP_RESETPASS
    try{
        const result = await usePostData1('resetPassword', { getNewpass,getConfirmpass,token,getEmail},
            apiKey
        );
        if(result.status === 200){
            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage(passwordRestored)
            setShowAlert(true)
         
        }
    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(20017)
        setAlertMessage(passwordRestoreFailure)
        setShowAlert(true)
    }
}



  return (

    <>
{validToken===true?
<div className="resettotWrapper">
    <div className="resetPassTotalArea">
        <div className="resetPassAreaA">
            <div className="resetPassHeaderWrapper">
                <h1 className="resetPassHeader">{headerText}</h1>
            </div>
            <div className="resetPassFormWrapper">
                <fieldset className="resetPassFieldset">
                    <input type="password" className="resetPassInput" placeholder={resetPassInputPlaceHolder}
                    onChange={(e)=>setNewpass(e.target.value)}
                    value={getNewpass}
                     />
                    <input type="password" className="resetPassConfirm" placeholder={resetPassInputConfirmPlaceHolder}
                    onChange={(e)=>setConfirmpass(e.target.value)}
                    value={getConfirmpass}
                    />
                    <button className="resetPassButton"
                    onClick={() => ResetHandler()}
                    >{resetPassButtonText}</button>
                </fieldset>
            </div>
        </div>
    </div></div>:''}
    {showAlert===true?<Alert message={alertMessage} code={alertCode} onClose={handleCloseAlert}/>:''}
    </>

  )
}

export default Resetpassword