import React,{useState,useEffect} from 'react'
import createProductionNew from '../hooks_styles/createProductionNew';
import Alert from '../functions/appAlert'
import {useGetData,usePutData,usePostData,useDeleteData} from '../hooks/useGetData'
import useDeviceDetection from '../hooks/useDeviceDetection'
import useReportErr from '../hooks/useReportErr'
import { RotatingLines } from  'react-loader-spinner'
import Componentname from '../hooks/Componentname';
import {RiDeleteBack2Line} from 'react-icons/ri'
import './productionNew1.css'
import {AiOutlinePlusCircle,AiOutlineMinusCircle} from 'react-icons/ai'
import {HiOutlineSaveAs} from 'react-icons/hi'
import {useNavigate} from 'react-router-dom';
import useFetchImageUrl from '../hooks/useFetchImageUrl';
import usePageSettings from '../hooks/usePageSettings';
import languages from '../AppLanguage/AppLanguage';
import AppError from '../AppLanguage/AppError';
import displayGadgetToSmall from '../functions/displayGadgetToSmall'

import { FaBullseye } from 'react-icons/fa';
import { IoCreateOutline } from 'react-icons/io5';

export default function CreateProductionNew() {


const[productionNew,setproductionNew]=useState([{}])
const[getallproductcategoriesObj,setallproductcategoriesObj]=useState([])
const[getproductsbyCategory,setproductsbyCategory]=useState([])
const[getproductionObj,setproductionObj]=useState([])
 const[gethistproddataObj,sethistproddataObj]=useState([])
// const[gethistorydataObj,sethistorydataObj]=useState([])
 const[getSavebool,setSavebool]=useState(false)
 const[getsaveContainer,setsaveContainer]=useState(false)
 const[getproductionName,setproductionName]=useState('')
 const[getchosenproductionId,setchosenproductionId]=useState(0)
 const [forceUpdateFlag, setForceUpdateFlag]= useState(false);
 const[getSpinnerLoading,setSpinnerLoading]=useState(false)
 const [getShowAlert,setShowAlert] = useState(false);
 const [getAlertMessage,setAlertMessage] = useState({});
 const[getAlertCode,setAlertCode]=useState(0)
 const[expandedRow,setExpandedRow]=useState(null)
 const[getChosenProductId,setChosenProductId]=useState(0)
 const[getShowSaveButton,setShowSaveButton]=useState(false)
const[getButtonSaveActive,setButtonSaveActive]=useState(false)
const[getSaveProductionMenu,setSaveProductionMenu]=useState(false)
const[getCreatedNewProduction,setCreatedNewProduction]=useState(false)
const[getproductionStatistics,setproductionStatistics]=useState([{}])
const[getClearedProduction,setClearedProduction]=useState(false) // status for the clear production button
const[getBackGroundShade,setBackGroundShade]=useState(0.75)
const[renderKey,setRenderKey]=useState(0)
const[getRenderKey,setRenderKey1]=useState(0)
const[getMenuShade,setMenuShade]=useState(0.75)
const[getMenuShade2,setMenuShade2]=useState(0.55)
const [getColorVal,setColorVal]=useState('')
const [getImageVal,setImageVal]=useState('')
const [getImageUrl,setImageUrl]=useState('')
const pageid=13
const [logtime]=useState(5000)

const [windowWidth,setWindowWith]=useState(0) // this is the window width
const [windowHeight,setWindowHeight]=useState(0)  // this is the window height
const [getMobileIsUsed,setMobileIsUsed]=useState(false) // this is the window height
const[getCountry,setCountry]=useState('se') // this is the country
const[getFuriganaName,setFuriganaName]=useState('') // this is the furigana name
const[getShowFurigana,setShowFurigana]=useState(false) // this is the furigana boolean
const[getNewProductIsCreated,setNewProductIsCreated]=useState(false) // 
const[getSparaButton,setSparaButton]=useState(false) //
const[getOnGoingIsActive,setOnGoingIsActive]=useState(false) //
const[getHistoryIsOnGoing,setHistoryIsOnGoing]=useState(false) //


useEffect(()=>{
  let country = localStorage.getItem('country')||'en' // this is the country code
  setCountry(country)
},[])

          // if language is japanese we use the font Zen Old Mincho
          let newFontFamily=''
          if(getCountry==='ja'){
          newFontFamily= "'Zen Old Mincho, Arial, Helvetica, sans-serif'"
          }else{
          newFontFamily='Arial, Helvetica, sans-serif'
          }
          document.documentElement.style.setProperty('--font-family', newFontFamily);
      

    const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal } = usePageSettings(pageid);
    const { imageUrl, alertMessage, alertCode, showAlert } = useFetchImageUrl(getImageVal); // runs at mount, gives ut the image url and fetch image from server
    let navigate = useNavigate();

    useEffect(() => {
      function setSize() {
        setWindowWith(window.innerWidth);
        setWindowHeight(window.innerHeight);
      
        if(window.innerWidth < 600){
    
          setMobileIsUsed(true)
        } 
    
      }
    
    
      // Call setSize initially to set the correct state
      setSize();
    
      // Add the event listener
      window.addEventListener('resize', setSize);
    
      // Return a cleanup function to remove the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', setSize);
      };
    }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]); // Dependencies
    
    function rgbToRgba(rgb, alpha) {
      return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
    }
    
    //--bakcground shade settings
    let rgbaVal
    let rgbaVal2
    let val
    
    if(getColorVal && getColorVal.length > 0){
    let rgb = getColorVal;
    let alpha = getMenuShade; // replace with your alpha value
    let alpha2 = getMenuShade2; // replace with your alpha value
    rgbaVal = rgbToRgba(rgb, alpha);
    rgbaVal2 = rgbToRgba(rgb, alpha2);
    val=`rgba(255, 255, 255, ${getBackGroundShade})`
    
    }else{
      rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
      rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
      val=`rgba(255, 255, 255, ${getBackGroundShade})`
    }
    //---

    useEffect(() => {
      let root = document.documentElement;
      root.style.setProperty('--rgbaVal', rgbaVal);
      root.style.setProperty('--rgbaVal2', rgbaVal2);
      root.style.setProperty('--val',val);
      root.style.setProperty('--windowHeight',windowHeight+'px');
    
      
    }, [getBackGroundShade,getMenuShade,getMenuShade2]);

      // language settings
      const selectedCountry = languages[getCountry];
     
       let title=''
      if(getMobileIsUsed===false){
        title=selectedCountry.workwithProduction?.title
      }else{
        title=selectedCountry.workwithProduction?.titleMobile
      }

      const {
        titleMobile,
        leftFirstSelectLegend,
        leftFirstSelectFirstOption,
        leftSecondSelectFirstOption,
        leftThirdSelectLegend,
        leftThirdSelectFirstOption,
        leftFourthSelectLegend,
        leftFourthSelectFirstOption,
        middleHeaderId,
        middleHeaderProdid,
        middleHeaderName,
        middleHeaderAmount,
        middleHeaderPrice,
        middleHeaderTotal,
        middleHeaderStatus,
        titleButtonOpenSaveMenu,
        titleIncreaseAmount,
        titleDeacreaseAmount,
        saveProductionBoxHeaderLegend,
        saveProductionInputPlaceHolder,
        createProductionButtonText,
        saveProductionButtonText,
        clearProductionButtonText,
        rightHeaderLegend,
        rightHeaderPid,
        rightHeaderCategoryId,
        rightHeaderCategory,
        rightHeaderSum,
        rightHeaderAmount,
        titleRightHeaderPid,
        titleRightHeaderCategoryId,
        titleAdjustMenu,
        titleAdjustMenu1,
        middleHeaderMobileProdid,
        clearProductInproductionButtonText,
        clearProductInProductionButtonText1,
        titleCreateNewProduct,
      } = selectedCountry.workwithProduction || {};

// error messages
const selectedError=AppError[localStorage.getItem('country')||'en']

const{ 
  productionSaved,
  productionCreated,
  productionCleared,
  productDeleted,
  productionVoid,
}=selectedCountry.confirmationMessages||{}


 

      useEffect(() => {
        GetSettings();
      }, [GetSettings]);

      // we set the image url here
      useEffect(() => {
        setImageUrl(imageUrl);
        if (alertCode > 0) {
          setAlertMessage(alertMessage);
          setAlertCode(alertCode);
          setShowAlert(showAlert);
        }
      }, [imageUrl]);
    
      // here we set the color values and image values
      useEffect(() => {
        setBackGroundShade(backGroundShade);
        setMenuShade(menuShade);
        setMenuShade2(menuShade2);
        setColorVal(colorVal);
        setImageVal(imageVal);
      }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);





      useEffect(()=>{

        async function GetData(){
          setSpinnerLoading(true)
       
          try {
            const response= await useGetData(`getProductCategories`)
            setallproductcategoriesObj(response.data[0])
            sethistproddataObj(response.data[1])  
             setSpinnerLoading(false)
          }catch(err){
            setAlertCode(10001)
            setAlertMessage(selectedError[10001])
            setSpinnerLoading(false)
            setShowAlert(true)
     
          
          }
        
        
        }
        GetData()
        },[renderKey])

        
  useEffect(()=>{
  
    async function GetTotalProductionData(){
      setSpinnerLoading(true)
    
      try{
        let response= await useGetData(`getTotalProductionData`)
        setproductionStatistics(response.data[1])
        setSpinnerLoading(false)
      } catch(error){
        setAlertCode(23002)
        setAlertMessage(selectedError[23002])
        setSpinnerLoading(false)
        setShowAlert(true)
 
      
      }
     
    }
  
    GetTotalProductionData()
  
  },[renderKey])

            
    const RunReportErrHandler = async (errCode) => {
        setSpinnerLoading(true);
        await useReportErr('ErrReport',{errCode});
        setSpinnerLoading(false);
      };
      
      useEffect(() => {
        if (getAlertCode > 0) {
          RunReportErrHandler(getAlertCode);
        }
      }, [getAlertCode]);
      
      const handleCloseAlert = () => {
        setShowAlert(false);
      };



      async function GetProducts(kategoriid){
        setSpinnerLoading(true)
        try {
          let response=await useGetData(`getAllProductByCategory/${kategoriid}`)
          setproductsbyCategory(response.data[0])
          setSpinnerLoading(false)
          
          }catch(err){
            setAlertCode(22003)
            setAlertMessage(selectedError[22003])
            setSpinnerLoading(false)
            setShowAlert(true)
     
        
        }
  
      }

      function createProduction(produktid,antal){
        setShowFurigana(false) // hiding furigana input
        setFuriganaName('')
        setproductionName('')
        setExpandedRow(null)
        setButtonSaveActive(true)
        setSparaButton(false) // vi kan inte spara en ny produktion
        setSaveProductionMenu(false)  // close the save production menu
     




        // here we identify the product we are working working with
       let productbenaemn=[]
       productbenaemn=getproductsbyCategory.find((data)=>data.produktid==produktid)
       let pbenaemn=productbenaemn?.benaemn
       let ppris=productbenaemn?.pris
      //  let produktionId=productbenaemn?.pproduktionId
       
       let mynewObjArr=[...getproductionObj]
       // we have to take out the id here from the getproductionObj and if we have no id we set it to 1
        let produktionId=mynewObjArr[0]?.produktionid||1

        // here we will show the create new production button if the produktionId is 1
        if(produktionId===1){
          setCreatedNewProduction(true)
          setNewProductIsCreated(true)
        }


       //here i want to check if the product is already in the productionObj array
        let checkIndex=mynewObjArr.findIndex((data)=>data.produktid==produktid)
        if(checkIndex!==-1){
          return
        }else{
          let mynewObj={
            produktionid:produktionId,
            produktid:produktid,
            benaemn:pbenaemn,
            antal:0,
            pris:ppris,
            totalt:0,
            Istatus:0
          }
          mynewObjArr.push(mynewObj)
          setproductionObj(mynewObjArr)
  
          }
       setSavebool(false)
    }



//     {getCreatedNewProduction===true&&getNewProductIsCreated
    
async function GetongoingdataHandler(produktionId){
  setShowFurigana(false) // hiding furigana input
  setFuriganaName('')
  setproductionName('')
  setSpinnerLoading(true)
  setchosenproductionId(produktionId)
  setExpandedRow(null)
  setButtonSaveActive(true) // we set the save button to active
  setOnGoingIsActive(true) // we set ongoing to true
  setHistoryIsOnGoing(false) // and history to false
  setSaveProductionMenu(false)  // close the save production menu
  

  try{
    let response=await useGetData(`getHistoryData/${produktionId}`)
      //here we map throus the res object and calculate totalt
        response.data[0].map((data)=>{return data.totalt=data.antal*data.pris})
  //res.data[0].map((data:any)=>{return data.Istatus=0})
  setproductionObj(response.data[0])
  setSpinnerLoading(false)

  } catch(error){
    setAlertCode(23004)
    setAlertMessage(selectedError[23004])
    setSpinnerLoading(false)
    setShowAlert(true)

  }
}


async function GethistorydataHandler(produktionId){
  setShowFurigana(false) // hiding furigana input
  setFuriganaName('')
  setproductionName('')
  setSpinnerLoading(true)
  setchosenproductionId(produktionId)
  setExpandedRow(null)
  setButtonSaveActive(true) // we set the save button to active
  setSaveProductionMenu(false)  // close the save production menu
  setHistoryIsOnGoing(true)
  setOnGoingIsActive(false) // we set ongoing to false

  try{

    let response= await useGetData(`getHistoryData/${produktionId}`)
        //here we map throu the res object and calculate totalt
      response.data[0].map((data)=>{return data.totalt=data.antal*data.pris})
      // response.data[0].map((data)=>{return data.Istatus=0})
      setproductionObj(response.data[0])
      setsaveContainer(false)
      setSpinnerLoading(false)

  } catch(error){
    setAlertCode(23005)
    setAlertMessage(selectedError[23005])
    setSpinnerLoading(false)
    setShowAlert(true)
  
  }
}



const ChangeAmountHandler=(produktid,mtype)=>{
  setButtonSaveActive(true)
  setSaveProductionMenu(false) // close the save production menu
 

  let mynewObjArr=[...getproductionObj]
  let updateIndex=mynewObjArr.findIndex((data)=>data.produktid===produktid)
  let newtotal=mynewObjArr[updateIndex].antal

    if(mtype===1){
    mynewObjArr[updateIndex].antal=newtotal+1
    }else if(mtype===2){
    mynewObjArr[updateIndex].antal=newtotal-1
    }else if(mtype===3){
    mynewObjArr[updateIndex].antal=newtotal+10
    }else if(mtype===4){
    mynewObjArr[updateIndex].antal=newtotal-10
    }

    // i want to add the total price here
    mynewObjArr[updateIndex].totalt=mynewObjArr[updateIndex].antal*mynewObjArr[updateIndex].pris
    setproductionObj(mynewObjArr)
  

}



async function IsdoneHandler(produktid){
 setButtonSaveActive(true)
  setSpinnerLoading(true)
  let mynewObjArr=[...getproductionObj]
  let updateIndex=mynewObjArr.findIndex((data)=>data.produktid===produktid)
// here i want to toggle the Istatus
  if(mynewObjArr[updateIndex].Istatus===0){
    mynewObjArr[updateIndex].Istatus=1
  }else if(mynewObjArr[updateIndex].Istatus===1){
    mynewObjArr[updateIndex].Istatus=0
  }
  //here i want to check if all products have a status of 1, if they have a status of 1 we will set the clear production button
  // to true
  let checkStatus=mynewObjArr.every((data)=>data.Istatus===1)
  if(checkStatus===true){
    setClearedProduction(true)
  }else{
    setClearedProduction(false)
  }

  setproductionObj(mynewObjArr)

 try{
  let response= await usePutData(`updateProductionStatus`,{produktid,getchosenproductionId})
  if(response.status===200){
    setSpinnerLoading(false)
  }

  // const res=await axios.get(`http://192.168.8.116:3001/updateProductionStatus/${produktid}/${getchosenproductionId}`)

 }catch(err){
  setAlertCode(23006)
  setAlertMessage(selectedError[23006])
  setSpinnerLoading(false)
  setShowAlert(true)

  }
}





async function DelproductHandler(produktid){
  setButtonSaveActive(true)
  setExpandedRow(null)
  let mynewObjArr=[...getproductionObj]
  let updateIndex=mynewObjArr.findIndex((data)=>data.produktid===produktid)
  mynewObjArr.splice(updateIndex,1)
  setproductionObj(mynewObjArr)

  // here we need to update the database
   try{
     let response=await useDeleteData(`deleteProduct/${produktid}/${getchosenproductionId}`)
     if(response.status===200){
      setSpinnerLoading(false)
      setShowAlert(0)
      setAlertMessage(productDeleted)
     }
    }catch(err){
      setAlertCode(23012)
      setAlertMessage(selectedError[23012])
      setSpinnerLoading(false)
      setShowAlert(true)
  
     }

 

}



const handleRowClickIndex = (index,produktid) =>{
  setChosenProductId(produktid)

 
  if (expandedRow === index) {
    setExpandedRow(null);

  } else {
    setExpandedRow(index);


  }
};

const saveProductionHandler=()=>{
  setSpinnerLoading(false)
  setExpandedRow(null)
}

const productionNameHandler=(e)=>{
  setproductionName(e)
  setShowFurigana(true)

  //
}

const SaveProductionHandler=async()=>{
     

  setSpinnerLoading(true)
  setSavebool(false)
  setButtonSaveActive(false)
  let stringifiedproductionObj=JSON.stringify(getproductionObj)

   try {
     let response= await usePostData(`saveProduction`,{stringifiedproductionObj,getchosenproductionId})
 
     if(response.status===200){
       setAlertMessage(productionSaved)
       setSpinnerLoading(false)
       setShowAlert(true)
       setRenderKey(renderKey + 1);
       setExpandedRow(null) // here we close the changeamount menu
       setSaveProductionMenu(false) // we close the menu after saving production
     }

       } catch (error) {
         setAlertCode(23007)
         setAlertMessage(selectedError[23007])
         setSpinnerLoading(false)
         setShowAlert(true);
    
       } 
}


async function CreateproductionHandler(){
  setSpinnerLoading(true)


let stringifiedproductionObj=JSON.stringify(getproductionObj)
  try {
    let response=await usePostData(`createProduction`,{stringifiedproductionObj,getproductionName,getFuriganaName})
    console.log(response)
       if(response.status===200){
        setAlertCode(0)
        setAlertMessage(productionCreated)
        setSpinnerLoading(false)
        setShowAlert(true)
        setSavebool(true)
        setExpandedRow(null) // here we close the changeamount menu
        setSaveProductionMenu(false) // here we close the save production menu
        setRenderKey(renderKey + 1);
       }else if(response.status===201){
        setAlertCode(0)
        setAlertMessage(productionVoid)
        setSpinnerLoading(false)
        setShowAlert(true)
        setSaveProductionMenu(false) 
       }

  }catch(err){
    setAlertCode(23008)
    setAlertMessage(selectedError[23008])
    setSpinnerLoading(false)
    setShowAlert(true)

  }
}

async function ClearProductionHandler(){
setSpinnerLoading(true)

try{
  let response=await useDeleteData(`clearProduction/${getchosenproductionId}`)
  if(response.status===200){
    //here we will remove the production from the productionObj array
    let mynewObjArr=[...gethistproddataObj]
    let updateIndex=mynewObjArr.findIndex((data)=>data.produktionid===getchosenproductionId)
    //here we will delete the production from the array
    mynewObjArr.splice(updateIndex,1)
    sethistproddataObj(mynewObjArr)
    setRenderKey(renderKey + 1);
    setExpandedRow(null) // here we close the changeamount menu
    setSaveProductionMenu(false) // we close the menu after saving production
    setAlertCode(0)
    setAlertMessage(productionCleared)
    setSpinnerLoading(false)
    setShowAlert(true)
    

    
  }
}catch(err){
  setAlertCode(23009)
  setAlertMessage(selectedError[23009])
  setSpinnerLoading(false)
  setShowAlert(true)

}

}

// if the user changges the value on the range control we save the value in the database
async function SetBackGroundShadeHandler(val){
  setBackGroundShade(val)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateBackGroundShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }
  }catch(error){
      setAlertCode(23010)
      setAlertMessage(selectedError[23010])
      setSpinnerLoading(false)
      setShowAlert(true)
  
    }

}

async function SetmenuColorHandler(val){
  setMenuShade(val)
  setMenuShade2(val-0.20)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateMenuShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }

}catch(error){
  setAlertCode(23011)
  setAlertMessage(selectedError[23011])
  setSpinnerLoading(false)
  setShowAlert(true)

  return;
}
}


const setFuriganaNameHandler=(e)=>{
  setFuriganaName(e)
}

 const createNewProductionEnvHandler=()=>{
  setproductionName('')
  setFuriganaName('')
  setproductionObj([])
  setproductionStatistics([])

 }

const openSaveMenuHandler=()=>{
  // toggle this menu
  if(getSaveProductionMenu===true){
    setSaveProductionMenu(false)
  }else{
  setSaveProductionMenu(true) 
}
}






  let produktionAtWorkObj=gethistproddataObj.filter((data)=>data.iStatus===0)
  let filteredObj=gethistproddataObj.filter((data)=>data.iStatus===1)
  let totalantal=getproductionObj.reduce((a,b)=>a+b.antal,0)
  let totalamount=getproductionObj.reduce((a,b)=>a+b.totalt,0)
  

  return (
 <>
<div className="xntotWrapper">
    {windowWidth<360?displayGadgetToSmall():''}
    <div className={getBackGroundShade<1?"xnwindowArea":"xnwindowArea1"}>
      {getBackGroundShade<1?<img src={imageUrl} alt="Bakesoft" className="xnbackgroundImageRecepyNew" />:''}
  

    <div className="xnheaderArea">
   <div className={getBackGroundShade==1?"xncompName1":"xncompName"}>{title} </div>
</div>
    <div className="xnrangeArea">
    <input type="range" min="0.0" max="1" value={getMenuShade} step="0.10" id={2}  
  title={titleAdjustMenu} className="xnrange2" onChange={(e)=>SetmenuColorHandler(e.target.value)}/>

  <input type="range" min="0.0" max="1" value={getBackGroundShade} step="0.10" id={2}  
  title={titleAdjustMenu1} className="xnrange1" onChange={(e)=>SetBackGroundShadeHandler(e.target.value)}/>


</div>
        
        <div className="xnwindowAreaA">

    <div className="xndataContainer">




<div className="xnareaWrapper">
    <fieldset className="xnnewProductFieldset"><legend className="xnnewProductLegend">{leftFirstSelectLegend}</legend>
      <select className="xnnewProductionCategorySelect" onChange={(e)=>GetProducts(e.target.value)}>
    <option value="0" hidden>&nbsp;&nbsp;{leftFirstSelectFirstOption}&nbsp;!&nbsp;</option>
    {getallproductcategoriesObj.map((data,key)=><option key={key}value={data.kategoriid}>&nbsp;&nbsp;{data.benaemn}({data.antal})</option>)}
    </select>

    <select className="xnnewProductionProductSelect" onChange={(e)=>createProduction(e.target.value)}>
    <option value="0" hidden>&nbsp;&nbsp;{leftSecondSelectFirstOption}&nbsp;!&nbsp;</option>

    {getproductsbyCategory.map((data,key)=>
    <option key={key}value={data.produktid}>{data.benaemn}</option>)}
    </select>
   
    
    <fieldset className="xnongoingProductionFieldset"><legend className="xnongoingProductionLegend">{leftThirdSelectLegend}</legend>
      <select className="xnongoingProductionSelect" onChange={(e)=>GetongoingdataHandler(e.target.value)}>
    <option value="0" hidden>&nbsp;&nbsp;{leftThirdSelectFirstOption}&nbsp;!&nbsp;</option>
    {produktionAtWorkObj.map((data,key)=><option key={key}value={data.produktionId}>&nbsp;{data.benaemn}</option>)}
    </select>
    </fieldset>


    <fieldset className="xnproductionHistoryFieldset"><legend className="xnproductionHistoryLegend">{leftFourthSelectLegend}</legend>
      <select className="xnproductionHistorySelect" onChange={(e)=>GethistorydataHandler(e.target.value)}>
    <option value="0" hidden>&nbsp;&nbsp;{leftFourthSelectFirstOption}&nbsp;!&nbsp;</option>
    {filteredObj.map((data,key)=><option key={key}value={data.produktionId}>&nbsp;&nbsp;{data.benaemn}</option>)}
    </select>
    </fieldset>

    </fieldset>


    

    </div>
 

    </div>
        

    <div className="xncreateproductionWrapper"><IoCreateOutline   className={getBackGroundShade<1?"xncreatenewItem":"xncreatenewItem1"}
    onClick={()=>createNewProductionEnvHandler()}
    title={titleCreateNewProduct}/></div>
   
        
        
        </div>
        <div className="xnwindowAreaB">
      
        <div className={getSaveProductionMenu===false?"xnproductionHeaderArea":"xnproductionHeaderArea1"}>
      <div className="xnproductionHeader">
      <button className="xnproductionPid">{middleHeaderId}</button>
      <button className="xnproductionBenaemn">{middleHeaderName}</button>
      <button className="xnproductionNum">{middleHeaderAmount}</button>
      <button className="xnproductionTotal">{middleHeaderTotal}</button>
      <button className="xnproductionStatus">{middleHeaderStatus}</button>
      <button className="xnproductionDelete">
      <RiDeleteBack2Line className="xnproductionDeleteArrow"/>
      </button>
      
      </div>
      
      <div className="xnproductionDataAreas">{getproductionObj.map((data,index)=>
      <div key={index} className="xndatarows">
        <button className={index%2===0?"xnproduktionid":"xnproduktionid1"}>{data.produktionid}</button>
        <button className={index%2===0?"xnbenaemn":"xnbenaemn1"} onClick={()=>handleRowClickIndex(index,data.produktid)}>{data.benaemn}</button>
        <button className={index%2===0?"xnantal":"xnantal1"}>{data.antal}</button>

        <button className={index%2===0?"xntotal":"xntotal1"}>{String(data.totalt).substring(0,6)}</button>
        <button className={index%2===0?"xnstatus":"xnstatus1"} onClick={()=>IsdoneHandler(data.produktid)}>{data.Istatus===1?clearProductInProductionButtonText1:clearProductInproductionButtonText}</button>
        <button className={index%2===0?"xndelete":"xndelete1"}>
        <RiDeleteBack2Line className="xnproductionDeleteArrow" onClick={()=>DelproductHandler(data.produktid)}/>
        </button>
   <div className="xnabcwrapper">
        {data.produktionid>0&&expandedRow === index && (<div className="xncreateProductionAnimatedDiv2">
        <ChangeAmountMenu productionNew={productionNew} ChangeAmountHandler={ChangeAmountHandler} getChosenProductId={getChosenProductId}/>
 

       </div>)}</div>
         </div>)}
         
         <SaveButton productionNew={productionNew} getShowSaveButton={getShowSaveButton} getButtonSaveActive={getButtonSaveActive}
         saveProductionHandler={saveProductionHandler} openSaveMenuHandler={openSaveMenuHandler}
         titleButtonOpenSaveMenu={titleButtonOpenSaveMenu}
         />

  
      </div>


      </div>   
      {getSaveProductionMenu===true?
                           <SaveProductionMenu productionNew={productionNew} getClearedProduction={getClearedProduction}
                           getallproductcategoriesObj={getallproductcategoriesObj}  productionNameHandler={productionNameHandler}
                           getproductionName={getproductionName} SaveProductionHandler={SaveProductionHandler} CreateProductionHandler={CreateproductionHandler}
                           getCreatedNewProduction={getCreatedNewProduction} ClearProductionHandler={ClearProductionHandler}
                           
                           saveProductionBoxHeaderLegend={saveProductionBoxHeaderLegend}
                           saveProductionInputPlaceHolder={saveProductionInputPlaceHolder}
                           createProductionButtonText={createProductionButtonText}
                           clearProductionButtonText={clearProductionButtonText}
                           saveProductionButtonText={saveProductionButtonText}
                           getCountry={getCountry}
                           setFuriganaNameHandler={setFuriganaNameHandler}
                           getFuriganaName={getFuriganaName}
                           getShowFurigana={getShowFurigana}
                           setShowFurigana={setShowFurigana}
                           getNewProductIsCreated={getNewProductIsCreated}
                           getSparaButton={getSparaButton}
                           getOnGoingIsActive={getOnGoingIsActive}
                           getHistoryIsOnGoing={getHistoryIsOnGoing}



                      />:''}
</div>



        <div className="xnwindowAreaC">
          <div className="xnproductionStatisticTop">
            <StatusHeader productionNew={productionNew} getproductionStatistics={getproductionStatistics} getSpinnerLoading={getSpinnerLoading}
                          rightHeaderLegend={rightHeaderLegend}
                          rightHeaderCategoryId={rightHeaderCategoryId}
                          rightHeaderPid={rightHeaderPid}
                          rightHeaderCategory={rightHeaderCategory}
                          rightHeaderSum={rightHeaderSum}
                          rightHeaderAmount={rightHeaderAmount}
                          titleRightHeaderCategoryId={titleRightHeaderCategoryId}
                          titleRightHeaderPid={titleRightHeaderPid}
                          
            />

          </div>


   
        </div>

      </div>
      
      <div className="xnprodNewSpinnerContainer">
        <div className="xnprodNewSpinner">
          <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="0.75" width="60" visible={getSpinnerLoading}/>
        </div>
        </div>

  {getShowAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}/>:''}
   
   
   </div>
    </>
  
  

  )
}

function ChangeAmountMenu(props){
let {productionNew,ChangeAmountHandler,getChosenProductId}=props

return(
  <div className="xneditproductTop">
  <div className="xneditproductArea">

      <div className="xnplussign" onClick={()=>ChangeAmountHandler(getChosenProductId,1)} 
      onDoubleClick={()=>ChangeAmountHandler(getChosenProductId,3)}
      title="Tryck för att öka mängd"><AiOutlinePlusCircle className="xnAiOutlinePlusCircle"/></div>

  <div className="xnminussign" onClick={()=>ChangeAmountHandler(getChosenProductId,2)} 
  onDoubleClick={()=>ChangeAmountHandler(getChosenProductId,4)} 
  title="Tryck för att minska mängd"><AiOutlineMinusCircle  className="xnAiOutlineMinusCircle"/></div>
  </div>
  </div>

)
}

function SaveButton(props){
  let {productionNew,getShowSaveButton,getButtonSaveActive,saveProductionHandler,
    titleButtonOpenSaveMenu,openSaveMenuHandler
  }=props
  return(
      <div className="xnproductionbuttonTop">
     <button className={getButtonSaveActive===false?"xnsaveproductionButton":"xnsaveproductionButtonActive"} onClick={openSaveMenuHandler} disabled={!getButtonSaveActive} title={titleButtonOpenSaveMenu}>
      {getButtonSaveActive===true?<HiOutlineSaveAs className="xnsaveproductionButtonIconActive"/>:<HiOutlineSaveAs className="xnsaveproductionButtonIcon"/>}</button>
    </div>

  )
}
function SaveProductionMenu(props){
  let{productionNew,SaveProductionHandler,productionNameHandler,getproductionName,
    CreateProductionHandler,getCreatedNewProduction,ClearProductionHandler,getClearedProduction,getSparaButton,
    getOnGoingIsActive,getHistoryIsOnGoing,

    saveProductionBoxHeaderLegend,saveProductionInputPlaceHolder,createProductionButtonText,clearProductionButtonText,
    saveProductionButtonText,getCountry,setFuriganaNameHandler,getFuriganaName,
    setShowFurigana,getShowFurigana,getNewProductIsCreated
  
  }=props
    
   return(
          <div className="xnsaveproductionMenuTop">
            <div className="xnsaveWrapper">
              <div className="xnsavemenuName">{saveProductionBoxHeaderLegend}</div>
              
              </div>
          <div className="xnsaveProductionMenuArea">
              
              <input type="text" className={getCountry!=='ja'?"xnsaveProductionMenuInput2":"xnsaveProductionMenuInputJAP"} 
              placeholder={saveProductionInputPlaceHolder} value={getproductionName||''} onChange={(e)=>productionNameHandler(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  setShowFurigana(false);
                }
              }}
              />
         
        {getCountry==='ja'&&getShowFurigana===true?
              <input type="text" className="xnsaveProductionMenuInputFurigana" 
              placeholder='ふりがな' value={getFuriganaName||''} onChange={(e)=>setFuriganaNameHandler(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  setShowFurigana(false);
                }
              }}
              
              />
         :''}

              <button className={getproductionName.length>0?"xnsaveproductMenuButtonActive":"xnsaveproductMenuButtonNotActive"}
            onClick={()=>CreateProductionHandler()} disabled={getproductionName.length<1}>{createProductionButtonText}</button>

            {getOnGoingIsActive===true&&getSparaButton===false&&getHistoryIsOnGoing===false?<button className="xnsaveproductMenuButtonActive1"
            onClick={()=>SaveProductionHandler()}>{saveProductionButtonText}</button>:''}

            <button className={getClearedProduction===true?"xnsaveproductMenuButtonKlarskriv":"xnsaveproductMenuButtonKlarskriv1"}
            onClick={()=>ClearProductionHandler()} disabled={getClearedProduction===false}>{clearProductionButtonText}</button>

            
          
          
          </div>
  
          </div>
      )
  }

  function StatusHeader(props){
    let{productionNew,getproductionStatistics,
    
      rightHeaderLegend,rightHeaderCategoryId,rightHeaderPid,rightHeaderCategory,
      rightHeaderSum,rightHeaderAmount,titleRightHeaderCategoryId,titleRightHeaderPid


    }=props
    
    
    // //let totalAmount = getproductionStatistics.reduce((acc, data) => acc + (data.totaltantal * data.pris), 0);
    // let totalantal=getproductionStatistics.reduce((acc,data)=>acc+data.totaltantal,0)
    
    // const totalsum = getproductionStatistics.reduce((acc, data) => acc + data.summa,0);
    
          return(
              
          //   <div className="xnstatusheaderMain">
          //  <fieldset className="xnstatusHeaderFieldset">
            // <legend className="xnstatusHeaderLegend">{rightHeaderLegend}</legend>
        
           <>
           <div className="xnstatusheaderHeader">
              <button className="xnstatusheaderProduktionsid" title={titleRightHeaderPid}>{rightHeaderPid}</button>
              <button  className="xnstatusheaderBenaemn">{rightHeaderCategory}</button>
              <button  className="xnstatusheaderSum">{rightHeaderSum}</button>
              <button  className="xnstatusheaderNum">{rightHeaderAmount}</button>
              </div>
           
           <div className="xnstatusheaderData">{getproductionStatistics.map((data,index)=>
           
           <div key={index}>
          
              <button className={index%2===0?"xnsamproduktionid":"xnsamproduktionid1"}>{data.produktionid}</button>
              <button className={index%2===0?"xnsambenaemn":"xnsambenaemn1"}>{data.benaemn}</button>
              <button className={index%2===0?"xnsamantal":"xnsamantal1"}>{data.summa}</button>
              <button className={index%2===0?"xnsamtotantal":"xnsamtotantal1"}>{data.totaltantal}</button>
              </div>)}

         
           
              </div>
              
          
           
           
          {/* //  </fieldset> */}
          {/* //  </div> */}
            </>
          )
        }

export {CreateProductionNew,ChangeAmountMenu,SaveButton,SaveProductionMenu,StatusHeader}