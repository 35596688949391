import { useState } from 'react';
import useGetData from '../hooks/useGetData';

function usePageSettings(pageid) {
  const [backGroundShade, setBackGroundShade] = useState(null);
  const [menuShade, setMenuShade] = useState(null);
  const [menuShade2, setMenuShade2] = useState(null);
  const [colorVal, setColorVal] = useState(null);
  const [imageVal,setImageVal] = useState(null);


  async function GetSettings() {    
      const response = await useGetData(`getPageSettings/${pageid}`);
      setBackGroundShade(response.data[0][0].backGroundShade);
      setMenuShade(response.data[1][0].menuShade);
      setMenuShade2(response.data[2][0].menuShade2);
      setColorVal(response.data[3][0].colorVal);
      setImageVal(response.data[5][0].imageVal)  // IMAGE NAME
    
  }

  return {
    GetSettings,
    backGroundShade,
    menuShade,
    menuShade2,
    colorVal,
    imageVal,
  };
}

export default usePageSettings;