import React from 'react'
import './displayGadgetToSmall.css'
function DisplayGadgetToSmall() {

   
  return (
    <div className="screenCover">

      <div className="screenCover__header">Bakesoft</div>

        <div className="screenCover__content">
            Unfortunately we do not support screens
            smaller than screenwidth 360px. Please
            try to use a tablet or a computer.  
    </div>
    </div>
  )
}

export default DisplayGadgetToSmall