import { useState, useEffect } from 'react';

function 
useFetchImageUrl(getImageVal) {
  const [imageUrl, setImageUrl] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertCode, setAlertCode] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const fetchImageUrl = async () => {
      try {
        const token = localStorage.getItem('auth_token');
        if (!token) {
          setAlertCode(52000);
          setAlertMessage('User is not logged in');
          setShowAlert(true);
          return;
        }

        




        const headers = token ? { authorization: `Bearer ${token}` } : {};
     //   const response = await fetch(`http://192.168.8.116:3001/getimages/${getImageVal||'bakermaninBakery.jpeg'}`, { headers });
     const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/getimages/${getImageVal||'bakermaninBakery.jpeg'}`, { headers });
     
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setImageUrl(url);
      } catch (error) {
        setAlertCode(52000);
        setAlertMessage('Kan inte hämta bakgrundsbild');
        setShowAlert(true);
      }
    };
    fetchImageUrl();
  }, [getImageVal]);

  return { imageUrl, alertMessage, alertCode, showAlert };
}
export default useFetchImageUrl;
