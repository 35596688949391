import React from 'react';
import './LineAppAlert.css'
const YesNoLineAlert = ({ message,onCloseYes,onCloseNo }) => {

  return (
    <div className="alert-overlay">
      <div className="alert-content">
        <div className='alert-message'>{message}</div>
      
        
        <button className='alertYes-button' onClick={onCloseYes}>同意する</button>
        <button className='alertNo-button' onClick={onCloseNo}>同意しない</button>

        <div className='bakesoftlogo'>Bakesoft</div>
        <div className='bakesoftuserdatapolicy'><a href={`${process.env.REACT_APP_BACKEND_FRONTEND_URL}/UserDataPolicy`}>Bakesoft User Data Policy</a></div>
        
        
      </div>
    </div>
  );
};

export default YesNoLineAlert;