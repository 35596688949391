
import React,{useState,useEffect,useContext, useRef} from 'react'
import languages from '../AppLanguage/AppLanguage';
import AppError from '../AppLanguage/AppError';
import { RotatingLines } from 'react-loader-spinner'
import Alert from '../functions/appAlert'
import useDeviceDetection from '../hooks/useDeviceDetection'
import useReportErr from '../hooks/useReportErr'
import {useNavigate,useParams} from 'react-router-dom';
import useFetchImageUrl from '../hooks/useFetchImageUrl';
import usePageSettings from '../hooks/usePageSettings';

import {useGetData,useDeleteData,usePostData,usePutData} from '../hooks/useGetData'
import './PrintInvoice.css'
import '../style/sliders.css'

export const MyContext = React.createContext();

export default function PrintInvoice() {

    const { invoiceId } = useParams();
    const[getBackGroundShade,setBackGroundShade]=useState(0.45)
    const[getMenuShade,setMenuShade]=useState(0.75)
    const[getMenuShade2,setMenuShade2]=useState(0.45)

    const [windowWidth,setWindowWith]=useState(0) // this is the window width
    const [windowHeight,setWindowHeight]=useState(0)  // this is the window height
    const [getMobileIsUsed,setMobileIsUsed]=useState(false) // this is the window height
    const[getCountry,setCountry]=useState('se') // this is the country
    const [getColorVal,setColorVal]=useState('')
    const [getImageVal,setImageVal]=useState('')
    const [getImageUrl,setImageUrl]=useState('')
    const[getSpinnerLoading,setSpinnerLoading]=useState(false)
    const [getAlertMessage,setAlertMessage] = useState({});
    const[getAlertCode,setAlertCode]=useState(0)
    const [getShowAlert,setShowAlert] = useState(false);
    // const[getName,setName]=useState('')
    // const[getSurname,setSurname]=useState('')
    // const[getAddress,setAddress]=useState('')
    // const[getZipcode,setZipcode]=useState('')
    // const[getCity,setCity]=useState('')
    // const[getRegion,setRegion]=useState('')
    // const[getPhone,setPhone]=useState('')
    // const[getEmail,setEmail]=useState('')
    // const[getPassword,setPassword]=useState('')
    // const[getPassword2,setPassword2]=useState('')
    const[getDisposableHeight,setDisposableHeight]=useState(0)
    const[getForetagName,setForetagName]=useState('')
    const[getViewDataUsage,setViewDataUsage]=useState(false)
    const[getUserDataUsage,setUserDataUsage]=useState([{}])
    const[getUserMainData,setUserMainData]=useState([{}])
const[getUserInfo,setUserInfo]=useState([{}])
const [getOldpass,setOldpass]=useState('')
const [getNewpass,setNewpass]=useState('')
const [getConfirmpass,setConfirmpass]=useState('')
const [getUserAccountBalance,setUserAccountBalance]=useState([{}])  // user account balance
const [getInvoiceNextDueDate,setInvoiceNextDueDate]=useState([{}]) // invoice due date
const [getUserInvoiceInfo,setUserInvoiceInfo]=useState([{}]) // user invoice information & historical
const [getUserBillingAdressInfo,setUserBillingAdressInfo]=useState([{}]) // user billing adress information
const [getTaxId,setTaxId]=useState('')
const [getMaxValueSlider,setMaxValueSlider]=useState(false)
const [getMaxValue,setMaxValue]=useState()
const[getAccountInformation,setAccountInformation]=useState([{}])
const[getServiceInformation,setServiceInformation]=useState([{}])
const[getInvoiceRowInfo,setInvoiceRowInfo]=useState([{}])
const[getServicePriceInformation,setServicePriceInformation]=useState([{}])
const[getCurrencyInfo,setCurrencyInfo]=useState([{}])
const [getServicesInformation,setServicesInformation]=useState([{}])
const[getchosenServiceid,setchosenServiceid]=useState(0)
const[expandedRow,setExpandedRow]=useState(null)
const[getServicesSalesInformation,setServicesSalesInformation]=useState([{}])
const[getBillingData,setBillingData]=useState([{}])
const[getOwningCompanyInfo,setOwningCompanyInfo]=useState([{}])
const[getchosenInvoiceId,setChosenInvoiceId]=useState(0)




const pageid=21


    useEffect(()=>{
        let country = localStorage.getItem('country')||'en' // this is the country code
        setCountry(country)
      },[])

      // if language is japanese we use the font Zen Old Mincho
        let newFontafamily=''
        if(getCountry==='ja'){
        newFontafamily= "'Zen Old Mincho', Arial, Helvetica, sans-serif"
        }else{
        newFontafamily='Arial, Helvetica, sans-serif'
        }
        document.documentElement.style.setProperty('--font-afamily', newFontafamily);

      const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal } = usePageSettings(pageid);
      const { imageUrl, alertMessage, alertCode, showAlert } = useFetchImageUrl(getImageVal); // runs at mount, gives ut the image url and fetch image from server

      const navigate = useNavigate();



      useEffect(() => {
        function setSize() {
          var totalHeight=window.innerHeight

            setDisposableHeight(totalHeight-150)
          setWindowWith(window.innerWidth);
          setWindowHeight(window.innerHeight);
          if(window.innerWidth<600){
            setMobileIsUsed(true)
          }
        }
        setSize();

      }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);

// set the passowrds to void
useEffect(()=>{
    setOldpass('')
    setNewpass('')
    setConfirmpass('')
},[])

// language settings
const selectedCountry = languages[getCountry];

const{
    title,
    websiteCompanyName,
invoiceText,
broughtToYouText,
customerIdText,
serviceText,
nameText,
numText,
maxText,
debitText,
kreditText,
totaltText,
sumNetText,
sumVatText,
attBetalaText,
bankgiroText,
swishText,
termsText,
bankText,
bicText,
ibanText,
}=selectedCountry.MyAccount||{};

const selectedError=AppError[localStorage.getItem('country')||'en']

const {
    emailSent,
    userAddressDataUpdated,
    activateServiceMessage,
    deactivateServiceMessage,
    prescriptionCreatedMessage,
}= selectedCountry.confirmationMessages||{};



//end language settings

useEffect(() => {
    GetSettings();
  }, [GetSettings]);

  // we set the image url here
  useEffect(() => {
    setImageUrl(imageUrl);
    if (alertCode > 0) {
      setAlertMessage(alertMessage);
      setAlertCode(alertCode);
      setShowAlert(showAlert);
    }
  }, [imageUrl]);

  // here we set the color values and image values
  useEffect(() => {
    setBackGroundShade(backGroundShade);
    setMenuShade(menuShade);
    setMenuShade2(menuShade2);
    setColorVal(colorVal);
    setImageVal(imageVal);
  }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);





function rgbToRgba(rgb, alpha) {
    return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
  }

//--bakcground shade settings
let rgbaVal
let rgbaVal2
let val

if(getColorVal && getColorVal.length > 0){
  let rgb = getColorVal;
  let alpha = getMenuShade; // replace with your alpha value
  let alpha2 = getMenuShade2; // replace with your alpha value
  rgbaVal = rgbToRgba(rgb, alpha);
  rgbaVal2 = rgbToRgba(rgb, alpha2);
  val=`rgba(255, 255, 255, ${getBackGroundShade})`

  }else{
    rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
    rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
    val=`rgba(255, 255, 255, ${getBackGroundShade})`
  }
//---

useEffect(() => {
    let root = document.documentElement;
    root.style.setProperty('--rgbaVal', rgbaVal);
    root.style.setProperty('--rgbaVal2', rgbaVal2);
    root.style.setProperty('--val',val);
    root.style.setProperty('--disposableHeight',getDisposableHeight+'px');
    root.style.setProperty('--windowHeight',windowHeight+'px');

  }, [getBackGroundShade,getMenuShade,getMenuShade2]);

  //-----

  // ErrHandling
  const RunReportErrHandler = async (errCode) => {
    setSpinnerLoading(true);

      await useReportErr('ErrReport',{errCode});
    setSpinnerLoading(false);
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  useEffect(() => {
    if (getAlertCode > 0) {
      RunReportErrHandler(getAlertCode);
    }
  }, [getAlertCode]);





useEffect(()=>{

FetchUserDataUsage()
},[getViewDataUsage])

async function FetchUserDataUsage(){
    setSpinnerLoading(true)
    try{
        const response = await useGetData(`getUserDataUsage`)

            setUserDataUsage(response.data[0])
            setUserAccountBalance(response.data[1])
            setInvoiceNextDueDate(response.data[2])
            setUserInvoiceInfo(response.data[3])
            setUserBillingAdressInfo(response.data[4])
            setMaxValue(response.data[4][0].invoiceMaxVal)
            setMaxValueSlider(response.data[4][0].invoiceMaxVal>0?true:false)
            setAccountInformation(response.data[5])
            setServiceInformation(response.data[6])
            setInvoiceRowInfo(response.data[7])
            setCurrencyInfo(response.data[8])
            setServicesInformation(response.data[9])
            setServicesSalesInformation(response.data[10])
            setOwningCompanyInfo(response.data[11])

            setSpinnerLoading(false)

    }catch(error){
        setAlertCode(53000)
        setAlertMessage(selectedError[53000])
        setSpinnerLoading(false)
        setShowAlert(true)
        return;
    }
}







async function SendUserDataByEmail(){
    setSpinnerLoading(true)

    try{
        const response = await useGetData(`sendUserDataByEmail`)
        if(response.status===200){
            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage(emailSent)
            setShowAlert(true)
        }
    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53004)
        setAlertMessage(selectedError[53004])
        setShowAlert(true)
    }
}

// async function GetUserMainData(){
//     setSpinnerLoading(true)

//     try{
//         const response = await useGetData(`getUserMainData`)
//        setUserMainData(response.data[0])
//             setSpinnerLoading(false)
//     }catch(error){
//         setSpinnerLoading(false)
//         setAlertCode(53005)
//         setAlertMessage(selectedError[53005])
//         setShowAlert(true)
//     }
// }

// async function GetUserAdressInfo(){
//     setSpinnerLoading(true)
//     try{
//         const response=await useGetData(`getUserAdressInfo`)
//         setUserInfo(response.data[0])
//         setSpinnerLoading(false)

//     }catch(error){
//         setSpinnerLoading(false)
//         setAlertCode(53006)
//         setAlertMessage(selectedError[53006])
//         setShowAlert(true)
//     }
// }



useEffect(()=>{
    FetchBillingData(invoiceId)
},[invoiceId])


async function FetchBillingData(afakturaid){
    setChosenInvoiceId(afakturaid)
    setSpinnerLoading(true)
    try{
        const response = await useGetData(`getBillingData/${afakturaid}`)
        if(response.status===200){
            setBillingData(response.data[0])
            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage('Billing data has been fetched')
            setShowAlert(true)

        }

    
      }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53015)
        setAlertMessage(selectedError[53015])
        setShowAlert(true)
    return;
}

}






  return (

<div>
<MyContext.Provider value={{
// getName:getName||'',
// getSurname:getSurname,
// getAddress:getAddress,
// getZipcode:getZipcode,
// getCity:getCity,
// getPhone:getPhone,
// getEmail:getEmail,
// getPassword:getPassword,
// getPassword2:getPassword2,
// getForetagName:getForetagName,
// getUserDataUsage:getUserDataUsage,
// getUserMainData:getUserMainData,
// getUserInfo:getUserInfo,
// getOldpass:getOldpass,
// getNewpass:getNewpass,
// getConfirmpass:getConfirmpass,
getUserAccountBalance:getUserAccountBalance,
getInvoiceNextDueDate:getInvoiceNextDueDate,
getUserInvoiceInfo:getUserInvoiceInfo,
getUserBillingAdressInfo:getUserBillingAdressInfo,
getTaxId:getTaxId,
// getMaxValueSlider:getMaxValueSlider,
// getMaxValue:getMaxValue,
getAccountInformation:getAccountInformation,
getServiceInformation:getServiceInformation,
getInvoiceRowInfo:getInvoiceRowInfo,
getServicePriceInformation:getServicePriceInformation,
getCurrencyInfo:getCurrencyInfo,
getServicesInformation:getServicesInformation,
getchosenServiceid:getchosenServiceid,
// expandedRow:expandedRow,
getServicesSalesInformation:getServicesSalesInformation,
getMobileIsUsed:getMobileIsUsed,
getBillingData:getBillingData,
getOwningCompanyInfo:getOwningCompanyInfo,
websiteCompanyName:websiteCompanyName,
invoiceText:invoiceText,
broughtToYouText:broughtToYouText,
customerIdText:customerIdText,
serviceText:serviceText,
nameText:nameText,
numText:numText,
maxText:maxText,
debitText:debitText,
kreditText:kreditText,
totaltText:totaltText,
sumNetText:sumNetText,
sumVatText:sumVatText,
attBetalaText:attBetalaText,
bankgiroText:bankgiroText,
swishText:swishText,
termsText:termsText,
bankText:bankText,
bicText:bicText,
ibanText:ibanText,
getchosenInvoiceId:getchosenInvoiceId,
getCountry:getCountry,



SendUserDataByEmail:SendUserDataByEmail,
setMaxValue:setMaxValue,
setSpinnerLoading:setSpinnerLoading,
FetchBillingData:FetchBillingData,

}}>

<Faktura/>
</MyContext.Provider>
</div>
)

}
function Faktura(){
    const ctx = useContext(MyContext);
    let userInvoiceInfo = ctx.getUserInvoiceInfo
    let OwnerCompanyInfo = ctx.getOwningCompanyInfo
    let userBillingName = ctx.getUserBillingAdressInfo[0].givenName
    let userBillingSurname = ctx.getUserBillingAdressInfo[0].familyName
    let userName=userBillingName+' '+userBillingSurname
    let xchangeRate=ctx.getCurrencyInfo[0].xchangeRate

    // here we calculate the net sum inclusing vat
    let netsum=userInvoiceInfo[0].nettotal
    // netsum
    let netsumInLocalCurrency=Math.round(netsum/xchangeRate)
    let vatRate=Math.round(ctx.getUserBillingAdressInfo[0].vatRate)
    // vat
    let vatSum=Math.round(userInvoiceInfo[0].nettotal/vatRate)
    let vatSumInLocalCurrency=Math.round(vatSum/xchangeRate)
    // totalsum
    let totalSumInLocalCurrency=netsumInLocalCurrency+vatSumInLocalCurrency

    let currencySymbol=ctx.getCurrencyInfo[0].currencySymbol
    
  
    return(
<>
<div className="afakturaPrintButtonWrapper">
    <button className="afakturaPrintButton" onClick={()=>window.print()}>{'Print'}</button>
</div>
        <div className="afakturaArea">
            <div className="afakturaAreaWrapper">
                <div className="afakturaAreaGrid">
                    <div className="afakturaAreaA">
                        <div className="afakturaHeaderWrapper">
                        <div className="afakturaHeaderName">{ctx.websiteCompanyName}&nbsp;</div>
                        <div className={ctx.getCountry!=='ja'?"fakturaHeaderType":"fakturaHeaderTypeJAP"}>{ctx.invoiceText}</div>
                        </div>
                        <div className="afakturaHeaderWrapper">
                        <div className="afakturaHeaderBroughtToYou">{ctx.broughtToYouText}</div>
                        <div className="afakturaHeaderInvoiceDue">{ctx.getchosenInvoiceId}</div>
                       </div>
                    </div>

    <div className="afakturaAreaB">

                    <div className="afakturaHeaderWrapper">
                             <div className='afakturaHeaderLine'></div>
                    </div>      
        <div className="afakturaInfoTop">
            <div className="afakturainfoWrapper">
                    <div className="afakturacompanyInfo">
                                <div className="afakturaOwnerInfo">{OwnerCompanyInfo[0].benaemn}</div>
                                <div className="afakturaOwnerInfo">{OwnerCompanyInfo[0].Address}</div>
                                <div className="afakturaOwnerInfo">{OwnerCompanyInfo[0].PostalCode}</div>
                                <div className="afakturaOwnerInfo">{OwnerCompanyInfo[0].City}</div>
                                <div className="afakturaOwnerInfo">{OwnerCompanyInfo[0].Phone}</div>
                                <div className="afakturaOwnerInfo">{OwnerCompanyInfo[0].Email}</div>
                                <div className="afakturaOwnerInfo">{OwnerCompanyInfo[0].VATID}</div>

                    </div>
                    <div className="afakturacustomerInfo">
                        <div className="afakturaCustomerid"></div>
                        <div className="afakturacustomerDet">{ctx.customerIdText}{ctx.getUserBillingAdressInfo[0].userid}</div>
                        <div className="afakturacustomerDet">{userName}</div>
                        <div className="afakturacustomerDet">{ctx.getUserBillingAdressInfo[0].address}</div>
                        <div className="afakturacustomerDet">{ctx.getUserBillingAdressInfo[0].postalCode}</div>
                        <div className="afakturacustomerDet">{ctx.getUserBillingAdressInfo[0].region}</div>
                        <div className="afakturacustomerDet">{ctx.getUserBillingAdressInfo[0].city}</div>
                        <div className="afakturacustomerDet">{ctx.getUserBillingAdressInfo[0].country}</div>
                  </div>    
           </div>
        </div>
   
    </div>
                
        <div className="afakturaAreaC">

                    <div className="afakturaHeaderWrapper">
                             <div className='afakturaHeaderLine'></div>
                    </div>  
            <div className="afakturaAreaCTop">
                    <div className="afakturaAreaCWrapper1">
                        <div className="afakturaAreaCHeaderService">{ctx.serviceText}</div>
                        <div className="afakturaAreaCHeaderNamn">{ctx.nameText}</div>
                        <div className="afakturaAreaCHeaderAntal">{ctx.numText}</div>
                        <div className="afakturaAreaCHeaderMaxAntal">{ctx.maxText}</div>
                        <div className="afakturaAreaCHeaderafakturerat">{ctx.debitText}</div>
                        <div className="afakturaAreaCHeaderKredit">{ctx.kreditText}</div>
                        <div className="afakturaAreaCHeaderTotalt">{ctx.totaltText}</div>
                       

                </div>
           </div>
                <div className="afakturaAreaCWrapper">
                    <div className="afakturaAreaList">
                        {ctx.getBillingData.map((data,index)=>(
                            <div key={index} className="afakturaAreaListRow">
                                <div className="afakturaAreaListService">{data.serviceid}</div>
                                <div className="afakturaAreaListNamn">{data.benaemn}</div>
                                <div className="afakturaAreaListAntal">{data.antal>0?data.antal:1}</div>
                                <div className="afakturaAreaListMaxAntal">{data.maxantal>0?data.maxval:0}</div>
                                <div className="afakturaAreaListafakturerat">
                                    <div style={{textAlign:'left'}}>
                                    
                                    {Math.round(data.debit/xchangeRate)}</div></div>
                                <div className="afakturaAreaListTotalt">{Math.round(data.kredit/xchangeRate)}</div>
                                <div className="afakturaAreaListKredit">{Math.round((data.debit-data.kredit)/xchangeRate)}</div>
                            </div>
                        ))}
                    </div>
                    </div>

             
        </div>



        <div className="afakturaAreaD">
    <div className="afakturaPayTop">
                <div className="afakturaPayWrapper">
                        <div className="afakturaPayNetSum"></div>
                        <div className="afakturaPayNetSum">{ctx.sumNetText}{netsumInLocalCurrency}</div>
                        <div className="afakturaPayTotal"></div>
        </div>
            <div className="afakturaPayWrapper">
                        <div className="afakturaPayNetSum"></div>
                        <div className="afakturaPayVAT">{ctx.sumVatText}{vatSumInLocalCurrency}</div>
                        <div className="afakturaPayTotal"></div>
        </div>
        <div className="afakturaPayWrapper">
                        <div className="afakturaPayNetSum"></div>
                        {currencySymbol==='kr'?
                        <div className="afakturaPayTotal">{ctx.attBetalaText}{':'}{totalSumInLocalCurrency}{''}{currencySymbol}</div>:
                        <div className="afakturaPayTotal">{ctx.attBetalaText}{currencySymbol}{totalSumInLocalCurrency}</div>}
        </div>
    
    </div>


        <div className="afakturaHeaderWrapper">
                             <div className='afakturaHeaderLine'></div>
                    </div> 
           <div className="afakturaFooterTop">
                    <div className="afakturaFooterWrapper">
                            <div className="afakturaFooterHeadertext">{ctx.bankgiroText}{OwnerCompanyInfo[0].BANKGIRO}</div>
                            <div className="afakturaFooterHeadertext">{ctx.swishText}{OwnerCompanyInfo[0].SWISH}</div>
                            <div className="afakturaFooterHeadertext">{ctx.termsText}{ctx.getUserBillingAdressInfo[0].benaemn}</div>
                    </div>
                    <div className="afakturaFooterWrapper">
                            <div className="afakturaFooterHeadertext">{ctx.bankText}{OwnerCompanyInfo[0].Bank}</div>
                            <div className="afakturaFooterHeadertext">{ctx.bicText}{OwnerCompanyInfo[0].BIC}</div>
                            <div className="afakturaFooterHeadertext">{ctx.ibanText}{OwnerCompanyInfo[0].IBAN}</div>
                  
                    </div>
              

            </div>

        </div>

                </div>

            </div>  
            </div>
</>
            
    )

}
export {Faktura}



