import React from 'react';
import './appAlert.css'
const YesNoAlert = ({ message,onCloseYes,onCloseNo,btnYes,btnNo }) => {

  return (
    <div className="alert-overlay">
      <div className="alert-content">
        <div className='alert-message'>{message}</div>
      
        
        <button className='alertYes-button' onClick={onCloseYes}>{btnYes}</button>
        <button className='alertNo-button' onClick={onCloseNo}>{btnNo}</button>
        
      </div>
    </div>
  );
};

export default YesNoAlert;