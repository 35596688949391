import React,{useEffect,useState,useContext} from 'react'

import './info.css'
import {useGetData,usePutData,useDeleteData,usePostData,usePostData1} from '../hooks/useGetData'
import { RotatingLines } from  'react-loader-spinner'
import  letsgetstarted from '../images/signup10.jpg'
import  jpwoman from '../images/jpwoman2.jpg'
import  jpwoman1 from '../images/jpwoman1.jpg'
import  jpwoman3 from '../images/jpwoman3.jpg'
import  jpman1 from '../images/jpman1.jpg'
import  jpman2 from '../images/jpman2.jpg'
import ukman1 from '../images/ukman1.jpg'
import ukwoman1 from '../images/ukwoman1.jpg'
import swedman1 from '../images/swedman2.jpg'
import yukiwoman2 from '../images/yukiwoman2.jpg'
import yukiinbakery2 from '../images/yukiinbakery2.jpg'
import cornerbread from '../images/cornerbread2.jpg'
import languages from '../AppLanguage/AppLanguage'
import { IoShieldCheckmarkOutline, IoVolumeHigh } from "react-icons/io5";
import useReportErr from '../hooks/useReportErr'
import Alert from '../functions/appAlert'
import AppError from '../AppLanguage/AppError';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate,Link } from 'react-router-dom';
import { SE } from 'country-flag-icons/react/3x2'
import { JP } from 'country-flag-icons/react/3x2'
import { GB } from 'country-flag-icons/react/3x2'
import klarnaimage from '../images/klarna.png'
import breadpricelist5 from '../images/breadpricelist5.jpeg'

import { FaHome,FaPlus, FaRegNewspaper,FaFileContract, FaConciergeBell, FaRobot, FaInfoCircle, FaQuestionCircle, FaSignInAlt,FaAddressBook,FaShieldAlt } from 'react-icons/fa';




export const MyContext = React.createContext();

export default function Info() {

const[signup,setSignup]=useState([{}])
const [getCountry,setCountry]=useState('en')
const [windowWidth, setWindowWith] = useState(window.innerWidth);
const [windowHeight, setWindowHeight] = useState(window.innerHeight);
const [mobileIsUsed, setMobileIsUsed] = useState(false);
const[getShowAlert,setShowAlert]=useState(false)
const[getAlertMessage,setAlertMessage]=useState('')
const[getAlertCode,setAlertCode]=useState(0)
const[pageid,setPageId]=useState(25)
const [buttonId, setButtonId] = useState(1);
const [customerPrices, setCustomerPrices] = useState([{}]);
const [xchangeRate, setXchangeRate] = useState(0);
const [currencySymbol, setCurrencySymbol] = useState([{}]);
const[getServicesInfo,setServicesInfo]=useState([{}])
const[getSpinnerLoading,setSpinnerLoading]=useState(false)
const[getServicesMainInfo,setServicesMainInfo]=useState([{}])
const[faqData,setfaqData]=useState([{}])
const[getNextTab,setNextTab]=useState(0)
const[getUserId,setUserId]=useState(0)
const[getVatInfo,setVatInfo]=useState([{}])
const[getSignUpStatus,setSignUpStatus]=useState([{}]);

//klarna payment

const [clientToken, setClientToken] = useState(null);
const [klarna, setKlarna] = useState(false);
const [chosenServicePrice, setChosenServicePrice] = useState(0);
const [serviceName, setServiceName] = useState('');
const [klarnaXchangeRate, setKlarnaXchangeRate] = useState(0);
const [chosenServicePriceWithXchangeRate, setChosenServicePriceWithXchangeRate] = useState(0);
const [klarnaTaxRate, setKlarnaTaxRate] = useState(2500); // 25% tax rate for Klarna payments in SEK
const [klarnaTotalAmount, setKlarnaTotalAmount] = useState(0);
const [klarnaTotalTaxAmount, setKlarnaTotalTaxAmount] = useState(0);
const[finalPay,setfinalPay]=useState(false)
const[getIconSize,setIconSize]=useState(16)
const[getServiceTabStatus,setServiceTabStatus]=useState(true) // status to show the sign up tab again

//sliding menu
const [isMenuOpen, setIsMenuOpen] = useState(false);
const [isPartiallyOpen, setIsPartiallyOpen] = useState(true);
const [touchStartX, setTouchStartX] = useState(0);
const [touchEndX, setTouchEndX] = useState(0);


const toggleMenu = () => {
  setIsMenuOpen(!isMenuOpen);
  setIsPartiallyOpen(false);
};


const openMenu = () => {
  setIsMenuOpen(true);
  setIsPartiallyOpen(false);
};
const closeMenu = () => {
  setIsMenuOpen(false);
  setIsPartiallyOpen(false);
};



const handleTouchStart = (e) => {
  setTouchStartX(e.changedTouches[0].screenX);
};

const handleTouchMove = (e) => {
  setTouchEndX(e.changedTouches[0].screenX);
};

const handleTouchEnd = () => {
  if (touchStartX - touchEndX > 50) {
    // Swipe from right to left detected
    openMenu();
  } else if (touchEndX - touchStartX > 50) {
    // Swipe from left to right detected
    closeMenu();
  }
};
useEffect(() => {
  window.addEventListener('touchstart', handleTouchStart);
  window.addEventListener('touchmove', handleTouchMove);
  window.addEventListener('touchend', handleTouchEnd);

  return () => {
    window.removeEventListener('touchstart', handleTouchStart);
    window.removeEventListener('touchmove', handleTouchMove);
    window.removeEventListener('touchend', handleTouchEnd);
  };
}, [touchStartX, touchEndX]);




const [lastFetchTimestamp, setLastFetchTimestamp] = useState(() => {
  // Initialize state with value from localStorage or 0 if not found
  return parseInt(localStorage.getItem('lastFetchTimestamp')) || 0;
});
const navigate = useNavigate();


const [getSignupInfo, setSignupInfo] = useState({
  firstName: '',
  lastName: '',
  address: '',
  postalCode: '',
  city: '',
  region: '',
  localCountry: '', // default value
  email: '',
  phone: '',
  password: '',
  confirmPassword: '',
  language: '',
  currency: '',
  service: ''
});


useEffect(()=>{
  let country = localStorage.getItem('country')||'en' // this is the country code
  setCountry(country)
},[])


let newFontFamily = '';
if (getCountry === 'ja') {
   newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
} else {
newFontFamily = 'Arial, Helvetica, sans-serif'
//newFontFamily = 'Lobster'
 //newFontFamily = 'Times New Roman, Times, serif'
//newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
}
document.documentElement.style.setProperty('--font-family', newFontFamily);



useEffect(() => {
  const fixedKey = 'customerPricesAndInfo';

  const storedData = localStorage.getItem(fixedKey);
  if (storedData) {
    const { data: decodedData, timestamp } = JSON.parse(decodeURIComponent(atob(storedData)));

    const currentTime = new Date().getTime();
    const oneMinute = 60 * 1000; // milliseconds

    // Check if the last fetch was more than a minute ago
    if (currentTime - timestamp < oneMinute) {
      // Use stored data if less than a minute has passed
      setCustomerPrices(decodedData[0]);
      setXchangeRate(decodedData[1]);
      setCurrencySymbol(decodedData[2]);
      setServicesInfo(decodedData[3]);
      setServicesMainInfo(decodedData[4]);
      setfaqData(decodedData[5]);
    } else {
      // Fetch new data if more than a minute has passed
      FetchPrices(fixedKey).catch(console.error);
    }
  } else {
    // Fetch new data if none is stored
    FetchPrices(fixedKey).catch(console.error);
  }
}, []);

async function FetchPrices(fixedKey) {
  setSpinnerLoading(true);
  let response = await useGetData(`getCustomerPrices`);
  if (response.status === 200) {
    setSpinnerLoading(false);
    setCustomerPrices(response.data[0]);
    setXchangeRate(response.data[1]);
    setCurrencySymbol(response.data[2]);
    setServicesInfo(response.data[3]);
    setServicesMainInfo(response.data[4]);
    setfaqData(response.data[5]);
    setVatInfo(response.data[6]);
    setSignUpStatus(response.data[7]);

    // Store data with current timestamp
    const currentTime = new Date().getTime();
    const storedObject = {
      data: response.data,
      timestamp: currentTime
    };
    const encodedData = btoa(encodeURIComponent(JSON.stringify(storedObject)));
    localStorage.setItem(fixedKey, encodedData);
  }
}



useEffect(() => {
  function setSize() {
    setWindowWith(window.innerWidth);
    setWindowHeight(window.innerHeight);
  
    if(window.innerWidth < 600){

      setMobileIsUsed(true)
    } 

  }



  // Call setSize initially to set the correct state
  setSize();

  // Add the event listener
  window.addEventListener('resize', setSize);

  // Return a cleanup function to remove the event listener when the component unmounts
  return () => {
    window.removeEventListener('resize', setSize);
  };
}, []); // Dependencies

// language settings
const selectedCountry = languages[getCountry];
const selectedError=AppError[localStorage.getItem('country')||'en']

const{
  startButtonText,
  signupButtonText,
  applicationButtonText,
  aiServicesButtonText,
  aboutUsButtonText,
  faqButtonText,
  basicButtonText,
  totalButtonText,
  basicCorporate,
  totalCorporate,
  basicfunctionalityButtonText,
  basicStoryButton,
  pleasefillupFormText,
  aftersignupemailwillbesent,
  accountsetupin24hrs,
  signUpHeaderText,
  firstNameText,
  lastNameText,
  addressText,
  postalCodeText,
  cityText,
  regionText,
  countryText,
  emailText,
  phoneText,
  passwordText,
  confirmPasswordText,
  selectLanguageText,
  selectSwedLangText,
  selectEngLangText,
  selectJapLangText,
  selectCurrencyText,
  selectSekCurrencyText,
  selectUsdCurrencyText,
  selectJapCurrencyText,
  selectServiceText,
  selectBasicServiceText,
  selectTotalServiceText,
  selectBasicCorporateServiceText,
  selectTotalCorporateServiceText,
  allfieldsMandatoryText,
  signUpButtonText,
  applicationBaseServicesHeader,
  applicationBaseVersions,
  applicationBaseVersions1,
  applicationBaseVersions2,
  applicationBaseVersions3,
  applicationBaseVersions4,
  applicationCanusePartly,
  applicationCanBeUsed,
  plusServicesHeaderText,
  plusServicesHeaderText1,
  plusServiceBenaemning,
  plusServicePrice,
  pricesGivenPerMonthText,
  faqHeaderText,
  totalWeTelltext,
  totalFunctionalitytext,
  totalCorporateWeTellText,
  totalCorporateFunctionalityText,
  basicCorporateWeTell,
  basicCorporateFunctionality,
  paypalMainHeaderText,
  pptotalAmountToPayText,
  ppChosenServiceText,
  ppChosenCurrencyText,
  registerAccountTabText,
  paymentTabText,
  ppBakesoftTermsText,
  ppBakesoftTerms,
  accountsetupSlow,
  contactInfoText,
  privacyPolicyText,
  klickOnTextMoreInfo,
  integrityPolicyText,
  contactInformationText,
  buyingTermsText,
  applicationPlusServicesText
}=selectedCountry.info||{};


const{
  passwordsMustBelongText,
  confirmPasswordtext,
  passwordNoMatchText,
  firstNameRequiredText,
  lastNameRequiredText,
  adressRequiredText,
  postalCodeRequiredText,
  cityRequiredText,
  regionRequiredText,
  countryRequiredText,
  phoneRequiredText,
  emailRequiredText,
  languageRequiredText,
  currencyRequiredText,
  serviceRequiredText,
  emailNotValidText,
  accountCreatedSuccessText,
accountAlreadyExistText,
accountCreationFailedText,
paypalPaymentConfirmText,
klarnaPaymentSuccessText,
finalPaymentMessage,
finalPaymentMessageLink,
signUptemporaryClosedText,
klarnaPaymentfailureText,
setupDetailsDeleteFailedText,
accountCreatedNoSuccessText
}=selectedCountry.confirmationMessages||{};








const activeButtonHandler=(id)=>{
  setButtonId(id)

  if(id===2){
    if(getSignUpStatus&&getSignUpStatus.length>0&&getSignUpStatus[0].signUpStatus===1){
      setAlertCode(0)
      setAlertMessage(signUptemporaryClosedText)
      setShowAlert(true)
 
    }
 
}
setIsMenuOpen(false);
}

function getImageUrl(buttonId) {
  switch (buttonId) {
    case 0:
    case 1:
      return cornerbread;
      //letsgetstarted
    case 2:
      return cornerbread;
    case 3:
      return cornerbread;
    
    case 4:
      return cornerbread;
    case 5:
      return cornerbread;
    case 6:
      return cornerbread;
    case 7:
      return  cornerbread;
    case 10:
      return cornerbread;
    case 11:
      return cornerbread;

    default:
      return 'default_image_url'; // Default image if none of the IDs match
  }
}

const RunReportErrHandler = async (errCode) => {
  setSpinnerLoading(true);

    await useReportErr('ErrReport',{errCode});
  setSpinnerLoading(false);
};
const handleCloseAlert = () => {
  setShowAlert(false);
};
useEffect(() => {
  if (getAlertCode > 0) {
    RunReportErrHandler(getAlertCode);
  }
}, [getAlertCode]);



function updateSignupInfo(value, key) {
  const error = validateField(key, value);
  if (error) {
    setAlertCode(56001);
   setAlertMessage(selectedError[56001])
   setShowAlert(true)
      return;
  }
  setSignupInfo(prevState => ({ ...prevState, [key]: value }));

}







function validateField(key, value) {
  const errors = {
      firstName: value.length > 44 ? "Name is too long." : null,
      lastName: value.length > 44 ? "Name is too long." : null,
      address: value.length > 44 ? "Address is too long." : null,
      phone: value.length > 44 ? "Phone number is too long." : null,
      postalCode: value.length > 44 ? "Postal code is too long." : null,
      city: value.length > 44 ? "City is too long." : null,
      region: value.length > 44 ? "Region is too long." : null,
      country: value.length > 44 ? "Country is too long." : null,


      // Add other field validations as needed
  };
  return errors[key] || null;
}

const createAccountRequestHandler = async () => {

    if(getSignupInfo.password.length<6){
    setAlertCode(0)
    setAlertMessage(passwordsMustBelongText)
    setShowAlert(true)
    return;
    } else if(getSignupInfo.confirmPassword.length<6){
    setAlertCode(0)
    setAlertMessage(confirmPasswordtext)
    setShowAlert(true)
    return;

    } else if(getSignupInfo.password !== getSignupInfo.confirmPassword){
    setAlertCode(0)
    setAlertMessage(passwordNoMatchText)
    setShowAlert(true)
    return;
   } else if(getSignupInfo.firstName.length<2){
    setAlertCode(0)
    setAlertMessage(firstNameRequiredText)
    setShowAlert(true)
    return;

   }else if (getSignupInfo.lastName.length<2){
    setAlertCode(0)
    setAlertMessage(lastNameRequiredText)
    setShowAlert(true)
    return;
   }else if (getSignupInfo.address.length<2){
    setAlertCode(0)
    setAlertMessage(adressRequiredText)
    setShowAlert(true)
    return;

    }else if (getSignupInfo.postalCode.length<2){
      setAlertCode(0)
      setAlertMessage(postalCodeRequiredText)
      setShowAlert(true)
      return;
    }else if (getSignupInfo.city.length<2){
      setAlertCode(0)
      setAlertMessage(cityRequiredText)
      setShowAlert(true)
      return;
    }
    
    else if (getSignupInfo.region.length<2){
      setAlertCode(0)
      setAlertMessage(regionRequiredText)
      setShowAlert(true)
      return;
    }
    else if (getSignupInfo.localCountry.length<2){
      setAlertCode(0)
      setAlertMessage(countryRequiredText)
      setShowAlert(true)
      return;
      // email check valid email
    }else if (getSignupInfo.email.length<2){
      setAlertCode(0)
      setAlertMessage(emailRequiredText)
      setShowAlert(true)
      return;
    } else if (!/^\S+@\S+\.\S+$/.test(getSignupInfo.email)) {
      setAlertCode(0)
      setAlertMessage( emailNotValidText)
      setShowAlert(true)
      return
    }

    else if (getSignupInfo.phone.length<2){
      setAlertCode(0)
      setAlertMessage(phoneRequiredText)
      setShowAlert(true)
      return;
    }
    
    else if (getSignupInfo.language===''){
      setAlertCode(0)
      setAlertMessage(languageRequiredText)
      setShowAlert(true)
      return;
    }else if (getSignupInfo.currency===''){
      setAlertCode(0)
      setAlertMessage(currencyRequiredText)
      setShowAlert(true)
      return;
    }else if (getSignupInfo.service===''){
      setAlertCode(0)
      setAlertMessage(serviceRequiredText)
      setShowAlert(true)
      return;
    }
    // setNextTab(2)
    // return;
    SignUpRequest();

}

async function SignUpRequest(){
  setSpinnerLoading(true)
  let apiKey=process.env.REACT_APP_CREATE_USR

try{
  const response=await usePostData1('createUserAccount',{getSignupInfo},
    apiKey
  )
  if(response.status === 200){
    setUserId(response.data.userId)
    setSpinnerLoading(false)
    setAlertCode(0)
    setAlertMessage(accountCreatedSuccessText)
    setShowAlert(true)
    //setSignupInfo([])// for clearing the form
    setNextTab(2)
  }else if(response.status === 201){
    setSpinnerLoading(false)
    setAlertCode(0)
    setAlertMessage(accountAlreadyExistText)
    setShowAlert(true)
  }
}catch(error){
  setSpinnerLoading(false)
  setAlertCode(0)
  setAlertMessage(accountCreationFailedText)
  setShowAlert(true)
}
}

async function CustomerPaymentHandler(userid,serviceid,language,transactionId){
  let apiKey=process.env.REACT_APP_PAYVERIFYKEY
  setSpinnerLoading(true)
  try{
     const response = await usePostData1('paypalPaymentVerification',{userid,serviceid,language,transactionId},
       apiKey
     )
   //   const response = await usePostData('paypalPaymentVerification',{userid,serviceid,language,transactionId})
      if(response.status===200){
          console.log(response)
          setSpinnerLoading(false)
          setAlertCode(0)
          setAlertMessage(paypalPaymentConfirmText)
          setShowAlert(true)
      
      }
  }catch(error){
      setSpinnerLoading(false)
      setAlertCode(53020)
      setAlertMessage(selectedError[53020])
      setShowAlert(true)
  }
}

const jpHandler=()=>{
  setCountry('ja')
  localStorage.setItem('country','ja');
  
  }
  const seHandler=()=>{
  setCountry('se')
  localStorage.setItem('country','se');
  }
  const gbHandler=()=>{
  setCountry('en')
  localStorage.setItem('country', 'en');
  
  }  

  // Klarna payment
    // Function to fetch the Klarna client token from your backend
    const fetchKlarnaPaymentSession = async () => {
      setSpinnerLoading(true)
      let totalSum1 = Math.round(chosenServicePriceWithXchangeRate) // Example value in SEK
      totalSum1=totalSum1*1.25 // +ax
      let totalAmount = Math.round(totalSum1 * 100); // Total amount in minor units (e.g., cents)
      const totalTaxAmount = Math.round((totalAmount * klarnaTaxRate) / (10000 + klarnaTaxRate)); // Correct tax amount calculation
      setKlarnaTotalAmount(totalAmount)
      setKlarnaTotalTaxAmount(totalTaxAmount)
  
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/klarnaPaymentOutside`, {
          method: 'POST',
          headers: {
            'x-api-key':process.env.REACT_APP_KLSESSION,
            'Content-Type': 'application/json',
          },
          // let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/getLatestNews/${getCountry}`, {
          //   headers: {
          //     'x-api-key':process.env.REACT_APP_NEWSKEY// Replace with your actual API key
          //   }
          // });
   
          body: JSON.stringify({  
              "purchase_country":'SE',
              "purchase_currency":'SEK',
              "locale":'sv-SE',
              "order_amount": totalAmount,
              "order_tax_amount": totalTaxAmount,
              "order_lines": [{
                  "name" :serviceName,
                  "quantity" :1,
                  "unit_price" :totalAmount,
                  "tax_rate" : klarnaTaxRate,
                  "total_amount" : totalAmount,
                  "total_discount_amount" : 0,
                  "total_tax_amount" : totalTaxAmount,
                  "merchant_data":'',
              },
        ],
          "billing_address": {
              "given_name":getSignupInfo.firstName,
              "family_name": getSignupInfo.lastName,
              "email":getSignupInfo.email,
              "phone":getSignupInfo.phone,
              "street_address":getSignupInfo.address,
              "postal_code": getSignupInfo.postalCode,
              "city": getSignupInfo.city,
              "region": getSignupInfo.region,
              "country":'SE'
          },
  
        }),
          });
  
        if(response.status===200){
        const data = await response.json();
        setSpinnerLoading(false)
        setClientToken(data.client_token);
        setKlarna(true) // showing  the klarna button
        
        }
  
      } catch (error) {
          setSpinnerLoading(false)
          setAlertCode(53023)
          setAlertMessage(selectedError[53023])
          setShowAlert(true)
  
      }
    };

    useEffect(() => {
      if(getSignupInfo.service){
      let currencyXchangeRate=xchangeRate.find((item)=>item.cc=='se')
      setKlarnaXchangeRate(currencyXchangeRate.xchangeRate)

      let myNewObjArr=[...customerPrices]
      let myNewObjIndex=myNewObjArr.findIndex((item)=>item.serviceid==getSignupInfo.service && item.cc=='se')
      setChosenServicePrice(myNewObjArr[myNewObjIndex].price)
      setServiceName(myNewObjArr[myNewObjIndex].benaemn)
      setChosenServicePriceWithXchangeRate(myNewObjArr[myNewObjIndex].price/currencyXchangeRate.xchangeRate)

      }
    }, [getSignupInfo]);
    

    async function finalizeKlarnaPayment (authorizationToken){
      setSpinnerLoading(true)
      
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/klarnaFinalOutside`, {
          method: 'POST',
          headers: {
              'x-api-key':process.env.REACT_APP_KLFINAL,
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              service_id: getSignupInfo.service, // The service id the user pays for
              user_id: getUserId, // The user id
              country_code:'SE', // The country code of the user
              authorization_token: authorizationToken, // The authorization token
              purchase_country:'SE',
              purchase_currency:'SEK',
              locale: 'sv-SE',
              order_amount:klarnaTotalAmount, // Total order amount in minor units (e.g., öre for SEK)
              order_tax_amount:klarnaTotalTaxAmount, // Total tax amount in minor units
              order_lines: [
                  {
                      name:serviceName, // Name of the service/item
                      quantity: 1, // Quantity of the service/item
                      unit_price:klarnaTotalAmount, // Unit price in minor units
                      tax_rate: klarnaTaxRate, // Tax rate in Klarna's expected format (e.g., 2500 for 25%)
                      total_amount:klarnaTotalAmount, // Total amount for this item in minor units
                      total_discount_amount: 0, // Any discounts applied to this item, in minor units
                      total_tax_amount:klarnaTotalTaxAmount, // Tax amount for this item in minor units
                      merchant_data:'' // Custom merchant data
                  }
              ],
              billing_address: {
                  given_name:getSignupInfo.firstName, // The first name of the customer
                  family_name:getSignupInfo.lastName, // The last name of the customer
                  email: getSignupInfo.email, // The email address of the customer
                  phone: getSignupInfo.phone, // The phone number of the customer
                  street_address: getSignupInfo.address, // The street address of the customer
                  street_address2:'',
                  postal_code:getSignupInfo.postalCode, // The postal code of the customer
                  city:getSignupInfo.city, // The city where the customer resides
                  region:getSignupInfo.region, // The region where the customer resides
                  country:'se', // The country where the customer resides
            
  
  
              }
          }), 
      })
      
      if(response.status === 200){
              setSpinnerLoading(false)
              setAlertCode(0)
              setfinalPay(true)
              setAlertMessage(klarnaPaymentSuccessText)
              setShowAlert(true)
              setKlarna(false)  // hide klarna
              setClientToken(null) // clean up
    
  
           // console.log('Payment completed successfully');
              // Handle success (e.g., show confirmation to user)
          } else {
              setSpinnerLoading(false)
              setAlertCode(53024)
              setAlertMessage(selectedError[53024])
              setShowAlert(true)
  
             // console.error('Payment completion failed', data);
              // Handle error (e.g., show error message to user)
          }
  }

  async function ClearUserfromSystem(){
    console.log('cam here')
    setSpinnerLoading(true)
   let apiKey=process.env.REACT_APP_DELETEUSR
    try{
      const response=await usePostData1('deleteUserAccountInSetup',{getUserId},
        apiKey
      )
      if(response.status === 200){
        setSpinnerLoading(false)
        setAlertCode(0)
        setAlertMessage(accountCreatedNoSuccessText)
        setShowAlert(true)
        //setSignupInfo([])// for clearing the form
        setNextTab(1)
        setServiceTabStatus(false) // status to show the sign up tab again
        
      }
    }catch(error){

      setSpinnerLoading(false)
      setAlertCode(53026)
      setAlertMessage(selectedError[53026])
      setShowAlert(true)
    }
  }



  return (
    <div>
    <MyContext.Provider value={{
      
      getCountry,setCountry,
      faqButtonText:faqButtonText,
      aboutUsButtonText:aboutUsButtonText,
      aiServicesButtonText:aiServicesButtonText,
      applicationButtonText:applicationButtonText,
      signupButtonText:signupButtonText,
      startButtonText:startButtonText,
      basicButtonText:basicButtonText,
      totalButtonText:totalButtonText,
      basicCorporate:basicCorporate,
      totalCorporate:totalCorporate,
      basicfunctionalityButtonText:basicfunctionalityButtonText,
      basicStoryButton:basicStoryButton,
      pleasefillupFormText:pleasefillupFormText,
      aftersignupemailwillbesent: aftersignupemailwillbesent,
      accountsetupin24hrs:accountsetupin24hrs,
      signUpHeaderText:signUpHeaderText,
      firstNameText:firstNameText,
      lastNameText:lastNameText,
      addressText:addressText,
      postalCodeText:postalCodeText,
      cityText:cityText,
      regionText:regionText,
      countryText:countryText,
      emailText:emailText,
      phoneText:phoneText,
      passwordText:passwordText,
      confirmPasswordText:confirmPasswordText,
      selectLanguageText:selectLanguageText,
      selectSwedLangText:selectSwedLangText,
      selectEngLangText:selectEngLangText,
      selectJapLangText:selectJapLangText,
      selectCurrencyText:selectCurrencyText,
      selectSekCurrencyText:selectSekCurrencyText,
      selectUsdCurrencyText:selectUsdCurrencyText,
      selectJapCurrencyText:selectJapCurrencyText,
      selectServiceText:selectServiceText,
      selectBasicServiceText:selectBasicServiceText,
      selectTotalServiceText:selectTotalServiceText,
      selectBasicCorporateServiceText:selectBasicCorporateServiceText,
      selectTotalCorporateServiceText:selectTotalCorporateServiceText,
      allfieldsMandatoryText:allfieldsMandatoryText,
      signUpButtonText:signUpButtonText,
      getImageUrl:getImageUrl,
      buttonId:buttonId,
      applicationBaseServicesHeader:applicationBaseServicesHeader,
      applicationBaseVersions:applicationBaseVersions,
      applicationBaseVersions1:applicationBaseVersions1,
      applicationBaseVersions2:applicationBaseVersions2,
      applicationBaseVersions3:applicationBaseVersions3,
      applicationBaseVersions4:applicationBaseVersions4,
      applicationCanusePartly:applicationCanusePartly,
      applicationCanBeUsed:applicationCanBeUsed,
      jpman1:jpman1,
      jpman2:jpman2,
      jpwoman:jpwoman,
      jpwoman1:jpwoman1,
      jpwoman3:jpwoman3,
    
      ukman1:ukman1,
      ukwoman1:ukwoman1,
      yukiwoman2:yukiwoman2,
      yukiinbakery2:yukiinbakery2,
      customerPrices:customerPrices,
      xchangeRate:xchangeRate,
      currencySymbol:currencySymbol,
      swedman1:swedman1,
      getServicesInfo:getServicesInfo,
      plusServicesHeaderText:plusServicesHeaderText,
      plusServicesHeaderText1:plusServicesHeaderText1,
      plusServiceBenaemning:plusServiceBenaemning,
      plusServicePrice:plusServicePrice,
      pricesGivenPerMonthText:pricesGivenPerMonthText,
      getSignupInfo:getSignupInfo,
      getSpinnerLoading:getSpinnerLoading,
      getServicesMainInfo:getServicesMainInfo,
      faqData:faqData,
      faqHeaderText:faqHeaderText,
      totalWeTelltext:totalWeTelltext,
      totalFunctionalitytext:totalFunctionalitytext,
      totalCorporateWeTellText:totalCorporateWeTellText,
      totalCorporateFunctionalityText:totalCorporateFunctionalityText,
      basicCorporateWeTell:basicCorporateWeTell,
      basicCorporateFunctionality:basicCorporateFunctionality,



  pleasefillupFormText:pleasefillupFormText,
  pleasefillupFormText:pleasefillupFormText,
  aftersignupemailwillbesent:aftersignupemailwillbesent,
  signUpHeaderText:signUpHeaderText,
  getCountry:getCountry,
  firstNameText:firstNameText,
  lastNameText:lastNameText,
  addressText:addressText,
  postalCodeText:postalCodeText,
  cityText:cityText,
  regionText:regionText,
  countryText:countryText,
  emailText:emailText,
  phoneText:phoneText,
  passwordText:passwordText,
  confirmPasswordText:confirmPasswordText,
  selectLanguageText:selectLanguageText,
  selectSwedLangText:selectSwedLangText,
  selectEngLangText:selectEngLangText,
  selectJapLangText:selectJapLangText,
  selectCurrencyText:selectCurrencyText,
  selectSekCurrencyText:selectSekCurrencyText,
  selectUsdCurrencyText:selectUsdCurrencyText,
  selectJapCurrencyText:selectJapCurrencyText,
  selectServiceText:selectServiceText,
  selectBasicServiceText:selectBasicServiceText,
  selectTotalServiceText:selectTotalServiceText,
  selectBasicCorporateServiceText:selectBasicCorporateServiceText,
  getSignupInfo:getSignupInfo,
  updateSignupInfo:updateSignupInfo,
  selectTotalCorporateServiceText:selectTotalCorporateServiceText,
  allfieldsMandatoryText:allfieldsMandatoryText,
  signUpButtonText:signUpButtonText,
  createAccountRequestHandler:createAccountRequestHandler,
  letsgetstarted:letsgetstarted,
  accountsetupin24hrs:accountsetupin24hrs,
  updateSignupInfo:updateSignupInfo,
  getNextTab:getNextTab,
  getUserId:getUserId,
  paypalMainHeaderText:paypalMainHeaderText,
  pptotalAmountToPayText:pptotalAmountToPayText,
  ppChosenServiceText:ppChosenServiceText,
  ppChosenCurrencyText:ppChosenCurrencyText,
  registerAccountTabText:registerAccountTabText,
  paymentTabText:paymentTabText,
  ppBakesoftTermsText:ppBakesoftTermsText,
  ppBakesoftTerms:ppBakesoftTerms,
  setAlertMessage:setAlertMessage,
  setShowAlert:setShowAlert,
  paypalPaymentConfirmText:paypalPaymentConfirmText,
  CustomerPaymentHandler:CustomerPaymentHandler,
  getVatInfo:getVatInfo,
  accountsetupSlow:accountsetupSlow,
  klarnaimage:klarnaimage,
  fetchKlarnaPaymentSession:fetchKlarnaPaymentSession,
  // setChosenServicePrice:setChosenServicePrice,
  // setServiceName:setServiceName,
  // customerPrices:customerPrices,
  clientToken:clientToken,
  klarnaTotalAmount:klarnaTotalAmount,
  klarnaTotalTaxAmount:klarnaTotalTaxAmount,
  chosenServicePrice:chosenServicePrice,
  serviceName:serviceName,
  chosenServicePriceWithXchangeRate:chosenServicePriceWithXchangeRate,
  klarnaTaxRate:klarnaTaxRate,
finalizeKlarnaPayment:finalizeKlarnaPayment,
setKlarna:setKlarna,
setAlertCode:setAlertCode,
klarna:klarna,
finalPay:finalPay,
finalPaymentMessage:finalPaymentMessage,
finalPaymentMessageLink:finalPaymentMessageLink,
klickOnTextMoreInfo:klickOnTextMoreInfo,
integrityPolicyText:integrityPolicyText,
contactInformationText:contactInformationText,
klarnaPaymentfailureText:klarnaPaymentfailureText,
getCountry:getCountry,
setCountry:setCountry,
ClearUserfromSystem:ClearUserfromSystem,
getServiceTabStatus:getServiceTabStatus,
startButtonText:startButtonText,
signupButtonText:signupButtonText,
applicationButtonText:applicationButtonText,
aiServicesButtonText:aiServicesButtonText,
faqButtonText:faqButtonText,
aboutUsButtonText:aboutUsButtonText,
activeButtonHandler:activeButtonHandler,
    contactInfoText:contactInfoText,
    privacyPolicyText:privacyPolicyText,
    buyingTermsText:buyingTermsText,



    
   }}>
    <div className="infototWrapper">
      <div className="infototalArea">

       {mobileIsUsed===true?
        <div className="infoA"></div>
        :null}

{mobileIsUsed===true?
      <SlidingMenu isOpen={isMenuOpen} toggleMenu={toggleMenu}
      seHandler={seHandler}
      jpHandler={jpHandler}
      gbHandler={gbHandler}
      isPartiallyOpen={isPartiallyOpen}
      />
      :null}
      
 
 {!mobileIsUsed?(
         <div className="infoA">
          <div className="infoButtonTopWrapper">
                <button className={`${getCountry !== 'ja' ? "infoButton" : "infoButtonJAP"} ${buttonId === 1 ? "activeButton" : ""}`} onClick={() => activeButtonHandler(1)}><FaHome size={getIconSize} />&nbsp;{startButtonText}</button>
              
                <button className={`${getCountry !== 'ja' ? "infoButton" : "infoButtonJAP"} ${buttonId === 2 ? "activeButton" : ""}`} onClick={() => activeButtonHandler(2)}><FaRegNewspaper size={getIconSize} />&nbsp;{signupButtonText}</button>
             
                <button className={`${getCountry !== 'ja' ? "infoButton" : "infoButtonJAP"} ${buttonId === 3 ? "activeButton" : ""}`} onClick={() => activeButtonHandler(3)}><FaConciergeBell size={getIconSize} />&nbsp;{applicationButtonText}</button>
                <button className={`${getCountry !== 'ja' ? "infoButton" : "infoButtonJAP"} ${buttonId === 4 ? "activeButton" : ""}`} onClick={() => activeButtonHandler(4)}><FaRobot size={getIconSize} />&nbsp;{aiServicesButtonText}</button>
                <button className={`${getCountry !== 'ja' ? "infoButton" : "infoButtonJAP"} ${buttonId === 7 ? "activeButton" : ""}`} onClick={() => activeButtonHandler(7)}><FaPlus size={getIconSize} />&nbsp;{applicationPlusServicesText}</button>
                <button className={`${getCountry !== 'ja' ? "infoButton" : "infoButtonJAP"} ${buttonId === 12 ? "activeButton" : ""}`} onClick={() => activeButtonHandler(12)}><FaFileContract size={getIconSize} />&nbsp;{buyingTermsText}</button>
            
                <button className={`${getCountry !== 'ja' ? "infoButton" : "infoButtonJAP"} ${buttonId === 5 ? "activeButton" : ""}`} onClick={() => activeButtonHandler(5)}> <FaInfoCircle size={getIconSize} />&nbsp;{aboutUsButtonText}</button>
                <button className={`${getCountry !== 'ja' ? "infoButton" : "infoButtonJAP"} ${buttonId === 6 ? "activeButton" : ""}`} onClick={() => activeButtonHandler(6)}><FaQuestionCircle size={getIconSize} />&nbsp;{faqButtonText}</button>
                <button className={`${getCountry !== 'ja' ? "infoButton" : "infoButtonJAP"} ${buttonId === 11 ? "activeButton" : ""}`} onClick={() => activeButtonHandler(11)}><FaAddressBook size={getIconSize} />&nbsp;{contactInfoText}</button>
                <button className={`${getCountry !== 'ja' ? "infoButton" : "infoButtonJAP"} ${buttonId === 10 ? "activeButton" : ""}`} onClick={() => activeButtonHandler(10)}><FaShieldAlt size={getIconSize} />&nbsp;{privacyPolicyText}</button>
               
                <button className={`${getCountry !== 'ja' ? "infoButton" : "infoButtonJAP"} ${buttonId === 8 ? "activeButton" : ""}`} onClick={() => activeButtonHandler(8)}><FaSignInAlt size={getIconSize} />&nbsp;Login</button>
   

              <div className="infoCountryFlagWrapper"> 
              <SE title="Swedish" className="xzloginflagse" onClick={seHandler} />
              <JP title="Japanese" className="xzloginflagJapan"  onClick={jpHandler}/>
              <GB title="English" className="xzloginflaggb" onClick={gbHandler} />
              </div>

              <div className="infoPaymentWrapper">

<div className="infoPaymentKlarna">
<klarna-placement
data-key="top-strip-promotion-auto-size"
data-locale="sv-SE"
></klarna-placement>

</div>  

 <div className="infoPaymentPayPal">
 <table border="0" cellPadding="10" cellSpacing="0" align="center">
   <tr>
      <td align="center"></td>
   </tr>
      <tr>
        <td align="center">
<a href="https://www.paypal.com/webapps/mpp/paypal-popup" title="How PayPal Works" 
target="_blank" rel="noopener noreferrer" style={{ display: 'block' }}>
<img src="https://www.paypalobjects.com/webstatic/mktg/logo/bdg_now_accepting_pp_2line_w.png" 
border="0" alt="Now accepting PayPal" /></a>
< div style={{ textAlign: 'center', marginTop: '10px' }}>

    <a href="https://www.paypal.com/webapps/mpp/pay-online" 
    style={{ fontSize: '12px', color: '#0079CD', fontFamily: 'Arial', textDecoration: 'none' }}>
    <strong>How PayPal Works</strong></a>
</div>
</td>
</tr>
</table>

</div>
</div>


            

          </div>
        </div> 
        ):null}

 
          {buttonId===0&& getCountry==='se'?<InfoStartSwedish/>:null}
          {buttonId===0&& getCountry==='en'?<InfoStartEnglish/>:null}
          {buttonId===0&& getCountry==='ja'?<InfoStartJapanese/>:null}

          {buttonId===1 || buttonId===2?
        <div className="infoB" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>

          {buttonId===1 && getCountry==='se'?<InfoStartSwedish/>:null}
          {buttonId===1 && getCountry==='en'?<InfoStartEnglish/>:null}
          {buttonId===1 && getCountry==='ja'?<InfoStartJapanese/>:null}

       
          
          {/* {getSignUpStatus&&getSignUpStatus.length>0&&getSignUpStatus[0].signUpStatus===0&&buttonId===2?<SignUpTop
          />:null}
          </div>:null} */}
                 {buttonId===2?<SignUpTop />:null}

          </div>:null}
          
      
          {buttonId===3?<BaseServices/>:null}
    



          {buttonId===4 && getCountry==='se'?<AIServicesSwedish/>:null}
          {buttonId===4 && getCountry==='en'?<AIServicesEnglish/>:null}
          {buttonId===4 && getCountry==='ja'?<AIServicesJapanese/>:null}
        
          
          {buttonId===5 && getCountry==='se'?<AboutUsSwedish/>:null}
          {buttonId===5 && getCountry==='en'?<AboutUsEnglish/>:null}
          {buttonId===5 && getCountry==='ja'?<AboutUsJapanese/>:null}
          {buttonId===6?<Faq/>:null}
          {buttonId===7?<PlusServices/>:null}

          {buttonId===8? navigate('/login'):null}
          {buttonId===10?<IntegrityPolicy/>:null}
          {buttonId===11?<ContactInfo/>:null}
      
          {getCountry==='se' && buttonId===12?<BuyingTerms/>:null}
          {getCountry==='en' && buttonId===12?<BuyingTermsENG/>:null}
          {getCountry==='ja' && buttonId===12?<BuyingTermsJAP/>:null}
       

         

  </div>
</div>





  </MyContext.Provider>
  <div className="infoSpinnerContainer">
        <div className="infoSpinner">
          <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="0.75" width="60" visible={getSpinnerLoading}/>
        </div>
        </div>
  
  {getShowAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}/>:''}
  </div>
  )
}

function BaseServices(){
let {basicButtonText,totalButtonText,basicCorporate,totalCorporate}=useContext(MyContext)
  const[getServiceTab,setServiceTab]=useState(0)
  return(
    <div className="baseServicesTop">
    <div className="baseServicesTabTop">
    <button className={`baseServicesmobileTab ${getServiceTab===1?'active':''}`}
    onClick={()=>setServiceTab(1)}>{basicButtonText}</button>
    <button className={`baseServicesmobileTab ${getServiceTab===2?'active':''}`}
    onClick={()=>setServiceTab(2)}>{totalButtonText}</button>
    <button className={`baseServicesmobileTab ${getServiceTab===3?'active':''}`}
    onClick={()=>setServiceTab(3)}>{basicCorporate}</button>
    <button className={`baseServicesmobileTab ${getServiceTab===4?'active':''}`}
    onClick={()=>setServiceTab(4)}>{totalCorporate}</button>


</div>

    <div className="baseServicesTabContent">
  
    {getServiceTab===0 ? <BsServices/> : ''}
    {getServiceTab===1 ? <BasicTop/> : ''}
    {getServiceTab===2 ? <TotalTop/> : ''}
    {getServiceTab===4 ? <CorporateTotalTop/> : ''}
    {getServiceTab===3 ? <CorporateBasicTop/> : ''}


    </div></div>
  )
}
function BasicInfo(){

}


function BasicTop(){
  const {getCountry,setCountry,basicfunctionalityButtonText,basicStoryButton}=useContext(MyContext)
  const[getServiceTab,setServiceTab]=useState(1)

 return(
    <div className="baseServicesTop">
    <div className="baseServicesTabTop">
    <button className={`baseServicesmobileTab ${getServiceTab===1?'active':''}`}
    onClick={()=>setServiceTab(1)}>{basicStoryButton}</button>
    <button className={`baseServicesmobileTab ${getServiceTab===2?'active':''}`}
    onClick={()=>setServiceTab(2)}>{basicfunctionalityButtonText}</button>

</div>

    <div className="baseServicesTabContent">
    {getServiceTab===2 && getCountry==='se' ?<BasicInfoListSwedish/> : ''}
    {getServiceTab===2 && getCountry==='en' ?<BasicInfoListEnglish/> : ''}
    {getServiceTab===2 && getCountry==='ja' ?<BasicInfoListJapanese/> : ''}

    {getServiceTab===1 && getCountry=='se'? <BasicInfoTextSwedish/> : ''}
    {getServiceTab===1 && getCountry=='en'? <BasicInfoTextEnglish/> : ''}
    {getServiceTab===1 && getCountry=='ja'? <BasicInfoTextJapanese/> : ''}
    
    
 


    </div></div>
 )

}

function BasicInfoTextSwedish(){
let {getImageUrl,buttonId}=useContext(MyContext)

  return(
//     <div className="basicServicesWrapper">
//       <div className="infoBEnglish" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
//           <div className="transparentBoxBasText">
//       <div className="basicServicesCommentText">
//       <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Grund funktionalitet och funktioner</div>
//         <hr className="basicServicesHr"></hr>
//       Bas erbjuder nyckel funktioner som att skapa recept med AI, skapa
//     recept manuellt med utgångspunkt på recept och ingredienser.
//     Att börja ett recept med ett annat recept, kortar ner tiden det tar att skapa ett nytt recept.<br></br>
//      Om du vill göra en ny variant av en cheesecake,tar du ett befintligt recept,du
//      gör dina ändringar och allt är klart.Bakesoft räknar automatiskt kostnad mängd och
//      nutrienter som kalorier,fett, protein m.m.<p></p>
//      <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Förstora elle förminska</div>
//      <hr className="basicServicesHr"></hr>
//      Förminska och förstora recept med ett klick. Detta öppnar
//      upp möjlighet att förändra direkt under arbetet och att anpassa storleken i olika recept till varandra.<p></p>
//      <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Portioner</div>
//      <hr className="basicServicesHr"></hr>
//      Du kan även portionsindela dina recept,detta är effektivt speciellt
//      när det gäller att skapa en ny produkt. Då en produkt alltid gäller en exakt utvald
//      mängd.<br></br><p></p>
//      <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Skapande av produkter</div>
//      <hr className="basicServicesHr"></hr>
//      Vid skapandet av en produkt kombinerar du recept och ingredienser och detta gör det oerhört enkelt och spännande
//       att produktifiera
//      dina recept. Du kan ta en del av en deg, en del av ytterligare ett recept och lägga till en klick av en annan ingrediens
//      och bakesoft hanterar allt i bakgrunden. Du ger produkten ett namn och sparar så är allt klart.<br></br><p></p> I prislistan sätter du priset
//      och du kan direkt se den marginal(netto vinst) du har på din nyskapade produkt.När du sammanför olika recept och ingredienser i en produkt så gör
//      programvaran alla kalkyleringar i bakgrunden.
//      <p></p> 
//      <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Integritet</div>
//      <hr className="basicServicesHr"></hr>
//      Viktigt att tillägga här är att dina recept & produkter är dina, databasens uppbyggnad gör det inte
//      möjligt att dela dina recept med andra i vår applikation, vi skyddar dina recept och din integritet genom att ge dig
//      ditt eget system som inte kan kopieras av andra.Varje recept eller produkt du skapar är unik i vårt system.
//      När vi skapar ditt konto genereras tusentals av rader in i vårt system som är unika för dig,dina recept
//      och produkter.
//      <br></br><p></p>
//      <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Återskapa värden</div>
//      <hr className="basicServicesHr"></hr>
//      När du ändrar inköpspris på en ingrediens, ändras<br></br> priset på alla recept och
//      produkter direkt. Detta spar<br></br> oerhört mycket tid, om du har 100 recept och lika många produkter
//       och alla
//      innehåller socker eller mjölk, så behöver du inte räkna om dina recept manuellt. Bakesoft hanterar detta 
//      i bakgrunden inklusive alla beräkningar som
//      gäller nutrienter. <br></br><p></p>
//      <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Byt många med en</div>
//      <hr className="basicServicesHr"></hr>
//      På ingredienser kan du även ersätta ingrediens med en annan. Detta betyder att om du tex
//      använder en typ av smör i 50 recept, du kan då ersätta en ingrediens med en annan i samtliga
//      dina recept på en gång, man väljer ingrediens, och de recept som ska
//      beröras och med ett klick så är allt uppdaterat.
//      <br></br>
//      <br></br>
//      <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Kategorier</div>
//      <hr className="basicServicesHr"></hr>
//      Då det som oftast blir många recept & ingredienser så indelar du dessa i kategorier med vår kategori
//      funktion som hjälper dig att skapa ändra och ta bort kategorier på ett enkelt sätt. Du får också en bra översikt
//      och du kan flytta ett eller flera recept till en annan kategori på en gång.
//      <br></br>
//      <br></br>
//      <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Av & På</div>
//      <hr className="basicServicesHr"></hr>
//      Ingredienser och recept har en list funktion, där du kan gå in i din receptlista och
//      aktivera samt avvaktivera ingredienser och recept. Detta är speciellt användbart om
//      du har många recept och säsongsvariationer då kan du enkelt aktivera recept och
//      ingredienser inför en kommande säsong.<br></br><p></p>
//      <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Skapa med vår data och AI</div>
//      <hr className="basicServicesHr"></hr>
//     Skapa nya recept med AI är en spännande miljö att arbeta i, oavsett om man med vår databas<br></br>
//     och AI tillsammans får nya recept beroende på vad man söker efter så utgör det en källa
//     till inspiration och utveckling, där man kan får nya ideer och uppslag. Vi matchar de förslagna receptens
//     ingredienser emot de ingredienser du har i din ingrediens lista och de ingredienser du inte har
//     lägger du enkelt till. <br></br><p></p>
//     <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Ingrediens information</div>
//     <hr className="basicServicesHr"></hr>
//     Ingrediens information ger dig möjlighet att fördjupa dina kunskaper om en ingrediens. Du kanske undrar vart den 
//     kommer ifrån osv. Ingrediens information ger dig möjlighet att snabbt få mer information om just
//     den ingrediens du undrar över. <br></br><p></p>
//     <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Nya idéer</div>
//     <hr className="basicServicesHr"></hr>
//     Recept idéer är en funktion som hjälper dig att inhämta nya idéer in i din verksamhet. Här söker man på mer generella
//     termer som tex pasta med kyckling eller mjuka kakor med choklad eller liknande, och får då förslag på recept som matchar denna sökning.
//     Genom att välja ett recept i listan så flyttas du till AI recept sökning som gör en sökning för dig på 
//     denna nya idé och du får på kort tid ett helt nytt recept som utgångspunkt att arbeta med. PÅ detta sätt kan du snabbt
//     utöka din receptbank, du kan ändra och uppdatera utan att byta miljö eller programvara. <br></br><p></p>
//     <br></br><p></p>
//     <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Nutrienter inkluderade</div>
//     <hr className="basicServicesHr"></hr>
//     Nutrient funktionen är en viktig del i bakesoft och möjliggör för dig att kunna se exakt vad ditt recept innehåller
//     i form av kalorier, fett, protein m.m. Uppdateringer på dina ingredienser gör du igenom att hämta extern information
//     i databaser vi har kontakt med i bakgrunden. I de fall du inte automatiskt kan hämta information så kan du själv enkelt
//     uppdatera denna information i applikationen. <br></br><p></p>
//     <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Konvertering</div>
//     <hr className="basicServicesHr"></hr>
//     Konverterings funktionen hjälper dig att konvertera volym till mängd. Då bakesoft måste kunna kalkylera exakta tal 
//     använder inte Bakesoft volym mått som deciliter eller matskedar utan allt är i gram. Detta är en stor fördel då
//     det hjälper dig att återskapa exakt samma recept varje gång. Konverteringen hjälper dig att konvertera tex
//     en deciliter mjöl till gram. <br></br><p></p>
//     <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Bild funktionalitet</div>
//     <hr className="basicServicesHr"></hr>
//     Vi har flera bildfunktioner så att du kan ladda upp bilder och generera bilder med hjälp av AI. För uppladdning av bilder
//     väljer du enkelt receptet och laddar upp bilden. För generering av bilder med AI så väljer du receptet och AI genererar
//     en bild utifrån den text du skriver in. Att generera bilder med AI kräver ett extra abonnemang och är inte inkluderat i
//     vår bas prenumeration.<br></br><p></p> 
//     <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Utökad funktionalitet</div>
//     <hr className="basicServicesHr"></hr>
//     Du kan enkelt aktivera extra funktioner igenom att gå till mitt konto och under tjänster
//     välja den funktion du vill ha. När du valt funktion så skickas en faktura till dig, du betalar enkelt i applikationen
//     under mitt konto, och då aktiveras funktionen direkt.  <br></br><p></p>
//     <p></p>
//     <p></p>
//     <p></p>
// </div>
//     </div>
//   </div>
//   </div>
  
<div className="basicServicesWrapper">
  <div className="infoBEnglish" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
    <div className="transparentBoxBasText">
      <div className="basicServicesCommentText">

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Grundfunktioner
        </div>
        <hr className="basicServicesHr"/>
        Bas erbjuder funktioner som AI-stödd receptskapande och manuell receptinmatning. Du kan enkelt skapa nya varianter av recept och Bakesoft räknar automatiskt kostnad, mängd och näringsvärden som kalorier, fett och protein.
        <p></p>

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Förstora eller förminska
        </div>
        <hr className="basicServicesHr"/>
        Ändra receptstorlekar med ett klick för att anpassa direkt under arbetets gång.
        <p></p>

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Portioner
        </div>
        <hr className="basicServicesHr"/>
        Dela upp recept i portioner, särskilt användbart för att skapa exakta produktmängder.
        <p></p>

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Produktutveckling
        </div>
        <hr className="basicServicesHr"/>
        Kombinera recept och ingredienser enkelt för att skapa nya produkter. I prislistan sätter du priset och ser din vinstmarginal direkt.
        <p></p>

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Integritet
        </div>
        <hr className="basicServicesHr"/>
        Dina recept och produkter är skyddade och unika för dig, utan möjlighet till delning i systemet.
        <p></p>

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Automatiska uppdateringar
        </div>
        <hr className="basicServicesHr"/>
        När du ändrar en ingredienspris, uppdateras alla recept och produkter automatiskt.
        <p></p>

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Byt ingredienser
        </div>
        <hr className="basicServicesHr"/>
        Byt ut ingredienser i flera recept på en gång med ett klick.
        <p></p>

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Kategorier
        </div>
        <hr className="basicServicesHr"/>
        Organisera dina recept och ingredienser i kategorier för enklare hantering.
        <p></p>

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Av & På
        </div>
        <hr className="basicServicesHr"/>
        Aktivera eller inaktivera recept och ingredienser, perfekt för säsongsvariationer.
        <p></p>

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> AI-receptskapande
        </div>
        <hr className="basicServicesHr"/>
        Skapa nya recept med AI, baserat på dina ingredienser.
        <p></p>

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Ingrediensinformation
        </div>
        <hr className="basicServicesHr"/>
        Få detaljerad information om ingredienser och deras ursprung.
        <p></p>

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Nya receptidéer
        </div>
        <hr className="basicServicesHr"/>
        Sök efter generella receptidéer och få AI-genererade förslag snabbt.
        <p></p>

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Näringsinformation
        </div>
        <hr className="basicServicesHr"/>
        Se exakt näringsinnehåll i dina recept och uppdatera ingredienser automatiskt.
        <p></p>

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Konvertering
        </div>
        <hr className="basicServicesHr"/>
        Konvertera volymmått till vikt, för exakta recept.
        <p></p>

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Bildfunktionalitet
        </div>
        <hr className="basicServicesHr"/>
        Ladda upp eller skapa bilder med AI (AI-bilder kräver ett extra abonnemang).
        <p></p>

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Utökad funktionalitet
        </div>
        <hr className="basicServicesHr"/>
        Aktivera fler funktioner via ditt konto, med omedelbar tillgång efter betalning.
        <p></p>
      </div>
    </div>
  </div>
</div>

  
 
  )
}
function BasicInfoTextEnglish(){
  let {getImageUrl,buttonId}=useContext(MyContext)
  return(

<div className="basicServicesWrapper">
  <div className="basicServicesText">
    <div className="infoBEnglish" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
      <div className="transparentBoxBasText">
        <div className="basicServicesCommentText">

          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Basic Functionality and Features
          </div>
          <hr className="basicServicesHr"/>
          Basic offers key features like AI-driven recipe creation, manual recipe creation, and automatic cost and nutrient calculations (calories, fat, protein). You can quickly make changes to existing recipes and resize them with one click.<p></p>

          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Product Creation
          </div>
          <hr className="basicServicesHr"/>
          Combine recipes and ingredients to create new products. Set prices and see margins instantly, with all calculations handled in the background.<p></p>

          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Privacy
          </div>
          <hr className="basicServicesHr"/>
          Your recipes are private and cannot be shared. Each recipe is unique to your account.<p></p>

          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Recalculate Values
          </div>
          <hr className="basicServicesHr"/>
          Changing the price of an ingredient updates all recipes and products instantly.<p></p>

          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Replace Ingredients
          </div>
          <hr className="basicServicesHr"/>
          Replace one ingredient across all recipes with a single action.<p></p>

          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Categories
          </div>
          <hr className="basicServicesHr"/>
          Organize your recipes and ingredients into categories for easy management.<p></p>

          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Activate & Deactivate
          </div>
          <hr className="basicServicesHr"/>
          Easily activate or deactivate recipes and ingredients, ideal for seasonal adjustments.<p></p>

          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> AI Recipe Creation
          </div>
          <hr className="basicServicesHr"/>
          Use AI to create new recipes and get inspired by suggested ingredients.<p></p>

          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Ingredient Information
          </div>
          <hr className="basicServicesHr"/>
          Get detailed information about ingredients to deepen your knowledge.<p></p>

          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Nutrient Function
          </div>
          <hr className="basicServicesHr"/>
          View detailed nutrient information (calories, fat, protein) for each recipe, automatically updated.<p></p>

          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Conversion
          </div>
          <hr className="basicServicesHr"/>
          Convert volume to weight (e.g., deciliters to grams) for precise recipes.<p></p>

          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Image Functionality
          </div>
          <hr className="basicServicesHr"/>
          Upload or generate images with AI. AI-generated images require an additional subscription.<p></p>

          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Extended Functionality
          </div>
          <hr className="basicServicesHr"/>
          Easily activate extra features via your account, with instant access after payment.<p></p>

        </div>
      </div>
    </div>
  </div>
</div>





  )
}
function BasicInfoTextJapanese(){
  let {getImageUrl,buttonId}=useContext(MyContext)
  return(
//     <div className="basicServicesWrapper">
//     <div className="basicServicesText">
//     <div className="infoBEnglish" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
//     <div className="transparentBoxBasText">

//     <div className="basicServicesCommentText">
//     <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>基本的な主な機能と特徴</div>
// <hr className="basicServicesHr"></hr>
// Basicは、AIでレシピを作成する機能、レシピと食材に基づいて手動でレシピを作成する機能、そして通常の一般的なレシピ作成方法である食材だけでなく、レシピを作成するための基本的な機能を提供します。
// <br></br><p></p>
// 特に重要なのは、レシピのコストを計算する自動機能です。経済が重要な要素である環境では、使用するレシピが経済的に持続可能であることが最も重要です。
// <br></br><p></p>
// 別のレシピを使って新しいレシピを始めることで、新しいレシピを作成する時間を短縮できます。新しいチーズケーキのバリエーションを作りたい場合は、既存のレシピを取り、変更を加えるだけですべてが完了します。Bakesoftは、コスト、量、カロリー、脂肪、タンパク質などの栄養素を自動的に計算します。
// <p></p>
// <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>増減</div>
// <hr className="basicServicesHr"></hr>
// クリック一つでレシピを拡大縮小できます。これにより、作業中に直接変更し、異なるレシピのサイズを調整することが可能になります。
// <p></p>
// <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>ポーション</div>
// <hr className="basicServicesHr"></hr>
// レシピをポーションに分けることもできます。これは、新しい製品を作成する際に特に効果的です。製品は常に正確に選ばれた量に基づいているからです。
// <br></br><p></p>
// <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>製品の作成</div>
// <hr className="basicServicesHr"></hr>
// 製品を作成する際に、レシピと食材を組み合わせることで、レシピを製品化するのが非常に簡単でエキサイティングになります。生地の一部、別のレシピの一部、さらに別の食材の一部を取り入れ、Bakesoftがすべてをバックグラウンドで処理します。製品に名前を付けて保存すると、すべてが完了します。
// <br></br><p></p>
// 価格リストでは、価格を設定し、新しく作成した製品の利益率（純利益）をすぐに確認できます。異なるレシピと食材を製品に組み合わせると、ソフトウェアがすべての計算をバックグラウンドで実行します。
// <p></p>
// <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>インテグリティ</div>
// <hr className="basicServicesHr"></hr>
// ここで重要なのは、あなたのレシピと製品はあなたのものであり、データベースの構造上、他の人と共有することができないということです。私たちは、あなたのレシピとプライバシーを保護し、他の人がコピーできない独自のシステムを提供します。あなたが作成するすべてのレシピや製品は、私たちのシステム内でユニークです。アカウントを作成する際に、あなた専用の何千もの行がシステムに生成されます。
// <br></br><p></p>
// <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>価値の再作成</div>
// <hr className="basicServicesHr"></hr>
// 食材の購入価格を変更すると、すべてのレシピと製品の価格が即座に変更されます。100のレシピと同じ数の製品があり、それらすべてに砂糖や牛乳が含まれている場合、手動でレシピを再計算する必要はありません。Bakesoftは、背景でこれを処理し、栄養素に関するすべての計算を行います。
// <br></br><p></p>
// <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>多くを一つに置き換える</div>
// <hr className="basicServicesHr"></hr>
// 食材についても、ある食材を別の食材に置き換えることができます。例えば、50のレシピで一種類のバターを使用している場合、すべてのレシピで一度に食材を置き換えることができます。食材を選択し、影響を受けるレシピを選び、クリック一つですべてが更新されます。
// <br></br>
// <br></br>
// <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>カテゴリ</div>
// <hr className="basicServicesHr"></hr>
// レシピや食材が多くなることが多いため、カテゴリ機能を使用してこれらをカテゴリ分けすることができます。これにより、カテゴリを簡単に作成、変更、削除することができ、良好な概要を得ることができます。また、一つまたは複数のレシピを一度に別のカテゴリに移動することもできます。
// <br></br>
// <br></br>
// <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>オンとオフ</div>
// <hr className="basicServicesHr"></hr>
// 食材とレシピにはリスト機能があり、レシピリストに入り、食材とレシピを有効化および無効化することができます。これは、特に多くのレシピや季節のバリエーションがある場合に便利で、次の季節に向けてレシピや食材を簡単に有効化できます。
// <br></br><p></p>
// <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>私たちのデータとAIで作成</div>
// <hr className="basicServicesHr"></hr>
// AIで新しいレシピを作成するのは、非常にエキサイティングな環境です。データベースとAIを組み合わせて、新しいレシピを探すことで、新しいアイデアや提案を得ることができます。提案されたレシピの食材を、あなたの食材リストの食材と照合し、持っていない食材は簡単に追加できます。
// <br></br><p></p>
// <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>食材情報</div>
// <hr className="basicServicesHr"></hr>
// 食材情報は、食材に関する知識を深めることができます。その食材の出身地などについて疑問がある場合に、すぐに情報を得ることができます。
// <br></br><p></p>
// <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>アイデアの生成</div>
// <hr className="basicServicesHr"></hr>
// レシピアイデアは、新しいアイデアをビジネスに取り入れるのに役立つ機能です。例えば、「鶏肉のパスタ」や「チョコレートの柔らかいケーキ」などの一般的な用語で検索し、その検索に一致するレシピの提案を得ることができます。リストのレシピを選択すると、AIレシピ検索に移動し、新しいアイデアに基づいた検索を行い、すぐに新しいレシピを得ることができます。この方法で、レシピバンクを迅速に拡張し、環境やソフトウェアを切り替えることなく、変更や更新を行うことができます。
// <br></br><p></p>
// <br></br><p></p>
// <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>栄養素を含む</div>
// <hr className="basicServicesHr"></hr>
// 栄養素機能はBakesoftの重要な部分であり、レシピに含まれるカロリー、脂肪、タンパク質などを正確に確認できます。食材の情報を外部データベースから取得して更新します。自動的に情報を取得できない場合は、アプリケーション内で簡単に情報を更新できます。
// <br></br><p></p>
// <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>変換</div>
// <hr className="basicServicesHr"></hr>
// 変換機能は、ボリュームを重量に変換するのに役立ちます。Bakesoftは正確な数値を計算する必要があるため、デシリットルや大さじのようなボリューム測定を使用せず、すべてグラムで表されます。これにより、毎回同じレシピを正確に再現することができます。変換機能は、例えば、デシリットル

// の小麦粉をグラムに変換するのに役立ちます。
// <br></br><p></p>
// <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>画像機能</div>
// <hr className="basicServicesHr"></hr>
// 複数の画像機能があり、画像をアップロードしたり、AIを使用して画像を生成したりすることができます。画像をアップロードする場合は、レシピを選択し、画像をアップロードします。AIで画像を生成する場合は、レシピを選択し、入力したテキストに基づいてAIが画像を生成します。AIでの画像生成には追加のサブスクリプションが必要で、基本サブスクリプションには含まれていません。
// <br></br><p></p>
// <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>拡張機能</div>
// <hr className="basicServicesHr"></hr>
// 追加機能を有効にするには、マイアカウントに移動し、サービスの下で希望する機能を選択するだけです。機能を選択すると、請求書が送信され、マイアカウントで簡単に支払い、機能が即座に有効化されます。
// <br></br><p></p>
// <p></p>
// <p></p>
// <p></p>

// </div>
// </div>
// </div>
// </div>
// </div>

<div className="basicServicesWrapper">
  <div className="basicServicesText">
    <div className="infoBEnglish" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
      <div className="transparentBoxBasText">
        <div className="basicServicesCommentText">

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> 基本的な機能と特徴
          </div>
          <hr className="basicServicesHr"/>
          Basicでは、AIや手動でレシピ作成ができ、コスト計算や栄養素の計算も自動化されています。既存のレシピを使って新しいレシピを簡単に作成できます。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> 増減
          </div>
          <hr className="basicServicesHr"/>
          クリック一つでレシピのサイズを変更できます。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> ポーション
          </div>
          <hr className="basicServicesHr"/>
          レシピをポーション分けして、新しい製品を作成するのに便利です。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> 製品の作成
          </div>
          <hr className="basicServicesHr"/>
          レシピと食材を組み合わせて製品を簡単に作成できます。価格リストで利益率もすぐに確認可能です。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> インテグリティ
          </div>
          <hr className="basicServicesHr"/>
          あなたのレシピと製品はシステム内で保護されており、他の人と共有することはできません。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> 価値の再計算
          </div>
          <hr className="basicServicesHr"/>
          食材価格を変更すると、すべてのレシピが自動で更新されます。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> 一括置換
          </div>
          <hr className="basicServicesHr"/>
          一つの食材を全レシピで一括置換可能です。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> カテゴリ
          </div>
          <hr className="basicServicesHr"/>
          カテゴリ機能でレシピや食材を簡単に整理できます。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> オンとオフ
          </div>
          <hr className="basicServicesHr"/>
          レシピや食材を有効・無効化する機能で、季節ごとの調整が簡単です。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> AIでのレシピ作成
          </div>
          <hr className="basicServicesHr"/>
          AIとデータベースを使って、新しいレシピやアイデアを簡単に生成できます。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> 食材情報
          </div>
          <hr className="basicServicesHr"/>
          食材に関する詳細情報をすぐに確認できます。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> レシピアイデア
          </div>
          <hr className="basicServicesHr"/>
          検索機能で新しいレシピアイデアを取り入れ、AIで新しいレシピをすぐに生成可能です。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> 栄養素を含む
          </div>
          <hr className="basicServicesHr"/>
          レシピの栄養素情報を確認し、外部データベースから自動で更新できます。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> 変換
          </div>
          <hr className="basicServicesHr"/>
          体積を重量に変換する機能で、正確なレシピ再現が可能です。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> 画像機能
          </div>
          <hr className="basicServicesHr"/>
          画像のアップロードやAIでの画像生成が可能です（追加のサブスクリプションが必要）。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> 拡張機能
          </div>
          <hr className="basicServicesHr"/>
          マイアカウントから追加機能を簡単に有効化できます。
          <p></p>

        </div>
      </div>
    </div>
  </div>
</div>


  )
}

function BasicInfoListSwedish(){
  let {getImageUrl,buttonId}=useContext(MyContext)
  return(
    <div className="basicServicesWrapper">
             <div className="infoBEnglish" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
            <div className="transparentBoxBasList">
    <div className="basicServicesHeader">Följande ingår i bas prenumeration</div>
    <div className="basicServicesText">
    <ol className="basicServicesList">
     
    <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Spara upp till 300 recept<br></br>
    <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Använd upp till 500 ingredienser<br></br>
    <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Skapa upp till 100 ingrediens-kategorier<br></br>
    <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Sök och skapa recept med AI,50st per månad<br></br>
    <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Skapa recept manuellt med ingredienser och recept<br></br>
    <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Sök recept ideer med AI, 50 st per månad.<br></br>
    <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Skapa bilder med AI for recept*(kostnad tillkommer)<br></br>
    <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Ladda upp egna bilder på recept<br></br>
    <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Spara upp till 400 bilder<br></br>
    <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Skapa upp till 100 recept-kategorier<br></br>
    <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Automatisk uträkning av näringsämnen på recept.<br></br>
    <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Automatisk uträkning av kostnad för recept<br></br>
    <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Automatisk matchning av ingredienser<br></br>
    <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Automatisk hämtning av näringsämnen<br></br>
    <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Informations om ingredienser 50st per månad<br></br>
    <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Konvertera volym till gram<br></br>
    <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Hantera ingrediens-recept kopplingar<br></br>
    <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Flytta och hantera kopplingar automatiskt<br></br>
    <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Hantera priser på ingredienser<br></br>
    <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Automatisk pris diagram för ingredienser<br></br>
    <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Humoristiska kommentarer ifrån vår AI<br></br>
      </ol>
  </div>
  </div>
  </div>
  </div>
  )
}
function BasicInfoListEnglish(){
  let {getImageUrl,buttonId}=useContext(MyContext)
  return(
    <div className="basicServicesWrapper">
             <div className="infoBEnglish" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
             <div className="transparentBoxBasList">
    <div className="basicServicesHeader">The following is included in the basic subscription</div>
<div className="basicServicesText">
<ol className="basicServicesList">
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Save up to 300 recipes<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Use up to 500 ingredients<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Create up to 100 ingredient categories<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Search and create recipes with AI, 50 per month<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Create recipes manually with ingredients and recipes<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Search for recipe ideas with AI, 50 per month.<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Create images with AI for recipes* (additional cost)<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Upload your own images for recipes<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Save up to 400 images<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Create up to 100 recipe categories<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Automatic calculation of nutrients in recipes.<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Automatic calculation of recipe cost<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Automatic matching of ingredients<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Automatic retrieval of nutrients<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Information lookup for ingredients ,50 per month<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Convert volume to grams<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Manage ingredient-recipe connections<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Move and manage connections automatically<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Manage prices of ingredients<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Automatic price diagram for ingredients<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Receive fresh comments from our AI<br></br>
            </ol>
</div>
</div>
</div>
</div>

  )
}

function BasicInfoListJapanese(){
  let {getImageUrl,buttonId}=useContext(MyContext)
  return(
    <div className="basicServicesWrapper">
          <div className="infoBEnglish" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
          <div className="transparentBoxBasList">
    <div className="basicServicesHeader">基本サブスクリプションに含まれる内容</div>
<div className="basicServicesText">
<ol className="basicServicesListJAP">
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>最大300のレシピを保存<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>最大500の食材を使用<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>最大100の食材カテゴリを作成<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>AIでレシピを検索および作成、月に50回<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>食材とレシピを使用して手動でレシピを作成<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>AIでレシピのアイデアを検索、月に50回<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>AIでレシピの画像を作成*（追加費用）<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>レシピに自分の画像をアップロード<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>最大400の画像を保存<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>最大100のレシピカテゴリを作成<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>レシピの栄養素を自動計算<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>レシピのコストを自動計算<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>食材の自動マッチング<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>栄養素の自動取得<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>食材の情報検索、月に50回<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>体積をグラムに変換<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>食材とレシピの接続を管理<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>接続を自動で移動および管理<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>食材の価格を管理<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>食材の価格図を自動作成<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>AIからの新鮮で少しユーモラスなコメントを<br></br>
            </ol>
</div>
</div>
</div>
</div>
  )
}

function TotalTop(){
  const {getCountry,setCountry,basicfunctionalityButtonText,basicStoryButton,totalWeTelltext,totalFunctionalitytext}=useContext(MyContext)

  const[getServiceTab,setServiceTab]=useState(1)


 return(
    <div className="baseServicesTop">
    <div className="baseServicesTabTop">
    <button className={`baseServicesmobileTab ${getServiceTab===1?'active':''}`}
    onClick={()=>setServiceTab(1)}>{totalWeTelltext}</button>
    <button className={`baseServicesmobileTab ${getServiceTab===2?'active':''}`}
    onClick={()=>setServiceTab(2)}>{ totalFunctionalitytext}</button>

</div>

    <div className="baseServicesTabContent">
    {getServiceTab===1 && getCountry==='se' ?<TotalSwedish   getServiceTab={getServiceTab}/> : ''}
    {getServiceTab===1 && getCountry==='en' ?<TotalEnglish  getServiceTab={getServiceTab}/> : ''}
    {getServiceTab===1 && getCountry==='ja' ?<TotalJapanese getServiceTab={getServiceTab} /> : ''}

    {getServiceTab===2 && getCountry=='se'? <TotalInfoTextSwedish  getServiceTab={getServiceTab}/> : ''}
    {getServiceTab===2 && getCountry=='en'? <TotalInfoTextEnglish  getServiceTab={getServiceTab}/> : ''}
    {getServiceTab===2 && getCountry=='ja'? <TotalInfoTextJapanese  getServiceTab={getServiceTab}/> : ''}
    
    
 


    </div></div>
 )

}

function TotalSwedish(props){
  let{jpman1}=useContext(MyContext)
  let {getServiceTab}=props

  function getTotalInfoImageUrl(serviceTab) {
    if(serviceTab===1){
      return jpman1

  }
}
  return(
    // <div className="basicServicesWrapper">
    //         <div className="infoBEnglish" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})` }}>
           
   
    // <div className="transparentBoxSwedishTotal">
    // <div className="totalServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Total</div>
    // <div className="totalServicesCommentText">Total abonnemang innehåller allt i bas + produkter och produktion.
    //   <br></br><p></p><div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Skapandet av produkter</div>

    //   Att kunna skapa 
    //   produkter i vår applikation är en mycket viktig del i applikationen då man kan säga att det som börjar
    //   med en ingrediens, blir ett recept och i slutändan en del eller helheten av en produkt.
    //   När vi skapat vår produkt och applikationen räknat ut alla parametrar så faller alla bitar på plats
    //   och du kan enkelt se din marginal och därmed lönsamhet på produkten.<br></br><p></p> Genom att prova och testa olika
    //   recept eller samma recept med olika ingredienser kan du arbeta fram en produkt som har den lönsamhet
    //   som du siktar på. I verkliga livet är det mycket svårt att skapa produkter och samtidigt få en bra lönsamhet
    //   på dessa. Därför skapar bakesoft en grund för dig att stå på när du arbetar i din verksamhet, i bakesoft blir 
    //   det roligt och intressant att skapa produkter och se hur lönsamheten påverkas av dina val.
    //   <br></br><p></p><div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Produktion</div>
    //    Produktion är en fortsättning och viktig det i arbetet när vi väl skapat våra produkter. Produkterna lägger
    //    en bas som du sedan kan arbeta med i produktion. Om du tex skapat en produkt, det kan vara 1 maträtt för 1 person, eller
    //    en bulle så kan du sedan skala upp tillverkningen genom att lägga dessa i en produktion. Om du vill tex göra
    //    100 bullar, så skapar du en ny produktion, lägger till 100 bullar, då kan du under produktions menyn Receptsummering
    //    se sammanställning och storlekar av dina recept, bakesoft tar fram samtliga recept på dina produkter och räknar
    //    fram nya storlekar på dessa så att du enkelt kan tillverka exakt den mängd du behöver. Detta gör applikationen
    //    med ett knapptryck, avancerade uträkningar i bakgrunden skapar dessa sammanställningar.<p></p>
    //    Att ta emot beställningar blir oerhört mycket enklare när du i din nya produktionsmiljö kan se exakt hur mycket 
    //    du behöver tillverka.
    //    <br></br><p></p><div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Receptsummering</div>
    //    I produktionsmiljön finns en meny som heter Receptsummering, där sammanställs samtliga recept inblandade
    //    i dina produkter och du kan se hur mycket du behöver producera per recept. Allt sammanställs direkt när du 
    //    behöver det. Och det är här du arbetar under arbetets gång i produktionen. Du kan välja det receptet du ska arbeta
    //    med och då ser du sammanställningen av receptet i din produktion samt även din receptbeskrivning.
    //    <br></br>
    //    <p></p>
    //    <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Ingrediens summering</div>
    //    I produktion finns en meny som heter ingrediens summering. Här presenteras summan av alla ingredienser på de produkter
    //    som du har i produktion. Detta hjälper dig att köpa in rätt mängd ingredienser så att du vet att du har 
    //    vad du behöver för att tillverka det som finns i produktion. I bakgrunden sammanställa mängder av data som summeras
    //    och listas upp så att du enkelt kan se och använda detta i ditt arbete.

    //    <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Totalt i produktion</div>
    //    I produktion finns en meny som listar samtliga produkter i produktion. Oavsett om du har flera produktioner så
    //    ser du totalt vas som finns i produktion inkl summeringar.

    //    <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Produkt statistik</div>
    //    I produktion finns en meny som heter produkt statistik. Här kan du se hur mycket du har tillverkat av en produkt fördelat på veckor
    //    och statistiken visas i en graf. Du väljer en kategori sedan produkt och statistiken visas.
    //    <p></p>
    //    <p></p>
    //    <p></p>

    
    
    
    
    // </div>
    // </div>
    // </div>
    // </div>

    <div className="basicServicesWrapper">
  <div className="infoBEnglish" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})` }}>
    <div className="transparentBoxSwedishTotal">
      <div className="totalServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Total</div>
      <div className="totalServicesCommentText">
        Total-abonnemanget innehåller allt från basen samt produkter och produktion.
        <br /><p></p>
        <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Skapandet av produkter</div>
        Att skapa produkter i applikationen är viktigt. En ingrediens blir ett recept och slutligen en produkt. När produkten är skapad, ser du enkelt marginaler och lönsamhet. Bakesoft hjälper dig att testa recept och hitta optimal lönsamhet.
        <br /><p></p>
        <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Produktion</div>
        När produkterna är klara kan du skala upp produktionen, t.ex. från en bulle till 100. Med ett knapptryck räknar Bakesoft ut nya storlekar för produktion.
        <br /><p></p>
        <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Receptsummering</div>
        I menyn Receptsummering ser du mängden som ska produceras per recept, sammanställd direkt under produktionen.
        <br /><p></p>
        <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Ingredienssummering</div>
        Här får du en sammanställning av alla ingredienser för att säkerställa rätt mängd inför produktion.
        <br /><p></p>
        <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Totalt i produktion</div>
        Menyn listar alla produkter som är i produktion, inklusive summeringar.
        <br /><p></p>
        <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Produktstatistik</div>
        Se statistik över hur mycket som tillverkats per produkt per vecka, presenterat i graf.
        <p></p>
      </div>
    </div>
  </div>
</div>

  
  )
}
function TotalEnglish(props){
  let{jpman1}=useContext(MyContext)
  let {getServiceTab}=props

  function getTotalInfoImageUrl(serviceTab) {
    if(serviceTab===1){
      return jpman1

  }
}
  return(


<div className="basicServicesWrapper">
  <div className="infoBEnglish" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})` }}>
    <div className="transparentBoxSwedishTotal">
      <div className="basicServicesHeader">
        <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Total, the Story
      </div>
      <div className="totalServicesCommentText">
        The Total subscription includes everything from the base plus products and production.
        <br /><p></p>
        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Product Creation
        </div>
        Creating products is key, starting from an ingredient and becoming a recipe or product. You can see margins and profitability easily after creating a product, helping you refine it for better results.
        <br /><p></p>
        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Production
        </div>
        Once products are created, you can scale up production (e.g., from 1 bun to 100) using the production tools. The app calculates the necessary quantities automatically.
        <br /><p></p>
        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Recipe Summary
        </div>
        The Recipe Summary menu compiles all recipes involved in production, showing how much you need to produce per recipe, updated instantly.
        <br /><p></p>
        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Ingredient Summary
        </div>
        Ingredient Summary lists all ingredients needed, helping with purchasing.
        <br /><p></p>
        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Total in Production
        </div>
        This menu lists all products in production, including summaries of multiple productions.
        <br /><p></p>
        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Product Statistics
        </div>
        The Product Statistics menu shows production data over weeks, displayed in graphs.
        <p></p>
      </div>
    </div>
  </div>
</div>




  )
}
function TotalJapanese(props){
  let{jpman1}=useContext(MyContext)
  let {getServiceTab}=props

  function getTotalInfoImageUrl(serviceTab) {
    if(serviceTab===1){
      return jpman1

  }
}
  return(
<div className="basicServicesWrapper">
  <div className="infoBJAP" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})` }}>
    <div className="transparentBoxSwedishTotal">
      <div className="basicServicesHeaderJAPTEXT">
        <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>トータル、ストーリー
      </div>
      <div className="totalServicesCommentTextJAP">
        トータルサブスクリプションには、ベース＋製品および生産が含まれます。
        <br /><p></p>
        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>製品の作成
        </div>
        製品を作成し、アプリがパラメータを計算すると、マージンと収益性が簡単に確認できます。異なるレシピや材料を試して、目指す収益性の製品を開発できます。
        <br /><p></p>
        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>生産
        </div>
        製品作成後、生産でスケールアップが可能です。例えば、100個のパンを追加し、レシピサマリーで必要な量を確認できます。すべて自動計算されます。
        <br /><p></p>
        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>レシピサマリー
        </div>
        生産環境でレシピをまとめ、必要な量をリアルタイムで確認できます。
        <br /><p></p>
        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>原材料サマリー
        </div>
        全材料の合計を表示し、必要な材料を確認できます。
        <br /><p></p>
        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>生産全体
        </div>
        すべての生産中の製品を一覧で確認できます。
        <br /><p></p>
        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>製品統計
        </div>
        週ごとの製造量をグラフで確認できます。
        <p></p>
      </div>
    </div>
  </div>
</div>


  )

}

function TotalInfoTextSwedish(props){
  let{jpwoman3}=useContext(MyContext)
  let {getServiceTab}=props


  function getTotalInfoImageUrl(serviceTab) {
    if(serviceTab===2){
      return jpwoman3

  }
}

  return(
    <div className="basicServicesWrapper">
             <div className="infoBEnglish" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})` }}>
             <div className="transparentBoxTotalFeatures">
    <div className="totalServicesHeader">Följande ingår i Total</div>
<div className="basicServicesText">
<ol className="basicServicesList">
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Allt som ingår i Bas<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Upp till 400 recept<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Skapa & hantera produkter, max 300 produkter<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Skapa & hantera produktion, max 250 produktioner<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Automatisk recept summering<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Automatisk ingrediens summering<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Automatisk produkt statistik<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Skapa & hantera prislista med marginaler<br></br>
<IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Skapa upp till 100 produkt-kategorier<br></br>

      </ol>
</div>
</div>
</div>
</div>
  )
}
function TotalInfoTextEnglish(props){
  let{jpwoman3}=useContext(MyContext)
  let {getServiceTab}=props


  function getTotalInfoImageUrl(serviceTab) {
    if(serviceTab===2){
      return jpwoman3

  }
}
  return(
    <div className="basicServicesWrapper">
    <div className="infoBEnglish" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})`}}>
      <div className="transparentBoxTotalFeatures">
        <div className="basicServicesHeader">The following is included in Total</div>
        <div className="basicServicesText">
          <ol className="basicServicesList">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Everything included in Basic<br></br>
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Up to 400 recipes<br></br>
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Create & manage products, max 300 products<br></br>
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Create & manage production, max 250 productions<br></br>
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Automatic recipe summary<br></br>
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Automatic ingredient summary<br></br>
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Automatic product statistics<br></br>
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Create & manage price lists with margins<br></br>
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Create up to 100 product categories<br></br>
          </ol>
        </div>
      </div>
    </div>
  </div>
  
  )
}

function TotalInfoTextJapanese(props){
  let{jpwoman3}=useContext(MyContext)
  let {getServiceTab}=props


  function getTotalInfoImageUrl(serviceTab) {
    if(serviceTab===2){
      return jpwoman3

  }
}
  return(
<div className="basicServicesWrapper">
  <div className="infoBJAP" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})`}}>
    <div className="transparentBoxTotalFeatures">
      <div className="basicServicesHeader">トータルに含まれる内容</div>
      <div className="basicServicesText">
        <ol className="totalServicesListJAP">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>ベーシックに含まれるすべて<br></br>
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>最大400レシピ<br></br>
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>製品の作成と管理、最大300製品<br></br>
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>生産の作成と管理、最大250生産<br></br>
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>自動レシピ集計<br></br>
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>自動材料集計<br></br>
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>自動製品統計<br></br>
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>マージン付きの価格表の作成と管理<br></br>
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>最大100製品カテゴリの作成<br></br>
        </ol>
      </div>
    </div>
  </div>
</div>

  )
}

function BasicCorporate(){
}
function TotalCorporate(){
}


function InfoStartSwedish(){
  
    return(
  
<div className="infoStartWrapper">
    <div className="infoStartHeader">Välkommen</div>
    <div className="infoStartTextSwedish">
        Bakesoft.se är en unik SAAS-applikation som samlar ihop <br></br>många viktiga funktioner. Den skapar en unik och kreativ<br></br> plats för kockar,bagare,cafe & restaurang personal.
        <br></br> Bakesoft beräknar olika parametrar och skapar<br></br> en komplett  och informativ  miljö
        <br></br> när man som mest behöver viktig<br></br> och avgörande information för att kunna prestera.<br></br>
        <br></br> Applikationen drivs av AI-tjänster i bakgrunden för att<br></br> förbättra användarupplevelsen, och för att <br></br> tillhandahålla extern och utökad<br></br> information på ett mycket enkelt och<br></br> förståeligt sätt.
        Kulinärernas område är stort<br></br> och så omfattande och nästan<br></br> oöverkomligt, vi har tillbringat<br></br> år för att ta fram en unik<br></br> och kreativ applikation<br></br> som kommer hjälpa <br></br>användaren att få ut det mesta<br></br> av sin kulinariska upplevelse.
        <br></br><p></p><p></p> Vi hoppas innerligt att du kommer att<br></br> gå med oss och bli en del av denna unika<br></br> och kreativa resa.<br></br>
        <br></br> Låt oss börja!<br></br>
        <br></br> Anders Hellström VD<br></br>
        www.bakesoft.se<br></br>
    </div>
</div>
    )
  }
  function InfoStartEnglish(){
  
    return(
  
      <div className="infoStartWrapper">
        <div className="infoStartHeader">Welcome</div>
        <div className="infoStartText">Bakesoft.se is a unique SAAS application<br></br> that brings several 
         important functionalities <br></br>together.It creates a unique and creative <br></br>place for chefs & bakers, professional or not.
         <br></br> Bakesoft calculates many different parameters<br></br> and brings forward a  complete and informative<br></br> enviroment for the
         chef or the baker <br></br>when they are in most need of the important<br></br>and crucial information to be able to perform.<br></br><br></br> The application is<br></br> powered by AI services<br></br> in the backgorund to 
         enhance<br></br> the experience for the user,and<br></br> to bring forward external and<br></br> extensive information<br></br> in a very easy and <br></br>understandable way.<br></br>
         The area of culinaries is vast<br></br> and so big and almost<br></br> unconquerable, we have spent<br></br> years to bring forward a unique<br></br> and creative application that will<br></br> help the user to get the most out<br></br> of their culinary experience.<br></br>
        <br></br> We sincereally hope you will join us<br></br> and be a part of this unique<br></br> and creative journey.<br></br>
        <br></br> Lets get started!<br></br>

        Anders Hellström CEO<br></br>
        www.bakesoft.se<br></br>
          
          
          
          
          </div>
      </div>
  
    )
  }
  function InfoStartJapanese(){
  
    return(
  
<div className="infoStartWrapper">
    <div className="infoStartHeaderJAP">ようこそ</div>
    <div className="infoStartTextJapanese">
        Bakesoft.seはユニークなSAASアプリケーショ<br></br>ンです それは多くの重要な機能を 一つ<br></br>にまとめます。プロフェッショナルであれそ<br></br>うでないであれ、シェフやベイカーの<br></br>ためにユニークでクリエイティブな<br></br> 場所を作り出します。
        <br></br> Bakesoftはさまざまなパラメータを計<br></br>算し、シェフやベイカーにとって重要<br></br>で重要な情報を必要とす<br></br>る時に、完全で有益な<br></br>環境を提供します。<br></br><br></br> このアプリケーションは<br></br> AIサービスによって<br></br> 背景で強化されており、<br></br>ユーザーの体験を向上させ、<br></br> 外部からの広範な情報を<br></br> 非常に簡単で<br></br> 理解しやすい方法で提供します。<br></br>
        料理の分野は広大で<br></br> 非常に大きく、ほとんど<br></br> 手に負えないほどですが、私たちは<br></br> 多年にわたってユニークで<br></br> クリエイティブなアプリケーションを開発し、<br></br> ユーザーが料理体験を最大限に<br></br> 活用できるようにしました。<br></br>
        <br></br> 私たちは心から、あなたがこのユニークで<br></br> クリエイティブな旅の一員となることを<br></br> 願っています。
        <br></br> さあ、始めましょう！<br></br>
        <br></br> アンダース・ヘルストロム CEO<br></br>
        www.bakesoft.se<br></br>
    </div>
</div>

  
    )
  }

  function CorporateTotalTop(){
    const {getCountry,setCountry,basicfunctionalityButtonText,basicStoryButton,totalCorporateWeTellText,totalCorporateFunctionalityText}=useContext(MyContext)

    const[getServiceTab,setServiceTab]=useState(1)
  
  
   return(
      <div className="baseServicesTop">
      <div className="baseServicesTabTop">
      <button className={`baseServicesmobileTab ${getServiceTab===1?'active':''}`}
      onClick={()=>setServiceTab(1)}>{totalCorporateWeTellText}</button>
      <button className={`baseServicesmobileTab ${getServiceTab===2?'active':''}`}
      onClick={()=>setServiceTab(2)}>{totalCorporateFunctionalityText}</button>
  
  </div>
  
      <div className="baseServicesTabContent">
      {getServiceTab===1 && getCountry==='se' ?<CorporateTotalSwedish   getServiceTab={getServiceTab}/> : ''}
      {getServiceTab===1 && getCountry==='en' ?<CorporateTotalEnglish  getServiceTab={getServiceTab}/> : ''}
      {getServiceTab===1 && getCountry==='ja' ?<CorporateTotalJapanese getServiceTab={getServiceTab} /> : ''}
  
      {getServiceTab===2 && getCountry=='se'? <CorporateTotalInfoTextSwedish  getServiceTab={getServiceTab}/> : ''}
      {getServiceTab===2 && getCountry=='en'? <CorporateTotalInfoTextEnglish  getServiceTab={getServiceTab}/> : ''}
      {getServiceTab===2 && getCountry=='ja'? <CorporateTotalInfoTextJapanese  getServiceTab={getServiceTab}/> : ''}
      
      
   
  
  
      </div></div>
   )
  
  }
  function CorporateTotalSwedish(props){
    let{swedman1}=useContext(MyContext)
    let {getServiceTab}=props
  
    function getTotalInfoImageUrl(serviceTab) {
      if(serviceTab===1){
        return jpwoman1
  
    }
  }
    return(
      // <div className="basicServicesWrapper">
      //         <div className="corporateTotalBackgroundImage" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})` }}>
      //         <div className="transparentBoxCorporateTotal">
      // <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Total, historien</div>
      // <div className="corporateTotalServicesCommentText">Total företag innehåller allt i bas + produkter och produktion.
      //   <br></br><p></p><div className="basicServicesHeader">
          
      //     <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Skapandet av produkter</div>
  
      //   Att kunna skapa 
      //   produkter i ett företag är en grundförutsättning för att verksamheten ska kunna utvecklas och 
      //   och följas upp på ett bra sätt. Bakesoft.se webbapplikation började som en prototyp när vi försökte att utveckla vår
      //   verksamhet i bageriet. 
      //    Så produkter är en mycket viktig del i applikationen då man kan säga att det som börjar
      //   med en ingrediens, blir ett recept och i slutändan en del eller helheten av en produkt.
      //   När vi skapat vår produkt och applikationen räknat ut alla parametrar så faller alla bitar på plats
      //   och du kan enkelt se din marginal och därmed lönsamhet på produkten.<br></br><p></p> Genom att prova och testa olika
      //   recept eller samma recept med olika ingredienser kan du arbeta fram en produkt som har den lönsamhet
      //   som du siktar på. I verkliga livet är det mycket svårt att skapa produkter och samtidigt få en bra lönsamhet
      //   på dessa. Därför skapar bakesoft en grund för dig att stå på när du arbetar i din verksamhet, i bakesoft blir 
      //   det roligt och intressant att skapa produkter och se hur lönsamheten påverkas av dina val.
      //   <br></br><p></p><div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Produktion</div>
      //    Produktion är en fortsättning och viktig det i arbetet när vi väl skapat våra produkter. Produkterna lägger
      //    en bas som du sedan kan arbeta med i produktion. Om du tex skapat en produkt, det kan vara 1 maträtt för 1 person, eller
      //    en bulle så kan du sedan skala upp tillverkningen genom att lägga dessa i en produktion. Om du vill tex göra
      //    100 bullar, så skapar du en ny produktion, lägger till 100 bullar, då kan du under produktions menyn Receptsummering
      //    se sammanställning och storlekar av dina recept, bakesoft tar fram samtliga recept på dina produkter och räknar
      //    fram nya storlekar på dessa så att du enkelt kan tillverka exakt den mängd du behöver. Detta gör applikationen
      //    med ett knapptryck, avancerade uträkningar i bakgrunden skapar dessa sammställningar.<p></p>
      //    Att ta emot beställningar blir oerhört mycket enklare när du i din nya produktionsmiljö kan se exakt hur mycket 
      //    du behöver tillverka.
      //    <br></br><p></p><div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Receptsummering</div>
      //    I produktionsmiljön finns en meny som heter Receptsummering, där sammanställs samtliga recept inblandade
      //    i dina produkter och du kan se hur mycket du behöver producera per recept. Allt sammanställs direkt när du 
      //    behöver det. Och det är här du arbetar under arbetets gång i produktionen. Du kan välja det receptet du ska arbeta
      //    med och då ser du sammanställningen av receptet i din produktion samt även din receptbeskrivning.
      //    <br></br>
      //    <p></p>
      //    <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Ingrediens summering</div>
      //    I produktion finns en meny som heter ingrediens summering. Här presenteras summan av alla ingredienser på de produkter
      //    som du har i produktion. Detta hjälper dig att köpa in rätt mängd ingredienser så att du vet att du har 
      //    vad du behöver för att tillverka det som finns i produktion. I bakgrunden sammanställa mängder av data som summeras
      //    och listas upp så att du enkelt kan se och använda detta i ditt arbete.
  
      //    <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Totalt i produktion</div>
      //    I produktion finns en meny som listar samtliga produkter i produktion. Oavsett om du har flera produktioner så
      //    ser du totalt vas som finns i produktion inkl summeringar.
  
      //    <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Produkt statistik</div>
      //    I produktion finns en meny som heter produkt statistik. Här kan du se hur mycket du har tillverkat av en produkt fördelat på veckor
      //    och statistiken visas i en graf. Du väljer en kategori sedan produkt och statistiken visas.
      //    <p></p>
      //    <p></p>
      //    <p></p>
  
      
      
      
      
      // </div>
      // </div>
      // </div>
      // </div>


      <div className="basicServicesWrapper">
  <div className="corporateTotalBackgroundImage" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})` }}>
    <div className="transparentBoxCorporateTotal">
      <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Total, historien</div>
      <div className="corporateTotalServicesCommentText">
        Total företag innehåller allt i bas + produkter och produktion.

        <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Skapandet av produkter</div>
        Att skapa produkter är nyckeln för verksamhetens utveckling. Bakesoft.se startade som en prototyp för vår bageriverksamhet. Produkten börjar med ingredienser och slutar som en komplett produkt. Applikationen hjälper dig beräkna marginaler och lönsamhet. Genom att experimentera med olika recept kan du nå den lönsamhet du siktar på, vilket gör produktutveckling både rolig och intressant.

        <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Produktion</div>
        Produkter skapar grunden för produktion. Du kan skala upp genom att lägga till en produktion, t.ex. från 1 bulle till 100. Applikationen omvandlar automatiskt receptmängder för produktion och gör beställningshantering enklare.

        <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Receptsummering</div>
        Sammanställer alla recept i produktion för att visa hur mycket du behöver producera per recept.

        <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Ingrediens summering</div>
        Visar summan av alla ingredienser för produktion, vilket hjälper dig att köpa rätt mängd.

        <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Totalt i produktion</div>
        Lista över alla produkter i produktion, oavsett antal produktioner.

        <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Produkt statistik</div>
        Visar hur mycket av en produkt som har tillverkats över tid, presenterat i en graf.
      </div>
    </div>
  </div>
</div>

    )
  }
  function CorporateTotalEnglish(props){
    const { jpman1 } = useContext(MyContext);
    const { getServiceTab } = props;
  
    function getTotalInfoImageUrl(serviceTab) {
      if (serviceTab === 1) {
        return jpwoman1;
      }
    }
  
    return (
      // <div className="basicServicesWrapper">
      //   <div className="infoBEnglish" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})` }}>
      //     <div className="transparentBoxCorporateTotal">
      //       <div className="basicServicesHeader">
      //         <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
      //         Total, the history
      //       </div>
      //       <div className="corporateTotalServicesCommentText">
      //         Total corporate subscription includes everything in the corporate basic subscription + products and production.
      //         <br /><p></p>
      //         <div className="basicServicesHeader">
      //           <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
      //           Product Creation
      //         </div>
      //         Being able to create products in a company is a basic requirement for the business to develop and be followed up in a good way. The bakesoft.se web application started as a prototype when we tried to develop our business in the bakery. So products are a very important part of the application as you can say that what starts with an ingredient becomes a recipe and ultimately a part or the whole of a product.
      //         When we have created our product and the application has calculated all the parameters, everything falls into place and you can easily see your margin and thus the profitability of the product.
      //         <br /><p></p> By trying and testing different recipes or the same recipe with different ingredients, you can work out a product that has the profitability you are aiming for. In real life, it is very difficult to create products and at the same time get good profitability on them. Therefore, Bakesoft creates a foundation for you to stand on when working in your business, in Bakesoft it becomes fun and interesting to create products and see how profitability is affected by your choices.
      //         <br /><p></p>
      //         <div className="basicServicesHeader">
      //           <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
      //           Production
      //         </div>
      //         Production is a continuation and important part of the work once we have created our products. The products lay a foundation that you can then work with in production. For example, if you have created a product, it can be one dish for one person, or a bun, you can then scale up production by adding these to a production. If you want, for example, to make 100 buns, you create a new production, add 100 buns, then in the production menu Recipe Summary, you can see a summary and sizes of your recipes. Bakesoft provides all recipes for your products and calculates new sizes for them so that you can easily produce exactly the amount you need. This is done by the application with a press of a button, advanced calculations in the background create these summaries.
      //         <p></p>
      //         Receiving orders becomes much easier when you can see exactly how much you need to produce in your new production environment.
      //         <br /><p></p>
      //         <div className="basicServicesHeader">
      //           <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
      //           Recipe Summary
      //         </div>
      //         In the production environment, there is a menu called Recipe Summary, where all recipes involved in your products are compiled and you can see how much you need to produce per recipe. Everything is compiled directly when you need it. And this is where you work during the production process. You can choose the recipe you will work with and then see the summary of the recipe in your production as well as your recipe description.
      //         <br /><p></p>
      //         <div className="basicServicesHeader">
      //           <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
      //           Ingredient Summary
      //         </div>
      //         In production, there is a menu called Ingredient Summary. Here, the sum of all ingredients for the products you have in production is presented. This helps you buy the right amount of ingredients so that you know you have what you need to manufacture what is in production. In the background, large amounts of data are compiled and listed so that you can easily see and use this in your work.
      //         <div className="basicServicesHeader">
      //           <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
      //           Total in Production
      //         </div>
      //         In production, there is a menu that lists all products in production. Regardless of whether you have several productions, you can see the total of what is in production including summaries.
      //         <div className="basicServicesHeader">
      //           <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
      //           Product Statistics
      //         </div>
      //         In production, there is a menu called Product Statistics. Here you can see how much you have produced of a product distributed over weeks, and the statistics are shown in a graph. You select a category, then product, and the statistics are displayed.
      //         <p></p><p></p><p></p>
      //       </div>
      //     </div>
      //   </div>
      // </div>

<div className="basicServicesWrapper">
  <div className="infoBEnglish" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})` }}>
    <div className="transparentBoxCorporateTotal">
      <div className="basicServicesHeader">
        <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
        Total, the history
      </div>
      <div className="corporateTotalServicesCommentText">
        Total corporate subscription includes everything in the basic + products and production.

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
          Product Creation
        </div>
        Creating products is essential for business development. Bakesoft.se started as a prototype to improve our bakery. The application helps turn ingredients into recipes and final products, showing margins and profitability with ease. You can experiment with recipes and ingredients to reach the desired profitability, making product creation both fun and profitable.

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
          Production
        </div>
        Once products are created, they become the foundation for production. Scale up by adding more units (e.g., 100 buns), and Bakesoft automatically calculates the recipe adjustments. This simplifies order handling and ensures you produce exactly what’s needed.

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
          Recipe Summary
        </div>
        This menu compiles all recipes, showing how much to produce per recipe and providing a summary during production.

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
          Ingredient Summary
        </div>
        Ingredient Summary helps you buy the right amounts by presenting the total ingredients needed for production.

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
          Total in Production
        </div>
        View all products in production, including summaries across multiple productions.

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
          Product Statistics
        </div>
        See product statistics by week, displayed in graphs. Choose a category and product to view the data.

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
          Pricelist Page
        </div>
        On the pricelist page, you set the price for your created product, and the profit margin is instantly displayed. The pricelist is grouped by your product categories, allowing for easy management.
      </div>
    </div>
  </div>
</div>


    )
  }
  function CorporateTotalJapanese(props){
    const { jpwoman } = useContext(MyContext);
    const { getServiceTab } = props;
  
    function getTotalInfoImageUrl(serviceTab) {
      if (serviceTab === 1) {
        return jpwoman;
      }
    }
  
    return (
      // <div className="basicServicesWrapper">
      //   <div className="infoBJAP" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})` }}>
      //     <div className="transparentBoxCorporateTotal">
      //       <div className="basicServicesHeader">
      //         <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
      //         トータル、歴史
      //       </div>
      //       <div className="basicServicesCommentTextJAP">
      //         トータル企業サブスクリプションには、ベース+製品と生産のすべてが含まれます。
      //         <br /><p></p>
      //         <div className="basicServicesHeader">
      //           <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
      //           製品作成
      //         </div>
      //         企業で製品を作成できることは、ビジネスが発展し、適切にフォローアップされるための基本要件です。Bakesoft.seウェブアプリケーションは、私たちがベーカリーでのビジネスを発展させようとしたときにプロトタイプとして始まりました。したがって、製品はアプリケーションの非常に重要な部分であり、材料から始まり、レシピになり、最終的には製品の一部または全体になります。
      //         製品を作成し、アプリケーションがすべてのパラメーターを計算すると、すべてが整い、製品の利益率を簡単に確認できます。
      //         <br /><p></p> 異なるレシピや異なる材料で同じレシピを試してテストすることで、目指す利益率を持つ製品を作り出すことができます。現実の世界では、製品を作成しながら同時に高い利益率を得ることは非常に難しいです。したがって、Bakesoftはビジネスで作業するときに立つための基盤を提供し、Bakesoftでは製品を作成し、選択によって利益率がどのように影響されるかを見るのが楽しく興味深くなります。
      //         <br /><p></p>
      //         <div className="basicServicesHeader">
      //           <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
      //           生産
      //         </div>
      //         生産は、製品を作成した後の作業の続きであり重要な部分です。製品は、その後の生産で作業するための基盤を提供します。例えば、1人分の料理やパンを作成した場合、それらを生産に追加することで製造を拡大できます。100個のパンを作りたい場合、新しい生産を作成し、100個のパンを追加すると、生産メニューのレシピ要約でレシピの要約とサイズを確認できます。Bakesoftは製品のすべてのレシピを提供し、それらの新しいサイズを計算して、必要な量を正確に製造できるようにします。これは、アプリケーションのボタンを押すだけで行われ、バックグラウンドで高度な計算がこれらの要約を作成します。
      //         <p></p>
      //         新しい生産環境でどれだけ製造する必要があるかを正確に確認できるため、注文を受け取るのが非常に簡単になります。
      //         <br /><p></p>
      //         <div className="basicServicesHeader">
      //           <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
      //           レシピ要約
      //         </div>
      //         生産環境には「レシピ要約」というメニューがあり、製品に関与するすべてのレシピがまとめられ、レシピごとにどれだけ製造する必要があるかを確認できます。必要なときにすべてが直接まとめられます。そして、これは生産の過程で作業するときに行います。作業するレシピを選択すると、そのレシピの要約とレシピの説明を生産で確認できます。
      //         <br /><p></p>
      //         <div className="basicServicesHeader">
      //           <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
      //           材料要約
      //         </div>
      //         生産には「材料要約」というメニューがあります。ここでは、製品のすべての材料の合計が表示されます。これにより、必要な量の材料を購入して、生産に必要なものを持っていることを確認できます。バックグラウンドで大量のデータがまとめられ、リスト化されて、作業中に簡単に見ることができ、使用できます。
      //         <div className="basicServicesHeader">
      //           <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
      //           生産全体
      //         </div>
      //         生産には、生産中のすべての製品をリストするメニューがあります。複数の生産があっても、生産に含まれるすべてのものを要約して確認できます。
      //         <div className="basicServicesHeader">
      //           <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
      //           製品統計
      //         </div>
      //         生産には「製品統計」というメニューがあります。ここでは、週ごとに分けて製造された製品の量を確認でき、統計はグラフで表示されます。カテゴリを選択し、製品を選択すると、統計が表示されます。
      //         <p></p><p></p><p></p>
      //       </div>
      //     </div>
      //   </div>
      // </div>

      <div className="basicServicesWrapper">
  <div className="infoBJAP" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})` }}>
    <div className="transparentBoxCorporateTotal">
      <div className="basicServicesHeader">
        <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
        トータル、歴史
      </div>
      <div className="basicServicesCommentTextJAP">
        トータル企業サブスクリプションには、ベース+製品と生産のすべてが含まれます。

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
          製品作成
        </div>
        企業で製品を作ることは、ビジネスの発展と利益率を確認するために重要です。Bakesoft.seはベーカリーの業務を発展させるためにプロトタイプとして始まりました。材料がレシピとなり、最終的に製品となります。アプリはすべてのパラメータを計算し、簡単に利益率を確認できます。

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
          生産
        </div>
        製品を作成した後、生産に追加して製造を拡大できます。アプリが自動でレシピのサイズを調整し、必要な量を簡単に製造できます。

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
          レシピ要約
        </div>
        すべてのレシピがまとめられ、製造する量を確認できます。

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
          材料要約
        </div>
        必要な材料の合計を表示し、適切な量を購入できます。

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
          生産全体
        </div>
        生産中のすべての製品を確認できます。

        <div className="basicServicesHeader">
          <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />
          製品統計
        </div>
        製品の製造統計が週ごとにグラフで表示されます。
      </div>
    </div>
  </div>
</div>

    );

  }
  function CorporateTotalInfoTextSwedish(props){
    let{yukiinbakery2}=useContext(MyContext)
    let {getServiceTab}=props
  
  
    function getTotalInfoImageUrl(serviceTab) {
      if(serviceTab===2){
        return jpwoman1
  
    }
  }

    return(
    <div className="basicServicesWrapper">
      <div className="infoBEnglish" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})` }}>
         <div className="transparentBoxCorporateTotalFeatures">
            <div className="basicServicesHeader">Följande ingår i Total</div>
                 <div className="basicServicesText">
                      <ol className="basicServicesList">
                            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Allt som ingår i Bas<br></br>
                            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Upp till 800 recept<br></br>
                            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Skapa & hantera produkter, max 500 produkter<br></br>
                            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Skapa & hantera produktion, max 500 produktioner<br></br>
                            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Automatisk recept summering<br></br>
                            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Automatisk ingrediens summering<br></br>
                            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Automatisk produkt statistik<br></br>
                            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Skapa & hantera prislista med marginaler<br></br>
                            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Skapa upp till 200 produkt-kategorier<br></br>

                      </ol>
                   </div>
              </div>
        </div>
      </div>
  )



  }
  function CorporateTotalInfoTextEnglish(props){
    const { ukwoman1 } = useContext(MyContext);
    const { getServiceTab } = props;
  
    function getTotalInfoImageUrl(serviceTab) {
      if (serviceTab === 2) {
        return jpwoman1;
      }
    }
  
    return (
      <div className="basicServicesWrapper">
        <div className="corporateTotalBackgroundImage" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})` }}>
          <div className="transparentBoxCorporateTotalFeatures">
            <div className="basicServicesHeader">The following is included in Total</div>
            <div className="basicServicesText">
              <ol className="basicServicesList">
                <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />Everything included in Basic<br></br>
                <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />Up to 800 recipes<br></br>
                <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />Create & manage products, max 500 products<br></br>
                <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />Create & manage production, max 500 productions<br></br>
                <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />Automatic recipe summary<br></br>
                <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />Automatic ingredient summary<br></br>
                <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />Automatic product statistics<br></br>
                <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />Create & manage price list with margins<br></br>
                <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />Create up to 200 product categories<br></br>
              </ol>
            </div>
          </div>
        </div>
      </div>
    )
  }
  function CorporateTotalInfoTextJapanese(props){
    const { yukiinbakery2 } = useContext(MyContext);
    const { getServiceTab } = props;
  
    function getTotalInfoImageUrl(serviceTab) {
      if (serviceTab === 2) {
        return jpwoman;
      }
    }
  
    return (
      <div className="basicServicesWrapper">
        <div className="infoBEnglish" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})` }}>
          <div className="transparentBoxCorporateTotalFeatures">
            <div className="basicServicesHeader">Totalに含まれるもの</div>
            <div className="basicServicesText">
              <ol className="totalServicesListJAP">
                <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />基本に含まれるすべて<br></br>
                <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />最大800レシピ<br></br>
                <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />製品の作成と管理、最大500製品<br></br>
                <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />生産の作成と管理、最大500生産<br></br>
                <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />自動レシピサマリー<br></br>
                <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />自動成分サマリー<br></br>
                <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />自動製品統計<br></br>
                <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />利益率を含む価格表の作成と管理<br></br>
                <IoShieldCheckmarkOutline className="basicServicesCheckIcon" />最大200の製品カテゴリの作成<br></br>
              </ol>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function CorporateBasicTop() {
    const {getCountry,setCountry,basicfunctionalityButtonText,basicStoryButton,basicCorporateWeTell,basicCorporateFunctionality}=useContext(MyContext)

    const[getServiceTab,setServiceTab]=useState(1)
  
  
   return(
      <div className="baseServicesTop">
      <div className="baseServicesTabTop">
      <button className={`baseServicesmobileTab ${getServiceTab===1?'active':''}`}
      onClick={()=>setServiceTab(1)}>{basicCorporateWeTell}</button>
      <button className={`baseServicesmobileTab ${getServiceTab===2?'active':''}`}
      onClick={()=>setServiceTab(2)}>{basicCorporateFunctionality}</button>
  
  </div>
  
      <div className="baseServicesTabContent">
      {getServiceTab===1 && getCountry==='se' ?<CorporateBasicSwedish   getServiceTab={getServiceTab}/> : ''}
      {getServiceTab===1 && getCountry==='en' ?<CorporateBasicEnglish  getServiceTab={getServiceTab}/> : ''}
      {getServiceTab===1 && getCountry==='ja' ?<CorporateBasicJapanese getServiceTab={getServiceTab} /> : ''}
  
      {getServiceTab===2 && getCountry=='se'? <CorporateBasicInfoTextSwedish  getServiceTab={getServiceTab}/> : ''}
      {getServiceTab===2 && getCountry=='en'? <CorporateBaicInfoTextEnglish  getServiceTab={getServiceTab}/> : ''}
      {getServiceTab===2 && getCountry=='ja'? <CorporateBasicInfoTextJapanese  getServiceTab={getServiceTab}/> : ''}
      
      
   
  
  
      </div></div>
   )

  }

  function CorporateBasicSwedish(props){
    const { yukiwoman2 } = useContext(MyContext);
    const { getServiceTab } = props;
  
    function getTotalInfoImageUrl(serviceTab) {
      if (serviceTab === 1) {
        return jpwoman3;
      }
    }

  return(
   
  //   <div className="basicServicesWrapper">
  //     <div className="basicServicesText">
  //     <div className="corporateBasBackgroundImage" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})` }}>
  //     <div className="transparentBoxCorporateBas">

  //     <div className="corporateBasServiceCommentText">
  //     <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Grund funktionalitet och funktioner</div>
  //       <hr className="basicServicesHr"></hr>
  //     Bas företag erbjuder nyckel funktioner som att skapa recept med AI, skapa
  //   recept manuellt med utgångspunkt på recept och ingredienser.<br></br><p></p>
  //   Speciellt viktigt är den automatiska
  //   funktionen som räknar ut din kostnad för receptet. I en miljö där ekonomi är en nyckelfaktor, är det av högsta
  //   vikt att de recept du arbetar med är ekonomiskt hållbara.<br></br><p></p>
  //   Att börja
  //    ett recept med ett annat, kortar ner tiden det tar att skapa ett nytt recept.
  //    Om du vill göra en ny variant av en cheesecake,tar du ett befintligt recept,du
  //    gör dina ändringar och allt är klart.Bakesoft räknar automatiskt kostnad mängd och
  //    nutrienter som kalorier,fett, protein m.m.<p></p>
  //    <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Förstora elle förminska</div>
  //    <hr className="basicServicesHr"></hr>
  //    Förminska och förstora recept med ett klick. Detta öppnar
  //    upp möjlighet att förändra direkt under arbetet och att anpassa storleken i olika recept till varandra.<p></p>
  //    <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Portioner</div>
  //    <hr className="basicServicesHr"></hr>
  //    Du kan även portionsindela dina recept,detta är effektivt speciellt
  //    när det gäller att skapa en ny produkt. Då en produkt alltid gäller en exakt utvald
  //    mängd.<br></br><p></p>
  //    <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Skapande av produkter</div>
  //    <hr className="basicServicesHr"></hr>
  //    Vid skapandet av en produkt kombinerar du recept och ingredienser och detta gör det oerhört enkelt och spännande
  //     att produktifiera
  //    dina recept. Du kan ta en del av en deg, en del av ytterligare ett recept och lägga till en klick av en annan ingrediens
  //    och bakesoft hanterar allt i bakgrunden. Du ger produkten ett namn och sparar så är allt klart.<br></br><p></p> I prislistan sätter du priset
  //    och du kan direkt se den marginal(netto vinst) du har på din nyskapade produkt.När du sammanför olika recept och ingredienser i en produkt så gör
  //    programvaran alla kalkyleringar i bakgrunden.
  //    <p></p> 
  //    <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Integritet</div>
  //    <hr className="basicServicesHr"></hr>
  //    Viktigt att tillägga här är att dina recept & produkter är dina, databasens uppbyggnad gör det inte
  //    möjligt att dela dina recept med andra i vår applikation, vi skyddar dina recept och din integritet genom att ge dig
  //    ditt eget system som inte kan kopieras av andra.Varje recept eller produkt du skapar är unik i vårt system.
  //    När vi skapar ditt konto genereras tusentals av rader in i vårt system som är unika för dig,dina recept
  //    och produkter.
  //    <br></br><p></p>
  //    <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Återskapa värden</div>
  //    <hr className="basicServicesHr"></hr>
  //    När du ändrar inköpspris på en ingrediens, ändras<br></br> priset på alla recept och
  //    produkter direkt. Detta spar<br></br> oerhört mycket tid, om du har 100 recept och lika många produkter
  //     och alla
  //    innehåller socker eller mjölk, så behöver du inte räkna om dina recept manuellt. Bakesoft hanterar detta 
  //    i bakgrunden inklusive alla beräkningar som
  //    gäller nutrienter. <br></br><p></p>
  //    <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Byt många med en</div>
  //    <hr className="basicServicesHr"></hr>
  //    På ingredienser kan du även ersätta ingrediens med en annan. Detta betyder att om du tex
  //    använder en typ av smör i 50 recept, du kan då ersätta en ingrediens med en annan i samtliga
  //    dina recept på en gång, man väljer ingrediens, och de recept som ska
  //    beröras och med ett klick så är allt uppdaterat.
  //    <br></br>
  //    <br></br>
  //    <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Kategorier</div>
  //    <hr className="basicServicesHr"></hr>
  //    Då det som oftast blir många recept & ingredienser så indelar du dessa i kategorier med vår kategori
  //    funktion som hjälper dig att skapa ändra och ta bort kategorier på ett enkelt sätt. Du får också en bra översikt
  //    och du kan flytta ett eller flera recept till en annan kategori på en gång.
  //    <br></br>
  //    <br></br>
  //    <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Av & På</div>
  //    <hr className="basicServicesHr"></hr>
  //    Ingredienser och recept har en list funktion, där du kan gå in i din receptlista och
  //    aktivera samt avvaktivera ingredienser och recept. Detta är speciellt användbart om
  //    du har många recept och säsongsvariationer då kan du enkelt aktivera recept och
  //    ingredienser inför en kommande säsong.<br></br><p></p>
  //    <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Skapa med vår data och AI</div>
  //    <hr className="basicServicesHr"></hr>
  //   Skapa nya recept med AI är en spännande miljö att arbeta i, oavsett om man med vår databas<br></br>
  //   och AI tillsammans får nya recept beroende på vad man söker efter så utgör det en källa
  //   till inspiration och utveckling, där man kan får nya ideer och uppslag. Vi matchar de förslagna receptens
  //   ingredienser emot de ingredienser du har i din ingrediens lista och de ingredienser du inte har
  //   lägger du enkelt till. <br></br><p></p>
  //   <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Ingrediens information</div>
  //   <hr className="basicServicesHr"></hr>
  //   Ingrediens information ger dig möjlighet att fördjupa dina kunskaper om en ingrediens. Du kanske undrar vart den 
  //   kommer ifrån osv. Ingrediens information ger dig möjlighet att snabbt få mer information om just
  //   den ingrediens du undrar över. <br></br><p></p>
  //   <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Nya idéer</div>
  //   <hr className="basicServicesHr"></hr>
  //   Recept idéer är en funktion som hjälper dig att inhämta nya idéer in i din verksamhet. Här söker man på mer generella
  //   termer som tex pasta med kyckling eller mjuka kakor med choklad eller liknande, och får då förslag på recept som matchar denna sökning.
  //   Genom att välja ett recept i listan så flyttas du till AI recept sökning som gör en sökning för dig på 
  //   denna nya idé och du får på kort tid ett helt nytt recept som utgångspunkt att arbeta med. PÅ detta sätt kan du snabbt
  //   utöka din receptbank, du kan ändra och uppdatera utan att byta miljö eller programvara. <br></br><p></p>
  //   <br></br><p></p>
  //   <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Nutrienter inkluderade</div>
  //   <hr className="basicServicesHr"></hr>
  //   Nutrient funktionen är en viktig del i bakesoft och möjliggör för dig att kunna se exakt vad ditt recept innehåller
  //   i form av kalorier, fett, protein m.m. Uppdateringer på dina ingredienser gör du igenom att hämta extern information
  //   i databaser vi har kontakt med i bakgrunden. I de fall du inte automatiskt kan hämta information så kan du själv enkelt
  //   uppdatera denna information i applikationen. <br></br><p></p>
  //   <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Konvertering</div>
  //   <hr className="basicServicesHr"></hr>
  //   Konverterings funktionen hjälper dig att konvertera volym till mängd. Då bakesoft måste kunna kalkylera exakta tal 
  //   använder inte Bakesoft volym mått som deciliter eller matskedar utan allt är i gram. Detta är en stor fördel då
  //   det hjälper dig att återskapa exakt samma recept varje gång. Konverteringen hjälper dig att konvertera tex
  //   en deciliter mjöl till gram. <br></br><p></p>
  //   <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Bild funktionalitet</div>
  //   <hr className="basicServicesHr"></hr>
  //   Vi har flera bildfunktioner så att du kan ladda upp bilder och generera bilder med hjälp av AI. För uppladdning av bilder
  //   väljer du enkelt receptet och laddar upp bilden. För generering av bilder med AI så väljer du receptet och AI genererar
  //   en bild utifrån den text du skriver in. Att generera bilder med AI kräver ett extra abonnemang och är inte inkluderat i
  //   vår bas prenumeration.<br></br><p></p> 
  //   <div className="basicServicesHeader"> <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Utökad funktionalitet</div>
  //   <hr className="basicServicesHr"></hr>
  //   Du kan enkelt aktivera extra funktioner igenom att gå till mitt konto och under tjänster
  //   välja den funktion du vill ha. När du valt funktion så skickas en faktura till dig, du betalar enkelt i applikationen
  //   under mitt konto, och då aktiveras funktionen direkt.  <br></br><p></p>
  //   <p></p>
  //   <p></p>
  //   <p></p>

  //   </div>
  // </div>
  // </div>
  // </div>
  // </div>

  <div className="basicServicesWrapper">
  <div className="basicServicesText">
    <div className="corporateBasBackgroundImage" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})` }}>
      <div className="transparentBoxCorporateBas">
        <div className="corporateBasServiceCommentText">

          <div className="basicServicesHeader"> 
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Grund funktionalitet
          </div>
          <hr className="basicServicesHr"/>
          Bas företag erbjuder AI-drivet receptskapande, manuell receptinmatning, och automatiska kostnadsberäkningar. Bakesoft gör det enkelt att skapa nya varianter av recept och uppdaterar automatiskt mängder, kostnader och näringsvärden.

          <div className="basicServicesHeader"> 
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Förstora eller förminska
          </div>
          <hr className="basicServicesHr"/>
          Justera receptstorlekar med ett klick, och gör enkla portionsindelningar vid produktutveckling.

          <div className="basicServicesHeader"> 
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Skapande av produkter
          </div>
          <hr className="basicServicesHr"/>
          Kombinera recept och ingredienser för att skapa nya produkter med kalkyler som hanteras i bakgrunden. Se omedelbart dina marginaler på prislistan.

          <div className="basicServicesHeader"> 
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Integritet
          </div>
          <hr className="basicServicesHr"/>
          Ditt recept är säkert och unikt för dig, skyddat från att kopieras av andra.

          <div className="basicServicesHeader"> 
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Återskapa värden
          </div>
          <hr className="basicServicesHr"/>
          Ändringar i ingredienspriser uppdateras automatiskt i alla dina recept och produkter.

          <div className="basicServicesHeader"> 
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Byt många med en
          </div>
          <hr className="basicServicesHr"/>
          Ersätt ingredienser i alla dina recept samtidigt med en enkel funktion.

          <div className="basicServicesHeader"> 
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Kategorier
          </div>
          <hr className="basicServicesHr"/>
          Hantera recept och ingredienser med kategorier för enkel organisering.

          <div className="basicServicesHeader"> 
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Av & På
          </div>
          <hr className="basicServicesHr"/>
          Aktivera eller inaktivera recept och ingredienser enkelt, särskilt användbart för säsongsvariationer.

          <div className="basicServicesHeader"> 
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Skapa med AI
          </div>
          <hr className="basicServicesHr"/>
          Skapa nya recept med AI för inspiration, baserat på ingredienser du har.

          <div className="basicServicesHeader"> 
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Ingrediensinformation
          </div>
          <hr className="basicServicesHr"/>
          Få detaljerad information om ingredienser för att fördjupa dina kunskaper.

          <div className="basicServicesHeader"> 
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Nya idéer
          </div>
          <hr className="basicServicesHr"/>
          Få receptidéer baserat på generella sökningar, och använd AI för att utveckla dem.

          <div className="basicServicesHeader"> 
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Nutrienter
          </div>
          <hr className="basicServicesHr"/>
          Se exakt näringsinnehåll i dina recept, med automatisk uppdatering från externa databaser.

          <div className="basicServicesHeader"> 
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Konvertering
          </div>
          <hr className="basicServicesHr"/>
          Konvertera volymmått till gram för exakt receptåtergivning.

          <div className="basicServicesHeader"> 
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Bildfunktioner
          </div>
          <hr className="basicServicesHr"/>
          Ladda upp bilder eller generera dem med AI för dina recept (AI-bilder kräver ett extra abonnemang).

          <div className="basicServicesHeader"> 
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Utökad funktionalitet
          </div>
          <hr className="basicServicesHr"/>
          Aktivera fler funktioner via kontotjänster för enkel fakturering och snabb aktivering.
        </div>
      </div>
    </div>
  </div>
</div>

  
)

  }
  function CorporateBasicEnglish(props){
    const { ukman1 } = useContext(MyContext);
    const { getServiceTab } = props;
  
    function getTotalInfoImageUrl(serviceTab) {
      if (serviceTab === 1) {
        return ukman1
      }
    }
    return(
  //     <div className="basicServicesWrapper">
  //     <div className="basicServicesText">
  //     <div className="infoBEnglish" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})` }}>
  //      <div className="transparentBoxCorporateBas">
  //     <div className="corporateBasServiceCommentText">
  
  
  // <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Basic main functionalities and features</div>
  // <hr className="basicServicesHr"></hr>
  // Basic offers key functionalities such as creating recipes with AI, creating recipes manually based on recipes and ingredients, and not just ingredients, which is the natural common way to create recipes.
  // <br></br><p></p>
  // Especially important is the automatic function that calculates the cost of your recipe. In an environment where the economy is a key factor, it is of utmost importance that the recipes you work with are economically sustainable.
  // <br></br><p></p>
  // Starting a recipe with another shortens the time it takes to create a new recipe. If you want to make a new variant of a cheesecake, you take an existing recipe, make your changes, and everything is done. Bakesoft automatically calculates cost, quantity, and nutrients such as calories, fat, protein, etc.
  // <p></p>
  // <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Increase or decrease</div>
  // <hr className="basicServicesHr"></hr>
  // Scale recipes up and down with a click. This opens up the possibility to change directly during work and to adjust the size of different recipes to each other.
  // <p></p>
  // <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Portions</div>
  // <hr className="basicServicesHr"></hr>
  // You can also portion your recipes, which is effective especially when creating a new product. Since a product always applies to an exact selected amount.
  // <br></br><p></p>
  // <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Creating products</div>
  // <hr className="basicServicesHr"></hr>
  // When creating a product, you combine recipes and ingredients, making it incredibly easy and exciting to productize your recipes. You can take part of a dough, part of another recipe, and add a touch of another ingredient, and Bakesoft handles everything in the background. You name the product and save it, and everything is ready.
  // <br></br><p></p>
  // In the price list, you set the price, and you can immediately see the margin (net profit) you have on your newly created product. When you combine different recipes and ingredients into a product, the software performs all calculations in the background.
  // <p></p>
  // <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Integrity</div>
  // <hr className="basicServicesHr"></hr>
  // It is important to add here that your recipes & products are yours, the database's structure makes it impossible to share your recipes with others in our application. We protect your recipes and your privacy by giving you your own system that cannot be copied by others. Every recipe or product you create is unique in our system. When we create your account, thousands of rows are generated into our system that are unique to you, your recipes, and products.
  // <br></br><p></p>
  // <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Recreating values</div>
  // <hr className="basicServicesHr"></hr>
  // When you change the purchase price of an ingredient, the price of all recipes and products changes immediately. This saves a lot of time; if you have 100 recipes and as many products and all contain sugar or milk, you don't have to recalculate your recipes manually. Bakesoft handles this in the background, including all calculations regarding nutrients.
  // <br></br><p></p>
  // <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Replacing many with one</div>
  // <hr className="basicServicesHr"></hr>
  // For ingredients, you can also replace an ingredient with another. This means that if you, for example, use one type of butter in 50 recipes, you can replace an ingredient with another in all your recipes at once. You select the ingredient and the recipes that will be affected, and with one click, everything is updated.
  // <br></br>
  // <br></br>
  // <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Categories</div>
  // <hr className="basicServicesHr"></hr>
  // Since there are often many recipes & ingredients, you can categorize them with our category function, which helps you create, change, and delete categories easily. You also get a good overview, and you can move one or more recipes to another category at once.
  // <br></br>
  // <br></br>
  // <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>On & Off</div>
  // <hr className="basicServicesHr"></hr>
  // Ingredients and recipes have a list function, where you can enter your recipe list and activate and deactivate ingredients and recipes. This is especially useful if you have many recipes and seasonal variations; you can easily activate recipes and ingredients for an upcoming season.
  // <br></br><p></p>
  // <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Creating with our data and AI</div>
  // <hr className="basicServicesHr"></hr>
  // Creating new recipes with AI is an exciting environment to work in. When you use our database and AI together to get new recipes depending on what you're looking for, it serves as a source of inspiration and development, where you can get new ideas and suggestions. We match the proposed recipes' ingredients against the ingredients you have in your ingredient list, and the ones you don't have, you can easily add.
  // <br></br><p></p>
  // <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Ingredient information</div>
  // <hr className="basicServicesHr"></hr>
  // Ingredient information allows you to deepen your knowledge about an ingredient. You might wonder where it comes from, etc. Ingredient information allows you to quickly get more information about the ingredient you're curious about.
  // <br></br><p></p>
  // <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Generating ideas</div>
  // <hr className="basicServicesHr"></hr>
  // Recipe ideas are a feature that helps you bring new ideas into your business. Here you search for more general terms such as pasta with chicken or soft cakes with chocolate or similar, and you get suggestions for recipes that match this search. By selecting a recipe in the list, you move to AI recipe search, which performs a search for you on this new idea, and you quickly get a brand-new recipe as a starting point to work with. This way, you can quickly expand your recipe bank, change, and update without switching environments or software.
  // <br></br><p></p>
  // <br></br><p></p>
  // <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Nutrients included</div>
  // <hr className="basicServicesHr"></hr>
  // The nutrient function is an important part of Bakesoft and allows you to see exactly what your recipe contains in terms of calories, fat, protein, etc. You update your ingredients by retrieving external information from databases we have contact with in the background. In cases where you cannot automatically retrieve information, you can easily update this information in the application yourself.
  // <br></br><p></p>
  // <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Conversion of values</div>
  // <hr className="basicServicesHr"></hr>
  // The conversion function helps you convert volume to weight. Since Bakesoft must be able to calculate exact numbers, it does not use volume measures such as deciliters or tablespoons; everything is in grams. This is a great advantage as it helps you recreate exactly the same recipe every time. The conversion helps you convert, for example, a deciliter of flour to grams.
  // <br></br><p></p>
  // <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Image functionalities</div>
  // <hr className="basicServicesHr"></hr>
  // We have several image functionalities so that you can upload images and generate images using AI. For uploading images, you simply choose the recipe and upload the image. For generating images with AI, you select the recipe, and AI generates an image based on the text you enter. Generating images with AI requires an additional subscription and is not included in our basic subscription.
  // <br></br><p></p>
  // <div className="basicServicesHeader"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Extended functionality</div>
  // <hr className="basicServicesHr"></hr>
  // You can easily activate extra functions by going to my account and under services, select the function you want. When you have chosen a function, an invoice is sent to you, and you pay easily in the application under my account, and then the function is activated immediately.
  // <br></br><p></p>
  // <p></p>
  // <p></p>
  // <p></p>
  // </div>
  // </div>
  // </div>
  // </div>
  // </div>


<div className="basicServicesWrapper">
  <div className="basicServicesText">
    <div className="infoBEnglish" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})` }}>
      <div className="transparentBoxCorporateBas">
        <div className="corporateBasServiceCommentText">

          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Basic functionalities and features
          </div>
          <hr className="basicServicesHr"/>
          Basic includes AI-driven and manual recipe creation, with automatic cost calculations to ensure economic sustainability. Quickly modify existing recipes for new variants, and Bakesoft handles the calculations for cost, quantity, and nutrients automatically.
          <p></p>
          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Scale Recipes
          </div>
          <hr className="basicServicesHr"/>
          Easily scale recipes up or down with a click, and adjust portion sizes for product creation.
          <p></p>
          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Product Creation
          </div>
          <hr className="basicServicesHr"/>
          Combine recipes and ingredients to create products effortlessly. Set prices and view margins instantly in the price list, with all calculations handled in the background.
          <p></p>
          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Privacy and Integrity
          </div>
          <hr className="basicServicesHr"/>
          Your recipes and products are private, unique to your account, and protected from sharing.
          <p></p>
          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Automatic Updates
          </div>
          <hr className="basicServicesHr"/>
          Ingredient price changes are reflected across all recipes instantly, saving time on recalculations.
          <p></p>
          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Replace Ingredients
          </div>
          <hr className="basicServicesHr"/>
          Replace an ingredient in multiple recipes with a single action, simplifying bulk updates.
          <p></p>
          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Categories
          </div>
          <hr className="basicServicesHr"/>
          Organize recipes and ingredients into categories for easy management and quick updates.
          <p></p>
          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> On & Off
          </div>
          <hr className="basicServicesHr"/>
          Activate or deactivate recipes and ingredients easily, ideal for seasonal variations.
          <p></p>
          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> AI Recipe Creation
          </div>
          <hr className="basicServicesHr"/>
          Use AI to generate new recipes based on your ingredients, inspiring new ideas and variations.
          <p></p>
          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Ingredient Information
          </div>
          <hr className="basicServicesHr"/>
          Get detailed information on ingredients to expand your knowledge.
          <p></p>
          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> New Recipe Ideas
          </div>
          <hr className="basicServicesHr"/>
          Search for recipe ideas based on general terms and use AI to create new recipes quickly.
          <p></p>
          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Nutrients
          </div>
          <hr className="basicServicesHr"/>
          View detailed nutrient information for your recipes, updated automatically from external databases.
          <p></p>
          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Conversion
          </div>
          <hr className="basicServicesHr"/>
          Convert volume to weight (e.g., deciliters to grams) for precise recipe calculations.
          <p></p>
          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Image Features
          </div>
          <hr className="basicServicesHr"/>
          Upload or generate images using AI for your recipes (AI image generation requires an additional subscription).
          <p></p>
          <div className="basicServicesHeader">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/> Extended Features
          </div>
          <hr className="basicServicesHr"/>
          Activate extra features from your account, with instant access upon payment.
          <p></p>

        </div>
      </div>
    </div>
  </div>
</div>




    )
  }
  function CorporateBasicJapanese(props){
    const { jpwoman3 } = useContext(MyContext);
    const { getServiceTab } = props;
  
    function getTotalInfoImageUrl(serviceTab) {
      if (serviceTab === 1) {
        return jpwoman3
      }
    }
    return(
  //     <div className="basicServicesWrapper">
  //     <div className="basicServicesText">
  //     <div className="infoBEnglish" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})` }}>
  //     <div className="transparentBoxCorporateBas">
  
  //     <div className="basicServicesCommentTextJAP">
  //     <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>基本的な主な機能と特徴</div>
  // <hr className="basicServicesHr"></hr>
  // Basicは、AIでレシピを作成する機能、レシピと食材に基づいて手動でレシピを作成する機能、そして通常の一般的なレシピ作成方法である食材だけでなく、レシピを作成するための基本的な機能を提供します。
  // <br></br><p></p>
  // 特に重要なのは、レシピのコストを計算する自動機能です。経済が重要な要素である環境では、使用するレシピが経済的に持続可能であることが最も重要です。
  // <br></br><p></p>
  // 別のレシピを使って新しいレシピを始めることで、新しいレシピを作成する時間を短縮できます。新しいチーズケーキのバリエーションを作りたい場合は、既存のレシピを取り、変更を加えるだけですべてが完了します。Bakesoftは、コスト、量、カロリー、脂肪、タンパク質などの栄養素を自動的に計算します。
  // <p></p>
  // <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>増減</div>
  // <hr className="basicServicesHr"></hr>
  // クリック一つでレシピを拡大縮小できます。これにより、作業中に直接変更し、異なるレシピのサイズを調整することが可能になります。
  // <p></p>
  // <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>ポーション</div>
  // <hr className="basicServicesHr"></hr>
  // レシピをポーションに分けることもできます。これは、新しい製品を作成する際に特に効果的です。製品は常に正確に選ばれた量に基づいているからです。
  // <br></br><p></p>
  // <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>製品の作成</div>
  // <hr className="basicServicesHr"></hr>
  // 製品を作成する際に、レシピと食材を組み合わせることで、レシピを製品化するのが非常に簡単でエキサイティングになります。生地の一部、別のレシピの一部、さらに別の食材の一部を取り入れ、Bakesoftがすべてをバックグラウンドで処理します。製品に名前を付けて保存すると、すべてが完了します。
  // <br></br><p></p>
  // 価格リストでは、価格を設定し、新しく作成した製品の利益率（純利益）をすぐに確認できます。異なるレシピと食材を製品に組み合わせると、ソフトウェアがすべての計算をバックグラウンドで実行します。
  // <p></p>
  // <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>インテグリティ</div>
  // <hr className="basicServicesHr"></hr>
  // ここで重要なのは、あなたのレシピと製品はあなたのものであり、データベースの構造上、他の人と共有することができないということです。私たちは、あなたのレシピとプライバシーを保護し、他の人がコピーできない独自のシステムを提供します。あなたが作成するすべてのレシピや製品は、私たちのシステム内でユニークです。アカウントを作成する際に、あなた専用の何千もの行がシステムに生成されます。
  // <br></br><p></p>
  // <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>価値の再作成</div>
  // <hr className="basicServicesHr"></hr>
  // 食材の購入価格を変更すると、すべてのレシピと製品の価格が即座に変更されます。100のレシピと同じ数の製品があり、それらすべてに砂糖や牛乳が含まれている場合、手動でレシピを再計算する必要はありません。Bakesoftは、背景でこれを処理し、栄養素に関するすべての計算を行います。
  // <br></br><p></p>
  // <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>多くを一つに置き換える</div>
  // <hr className="basicServicesHr"></hr>
  // 食材についても、ある食材を別の食材に置き換えることができます。例えば、50のレシピで一種類のバターを使用している場合、すべてのレシピで一度に食材を置き換えることができます。食材を選択し、影響を受けるレシピを選び、クリック一つですべてが更新されます。
  // <br></br>
  // <br></br>
  // <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>カテゴリ</div>
  // <hr className="basicServicesHr"></hr>
  // レシピや食材が多くなることが多いため、カテゴリ機能を使用してこれらをカテゴリ分けすることができます。これにより、カテゴリを簡単に作成、変更、削除することができ、良好な概要を得ることができます。また、一つまたは複数のレシピを一度に別のカテゴリに移動することもできます。
  // <br></br>
  // <br></br>
  // <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>オンとオフ</div>
  // <hr className="basicServicesHr"></hr>
  // 食材とレシピにはリスト機能があり、レシピリストに入り、食材とレシピを有効化および無効化することができます。これは、特に多くのレシピや季節のバリエーションがある場合に便利で、次の季節に向けてレシピや食材を簡単に有効化できます。
  // <br></br><p></p>
  // <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>私たちのデータとAIで作成</div>
  // <hr className="basicServicesHr"></hr>
  // AIで新しいレシピを作成するのは、非常にエキサイティングな環境です。データベースとAIを組み合わせて、新しいレシピを探すことで、新しいアイデアや提案を得ることができます。提案されたレシピの食材を、あなたの食材リストの食材と照合し、持っていない食材は簡単に追加できます。
  // <br></br><p></p>
  // <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>食材情報</div>
  // <hr className="basicServicesHr"></hr>
  // 食材情報は、食材に関する知識を深めることができます。その食材の出身地などについて疑問がある場合に、すぐに情報を得ることができます。
  // <br></br><p></p>
  // <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>アイデアの生成</div>
  // <hr className="basicServicesHr"></hr>
  // レシピアイデアは、新しいアイデアをビジネスに取り入れるのに役立つ機能です。例えば、「鶏肉のパスタ」や「チョコレートの柔らかいケーキ」などの一般的な用語で検索し、その検索に一致するレシピの提案を得ることができます。リストのレシピを選択すると、AIレシピ検索に移動し、新しいアイデアに基づいた検索を行い、すぐに新しいレシピを得ることができます。この方法で、レシピバンクを迅速に拡張し、環境やソフトウェアを切り替えることなく、変更や更新を行うことができます。
  // <br></br><p></p>
  // <br></br><p></p>
  // <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>栄養素を含む</div>
  // <hr className="basicServicesHr"></hr>
  // 栄養素機能はBakesoftの重要な部分であり、レシピに含まれるカロリー、脂肪、タンパク質などを正確に確認できます。食材の情報を外部データベースから取得して更新します。自動的に情報を取得できない場合は、アプリケーション内で簡単に情報を更新できます。
  // <br></br><p></p>
  // <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>変換</div>
  // <hr className="basicServicesHr"></hr>
  // 変換機能は、ボリュームを重量に変換するのに役立ちます。Bakesoftは正確な数値を計算する必要があるため、デシリットルや大さじのようなボリューム測定を使用せず、すべてグラムで表されます。これにより、毎回同じレシピを正確に再現することができます。変換機能は、例えば、デシリットル
  
  // の小麦粉をグラムに変換するのに役立ちます。
  // <br></br><p></p>
  // <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>画像機能</div>
  // <hr className="basicServicesHr"></hr>
  // 複数の画像機能があり、画像をアップロードしたり、AIを使用して画像を生成したりすることができます。画像をアップロードする場合は、レシピを選択し、画像をアップロードします。AIで画像を生成する場合は、レシピを選択し、入力したテキストに基づいてAIが画像を生成します。AIでの画像生成には追加のサブスクリプションが必要で、基本サブスクリプションには含まれていません。
  // <br></br><p></p>
  // <div className="basicServicesHeaderJAPTEXT"><IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>拡張機能</div>
  // <hr className="basicServicesHr"></hr>
  // 追加機能を有効にするには、マイアカウントに移動し、サービスの下で希望する機能を選択するだけです。機能を選択すると、請求書が送信され、マイアカウントで簡単に支払い、機能が即座に有効化されます。
  // <br></br><p></p>
  // <p></p>
  // <p></p>
  // <p></p>
  
  // </div>
  // </div>
  // </div>
  // </div>
  // </div>


  <div className="basicServicesWrapper">
  <div className="basicServicesText">
    <div className="infoBEnglish" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})` }}>
      <div className="transparentBoxCorporateBas">
        <div className="basicServicesCommentTextJAP">

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>基本機能と特徴
          </div>
          <hr className="basicServicesHr"/>
          Basicは、AIおよび手動でレシピを作成する機能を提供し、コストを自動で計算します。経済的に持続可能なレシピ作成が可能です。既存のレシピを使って、新しいレシピを短時間で作成できます。Bakesoftが自動でコストや栄養素を計算します。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>増減機能
          </div>
          <hr className="basicServicesHr"/>
          クリックでレシピを拡大・縮小可能。作業中に簡単にサイズ調整ができます。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>ポーション
          </div>
          <hr className="basicServicesHr"/>
          レシピをポーションごとに分割でき、新しい製品の作成時に便利です。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>製品作成
          </div>
          <hr className="basicServicesHr"/>
          レシピと食材を組み合わせて製品を簡単に作成し、価格リストで利益率をすぐに確認できます。すべての計算はバックグラウンドで処理されます。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>インテグリティ
          </div>
          <hr className="basicServicesHr"/>
          あなたのレシピは安全で、システム内でユニークです。共有やコピーはできません。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>価値の再計算
          </div>
          <hr className="basicServicesHr"/>
          食材の価格変更は即座に全レシピと製品に反映され、栄養素の計算も自動で行います。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>一括置換
          </div>
          <hr className="basicServicesHr"/>
          1つの食材を複数のレシピで一括して別の食材に置き換えることができます。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>カテゴリ分け
          </div>
          <hr className="basicServicesHr"/>
          レシピと食材を簡単にカテゴリ分けして整理できます。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>オン/オフ
          </div>
          <hr className="basicServicesHr"/>
          レシピや食材を有効/無効にする機能で、季節ごとの調整が可能です。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>AIで作成
          </div>
          <hr className="basicServicesHr"/>
          AIで新しいレシピを作成し、提案された食材をリストに追加できます。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>食材情報
          </div>
          <hr className="basicServicesHr"/>
          食材の詳細情報を簡単に確認できます。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>アイデア生成
          </div>
          <hr className="basicServicesHr"/>
          レシピ検索で新しいアイデアを得て、AIでレシピを自動生成。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>栄養素
          </div>
          <hr className="basicServicesHr"/>
          レシピの栄養素情報を正確に確認し、外部データベースから自動更新。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>変換機能
          </div>
          <hr className="basicServicesHr"/>
          容量を重量に変換し、正確なレシピ再現が可能です。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>画像機能
          </div>
          <hr className="basicServicesHr"/>
          画像のアップロードとAIによる生成が可能です（追加サブスクリプションが必要）。
          <p></p>

          <div className="basicServicesHeaderJAPTEXT">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>拡張機能
          </div>
          <hr className="basicServicesHr"/>
          追加機能をアカウントからすぐに有効化できます。
          <p></p>

        </div>
      </div>
    </div>
  </div>
</div>

  
    )
  }
  function CorporateBasicInfoTextSwedish(props){
    const { yukiinbakery2 } = useContext(MyContext);
    const { getServiceTab } = props;
  
    function getTotalInfoImageUrl(serviceTab) {
      if (serviceTab === 2) {
        return jpwoman
      }
    }
    return(
      <div className="basicServicesWrapper">
               <div className="infoBEnglish" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})` }}>
               <div className="transparentBoxCorporateBasFeatures">
      <div className="basicServicesHeader">Följande ingår i Bas Företag prenumeration</div>
      <div className="basicServicesListText">
      <ol className="basicServicesList">
    
      <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Spara upp till 500 recept<br></br>
      <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Använd upp till 500 ingredienser<br></br>
      <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Skapa upp till 200 ingrediens-kategorier<br></br>
      <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Sök och skapa recept med AI,75st per månad<br></br>
      <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Skapa recept manuellt med ingredienser och recept<br></br>
      <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Sök recept ideer med AI, 75 st per månad.<br></br>
      <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Skapa bilder med AI for recept*(kostnad tillkommer)<br></br>
      <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Ladda upp egna bilder på recept<br></br>
      <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Spara upp till 1000 bilder<br></br>
      <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Skapa upp till 200 recept-kategorier<br></br>
      <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Automatisk uträkning av näringsämnen på recept.<br></br>
      <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Automatisk uträkning av kostnad för recept<br></br>
      <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Automatisk matchning av ingredienser<br></br>
      <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Automatisk hämtning av näringsämnen<br></br>
      <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Informations om ingredienser 50st per månad<br></br>
      <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Konvertera volym till gram<br></br>
      <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Hantera ingrediens-recept kopplingar<br></br>
      <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Flytta och hantera kopplingar automatiskt<br></br>
      <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Hantera priser på ingredienser<br></br>
      <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Automatisk pris diagram för ingredienser<br></br>
      <IoShieldCheckmarkOutline className="basicServicesCheckIcon"/>Humoristiska kommentarer ifrån vår AI<br></br>
        </ol>
    </div>
    </div>
    </div>
    </div>
    )
  }
  function CorporateBaicInfoTextEnglish(props){
    const { jpwoman3 } = useContext(MyContext);
const { getServiceTab } = props;

function getTotalInfoImageUrl(serviceTab) {
  if (serviceTab === 2) {
    return jpwoman3;
  }
}

return (
  <div className="basicServicesWrapper">
    <div className="infoBEnglish" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})` }}>
      <div className="transparentBoxCorporateBasFeatures">
        <div className="basicServicesHeader">The following are included in the Basic Corporate subscription</div>
        <div className="basicServicesListText">
          <ol className="basicServicesList">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Save up to 500 recipes<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Use up to 500 ingredients<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Create up to 200 ingredient categories<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Search and create recipes with AI, 75 per month<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Create recipes manually with ingredients and instructions<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Search recipe ideas with AI, 75 per month<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Create images with AI for recipes (additional cost)<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Upload your own recipe images<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Save up to 1000 images<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Create up to 200 recipe categories<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Automatic nutrient calculation for recipes<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Automatic cost calculation for recipes<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Automatic ingredient matching<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Automatic nutrient retrieval<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Ingredient information lookup, 50 per month<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Convert volume to grams<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Manage ingredient-recipe connections<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Move and manage connections automatically<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Manage ingredient prices<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Automatic price charts for ingredients<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Get fresh and slightly humorous comments from our AI<br />
          </ol>
        </div>
      </div>
    </div>
  </div>
);
    }
    function CorporateBasicInfoTextJapanese(props){
      const { jpman1 } = useContext(MyContext);
const { getServiceTab } = props;

function getTotalInfoImageUrl(serviceTab) {
  if (serviceTab === 2) {
    return jpman1;
  }
}

return (
  <div className="basicServicesWrapper">
    <div className="infoBEnglish" style={{ backgroundImage: `url(${getTotalInfoImageUrl(getServiceTab)})` }}>
      <div className="transparentBoxCorporateBasFeatures">
        <div className="basicServicesHeader">ベーシック企業サブスクリプションに含まれる内容</div>
        <div className="basicServicesListText">
          <ol className="totalServicesListJAP">
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> 最大500件のレシピを保存<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> 最大500種類の食材を使用<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> 最大200件の食材カテゴリを作成<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> AIでレシピを検索・作成、月75件<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> 食材とレシピを手動で作成<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> AIでレシピのアイデアを検索、月75件<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> AIでレシピの画像を作成（追加料金がかかります）<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> 独自のレシピ画像をアップロード<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> 最大1000件の画像を保存<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> 最大200件のレシピカテゴリを作成<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> レシピの栄養素を自動計算<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> レシピのコストを自動計算<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> 食材の自動マッチング<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> 栄養素を自動取得<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> 月50件の食材情報の検索<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> 容量をグラムに変換<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> 食材とレシピの関連付けを管理<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> 関連付けを自動で移動および管理<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> 食材の価格を管理<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> 食材の価格チャートを自動生成<br />
            <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> AIからの新鮮で少しユーモラスなコメントを取得<br />
          </ol>
        </div>
      </div>
    </div>
  </div>
);
    }

function SignUpTop(props) {
  let { registerAccountTabText, paymentTabText, getNextTab,getSignupInfo,getServiceTabStatus } = useContext(MyContext);

//console.log(getSignupInfo)

  const [getServiceTab, setServiceTab] = useState(1);
  const [isServiceTab1Disabled, setServiceTab1Disabled] = useState(false); 

  useEffect(() => {
    if (getNextTab > 1) {
      setServiceTab(getNextTab);
      setServiceTab1Disabled(true);
    }
  }, [getNextTab]);

  useEffect(() => {
    setServiceTab1Disabled(false);
    setServiceTab(1);
  }, [getServiceTabStatus]);

  return (
    <div className="baseServicesTop">
      <div className="baseServicesTabTop">
        <button
          className={`baseServicesmobileTab ${getServiceTab === 1 ? 'active' : ''}`}
          onClick={() => setServiceTab(1)}
          disabled={isServiceTab1Disabled} 
        >
          {registerAccountTabText}
        </button>
        <button
          className={`baseServicesmobileTab ${getServiceTab === 2 ? 'active' : ''}`}
          onClick={() => setServiceTab(2)}
          disabled={isServiceTab1Disabled===false}
        >
          {paymentTabText}
        </button>
      </div>
{/* 
      <div className="baseServicesTabContent">
        {getServiceTab === 1 ? <SignUp getServiceTab={getServiceTab} /> : ''}
        {getServiceTab === 2 && getSignupInfo.currency!=='1' ? <Payment getServiceTab={getServiceTab} /> :PaymentKlarna(getServiceTab)}
      </div> */}
    
    <div className="baseServicesTabContent">
        {getServiceTab === 1 ? <SignUp getServiceTab={getServiceTab} /> : ''}
        {getServiceTab === 2 ?<PaymentOptions /> : ''}
      </div>
    </div>
  );
}
function PaymentOptions(props){
const ctx = useContext(MyContext)
let klarna = ctx.klarna //klarna payment variable
const [getPayMentType, setPaymentType] = useState(0);
const [openKlarna, setOpenKlarna] = useState(true);


const setPaymentTypeHandler = (e) => {
  setPaymentType(e);
  setOpenKlarna(true)
}

  return(
    // getPayMentType === 0 && getShowPaymentOption===false ? (
    //   <div className="paymentOptionWrapper">

    //     {ctx.getSignupInfo.currency=='1'?(
    //     <div className="klarnaInitialButtonInfo" onClick={() => setPaymentTypeHandler(1)}>
    //       <div className="klarnaMessageInfo">Betala med&nbsp;</div>
    //           <div className="klarnalogoWrapperInfo">
    //              <img src={ctx.klarnaimage} alt="Klarna Logo" className="klarnaButtonImageInfo" />
    //           </div>
    //     </div>
    //    ) :''}

    //     <div className="payPalInitialButtonInfo" onClick={() => setPaymentTypeHandler(2)}>
    //       <div className="payPalMessageInfo">Betala med&nbsp;</div>
    //       <div className="paypallogoWrapperInfo">
    //         <img src="https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_37x23.jpg"
    //           alt="PayPal Logo" className="payPalButtonImageInfo" />
    //       </div>
    //     </div>
    //   </div>
    // ) : getPayMentType===2?<Payment/>:<KlarnaPayment openKlarna={openKlarna} klarna={klarna}/>

  
    <>
    {getPayMentType === 0 && (
      <div className="paymentOptionWrapper">

        {ctx.getSignupInfo.currency === '1' && (
          <div className="klarnaInitialButtonInfo" onClick={() => setPaymentTypeHandler(1)}>
            <div className="klarnaMessageInfo">Betala med&nbsp;</div>
            <div className="klarnalogoWrapperInfo">
              <img src={ctx.klarnaimage} alt="Klarna Logo" className="klarnaButtonImageInfo" />
            </div>
          </div>
        )}

        <div className="payPalInitialButtonInfo" onClick={() => setPaymentTypeHandler(2)}>
          <div className="payPalMessageInfo">Betala med&nbsp;</div>
          <div className="paypallogoWrapperInfo">
            <img src="https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_37x23.jpg"
              alt="PayPal Logo" className="payPalButtonImageInfo" />
          </div>
        </div>
      </div>
    )}

    {getPayMentType === 2 && <Payment />}

    {getPayMentType === 1  && (
      <KlarnaPayment openKlarna={openKlarna} klarna={klarna} />
    )}

{ctx.finalPay &&(
      <div className="finalPaymentInfoTopWrapper">

<div className="finalPaymentMessageInfo">Tack för er betalning!</div>
<Link to="/login" className="finalPaymentLinkInfo">Logga in här</Link>
      </div>
    )}
  </>


    
  
  )
}

function KlarnaPayment(props){
  let {openKlarna,klarna}=props


const ctx=useContext(MyContext)
     useEffect(() => {
    ctx.fetchKlarnaPaymentSession();
     }, []);

     useEffect(() => {
      // // Check if the clientToken is available
      // if (!ctx.clientToken) {
      //     console.error("Client token is missing or invalid");
      //     return;
      // }

      // Load the Klarna script
      const script = document.createElement('script');
      script.src = 'https://x.klarnacdn.net/kp/lib/v1/api.js';
      script.async = true;
      document.head.appendChild(script);

      // Initialize Klarna Payments once the script is loaded
      script.onload = () => {
          if (window.Klarna) {
              try {
                  window.Klarna.Payments.init({
                      client_token:ctx.clientToken, // Use the passed clientToken
                  });

                  window.Klarna.Payments.load({
                      container: '#klarna_container',
                      payment_method_category: 'pay_over_time',
                  }, function (res) {
                      console.log('Klarna payment method loaded');
                      console.debug(res);
                  });
              } catch (error) {
                  //console.error("Error initializing Klarna Payments:", error);
              }
          }
      };

      return () => {
          // Cleanup: remove the script when the component unmounts
          document.head.removeChild(script);
      };
  }, [ctx.clientToken]); // Depend on the clientToken passed as a prop


  function HandleAuthorize ()  {
   

      if (window.Klarna) {
          window.Klarna.Payments.authorize({
              payment_method_category: 'pay_over_time',
          }, {
              billing_address: {
                  given_name:ctx.getSignupInfo.firstName,
                  family_name:ctx.getSignupInfo.lastName,
                  email: ctx.getSignupInfo.email,
                  street_address:ctx.getSignupInfo.address,
                  postal_code:ctx.getSignupInfo.postalCode,
                  city:ctx.getSignupInfo.city,
                  region:ctx.getSignupInfo.region,
                  phone:ctx.getSignupInfo.phone,
                  country:'SE',
                  
              },
              order_amount:ctx.klarnaTotalAmount,
              order_tax_amount:ctx.klarnaTotalTaxAmount,
              order_lines: [
                  {
                      type: 'physical',
                      reference:ctx.getSignupInfo.service,
                      name: ctx.serviceName,
                      quantity: 1,
                      unit_price:ctx.klarnaTotalAmount,
                      tax_rate:ctx.klarnaTaxRate,
                      total_amount:ctx.klarnaTotalAmount,
                      total_discount_amount: 0,
                      total_tax_amount:ctx.klarnaTotalTaxAmount,
                  },
              ],
          }, function (res) {
              if (res.approved) {
                  console.log('Payment authorized', res.authorization_token);
                  
                  // Send the authorization_token to your backend to finalize the order
                  ctx.finalizeKlarnaPayment(res.authorization_token)
              } else {
                  ctx.setKlarna(false)
                  ctx.setAlertCode(0)
                  ctx.setAlertMessage(ctx.klarnaPaymentfailureText|| 'Payment authorization failed')
                  ctx.setShowAlert(true)
                  console.error('Payment authorization failed', res);
                  ctx.ClearUserfromSystem()
              }
          });
      }
  };







  return(

    openKlarna && klarna ? (
  // <div style={{ width: '350px', paddingTop: '10px', paddingBottom: '10px' }}>
  <div className="klarnaTop">
  <img src="https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.svg" alt="Klarna" className="klarnaLogoSize" />
  {/* <div id="klarna_container" style={{ width: '250px', margin: 'auto' }}></div>
  <button onClick={HandleAuthorize} style={{ width: '250px', height: '50px', margin: 'auto', display: 'block' }}>
      Your Buy Button
  </button> */}
  <div id="klarna_container" style={{ width: '350px', margin: 'auto' }}></div>
          <div className="klarnaInitialButtonInfoFinal" onClick={HandleAuthorize}>
              <div className="klarnaMessageFinal">Betala med&nbsp;</div>
              <div className="klarnalogoWrapper">
                  <img src={ctx.klarnaimage} alt="Klarna Logo" className="klarnaButtonImage" />
              </div>
          </div>

          <div className="ppKlarnaTextWrapper">
          <div className="ppKlarnatext">{ctx.pptotalAmountToPayText}:&nbsp;{ctx.klarnaTotalAmount/100}&nbsp;{'SEK'}</div>
          <div className="ppKlarnatext">{ctx.ppChosenServiceText}:&nbsp;{ctx.serviceName}</div>
          <div className="ppKlarnatext">{ctx.ppBakesoftTermsText}:&nbsp;{ctx.ppBakesoftTerms}</div>
          <div className="ppKlarnatext">{ctx.ppChosenCurrencyText}:&nbsp;{'SEK'}</div>
       </div>
  

</div>
  ) : ''
     
  )
}


    function Payment(props){
    let{getSignupInfo,xchangeRate,customerPrices,currencySymbol,getUserId,getCountry,
      paypalMainHeaderText,pptotalAmountToPayText,ppChosenServiceText,ppChosenCurrencyText,
      ppBakesoftTermsText,ppBakesoftTerms,setAlertMessage,setShowAlert,paypalPaymentConfirmText,
      CustomerPaymentHandler,getVatInfo
    }=useContext(MyContext)
 
  
    let vatRate = Array.isArray(getVatInfo) && getVatInfo.length > 0 
    ? getVatInfo.find(vat => vat.cc === String(getCountry))?.vatRate ?? '' 
    : '';
  

let basicPrice = customerPrices.find(data => data.serviceid === 127).price 
let basicServiceName= customerPrices.find(data => data.serviceid === 127 && data.cc===getCountry).benaemn
let totalPrice= customerPrices.find(data => data.serviceid === 128).price
let totalServiceName= customerPrices.find(data => data.serviceid === 128 && data.cc===getCountry).benaemn
let corporateBasicPrice = customerPrices.find(data => data.serviceid === 133).price
let corporateBasicName=customerPrices.find(data => data.serviceid === 133 && data.cc===getCountry).benaemn
let corporateTotalPrice = customerPrices.find(data => data.serviceid === 134).price
let corporateTotalName=customerPrices.find(data => data.serviceid === 134 && data.cc===getCountry).benaemn
let countryXchange = xchangeRate.find(rate => rate.currencyid ==String(getSignupInfo.currency))?.xchangeRate ?? '';
let currencyName=currencySymbol.find(currency => currency.currencyid == String(getSignupInfo.currency))?.currencyName ?? '';


let serviceName
let amount;
let chosenServiceId= getSignupInfo.service
if(chosenServiceId == 127){
  amount = Math.floor(basicPrice/countryXchange)  * (1 + (vatRate / 100))
  serviceName=basicServiceName
}
else if(chosenServiceId == 128){

  amount = Math.floor(totalPrice/countryXchange)* (1 + (vatRate / 100))
  serviceName=totalServiceName
}
else if(chosenServiceId == 133){
  amount = Math.floor(corporateBasicPrice/countryXchange)* (1 + (vatRate / 100))
  serviceName=corporateBasicName
}
else if(chosenServiceId == 134){
  amount = Math.floor(corporateTotalPrice/countryXchange)* (1 + (vatRate / 100))
  serviceName=corporateTotalName
}

const initialOptions = {
//clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
clientId:'AQjrDlmolcZ3ZiCPL6LFPQQotV96Ry7kq2qYJ3wH6AP4qlUxyjc7gorHVhB0pRx0R0iUjqnCNugpZlrW',

  currency: currencyName,
};
const styles = {
  shape: "rect",
  layout: "vertical",

};
      return(

        <div className="payPalWrapper">
          {/* <div className="payPalBakesoftHeaderWrapper">
          <div className="payPalBakesoftHeader">{paypalMainHeaderText}</div>¨
          </div> */}
        <div className="ppBakesoftTextWrapper">
          <div className="ppBakesofttext">{pptotalAmountToPayText}:&nbsp;{amount}&nbsp;{currencyName}</div>
          <div className="ppBakesofttext">{ppChosenServiceText}:&nbsp;{serviceName}</div>
          <div className="ppBakesofttext">{ppBakesoftTermsText}:&nbsp;{ppBakesoftTerms}</div>
          <div className="ppBakesofttext">{ ppChosenCurrencyText}:&nbsp;{currencyName}</div>
       </div>
      

   
       <div className="payPalButtonWrapper">
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
    
          style={styles}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [{
                amount: {
                  currency_code: String(currencyName),
                  value: amount // Set your amount here
                },
                custom_id: JSON.stringify({
                  userId: getUserId,
                  serviceId: chosenServiceId,
                  languageCode: getSignupInfo.language,
                }),
              }],
              application_context: {
                shipping_preference: 'NO_SHIPPING', // Disable shipping address
              }
            });
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then(details => {
                     // transaction id
                     const transactionID = data.orderID;
                     CustomerPaymentHandler(getUserId,chosenServiceId,getSignupInfo.language,transactionID)

              setAlertMessage(paypalPaymentConfirmText)
              setShowAlert(true);
             // alert('Transaction completed by ' + details.payer.name.given_name);
            });
          }}
        />
      </PayPalScriptProvider>
      </div>
    </div>
  );
};


  function PayPalApp() {
  }
  

  


function SignUp(props){

let {pleasefillupFormText,aftersignupemailwillbesent,signUpHeaderText,
  firstNameText,lastNameText,addressText,postalCodeText,cityText,regionText,countryText,
  emailText,phoneText,passwordText,confirmPasswordText,
  selectLanguageText,selectSwedLangText,selectEngLangText,selectJapLangText,
  selectCurrencyText,selectSekCurrencyText,selectUsdCurrencyText,selectJapCurrencyText,
  selectServiceText,selectBasicServiceText,selectTotalServiceText,selectBasicCorporateServiceText,getSignupInfo,updateSignupInfo,
  selectTotalCorporateServiceText,allfieldsMandatoryText,signUpButtonText,
  createAccountRequestHandler,getCountry,setCountry,
  accountsetupin24hrs,mobileIsUsed,customerPrices,xchangeRate,currencySymbol,getVatInfo
} =useContext(MyContext)

//   // const[getCountry,setCountry]=useState('se')
// // prices
// let basicPrice = customerPrices.find(data => data.serviceid === 127).price 
// let totalPrice= customerPrices.find(data => data.serviceid === 128).price
// let corporateBasicPrice = customerPrices.find(data => data.serviceid === 133).price
// let corporateTotalPrice = customerPrices.find(data => data.serviceid === 134).price
// let countryXchange = xchangeRate.find(rate => rate.cc === String(getCountry))?.xchangeRate ?? '';
// let countryCurrencySymbol=currencySymbol.find(symbol=>symbol.currencyKey===String(getCountry))?.currencySymbol ?? '';
// // let vatRate=getVatInfo&&getVatInfo.length>0?getVatInfo.find(vat=>vat.cc==String(getCountry))?.vatRate:''
// // console.log(vatRate)
// let vatRate = Array.isArray(getVatInfo) && getVatInfo.length > 0 
//   ? getVatInfo.find(vat => vat.cc === String(getCountry))?.vatRate ?? '' 
//   : '';

// // xchange rate
// // here lets check the variables customerPrices and xchangeRate
// let basicPriceWithXchange=''
// let totalPriceWithXchange=''
// let corporateBasicPriceWithXchange=''
// let corporateTotalPriceWithXchange=''

// if(customerPrices.length>0 && xchangeRate.length>0){
// basicPriceWithXchange = Math.floor((basicPrice / countryXchange * (1 + (vatRate / 100))));
// totalPriceWithXchange=Math.floor(totalPrice / countryXchange * (1 + (vatRate / 100)))
// corporateBasicPriceWithXchange = Math.floor(corporateBasicPrice / countryXchange * (1 + (vatRate / 100)))
// corporateTotalPriceWithXchange = Math.floor(corporateTotalPrice / countryXchange * (1 + (vatRate / 100)))

// }





  return(

    <div className="infoSignUpWrapper">
        <div className="infoBEnglish" >
        <div className="signUptransparentBox">
      {/* <div className={getCountry!=='ja'?"infoSignUpHeader":"infoSignUpHeaderJAP"}>{signUpHeaderText}</div> */}

      <div className="infoSignUpText">{pleasefillupFormText}</div>
      {/* <div className="infoSignUpText">{aftersignupemailwillbesent}</div> */}
      <div className="infoSignUpText">{accountsetupin24hrs}</div>
      {/* <div className="infoSignUpText">{accountsetupSlow}</div> */}
    
      
      <div className="infoSignUpArea">
        <input type="text" placeholder={firstNameText}  className="signupinput" 
        name="firstName"
        autoComplete='given-name'
        onChange={(e)=>updateSignupInfo(e.target.value,'firstName')}value={getSignupInfo.firstName}
        />
        <input type="text" placeholder={lastNameText} className="signupinput"
        name="lastName"
        autoComplete='family-name'
        onChange={(e)=>updateSignupInfo(e.target.value,'lastName')} value={getSignupInfo.lastName}
        />
        <input type="text" placeholder={addressText} className="signupinput"
        name="address"
        autoComplete='address-line1'
        onChange={(e)=>updateSignupInfo(e.target.value,'address')} value={getSignupInfo.address}
        />
        <input type="text" placeholder={postalCodeText} className="signupinput"
        name="postalCode"
        autoComplete='postal-code'
        onChange={(e)=>updateSignupInfo(e.target.value,'postalCode')} value={getSignupInfo.postalCode}
        />
        <input type="text" placeholder={cityText} className="signupinput"
        name="city"
        autoComplete='address-level2'
        onChange={(e)=>updateSignupInfo(e.target.value,'city')} value={getSignupInfo.city}
        />
        <input type="text" placeholder={regionText} className="signupinput"
        name="region"
        autoComplete='address-level1'
        onChange={(e)=>updateSignupInfo(e.target.value,'region')} value={getSignupInfo.region}
        />
        <input type="text" placeholder={countryText} className="signupinput"
        name="country"
        autoComplete='country-name'
        onChange={(e)=>updateSignupInfo(e.target.value,'localCountry')} value={getSignupInfo.country}
        />
        <input type="email" placeholder={emailText} className="signupinput"
        name="email"
        autoComplete='email'
        onChange={(e)=>updateSignupInfo(e.target.value,'email')} value={getSignupInfo.email}
        />
        <input type="tel" placeholder={phoneText} className="signupinput"
        name="phone"
        autoComplete='tel'
        onChange={(e)=>updateSignupInfo(e.target.value,'phone')} value={getSignupInfo.phone}
        />  
        <input type="password" placeholder={passwordText} className="signupinput"
        onChange={(e)=>updateSignupInfo(e.target.value,'password')} value={getSignupInfo.password}
        />
        <input type="password" placeholder={confirmPasswordText} className="signupinput"
        onChange={(e)=>updateSignupInfo(e.target.value,'confirmPassword')} value={getSignupInfo.confirmPassword}
        />
        <div className="infoservicesArea">
          <select className="infoselect" onChange={(e)=>updateSignupInfo(e.target.value,'language')}>
            <option value="0">{selectLanguageText}</option>
            <option value="se">{selectSwedLangText}</option>
            <option value="en">{selectEngLangText}</option>
            <option value="ja">{selectJapLangText}</option>
          </select>

          <select className="infoselect" 
              onChange={(e) => {
                updateSignupInfo(e.target.value, 'currency');
               }}
          >
            <option value="0">{selectCurrencyText}</option>
            <option value="1">{selectSekCurrencyText}</option>
            <option value="2">{selectUsdCurrencyText}</option>
            <option value="3">{selectJapCurrencyText}</option>
          </select>

          <select className="infoselect" 
                 onChange={(e) => {
                  updateSignupInfo(e.target.value, 'service');
                 }}
          >
            <option value="0">{selectServiceText}</option>
            <option value="127">{selectBasicServiceText}</option>
            <option value="128">{selectTotalServiceText}</option>
            <option value="133">{selectBasicCorporateServiceText}</option>
            <option value="134">{selectTotalCorporateServiceText}</option>
          </select>

          <div className="signupmandatoryText">{allfieldsMandatoryText}</div>

          


        </div>
     
        <button className="infosignupButton"
      

        onClick={()=>createAccountRequestHandler()}
        
        >{signUpButtonText}</button>

  


  

 {mobileIsUsed && (
    <div className="infoPaymentWrapper">

              <div className="infoPaymentKlarna">
                  <klarna-placement
                    data-key="top-strip-promotion-auto-size"
                    data-locale="sv-SE"
                  ></klarna-placement>
              </div>  

           <div className="infoPaymentPayPal">
                            <table border="0" cellPadding="10" cellSpacing="0" align="center">
                    <tr>
                      <td align="center"></td>
                    </tr>
                    <tr>
                      <td align="center">
                        <a 
                          href="https://www.paypal.com/webapps/mpp/paypal-popup" 
                          title="How PayPal Works" 
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ display: 'block' }}
                        >
                          <img 
                            src="https://www.paypalobjects.com/webstatic/mktg/logo/bdg_now_accepting_pp_2line_w.png" 
                            border="0" 
                            alt="Now accepting PayPal" 
                          />
                        </a>
                        <div style={{ textAlign: 'center', marginTop: '10px' }}>
                          <a 
                            href="https://www.paypal.com/webapps/mpp/pay-online" 
                            style={{ fontSize: '12px', color: '#0079CD', fontFamily: 'Arial', textDecoration: 'none' }}
                          >
                            <strong>How PayPal Works</strong>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </table>

           </div>
  </div>
      )}

    </div>
    <div  className="servicesArea">
     

    </div>
</div>
</div>
</div>
  )
}

function BsServices(){
let{applicationBaseServicesHeader,applicationBaseVersions,getImageUrl,buttonId,
  applicationCanusePartly,applicationCanBeUsed,getCountry,xchangeRate,customerPrices,currencySymbol,getVatInfo,
}=useContext(MyContext)

let basicPrice = customerPrices.find(data => data.serviceid === 127).price 
let totalPrice= customerPrices.find(data => data.serviceid === 128).price
let corporateBasicPrice = customerPrices.find(data => data.serviceid === 133).price
let corporateTotalPrice = customerPrices.find(data => data.serviceid === 134).price
let countryXchange = xchangeRate.find(rate => rate.cc === String(getCountry))?.xchangeRate ?? '';
let countryCurrencySymbol=currencySymbol.find(symbol=>symbol.currencyKey===String(getCountry))?.currencySymbol ?? '';
// let vatRate=getVatInfo&&getVatInfo.length>0?getVatInfo.find(vat=>vat.cc==String(getCountry))?.vatRate:''
// console.log(vatRate)
let vatRate = Array.isArray(getVatInfo) && getVatInfo.length > 0 
  ? getVatInfo.find(vat => vat.cc === String(getCountry))?.vatRate ?? '' 
  : '';
// xchange rate
// here lets check the variables customerPrices and xchangeRate
let basicPriceWithXchange=''
let totalPriceWithXchange=''
let corporateBasicPriceWithXchange=''
let corporateTotalPriceWithXchange=''
let baseApplicationName=''
let totalApplicationName=''
let corporateBaseApplicationName=''
let corporateTotalApplicationName=''
let countryMonthlyExpression=''


if(customerPrices.length>0 && xchangeRate.length>0){

  basicPriceWithXchange = Math.floor((basicPrice / countryXchange * (1 + (vatRate / 100))));
totalPriceWithXchange=Math.floor(totalPrice / countryXchange * (1 + (vatRate / 100)))
corporateBasicPriceWithXchange = Math.floor(corporateBasicPrice / countryXchange * (1 + (vatRate / 100)))
corporateTotalPriceWithXchange = Math.floor(corporateTotalPrice / countryXchange * (1 + (vatRate / 100)))


baseApplicationName=customerPrices.find(data => data.cc===String(getCountry) && data.serviceid===127).benaemn
totalApplicationName=customerPrices.find(data => data.cc ===String(getCountry) && data.serviceid===128).benaemn
corporateBaseApplicationName=customerPrices.find(data => data.cc === String(getCountry) && data.serviceid===133).benaemn
corporateTotalApplicationName=customerPrices.find(data => data.cc === String(getCountry) && data.serviceid===134).benaemn
countryMonthlyExpression=customerPrices.find(data => data.cc === String(getCountry) && data.serviceid===127).quoteText



}




  return(

    <div className="infoBsServicesWrapper">
        
        <div className="infoB" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
      <div className="transparentBoxBs">
      <p></p>
      <div className="infoBsTopArea">
      <div className={getCountry!=='ja'?"infoBsServicesHeader":"infoBsServicesHeaderJAP"}>{ applicationBaseServicesHeader}</div>
      <hr className="basicServicesHr"></hr>
      {/* <div className="infoBsServicesText">{applicationBaseVersions}<p></p>
      </div> */}
      <div className="infoBsServicesBaseArea">
        <div className={getCountry!=='ja'?"infoBsServicesBaseHeader":"infoBsServicesBaseHeaderJAP"}>{baseApplicationName}&nbsp;&nbsp;<div className={getCountry!=='ja'?"infoBsServicesBaseHeaderPrice":"infoBsServicesBaseHeaderPriceJAP"}>{countryCurrencySymbol}&nbsp;{basicPriceWithXchange}/{countryMonthlyExpression}</div></div>
   
      
        <div className="infoBsServicesBaseText">{applicationCanusePartly}</div>
        <hr className="basicServicesHr"></hr>
        
        <div className={getCountry!=='ja'?"infoBsServicesBaseHeader":"infoBsServicesBaseHeaderJAP"}>{totalApplicationName}&nbsp;&nbsp;<div className={getCountry!=='ja'?"infoBsServicesBaseHeaderPrice":"infoBsServicesBaseHeaderPriceJAP"}>{countryCurrencySymbol}&nbsp;{totalPriceWithXchange}/{countryMonthlyExpression}</div></div>
        
        <div className="infoBsServicesBaseText">{applicationCanBeUsed}</div>
        <hr className="basicServicesHr"></hr>
        <div className={getCountry!=='ja'?"infoBsServicesBaseHeader":"infoBsServicesBaseHeaderJAP"}>{corporateBaseApplicationName}&nbsp;&nbsp;<div className={getCountry!=='ja'?"infoBsServicesBaseHeaderPrice":"infoBsServicesBaseHeaderPriceJAP"}>{countryCurrencySymbol}&nbsp;{corporateBasicPriceWithXchange}/{countryMonthlyExpression}</div></div>
       
        <div className="infoBsServicesBaseText">{applicationCanusePartly}</div>
        <hr className="basicServicesHr"></hr>

        <div className={getCountry!=='ja'?"infoBsServicesBaseHeader":"infoBsServicesBaseHeaderJAP"}>{corporateTotalApplicationName}&nbsp;&nbsp;<div className={getCountry!=='ja'?"infoBsServicesBaseHeaderPrice":"infoBsServicesBaseHeaderPriceJAP"}>{countryCurrencySymbol}&nbsp;{corporateTotalPriceWithXchange}/{countryMonthlyExpression}</div></div>

        <div className="infoBsServicesBaseText">{applicationCanBeUsed}</div>
 

       </div>
      </div>
      </div>
      
</div>
</div>

  )
}

function AIServicesSwedish(){
  let {getImageUrl, buttonId} = useContext(MyContext)
 

  return (
    <div className="basicServicesWrapper">
      <div className="infoBEnglish" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
  <div className="transparentBoxAIServices">
  <div className="plusServicesHeader">Vi har utvecklat och använder följande AI baserade tjänster:</div>

        <div className="basicServicesListText">
        <ol className="basicServicesListMid">
         <IoShieldCheckmarkOutline className="aiServicesCheckIcon" />Skapa recept <br></br>
            <IoShieldCheckmarkOutline className="aiServicesCheckIcon" />Skapa recept idéer<br></br>
            <IoShieldCheckmarkOutline className="aiServicesCheckIcon" />Skapa bilder med AI<br></br>
            <IoShieldCheckmarkOutline className="aiServicesCheckIcon" />Sök ingrediens information<br></br>
            <IoShieldCheckmarkOutline className="aiServicesCheckIcon" />Ingrediens konvertering<br></br>
            </ol>
        </div>
            
        

</div>
</div>
</div>

    )


}

function AIServicesEnglish(){
  const { getImageUrl, buttonId } = useContext(MyContext);

  return (
    <div className="basicServicesWrapper">
      <div className="infoBEnglish" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
        <div className="transparentBox">
          <div className="basicServicesHeader">
            We have developed and use the following<br></br> AI-based services:
          </div>
          <div className="basicServicesListText">
            <ol className="basicServicesListMid">
              <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Create recipes<br></br>
              <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Generate recipe ideas<br></br>
              <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Create images with AI<br></br>
              <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Search ingredient information<br></br>
              <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> Ingredient conversion<br></br>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}
function AIServicesJapanese(){
  let {getImageUrl, buttonId} = useContext(MyContext)

  return (
    <div className="basicServicesWrapper">
      <div className="infoBEnglish" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
        <div className="transparentBox">
          <div className="basicServicesHeader">
            私たちは次のAIベースのサービスを開発し、<br></br>使用しています:
          </div>
          <div className="basicServicesListText">
            <ol className="basicServicesListMid">
              <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> レシピを作成<br></br>
              <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> レシピのアイデアを作成<br></br>
              <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> AIで画像を作成<br></br>
              <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> 材料情報を検索<br></br>
              <IoShieldCheckmarkOutline className="basicServicesCheckIcon" /> 材料の変換<br></br>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}



// swedish
function AboutUsSwedish(props){
  let {getImageUrl, buttonId} = useContext(MyContext)


  return (
    <div className="basicServicesWrapper">
      <div className="infoBEnglish" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
  <div className="aboutUsTransparentBox">
<div className="basicServicesHeader"></div>
<div className="aboutUsServicesCommentText">
<b>www.bakesoft.se</b> har långsamt sett dagens ljus igenom att vi arbetat fram olika prototyper av programvara
som vi själva vill arbeta med i bageriet.Vårt företag driver idag ett mindre bageri i västra Sverige som heter Överlida Bageri.
Detta är inte det första bageriet vi har drivit, vi startade redan på 90-talet med att driva bageri i Göteborg.<br></br>
<p></p>
Anders Hellström som är grundare av företaget har en bakgrund som programmerare och projekteledare på bla Telia
och Stroede IT Development i större projekt på bla SJ och SAS och Norska Aftonposten.<br></br><p></p>
I arbetet med Överlida Bageri skapade Anders en första prototyp av www.bakesoft.se för företaget med
tanke på att i allt större grad kunna kontrollera marginaler, inköp, recept och produkter på ett helt
annat sätt än vad som kan göras i enklare bokföringsprogram.<br></br><p></p>
Allting ligger i marginalerna, vilka produkter är lönsamma?, vilka produkter går bäst? hur kan vi maximera vår
vinst så att vi kan säkra upp våran verksamhet så att vi överlever både kortsiktigt och långsiktigt. Detta
är en nyckelfaktor för alla företag. Den första prototypen av www.bakesoft.se hanterade ingredienser
samt räknade ut kostnaden för ett recept. 
<br></br><p></p>
Detta gav oss möjligheten att mycket enklare kunna jämföra kostnaden för olika recept beroende på vilka 
ingredienser vi valde att arbeta med samt hur mycket av olika ingredienser som fick ingå i olika recept.
Detta började skapa en bas för att kunna få en första översikt av vad som rörde sig i de olika produkter
som företaget arbetade med. <br></br><p></p>
Under en period på ca 3 år har vi tagit applikationen långsamt framåt och speciellt de senaste 12 månaderna
så har applikation växt till sig ordentligt. Applikation fick i december 2023 ett webgränssnit emot mobiltelefoner,
tidigare hade vi endast använt applikationen på en Ipad.<br></br><p></p>
Idag har vi ett webgränsnitt som täcker de flesta hårdvaror, ifrån mobiltelefoner, läsplattor, laptops, desktops
osv. <br></br><p></p>
Applikationen har under 2023 och 2024 fått flera integrationer med AI samt ytterligare funktionalitet som gör
det enklare att utföra sökning efter nya recept, nya recept ideer, uppladdning av bilder och skapande av bilder
med AI. <br></br><p></p>
Vi som driver www.bakesoft.se är ett litet företag och idag är vi 3 personer. Vi har ett stort intresse av att
utveckla www.bakesoft.se vidare och vi ser med glädje på hur applikationen utvecklats tills nu.<br></br><p></p>
Det var först hösten 2023 som vi tog beslutet av försöka skapa möjlighet i applikationen att få in fler användare
än oss själva, tidigare hade vi ingen tanke på att få in fler företag eller privatpersoner i applikationen, då vi skapat den
som ett verktyg för oss själva.Detta krävde en hel del ombyggnad i applikationen men detta är nu klart.<br></br><p></p>
Varför lägger vi ner så mycket arbete på att skapa en applikation för ingrediens, recept och produkthantering?
Svaret på detta är att vi tycker det är roligare att baka och att ha full kontroll på våra produkter än att sitta med
manuella uträkningar på papper eller i ett dokument på en dator och ändå inte kunna ha full kontroll på vad det är 
vi gör. Vi spar helt enkelt mycket tid och har kontroll på vad vi gör.
<br></br><p></p>
Vi har lång erfarenhet ifrån miljöer med utveckling av programvaror samt projektledning och vi har djup respekt
för hur svårt det är att utveckla nya verktyg som kan skapa nya möjligheter för sina användare.
Svaret på frågan om vilka vi är, är att vi är ett litet företag  i Sverige som har erfarenhet inom programvarutveckling
och även har erfarenhet av att driva bagerier, vi för detta tillsamans och vi tycker att det går bra.<br></br><p></p>
Välkommen till oss på www.bakesoft.se, vi hoppas vi kan hjälpa dig att få ut lika mycket av www.bakesoft.se som
vi får ut. Vi har i dags dato mer än 400 utförda produktioner i systemet, vi har ca 300 produkter, runt 500 recept, mängder
av bilder och allt annat som applikationen bjuder på.<br></br>Tack för att du tagit dig tid att läsa om oss
och vårt företag.

</div>
</div>
</div>
</div>


    )


}
function AboutUsEnglish(props){
  let {getImageUrl, buttonId} = useContext(MyContext)

  return (
    <div className="basicServicesWrapper">
      <div className="infoBEnglish" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
        <div className="aboutUsTransparentBox">
          <div className="basicServicesHeader"></div>
          <div className="basicServicesCommentText">
            <b>www.bakesoft.se</b> has slowly seen the light of day through our development of various software prototypes that we want to use in our own bakery.<br></br>
            <p></p> 
            Our company currently operates a small bakery in western Sweden called Överlida Bageri. This is not the first bakery we have run; we started operating a bakery in Gothenburg back in the 90s.<br></br>
            <p></p>
            Anders Hellström, the founder of the company, has a background as a programmer and project manager at companies like Telia and Stroede IT Development, working on major projects for SJ, SAS, and the Norwegian newspaper Aftonposten.<br></br>
            <p></p>
            While working with Överlida Bageri, Anders created the first prototype of www.bakesoft.se for the company, with the aim of better controlling margins, purchases, recipes, and products compared to simpler accounting software.<br></br>
            <p></p>
            Everything lies in the margins: which products are profitable? Which products sell best? How can we maximize our profit to secure our business for both the short and long term? This is a key factor for all businesses. The first prototype of www.bakesoft.se handled ingredients and calculated recipe costs.<br></br>
            <p></p>
            This allowed us to much more easily compare the costs of different recipes based on the ingredients we chose to work with and the quantities used. This began to create a foundation for getting an initial overview of the various products the company worked with.<br></br>
            <p></p>
            Over a period of about 3 years, we have gradually advanced the application, especially in the last 12 months, during which the application has grown significantly. In December 2023, the application received a web interface for mobile phones; previously, we had only used the application on an iPad.<br></br>
            <p></p>
            Today, we have a web interface that covers most hardware, from mobile phones, tablets, laptops, desktops, and so on.<br></br>
            <p></p>
            In 2023 and 2024, the application received several integrations with AI and additional functionality that makes it easier to search for new recipes, new recipe ideas, upload images, and create images with AI.<br></br>
            <p></p>
            We, who run www.bakesoft.se, are a small company, and today we are 3 people. We have a great interest in further developing www.bakesoft.se and are pleased with how the application has developed so far.<br></br>
            <p></p>
            It was not until the autumn of 2023 that we decided to create the possibility for more users to join the application besides ourselves. Previously, we had no intention of allowing other companies or individuals to use the application, as we had created it as a tool for ourselves. This required significant reconstruction of the application, but this is now complete.<br></br>
            <p></p>
            Why do we put so much effort into creating an application for ingredient, recipe, and product management? The answer is that we find it more enjoyable to bake and have full control over our products than to sit with manual calculations on paper or in a document on a computer and still not have full control over what we are doing. We simply save a lot of time and have control over what we do.<br></br>
            <p></p>
            We have extensive experience in software development environments and project management, and we have deep respect for how difficult it is to develop new tools that can create new opportunities for their users. The answer to the question of who we are is that we are a small company in Sweden with experience in software development and running bakeries. We combine these, and we think it is going well.<br></br>
            <p></p>
            Welcome to us at www.bakesoft.se, and we hope we can help you get as much out of www.bakesoft.se as we do. As of today, we have more than 400 completed productions in the system, around 300 products, about 500 recipes, plenty of images, and everything else the application offers.<br></br>
            Thank you for taking the time to read about us and our company.
          </div>
        </div>
      </div>
    </div>
  );

}
function AboutUsJapanese(props){
  let {getImageUrl, buttonId} = useContext(MyContext)

  return (
    <div className="basicServicesWrapper">
      <div className="infoBEnglish" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>

        <div className="aboutUsTransparentBox">
          <div className="basicServicesHeader"></div>
          <div className="aboutUsCommentText">
            <b>www.bakesoft.se</b> は、私たちが自分たちのベーカリーで使用したい様々なソフトウェアのプロトタイプを開発することで、ゆっくりと日の目を見てきました。<br></br>
            <p></p> 
            私たちの会社は現在、スウェーデン西部にある小さなベーカリー「Överlida Bageri」を運営しています。これが最初のベーカリーではなく、1990年代にヨーテボリでベーカリーを運営し始めました。<br></br>
            <p></p>
            会社の創設者であるアンダース・ヘルストロムは、TeliaやStroede IT Developmentなどの企業でプログラマーやプロジェクトマネージャーとして、SJ、SAS、ノルウェーの新聞Aftonpostenの大規模プロジェクトに取り組んだ経験があります。<br></br>
            <p></p>
            Överlida Bageriでの作業中に、アンダースは最初のプロトタイプwww.bakesoft.seを会社のために作成し、より簡単な会計ソフトウェアと比べて、マージン、購入、レシピ、製品をより良く管理できるようにしました。<br></br>
            <p></p>
            すべてはマージンにあります。どの商品が利益をもたらすのか？どの商品が一番売れるのか？短期的および長期的に私たちのビジネスを確保するために利益を最大化するにはどうすればよいのか？これはすべての企業にとって重要な要素です。www.bakesoft.seの最初のプロトタイプは、材料を処理し、レシピのコストを計算しました。<br></br>
            <p></p>
            これにより、選択した材料や使用する量に基づいて、さまざまなレシピのコストをはるかに簡単に比較できるようになりました。これが、会社が取り組んでいるさまざまな製品に関する最初の概要を得るための基盤を作り始めました。<br></br>
            <p></p>
            約3年の期間にわたり、特に過去12か月間にアプリケーションをゆっくりと前進させてきました。この期間にアプリケーションは大幅に成長しました。2023年12月には、アプリケーションにモバイルフォン向けのウェブインターフェースが導入されました。それ以前は、iPadでのみアプリケーションを使用していました。<br></br>
            <p></p>
            今日では、モバイルフォン、タブレット、ラップトップ、デスクトップなど、ほとんどのハードウェアをカバーするウェブインターフェースがあります。<br></br>
            <p></p>
            2023年と2024年には、アプリケーションにAIとの複数の統合や、新しいレシピの検索、新しいレシピのアイデア、画像のアップロード、AIを使った画像作成を簡単にするための追加機能が導入されました。<br></br>
            <p></p>
            私たちwww.bakesoft.seを運営するのは小さな会社で、現在3人です。www.bakesoft.seのさらなる開発に大きな関心を持ち、これまでのアプリケーションの発展に満足しています。<br></br>
            <p></p>
            2023年の秋まで、私たち自身以外のユーザーがアプリケーションに参加できるようにすることを決定するまで、他の企業や個人がアプリケーションを使用することを考えていませんでした。これにはアプリケーションの大幅な改修が必要でしたが、今では完了しています。<br></br>
            <p></p>
            なぜ私たちは、材料、レシピ、製品管理のアプリケーションを作成することにこれほどまでに努力を注いでいるのでしょうか？その答えは、手作業での計算を紙やコンピュータのドキュメントで行うよりも、製品を完全に管理することが楽しいからです。私たちは単に多くの時間を節約し、行っていることを管理しています。<br></br>
            <p></p>
            ソフトウェア開発環境やプロジェクト管理の経験が豊富であり、新しいユーザーに新しい機会を提供できる新しいツールを開発することがいかに難しいかを深く理解しています。私たちが誰なのかという質問に対する答えは、ソフトウェア開発の経験があり、ベーカリーの運営経験もあるスウェーデンの小さな会社です。これらを組み合わせて、うまくいっていると思います。<br></br>
            <p></p>
            www.bakesoft.seへようこそ。私たちがwww.bakesoft.seから得ているのと同じように、あなたもこのアプリケーションを最大限に活用できることを願っています。現在、システム内で400以上の生産を行い、約300の製品、約500のレシピ、数多くの画像など、アプリケーションが提供するすべてのものがあります。<br></br>
            私たちと私たちの会社について読んでいただき、ありがとうございました。
          </div>
        </div>
      </div>
    </div>
  );

}


function PlusServices(){

  let {getImageUrl, buttonId,getServicesInfo,getCountry,xchangeRate,currencySymbol,plusServicePrice,plusServiceBenaemning,
  plusServicesHeaderText,plusServicesHeaderText1,pricesGivenPerMonthText,getServicesMainInfo,klickOnTextMoreInfo
} = useContext(MyContext)

const [expandedRow, setExpandedRow] = useState();

const handleClick = (index) => {
  setExpandedRow(expandedRow === index ? null : index);
};
  

  // here we have to filter the getServices info
  let getServicesInfoFiltered = getServicesInfo && getServicesInfo.length>0?getServicesInfo.filter(data => data.cc === getCountry):''
  let localxchangeRate = xchangeRate && xchangeRate.length>0?xchangeRate.find(rate => rate.cc === getCountry)?.xchangeRate ?? '' : ''
  let localcurrencySymbol =   currencySymbol && currencySymbol.length>0?currencySymbol.find(symbol => symbol.currencyKey === getCountry)?.currencySymbol ?? '':''


  return (
    <div className="basicServicesWrapper">
      <div className="infoBEnglish" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
  <div className="transparentBoxPlus">
  <div className={getCountry!=='ja'?"basicServicesPlusHeader":"basicServicesPlusHeaderJAP"}>{plusServicesHeaderText}&nbsp;{plusServicesHeaderText1}<p></p></div>
  <div className="basicServicesPlusHeader">{pricesGivenPerMonthText}.<p></p></div>
  <div className="basicServicesPlusHeaderMoreInfo">{klickOnTextMoreInfo}<p></p></div>
  
  <div className="basicServicesListWrapper">
  <div className="basicServicesDataList">
  {getServicesInfoFiltered.map((data, index) => (
    <React.Fragment key={index}>
      {/* {index === 0 && (
        <div className="basicDataListHeader">
          <div className="basicDataHeaderServiceId">&nbsp;{'Id'}</div>
          <div className="basicDataHeaderBenaemn">{plusServiceBenaemning}</div>
          <div className="basicDataHeaderPrice">{plusServicePrice}</div>
        </div>
      )} */}
      <div className="basicServicesData">

        <div className="basicServicesListId">{data.serviceid}</div>
        <div className="basicServicesListBenaemn"
        onClick={()=>handleClick(index)}
        >{data.benaemn}</div>
    
        <div className="basicServicesListPrice">{localcurrencySymbol}&nbsp;{Math.floor(data.price/localxchangeRate)}</div>
        
      </div>
      {expandedRow === index && (<div className="tjansterInfoAnimated">
          <div className="basicServicesBaseInfo">
            {getServicesMainInfo.filter(info=>info.cc===getCountry).map((info, index) => {
              return (
                <div key={index}>
                  {info.serviceid === data.serviceid && info.benaemn}
                </div>
              );
            })}
          </div>
        </div>)}
    </React.Fragment>
    
  ))}

</div>
</div>
</div>

</div>
</div>


    )

}
function Faq(){
  let{faqData,getCountry,faqHeaderText,getImageUrl,buttonId}=useContext(MyContext)
  const[expandedRow,setExpandedRow]=useState()
    return(
  
      <div className="infoFaqWrapper">
          <div className="faqBackgroundImage" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
            <div className="faqtransparentBox">
        <div className={getCountry!=='ja'?"infoFaqHeader":"infoFaqHeaderJAP"}>
          {faqHeaderText}
        </div>
        <div className="infofaqDataList">
          {faqData.filter(data=>data.cc===getCountry).map((data,index)=>(
            <>
            <div className="infofaqData" key={index}>
                  <div className="infofaqId">{data.faqid}&nbsp;&nbsp;</div>
                  <div className="infofaqQuestion" onClick={() => setExpandedRow(expandedRow === index ? null : index)}>{data.question}</div>
            </div>
            <div className="infofaqAnswer">
              {expandedRow===index && <div className="infofaqAnimated">{data.answer}</div>}
              
              </div></>
          ))}
    

      </div>
      </div>
      </div>
      </div>
  
    )
  
}

const SlidingMenu = (props) => {
  let { isOpen,toggleMenu,
  seHandler,jpHandler,gbHandler,isPartiallyOpen,
   }=props
   let ctx=useContext(MyContext)
   
  return (
    <div className={`sliding-menu ${isOpen ? 'open' : ''} ${isPartiallyOpen ? 'partially-open' : ''}`}>
      <button className="close-button" onClick={toggleMenu}>X</button>
      <div className="slidingMenuWrapper">
      <button className="slidingMenuButton" onClick={() => ctx.activeButtonHandler(1)}> <FaHome size={20}/>&nbsp;{ctx.startButtonText}</button>
      <button className="slidingMenuButton" onClick={() => ctx.activeButtonHandler(2)}> <FaRegNewspaper size={20}/>&nbsp;{ctx.signupButtonText}</button>
  
  
     <button className="slidingMenuButton" onClick={() => ctx.activeButtonHandler(3)}> <FaConciergeBell size={20} />&nbsp;{ctx.applicationButtonText}</button>
      <button className="slidingMenuButton" onClick={() => ctx.activeButtonHandler(4)}> <FaRobot size={20}/>&nbsp;{ctx.aiServicesButtonText}</button>
      <button className="slidingMenuButton" onClick={() => ctx.activeButtonHandler(7)}><FaPlus size={20} />&nbsp;Plus</button>
      <button className="slidingMenuButton" onClick={() => ctx.activeButtonHandler(12)}><FaFileContract size={20} />&nbsp;{ctx.buyingTermsText}</button>
      <button className="slidingMenuButton" onClick={() => ctx.activeButtonHandler(5)}> <FaInfoCircle size={20}/>&nbsp;{ctx.aboutUsButtonText}</button>
      <button className="slidingMenuButton" onClick={() => ctx.activeButtonHandler(6)}> <FaQuestionCircle size={20} />&nbsp;{ctx.faqButtonText}</button>
      <button className="slidingMenuButton" onClick={() => ctx.activeButtonHandler(11)}> <FaAddressBook size={20} />&nbsp;{ctx.contactInfoText}</button>
      <button className="slidingMenuButton" onClick={() => ctx.activeButtonHandler(10)}> <FaShieldAlt size={20} />&nbsp;{ctx.privacyPolicyText}</button>
  
      <button className="slidingMenuButton" onClick={() => ctx.activeButtonHandler(8)}> <FaSignInAlt size={20} />&nbsp;Login</button>
    </div>
    <div className="menuCountryFlagWrapper">
      <SE title="Swedish" className="xzloginflagse" onClick={seHandler} />
      <JP title="Japanese" className="xzloginflagJapan" onClick={jpHandler} />
      <GB title="English" className="xzloginflaggb" onClick={gbHandler} />




      </div>
    </div>
  );
};


function IntegrityPolicy(){
  let {getImageUrl, buttonId,integrityPolicyText} = useContext(MyContext)
  return(
    <div className="integrityPolicyTop">
   

          <div className="integrityPolicyBackgroundImage" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
            <div className="faqtransparentBox">


    <div className="integrityPolicyHeaderText">{integrityPolicyText}</div>
    <div className="integrityPolicyWrapper">
                 <div className="integrityPolicyDoc">
                    <a href={`${process.env.REACT_APP_BACKEND_URL}/userdatapolicy/se`} 
                    style={{ textDecoration: 'none', color:'black' }}
                    >Integritets policy - Swedish</a>
                </div>
                      <div className="integrityPolicyDoc">
                          <a href={`${process.env.REACT_APP_BACKEND_URL}/userdatapolicy/ja`}
                          style={{ textDecoration: 'none',color:'black' }}
                          >プライバシーポリシー - 日本語</a>
                    </div>
                        <div className="integrityPolicyDoc">
                              <a href={`${process.env.REACT_APP_BACKEND_URL}/userdatapolicy/en`}
                              style={{ textDecoration: 'none',color:'black' }}
                              >Privacy Policy -  English</a>
                        </div>
                    
                </div>
                
                </div>
                </div>
                </div>
            

      

  

        )
}


function ContactInfo(){
  let{getImageUrl,buttonId,contactInformationText
  }=useContext(MyContext)

  return(
    <div className="infoContactInfoTop">
            <div className="integrityPolicyBackgroundImage" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
            <div className="faqtransparentBox">

       <div className="infoContactInfoHeader">{contactInformationText}</div>
             <div className="infoContactInfoWrapper">
               
                         <div className="infoContactInfoText">Hellström Trading i Göteborg AB</div>
                          <div className="infoContactInfoText">Skenevägen 4</div>
                          <div className="infoContactInfoText">512 60 Överlida</div>
                          <div className="infoContactInfoText">Sweden</div>
                          <div className="infoContactInfoText">Org.nr:556572-0413</div>
                          <div className="infoContactInfoText">
                              <a href="mailto:support@bakesoft.se">support@bakesoft.se</a>


                        </div>
            
                  </div>
                  </div>
             </div>
    </div>

  )
}

function BuyingTerms(){
let ctx = useContext(MyContext)
return(

  <div className="integrityPolicyBackgroundImage" style={{ backgroundImage: `url(${ctx.getImageUrl(ctx.buttonId)})` }}>
    <div className="buyingTermstransparentBox">
      <div className="infoBuyingTermsHeader">Köpvillkor</div>
      <div className="infoBuyingTermsWrapper">
        <div className="infoBuyingTermsText">
        
<div className="allmant-section">
  <h2 className="allmant-heading">1. Allmänt</h2>
  <p className="allmant-paragraph">
    Dessa köpvillkor gäller för alla köp som görs genom <strong>Hellström Trading i Göteborg AB</strong> 
    (Org nr: 556572-0413) via webbplatsen <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a>.
    Genom att lägga en beställning på vår webbplats accepterar du att följa dessa villkor.
  </p>
  <p className="allmant-paragraph">
    <strong>Hellström Trading i Göteborg AB</strong> är baserat i Göteborg, Sverige, och alla transaktioner 
    och avtal mellan kunden och företaget regleras av svensk lag. Särskilt gäller <strong>E-handelslagen</strong>, 
    som reglerar rättigheter och skyldigheter för företag och konsumenter vid handel på internet. 
    För mer information om denna lag, besök den officiella webbplatsen för Konsumentverket: 
    <a href="https://www.konsumentverket.se/for-foretag/olika-saljkanaler/regler-nar-du-saljer-pa-internet/e-handelslagen/" 
    target="_blank" rel="noopener noreferrer">E-handelslagen</a>.
  </p>
  <p className="allmant-paragraph">
    Genom att använda <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a> 
    &nbsp;bekräftar du att du är minst 18 år gammal eller har förälders samtycke, och att du förstår och accepterar dessa köpvillkor.
  </p>
</div>
<div className="bestallning-section">
  <h2 className="bestallning-heading">2. Beställning</h2>
  <p className="bestallning-paragraph">
    För att göra din första beställning på <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a> 
    &nbsp;måste du registrera ett konto. Vid registreringen kan du välja mellan fyra grundläggande tjänstepaket:
  </p>
  <ul className="bestallning-list">
    <li>Bas</li>
    <li>Total</li>
    <li>Bas Företag</li>
    <li>Total Företag</li>
  </ul>
  <p className="bestallning-paragraph">
    Du måste ha ett av dessa grundpaket för att kunna hantera ett konto på plattformen. Varje paket ger tillgång till nödvändiga funktioner och verktyg för att hantera dina aktiviteter på webbplatsen.
  </p>
  <p className="bestallning-paragraph">
    Utöver de grundläggande tjänsterna erbjuder <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a> cirka 25 tilläggstjänster som du kan välja att lägga till för att förbättra din upplevelse och hjälpa dig att växa inom applikationen. Varje tilläggstjänst debiteras individuellt och du kan välja de tjänster som bäst passar dina behov.
  </p>
  <h3 className="bestallning-subheading">Betalningsvillkor:</h3>
  <p className="bestallning-paragraph">
    Vid beställning av någon tjänst eller tilläggsfunktion krävs betalning i enlighet med de betalningsvillkor som anges i avsnitt 4 av dessa villkor. Alla tjänster och produkter faktureras individuellt och debiteras enligt den prisinformation som ges vid utcheckningen.
  </p>
</div>

<div className="priser-section">
  <h2 className="priser-heading">3. Priser</h2>
  <p className="priser-paragraph">
    Alla priser på <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a> inkluderar mervärdesskatt (moms) som styrs av ditt val av land under kontoinställningen. Momssatserna är som följer:
  </p>
  <ul className="priser-list">
    <li>USD/UK: 20% moms</li>
    <li>SEK: 25% moms</li>
    <li>YEN: 10% moms</li>
  </ul>
  <p className="priser-paragraph">
    Företag utanför EU kan ange sitt skattenummer under "Mitt konto", och momsen kommer då att dras från deras faktura.
  </p>
  <p className="priser-paragraph">
    Inga ytterligare avgifter tillkommer. Alla våra paket och tjänster debiteras automatiskt månadsvis. För att undvika att få en ny faktura måste tjänster sägas upp senast den 30:e varje månad (28:e i februari). Uppsägning kan ske när som helst under månaden via ditt konto.
  </p>
</div>

<div className="betalning-section">
  <h2 className="betalning-heading">4. Betalning</h2>
  <p className="betalning-paragraph">
    Vi erbjuder flera betalningsmetoder beroende på ditt land och dina preferenser:
  </p>
  <ul className="betalning-list">
    <li>PayPal: Tillgängligt för alla länder och alla kunder.</li>
    <li>Klarna: Tillgängligt för kunder i Sverige.</li>
    <li>Swish: Tillgängligt för kunder i Sverige.</li>
  </ul>
  <p className="betalning-paragraph">
    När betalningen har bearbetats av våra betalpartners, kommer vårt system att utföra den tjänst som kunden efterfrågar, vilket innebär att avtalet mellan kunden och <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a> är giltigt och bindande.
  </p>
  <p className="betalning-paragraph">
    Våra betalningspartners använder den senaste tekniken för att säkerställa säkerheten vid betalning. <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">Bakesoft.se</a> lagrar inga betalningsuppgifter såsom kortnummer eller annan känslig information. 
  </p>
</div>

<div className="leverans-section">
  <h2 className="leverans-heading">5. Leverans</h2>
  <p className="leverans-paragraph">
    När du betalar för en ny tjänst eller ett nytt konto i applikationen, sker uppsättningen av kontot vanligtvis inom cirka 5 minuter. Eftersom vår verksamhet är helt online har vi inga geografiska restriktioner, och våra tjänster är tillgängliga för kunder över hela världen.
  </p>
  <p className="leverans-paragraph">
    Eventuella förseningar i leveransen skulle bero på att applikationen är otillgänglig, vilket skulle innebära att användarna inte kan komma åt sina konton eller tjänster. I sådana fall kommer vi att meddela användarna om det uppstår problem, antingen på vår sida eller från våra leverantörers sida.
  </p>
</div>

<div className="angerratt-section">
  <h2 className="angerratt-heading">6. Ångerrätt</h2>
  <p className="angerratt-paragraph">
    Eftersom vi endast säljer digitala tjänster, gäller ingen ångerrätt enligt gällande lagstiftning. När en tjänst har betalats och aktiverats anses avtalet vara giltigt och bindande.
  </p>
  <p className="angerratt-paragraph">
    Kunder har rätt att säga upp sina prenumerationer när som helst via sitt konto, men vi erbjuder ingen återbetalning för en redan aktiv och giltig prenumeration.
  </p>
</div>

<div className="reklamation-section">
  <h2 className="reklamation-heading">7. Reklamation och Garanti</h2>
  <p className="reklamation-paragraph">
    Inuti applikationen finns en supportpanel där användare kan göra förfrågningar, lämna klagomål eller ställa frågor om applikationen. Vi strävar efter att besvara alla förfrågningar inom 24 timmar.
  </p>
  <p className="reklamation-paragraph">
    Om det uppstår tekniska problem som orsakas av oss eller våra leverantörer, kommer vi att meddela användarna så snart som möjligt. Vi tar fullt ansvar för att lösa eventuella problem som påverkar applikationens tillgänglighet.
  </p>
  <p className="reklamation-paragraph">
    Som kompensation för förlorad tid i applikationen, på grund av tekniska problem, kommer vi att förlänga användarens tillgång med samma tid som användaren inte kunde använda applikationen.
  </p>
</div>

<div className="force-majeure-section">
  <h2 className="force-majeure-heading">8. Force Majeure</h2>
  <p className="force-majeure-paragraph">
    Vi ansvarar inte för förseningar eller brister i leverans av tjänster om dessa beror på händelser utanför vår kontroll. Detta inkluderar men är inte begränsat till naturkatastrofer, krig, strejker, myndighetsbeslut, pandemier eller större tekniska fel som påverkar vår infrastruktur eller våra leverantörers infrastruktur.
  </p>
  <p className="force-majeure-paragraph">
    I händelse av en force majeure-situation kommer vi att meddela alla användare så snart som möjligt. Under sådana omständigheter kan våra tjänster vara otillgängliga till dess att problemet har lösts. Vi kommer att återuppta tjänsterna så snart situationen tillåter det.
  </p>
  <p className="force-majeure-paragraph">
    Force majeure fritar oss från ansvar för försenad eller utebliven leverans av tjänster under den tid som händelsen varar, men vi kommer att göra vårt bästa för att minimera påverkan på våra kunder.
  </p>
</div>

<div className="tvist-section">
  <h2 className="tvist-heading">9. Tvist</h2>
  <p className="tvist-paragraph">
    Vid en tvist mellan kunden och <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a> ska tvisten i första hand lösas genom förhandling mellan parterna. Vi strävar alltid efter att hitta en fredlig lösning som gynnar båda parter.
  </p>
  <p className="tvist-paragraph">
    Om en tvist inte kan lösas genom förhandling, och om rättsliga åtgärder blir nödvändiga, ska alla rättsliga förfaranden ske i domstol i Göteborg, där vårt företag är baserat. Tvisten ska inte behandlas i någon annan jurisdiktion eller ort.
  </p>
</div>

<div className="personuppgifter-section">
  <h2 className="personuppgifter-heading">10. Personuppgifter</h2>
  <p className="personuppgifter-paragraph">
    Vi följer dataskyddsförordningen (GDPR) och skyddar alla våra kunders personuppgifter. De uppgifter vi samlar in sker enbart i samband med kontoregistrering och används för att fakturera kunden samt för att kunna hantera kundens användning av vår applikation.
  </p>
  <p className="personuppgifter-paragraph">
    Kundernas personuppgifter lagras säkert på våra servrar hos Amazon Web Services (AWS), som erbjuder en säker och pålitlig infrastruktur. Vi ser till att dina data skyddas enligt de senaste säkerhetsstandarderna.
  </p>
  <p className="personuppgifter-paragraph">
    All data som lagras på våra servrar tillhör <strong>Hellström Trading i Göteborg AB</strong> och är vårt exklusiva ägande, även om du som användare har fullständig rätt att använda datan i enlighet med våra villkor. Detta klargör att ingen äganderätt överförs till användaren, för att undvika eventuella anspråk på ägande av data.
  </p>
  <p className="personuppgifter-paragraph">
    Vi säljer eller överför aldrig kunduppgifter till tredje part på något sätt, varken inom eller utanför EU. Ditt data används endast för att leverera de tjänster du har beställt från oss.
  </p>
</div>
&nbsp;
&nbsp;


        </div>
      </div>
    </div>
  </div>
)
}


function BuyingTermsENG(){
  let ctx = useContext(MyContext)
  return(
<div className="integrityPolicyBackgroundImage" style={{ backgroundImage: `url(${ctx.getImageUrl(ctx.buttonId)})` }}>
  <div className="buyingTermstransparentBox">
    <div className="infoBuyingTermsHeader">Terms of Purchase</div>
    <div className="infoBuyingTermsWrapper">
      <div className="infoBuyingTermsText">
        
        <div className="allmant-section">
          <h2 className="allmant-heading">1. General</h2>
          <p className="allmant-paragraph">
            These terms of purchase apply to all purchases made through <strong>Hellström Trading i Göteborg AB</strong>
            (Org No: 556572-0413) via the website <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a>.
            By placing an order on our website, you agree to follow these terms.
          </p>
          <p className="allmant-paragraph">
            <strong>Hellström Trading i Göteborg AB</strong> is based in Gothenburg, Sweden, and all transactions
            and agreements between the customer and the company are regulated by Swedish law. Specifically, the <strong>E-commerce Act</strong>,
            which regulates the rights and obligations of businesses and consumers in internet commerce.
            For more information about this law, visit the official website of the Swedish Consumer Agency: 
            <a href="https://www.konsumentverket.se/for-foretag/olika-saljkanaler/regler-nar-du-saljer-pa-internet/e-handelslagen/"
              target="_blank" rel="noopener noreferrer">E-commerce Act</a>.
          </p>
          <p className="allmant-paragraph">
            By using <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a>, you confirm
            that you are at least 18 years old or have parental consent, and that you understand and accept these terms of purchase.
          </p>
        </div>
        <div className="bestallning-section">
          <h2 className="bestallning-heading">2. Order</h2>
          <p className="bestallning-paragraph">
            To make your first order on <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a>, 
            &nbsp;you must register an account. During registration, you can choose from four basic service packages:
          </p>
          <ul className="bestallning-list">
            <li>Base</li>
            <li>Total</li>
            <li>Base Business</li>
            <li>Total Business</li>
          </ul>
          <p className="bestallning-paragraph">
            You must have one of these basic packages to manage an account on the platform. Each package provides access to essential features and tools for managing your activities on the website.
          </p>
          <p className="bestallning-paragraph">
            In addition to the basic services, <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a> offers approximately 25 add-on services that you can choose to enhance your experience and help you grow within the application. Each add-on service is charged individually, and you can select the services that best suit your needs.
          </p>
          <h3 className="bestallning-subheading">Payment Terms:</h3>
          <p className="bestallning-paragraph">
            When ordering any service or add-on feature, payment is required in accordance with the payment terms stated in section 4 of these terms. All services and products are invoiced individually and charged according to the price information provided at checkout.
          </p>
        </div>

        <div className="priser-section">
          <h2 className="priser-heading">3. Prices</h2>
          <p className="priser-paragraph">
            All prices on <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a> include value-added tax (VAT), which is governed by your choice of country during account setup. The VAT rates are as follows:
          </p>
          <ul className="priser-list">
            <li>USD/UK: 20% VAT</li>
            <li>SEK: 25% VAT</li>
            <li>YEN: 10% VAT</li>
          </ul>
          <p className="priser-paragraph">
            Companies outside the EU can enter their tax number under "My Account", and the VAT will be deducted from their invoice.
          </p>
          <p className="priser-paragraph">
            No additional fees apply. All of our packages and services are automatically billed monthly. To avoid receiving a new invoice, services must be canceled by the 30th of each month (28th in February). Cancellations can be made at any time during the month via your account.
          </p>
        </div>

        <div className="betalning-section">
          <h2 className="betalning-heading">4. Payment</h2>
          <p className="betalning-paragraph">
            We offer several payment methods depending on your country and preferences:
          </p>
          <ul className="betalning-list">
            <li>PayPal: Available for all countries and all customers.</li>
            <li>Klarna: Available for customers in Sweden.</li>
            <li>Swish: Available for customers in Sweden.</li>
          </ul>
          <p className="betalning-paragraph">
            Once payment has been processed by our payment partners, our system will execute the service requested by the customer, which means that the agreement between the customer and <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a> is valid and binding.
          </p>
          <p className="betalning-paragraph">
            Our payment partners use the latest technology to ensure payment security. <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">Bakesoft.se</a> does not store any payment details such as card numbers or other sensitive information.
          </p>
        </div>

        <div className="leverans-section">
          <h2 className="leverans-heading">5. Delivery</h2>
          <p className="leverans-paragraph">
            When you pay for a new service or account in the application, the account setup usually takes about 5 minutes. Since our business is entirely online, we have no geographical restrictions, and our services are available to customers worldwide.
          </p>
          <p className="leverans-paragraph">
            Any delivery delays would occur if the application is unavailable, which would mean that users cannot access their accounts or services. In such cases, we will notify users if problems arise, either on our side or from our suppliers.
          </p>
        </div>

        <div className="angerratt-section">
          <h2 className="angerratt-heading">6. Right of Withdrawal</h2>
          <p className="angerratt-paragraph">
            Since we only sell digital services, no right of withdrawal applies under current legislation. Once a service has been paid for and activated, the agreement is considered valid and binding.
          </p>
          <p className="angerratt-paragraph">
            Customers have the right to cancel their subscriptions at any time via their account, but we do not offer refunds for an already active and valid subscription.
          </p>
        </div>

        <div className="reklamation-section">
          <h2 className="reklamation-heading">7. Complaints and Warranty</h2>
          <p className="reklamation-paragraph">
            Inside the application, there is a support panel where users can make requests, submit complaints, or ask questions about the application. We strive to respond to all requests within 24 hours.
          </p>
          <p className="reklamation-paragraph">
            If technical issues arise that are caused by us or our suppliers, we will notify users as soon as possible. We take full responsibility for resolving any issues that affect the application's availability.
          </p>
          <p className="reklamation-paragraph">
            As compensation for lost time in the application due to technical problems, we will extend the user's access by the same amount of time the user was unable to use the application.
          </p>
        </div>

        <div className="force-majeure-section">
          <h2 className="force-majeure-heading">8. Force Majeure</h2>
          <p className="force-majeure-paragraph">
            We are not responsible for delays or deficiencies in the delivery of services if these are caused by events beyond our control. This includes, but is not limited to, natural disasters, war, strikes, government decisions, pandemics, or major technical failures affecting our infrastructure or that of our suppliers.
          </p>
          <p className="force-majeure-paragraph">
            In the event of a force majeure situation, we will notify all users as soon as possible. Under such circumstances, our services may be unavailable until the issue is resolved. We will resume services as soon as the situation allows.
          </p>
          <p className="force-majeure-paragraph">
            Force majeure exempts us from liability for delayed or non-delivery of services during the time the event lasts, but we will do our best to minimize the impact on our customers.
          </p>
        </div>

        <div className="tvist-section">
          <h2 className="tvist-heading">9. Dispute</h2>
          <p className="tvist-paragraph">
            In the event of a dispute between the customer and <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a>, the dispute shall first be resolved through negotiation between the parties. We always strive to find a peaceful solution that benefits both parties.
          </p>
          <p className="tvist-paragraph">
            If a dispute cannot be resolved through negotiation, and if legal action becomes necessary, all legal proceedings shall take place in the court in Gothenburg, where our company is based. The dispute shall not be processed in any other jurisdiction or location.
          </p>
        </div>

        <div className="personuppgifter-section">
          <h2 className="personuppgifter-heading">10. Personal Data</h2>
          <p className="personuppgifter-paragraph">
            We comply with the General Data Protection Regulation (GDPR) and protect all of our customers' personal data. The data we collect is solely for account registration purposes and is used to bill the customer and manage the customer's use of our application.
          </p>
          <p className="personuppgifter-paragraph">
            Customers' personal data is securely stored on our servers with Amazon Web Services (AWS), which provides a secure and reliable infrastructure. We ensure that your data is protected according to the latest security standards.
          </p>
          <p className="personuppgifter-paragraph">
            All data stored on our servers belongs to <strong>Hellström Trading i Göteborg AB</strong> and is our exclusive ownership, although you, as a user, have full rights to use the data in accordance with our terms. This clarifies that no ownership is transferred to the user to avoid any claims of data ownership.
          </p>
          <p className="personuppgifter-paragraph">
            We never sell or transfer customer data to third parties in any way, either within or outside the EU. Your data is only used to deliver the services you have ordered from us.
          </p>
        </div>
        &nbsp;
        &nbsp;

      </div>
    </div>
  </div>
</div>

  )
  }

  function BuyingTermsJAP(){
    let ctx = useContext(MyContext)
    return(
    
<div className="integrityPolicyBackgroundImage" style={{ backgroundImage: `url(${ctx.getImageUrl(ctx.buttonId)})` }}>
  <div className="buyingTermstransparentBox">
    <div className="infoBuyingTermsHeader">購入条件</div>
    <div className="infoBuyingTermsWrapper">
      <div className="infoBuyingTermsText">
        
        <div className="allmant-section">
          <h2 className="allmant-heading">1. 一般事項</h2>
          <p className="allmant-paragraph">
            これらの購入条件は、<strong>Hellström Trading i Göteborg AB</strong> 
            （法人番号: 556572-0413）を通じて、ウェブサイト 
            <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a> 
            で行われるすべての購入に適用されます。当社のウェブサイトで注文することにより、これらの条件に従うことに同意したものとみなされます。
          </p>
          <p className="allmant-paragraph">
            <strong>Hellström Trading i Göteborg AB</strong> はスウェーデンのヨーテボリに拠点を置いており、顧客と会社の間のすべての取引および契約は、スウェーデンの法律に基づいています。特に、インターネットでの取引における企業と消費者の権利と義務を規定する<strong>電子商取引法</strong>が適用されます。この法律の詳細については、スウェーデン消費者庁の公式ウェブサイトをご覧ください: 
            <a href="https://www.konsumentverket.se/for-foretag/olika-saljkanaler/regler-nar-du-saljer-pa-internet/e-handelslagen/" 
            target="_blank" rel="noopener noreferrer">電子商取引法</a>。
          </p>
          <p className="allmant-paragraph">
            <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a>を利用することにより、あなたが18歳以上であるか、保護者の同意を得ていることを確認し、これらの購入条件を理解し、受け入れることに同意します。
          </p>
        </div>

        <div className="bestallning-section">
          <h2 className="bestallning-heading">2. 注文</h2>
          <p className="bestallning-paragraph">
            <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a>で初めて注文するには、アカウントを登録する必要があります。登録時には、次の4つの基本サービスパッケージから選択できます:
          </p>
          <ul className="bestallning-list">
            <li>ベース</li>
            <li>トータル</li>
            <li>企業向けベース</li>
            <li>企業向けトータル</li>
          </ul>
          <p className="bestallning-paragraph">
            プラットフォーム上でアカウントを管理するには、これらの基本パッケージのいずれかを使用する必要があります。各パッケージには、ウェブサイトでの活動を管理するために必要な機能とツールへのアクセスが含まれています。
          </p>
          <p className="bestallning-paragraph">
            基本サービスに加えて、<a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a>は、アプリケーション内での体験を向上させ、成長を支援するための約25の追加サービスを提供しています。各追加サービスは個別に課金され、ニーズに最も適したサービスを選択できます。
          </p>
          <h3 className="bestallning-subheading">支払い条件:</h3>
          <p className="bestallning-paragraph">
            いかなるサービスまたは追加機能を注文する際にも、これらの条件の第4条に記載されている支払い条件に従って支払いが必要です。すべてのサービスと製品は個別に請求され、チェックアウト時に提供される価格情報に従って請求されます。
          </p>
        </div>

        <div className="priser-section">
          <h2 className="priser-heading">3. 価格</h2>
          <p className="priser-paragraph">
            <a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a>のすべての価格には、アカウント設定時に選択した国によって異なる付加価値税（VAT）が含まれます。VATの税率は以下の通りです:
          </p>
          <ul className="priser-list">
            <li>USD/UK: 20% VAT</li>
            <li>SEK: 25% VAT</li>
            <li>YEN: 10% VAT</li>
          </ul>
          <p className="priser-paragraph">
            EU外の企業は、「マイアカウント」で税番号を入力でき、VATが請求書から差し引かれます。
          </p>
          <p className="priser-paragraph">
            追加料金は発生しません。すべてのパッケージとサービスは自動的に毎月請求されます。新しい請求書を避けるためには、サービスは各月の30日（2月は28日）までに解約する必要があります。解約はアカウントを通じていつでも行えます。
          </p>
        </div>

        <div className="betalning-section">
          <h2 className="betalning-heading">4. 支払い</h2>
          <p className="betalning-paragraph">
            国や好みに応じて、以下の支払い方法を提供しています:
          </p>
          <ul className="betalning-list">
            <li>PayPal: すべての国とすべての顧客に対応。</li>
            <li>Klarna: スウェーデンの顧客向け。</li>
            <li>Swish: スウェーデンの顧客向け。</li>
          </ul>
          <p className="betalning-paragraph">
            支払いが当社の決済パートナーによって処理されると、当社のシステムは顧客が要求したサービスを実行します。これにより、顧客と<a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a>との間の契約は有効かつ拘束力のあるものとなります。
          </p>
          <p className="betalning-paragraph">
            当社の決済パートナーは、支払いの安全性を確保するための最新技術を使用しています。<a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">Bakesoft.se</a>は、カード番号やその他の機密情報などの支払い情報を保存しません。
          </p>
        </div>

        <div className="leverans-section">
          <h2 className="leverans-heading">5. 配送</h2>
          <p className="leverans-paragraph">
            アプリケーション内で新しいサービスまたはアカウントを支払うと、アカウントの設定は通常約5分以内に完了します。当社のビジネスは完全にオンラインで行われているため、地理的な制限はなく、当社のサービスは世界中の顧客に提供されています。
          </p>
          <p className="leverans-paragraph">
            配送の遅延は、アプリケーションが利用できない場合に発生する可能性があり、ユーザーはアカウントやサービスにアクセスできなくなります。その場合、当社または供給者側で問題が発生した場合には、ユーザーに通知いたします。
          </p>
        </div>

        <div className="angerratt-section">
          <h2 className="angerratt-heading">6. 取消権</h2>
          <p className="angerratt-paragraph">
            当社はデジタルサービスのみを提供しているため、現行の法律に基づいて取消権は適用されません。サービスが支払われ、アクティブ化された時点で契約は有効かつ拘束力のあるものとみなされます。
          </p>
          <p className="angerratt-paragraph">
            顧客はアカウントを通じていつでもサブスクリプションを解約する権利がありますが、既にアクティブで有効なサブスクリプションに対しては払い戻しは行いません。
          </p>
        </div>

        <div className="reklamation-section">
          <h2 className="reklamation-heading">7. クレームと保証</h2>
          <p className="reklamation-paragraph">
            アプリケーション内には、ユーザーがリクエストを行ったり、クレームを提出したり、アプリケーションに関する質問をするためのサポートパネルがあります。当社はすべてのリクエストに24時間以内に対応するよう努めています。
          </p>
          <p className="reklamation-paragraph">
            当社または供給者によって引き起こされた技術的な問題が発生した場合、当社はできるだけ早くユーザーに通知します。当社は、アプリケーションの可用性に影響を与える問題を解決する全責任を負います。
          </p>
          <p className="reklamation-paragraph">
            技術的な問題によりアプリケーションの使用が妨げられた時間に対しては、その期間と同じ期間だけ、ユーザーのアクセスを延長することで補償いたします。
          </p>
        </div>

        <div className="force-majeure-section">
          <h2 className="force-majeure-heading">8. 不可抗力</h2>
          <p className="force-majeure-paragraph">
            私たちは、当社の制御外の事象によりサービスの提供が遅延したり不完全になったりする場合、責任を負いません。これには、自然災害、戦争、ストライキ、政府の決定、パンデミック、または当社や供給者のインフラに影響を与える大規模な技術的な障害が含まれますが、これに限定されません。
          </p>
          <p className="force-majeure-paragraph">
            不可抗力の状況が発生した場合、できるだけ早くすべてのユーザーに通知します。そのような状況下では、問題が解決するまでサービスが利用できない場合があります。状況が許す限り、サービスを再開します。
          </p>
          <p className="force-majeure-paragraph">
            不可抗力は、事象が続いている間、サービスの遅延または提供不能に関する責任を免除しますが、顧客への影響を最小限に抑えるために最善を尽くします。
          </p>
        </div>

        <div className="tvist-section">
          <h2 className="tvist-heading">9. 紛争</h2>
          <p className="tvist-paragraph">
            顧客と<a href="https://www.bakesoft.se" target="_blank" rel="noopener noreferrer">www.bakesoft.se</a>の間で紛争が発生した場合、その紛争はまず両当事者間の交渉により解決されるものとします。当社は常に両当事者にとって有益な平和的な解決策を見つけるよう努めています。
          </p>
          <p className="tvist-paragraph">
            交渉によって紛争が解決できない場合、法的措置が必要な場合は、すべての法的手続きは当社の所在地であるヨーテボリの裁判所で行われます。紛争は他の管轄区や場所では処理されません。
          </p>
        </div>

        <div className="personuppgifter-section">
          <h2 className="personuppgifter-heading">10. 個人情報</h2>
          <p className="personuppgifter-paragraph">
            私たちは、一般データ保護規則（GDPR）に準拠して、すべての顧客の個人情報を保護します。収集される情報はアカウント登録時のみであり、顧客に請求書を発行し、アプリケーションの使用を管理するために使用されます。
          </p>
          <p className="personuppgifter-paragraph">
            顧客の個人情報は、Amazon Web Services（AWS）のサーバーに安全に保存され、安全で信頼性の高いインフラを提供します。最新のセキュリティ基準に従ってデータを保護します。
          </p>
          <p className="personuppgifter-paragraph">
            サーバーに保存されているすべてのデータは<strong>Hellström Trading i Göteborg AB</strong>の所有物であり、当社の専有物です。ただし、ユーザーは当社の条件に従ってデータを使用する完全な権利を有します。これは、データ所有権に対する請求を回避するために、所有権がユーザーに移転されないことを明確にしています。
          </p>
          <p className="personuppgifter-paragraph">
            当社は、EU内外を問わず、いかなる方法でも第三者に顧客情報を販売または移転することはありません。お客様のデータは、お客様が当社から注文したサービスを提供するためにのみ使用されます。
          </p>
        </div>
        &nbsp;
        &nbsp;

      </div>
    </div>
  </div>
</div>

    )
    }




export {SignUp,BaseServices,Faq,BsServices,TotalCorporate,PayPalApp,
  BasicCorporate,BasicInfoTextSwedish,BasicInfoTextEnglish,BasicInfoTextJapanese,
  BasicInfoListSwedish,BasicInfoListEnglish,BasicInfoListJapanese,InfoStartEnglish,InfoStartSwedish,
  InfoStartJapanese,TotalTop,TotalSwedish,TotalEnglish,TotalJapanese,
  TotalInfoTextSwedish,TotalInfoTextEnglish,TotalInfoTextJapanese,SignUpTop,Payment,
  CorporateBasicInfoTextSwedish,CorporateBaicInfoTextEnglish,CorporateBasicInfoTextJapanese,
  AboutUsSwedish,AboutUsEnglish,AboutUsJapanese,AIServicesSwedish,AIServicesEnglish,
  AIServicesJapanese,PlusServices,SlidingMenu,IntegrityPolicy,ContactInfo,BuyingTerms,
  BuyingTermsENG,BuyingTermsJAP}

