import React,{useEffect,useState} from 'react'
import Alert from '../functions/appAlert'
import {useGetData,usePutData} from '../hooks/useGetData'
import useReportErr from '../hooks/useReportErr'
import { RotatingLines } from  'react-loader-spinner'
import './ProdTotalNew2.css'
import {useNavigate} from 'react-router-dom';
import useFetchImageUrl from '../hooks/useFetchImageUrl';
import usePageSettings from '../hooks/usePageSettings';
import languages from '../AppLanguage/AppLanguage';
import AppError from '../AppLanguage/AppError';
import displayGadgetToSmall from '../functions/displayGadgetToSmall'

 export default function ProdTotalNew() {

  const[getproductionObj,setproductionObj]=useState([{}])
  const[getproductionSummary,setproductionSummary]=useState([{}])
  const[getchosenproductionId,setchosenproductionId]=useState(0)
  const[getSavebool,setSavebool]=useState(false)
  const[getproductionStatistics,setproductionStatistics]=useState([{}])

    
  const[getSpinnerLoading,setSpinnerLoading]=useState(false)
  const [getShowAlert,setShowAlert] = useState(false);
  const [getAlertMessage,setAlertMessage] = useState({});
  const[getAlertCode,setAlertCode]=useState(0)
  const[prodTotal,setprodTotal]=useState([{}])
  const[getBackGroundShade,setBackGroundShade]=useState(0.55)
  const[getRenderKey,setRenderKey]=useState(0)
  const[getMenuShade,setMenuShade]=useState(0.75)
  const[getMenuShade2,setMenuShade2]=useState(0.55)
  const [getColorVal,setColorVal]=useState('')
  const [getImageVal,setImageVal]=useState('')
  const [getImageUrl,setImageUrl]=useState('')
  const pageid=14
  const [logtime]=useState(5000)

  
  const [windowWidth,setWindowWith]=useState(0) // this is the window width
  const [windowHeight,setWindowHeight]=useState(0)  // this is the window height
  const [getMobileIsUsed,setMobileIsUsed]=useState(false) // this is the window height
  const[getCountry,setCountry]=useState('se') // this is the country
  
  
  useEffect(()=>{
    let country = localStorage.getItem('country')||'en' // this is the country code
    setCountry(country)
  },[])

     // if language is japanese we use the font Zen Old Mincho
     let newFontFamily=''
     if(getCountry==='ja'){
     newFontFamily= "'Zen Old Mincho, Arial, Helvetica, sans-serif'"
     }else{
     newFontFamily='Arial, Helvetica, sans-serif'
     }
     document.documentElement.style.setProperty('--font-family', newFontFamily);
 
 
     
  const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal } = usePageSettings(pageid);
  const { imageUrl, alertMessage, alertCode, showAlert } = useFetchImageUrl(getImageVal); // runs at mount, gives ut the image url and fetch image from server
  
  const navigate = useNavigate();

  useEffect(() => {
    function setSize() {
      setWindowWith(window.innerWidth);
      setWindowHeight(window.innerHeight);
    
      if(window.innerWidth < 600){
  
        setMobileIsUsed(true)
      } 
  
    }

  
    // Call setSize initially to set the correct state
    setSize();
  
    // Add the event listener
    window.addEventListener('resize', setSize);
  
    // Return a cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', setSize);
    };
  }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]); // Dependencies

  function rgbToRgba(rgb, alpha) {
    return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
  }
  
//--bakcground shade settings
let rgbaVal
let rgbaVal2
let val

if(getColorVal && getColorVal.length > 0){
  let rgb = getColorVal;
  let alpha = getMenuShade; // replace with your alpha value
  let alpha2 = getMenuShade2; // replace with your alpha value
  rgbaVal = rgbToRgba(rgb, alpha);
  rgbaVal2 = rgbToRgba(rgb, alpha2);
  val=`rgba(255, 255, 255, ${getBackGroundShade})`

  }else{
    rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
    rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
    val=`rgba(255, 255, 255, ${getBackGroundShade})`
  }
//---

useEffect(() => {
    let root = document.documentElement;
    root.style.setProperty('--rgbaVal', rgbaVal);
    root.style.setProperty('--rgbaVal2', rgbaVal2);
    root.style.setProperty('--val',val);
    root.style.setProperty('--windowHeight',windowHeight+'px');

    
  }, [getBackGroundShade,getMenuShade,getMenuShade2]);





    // language settings
    const selectedCountry = languages[getCountry];
  
     let title=''
    if(getMobileIsUsed===false){
      title=selectedCountry.totalprodNew?.title
    }else{
      title=selectedCountry.totalprodNew?.titleMobile
    }

    const {
      titleMobile,
      leftHeaderLegend,
      headerMenuProdid,
      headerMenuProductid,
      headerMenuProductName,
      headerMenuProductQuantity,
      headerMenuProductPrice,
      headerMenuProductTotal,
      rightMenuHeaderLegend,
      listHeaderLegend,
      rightListHeaderPID,
      rightListHeaderKID,
      rightListHeaderCategory,
      rightListHeaderAmount,
      rightListHeaderQuantity,
      titleHeaderName,
      titleHeaderAntal,
      titleAdjustMenu,
      titleAdjustMenu1,
      mobileMenuProductid,
      titleProductionId,
      titleCategoryId
    } = selectedCountry.totalprodNew  || {};

     // error messages
 const selectedError=AppError[localStorage.getItem('country')||'en']




        useEffect(() => {
          GetSettings();
        }, [GetSettings]);

        // we set the image url here
        useEffect(() => {
          setImageUrl(imageUrl);
          if (alertCode > 0) {
            setAlertMessage(alertMessage);
            setAlertCode(alertCode);
            setShowAlert(showAlert);
          }
        }, [imageUrl]);
      
        // here we set the color values and image values
        useEffect(() => {
          setBackGroundShade(backGroundShade);
          setMenuShade(menuShade);
          setMenuShade2(menuShade2);
          setColorVal(colorVal);
          setImageVal(imageVal);
        }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);



useEffect(()=>{

  async function GetTotalProductionData(){
    setSpinnerLoading(true)
    try{
      let response= await useGetData(`getTotalProductionData`)
      setproductionObj(response.data[0])
      setproductionSummary(response.data[1])
      setSpinnerLoading(false)
    } catch(error){
    setAlertCode(21001)
    setAlertMessage(selectedError[21001])
    setSpinnerLoading(false)
    setShowAlert(true)
  
    }
   
  }

  GetTotalProductionData()

},[])

const RunReportErrHandler = async (errCode) => {
  setSpinnerLoading(true);
  await useReportErr('ErrReport',{errCode});
  setSpinnerLoading(false);
};

useEffect(() => {
  if (getAlertCode > 0) {
    RunReportErrHandler(getAlertCode);
  }
}, [getAlertCode]);

const handleCloseAlert = () => {
  setShowAlert(false);
};

const sortHandler=()=>{
//here i want to sort the getproductionObj and make it possible to toggel ascending descending
//i want to sort the getproductionObj and make it possible to toggel ascending descending
let mynewObjArr=[...getproductionObj]
if(getSavebool===false){
  mynewObjArr.sort((a,b)=>a.benaemn.localeCompare(b.benaemn))
  setSavebool(true)
}else{
    mynewObjArr.sort((a,b)=>b.benaemn.localeCompare(a.benaemn))
    setSavebool(false)
}
setproductionObj(mynewObjArr)
}

const sortHandlerPID=()=>{
  //here i want to sort the getproductionObj and make it possible to toggel ascending descending
  //i want to sort the getproductionObj and make it possible to toggel ascending descending
  let mynewObjArr=[...getproductionObj]
  if(getSavebool===false){
    mynewObjArr.sort((a,b)=>a.produktionid-b.produktionid)
    setSavebool(true)
}else{
      mynewObjArr.sort((a,b)=>b.produktionid-a.produktionid)
      setSavebool(false)
  }
  setproductionObj(mynewObjArr)
  }

  const sortNumHandler=()=>{
    //here i want to sort the getproductionObj and make it possible to toggel ascending descending
    //i want to sort the getproductionObj and make it possible to toggel ascending descending
    let mynewObjArr=[...getproductionObj]
    if(getSavebool===false){
      mynewObjArr.sort((a,b)=>a.antal-b.antal)
      setSavebool(true)
  }else{
        mynewObjArr.sort((a,b)=>b.antal-a.antal)
        setSavebool(false)
    }
    setproductionObj(mynewObjArr)
    }

    const sortSumHandler=()=>{
      // here i want to sort the getproductionObj and make it possible to toggel ascending descending
      // for the pris column of the getproductionObj
      let mynewObjArr=[...getproductionObj]
      if(getSavebool===false){
        mynewObjArr.sort((a,b)=>a.pris-b.pris)
        setSavebool(true)
    }else{
          mynewObjArr.sort((a,b)=>b.pris-a.pris)
          setSavebool(false)
      }
      setproductionObj(mynewObjArr)
    }

    const sortTotalHandler=()=>{
      // here i want to sort the getproductionObj and make it possible to toggel ascending descending
      // for the total column of the getproductionObj
      let mynewObjArr=[...getproductionObj]
      if(getSavebool===false){
        mynewObjArr.sort((a,b)=>(a.antal*a.pris)-(b.antal*b.pris))
        setSavebool(true)
    }else{
          mynewObjArr.sort((a,b)=>(b.antal*b.pris)-(a.antal*a.pris))
          setSavebool(false)
      }
      setproductionObj(mynewObjArr)
    }


  // if the user changges the value on the range control we save the value in the database
async function SetBackGroundShadeHandler(val){
  setBackGroundShade(val)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateBackGroundShade5`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }
  }catch(error){
      setAlertCode(21002)
      setAlertMessage(selectedError[21002])
      setSpinnerLoading(false)
      setShowAlert(true)

      return;
    }

}

async function SetmenuColorHandler(val){
  setMenuShade(val)
  setMenuShade2(val-0.20)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateMenuShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }

}catch(error){
  setAlertCode(21003)
  setAlertMessage(selectedError[21003])
  setSpinnerLoading(false)
  setShowAlert(true)

  return;
}
}


//const totalsum = Number(getproductionSummary.reduce((acc, data) => acc + data.summa,0))
const totalsum = getproductionSummary.reduce((acc, data) => {
  const summa = parseFloat(data.summa);
  return isNaN(summa) ? acc : acc + summa;
}, 0);

return(
  <>
<div className="xptotWrapper">
  {windowWidth<360?displayGadgetToSmall():''}
    <div className={getBackGroundShade<1?"xpProdTotalArea1":"xpProdTotalArea"}>

{getBackGroundShade<1?<img src={imageUrl} alt="Bakesoft" className="xpbackgroundImageRecepyNew" />:''}
 
  <div className="xpheaderArea">
  {getMobileIsUsed===false?
  <div className={getBackGroundShade<1?"xpCompName":"xpCompName1"}>{title}</div>
  :<div className={getBackGroundShade<1?"xpCompName":"xpCompName1"}>{titleMobile}</div>}
 </div>
  
  
  <div className="xprangeArea">
  <input type="range" min="0.0" max="1" value={getMenuShade||0.2} step="0.10" id={2}  
  title={titleAdjustMenu} className="xprange2" onChange={(e)=>SetmenuColorHandler(e.target.value)}/>
  
  <input type="range" min="0.0" max="1" value={getBackGroundShade||0.2} step="0.10" id={2}  
  title={titleAdjustMenu1} className="xprange1" onChange={(e)=>SetBackGroundShadeHandler(e.target.value)}/>
  </div>


  <div className="xpProdTotalWindowA">
    
<fieldset className="xpProdTotalFieldset">
<legend className="xpProdTotalLegend">{leftHeaderLegend}:</legend>
     <div className="xpProdTotalHeaderArea">
    <div className="xpProdTotalProduktionsId"  onClick={()=>sortHandlerPID()}>{headerMenuProdid}</div>
    <div className="xpProdTotalBenaemn" onClick={()=>sortHandler()}  title={titleHeaderName}>{headerMenuProductName}</div>
    <div className="xpProdTotalAntal" onClick={()=>sortNumHandler()} title={titleHeaderAntal}>{headerMenuProductQuantity}</div>
    <div className="xpProdTotalPris" onClick={()=>sortSumHandler()}>{headerMenuProductPrice}</div>
    <div className="xpProdTotalTot"  onClick={()=>sortTotalHandler()}>{headerMenuProductTotal}</div>

</div>


<div className="xptotallist1Data">{getproductionObj.map((data,index)=>
    <div key={index}>
      <button className={index%2===0?"xptotalproduktionid":"xptotalproduktionid1"}>{data.produktionid}</button>
      <button className={index%2===0?"xptotalbenaemn":"xptotalbenaemn1"}>{data.benaemn}</button>
      <button className={index%2===0?"xptotalantal":"xptotalantal1"}>{data.antal}</button>
      <button className={index%2===0?"xptotalpris":"xptotalpris1"}>{data.pris}</button>
     <button className={index%2===0?"xptotallisttotal":"xptotallisttotal1"}>{(data.antal*data.pris).toFixed(2)}</button>
       </div>)}
</div>
</fieldset>

    </div>
   <div className="xpProdTotalWindowB">
   <div className="xpProdTotalSummaryBottom">
         
        <div className="xpProdTotalSummaryBottomAntal"> 
        <div className="xpProdTotalSummaryText">{rightMenuHeaderLegend}:{totalsum.toFixed(2)}</div></div>
     </div>
    <fieldset className="xpProdTotalSummaryFieldset">
        <legend className="xpProdTotalSummaryTopLegend">{listHeaderLegend}</legend>
     <StatusHeader prodTotal={prodTotal} getproductionSummary={getproductionSummary}
          rightListHeaderPID={rightListHeaderPID}
          rightListHeaderKID={rightListHeaderKID}
          rightListHeaderCategory={rightListHeaderCategory}
          rightListHeaderAmount={rightListHeaderAmount}
          rightListHeaderQuantity={rightListHeaderQuantity}
          titleProductionId={titleProductionId}
          titleCategoryId={titleCategoryId}
           sortHandler={sortHandler}/>
 

    </fieldset>
    
   </div>
   <div className="xpProdTotalSpinnerContainer">
        <div className="xpProdTotalSpinner">
          <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="0.75" width="60" visible={getSpinnerLoading}/>
        </div>
        </div>

  {showAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}/>:''}

</div>
</div>
</>
)
}

function StatusHeader(props){
    let{prodTotal,getproductionSummary,sortHandler,
    
    rightListHeaderPID,rightListHeaderKID,rightListHeaderCategory,rightListHeaderAmount,
    rightListHeaderQuantity,titleProductionId,titleCategoryId
    
    }=props
    
    
    //let totalAmount = getproductionStatistics.reduce((acc, data) => acc + (data.totaltantal * data.pris), 0);
    let totalantal=getproductionSummary.reduce((acc,data)=>acc+data.totaltantal,0)
    
    const totalsum = getproductionSummary.reduce((acc, data) => acc + data.summa,0);
    
          return(
              
            <div className="xpstatusheaderMain">
          
        
           
           <div className="xpstatusheaderHeader">
              <button className="xpstatusheaderProduktionsid" title={titleProductionId} onClick={()=>sortHandler(1)}>{rightListHeaderPID}</button>
              <button  className="xpstatusheaderProduktid" title={titleCategoryId}>{rightListHeaderKID}</button>
              <button  className="xpstatusheaderBenaemn">{rightListHeaderCategory}</button>
              <button  className="xpstatusheaderSum">{rightListHeaderAmount}</button>
              <button  className="xpstatusheaderNum">{rightListHeaderQuantity}</button>
              </div>
           
           <div className="xpstatusheaderData">{getproductionSummary.map((data,index)=>
           
           <div key={index}>
          
              <button className={index%2===0?"xpsamproduktionid":"xpsamproduktionid1"}>{data.produktionid}</button>
              <button className={index%2===0?"xpsamkategoriid":"xpsamkategoriid1"}>{data.kategoriid}</button>
              <button className={index%2===0?"xpsambenaemn":"xpsambenaemn1"}>{data.benaemn}</button>
              <button className={index%2===0?"xpsamsum":"xpsamsum1"}>{data.summa}</button>
              <button className={index%2===0?"xpsamtotal":"xpsamtotal1"}>{data.totaltantal}</button>
              </div>)}

         
           
              </div>
              
          
           
           
    
           </div>
            
          )
        }
export {ProdTotalNew,StatusHeader}
