
import React,{useState,useEffect,useRef} from 'react'
import useReportErr from '../hooks/useReportErr'
import {useGetData,useDeleteData,usePostData,usePutData} from '../hooks/useGetData'
import {useNavigate} from 'react-router-dom';
import useFetchImageUrl from '../hooks/useFetchImageUrl';
import usePageSettings from '../hooks/usePageSettings';
import languages from '../AppLanguage/AppLanguage';
import AppError from '../AppLanguage/AppError';
import useNutritionSummary from '../hooks/useNutritionSummary'
import displayGadgetToSmall from '../functions/displayGadgetToSmall'
import noimagefound1 from '../images/noimagefound1.jpg'
import './SearchRecepyScroll.css'
import { RotatingLines } from 'react-loader-spinner'

export default function SearchRecepyScroll() {




      const[getCountry,setCountry] = useState('se')
      const[getImageVal,setImageVal] = useState('')
      const[getRecepydata,setRecepyData] = useState([])
      const[getIngredNutrients,setIngredNutrients] = useState([{}])
      const[windowWidth,setWindowWith] = useState(window.innerWidth)
      const[windowHeight,setWindowHeight] = useState(window.innerHeight)
      const[mobileIsUsed,setMobileIsUsed] = useState(false)
      const[getBackGroundShade,setBackGroundShade]=useState(0.45)
      const[getRenderKey,setRenderKey]=useState(0) // this is the key for the component
      const[getMenuShade,setMenuShade]=useState(0.75)
      const[getMenuShade2,setMenuShade2]=useState(0.45)
      const [getColorVal,setColorVal]=useState('')
      const [getImageUrl,setImageUrl]=useState('')
      const[getSpinnerLoading,setSpinnerLoading]=useState(false)
      const[getFirstSpinnerLoading,setFirstSpinnerLoading]=useState(false)
      const [getShowAlert,setShowAlert] = useState(false);
      const [getAlertMessage,setAlertMessage] = useState({});
      const[getAlertCode,setAlertCode]=useState(0)
      const[getBackGroundShade1,setBackGroundShade1]=useState(0.45)
      const[getScrollCategories,setScrollCategories]=useState([{}])
      const[getChosenCategory,setChosenCategory]=useState(0)
      const[getTabNr,setTabNr]=useState(1)
      const[imagePageId,setImagePageId]=useState(1)
      const[getImages,setImages]=useState([])
      const loader = useRef(null);
      const[imageDetails,setImageDetails]=useState([])
      const[getChosenImage,setChosenImage]=useState(null)
      const[getChosenInfo,setChosenInfo]=useState(null)
      const [getChosenReceptid,setChosenReceptid]=useState(0)
      const[getRecepyMain,setRecepyMain]=useState([{}])
      const[getRecipeDescription,setRecipeDescription]=useState([{}])
      const[getChosenDescription,setChosenDescription]=useState(null)
    

    
      const[pageid] = useState(22)




    useEffect(()=>{
        let country = localStorage.getItem('country')||'se' // this is the country code
        setCountry(country)
      },[])
  
      
      let newFontFamily = '';
      if (getCountry === 'ja') {
         newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
      } else {
       newFontFamily = 'Arial, Helvetica, sans-serif'
        //newFontFamily = 'Lobster'
      //newFontFamily = 'Times New Roman, Times, serif'
      //newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
      }
      document.documentElement.style.setProperty('--font-family', newFontFamily);
  
  
  
          const { nutrientSummary, calculateTotalNutrients } = useNutritionSummary(getRecepydata,getIngredNutrients);
          const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal } = usePageSettings(pageid);
          const { imageUrl, alertMessage, alertCode, showAlert } = useFetchImageUrl(getImageVal); // runs at mount, gives ut the image url and fetch image from server
  

          useEffect(() => {
            function setSize() {
              setWindowWith(window.innerWidth);
              setWindowHeight(window.innerHeight);
            
              if(window.innerWidth < 600){
          
                setMobileIsUsed(true)
              } else{
                setMobileIsUsed(false)
              }
          
            }
          
          
            // Call setSize initially to set the correct state
            setSize();
          
            // Add the event listener
            window.addEventListener('resize', setSize);
          
            // Return a cleanup function to remove the event listener when the component unmounts
            return () => {
              window.removeEventListener('resize', setSize);
            };
          }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]); // Dependencies
          

          function rgbToRgba(rgb, alpha) {
            return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
          }
          
          //--bakcground shade settings
          let rgbaVal
          let rgbaVal2
          let val
          
          if(getColorVal && getColorVal.length > 0){
          let rgb = getColorVal;
          let alpha = getMenuShade; // replace with your alpha value
          let alpha2 = getMenuShade2; // replace with your alpha value
          rgbaVal = rgbToRgba(rgb, alpha);
          rgbaVal2 = rgbToRgba(rgb, alpha2);
          val=`rgba(255, 255, 255, ${getBackGroundShade})`
          
          }else{
            rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
            rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
            val=`rgba(255, 255, 255, ${getBackGroundShade})`
          }
          //---
          
          useEffect(() => {
            let root = document.documentElement;
            root.style.setProperty('--rgbaVal', rgbaVal);
            root.style.setProperty('--rgbaVal2', rgbaVal2);
            root.style.setProperty('--val',val);
            root.style.setProperty('--windowHeight',windowHeight+'px');
          
            
          }, [getBackGroundShade,getMenuShade,getMenuShade2]);
        
    // language settings
    const selectedCountry = languages[getCountry];

     let title=''
    if(mobileIsUsed===false){
      title=selectedCountry.scrollRecipe?.title
    }else{
      title=selectedCountry.scrollRecipe?.mobileTitle
    }
 

        const {

          titleJusteraBakgrund,
          scrollIngredientButtontext,
          scrollDescriptionButtontext,
          scrollBenaemnText,
          scrollMangdText,
          scrollSummaText,
          scrollKcalText,
          scrollArborderText,
          scrollDescriptionHeaderText,



        } = selectedCountry.scrollRecipe || {}

   // error messages
   const selectedError=AppError[localStorage.getItem('country')||'en']

   const{
    recepyCreated,
    recepyDeleted,
    yesNoDeleteRecepy,
    yesNoDeleteRecepyWithImages,
    btnYes,
    btnNo,
    recipeUpdated,
    selectedImageDeleted,
    imageUploadedMessage,
    imageDeletQuestion,
    imageSearchTextToLong,
    aiimageIsDone,
    aiimageCantBeDone,
    aiImageCantBeUploaded,
    aiSubscriptionVoid,
    imageuploadVoid,
    
    
   }=selectedCountry.confirmationMessages || {}
          
          useEffect(() => {
            GetSettings();
          }, [GetSettings]);

          // we set the image url here
          useEffect(() => {
            setImageUrl(imageUrl);
            if (alertCode > 0) {
              setAlertMessage(alertMessage);
              setAlertCode(alertCode);
              setShowAlert(showAlert);
            }
          }, [imageUrl]);
        
          // here we set the color values and image values
          useEffect(() => {
            setBackGroundShade(backGroundShade);
            setMenuShade(menuShade);
            setMenuShade2(menuShade2);
            setColorVal(colorVal);
            setImageVal(imageVal);
          }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);



useEffect(()=>{
  setFirstSpinnerLoading(true)
  async function GetScrollCategories(){
    try{
      const response = await useGetData(`getRecepyCategories`)
      if(response.status===200){
        setScrollCategories(response.data[0])
        setFirstSpinnerLoading(false)
      }
    }catch(error){
      setAlertCode(19010)
      setAlertMessage(selectedError[55001])
      setFirstSpinnerLoading(false)
      setShowAlert(true)

      return;
    }
  }
  GetScrollCategories()
},[])


// if the user changges the value on the range control we save the value in the database
async function SetBackGroundShadeHandler(val){
    setBackGroundShade(val)
    setRenderKey(getRenderKey+1)
    setSpinnerLoading(true)
    try{
      const response = await usePutData(`updateBackGroundShade`,{pageid,val})
      if(response.status===200){
      setSpinnerLoading(false)
      }
    }catch(error){
        setAlertCode(19011)
        setAlertMessage(selectedError[55002])
        setSpinnerLoading(false)
        setShowAlert(true)
  
        return;
      }
  
  }
  
  async function SetmenuColorHandler(val){
    setMenuShade(val)
    setMenuShade2(val-0.20)
    setRenderKey(getRenderKey+1)
    setSpinnerLoading(true)
    try{
      const response = await usePutData(`updateMenuShade`,{pageid,val})
      if(response.status===200){
      setSpinnerLoading(false)
      }
  
  }catch(error){
    setAlertCode(19012)
    setAlertMessage(selectedError[55003])
    setSpinnerLoading(false)
    setShowAlert(true)
  
    return;
  }
  }

  const chosenCategoryhandler = (val) => {
    setChosenCategory(val)  
    setChosenImage(null)
    setChosenInfo(null)
    setChosenDescription(null)
    setChosenReceptid(0)
  }


  useEffect(() => {
    GetImageData(getChosenCategory);
    setImagePageId(1)
    setImages([])
}, [getChosenCategory]);



async function GetImageData(getChosenCategory){
  setFirstSpinnerLoading(true)
  try{
      const token = localStorage.getItem('auth_token');
      const headers = token ? { authorization: `Bearer ${token}` } : {};  
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/streamBucketScrollImages/${getChosenCategory}/${imagePageId}`, { headers });
      if(response.status === 200){
          const data = await response.json();
          const images = data.map(item => item.image);
          const details = data.map(item => item.details);
          setImageDetails(details);
          
          const imageBlobs = images.map(image => {
              const byteCharacters = atob(image);
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              return new Blob([byteArray], {type: 'image/jpeg'}); // adjust the MIME type as needed
          });
          const imageUrls = imageBlobs.map(blob => URL.createObjectURL(blob));

          // Add images to state
          setImages(prevImages => [...prevImages, ...imageUrls]);
          // setTimeout(() => {
          //   setSpinnerLoading(false);
          // }, 1000); // delay in milliseconds
          setFirstSpinnerLoading(false)
   
      }
  }catch(error){
      setAlertCode(19009)
      setAlertMessage(selectedError[55004])
      setFirstSpinnerLoading(false)
      setShowAlert(true)
  }
}

const setChosenImageHandler = (index) => {
  setChosenImage(index);
  
}

const setChosenInfoHandler = (index,receptid) => {

  setChosenReceptid(receptid)
  // i want to toggek here to be able to do on and off
  if(getChosenInfo===index){
    setChosenInfo(null)
  }else{
  setChosenInfo(index);
  }
}


useEffect(()=>{
async function GetRecepyData(){
  setSpinnerLoading(true)
  try{
    const response=await useGetData(`getRecepyMain/${getChosenReceptid}`)
    if(response.status===200){
      setRecepyMain(response.data[0])
      setRecipeDescription(response.data[1])
      setTimeout(() => {
        setSpinnerLoading(false);
      }, 1000);

    }
  }catch(error){
    setAlertCode(19013)
    setAlertMessage(selectedError[55005])
    setSpinnerLoading(false)
    setShowAlert(true)
  }

}
GetRecepyData()
},[getChosenReceptid])

const chosenDescriptionHandler = (index,receptid) => {

  setChosenReceptid(receptid)
  if(getChosenDescription===index){
  setChosenDescription(null)
}else{
  setChosenDescription(index)
}
}




    return(
<div className="bsaTotWrapper">
              {windowWidth<360?displayGadgetToSmall():''}
  <div className={getBackGroundShade<1?"bsatotalArea":"bsatotalArea1"}>
          {getBackGroundShade<1?<img src={getImageUrl} alt="Bakesoft" className="bsabackgroundImageRecepyNew" />:''}
 
       <div className="bsaheaderArea">
           {getCountry!=='ja'?
            <div className={getBackGroundShade<1?"bsacompName":"bsacompName1"}>{title}</div>
            : <div className={getBackGroundShade<1?"bsacompNameJAP":"bsacompNameJAP1"}>{title}</div>}
      </div>

       <div className="bsarangeArea">
          <input type="range" min="0.0" max="1" value={getMenuShade||0.2} step="0.10" id={2}  
            title={titleJusteraBakgrund} className="bsarange2" onChange={(e)=>SetmenuColorHandler(e.target.value)}/>

          <input type="range" min="0.0" max="1" value={getBackGroundShade||0.2} step="0.10" id={2}  
            title={titleJusteraBakgrund} className="bsarange1" onChange={(e)=>SetBackGroundShadeHandler(e.target.value)}/>
      </div>


            <div className="bsaareaA">
                  <div className="bsacategoriSelectWrapper">
                    
                        <select className="bsacategorySelect" onChange={(e)=>chosenCategoryhandler(e.target.value)}>
                       
                          {getScrollCategories.map((item,index)=>{
                            return(
                              <option key={index} value={item.kategoriId}>{item.Benaemn}</option>
                            )
                          }
                          )}
                        </select>

                  </div>
              
            </div>

            <div className="bsaareaB">
                    <div className="bsaimageArea">
                          {getImages.map((imageUrl, index) => (
                             <><div key={index} className={getChosenImage!==null && getChosenImage===index?"bsaimageContainer1":"bsaimageContainer"}>
                                  <div className="bsaimageOverlay">
                                        <img key={index} src={imageUrl} alt={`Image ${index + 1}`} 
                                        className="bsascrollImages"/>
                                  </div>
                                  <div className={`${getCountry !== 'ja' ? 'textOverlay' : 'textOverlayJap'} ${getChosenImage === index ? 'active' : ''}`}
                                              onClick={() =>setChosenImageHandler(index) }
                                              > {imageDetails[index].benaemn} 
                                        </div>
                                  
                                                {getChosenImage!==null && getChosenImage===index?
                                              <div className={`buttonContainer ${getChosenImage === index ? 'visible' : ''}`}>
                                                      <button className="bsagetinfoButton"
                                                        onClick={() =>setChosenInfoHandler(index,imageDetails[index].receptid)}
                                                      >{scrollIngredientButtontext}</button>
                                                      <button className="bsagetinfoButton"
                                                      onClick={() =>chosenDescriptionHandler(index,imageDetails[index].receptid)}
                                                      >{scrollDescriptionButtontext}</button>
                                              </div>
                                                :''}
                                                                    
                              </div>
                              <div className={`infocontainer ${getChosenInfo!==null && getChosenInfo === index ? 'open' : 'closed'}`}>
                                   {getChosenInfo === index && <div className="bsarecipeBox">
                                    <div className={getCountry!=='ja'?"bsarecipeBoxHeader":"bsarecipeBoxHeaderJAP"}>{imageDetails[index].benaemn}</div>
                                    <div className="spinner">
                                     <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="0.75" width="60" visible={getSpinnerLoading}/>
                                   </div>
                                      <div className="bsaRecipeList">
                                            <div className="bsaRecipeListHeader">
                                            <div className="bsaitemBenaemn">{scrollBenaemnText}</div>
                                            <div className="bsaitemMangd">{scrollMangdText}</div>
                                            <div className="bsaitemAdsum">{scrollSummaText}</div>
                                            <div className="bsaitemCalories">{scrollKcalText}</div>
                                            <div className="bsaitemArborder">{scrollArborderText}</div>
                                    </div>
                                        {getRecepyMain.map((item,index)=>{
                                          return(
                                            <div key={index} className="bsaReceipeListItem">
                                              <div className={index%2===0?"bsaitemBenaemn":"bsaitemBenaemn1"}>{item.benaemn}</div>
                                              <div className={index%2==0?"bsaitemMangd":"bsaitemMangd1"}>{item.mangd}</div>
                                              <div className={index%2==0?"bsaitemAdsum":"bsaitemAdsum1"}>{Math.round(item.ADsum)}</div>
                                              <div className={index%2==0?"bsaitemCalories":"bsaitemCalories1"}>{Math.round(item.kaloriekoeff*item.mangd)}</div>
                                              <div className={index%2===0?"bsaitemArborder":"bsaitemArborder1"}>{item.arborder}</div>
                                              
                                            </div>
                                          )
                                        })}
                        
                                    </div>

                     
                                    </div>}
                              </div>
                              <div className={`infocontainer ${getChosenDescription!==null && getChosenDescription === index ? 'open' : 'closed'}`}>

                              {getChosenDescription === index && <div className="bsaDescriptionBox">
                                {getChosenInfo===null?<div className={getCountry!=='ja'?"bsaDescriptionBoxHeader":"bsaDescriptionBoxHeaderJAP"}>{imageDetails[index].benaemn}</div>:
                                <div className="bsaDescriptionBoxHeader">{scrollDescriptionHeaderText}</div>}
                                <div className="spinner">
                                     <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="0.75" width="60" visible={getSpinnerLoading}/>
                                   </div>
                                <textarea className="bsaDescriptionBoxText"
                                value={getRecipeDescription && getRecipeDescription.length>0 && getRecipeDescription[0].btext||''} readOnly={true}
                                ></textarea>
                                
                                </div>}
                              </div>
                             </>
                                                
                          ))}

                    </div>
              </div>

  <div className="bsasearchSpinnerContainer">
    <div className="bsasearchSpinner">
    <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="0.75" width="60" visible={getFirstSpinnerLoading}/>
    </div>
  </div>

</div>
</div>

    )
}

