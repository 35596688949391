import React,{useEffect,useState} from 'react'
import useDeviceDetection from '../hooks/useDeviceDetection';
import listRecept from '../hooks_styles/listRecept';
import {useGetData,usePutData}from '../hooks/useGetData';
import Componentname from '../hooks/Componentname';
import {ProgressBar, RotatingLines } from 'react-loader-spinner';
import Alert from '../functions/appAlert';
import useReportErr from '../hooks/useReportErr';
import './ListRecept1.css'
import {useNavigate} from 'react-router-dom';
import useFetchImageUrl from '../hooks/useFetchImageUrl';
import usePageSettings from '../hooks/usePageSettings';
import languages from '../AppLanguage/AppLanguage';
import AppError from '../AppLanguage/AppError';
import displayGadgetToSmall from '../functions/displayGadgetToSmall';
import { ImCheckboxChecked } from "react-icons/im";
import { ImCheckboxUnchecked } from "react-icons/im";
function ListRecept() {

    const[getKategori,setKategori]=useState([{}])
    const[getSpinnerLoading,setSpinnerLoading]=useState(false)
    const[getAlertCode,setAlertCode]=useState(0)
    const[getAlertMessage,setAlertMessage]=useState('') // this is the message for the alert
    const[getShowAlert,setShowAlert]=useState(false) // this is the alert
    const[getBackGroundShade,setBackGroundShade]=useState(0.75)
    const[getRenderKey,setRenderKey]=useState(0) // this is the key for the component
    const[getMenuShade,setMenuShade]=useState(0.95)
    const[getMenuShade2,setMenuShade2]=useState(0.75)
    const[getShowYesNoAlert,setShowYesNoAlert]=useState(false)
    const[getYesNoAlertMessage,setYesNoAlertMessage]=useState('')
    const[getReceptList,setReceptList]=useState([{}]) // this is the list of ingredients
    const[getchosenCategory,setchosenCategory]=useState(0) // this is the chosen category
    const[recepyData,setRecepyData]=useState([]) // this is the data for the ingredients
    const[getButtonSavedStatus,setButtonSavedStatus]=useState(false) // this is the button saved status
    const[getChosenRecepy,setChosenRecepy]=useState(0) // this is the chosen recepy
    const[getColorVal,setColorVal]=useState(0) // this is the color value
    const [getImageVal,setImageVal]=useState('')
    const [getImageUrl,setImageUrl]=useState('')
    const[pageid]=useState(2) // this is the page id
    const[logtime]=useState(5000) // this is the logtime
    const[getCountry,setCountry]=useState('se') // this is the country
    const [getMobileIsUsed,setMobileIsUsed]=useState(false) // this is the mobile is used


    const [windowWidth,setWindowWith]=useState(0) // this is the window width
    const [windowHeight,setWindowHeight]=useState(0)  // this is the window height
    const[getDisposableHeight,setDisposableHeight]=useState(0) // this is the disposable height


    useEffect(()=>{
      let country = localStorage.getItem('country')||'en' // this is the country code
      setCountry(country)
    },[])

    let newFontFamily = '';
    if (getCountry === 'ja') {
       newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
    } else {
      newFontFamily = 'Arial, Helvetica, sans-serif'
    //newFontFamily = 'Times New Roman, Times, serif'
    //newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
    }
    document.documentElement.style.setProperty('--font-family', newFontFamily);
  
    

        const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal } = usePageSettings(pageid);
        const { imageUrl, alertMessage, alertCode, showAlert } = useFetchImageUrl(getImageVal); // runs at mount, gives ut the image url and fetch image from server


        const navigate = useNavigate();
        useEffect(() => {
          function setSize() {
            var totalHeight=window.innerHeight
     
              setDisposableHeight(totalHeight-150)
            setWindowWith(window.innerWidth);
            setWindowHeight(window.innerHeight);
            if(window.innerWidth<575.98){
              setMobileIsUsed(true)
            }
          }
          setSize();
  
        }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);
  
        function rgbToRgba(rgb, alpha) {
          return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
        }
        
      //--bakcground shade settings
      let rgbaVal
      let rgbaVal2
      let val
      
      if(getColorVal && getColorVal.length > 0){
        let rgb = getColorVal;
        let alpha = getMenuShade; // replace with your alpha value
        let alpha2 = getMenuShade2; // replace with your alpha value
        rgbaVal = rgbToRgba(rgb, alpha);
        rgbaVal2 = rgbToRgba(rgb, alpha2);
        val=`rgba(255, 255, 255, ${getBackGroundShade})`
      
        }else{
          rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
          rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
          val=`rgba(255, 255, 255, ${getBackGroundShade})`
        }
      //---
      
      useEffect(() => {
          let root = document.documentElement;
          root.style.setProperty('--rgbaVal', rgbaVal);
          root.style.setProperty('--rgbaVal2', rgbaVal2);
          root.style.setProperty('--val',val);
          root.style.setProperty('--disposableHeight',getDisposableHeight+'px');
          root.style.setProperty('--windowHeight',windowHeight+'px');
          
        }, [getBackGroundShade,getMenuShade,getMenuShade2]);
      
        //-----

   
      // language settings
      const selectedCountry = languages[getCountry];
      
       let receptlistaTitle=''
      if(getMobileIsUsed===false){
      receptlistaTitle=selectedCountry.receptlista?.title
      }else{
      receptlistaTitle=selectedCountry.receptlista?.titleMobile
      }
      const kategoriLegend=selectedCountry.receptlista?.kategoriLegend
      const headerId=selectedCountry.receptlista?.headerId
      const headerNamn=selectedCountry.receptlista?.headerNamn
      const headerAktiv=selectedCountry.receptlista?.headerAktiv
      const headerDela=selectedCountry.receptlista?.headerDela
      const headerSpara=selectedCountry.receptlista?.headerSpara
      const ListSaveButton=selectedCountry.receptlista?.ListSaveButton
      const SavedOnButton=selectedCountry.receptlista?.SavedOnButton
      const selectFirstOption=selectedCountry.receptlista?.selectFirstOption
      const titleAdjustMenu=selectedCountry.receptlista?.titleAdjustMenu
      const titleAdjustMenu1=selectedCountry.receptlista?.titleAdjustMenu1

      
        // error messages
        const selectedError=AppError[localStorage.getItem('country')]


          useEffect(() => {
            GetSettings();
          }, [GetSettings]);

          // we set the image url here
          useEffect(() => {
            setImageUrl(imageUrl);
            if (alertCode > 0) {
              setAlertMessage(alertMessage);
              setAlertCode(alertCode);
              setShowAlert(showAlert);
            }
          }, [imageUrl]);

                   // here we set the color values and image values
                   useEffect(() => {
                    setBackGroundShade(backGroundShade);
                    setMenuShade(menuShade);
                    setMenuShade2(menuShade2);
                    setColorVal(colorVal);
                    setImageVal(imageVal);
                  }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);

          useEffect(()=>{
            async function FetchAllRecept() {
                setSpinnerLoading(true)
        
          
                try {
                  const response = await useGetData('getRecepyCategories')
                  setKategori(response.data[0]);
                  setSpinnerLoading(false)
             
                } catch (error) {
                  setAlertCode(41001)
                  setAlertMessage(selectedError[41001])
                  setSpinnerLoading(false)
                  setShowAlert(true)
                }

        
            
                
          
                }
                FetchAllRecept();

          },[])

useEffect(()=>{
    async function FetchAllRecepy() {
    setSpinnerLoading(true)
            
              
     try {
         const response = await useGetData(`recepybycategoryList/${getchosenCategory}`)
        setRecepyData(response.data[0]);
        setSpinnerLoading(false)
                 
        } catch (error) {
        setAlertCode(41002)
        setAlertMessage(selectedError[41002])
        setSpinnerLoading(false)
        setShowAlert(true)
        }

 
             
}
FetchAllRecepy()
},[getchosenCategory])

const RunReportErrHandler = async (errCode) => {
    setSpinnerLoading(true);

      await useReportErr('ErrReport',{errCode});
    setSpinnerLoading(false);
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  useEffect(() => {
    if (getAlertCode > 0) {
      RunReportErrHandler(getAlertCode);
    }
  }, [getAlertCode]);
        



const kategoriHandler=(val)=>{
    setchosenCategory(val)
}

const activeHandler=(receptid)=>{
  setChosenRecepy(receptid)
    let mynewObjArray =[...recepyData]
    // find the index by ingredid
    let index = mynewObjArray.findIndex(data =>data.receptid===receptid);
    // first we find the value of the active
    let active = mynewObjArray[index].active
    if(active===1){
        active=0
        mynewObjArray[index].buttonStatus=1
    }else{
        active=1
        mynewObjArray[index].buttonStatus=1
  
    }
    mynewObjArray[index].active=active
    // add buttonstatus to the object
    //mynewObjArray[index].buttonStatus=active
   // mynewObjArray[index].buttonStatus=active
    setRecepyData(mynewObjArray)

}

async function UpdateActive(receptid){   
    setSpinnerLoading(true)
    // here lets find the active and shared values
    let mynewObjArray =[...recepyData]
    // find the index by ingredid
    let index = mynewObjArray.findIndex(data =>data.receptid===receptid);
    // first we find the value of the active
    let active = mynewObjArray[index].active
    let shared = mynewObjArray[index].shared

    try{
        const response = await usePutData(`updateRecepyActiveShared`,{receptid,active,shared,getchosenCategory})
        if(response.status===200){
        setSpinnerLoading(false)
        //here we must set buttonstatus back to 0
        mynewObjArray[index].buttonStatus=0
        mynewObjArray[index].buttonSaved=1
        setRecepyData(mynewObjArray)
        }
    }catch(error){
        setAlertCode(41003)
        setAlertMessage(selectedError[41003])
        setSpinnerLoading(false)
        setShowAlert(true)
    }




}


const sharedHandler=(receptid)=>{
    let mynewObjArray =[...recepyData]
    // find the index by ingredid
    let index = mynewObjArray.findIndex(data =>data.receptid===receptid);
    // first we find the value of the active
    let active = mynewObjArray[index].shared
    if(active===1){
        active=0
        mynewObjArray[index].buttonStatus=1
    }else{
        active=1
        mynewObjArray[index].buttonStatus=1
  
    }
    mynewObjArray[index].shared=active
    // add buttonstatus to the object
   // mynewObjArray[index].buttonStatus=active
    setRecepyData(mynewObjArray)
}

const saveHandler=(receptid)=>{
    UpdateActive(receptid)
}


// if the user changges the value on the range control we save the value in the database
async function SetBackGroundShadeHandler(val){
  setBackGroundShade(val)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{

    const response=await usePutData(`updateBackGroundShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }
  }catch(error){
      setAlertCode(41004)
      setAlertMessage(selectedError[41004])
      setSpinnerLoading(false)
      setShowAlert(true)
  }



}

async function SetmenuColorHandler(val){
  setMenuShade(val)
  setMenuShade2(val-0.20)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    let response = await usePutData(`updateMenuShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }

}catch(error){
  setAlertCode(41005)
  setAlertMessage(selectedError[41005])
  setSpinnerLoading(false)
  setShowAlert(true)
}

}
  return (
    <>
{windowWidth<360?displayGadgetToSmall():''}
{getBackGroundShade<1?<img src={imageUrl} alt="Bakesoft" className="backgroundImageListProdukt" /> :
<div className="backgroundImageListProdukt1"/>
}
    <div className="xdproduktArea1">  
  
<div className="xcheaderArea">
{getCountry!=='ja'?
        <div className={getBackGroundShade<1?"xccompName":"xccompName1"}>{receptlistaTitle}</div>
        :<div className={getBackGroundShade<1?"xccompNameJAP":"xccompNameJAP1"}>{receptlistaTitle}</div>
  }
</div>
     
       
        <div className="xcrangeArea">
        <input type="range" min="0.0" max="1" value={getMenuShade||0.2} step="0.10" id={2}  
        title={titleAdjustMenu} className="xcrange2" onChange={(e)=>SetmenuColorHandler(e.target.value)}/>


        <input type="range" min="0.0" max="1" value={getBackGroundShade||0.2} step="0.10" id={2}  
        title={titleAdjustMenu1} className="xcrange1" onChange={(e)=>SetBackGroundShadeHandler(e.target.value)}/>


        </div>


        <div className="xcareaReceptListB">
            <div className="xcreceptselectTop">
            <fieldset className="xcreceptselectFieldset">
                <legend className={getCountry!=='ja'?"xcreceptselectLegend":"xcreceptselectLegendJAP"}>{kategoriLegend}</legend>
                <select className={getCountry!=='ja'?"xcreceptselectrecept":"xcreceptselectreceptJAP"} onChange={(e)=>kategoriHandler(e.target.value)}>
                                 <option value='0' hidden>&nbsp;{selectFirstOption}&nbsp;!&nbsp;</option>
               {getKategori.map((data,index)=><option className="option" key={index} 
                                              value={data.kategoriId}>&nbsp;{data.Benaemn}</option>)}
               </select>
 </fieldset>
        </div>

        <div className="xcdatalistTop">
            <button className={getCountry!=='ja'?"xclistreceptid":"xclistreceptidJAP"}>{headerId||'Id'}</button>
            <button className={getCountry!=='ja'?"xclistreceptbenaemn":"xclistreceptbenaemnJAP"}>{headerNamn||'Namn'}</button>
            <button className={getCountry!=='ja'?"xclistreceptactive":"xclistreceptactiveJAP"}>{headerAktiv||'Aktiv'}</button>
            <button className={getCountry!=='ja'?"xclistreceptSave":"xclistreceptSaveJAP"}>{headerSpara||'Spara'}</button>
        </div>

        <div className={getCountry!=='ja'?"xcdatalistData":"xcdatalistDataJAP"}>
        <div className="xarowData">
            {recepyData.length>0?recepyData.map((data,index)=><div  key={index}>
        <button className={index%2===0?"xcitemId":"xcitemId1"}>{data.receptid}</button>
        <button className={index%2===0?"xcbenaemn":"xcbenaemn1"}>{data.benaemn}</button>
        <button className={index%2===0?"xcactive":"xcactive1"}
                onClick={()=>activeHandler(data.receptid)}
                
                >
                  {data.active===1?<ImCheckboxChecked className="xcdataitemChecked"/>:<ImCheckboxUnchecked className="xcdataitemUnchecked"/>}</button>
             
     
          {getCountry!=='ja'?
          <button className={data.buttonStatus===1?"xcsaveButton1":"xcsaveButton2"}
          onClick={()=>saveHandler(data.receptid)} title={data.buttonStatus===1?ListSaveButton:''}>{data.buttonSaved===1?SavedOnButton:ListSaveButton}</button>
          :
          <button className={data.buttonStatus===1?"xcsaveButtonJAP1":"xcsaveButtonJAP2"}
          onClick={()=>saveHandler(data.receptid)} title={data.buttonStatus===1?ListSaveButton:''}>{data.buttonSaved===1?SavedOnButton:ListSaveButton}</button>}
     

        </div>):null}
</div>
        </div>
        </div>
      

        <div className="xclistSpinnerContainer">
        <div className="listSpinner">
          <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="1" width="60" visible={getSpinnerLoading}/>
        </div>
        </div>

   

  {getShowAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}/>:''}


    </div>
    </>
  )
}

export default ListRecept