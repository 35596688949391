import React,{useEffect,useState} from 'react'
import axios from 'axios'
import {useGetData,usePostData,usePutData,useDeleteData} from '../hooks/useGetData'
import { RotatingLines } from  'react-loader-spinner'
import Alert from '../functions/appAlert'
import useReportErr from '../hooks/useReportErr'
import { IoCreateOutline } from 'react-icons/io5';
import useAutoHideVisibility from '../hooks/useAutohide'
import './ProductCategoriesNew3.css'
import {useNavigate} from 'react-router-dom';
import useFetchImageUrl from '../hooks/useFetchImageUrl';
import usePageSettings from '../hooks/usePageSettings';
import YesNoAlert from '../functions/YesNoAlert'
import languages from '../AppLanguage/AppLanguage';
import AppError from '../AppLanguage/AppError';
import displayGadgetToSmall from '../functions/displayGadgetToSmall'



export default function ProductCategoriesNew2() {


  const [getproductCategories,setproductCategories]=useState([{}])
  const[getproductByCategory, setproductByCategory]=useState([])

  const[getChosenCategory,setChosenCategory]=useState(0)
  const[getcategoryName,setcategoryName]=useState('')
  const[getSpinnerLoading,setSpinnerLoading]=useState(false)
  const[productReadyToMove,setProductReadyToMove]=useState(false)
  const [getShowAlert,setShowAlert] = useState(false);
  const [getAlertMessage,setAlertMessage] = useState({});
  const[getAlertCode,setAlertCode]=useState(0)
  const[catClass,setcatClass]=useState({})
  const[getViewCreateCategory,setViewCreateCateory]=useState(false)
  const[moveToCategory,setMoveToCategory]=useState(0)
  const[getShowYesNoAlert,setShowYesNoAlert]=useState(false)
  const[getYesNoAlertMessage,setYesNoAlertMessage]=useState('')
  const[getBackGroundShade,setBackGroundShade]=useState(0.65)
  const[getRenderKey,setRenderKey]=useState(0)
  const[getMenuShade,setMenuShade]=useState(0.85)
  const[getMenuShade2,setMenuShade2]=useState(0.65)
  const [getColorVal,setColorVal]=useState('')
  const [getImageVal,setImageVal]=useState('')
  const [getImageUrl,setImageUrl]=useState('')
  const pageid=11;
  const [logtime]=useState(5000)
  const[getFuriganaName,setFuriganaName]=useState('')
  const[getKanjiName,setKanjiName]=useState('')



  //we use this hook to show the createcategory component
  const [isVisible, setIsVisible] = useAutoHideVisibility(false, 30000);

  
  const [windowWidth,setWindowWith]=useState(0) // this is the window width
  const [windowHeight,setWindowHeight]=useState(0)  // this is the window height
  const [getMobileIsUsed,setMobileIsUsed]=useState(false) // this is the window height
  const[getCountry,setCountry]=useState('se') // this is the country


  useEffect(()=>{
    let country = localStorage.getItem('country')||'en' // this is the country code
    setCountry(country)
  },[])

  // if language is japanese we use the font Zen Old Mincho

  let newFontFamily = '';
  if (getCountry === 'ja') {
     newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
  } else {
    newFontFamily = 'Arial, Helvetica, sans-serif'
    
  //newFontFamily = 'Times New Roman, Times, serif'
  //newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
  }
  document.documentElement.style.setProperty('--font-family', newFontFamily);

  
  const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal } = usePageSettings(pageid);
  const { imageUrl, alertMessage, alertCode, showAlert } = useFetchImageUrl(getImageVal); // runs at mount, gives ut the image url and fetch image from server
  const navigate = useNavigate();


  useEffect(() => {
    function setSize() {
      setWindowWith(window.innerWidth);
      setWindowHeight(window.innerHeight);
    
      if(window.innerWidth < 600){
  
        setMobileIsUsed(true)
      } 
  
    }

  
    // Call setSize initially to set the correct state
    setSize();
  
    // Add the event listener
    window.addEventListener('resize', setSize);
  
    // Return a cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', setSize);
    };
  }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]); // Dependencies

  function rgbToRgba(rgb, alpha) {
    return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
  }
  
//--bakcground shade settings
let rgbaVal
let rgbaVal2
let val

if(getColorVal && getColorVal.length > 0){
  let rgb = getColorVal;
  let alpha = getMenuShade; // replace with your alpha value
  let alpha2 = getMenuShade2; // replace with your alpha value
  rgbaVal = rgbToRgba(rgb, alpha);
  rgbaVal2 = rgbToRgba(rgb, alpha2);
  val=`rgba(255, 255, 255, ${getBackGroundShade})`

  }else{
    rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
    rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
    val=`rgba(255, 255, 255, ${getBackGroundShade})`
  }
//---

useEffect(() => {
    let root = document.documentElement;
    root.style.setProperty('--rgbaVal', rgbaVal);
    root.style.setProperty('--rgbaVal2', rgbaVal2);
    root.style.setProperty('--val',val);
    root.style.setProperty('--windowHeight',windowHeight+'px');

    
  }, [getBackGroundShade,getMenuShade,getMenuShade2]);








   // language settings
   const selectedCountry = languages[getCountry];
  
    let title=''
   if(getMobileIsUsed===false){
     title=selectedCountry.productcategories?.title
   }else{
     title=selectedCountry.productcategories?.titleMobile
   }
 let textmessage=''
  if(getCountry==='en'){
   textmessage='Welcome to ingredient categories,select a category, move to another category, remove category by double clicking category, if a category has ingredients, they are moved to category other and the category is removed. Create new category by pressing the create icon.'
  }else if(getCountry==='se'){
   textmessage='Välkommen till ingredienskategorier, välj en kategori, välj en ingrediens, flytta till annan kategori, ta bort kategori genom att dubbelklicka på kategori, om en kategori har ingredienser flyttas dom till kategori övrigt och kategori tas bort. Skapa ny kategori genom att trycka på ikonen skapa'
   }else if(getCountry==='ja'){
     textmessage='食材のカテゴリーへようこそ！カテゴリーを「選択」し、食材を「選択」し、別のカテゴリーへ「移動」することができます。カテゴリーを削除するときはダブルクリックしてください。もしも削除するカテゴリーに食材がある場合は、「その他」のカテゴリーに移動し、対象のカテゴリーは削除されます。新たなカテゴリーを作成するときは、「作成」ボタンを押してください。'
   }
  let displayText = textmessage

  const categoryHeaderKategori=selectedCountry.productcategories?.categoryHeaderKategori
  const  categoryHeaderAntal=selectedCountry.ingredcategories?.categoryHeaderAntal
  const categoryMoveIngredLegend=selectedCountry.ingredcategories?.categoryMoveIngredLegend
  const categorySelectFirstOption=selectedCountry.ingredcategories?.categorySelectFirstOption
  const categoryButtonMoveSubmit=selectedCountry.ingredcategories?.categoryButtonMoveSubmit
  const categoryCreateCategorySubmit=selectedCountry.ingredcategories?.categoryCreateCategorySubmit
 

// error messages
const selectedError=AppError[localStorage.getItem('country')||'en']

const{
  categoryDeleted,
  categoryDeleteQuestion,
  categoryNameWrite,
  ingredientsMovedTochosenCategory,
  productsMovedTochosenCategory,
  categoryCreated,
  btnYes,
  btnNo,
  categoriesVoid,
}=selectedCountry.confirmationMessages||{};
 


  useEffect(() => {
    GetSettings();
  }, [GetSettings]);

  // we set the image url here
  useEffect(() => {
    setImageUrl(imageUrl);
    if (alertCode > 0) {
      setAlertMessage(alertMessage);
      setAlertCode(alertCode);
      setShowAlert(showAlert);
    }
  }, [imageUrl]);

  // here we set the color values and image values
  useEffect(() => {
    setBackGroundShade(backGroundShade);
    setMenuShade(menuShade);
    setMenuShade2(menuShade2);
    setColorVal(colorVal);
    setImageVal(imageVal);
  }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);









useEffect(()=>{

  async function GetProductCategories(){
    setSpinnerLoading(true)
   try{
    let response= await useGetData(`getProductCategoriesC`)
    setproductCategories(response.data[0])
    setSpinnerLoading(false)
   }catch(error){
    setAlertCode(29001)
    setAlertMessage(selectedError[29001])
    setSpinnerLoading(false)
    setShowAlert(true)
 
   }

}
GetProductCategories()
},[])

const RunReportErrHandler = async (errCode) => {
  setSpinnerLoading(true);
  await useReportErr('ErrReport',{errCode});
  setSpinnerLoading(false);
};

useEffect(() => {
  if (getAlertCode > 0) {
    RunReportErrHandler(getAlertCode);
  }
}, [getAlertCode]);


const handleCloseAlert = () => {
  setShowAlert(false);
};

const handleCloseAlertYes = () => {
  setShowYesNoAlert(false);
  DeleteCategory()
};
const handleCloseAlertNo = () => {
  setShowYesNoAlert(false);
};
//start here again
const categoryHandler=()=>{

  if(getcategoryName.length>0){
    async function InsertnewCategory(){
      setSpinnerLoading(true)
 try{
  let response= await usePostData(`insertNewCategory`,{getcategoryName})
  if(response.status===200){
   setproductCategories(response.data[0])
   setSpinnerLoading(false)
   setAlertCode(0)
    setAlertMessage(categoryCreated)
    setShowAlert(true)
  }else if (response.status===201){
    setSpinnerLoading(false)
    setAlertCode(0)
    setAlertMessage(categoriesVoid)
    setShowAlert(true)
  }
 } catch(error){
  setAlertCode(29002)
  setAlertMessage(selectedError[29002])
  setSpinnerLoading(false)
  setShowAlert(true)

 }
    
  }
  InsertnewCategory()
  setcategoryName('')
}else{
  setAlertCode(0)
  setAlertMessage(categoryNameWrite)
  setShowAlert(true)
}
}
async function DeleteCategory(){
  setSpinnerLoading(true)
  try{
    let response= await useDeleteData(`checkproductItemsInCategoryAndMove/${getChosenCategory}`)
    if(response.status===200){
      setSpinnerLoading(false)
      setAlertCode(0)
      setAlertMessage(categoryDeleted)
      setShowAlert(true)
      // we need to update the state and deltet the category from the state
      let newObjArr=[...getproductCategories]
      let newObjArrIndex=newObjArr.findIndex(data=>data.kategoriid==getChosenCategory)
      // we need to delete the category from the object
      newObjArr.splice(newObjArrIndex,1)
      //update state
      setproductCategories(newObjArr)

    }
  }catch(error){
    setAlertCode(29009)
    setAlertMessage(selectedError[29009])
    setSpinnerLoading(false)
    setShowAlert(true)
  
  }
}


const datainfoHandler=(category)=>{
  setProductReadyToMove(false) // here we reset the ingredient ready to move
  setMoveToCategory(0) // here we reset the move to category
  async function GetproductByCategory(category){
    setSpinnerLoading(true)
    try{
      let response=await useGetData(`getproductsByCategory/${category}`)
      setproductByCategory(response.data[0])
      setSpinnerLoading(false)
      setViewCreateCateory(false)
    }catch(error){
  setAlertCode(29003)
  setAlertMessage(selectedError[29003])
  setSpinnerLoading(false)
  setShowAlert(true)

    }
   }
   GetproductByCategory(category)

  setChosenCategory(category)

  }

     const setViewCreateCateoryHandler=()=>{
      setproductByCategory([])// here we reset the product by category
        if(getViewCreateCategory===false){
          setIsVisible(true)
          setViewCreateCateory(true)
        }else{
          setViewCreateCateory(false)
        }
      }


// if the user changges the value on the range control we save the value in the database
async function SetBackGroundShadeHandler(val){
  setBackGroundShade(val)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateBackGroundShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }
  }catch(error){
      setAlertCode(29007)
      setAlertMessage(selectedError[29007])
      setSpinnerLoading(false)
      setShowAlert(true)
  
    }

}

async function SetmenuColorHandler(val){
  setMenuShade(val)
  setMenuShade2(val-0.20)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateMenuShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }

}catch(error){
  setAlertCode(29008)
  setAlertMessage(selectedError[29008])
  setSpinnerLoading(false)
  setShowAlert(true)

}
}

const deleteCategoryHandlerContainer=(kategoriid)=>{
  //here we want to find the name of the category
  let newObjArr=[...getproductCategories]
  let newObjArrIndex=newObjArr.findIndex(data=>data.kategoriid==kategoriid)
  let kategoriname=newObjArr[newObjArrIndex].benaemn
  setYesNoAlertMessage(`${categoryDeleteQuestion} ${kategoriname}?`)
  setShowYesNoAlert(true)
}

const setChosenProductHandler=(produktid)=>{
  // first we will remove this item from the list of ingredients getingredByCategory
  let newObjArr=[...getproductByCategory]
  let newObjArrIndex=newObjArr.findIndex(data=>data.produktid===produktid)
  //we will not drop we will add a new property to the object and we will toggle this property
  if(newObjArr[newObjArrIndex].readyTomove==1){
    newObjArr[newObjArrIndex].readyTomove=undefined
  }else{
    newObjArr[newObjArrIndex].readyTomove=1
  }
  //update state
  setproductByCategory(newObjArr)
  }
  
  // here i watn to check if we have any ingredients that are ready to move
  useEffect(()=>{
    let newObjArr=[...getproductByCategory]
    let newObjArrIndex=newObjArr.findIndex(data=>data.readyTomove==1)
    if(newObjArrIndex>-1){
      setProductReadyToMove(true)
    }
  }
  ,[getproductByCategory])

  const moveToCategoryHandler=(val)=>{
    setMoveToCategory(val)
  }

  
function moveProductsToNewCategory(moveToCategory){
  // first we must identify the ingredients ready to move
  let newObjArr=[...getproductByCategory]
  // so we filter out the ingredients ready to move and we only need the ingredid

  let newObjArrReadyToMove=newObjArr.filter(data=>data.readyTomove==1)
  //take out all the ingredid from this object
  let produktidArr=newObjArrReadyToMove.map(data=>data.produktid)
 

  
  // first we update the database
 let decodedProductData=btoa(JSON.stringify(produktidArr))
  
  async function UpdateIngredCategory(){
    setSpinnerLoading(true)
    try{
      let response=await usePutData(`moveProductToCategory`,{decodedProductData,moveToCategory})
      if(response.status===200){
        setSpinnerLoading(false)
        setAlertCode(0)
        setAlertMessage(productsMovedTochosenCategory)
        setShowAlert(true)
      }
    }catch(error){
      setAlertCode(29010)
      setAlertMessage(selectedError[29010])
      setSpinnerLoading(false)
      setShowAlert(true)
    
    }
  }
  UpdateIngredCategory()

  // then we update the state
  // we filter out the ingredients not ready to move
  let newObjArrNotReadyToMove=newObjArr.filter(data=>data.readyTomove==undefined)
  // we update the state
  setproductByCategory(newObjArrNotReadyToMove)
  // we update the category object
  let newObjCategory=[...getproductCategories]
  // find the index of the moveToCategory
  let moveToCategoryIndex=newObjCategory.findIndex(data=>data.kategoriid==moveToCategory)
  //here we need to update the object property antal on the moveToCategory
  // we update antal
  let antal=newObjCategory[moveToCategoryIndex].antal
  // we add as many as we have in newObjArrReadyToMove
  antal=antal+newObjArrReadyToMove.length
  // we update the antal
  newObjCategory[moveToCategoryIndex].antal=antal
  //here we need to update the object property antal on the from category
  //find the index of the from category
  let fromCategoryIndex=newObjCategory.findIndex(data=>data.kategoriid==getChosenCategory)
  // we update antal with - as many as we have in newObjArrReadyToMove
  let antal1=newObjCategory[fromCategoryIndex].antal
  // we minus as many as we have in newObjArrReadyToMove
  antal1=antal1-newObjArrReadyToMove.length
  // we update the antal
  newObjCategory[fromCategoryIndex].antal=antal1
  //update state
  setproductCategories(newObjCategory)
  //clean up
  setProductReadyToMove(false)
  setMoveToCategory(0)

}
const setKanjiNameHandler=(val)=>{
  setKanjiName(val)
}

const setFuriganaNameHandler=(val)=>{
  setFuriganaName(val)
}

const createCategoryJAPHandler=()=>{
CreateJAPCategory()
}

async function CreateJAPCategory(){
  setSpinnerLoading(true)
  try{
    let response=await usePostData(`insertNewCategoryJAP`,{getKanjiName,getFuriganaName})
    if(response.status===200){
      setproductCategories(response.data[0])
      setSpinnerLoading(false)
      setAlertCode(0)
      setAlertMessage(categoryCreated)
      setShowAlert(true)
      setViewCreateCateory(false)
      setKanjiName('')
      setFuriganaName('')
    }else if(response.status===201){
      setSpinnerLoading(false)
      setAlertCode(0)
      setAlertMessage(categoriesVoid)
      setShowAlert(true)
    }
    
  }
  catch(error){
    setAlertCode(29011)
    setAlertMessage(selectedError[29011])
    setSpinnerLoading(false)
    setShowAlert(true)
   
  }
}


      return(
        <>
        {windowWidth<360?displayGadgetToSmall():''}
        <div className={getBackGroundShade==1?"xhtotalArea1":"xhtotalArea"}>
        {getBackGroundShade==1?'':<img src={imageUrl} alt="Bakesoft" className="xhbackgroundImageRecepyNew" />}

<div className="xhheaderArea">
  {getCountry!=='ja'?
<div className={getBackGroundShade<1?"xhcompName":"xhcompName1"}>{title}</div>
:<div className={getBackGroundShade<1?"xhcompNameJAP":"xhcompNameJAP1"}>{title}</div>}
 </div> 

 <div className="xhrangeArea">
<input type="range" min="0.0" max="1" value={getMenuShade||0.2} step="0.10" id={1}  
    title="Justera menyfärg" className="xhrange2" onChange={(e)=>SetmenuColorHandler(e.target.value)}/>

<input type="range" min="0.0" max="1" value={getBackGroundShade||0.2} step="0.10" id={2}  
  title="Justera bakgrund" className="xhrange1" onChange={(e)=>SetBackGroundShadeHandler(e.target.value)}/>

</div>



    <div className="xhareaA">
    <div className="xhCategoryDatalist">
    <div className="xhCategoryTop">
     <button className="xhBenaemnHeader">{categoryHeaderKategori}</button>
     <button className="xhAntalHeader">{categoryHeaderAntal}</button>
</div>
<div className="xhrowsdata">
{getproductCategories.map((data,index)=>
  <div key={index}   className="xhrows">
<button 
className={index%2===0?"xhdataBenaemn":"xhdataBenaemn1"}  key={index} 
onClick={()=>datainfoHandler(data.kategoriid)}
onDoubleClick={()=>deleteCategoryHandlerContainer(data.kategoriid)}
>{data.benaemn}</button>

<button className={index%2===0?"xhdataAntal":"xhdataAntal1"}>{data.antal}</button>
</div>

)}                                                                      
</div>
</div>





            </div>
            <div className="xhareaB">
            <div className="xhcreatecatTop">
            <IoCreateOutline  title="Skapa ny kategori" onClick={()=>setViewCreateCateoryHandler()} 
            className={getBackGroundShade==1?"xhcreateCat1":"xhcreateCat"}/>

            {getViewCreateCategory===true&&isVisible&&getCountry!=='ja'?<AddCategory categoryHandler={categoryHandler} catClass={catClass}
              setcategoryName={setcategoryName} getcategoryName={getcategoryName}
              categoryCreateCategorySubmit={categoryCreateCategorySubmit}
              setKanjiNameHandler={setKanjiNameHandler}
              setFuriganaNameHandler={setFuriganaNameHandler}
              getKanjiName={getKanjiName}
              getFuriganaName={getFuriganaName}
              createCategoryJAPHandler={createCategoryJAPHandler}
              />:''}

              {getViewCreateCategory===true&&isVisible&&getCountry==='ja'?<AddCategoryJAP categoryHandler={categoryHandler} catClass={catClass}
              setcategoryName={setcategoryName} getcategoryName={getcategoryName}
              categoryCreateCategorySubmit={categoryCreateCategorySubmit}
              setKanjiNameHandler={setKanjiNameHandler}
              setFuriganaNameHandler={setFuriganaNameHandler}
              getKanjiName={getKanjiName}
              getFuriganaName={getFuriganaName}
              createCategoryJAPHandler={createCategoryJAPHandler}
              />:''} 
              </div>
 
    
 <div>

            <div className="xhrightdataArea">

{getproductByCategory.length >0
    ? getproductByCategory
    .filter(data=>data.readyTomove===undefined)
        .map((data, key) => (
          <div  
            className={key % 2 === 0 ?"xhcategoryrightButton1":"xhcategoryrightButton"} 
            key={key}
          >
            <div 
              className="xhcategoryrightButtontext"
              onClick={() => setChosenProductHandler(data.produktid)}
            >
              {data.benaemn}
            </div>
          </div>
        ))
    : ''
}
</div>

{productReadyToMove===true?
<div className="xhreceptmoveitemTop">
<fieldset className="xhreceptmoveitemFieldset">
  <legend className="xhreceptmoveitemLegend">{categoryMoveIngredLegend}</legend>

{
  getproductByCategory.length > 0 && productReadyToMove === true
    ? getproductByCategory
        .filter(data => data.readyTomove == 1)
        .map((data, key) => (
          <div  
            className={key % 2 === 0 ?"xhcategoryrightButton1":"xhcategoryrightButton"} 
            key={key}
          >
            <div 
              className="xhcategoryrightButtontext"
              onClick={() => setChosenProductHandler(data.produktid)}
              >
              {data.benaemn}
            </div>
          </div>
        ))
    : ''
}
</fieldset>
<div className="xhingredmoveTop">
<select className="xhmoveitemSelect" onChange={(e)=>moveToCategoryHandler(e.target.value)}>
<option value="0">&nbsp;&nbsp;{categorySelectFirstOption}</option>
{getproductCategories.map((data,index)=>
<option key={index} value={data.kategoriid}>&nbsp;{data.benaemn}</option>
)}
</select>

<button className={moveToCategory>0?"xhmoveitemSaveButton":"xhmoveitemSaveButton1"} disabled={moveToCategory===0}
onClick={()=>moveProductsToNewCategory(moveToCategory)}
>{categoryButtonMoveSubmit}</button>
</div>
</div>
:''}

        </div>




            </div>
            <div className="xhareaC">
            {getMobileIsUsed===false?
                <div className="productcattextAreaMessage">
            <TextArea displayText={displayText} cols={55} rows={30} catClass={catClass}/></div>
            :''}
            </div>


            <div className="xhSpinnerContainer">
        <div className="xhSpinner">
          <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="0.75" width="80" visible={getSpinnerLoading}/>
        </div>
        </div>
        {getShowAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}/>:''}
        {getShowYesNoAlert===true?<YesNoAlert message={getYesNoAlertMessage}  onCloseYes={handleCloseAlertYes} onCloseNo={handleCloseAlertNo}
        btnYes={btnYes} btnNo={btnNo}
        />:''}
 
    
            
            
            
            
            </div>
        
        </>
        
        )






    }

    function TextArea(props){
        let displayText=props.displayText
        return(
          <div>
      
          <textarea
          value={displayText}
           readOnly
           className="xhtextAreaMessage"
          ></textarea>
          </div>
        )
      }
      
      function AddCategory(props){
      
        let setcategoryName=props.setcategoryName
        let getcategoryName=props.getcategoryName
        let categoryHandler=props.categoryHandler
        let categoryCreateCategorySubmit=props.categoryCreateCategorySubmit

      
        return(
          <div className="xhcatcreateCategoryTop">
          <input onChange={(e)=>setcategoryName(e.target.value)} value={getcategoryName}type="text" className="xhcatcreateCategoryInput" />
          <input type="button" onClick={()=>categoryHandler()}className={!getcategoryName?"xhproductcatcreateCategoryButton1":"xhproductcatcreateCategoryButton"} 
          value={categoryCreateCategorySubmit}
          disabled={getcategoryName.length>0?false:true}
          /></div>
        )
      }

      
  function AddCategoryJAP(props){
    let {categoryCreateCategorySubmit,setKanjiNameHandler,setFuriganaNameHandler,createCategoryJAPHandler,
           getKanjiName,getFuriganaName
   }=props

  
    return(
      <div className="xhCategoryTopJAP">
      <input onChange={(e)=>setKanjiNameHandler(e.target.value)} value={getKanjiName}type="text" 
      className="xhCategoryInputKanji"
      placeholder='カテゴリ名'
      />

      <input onChange={(e)=>setFuriganaNameHandler(e.target.value)} value={getFuriganaName}type="text" 
      className="xhCategoryInputFurigana" placeholder='ふりがな' />
  
      <input type="button" onClick={()=>createCategoryJAPHandler()}className={getKanjiName.length>0&&getFuriganaName.length>0?"xhcreateCategoryButtonJAP":"xhcreateCategoryButtonJAP1"} 
      value={categoryCreateCategorySubmit}
      disabled={getKanjiName.length>0&&getFuriganaName.length>0?false:true}
      /></div>
    )
  }





    export {ProductCategoriesNew2,TextArea,AddCategory,AddCategoryJAP}