


import React,{useState,useEffect} from 'react';
import {useGetData,usePostData} from '../hooks/useGetData';
import useReportErr from '../hooks/useReportErr';
import { RotatingLines } from 'react-loader-spinner';
import { SwatchesPicker } from 'react-color';
import tinycolor from 'tinycolor2';
import Alert from '../functions/appAlert';
import languages from '../AppLanguage/AppLanguage';
import AppError from '../AppLanguage/AppError';
import displayGadgetToSmall from '../functions/displayGadgetToSmall'
import './MySettings.css'


export default function MySettings(){


    const[getBackGroundShade,setBackGroundShade]=useState(0)
    const[getRenderKey,setRenderKey]=useState(0)
    const[getMenuShade,setMenuShade]=useState(0)
    const[getMenuShade2,setMenuShade2]=useState(0)
    const[pageData,setPageData]=useState([{}])
    const[myset,setMySet]=useState([{}]);
    const [showAlert,setShowAlert] = useState(false);
    const [getAlertMessage,setAlertMessage] = useState({});
    const[getAlertCode,setAlertCode]=useState(0)
    const[getSpinnerLoading,setSpinnerLoading]=useState(false)
    const[page,setPage]=useState(1)
    const[getColorVal,setColorVal]=useState('')
    const[getAllChanged,setAllChanged]=useState(false)
    const[getMenuStat,setMenuStat]=useState(false)
    const[getBackgroundStat,setBackgroundStat]=useState(false)
    const[getColorStat,setColorStat]=useState(false)
    const[getChosenPage,setChosenPage]=useState(1)
    const[getImageData,setImageData]=useState([{}])
    const[getChosenImage,setChosenImage]=useState(0)
    const[getChosenImageId,setChosenImageId]=useState(40)
    const[getImageVal,setImageVal]=useState('')
    const[getImageStat,setImageStat]=useState(false)
    const[getMenuColorStat,setMenuColorStat]= useState(false)
    const[getMenuColor,setMenuColor]=useState('')

    const [windowWidth,setWindowWith]=useState(0) // this is the window width
const [windowHeight,setWindowHeight]=useState(0)  // this is the window height
const [getMobileIsUsed,setMobileIsUsed]=useState(false) // this is the window height
const[getCountry,setCountry]=useState('se') // this is the country


useEffect(()=>{
  let country = localStorage.getItem('country')||'en' // this is the country code
  setCountry(country)
},[])

let newFontFamily = '';
if (getCountry === 'ja') {
   newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
} else {
  newFontFamily = 'Arial, Helvetica, sans-serif'
  
//newFontFamily = 'Times New Roman, Times, serif'
//newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
}
document.documentElement.style.setProperty('--font-family', newFontFamily);
    
    

useEffect(() => {
  function setSize() {
    setWindowWith(window.innerWidth);
    setWindowHeight(window.innerHeight);
  
    if(window.innerWidth < 600){

      setMobileIsUsed(true)
    } 

  }

  // Call setSize initially to set the correct state
  setSize();

  // Add the event listener
  window.addEventListener('resize', setSize);

  // Return a cleanup function to remove the event listener when the component unmounts
  return () => {
    window.removeEventListener('resize', setSize);
  };
}, [getColorVal,getImageVal]); // Dependencies

function rgbToRgba(rgb, alpha) {
  return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
}

//--bakcground shade settings
let rgbaVal
let rgbaVal2
let val

if(getColorVal && getColorVal.length > 0){
let rgb = getColorVal;
let alpha = getMenuShade; // replace with your alpha value
let alpha2 = getMenuShade2; // replace with your alpha value
rgbaVal = rgbToRgba(rgb, alpha);
rgbaVal2 = rgbToRgba(rgb, alpha2);
val=`rgba(255, 255, 255, ${getBackGroundShade})`

}else{
  rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
  rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
  val=`rgba(255, 255, 255, ${getBackGroundShade})`
}
//---

useEffect(() => {
  let root = document.documentElement;
  root.style.setProperty('--rgbaVal', rgbaVal);
  root.style.setProperty('--rgbaVal2', rgbaVal2);
  root.style.setProperty('--val',val);
  root.style.setProperty('--windowHeight',windowHeight+'px');

  

  
}, [getBackGroundShade,getMenuShade,getMenuShade2,getColorVal]);


    // language settings
    const selectedCountry = languages[getCountry];
     let title=''
    if(getMobileIsUsed===false){
      title=selectedCountry.mysettings?.title
    }else{
      title=selectedCountry.mysettings?.mobiletitle
    }

    const {
      mobiletitle,
      leftHeaderLegend,
      midBackgroundFilter,
      midMenuFilter,
      midMenuColor,
      saveButtonText,
      rightHeaderlegend
    } = selectedCountry.mysettings||{};

 // error messages
 const selectedError=AppError[localStorage.getItem('country')||'en']

 const{
  pageSaved
 }=selectedCountry.confirmationMessages||{};
 



          useEffect(()=>{
            
            async function GetPageData(){
                try{
                    let response= await useGetData(`getPageData`)
                    setPageData(response.data[0])
                    setImageData(response.data[1])
                }catch(err){
                    setAlertCode(33001)
                    setAlertMessage(selectedError[33001])
                    setSpinnerLoading(false)
                    setShowAlert(true)
              
                }
            }
         GetPageData()
        },[])

        // useEffect(()=>{
        //     setRenderKey(getRenderKey+1)
        // },[getChosenPage])

useEffect(()=>{
        async function GetColorSettings(){
             setSpinnerLoading(true)
            try {
                const response = await useGetData(`getPageSettings/${page}`);
                setSpinnerLoading(false);
                
                if (
                    response.data.length >= 4 &&
                    response.data[0].length > 0 &&
                    response.data[1].length > 0 &&
                    response.data[2].length > 0 &&
                    response.data[3].length > 0
                  ) {
                    setBackGroundShade(response.data[0][0].backGroundShade);
                    setMenuShade(response.data[1][0].menuShade);
                    setMenuShade2(response.data[2][0].menuShade2);
                    setColorVal(response.data[3][0].colorVal);
                    setImageVal(response.data[4][0].imageVal)
                    
              
                  }
              } catch (error) {
                setAlertCode(33004);
                setAlertMessage(selectedError[33004])
                setSpinnerLoading(false);
                setShowAlert(true);
                return;
              }
          }
          GetColorSettings()
        },[page])

    



        const RunReportErrHandler = async (errCode) => {
            setSpinnerLoading(true);
              await useReportErr('ErrReport',{errCode});
            setSpinnerLoading(false);
          };
          
          useEffect(() => {
            if (getAlertCode > 0) {
              RunReportErrHandler(getAlertCode);
            }
          }, [getAlertCode]);


        const handleCloseAlert = () => {
            setShowAlert(false);
          };


const setBackGroundShadeHandler=(value)=>{
    setBackGroundShade(value)
    setBackgroundStat(true)
}

const setMenuShadeHandler=(value)=>{
    setMenuShade(value)
    setMenuShade2(value-0.30)
    setMenuStat(true)
}
const setPageValHandler=(value)=>{
    setPage(value)
    setChosenPage(value)
    setAllChanged(false)
    setBackgroundStat(false)
    setMenuStat(false)
    setColorStat(false)
    setImageStat(false)
}

const setChosenImageIdHandler=(value)=>{
    setChosenImageId(value)
    setImageStat(true)
}



const handleColorChange = (color) => {
  const rgbaColor = tinycolor(color.rgb).toRgbString();
  setColorVal(rgbaColor);
  setColorStat(true);
}




  const SavePageSettingHandler=async()=>{
        setSpinnerLoading(true)
        try{
            let response=await usePostData(`savePageSetting`,{getColorVal,getBackGroundShade,getMenuShade,page,getChosenImageId})
            //let response=await useGetData(`savePageSetting/${getColorVal}/${getBackGroundShade}/${getMenuShade}/${page}/${getChosenImageId}`)
         
            if(response.status===200){
            setAlertCode(0)
            setAlertMessage(pageSaved)
            setSpinnerLoading(false)
            setShowAlert(true)
            }
        }catch(err){
            setAlertCode(33003)
            setAlertMessage(selectedError[33004])
            setSpinnerLoading(false)
            setShowAlert(true)
        }
    
  }


let pageBenaemn
if(pageData.length>0){
pageBenaemn=pageData.find((data)=>data.pageid===page)
}else{
    let pageBenaemn=''
}

// here i need to checj if the page is changed or not
useEffect(()=>{
    if(getMenuStat===true||getBackgroundStat===true||getColorStat===true||getImageStat===true){
        setAllChanged(true)
    }
    else{
        setAllChanged(false)
    }
}
,[getMenuStat,getBackgroundStat,getColorStat,getImageStat])



    return(
      <>
         {windowWidth<360?displayGadgetToSmall():''}
<div className="xytotWrapper">
        <div className="xywindowArea">

<div className="xyheaderArea">
  {getCountry!=='ja'?
<div className="xycompName">{title}</div>
:<div className="xycompNameJAP">{title}</div>}
</div>
            <div className="xyareaA">
              <div className="xypageHeaderMenyWrapper">
            <div className="xypageHeaderMenu">{leftHeaderLegend}:</div>
            </div>
            <div className="xyareaTopWrapper">
                <div className="xyareaTop">
                    {pageData.map((data,index)=>{
                        return(
                          <div key={index} className="xyareaTopData">
                          <button className={index%2===0?"xypageId":"xypageId1"}>{data.pageid}</button>
                          <button className={
                              getChosenPage===data.pageid?"xypageBenaemn2":index%2===0?"xypageBenaemn":"xypageBenaemn1"}
                              onClick={()=>setPageValHandler(data.pageid)}>{data.benaemn}</button>
                      </div>
                        )
                    }
                    
                    )}
            </div>
            </div>
            </div>

            <div className="xyareaB">
                <div className="xyareabTop">
                <div className="xypageVal">
                
                    {page>0&&pageBenaemn?pageBenaemn.benaemn:''}
                </div>
                {page>0?<BackgroundShade myset={myset} setBackGroundShadeHandler={setBackGroundShadeHandler}
                getBackGroundShade={getBackGroundShade}     midBackgroundFilter={midBackgroundFilter}
                />:''}  

                {page>0?<MenuShade myset={myset} setMenuShadeHandler={setMenuShadeHandler} getMenuShade={getMenuShade}
                midMenuFilter={midMenuFilter}
                />:''}
            
                {page>0?<MenuColor myset={myset} getColorVal={getColorVal} 
                 handleColorChange={handleColorChange} midMenuColor={midMenuColor}
                 />:''}

                 {page>0?<SaveButton myset={myset} getImageStat={getImageStat}
                 SavePageSettingHandler={SavePageSettingHandler} getAllChanged={getAllChanged}
                 saveButtonText={saveButtonText}
                 />:''}
                 
  </div>
            </div>
            <div className="xyareaC">
                <ImageGrid myset={myset} getImageData={getImageData} 
                setChosenImage={setChosenImage} getChosenImage={getChosenImage}
                setChosenImageIdHandler={setChosenImageIdHandler} getImageVal={getImageVal}
                rightHeaderlegend={rightHeaderlegend}
                />
            </div>
            
            <div className="xysettingsSpinnerContainer">
        <div className="xysettingsSpinner">
          <RotatingLines strokeColor="grey" strokeWidth={windowWidth<420?"2":"5"} animationDuration="0.75" width="70" visible={getSpinnerLoading}/>
        </div>
        </div>
        {showAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}/>:''}

        
        </div>
        </div>
        </>
    )
}

function BackgroundShade(props){
    let {myset,setBackGroundShadeHandler,getBackGroundShade,
      midBackgroundFilter
    }=props


    return(
        <div className="xybackgroundShadeTop">
            <fieldset className="xybackgroundShadeFieldset">
                <legend className="xybackgroundShadeLegend">{midBackgroundFilter}</legend>
                <select  value={getBackGroundShade} className="xybackgroundShadeSelect" onChange={(e)=>setBackGroundShadeHandler(e.target.value)}>
                    <option value="default" className="xybackgroundShadeOption">{getBackGroundShade}</option>
                    <option value="0.10" className="xybackgroundShadeOption">0.10</option>
                    <option value="0.20" className="xybackgroundShadeOption">0.20</option>
                    <option value="0.30" className="xybackgroundShadeOption">0.30</option>
                    <option value="0.40" className="xybackgroundShadeOption">0.40</option>
                    <option value="0.50" className="xybackgroundShadeOption">0.50</option>
                    <option value="0.60" className="xybackgroundShadeOption">0.60</option>
                    <option value="0.70" className="xybackgroundShadeOption">0.70</option>
                    <option value="0.80" className="xybackgroundShadeOption">0.80</option>
                    <option value="0.90" className="xybackgroundShadeOption">0.90</option>
                    <option value="1" className="xybackgroundShadeOption">1</option>
                </select>
                </fieldset>
        </div>
    )

}

function MenuShade(props){
    let {myset,setMenuShadeHandler,getMenuShade,
      midMenuFilter
    }=props
    return(
        <div className="xybackgroundShadeMiddle">
            <fieldset className="xybackgroundShadeFieldset">
                <legend className="xybackgroundShadeLegend">{midMenuFilter}</legend>
                <select className="xybackgroundShadeSelect" onChange={(e)=>setMenuShadeHandler(e.target.value)}>
                    <option value={getMenuShade} className="xybackgroundShadeOption">{getMenuShade}</option>
                    <option value="0.10" className="xybackgroundShadeOption">0.10</option>
                    <option value="0.20" className="xybackgroundShadeOption">0.20</option>
                    <option value="0.30" className="xybackgroundShadeOption">0.30</option>
                    <option value="0.40" className="xybackgroundShadeOption">0.40</option>
                    <option value="0.50" className="xybackgroundShadeOption">0.50</option>
                    <option value="0.60" className="xybackgroundShadeOption">0.60</option>
                    <option value="0.70" className="xybackgroundShadeOption">0.70</option>
                    <option value="0.80" className="xybackgroundShadeOption">0.80</option>
                    <option value="0.90" className="xybackgroundShadeOption">0.90</option>
                    <option value="1" className="xybackgroundShadeOption">1</option>
                </select>
                </fieldset>
        </div>
    )

}


function MenuColor(props) {
    let{myset,getColorVal,handleColorChange,
      midMenuColor
    }=props
 
    const styles = {
      default: {
        picker: {
          width: '100%',
          height: '10px',
          border: 'none',
          background: 'none',
          padding: '0px',
          margin:'0',
          cursor: 'pointer',
        },
      }
    }
  
    return (
      <div>
        <div className="xypickerWrapper">
                <fieldset className="xybackgroundShadeFieldset1">
                <legend className="xybackgroundShadeLegend">{midMenuColor}</legend>
        <SwatchesPicker color={getColorVal} onChange={handleColorChange} styles={styles}
        className="xycolorSwatch"
        />
        </fieldset>
        </div>
      </div>
    );
  }

  function SaveButton(props){
      let{myset,SavePageSettingHandler,getAllChanged,getImageStat,saveButtonText
    }=props
      return(
        <div className="xysaveButtonWrapper">
          <button className={getAllChanged===true&&getImageStat===true>0?"xysaveButton1":"xysaveButton"} 
          onClick={()=>SavePageSettingHandler()} disabled={getAllChanged?false:true}>
              {saveButtonText}
          </button>
          </div>
      )
  }

  function ImageGrid(props){
    let {myset, getImageData,setChosenImage,getChosenImage,setChosenImageIdHandler,getImageVal,
      rightHeaderlegend
    } = props;
    const [imageUrls, setImageUrls] = useState([]);
  
  
    useEffect(() => {
      const fetchImageUrls = async () => {
        const urls = await Promise.all(getImageData.map(async (data) => {
          const token = localStorage.getItem('auth_token');
          const headers = token ? { authorization: `Bearer ${token}` } : {};
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/getimages/${data.imagelink}`, { headers });
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          return { url, id: data.imageid };
        }));
      
        setImageUrls(urls);
      };
  
      fetchImageUrls();
    }, [getImageData]);
  
    return (
        <div>
            
        <div className="xyimageGridTop">{rightHeaderlegend}:</div>
        <div className="xyimageGridContainer">
        {imageUrls.map((url, index) => (
          <div key={index} className="xyimageGridItem">
            <img src={url.url} alt={getImageData[index].imagelink}
             className={
              getImageVal == url.id
                ? "xyimageGridImage1"
                : getChosenImage == url.id
                ? "xyimageGridImage2" // replace with the style you want to apply when an image is clicked
                : "xyimageGridImage"
            } onClick={() => {
  setChosenImage(url.id);
  setChosenImageIdHandler(url.id);
}}/>
          </div>
        ))}
      </div>
        </div>
    
    );
  }


export {MySettings,BackgroundShade,MenuShade,MenuColor,
    SaveButton,ImageGrid};