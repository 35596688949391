import React,{useState,useEffect,useContext} from "react";
import { BrowserRouter, Routes, Route,useLocation} from "react-router-dom";
import ProtectedRoute from "./ProtetedRoute";
import './Layout.css'
import './index.css'
import { jwtDecode } from 'jwt-decode';
//components
import HeaderMenu from './components/HeaderMenu/HeaderMenu'
import WelcomeImage from './components/WelcomeImage'
import ProdStatNew from "./components/ProdStatNew/ProdStatNew";
import IngredCategoriesNew from "./components/IngredCategoriesNew/IngredCategoriesNew";
import RecepyCategoriesNew2 from "./components/RecepyCategoriesNew2/RecepyCategoriesNew2";
import ProductCategoriesNew2 from "./components/ProductCategoriesNew2/ProductCategoriesNew2";
import SearchRecepyNew2 from "./components/SearchRecepyNew2/SearchRecepyNew2";
import CreateProductNew from "./components/CreateProductNew/CreateProductNew";
import CreateProductionNew from "./components/CreateProductionNew/CreateProductionNew";
import ProdArbSumNew from "./components/ProdArbSumNew/ProdArbSumNew";
import ProdTotalNew from "./components/ProdTotalNew/ProdTotalNew";
import IngredTotNew from "./components/IngredTotNew/IngredTotNew";
import PriceListNew from "./components/PriceListNew/PriceListNew";
import IngredientsNew from "./components/IngredientsNew/IngredientsNew";
import CreateManRecepyNew from "./components/CreateManRecepyNew/CreateManRecepyNew";
import CreateRecepyNew2 from "./components/CreateRecepyNew2/CreateRecepyNew2";
import LoginPage from "./components/Login/LoginPage";
import Logout from "./components/Login/Logout";
import Info from "./components/Info/Info";
import ListIngred from "./components/ListIngred/ListIngred";
import ListRecept from "./components/ListRecept/ListRecept";
import ListProdukt from "./components/ListProdukt/ListProdukt";
import MySettings from "./components/MySettings/MySettings";
import HeaderLargeMobile from "./components/HeaderLargeMobile/HeaderLargeMobile";
import Support from "./components/Support/Support";
import UserDataPolicy from "./components/Policies/UserDataPolicy";
import RecepyIdeas from "./components/RecepyIdeas/RecepyIdeas";
import MyAccount from "./components/MyAccount/MyAccount";
import Logout1 from "./components/Logout/Logout1";
import Resetpassword from "./components/ResetPassword/Resetpassword";
import PrintInvoice from "./components/MyAccount/PrintInvoice";
import SearchRecepyScroll from "./components/SearchRecepyScroll/SearchRecepyScroll";
import Footer from "./components/Footer/Footer";





//helloes again we know
export default function Layout(props) {

const[device,setDevice]=useState([{}])
const [windowWidth,setWindowWith]=useState(0)
const [windowHeight,setWindowHeight]=useState(0)
const[getMobileIsUsed,setMobileIsUsed]=useState(false)
const[getuserCheck,setuserCheck]=useState(false)


  useEffect(() => {
    function setSize() {
      setWindowWith(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }
    // Set initial window size
    setSize();
    if(window.innerWidth < 490){
      setMobileIsUsed(true)
    } 

    // Update window size when the window is resized
    window.addEventListener('resize', setSize);
  
    // Remove event listener when the component unmounts
    return () => window.removeEventListener('resize', setSize);

  }, []);



  return (
    
    <div className="indexTop">
    <BrowserRouter>

    {/* // if we have no token we will not show the menues */}
     {getMobileIsUsed===false?<HeaderMenu/>:<HeaderLargeMobile/>}
  
    
    <Routes>
      <> 
      <Route path="/IngrediensTotalerNew" element={<ProtectedRoute component={IngredTotNew} />} />
        
                <Route path="/SearchRecepyNew2" element={<ProtectedRoute component={SearchRecepyNew2} />} />
                <Route path="/CreateManRecepyNew" element={<ProtectedRoute component={CreateManRecepyNew} />} />
                <Route path="/CreateNewRecepy" element={<ProtectedRoute component={CreateRecepyNew2} />} />
                <Route path="/RecepycategoriesNew" element={<ProtectedRoute component={RecepyCategoriesNew2} />} />
                <Route path="/ingredNew" element={<ProtectedRoute component={IngredientsNew} />} />
                <Route path="/IngredCategoriesNew" element={<ProtectedRoute component={IngredCategoriesNew} />} />
                <Route path="/CreateproductNew" element={<ProtectedRoute component={CreateProductNew} />} />
                <Route path="/ProductcategoriesNew" element={<ProtectedRoute component={ProductCategoriesNew2} />} />
                <Route path="/statisticsbyproductNew" element={<ProtectedRoute component={ProdStatNew} />} />
                <Route path="/CreateproductionNew" element={<ProtectedRoute component={CreateProductionNew} />} />
                <Route path="/TotalProductionNew" element={<ProtectedRoute component={ProdTotalNew} />} />
                <Route path="/ProdArbSumNew" element={<ProtectedRoute component={ProdArbSumNew} />} />
                <Route path="/PriceListNew" element={<ProtectedRoute component={PriceListNew} />} />
                <Route path="/WelcomeImage" element={<ProtectedRoute component={WelcomeImage}/>} />
                <Route path="/ListIngred" element={<ProtectedRoute component={ListIngred} />} />
                <Route path="/ListRecept" element={<ProtectedRoute component={ListRecept} />} />
                <Route path="/ListProdukt" element={<ProtectedRoute component={ListProdukt} />} />
                <Route path="/MySettings" element={<ProtectedRoute component={MySettings} />} />
                <Route path="/Logout" element={<ProtectedRoute component={Logout} />} />
                <Route path="/signup" element={<Info/>} />
                <Route path="/" element={<LoginPage/>} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/Support" element={<ProtectedRoute component={Support} />} />
                <Route path="/UserDataPolicy" element={<UserDataPolicy />} />
                <Route path="/RecipeIdeas" element={<RecepyIdeas />} />
                <Route path="/CreateNewRecepy/:recipeName" element={<ProtectedRoute component={CreateRecepyNew2} />} />
                <Route path="/MyAccount" element={<ProtectedRoute component={MyAccount} />} />
                <Route path="/loggedout" element={<Logout1 />} />
                <Route path="/resetpass" element={<Resetpassword />} />
                <Route path="/printInvoice/:invoiceId" element={<ProtectedRoute component={PrintInvoice} />} />
                <Route path="/recipescroll" element={<ProtectedRoute component={SearchRecepyScroll} />} />

           
      </>
    </Routes>
    </BrowserRouter>
 
    </div>
  );
}