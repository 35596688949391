import React,{useState,useEffect} from 'react'
import {useGetData,usePutData} from '../hooks/useGetData';
import useReportErr from '../hooks/useReportErr';
import './ProdArbSumNew1.css'
import{BiBookAdd} from 'react-icons/bi'
import {TiDeleteOutline} from 'react-icons/ti'
import Alert from '../functions/appAlert';
import { RotatingLines } from 'react-loader-spinner';
import { useNavigate,Link } from 'react-router-dom';
import useFetchImageUrl from '../hooks/useFetchImageUrl';
import usePageSettings from '../hooks/usePageSettings';
import languages from '../AppLanguage/AppLanguage';
import AppError from '../AppLanguage/AppError';
import displayGadgetToSmall from '../functions/displayGadgetToSmall'



export default function ProdArbSumNew() {
    const [prodArb,setprodArb]=useState([]);// we get the styles for the device type here
    const [gethistproddataObj,sethistproddataObj] = useState([]);
    const[getsumdataObj,setsumdataObj]=useState([]);
    const[getshowcalculate,setshowcalculate]=useState(false);
    const[getChosenRecipe,setChosenRecipe]=useState(0);
    const [getKoeffData, setKoeffData] = useState([]);
    const [gettotalMangd, settotalMangd] = useState(0);
    const [getVikt, setVikt] = useState(0);
    const[getrecipeDescription,setrecipeDescription]=useState('')
    const[getChosenProductId,setChosenProductId]=useState(0)
    const[getproduktdataObj,setproduktdataObj]=useState([])
    const[getdescriptionBool,setdescriptionBool]=useState(false)
    
    const[getSpinnerLoading,setSpinnerLoading]=useState(false)
    const [getShowAlert,setShowAlert] = useState(false);
    const [getAlertMessage,setAlertMessage] = useState({});
    const[getAlertCode,setAlertCode]=useState(0)
    const[getBackGroundShade,setBackGroundShade]=useState(0.55)
    const[getRenderKey,setRenderKey]=useState(0)
    const[getMenuShade,setMenuShade]=useState(0.75)
    const[getMenuShade2,setMenuShade2]=useState(0.55)
    const [getColorVal,setColorVal]=useState('')
    const [getImageVal,setImageVal]=useState('')
    const [getImageUrl,setImageUrl]=useState('')
    const pageid=15
    const [logtime]=useState(5000)

    //Handeling the recipe description, here we set the height of the textarea automaticly
let textareaHeight = Math.floor(getrecipeDescription.length*0.8);
if(textareaHeight<10){
  textareaHeight=20
}


const [windowWidth,setWindowWith]=useState(0) // this is the window width
const [windowHeight,setWindowHeight]=useState(0)  // this is the window height
const [getMobileIsUsed,setMobileIsUsed]=useState(false) // this is the window height
const[getCountry,setCountry]=useState('en') // this is the country
  

useEffect(()=>{
  let country = localStorage.getItem('country')||'en' // this is the country code
  setCountry(country)
},[])


let newFontFamily = '';
if (getCountry === 'ja') {
   newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
} else {
  newFontFamily = 'Arial, Helvetica, sans-serif'
  
//newFontFamily = 'Times New Roman, Times, serif'
//newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
}
document.documentElement.style.setProperty('--font-family', newFontFamily);


const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal } = usePageSettings(pageid);
const { imageUrl, alertMessage, alertCode, showAlert } = useFetchImageUrl(getImageVal); // runs at mount, gives ut the image url and fetch image from server

const navigate = useNavigate();

useEffect(() => {
  function setSize() {
    setWindowWith(window.innerWidth);
    setWindowHeight(window.innerHeight);
  
    if(window.innerWidth < 600){

      setMobileIsUsed(true)
    } 

  }
  setSize();
    // Add the event listener
    window.addEventListener('resize', setSize);

    // Return a cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', setSize);
    };

}, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);

function rgbToRgba(rgb, alpha) {
  return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
}

//--bakcground shade settings
let rgbaVal
let rgbaVal2
let val

if(getColorVal && getColorVal.length > 0){
let rgb = getColorVal;
let alpha = getMenuShade; // replace with your alpha value
let alpha2 = getMenuShade2; // replace with your alpha value
rgbaVal = rgbToRgba(rgb, alpha);
rgbaVal2 = rgbToRgba(rgb, alpha2);
val=`rgba(255, 255, 255, ${getBackGroundShade})`

}else{
  rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
  rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
  val=`rgba(255, 255, 255, ${getBackGroundShade})`
}
//---

useEffect(() => {
  let root = document.documentElement;
  root.style.setProperty('--rgbaVal', rgbaVal);
  root.style.setProperty('--rgbaVal2', rgbaVal2);
  root.style.setProperty('--val',val);
  root.style.setProperty('--windowHeight',windowHeight+'px');

  
}, [getBackGroundShade,getMenuShade,getMenuShade2]);

//-----

    // language settings
    const selectedCountry = languages[getCountry];
     let title=''
    if(getMobileIsUsed===false){
      title=selectedCountry.prodarbSum?.title
    }else{
      title=selectedCountry.prodarbSum?.titleMobile
    }




      const {
        titleMobile,
        leftSelectHeaderLegend,
        leftSelectFirstOption,
        middleHeaderLegend,
        middleHeaderId,
        middleHeaderIngredient,
        middleHeaderAmount,
        middleHeaderPercentage,
        middleheaderWorkWeight,
        middleHeaderArborder,
        middleDescriptionLegend,
        rightHeaderLegend,
        rightHeaderProductId,
        rightHeaderProductName,
        rightHeaderProductQuantity,
        titleMidHeaderId,
        titleMidHeaderName,
        titleMidHeaderIngredientAmount,
        titleMidHeaderPercentageOf,
        titleMiddleHeaderCalculatedIngredientAmount,
        titleMiddleHeaderWorkOrder,
        titleAdjustMenu,
        titleAdjustMenu1,
        footerTotalText,
        recipeDescriptionLegend,
      } = selectedCountry.prodarbSum ||{};

 // error messages
 const selectedError=AppError[localStorage.getItem('country')||'en']

const{
  descriptionIsUpdated
}=selectedCountry.confirmationMessages||{}


      useEffect(() => {
        GetSettings();
      }, [GetSettings]);

      // we set the image url here
      useEffect(() => {
        setImageUrl(imageUrl);
        if (alertCode > 0) {
          setAlertMessage(alertMessage);
          setAlertCode(alertCode);
          setShowAlert(showAlert);
        }
      }, [imageUrl]);
    
      // here we set the color values and image values
      useEffect(() => {
        setBackGroundShade(backGroundShade);
        setMenuShade(menuShade);
        setMenuShade2(menuShade2);
        setColorVal(colorVal);
        setImageVal(imageVal);
      }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);



      useEffect(()=>{

        async function GetData(){
          setSpinnerLoading(true)
          try {
            let response=await useGetData(`getProduction`)
            sethistproddataObj(response.data[0])
            setSpinnerLoading(false)
          }catch(err){
            setAlertCode(22001)
            setAlertMessage(selectedError[22001])
            setSpinnerLoading(false)
            setShowAlert(true)
        
          }
        
        
        }
        GetData()
        },[])
      
           
        const RunReportErrHandler = async (errCode) => {
          setSpinnerLoading(true);
          await useReportErr('ErrReport',{errCode});
          setSpinnerLoading(false);
        };
        
        useEffect(() => {
          if (getAlertCode > 0) {
            RunReportErrHandler(getAlertCode);
          }
        }, [getAlertCode]);
        
        const handleCloseAlert = () => {
          setShowAlert(false);
        };

        const GetarbsumData= async(produktionId)=>{
            setSpinnerLoading(true)
       
            setChosenProductId(produktionId)
            try {
              let response=await useGetData(`produktionSummering/${produktionId}`)
                setsumdataObj(response.data[0]);
                setproduktdataObj(response.data[1]);
                setSpinnerLoading(false)
          
        
            }catch(err){
              setAlertCode(22002)
              setAlertMessage(selectedError[22002])
              setSpinnerLoading(false)
              setShowAlert(true)
       
    
            }
          }
        
        const ShowcalculateHandler=async(receptid,vikt)=>{
          setSpinnerLoading(true)
          setdescriptionBool(false)
      
          setVikt(vikt)
          try{
          const res = await useGetData(`getProduktionKoeff/${receptid}`);
          setKoeffData(res.data[0]);
          settotalMangd(res.data[1][0].totalmangd)
          setrecipeDescription(res.data[2][0].btext)
          setSpinnerLoading(false)
          }catch(err){
            setAlertCode(22003)
            setAlertMessage(selectedError[22003])
            setSpinnerLoading(false)
            setShowAlert(true)
          
    
            return
          }
        
          setChosenRecipe(receptid)
          setshowcalculate(true)
        }
        
        const deleteingredientfromrecipeObject=(ingredid)=>{
        
          const newKoeffData=getKoeffData.filter((data)=>data.ingredid!==ingredid)
          setKoeffData(newKoeffData)
        }
        
        
        const descriptionHandler=(e)=>{
          setrecipeDescription(e)
          setdescriptionBool(true)
        }
        
   
        
        const UpdaterecipeDescription=async()=>{
          setSpinnerLoading(true)
          setdescriptionBool(false)
          let decodedDescription=btoa(JSON.stringify(getrecipeDescription))
          try{
            const response=await usePutData(`updateRecipeDescription`,{getChosenRecipe,decodedDescription})
            if(response.status===200){
              setAlertCode(0)
              setAlertMessage(descriptionIsUpdated)
              setSpinnerLoading(false)
              setShowAlert(true)
        
            }
          }catch(err){
            setAlertCode(22004)
            setAlertMessage(selectedError[22004])
            setSpinnerLoading(false)
            setShowAlert(true)
           
            
          }
        }
        

// if the user changges the value on the range control we save the value in the database
async function SetBackGroundShadeHandler(val){
  setBackGroundShade(val)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateBackGroundShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }
  }catch(error){
      setAlertCode(22005)
      setAlertMessage(selectedError[22005])
      setSpinnerLoading(false)
      setShowAlert(true)

    }

}

async function SetmenuColorHandler(val){
  setMenuShade(val)
  setMenuShade2(val-0.20)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateMenuShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }

}catch(error){
  setAlertCode(22006)
  setAlertMessage(selectedError[22006])
  setSpinnerLoading(false)
  setShowAlert(true)

}
}



  return (
    <>
    <div className="xrtotWrapper">
    {windowWidth<360?displayGadgetToSmall():''}
 
    <div className={getBackGroundShade<1?"xrwindowArea":"xrwindowArea1"}> 
    {getBackGroundShade<1?<img src={imageUrl} alt="Bakesoft" className="xrbackgroundImageRecepyNew" />:''}
  
  <div className="xrheaderArea2">
    {getCountry!=='ja'?
    <div className={getBackGroundShade<1?"xrcompName":"xrcompName1"}>{title} </div>
    : <div className={getBackGroundShade<1?"xrcompNameJAP":"xrcompNameJAP1"}>{title} </div>}
    </div>
  
  <div className="xrrangeArea">

  <input type="range" min="0.0" max="1" value={getMenuShade||0.2} step="0.10" id={2}  
  title={titleAdjustMenu} className="xrrange2" onChange={(e)=>SetmenuColorHandler(e.target.value)}/>
  
  <input type="range" min="0.0" max="1" value={getBackGroundShade||0.2} step="0.10" id={2}  
  title={titleAdjustMenu1} className="xrrange1" onChange={(e)=>SetBackGroundShadeHandler(e.target.value)}/>



  </div>

    <div className="xrwindowAreaA">
    
  
      <div className="xrleftTop">
      <fieldset className="xrleftFieldset"><legend className="xrleftLegend">&nbsp;{leftSelectHeaderLegend}</legend>
      <select className="xrleftSelect"  onChange={(e)=>GetarbsumData(e.target.value)}>
  <option value="0" hidden>&nbsp;&nbsp;{leftSelectFirstOption}&nbsp;!&nbsp;</option>
  {gethistproddataObj.map((data) => (<option key={data.produktionId} value={data.produktionId}>&nbsp;&nbsp;{data.benaemn}</option>))}
 
  </select>
       </fieldset>
       
       
       <div className="xrdataTop">
       {getsumdataObj.length>0 ?getsumdataObj.map((data,index)=>
       <button onClick={(e)=>ShowcalculateHandler(data.elementid,data.vikt)} className={index%2===0?"xrreceptidButton":"xrreceptidButton1"}
       key={index}>{data.benaemn}</button>):''}
       
       </div></div>
 
    
    
    
    
    
    
    </div>
    <div className="xrwindowAreaB">
      <CalculateRecipe prodArb={prodArb} getKoeffData={getKoeffData} gettotalMangd={gettotalMangd} getVikt={getVikt} getrecipeDescription={getrecipeDescription}
         descriptionHandler={descriptionHandler} getMobileIsUsed={getMobileIsUsed}
            getproduktdataObj={getproduktdataObj} getdescriptionBool={getdescriptionBool} updaterecipeDescription={UpdaterecipeDescription}
            
            middleHeaderLegend={middleHeaderLegend}
            middleHeaderId={middleHeaderId}
            middleHeaderIngredient={middleHeaderIngredient}
            middleHeaderAmount={middleHeaderAmount}
            middleHeaderArborder={middleHeaderArborder}
            middleHeaderPercentage={middleHeaderPercentage}
            middleheaderWorkWeight={middleheaderWorkWeight}
            titleMidHeaderId={titleMidHeaderId}
            titleMidHeaderName={titleMidHeaderName}
            titleMiddleHeaderWorkOrder={titleMiddleHeaderWorkOrder}
            titleMiddleHeaderCalculatedIngredientAmount={titleMiddleHeaderCalculatedIngredientAmount}
            titleMidHeaderPercentageOf={titleMidHeaderPercentageOf}
            titleMidHeaderIngredientAmount={titleMidHeaderIngredientAmount}
            footerTotalText={footerTotalText}
            recipeDescriptionLegend={recipeDescriptionLegend}
            
            
            />
   
     
    
    
    
    
    </div>
    <div className="xrwindowAreaC">
   
      <fieldset className="xrproductMenuFieldset">
        <legend className="xrproductMenuLegend">{rightHeaderLegend}</legend>
      <div className="xrproductMenuTop">
      <div className="xrmenuProduktid"  >{rightHeaderProductId}</div>
      <div className="xrmenuBenaemn" >{rightHeaderProductName}</div>
      <div className="xrmenuAntal" >{rightHeaderProductQuantity}</div>
      </div>
      <div className="xrproductMenuData">
      {getproduktdataObj.map((data,index)=>
      <div key={index} className="xrproductMenuDataArea">
      <div className={index%2===0?"xrproduktidData":"xrproduktidData1"}>{data.produktid}</div> 
      <div className={index%2===0?"xrbenaemnData":"xrbenaemnData1"}>{data.benaemn}</div>
      <div className={index%2===0?"xrantalData":"xrantalData1"}>{data.antal}</div>
      </div>)}

      </div>
      </fieldset>
      </div>

    </div>
    <div className="xrarbsumNewSpinnerContainer">
        <div className="xrarbsumNewSpinner">
          <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="0.75" width="60" visible={getSpinnerLoading}/>
        </div>
        </div>

  {getShowAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}/>:''}
    </div>
    </>
  
  )
}

function CalculateRecipe(props) {
    const {prodArb,getKoeffData,gettotalMangd,getVikt,getrecipeDescription,descriptionHandler,showproductmenuHandler,
      getshowproductMenu,getproduktdataObj,getdescriptionBool,updaterecipeDescription,
      getMobileIsUsed,
      middleHeaderLegend,middleHeaderId,middleHeaderIngredient,middleHeaderAmount,middleHeaderArborder,
      middleHeaderPercentage,middleheaderWorkWeight,

      titleMidHeaderId,titleMidHeaderName,titleMiddleHeaderWorkOrder,titleMiddleHeaderCalculatedIngredientAmount,
      titleMidHeaderPercentageOf,titleMidHeaderIngredientAmount,footerTotalText,recipeDescriptionLegend

    } = props
  
    let newObjarr=getKoeffData.map((data)=>{
      let koeff=data.mangd/gettotalMangd
      koeff=koeff*100
      let ingredarbvikt=getVikt*koeff
      ingredarbvikt=ingredarbvikt/100
      return{ingredid:data.ingredid,mangd:data.mangd,benaemn:data.benaemn,arborder:data.arborder,koeff:koeff,ingredarbvikt:ingredarbvikt}})
    
      let totalvikt=newObjarr.reduce((acc,data)=>acc+data.ingredarbvikt,0)
      let totalkoeff=newObjarr.reduce((acc,data)=>acc+data.koeff,0)
      let totalmangd=newObjarr.reduce((acc,data)=>acc+data.mangd,0)
      let ingredarbvikt=newObjarr.reduce((acc,data)=>acc+data.ingredarbvikt,0)
  
  
  return(
<>
    <fieldset className="xrdatafieldset"><legend className="xrdatalegend">{middleHeaderLegend}</legend>
  <div className="xrheaderWrapper">
  <div className="xrheaderArea1">
  <button className="xrheaderIngredid" title={titleMidHeaderId}>{middleHeaderId}</button>
  <button className="xrheaderBenaemn" title={titleMidHeaderName}>{middleHeaderIngredient}</button>
  <button className="xrheaderMangd" title={titleMidHeaderIngredientAmount}>{middleHeaderAmount}</button>

  <button className="xrheaderKoeff" title={titleMidHeaderPercentageOf}>{middleHeaderPercentage}</button>

  <button className="xrheaderIngredArbvikt" title={titleMiddleHeaderCalculatedIngredientAmount}>{middleheaderWorkWeight}</button>
  <button className="xrheaderArborder" title={titleMiddleHeaderWorkOrder}>{middleHeaderArborder}</button>
  
  
  </div></div>
  <div className="xrdataAreaTop">
     {newObjarr.map((data,index)=>
     <div key={index} className="xrdatarows">
     <button className={index%2===0?"xrsamdataingredid":"xrsamdataingredid1"}>{data.ingredid}</button>
     <button className={index%2===0?"xrsamdatabenaemn":"xrsamdatabenaemn1"}>{data.benaemn}</button>
     <button className={index%2===0?"xrsamdatamangd":"xrsamdatamangd1"}>{data.mangd}</button>
     <button className={index%2===0?"xrsamdatakoeff":"xrsamdatakoeff1"}>{String(data.koeff).substring(0,4)}%</button>
     <button className={index%2===0?"xrsamdataarbvikt":"xrsamdataarbvikt1"}>{Math.round(data.ingredarbvikt)}</button>
     <button className={index%2===0?"xrsamdataarborder":"xrsamdataarborder1"}>{data.arborder}</button>
     
      </div>)}
      </div>

      <div className="xrfooterWrapper">
      <div className="xrfooter">
      <div className="xrfooterIngredid"></div>
      <div className="xrfooterBenaemn">{footerTotalText}</div>
      <div className="xrfooterMangd">{String(totalmangd).substring(0,5)}</div>
      <div className="xrfooterKoeff">{String(totalkoeff).substring(0,5)}%</div>
      <div className="xrfooterIngredArbvikt">{String(Math.floor(ingredarbvikt))}</div>
      <div className="xrfooterArborder"></div>
      </div>
      </div>
      </fieldset>
    
  
      <div className="xrprodarbsumDescriptionArea">
      {getrecipeDescription.length>0?<RecipeDescription prodArb={prodArb} getrecipeDescription={getrecipeDescription} descriptionHandler={descriptionHandler}
           updaterecipeDescription={updaterecipeDescription} getdescriptionBool={getdescriptionBool}
           
           recipeDescriptionLegend={recipeDescriptionLegend}
           />:''}
    </div>
  </>
  
  
  )
    }

    function RecipeDescription(props){
        let{prodArb,getrecipeDescription,descriptionHandler,getdescriptionBool,updaterecipeDescription,
          recipeDescriptionLegend
        }=props
        
     
        
        
          return(
        
        
        
        
        <div className="xrprodsamDescriptionTop">
        <fieldset className="xrprodsamDescriptionFieldset">
        <legend className="xrprodsamDescriptionLegend">{recipeDescriptionLegend}</legend>
        <div className="xrprodsamDescriptionArea">
          <textarea className={!getdescriptionBool?"xrprodsamDescriptionTextArea":"prodsamDescriptionTextAreaChange"} 
          value={getrecipeDescription} onDoubleClick={()=>updaterecipeDescription()} 
          onChange={(e)=>descriptionHandler(e.target.value)}></textarea>
        </div>
        </fieldset>
        
        </div>
        
        
        
          )
        
        }


export {ProdArbSumNew,CalculateRecipe,RecipeDescription}