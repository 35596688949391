import { useState, useEffect } from 'react';

function useAutoHideVisibility(initialVisibility = false, timeToBeVisible = 5000) {
  const [isVisible, setIsVisible] = useState(initialVisibility);

  useEffect(() => {
    let visibilityTimer;

    if (isVisible) {
      visibilityTimer = setTimeout(() => {
        setIsVisible(false);
      }, timeToBeVisible);
    }

    return () => {
      clearTimeout(visibilityTimer);
    };
  }, [isVisible, timeToBeVisible]);

  return [isVisible, setIsVisible];
}

export default useAutoHideVisibility;
