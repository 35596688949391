import React,{useEffect,useState} from 'react'
import axios from 'axios'
import {useGetData,usePutData,useDeleteData,usePostData} from '../hooks/useGetData'
import { RotatingLines } from  'react-loader-spinner'
import Alert from '../functions/appAlert'
import useReportErr from '../hooks/useReportErr'
import { BsTrash } from 'react-icons/bs';
import { IoCreateOutline } from 'react-icons/io5';
import useAutoHideVisibility from '../hooks/useAutohide'
import useFetchImageUrl from '../hooks/useFetchImageUrl';
import usePageSettings from '../hooks/usePageSettings';
import {useNavigate} from 'react-router-dom';
import YesNoAlert from '../functions/YesNoAlert'
import languages from '../AppLanguage/AppLanguage';
import AppError from '../AppLanguage/AppError';
import '../IngredCategoriesNew/IngredCategoriesNew.css'
import displayGadgetToSmall from '../functions/displayGadgetToSmall';




export default function IngredCategoriesNew(props) {


  const [getingredCategories,setingredCategories]=useState([{}])
  const[getingredByCategory, setingredByCategory]=useState([])
  const[getChosenCategory,setChosenCategory]=useState(0)
  const[getcategoryName,setcategoryName]=useState('')
  const[getdelButton,setdelButton]=useState(0)
  const[getviewDeleteCategory,setviewDeleteCategory]=useState(0)
  const[getSpinnerLoading,setSpinnerLoading]=useState(false)

  const [getShowAlert,setShowAlert] = useState(false);
  const [getAlertMessage,setAlertMessage] = useState({});
  const[getAlertCode,setAlertCode]=useState(0)
  const[getingred,setIngred]=useState({})
  const[getViewCreateCategory,setViewCreateCateory]=useState(false)
  const[ingredientReadyToMove,setIngredientReadyToMove]=useState(false)
  const[moveToCategory,setMoveToCategory]=useState(0)
  const[getShowYesNoAlert,setShowYesNoAlert]=useState(false)
  const[getYesNoAlertMessage,setYesNoAlertMessage]=useState('')

  const[getBackGroundShade,setBackGroundShade]=useState(0.30)
  const[getRenderKey,setRenderKey]=useState(0)
  const[getMenuShade,setMenuShade]=useState(0.75)
  const[getMenuShade2,setMenuShade2]=useState(0.55)
  const [getColorVal,setColorVal]=useState('')
  const [getImageVal,setImageVal]=useState('')
  const [getImageUrl,setImageUrl]=useState('')
 const pageid=5
 const [logtime]=useState(5000)
  //we use this hook to show the createcategory component
  const [isVisible, setIsVisible] = useAutoHideVisibility(false, 30000);

  const [windowWidth,setWindowWith]=useState(0) // this is the window width
  const [windowHeight,setWindowHeight]=useState(0)  // this is the window height
  const [ipadIsUsed,setIpadIsUsed]=useState(false) // if we use ipad
  const [mobileIsUsed,setMobileIsUsed]=useState(false) // if we use mobile
  const [windowIsUsed,setWindowIsUsed]=useState(false) // if we use windows
  const[getCountry,setCountry]=useState('en') // this is the country
  const [furiganaCategoryname,setFuriganaCategoryname]=useState('') // this is the furigana category name
  const [kanjiCategoryname,setKanjiCategoryname]=useState('') // this is the kanji category name
  const [getFuriganaName,setFuriganaName]=useState('') // this is the furigana name
  const[getKanjiName,setKanjiName]=useState('') // this is the kanji name

  useEffect(()=>{
    let country = localStorage.getItem('country')||'se' // this is the country code
    setCountry(country)
  },[])

  let newFontFamily = '';
  if (getCountry === 'ja') {
     newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
  } else {
    newFontFamily = 'Arial, Helvetica, sans-serif'
    
  //newFontFamily = 'Times New Roman, Times, serif'
  //newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
  }
  document.documentElement.style.setProperty('--font-family', newFontFamily);
     
  

  const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal } = usePageSettings(pageid);
  const { imageUrl, alertMessage, alertCode, showAlert } = useFetchImageUrl(getImageVal); // runs at mount, gives ut the image url and fetch image from server

  const navigate = useNavigate();


  useEffect(() => {
    function setSize() {
      setWindowWith(window.innerWidth);
      setWindowHeight(window.innerHeight);
    
      if(window.innerWidth < 600){
  
        setMobileIsUsed(true)
      } 
  
    }

  
    // Call setSize initially to set the correct state
    setSize();
  
    // Add the event listener
    window.addEventListener('resize', setSize);
  
    // Return a cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', setSize);
    };
  }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]); // Dependencies


  function rgbToRgba(rgb, alpha) {
    return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
  }
  
//--bakcground shade settings
let rgbaVal
let rgbaVal2
let val

if(getColorVal && getColorVal.length > 0){
  let rgb = getColorVal;
  let alpha = getMenuShade; // replace with your alpha value
  let alpha2 = getMenuShade2; // replace with your alpha value
  rgbaVal = rgbToRgba(rgb, alpha);
  rgbaVal2 = rgbToRgba(rgb, alpha2);
  val=`rgba(255, 255, 255, ${getBackGroundShade})`

  }else{
    rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
    rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
    val=`rgba(255, 255, 255, ${getBackGroundShade})`
  }
//---

useEffect(() => {
    let root = document.documentElement;
    root.style.setProperty('--rgbaVal', rgbaVal);
    root.style.setProperty('--rgbaVal2', rgbaVal2);
    root.style.setProperty('--val',val);
    root.style.setProperty('--windowHeight',windowHeight+'px');

    
  }, [getBackGroundShade,getMenuShade,getMenuShade2]);








   // language settings
  const selectedCountry = languages[getCountry];
   let title=''
  if(mobileIsUsed===false){
    title=selectedCountry.ingredcategories?.title
  }else{
    title=selectedCountry.ingredcategories?.titleMobile
  }
let textmessage=''
 if(getCountry==='en'){
  textmessage='Welcome to ingredient categories, select a category, select an ingredient, move to another category, remove category by double clicking category, if a category has ingredients, they are moved to category other and the category is removed. Create new category by pressing the create icon.'
 }else if(getCountry==='se'){
  textmessage='Välkommen till ingredienskategorier, välj en kategori, välj en ingrediens, flytta till annan kategori, ta bort kategori genom att dubbelklicka på kategori, om en kategori har ingredienser flyttas dom till kategori övrigt och kategori tas bort. Skapa ny kategori genom att trycka på ikonen skapa.'
  }else if(getCountry==='ja'){
    textmessage='食材のカテゴリーへようこそ！カテゴリーを「選択」し、食材を「選択」し、別のカテゴリーへ「移動」することができます。カテゴリーを削除するときはダブルクリックしてください。もしも削除するカテゴリーに食材がある場合は、「その他」のカテゴリーに移動し、対象のカテゴリーは削除されます。新たなカテゴリーを作成するときは、「作成」ボタンを押してください。'
  }



 const categoryHeaderKategori=selectedCountry.ingredcategories?.categoryHeaderKategori
 const  categoryHeaderAntal=selectedCountry.ingredcategories?.categoryHeaderAntal
 const categoryMoveIngredLegend=selectedCountry.ingredcategories?.categoryMoveIngredLegend
 const categorySelectFirstOption=selectedCountry.ingredcategories?.categorySelectFirstOption
 const categoryButtonMoveSubmit=selectedCountry.ingredcategories?.categoryButtonMoveSubmit
 const categoryCreateCategorySubmit=selectedCountry.ingredcategories?.categoryCreateCategorySubmit
const  furiganaLabel=selectedCountry.ingredcategories?.furiganaLabel
const furiganaHolder=selectedCountry.ingredcategories?.furiganaHolder
const kanjiHolder=selectedCountry.ingredcategories?.kanjiHolder


   // error messages
   const selectedError=AppError[localStorage.getItem('country')||'en']
  
   const {
    categoryDeleted,
    categoryDeleteQuestion,
    categoryNameWrite,
    ingredientsMovedTochosenCategory,
    categoryCreated,
    btnYes,
    btnNo,
    categoriesVoid,
  } = selectedCountry.confirmationMessages||{};





  useEffect(() => {
    GetSettings();
  }, [GetSettings]);

  // we set the image url here
  useEffect(() => {
    setImageUrl(imageUrl);
    if (alertCode > 0) {
      setAlertMessage(alertMessage);
      setAlertCode(alertCode);
      setShowAlert(showAlert);
    }
  }, [imageUrl]);

  // here we set the color values and image values
  useEffect(() => {
    setBackGroundShade(backGroundShade);
    setMenuShade(menuShade);
    setMenuShade2(menuShade2);
    setColorVal(colorVal);
    setImageVal(imageVal);
  }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal,getingredCategories]);



useEffect(()=>{

  async function GetIngredCategories(){
    setSpinnerLoading(true)
   try{
    let response= await useGetData(`getIngredCategories`)
    setingredCategories(response.data[0])
    setSpinnerLoading(false)
   }catch(error){
    setAlertCode(4001)
    setAlertMessage(selectedError[4001])
    setSpinnerLoading(false)
    setShowAlert(true)

    return;
   }

}
GetIngredCategories()

},[])

const RunReportErrHandler = async (errCode) => {
  setSpinnerLoading(true);
  await useReportErr('ErrReport',{errCode});
  setSpinnerLoading(false);
};

useEffect(() => {
  if (getAlertCode > 0) {
    RunReportErrHandler(getAlertCode);
  }
}, [getAlertCode]);


const handleCloseAlert = () => {
  setShowAlert(false);
};


const handleCloseAlertYes = () => {
  setShowYesNoAlert(false);
  DeleteCategory()
};
const handleCloseAlertNo = () => {
  setShowYesNoAlert(false);
};

async function DeleteCategory(){
  setSpinnerLoading(true)
  try{
    let response= await useDeleteData(`checkIngredItemsInCategoryAndMove/${getChosenCategory}`)
    if(response.status===200){
      setSpinnerLoading(false)
      setAlertCode(0)
      setAlertMessage(categoryDeleted)
      setShowAlert(true)
      // we need to update the state and deltet the category from the state
      let newObjArr=[...getingredCategories]
      let newObjArrIndex=newObjArr.findIndex(data=>data.kategoriid==getChosenCategory)
      // we need to delete the category from the object
      newObjArr.splice(newObjArrIndex,1)
      //update state
      setingredCategories(newObjArr)

    }
  }catch(error){
    setAlertCode(4013)
    setAlertMessage(selectedError[4013])
    setSpinnerLoading(false)
    setShowAlert(true)
  }
}

const deleteCategoryHandlerContainer=(kategoriid)=>{
  //here we want to find the name of the category
  let newObjArr=[...getingredCategories]
  let newObjArrIndex=newObjArr.findIndex(data=>data.kategoriid==kategoriid)
  let kategoriname=newObjArr[newObjArrIndex].benaemn
  setYesNoAlertMessage(`${categoryDeleteQuestion} ${kategoriname}?`)
  setShowYesNoAlert(true)
}

const categoryHandler=()=>{

  if(getcategoryName.length>0){
    async function InsertnewCategory(){
      setSpinnerLoading(true)
 try{
  let response= await useGetData(`insertNewIngredCategory/${getcategoryName}`)
  if(response.status===200){
   setingredCategories(response.data[0])
   setSpinnerLoading(false)
   setcategoryName('')
   setAlertCode(0)
   setAlertMessage(categoryCreated)
   setShowAlert(true)
  }else if(response.status===201){
    setSpinnerLoading(false)
    setAlertCode(0)
    setAlertMessage(categoriesVoid)
    setSpinnerLoading(false)
    setShowAlert(true)
  }
 } catch(error){
  setAlertCode(4002)
  setAlertMessage(selectedError[4002])
  setSpinnerLoading(false)
  setShowAlert(true)
  return;
 }
    
  }
  InsertnewCategory()


 // let categories=getingredCategories.filter(data=>data.kategoriid<99)
 // let highestCategoryId=categories[categories.length-1].kategoriid
  let highestCategoryId=getingredCategories[getingredCategories.length-1].kategoriid


   //making a new object
   let newcategory={['kategoriid']:highestCategoryId+1,['antal']:0,['Benaemn']:getcategoryName,['btext']:''}
//gettting the main object
   let newObjArr=[...getingredCategories]
   //push on
   newObjArr.push(newcategory)
  
   //replace
   setingredCategories(newObjArr)
}else{
  setAlertCode(0)
  setAlertMessage(categoryNameWrite)
  setShowAlert(true)
}


}



const datainfoHandler=(category)=>{
  setIngredientReadyToMove(false) // here we reset the ingredient ready to move
  setMoveToCategory(0) // here we reset the move to category

  async function GetIngredbyCategory(category){
    setSpinnerLoading(true)
    try{
      let response=await useGetData(`getIngredByCategory/${category}`)
      setingredByCategory(response.data[0])
      setSpinnerLoading(false)
    }catch(error){
  setAlertCode(4003)
  setAlertMessage(selectedError[4003])
  setSpinnerLoading(false)
  setShowAlert(true)
  return;
    }
   }
   GetIngredbyCategory(category)

  setdelButton(0)
  setChosenCategory(category)
  if(getviewDeleteCategory==0){
  setviewDeleteCategory(1)
 // setviewDeleteCategory(0)
  
  }else{
    setviewDeleteCategory(0)
  }
  
  
  }
  

     const setViewCreateCateoryHandler=()=>{
        if(getViewCreateCategory===false){
          setIsVisible(true)
          setViewCreateCateory(true)
        }else{
          setViewCreateCateory(false)
        }
      }


      // if the user changges the value on the range control we save the value in the database
async function SetBackGroundShadeHandler(val){
  setBackGroundShade(val)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateBackGroundShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }
  }catch(error){
      setAlertCode(4010)
      setAlertMessage(selectedError[4010])
      setSpinnerLoading(false)
      setShowAlert(true)
      setTimeout(() => {
        navigate('/Logout');
      },logtime);
    }

}

async function SetmenuColorHandler(val){
  setMenuShade(val)
  setMenuShade2(val-0.20)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateMenuShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }

}catch(error){
  setAlertCode(4011)
  setAlertMessage(selectedError[4011])
  setSpinnerLoading(false)
  setShowAlert(true)
}
}

const setChosenIngredHandler=(ingredid)=>{

// first we will remove this item from the list of ingredients getingredByCategory
let newObjArr=[...getingredByCategory]
let newObjArrIndex=newObjArr.findIndex(data=>data.ingredid==ingredid)
//we will not drop we will add a new property to the object and we will toggle this property
if(newObjArr[newObjArrIndex].readyTomove==1){
  newObjArr[newObjArrIndex].readyTomove=undefined
}else{
  newObjArr[newObjArrIndex].readyTomove=1
}
//update state
setingredByCategory(newObjArr)
}

// here i watn to check if we have any ingredients that are ready to move
useEffect(()=>{
  let newObjArr=[...getingredByCategory]
  let newObjArrIndex=newObjArr.findIndex(data=>data.readyTomove==1)
  if(newObjArrIndex>-1){
    setIngredientReadyToMove(true)
  }
}
,[getingredByCategory])

const moveToCategoryHandler=(val)=>{
  setMoveToCategory(val)
}

function moveIngredientsToNewCategory(moveToCategory){
  // first we must identify the ingredients ready to move
  let newObjArr=[...getingredByCategory]
  // so we filter out the ingredients ready to move and we only need the ingredid

  let newObjArrReadyToMove=newObjArr.filter(data=>data.readyTomove==1)
  //take out all the ingredid from this object
  let ingredidArr=newObjArrReadyToMove.map(data=>data.ingredid)

  
  // first we update the database
  let decodedIngredientData=btoa(JSON.stringify(ingredidArr))
  async function UpdateIngredCategory(){
    setSpinnerLoading(true)
    try{
      let response=await usePutData(`moveIngredToCategory2`,{decodedIngredientData,moveToCategory,getChosenCategory})
      if(response.status===200){
        setSpinnerLoading(false)
        setAlertCode(0)
        setAlertMessage(ingredientsMovedTochosenCategory)
        setShowAlert(true)
      }
    }catch(error){
      setAlertCode(4012)
      setAlertMessage(selectedError[4012])
      setSpinnerLoading(false)
      setShowAlert(true)
    }
  }
  UpdateIngredCategory()

  // then we update the state
  // we filter out the ingredients not ready to move
  let newObjArrNotReadyToMove=newObjArr.filter(data=>data.readyTomove==undefined)
  // we update the state
  setingredByCategory(newObjArrNotReadyToMove)
  // we update the category object
  let newObjCategory=[...getingredCategories]
  // find the index of the moveToCategory
  let moveToCategoryIndex=newObjCategory.findIndex(data=>data.kategoriid==moveToCategory)
  //here we need to update the object property antal on the moveToCategory
  // we update antal
  let antal=newObjCategory[moveToCategoryIndex].antal
  // we add as many as we have in newObjArrReadyToMove
  antal=antal+newObjArrReadyToMove.length
  // we update the antal
  newObjCategory[moveToCategoryIndex].antal=antal
  //here we need to update the object property antal on the from category
  //find the index of the from category
  let fromCategoryIndex=newObjCategory.findIndex(data=>data.kategoriid==getChosenCategory)
  // we update antal with - as many as we have in newObjArrReadyToMove
  let antal1=newObjCategory[fromCategoryIndex].antal
  // we minus as many as we have in newObjArrReadyToMove
  antal1=antal1-newObjArrReadyToMove.length
  // we update the antal
  newObjCategory[fromCategoryIndex].antal=antal1
  //update state
  setingredCategories(newObjCategory)
  //clean up
  setIngredientReadyToMove(false)
  setMoveToCategory(0)

}

const setFuriganaValueHandler=(val)=>{
  setFuriganaCategoryname(val)
}

const setKanjiValueHandler=(val)=>{
  setKanjiCategoryname(val)
}

const CreateNewJAPCategory=async()=>{
  setSpinnerLoading(true)
  try{
    let response =await usePostData(`insertNewJAPIngredCategory`,{getKanjiName,getFuriganaName})
    if(response.status===200){
    setingredCategories(response.data[0])
    setSpinnerLoading(false)
    setViewCreateCateory(false)
    setAlertCode(0)
    setAlertMessage(categoryCreated)
    setShowAlert(true)
    return;
  }else if(response.status===201){
    setAlertCode(0)
    setAlertMessage(categoriesVoid)
    setShowAlert(true)
    setSpinnerLoading(false)
    return;
  }
  }catch(error){
    setAlertCode(4014)
    setAlertMessage(selectedError[4014])
    setSpinnerLoading(false)
    setShowAlert(true)
  }



}

const setKanjiNameHandler=(val)=>{
  setKanjiName(val)
}

const setFuriganaNameHandler=(val)=>{
  setFuriganaName(val)
}

const createCategoryJAPHandler=()=>{
  CreateNewJAPCategory()
}


      return(
    <>
        {windowWidth<360?displayGadgetToSmall():''}
        <div className={getBackGroundShade==1?"xfingredtotalArea1":"xfingredtotalArea"}>
        {getBackGroundShade==1?'':<img src={imageUrl} alt="Bakesoft" className="xfbackgroundImageRecepyNew" />}
 
 <div className="xfheaderArea">
        {getCountry!=='ja'?
        <div className={getBackGroundShade<1?"xfcompName":"xfcompName1"}>{title}</div>
        : <div className={getBackGroundShade<1?"xfcompNameJAP":"xfcompNameJAP1"}>{title}</div>}
        </div>
        
      
    <div className="xfrangeArea">
         
    <input type="range" min="0.0" max="1" value={getMenuShade||0.2} step="0.10" id={1}  
    title="Justera menyfärg" className="xfingredrange2" onChange={(e)=>SetmenuColorHandler(e.target.value)}/>
 
    <input type="range" min="0.0" max="1" value={getBackGroundShade||0.2} step="0.10" id={2}  
    title="Justera bakgrund" className="xfingredrange1" onChange={(e)=>SetBackGroundShadeHandler(e.target.value)}/>
    
    </div>
        
        <div className="xfingredareaA">

        <div className="xfingredCategoryDatalist">
     <div className="xfingredCategoryTop">
     <button className="xfingredcategoryBenaemnHeader">{categoryHeaderKategori}</button>
     <button className="xfingredcategoryAntalHeader">{categoryHeaderAntal}</button>
   
    <div className="xfingredcatrowsdata">
{getingredCategories.map((data,index)=>

<div key={index}   className="xfrows">
<button className={index%2===0?"xfingredcategorydataBenaemn":"xfingredcategorydataBenaemn1"}  key={index} 
onClick={()=>datainfoHandler(data.kategoriid)}
onDoubleClick={()=>deleteCategoryHandlerContainer(data.kategoriid)}
>{data.benaemn}</button>

<button className={index%2===0?"xfingredcategorydataAntal":"xfingredcategorydataAntal1"}>{data.antal}</button>
</div>
)}          
</div>                                                            
</div>
</div>
</div>


<div className="ingredareaB">
<div className="ingredcreatecatTop">
<IoCreateOutline  title="Skapa ny kategori" onClick={()=>setViewCreateCateoryHandler()}
 className="xfingredcreateCat"/>

{getViewCreateCategory===true&&isVisible&&getCountry!=='ja'?<AddCategory categoryHandler={categoryHandler} getingred={getingred}
setcategoryName={setcategoryName} getcategoryName={getcategoryName} getCountry={getCountry}
categoryCreateCategorySubmit={categoryCreateCategorySubmit} 
/>:''}

{getViewCreateCategory===true&&isVisible&&getCountry==='ja'?<AddCategoryJAP 
getingred={getingred} setKanjiNameHandler={setKanjiNameHandler} 
setFuriganaNameHandler={setFuriganaNameHandler} createCategoryJAPHandler={createCategoryJAPHandler}
getKanjiName={getKanjiName} getFuriganaName={getFuriganaName} categoryCreateCategorySubmit={categoryCreateCategorySubmit}
/>:''}
</div>

<div className="ingredcatrightdataArea">


{
  getingredByCategory.length >0
    ? getingredByCategory
    .filter(data=>data.readyTomove===undefined)
        .map((data, key) => (
          <div  
            className={key % 2 === 0 ?"xfingredcategoryrightButton1":"xfingredcategoryrightButton"} 
            key={key}
          >
            <div 
              className="ingredcategoryrightButtontext"
              onClick={() => setChosenIngredHandler(data.ingredid)}
        

            >
              {data.benaemn}
            </div>
          </div>
        ))
    : ''
}
</div>
<div>
{ingredientReadyToMove===true?
<div className="ingredmoveitemTop">
<fieldset className="ingredmoveitemFieldset">
  <legend className="ingredmoveitemLegend">{categoryMoveIngredLegend}</legend>

{
  getingredByCategory.length > 0 && ingredientReadyToMove === true
    ? getingredByCategory
        .filter(data => data.readyTomove == 1)
        .map((data, key) => (
          <div  
            className={key % 2 === 0 ?"xfingredcategoryrightButton1":"xfingredcategoryrightButton"} 
            key={key}
          >
            <div 
              className="ingredcategoryrightButtontext"
              onClick={() => setChosenIngredHandler(data.ingredid)}
          
              >
              {data.benaemn}
            </div>
          </div>
        ))
    : ''
}
</fieldset>
<div className="xfingredmoveTop">
<select className="ingredmoveitemSelect" onChange={(e)=>moveToCategoryHandler(e.target.value)}>
<option value="0">&nbsp;&nbsp;{categorySelectFirstOption}</option>
{getingredCategories.map((data,index)=>
<option key={index} value={data.kategoriid}>&nbsp;&nbsp;{data.benaemn}</option>
)}
</select>

<button className={moveToCategory>0?"ingredmoveitemSaveButton":"ingredmoveitemSaveButton1"} 
disabled={moveToCategory===0}
onClick={()=>moveIngredientsToNewCategory(moveToCategory)}
>{categoryButtonMoveSubmit}</button>
</div>
</div>
:''}

</div>
</div>
        
        
        
        <div className="ingredareaC">

          {/* // here we will show the textmessage,if mobile we do not show the textarea */}
        {mobileIsUsed===false?
        <div className="ingredcattextAreaMessage">
            <TextArea displayText={textmessage}  rows={30} getingred={getingred}/></div>
            :''}

        </div>

        <div className="ingredSpinnerContainer">
        <div className="ingredSpinner">
          <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="1" width="60" visible={getSpinnerLoading}/>
        </div>
        </div>
  {getShowAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}/>:''}
  {getShowYesNoAlert===true?<YesNoAlert message={getYesNoAlertMessage}  onCloseYes={handleCloseAlertYes} onCloseNo={handleCloseAlertNo}
  btnYes={btnYes} btnNo={btnNo}
  />:''}
      
        </div>
      </>
    
      )

}

function TextArea(props){
    let displayText=props.displayText

    return(
      <div>
  
      <textarea
      value={displayText}
    
  
       readOnly
       className="ingredcattextAreaMessage"
      ></textarea>
      </div>
    )
  }
  
  function AddCategory(props){
  
    let setcategoryName=props.setcategoryName
    let getcategoryName=props.getcategoryName
    let categoryHandler=props.categoryHandler
    let getingred=props.getingred
    let categoryCreateCategorySubmit=props.categoryCreateCategorySubmit
    let getCountry=props.getCountry
    
  
    return(
      <div className="xfingredcatcreateCategoryTop">
  
      <input onChange={(e)=>setcategoryName(e.target.value)} value={getcategoryName} type="text" className="xfingredcatcreateCategoryInput" />
   
      
      <input type="button" onClick={()=>categoryHandler()}
      className={!getcategoryName?"xfingredcatcreateCategoryButton1":"xfingredcatcreateCategoryButton"} 
      value={categoryCreateCategorySubmit}
      disabled={!getcategoryName}
      
      /></div>
    )
  }


    
  function AddCategoryJAP(props){
    let {  catnew,
           categoryCreateCategorySubmit,setKanjiNameHandler,setFuriganaNameHandler,createCategoryJAPHandler,
           getKanjiName,getFuriganaName,getingred
   }=props

  
    return(
      <div className="ingredCategoryTopJAP">
      <input onChange={(e)=>setKanjiNameHandler(e.target.value)} value={getKanjiName}type="text" 
      className="ingredCategoryInputKanji"
      placeholder='カテゴリ名'
      />

      <input onChange={(e)=>setFuriganaNameHandler(e.target.value)} value={getFuriganaName}type="text" 
      className="ingredCategoryInputFurigana" placeholder='ふりがな' />
  
      <input type="button" onClick={()=>createCategoryJAPHandler()}className={getKanjiName.length>0&&getFuriganaName.length>0?"ingredCategoryButtonJAP":"ingredCategoryButtonJAP1"} 
      value={categoryCreateCategorySubmit}
      disabled={getKanjiName.length>0&&getFuriganaName.length>0?false:true}
      /></div>
    )
  }


  
  
  export{IngredCategoriesNew,TextArea,AddCategory,AddCategoryJAP}
  



