import React,{useEffect,useState} from 'react'
import './Logout.css'
import kitchenwithbread from '../images/kitchenwithbread.jpeg'
import yukiinbakery2 from '../images/yukiinbakery2.jpg'
import displayGadgetToSmall from '../functions/displayGadgetToSmall'
import { Link } from 'react-router-dom';
import languages from '../AppLanguage/AppLanguage';
import { usePostData } from '../hooks/useGetData'


function Logout() {

   const[getCountry,setCountry] = useState('se')
    const[getMobileIsUsed,setMobileIsUsed] = useState(false)
    const[windowWidth,setWindowWith] = useState(0)
    const[windowHeight,setWindowHeight] = useState(0)


    let token = localStorage.getItem('auth_token')
    // we remove the token since it is no longer valid
   async function RemoveTokenInDatabase(){
    try{
     // let response =await usePostData('deleteUserToken',{token})
     const token = localStorage.getItem('auth_token');
     const headers = token ? { authorization: `Bearer ${token}` } : {};  
     const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/deleteUserToken`, { headers });




      if(response.status === 200){
        localStorage.removeItem('auth_token')
      }

    }catch(e){
      console.log(e)
    }
   }



  useEffect(()=>{
    RemoveTokenInDatabase()
    let country = localStorage.getItem('country')||'se' // this is the country code
    setCountry(country)
  },[])

  
  let newFontFamily = '';
  if (getCountry === 'ja') {
     newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
  } else {
   newFontFamily = 'Arial, Helvetica, sans-serif'
  //newFontFamily = 'Lobster'
    
  //newFontFamily = 'Times New Roman, Times, serif'
  //newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
  }
  document.documentElement.style.setProperty('--font-family', newFontFamily);

  useEffect(() => {
    function setSize() {
      setWindowWith(window.innerWidth);
      setWindowHeight(window.innerHeight);
    
      if(window.innerWidth < 600){
  
        setMobileIsUsed(true)
      } else{
        setMobileIsUsed(false)
      }
  
    }
  
  
    // Call setSize initially to set the correct state
    setSize();
  
    // Add the event listener
    window.addEventListener('resize', setSize);
  
    // Return a cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', setSize);
    };
  }, []); // Dependencies

  const selectedCountry = languages[getCountry];
const {
  logoutMessage,
  loginMessage,
} = selectedCountry.logout || {}


  return (

    <div className="avtotWrapper">
        {windowWidth<360?displayGadgetToSmall():''}
        
      <div className="avtotalArea">
          <div className="avareaA">
               <div className="avlogoutTop">{logoutMessage}</div>
          </div>


      <div className="avareaB">
      <Link to="/login" className="avlogoutLink">
    <img src={yukiinbakery2} alt="Bakesoft login" title="Click to login" className="avlogoutImage"/>
</Link>
    
      </div>

      <div className="avareaC">
      <button className="avloginButton" onClick={()=>{window.location.href='/login'}}>{loginMessage}</button>
      </div>
    
    
    </div>
    </div>
    
  )
}

export default Logout