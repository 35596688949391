import React,{useEffect,useState} from 'react'
import software1 from './images/software1.png'
import starteryukibyowen from './images/starteryukibyowen.jpg'
import './welcomeimage.css'
import useFetchImageUrl from './hooks/useFetchImageUrl'
import usePageSettings from './hooks/usePageSettings'
import { FaListAlt, FaScroll, FaSearch, FaBoxOpen, FaDollarSign,FaTools, FaLifeRing,
  FaHome, FaInfoCircle, FaPhone,FaBars
 } from 'react-icons/fa';
import useGetData, { usePostData } from './hooks/useGetData'
import languages from './AppLanguage/AppLanguage';
import AppError from './AppLanguage/AppError';
import { RotatingLines } from 'react-loader-spinner'
import Alert from './functions/appAlert'
import useReportErr from './hooks/useReportErr'

export const MyContext = React.createContext();



export default function WelcomeImage() {

const [getCountry,setCountry] = useState('en')
const [windowWidth, setWindowWith] = useState(window.innerWidth);
const [windowHeight, setWindowHeight] = useState(window.innerHeight);
const [mobileIsUsed,setMobileIsUsed] = useState(false)
const[getBackGroundShade,setBackGroundShade]=useState(0.75)
const[getRenderKey,setRenderKey]=useState(0) // this is the key for the component
const[getMenuShade,setMenuShade]=useState(0.95)
const[getMenuShade2,setMenuShade2]=useState(0.75)
const [getColorVal,setColorVal]=useState('')
const [getImageVal,setImageVal]=useState('')
const [getImageUrl,setImageUrl]=useState('')
const[getSpinnerLoading,setSpinnerLoading]=useState(false)
const[getAlertCode,setAlertCode]=useState(0)
const[getAlertMessage,setAlertMessage]=useState('') // this is the message for the alert
const[getShowAlert,setShowAlert]=useState(false) // this is th
const [isMenuVisible, setIsMenuVisible] = useState(false);
const [menuChoice, setMenuChoice] = useState(0); 
const [getType,setType]=useState(0)
const [priceListCategories,setPriceListCategories]=useState([])
const [chosenCategory,setChosenCategory]=useState(0)
const [priceList,setPriceList]=useState([])
const mpercentage=100;


const [pageid]=useState(23)

  useEffect(()=>{
    let country = localStorage.getItem('country')||'en' // this is the country code
    setCountry(country)
  },[])


let newFontFamily = '';
if (getCountry === 'ja') {
   newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
} else {
    newFontFamily='Arial, Helvetica, sans-serif'
//newFontFamily = 'Times New Roman, Times, serif'
//newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
}
document.documentElement.style.setProperty('--font-family', newFontFamily);



const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal} = usePageSettings(pageid);
const { imageUrl, alertMessage, alertCode, showAlert } = useFetchImageUrl(getImageVal);



useEffect(() => {
  function setSize() {
    setWindowWith(window.innerWidth);
    setWindowHeight(window.innerHeight);
  
    if(window.innerWidth < 600){

      setMobileIsUsed(true)
    }

  }


  // Call setSize initially to set the correct state
  setSize();

  // Add the event listener
  window.addEventListener('resize', setSize);

  // Return a cleanup function to remove the event listener when the component unmounts
  return () => {
    window.removeEventListener('resize', setSize);
  };
}, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]); // Dependencies
        


function rgbToRgba(rgb, alpha) {
  return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
}
      //--bakcground shade settings
      let rgbaVal
      let rgbaVal2
      let val
      
      if(getColorVal && getColorVal.length > 0){
        let rgb = getColorVal;
        let alpha = getMenuShade; // replace with your alpha value
        let alpha2 = getMenuShade2; // replace with your alpha value
        rgbaVal = rgbToRgba(rgb, alpha);
        rgbaVal2 = rgbToRgba(rgb, alpha2);
        val=`rgba(255, 255, 255, ${getBackGroundShade})`
      
        }else{
          rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
          rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
          val=`rgba(255, 255, 255, ${getBackGroundShade})`
        }
      //---
      
      useEffect(() => {
          let root = document.documentElement;
          root.style.setProperty('--rgbaVal', rgbaVal);
          root.style.setProperty('--rgbaVal2', rgbaVal2);
          root.style.setProperty('--val',val);
          root.style.setProperty('--windowHeight',windowHeight+'px');
          
        }, [getBackGroundShade,getMenuShade,getMenuShade2]);
      
        //-----


        useEffect(() => {
          GetSettings();
        }, [GetSettings]);

        // we set the image url here
        useEffect(() => {
          setImageUrl(imageUrl);
          if (alertCode > 0) {
            setAlertMessage(alertMessage);
            setAlertCode(alertCode);
            setShowAlert(showAlert);
          }
        }, [imageUrl]);
      
        // here we set the color values and image values
        useEffect(() => {
          setBackGroundShade(backGroundShade);
          setMenuShade(menuShade);
          setMenuShade2(menuShade2);
          setColorVal(colorVal);
          setImageVal(imageVal);
        }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);

        const toggleMenu = () => {
          setIsMenuVisible(!isMenuVisible);
        }

        const menuHandler = (menuNumber) => {
          setMenuChoice(menuNumber);
          setType(menuNumber);
        }

        const selectedError=AppError[localStorage.getItem('country')||'en']





        async function GetBaseData() {
          console.log('getBaseData')
          setSpinnerLoading(true);
          try {
            const response = await usePostData('getBaseData', { type:getType});
            console.log(response.data[0])
            if(getType===1){
              setPriceListCategories(response.data[1])
            }
          } catch (error) {
            setSpinnerLoading(false);
            setAlertMessage(selectedError[57001]);
            setAlertCode(0);
            setShowAlert(true);
          }

        }

      useEffect(() => {
        if (getType > 0) {
          GetBaseData();
        }
      }, [getType]);


const categoryDataHandler = (categoryid) => {
  setChosenCategory(categoryid)
}

 async function GetPricelistByCategory() {
  setSpinnerLoading(true);
  try {
    const response = await useGetData(`productToPricelist/${chosenCategory}`);
    console.log(response.data[0])
    setSpinnerLoading(false);
    setPriceList(response.data[0]);
  } catch (error) {
    setSpinnerLoading(false);
    setAlertMessage(selectedError[57002]);
    setAlertCode(0);
    setShowAlert(true);
  }
}

useEffect(() => {
  if (chosenCategory > 0) {
    GetPricelistByCategory();
  }
}
, [chosenCategory]);


const mobilemenuHandler = (menuNumber) => {
  setMenuChoice(menuNumber);
  setType(menuNumber);
  setIsMenuVisible(false);
}

const backToHomeHandler = () => {
  setType(0);
  setMenuChoice(0);
}


  return (
<div>
    <MyContext.Provider value={{

      getCountry:getCountry,
      priceListCategories:priceListCategories,
      categoryDataHandler:categoryDataHandler,
      priceList:priceList,
      mpercentage:mpercentage,
      mobilemenuHandler:mobilemenuHandler,
      backToHomeHandler:backToHomeHandler

    }}>

    <div className="wwtotalTop">
      <img src={imageUrl} alt="Bakesoft" className="xgbackgroundImageRecepyNew" />
        {/* <img src={starteryukibyowen} alt="Bakesoft" className="backgroundImage" /> */}
         <div className="wwtotalArea">
         {!mobileIsUsed && (
          <div className="areaATop">
           
         
            <div className="areaAButtons">

              <div className="wwbuttonRow"><button className={menuChoice===1?"areaAButton1":"areaAButton"}
              onClick={()=>menuHandler(1)}
              ><FaListAlt />&nbsp;Pricelist</button></div>
              <div className="wwbuttonRow"><button className={menuChoice===2?"areaAButton1":"areaAButton"}
               onClick={()=>menuHandler(2)}
              ><FaScroll />&nbsp;Recipe scroll</button></div>
              <div className="wwbuttonRow"><button className={menuChoice===3?"areaAButton1":"areaAButton"}
               onClick={()=>menuHandler(3)}
              ><FaSearch />&nbsp;Recipe search</button></div>
              <div className="wwbuttonRow"><button className={menuChoice===4?"areaAButton1":"areaAButton"}
               onClick={()=>menuHandler(4)}
              ><FaBoxOpen />&nbsp;Product search</button></div>
              <div className="wwbuttonRow"><button className={menuChoice===5?"areaAButton1":"areaAButton"}
               onClick={()=>menuHandler(5)}
              ><FaDollarSign />&nbsp;Ingredient price</button></div>
              <div className="wwbuttonRow"><button className={menuChoice===6?"areaAButton1":"areaAButton"}
               onClick={()=>menuHandler(6)}
              ><FaTools />&nbsp;Hardware</button></div>
            
              <div className="wwbuttonRow"><button className={menuChoice===7?"areaAButton1 support-button":"areaAButton support-button"}
               onClick={()=>menuHandler(7)}
              ><FaLifeRing />&nbsp;Support</button></div>

            </div>
            
          </div>
          )}

          <div className="areaBTop">
            <div className="areaBArea">

              {menuChoice === 1 ?<Pricelist /> : null}
              {menuChoice === 2 ? <RecipeScroll /> : null}
              {menuChoice === 3 ? <RecipeSearch /> : null}
              {menuChoice === 4 ? <ProductSearch /> : null}
              {menuChoice === 5 ? <IngredientPrice /> : null}
              {menuChoice === 6 ? <Hardware /> : null}
              {menuChoice === 7 ? <Support /> : null}

            </div>
          </div>


          {isMenuVisible && mobileIsUsed?(
          <div className="menuOverlay">
            <div className="areaAButtons">
              <div className="wwbuttonRow"><button className={menuChoice===1?"areaAButton1":"areaAButton"}
              onClick={()=>mobilemenuHandler(1)}
              ><FaListAlt />&nbsp;Pricelist</button></div>
              <div className="wwbuttonRow"><button className={menuChoice===2?"areaAButton1":"areaAButton"}
              onClick={()=>mobilemenuHandler(2)}
              ><FaScroll />&nbsp;Recipe scroll</button></div>
              <div className="wwbuttonRow"><button className={menuChoice===3?"areaAButton1":"areaAButton"}
              onClick={()=>mobilemenuHandler(3)}
              ><FaSearch />&nbsp;Recipe search</button></div>
              <div className="wwbuttonRow"><button className={menuChoice===4?"areaAButton1":"areaAButton"}
              onClick={()=>mobilemenuHandler(4)}
              ><FaBoxOpen />&nbsp;Product search</button></div>
              <div className="wwbuttonRow"><button className={menuChoice===5?"areaAButton1":"areaAButton"}
              onClick={()=>mobilemenuHandler(5)}
              ><FaDollarSign />&nbsp;Ingredient price</button></div>
              <div className="wwbuttonRow"><button className={menuChoice===6?"areaAButton1":"areaAButton"}
              onClick={()=>mobilemenuHandler(6)}
              ><FaTools />&nbsp;Hardware</button></div>
              <div className="wwbuttonRow"><button className={menuChoice===7?"areaAButton1 support-button":"areaAButton support-button"}
              onClick={()=>mobilemenuHandler(7)}
              ><FaLifeRing />&nbsp;Support</button></div>
            </div>
          </div>
          ):null}

{mobileIsUsed?<Footer toggleMenu={toggleMenu} />:null}




    </div>
   
    </div>
    </MyContext.Provider>
</div>
    )



}    


function Footer({toggleMenu}) {
  let ctx = React.useContext(MyContext);
  return (
    <footer className="wwfooter">
      <button className="wwfooter-button"
      onClick={()=>ctx.backToHomeHandler()}
      ><FaHome /> Home</button>
      <button className="wwfooter-button"
      onClick={toggleMenu}
      ><FaBars />Menu</button>
      <button className="wwfooter-button"><FaPhone /> Contact</button>
    </footer>
  );
}

function Pricelist() {
  const ctx = React.useContext(MyContext);
  let priceListCategories = ctx.priceListCategories;

  return (
<div className="xddpricelistTop">
  <div className="xddpricelistWrapper">
            <select className="xddpricelistSelect" onClick={(e)=>ctx.categoryDataHandler(e.target.value)}>
              <option value="0">Select category</option>
              {priceListCategories.map((data, index) => (
                <option key={index} value={data.kategoriid}>{data.benaemn}</option>
              ))}
            </select>
      </div>

      <div className="xddpricelistWrapper1">
           <div className="xddpricelistHeaderWrapper">
              <div className="xddpricelistHeaderID">ID</div>
              <div className="xddpricelistHeaderName">Name</div>
              <div className="xddpricelistHeaderPrice">Price</div>
              <div className="xddpricelistHeaderMargin">Margin</div>
          </div>
       
              {ctx.priceList.map((data, index) => (
                <div key={index} className="xddpricelistRow">
                  <div className={`xddpricelistID ${index % 2 === 0 ? 'xddpricelistID1' : ''}`}>{data.produktid}</div>
                  <div className={`xddpricelistName ${index % 2 === 0 ? 'xddpricelistName1' : ''}`}>{data.benaemn}</div>
                  <div className={`xddpricelistPrice ${index % 2 === 0 ? 'xddpricelistPrice1' : ''}`}>{data.pris}</div>
                  <div className={`xddpricelistMargin ${index % 2 === 0 ? 'xddpricelistMargin1' : ''}`}>{Math.floor(Math.abs((ctx.mpercentage/100)-(data.totals/data.pris))*100)}%</div>
                </div>
              ))}
              </div>
  </div>

    
    
  );
}

function RecipeScroll() {
  return (
    <div>
      <h1>Recipe Scroll</h1>
    </div>
  );
}

function RecipeSearch() {
  return (
    <div>
      <h1>Recipe Search</h1>
    </div>
  );
}

function ProductSearch() {
  return (
    <div>
      <h1>Product Search</h1>
    </div>
  );
}

function IngredientPrice() {
  return (
    <div>
      <h1>Ingredient Price</h1>
    </div>
  );
}

function Hardware() {
  return (
    <div>
      <h1>Hardware</h1>
    </div>
  );
}

function Support() {
  return (
    <div>
      <h1>Support</h1>
    </div>
  );
}




export {WelcomeImage,Footer,Pricelist,RecipeScroll,RecipeSearch,ProductSearch,IngredientPrice,
  Hardware,Support}


