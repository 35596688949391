import MainStyles from './main_styles'

function useHeaderStyle(getCountry) {
 let val=''
 let menuval=''
  let menuval2=''
  let getColorVal=''
  let textareaHeight=''
  let {generalStyles}=MainStyles(val,menuval,menuval2,getColorVal,textareaHeight,getCountry)

    
const navbarStylesIpad10th={
    navbar: {
      backgroundColor: 'RGB(84, 48, 0)',
      fontFamily: 'Arial',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '151.5vh',
      height: '6.5vh',
      marginBottom: '1px',
      zIndex:'10',
      position: 'relative',
      borderColor: 'grey',
      borderWidth: '1px',
      borderStyle: 'solid',
    },
  navbarLink: {
    float: 'left',
    fontSize: '16px',
    color: 'white',
    textAlign: 'center',
    padding: '14px 16px',
    textDecoration: 'none',
  },
  dropdown: {
    float: 'left',
    overflow: 'hidden',
  },
  dropbtn: {
    fontFamily:generalStyles.fontFamily,
    fontSize: '14px',
    marginTop: '7px',
    backgroundColor:'white',
    color:generalStyles.colorblack,
  },

  dropdownBtn: {
    fontSize: '16px',
    border: 'none',
    outline: 'none',
    color: 'white',
    padding: '14px 16px',
    backgroundColor: 'inherit',
    fontFamily: 'inherit',
    margin: '0',
  },
  navbarLinkHover: {
    backgroundColor: 'rgb(175, 102, 6)',
  },
  dropdownContent: {
    display: 'none',
    position: 'absolute',
    backgroundColor: '#f9f9f9',
    minWidth: '160px',
    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
    zIndex: '10',
  },
  dropdownLink: {
    float: 'none',
    color: 'black',
    padding: '12px 16px',
    textDecoration: 'none',
    display: 'block',
    textAlign: 'left',
  },
  dropdownLinkHover: {
    backgroundColor: '#ddd',
  },
  navbar1: {
    overflow: 'hidden',
    backgroundColor: 'white',
    fontFamily: 'Arial',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  navbar1Link: {
    float: 'left',
    fontSize: '16px',
    color: 'black',
    textAlign: 'center',
    padding: '14px 16px',
    textDecoration: 'none',
    width: '150px',
  },
  dropdown1: {
    float: 'left',
    overflow: 'hidden',
  },
  dropdown1Btn: {
    fontSize: '16px',
    border: 'none',
    outline: 'none',
    color: 'black',
    padding: '14px 16px',
    backgroundColor: 'inherit',
    fontFamily: 'inherit',
    margin: '0',
  },
  navbar1LinkHover: {
    backgroundColor: 'rgb(235, 234, 234)',
  },
  dropdown1Content: {
    display: 'none',
    position: 'absolute',
    backgroundColor: '#f9f9f9',
    minWidth: '160px',
    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
    zIndex: '10',
  },
  dropdown1Link: {
    float: 'none',
    color: 'black',
    padding: '12px 16px',
    textDecoration: 'none',
    display: 'block',
    textAlign: 'left',
  },
  dropdown1LinkHover: {
    backgroundColor: 'rgb(243, 241, 239)',
  },
  loginButton: {
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'right',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    marginTop: '3px',
    marginLeft: '10%',
  },
  signoutButton: {
    marginTop: '0.6rem',
    marginLeft: '0.5rem',
    marginBottom: '3px',
    width: '80px',
    height: '29px',
    borderRadius: '6px',
    backgroundColor: 'RGB(255, 128, 0)',
    color: 'white',
    fontSize: '12px',
    border: 'none',
    cursor: 'pointer',
    transition: '0.3s',
  },
  userPicture: {
    position: 'absolute',
    top: '12px',
    right: '25px',
    borderRadius: '50%',
    width: '45px',
    height: '45px',
    marginBottom:'6px',
  },
  userWelcomeMessage: {
    fontFamily: 'inherit',
    fontSize: '14px',
    marginTop: '1rem',
    marginLeft: '0.2rem',
  },
  linkStyle:{
    fontFamily:generalStyles.fontFamily,
  }

};


const navbarStylesAndroid={
    navbar:{
      backgroundColor: 'RGB(84, 48, 0)',
      fontFamily: 'Arial',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width:'100%',
      height:'6.5vh',
      //marginBottom: '1px',
      zIndex: 2,
      position: 'relative',
      borderColor: 'grey',
      borderWidth: '1px',
      borderStyle: 'solid',
    },
  navbarLink: {
    float: 'left',
    fontSize: '16px',
    color: 'white',
    textAlign: 'center',
    padding: '14px 16px',
    textDecoration: 'none',
  },
  dropdown: {
    float: 'left',
    overflow: 'hidden',
  },
  dropdownBtn: {
    fontSize: '16px',
    border: 'none',
    outline: 'none',
    color: 'white',
    padding: '14px 16px',
    backgroundColor: 'inherit',
    fontFamily: 'inherit',
    margin: '0',
  },
  navbarLinkHover: {
    backgroundColor: 'rgb(175, 102, 6)',
  },
  dropdownContent: {
    display: 'none',
    position: 'absolute',
    backgroundColor: '#f9f9f9',
    minWidth: '160px',
    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
    zIndex: '1',
  },
  dropdownLink: {
    float: 'none',
    color: 'black',
    padding: '12px 16px',
    textDecoration: 'none',
    display: 'block',
    textAlign: 'left',
  },
  dropdownLinkHover: {
    backgroundColor: '#ddd',
  },
  navbar1: {
    overflow: 'hidden',
    backgroundColor: 'white',
    fontFamily: 'Arial',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  navbar1Link: {
    float: 'left',
    fontSize: '16px',
    color: 'black',
    textAlign: 'center',
    padding: '14px 16px',
    textDecoration: 'none',
    width: '150px',
  },
  dropdown1: {
    float: 'left',
    overflow: 'hidden',
  },
  dropdown1Btn: {
    fontSize: '16px',
    border: 'none',
    outline: 'none',
    color: 'black',
    padding: '14px 16px',
    backgroundColor: 'inherit',
    fontFamily: 'inherit',
    margin: '0',
  },
  navbar1LinkHover: {
    backgroundColor: 'rgb(235, 234, 234)',
  },
  dropdown1Content: {
    display: 'none',
    position: 'absolute',
    backgroundColor: '#f9f9f9',
    minWidth: '160px',
    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
    zIndex: '1',
  },
  dropdown1Link: {
    float: 'none',
    color: 'black',
    padding: '12px 16px',
    textDecoration: 'none',
    display: 'block',
    textAlign: 'left',
  },
  dropdown1LinkHover: {
    backgroundColor: 'rgb(243, 241, 239)',
  },
  loginButton: {
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'right',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    marginTop: '3px',
    marginLeft: '10%',
  },
  signoutButton: {
    marginTop: '0.6rem',
    marginLeft: '0.5rem',
    marginBottom: '3px',
    width: '80px',
    height: '29px',
    borderRadius: '6px',
    backgroundColor: 'RGB(255, 128, 0)',
    color: 'white',
    fontSize: '12px',
    border: 'none',
    cursor: 'pointer',
    transition: '0.3s',
  },
  userPicture: {
    borderRadius: '50%',
    width: '2.5rem',
    height: '2.5rem',
    marginTop: '0.25rem',
  },
  userWelcomeMessage: {
    fontFamily: 'inherit',
    fontSize: '14px',
    marginTop: '1rem',
    marginLeft: '0.2rem',
  },
};





const navbarStylesWindows={
  navbar:{
    display: 'grid',
    gridTemplateAreas: 
    "a b c d e f g h i j k",
    gridTemplateColumns:"repeat(11,150px)",
    justifyContent:'space-evenly',
   // justifyItems:'center',
    backgroundColor: 'white',
    fontFamily: 'Arial',
    // display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'center',
    width:'110%',
    height:'6.5vh',
    marginBottom: '2px',
   
    paddingTop:'7px',
    zIndex: 2,
    position: 'relative',
   // borderColor: 'grey',
   // borderWidth: '1px',
   // borderStyle: 'solid',
   boxShadow: '4px 4px 4px 1px rgba(0,0,0,0.2)',
 
  },
navbarLink: {
  float: 'left',
  fontSize: '15px',
  color: 'black',
  textAlign: 'center',
  padding: '14px 16px',
  textDecoration: 'none',
 width:'100px',

},
dropdown: {
  float: 'left',
  overflow: 'hidden',
},
dropbtn: {
  fontFamily:generalStyles.fontFamily,
  fontSize: '17px',
  marginTop: '10px',
  backgroundColor:'white',
  color:generalStyles.colorblack,
  border:'none',
},
dropdownBtn: {
  fontSize: '16px',
  border: 'none',
  outline: 'none',
  color: 'white',
  padding: '14px 16px',
  backgroundColor: 'inherit',
  fontFamily: 'inherit',
  margin: '0',
},
navbarLinkHover: {
  backgroundColor: 'rgb(175, 102, 6)',
},
dropdownContent: {
  display: 'none',
  position: 'absolute',
  backgroundColor: '#f9f9f9',
  minWidth: '160px',
  boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
  zIndex: '1',
},
dropdownLink: {
  float: 'none',
  color: 'black',
  padding: '12px 16px',
  textDecoration: 'none',
  display: 'block',
  textAlign: 'left',
},
dropdownLinkHover: {
  backgroundColor: '#ddd',
},
navbar1: {
  overflow: 'hidden',
  backgroundColor: 'white',
  fontFamily: 'Arial',
  width: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
},
navbar1Link: {
  float: 'left',
  fontSize: '16px',
  color: 'black',
  textAlign: 'center',
  padding: '14px 16px',
  textDecoration: 'none',
  width: '150px',
},
dropdown1: {
  float: 'left',
  overflow: 'hidden',
},
dropdown1Btn: {
  fontSize: '16px',
  border: 'none',
  outline: 'none',
  color: 'black',
  padding: '14px 16px',
  backgroundColor: 'inherit',
  fontFamily: 'inherit',
  margin: '0',
},
navbar1LinkHover: {
  backgroundColor: 'rgb(235, 234, 234)',
},
dropdown1Content: {
  display: 'none',
  position: 'absolute',
  backgroundColor: '#f9f9f9',
  minWidth: '160px',
  boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
  zIndex: '1',
},
dropdown1Link: {
  float: 'none',
  color: 'black',
  padding: '12px 16px',
  textDecoration: 'none',
  display: 'block',
  textAlign: 'left',
},
dropdown1LinkHover: {
  backgroundColor: 'rgb(243, 241, 239)',
},
loginButton: {
  width: '20%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'right',
  flexWrap: 'wrap',
  alignItems: 'flex-end',
  marginTop: '3px',
  marginLeft: '10%',
},
signoutButton: {
  marginTop: '0.6rem',
  marginLeft: '0.5rem',
  marginBottom: '3px',
  width: '80px',
  height: '29px',
  borderRadius: '6px',
  backgroundColor: 'RGB(255, 128, 0)',
  color: 'white',
  fontSize: '12px',
  border: 'none',
  cursor: 'pointer',
  transition: '0.3s',
},
userPicture: {
  borderRadius: '50%',
  width: '40px',
  height: '40px',
 // marginBottom:'3px',
 marginTop:'1px',
 border:'1px solid',
 borderColor:generalStyles.datalistBorderColor,
 outline:'none',
},
userWelcomeMessage: {
  fontFamily: 'inherit',
  fontSize: '14px',
  marginTop: '1rem',
  marginLeft: '0.2rem',
},
};

return {navbarStylesIpad10th,navbarStylesAndroid,navbarStylesWindows };
}

export default useHeaderStyle