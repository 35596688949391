import React,{useEffect,useState} from 'react';

import { createRoot } from 'react-dom/client';
import Layout from './Layout';

export default function Index(){

  




  return(


    <Layout />


  )

}

const root = createRoot(document.getElementById('root'));
root.render(<Index />);

