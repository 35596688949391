
    import React, { useEffect,useState } from 'react'
    import {useGetData,usePostData,usePutData} from '../hooks/useGetData'
    import { RotatingLines } from  'react-loader-spinner'
    import { MdDoneOutline } from 'react-icons/md';
    import Alert from '../functions/appAlert';
    import useReportErr from '../hooks/useReportErr';
    import {RiDeleteBackLine} from 'react-icons/ri'
    import{BiMessageSquareAdd} from 'react-icons/bi'
    import './CreateRecepyNew3.css'
    import alertBells from '../audio/alertbells.mp3'
    import useFetchImageUrl from '../hooks/useFetchImageUrl';
    import usePageSettings from '../hooks/usePageSettings';
    import {useNavigate} from 'react-router-dom';
    import { IoMdClose } from "react-icons/io";
    import languages from '../AppLanguage/AppLanguage';
    import AppError from '../AppLanguage/AppError';
    import YesNoAlert from '../functions/YesNoAlert'
    import{AiOutlinePlusCircle} from 'react-icons/ai'
    import{AiOutlineMinusCircle} from 'react-icons/ai'
    import { useParams } from 'react-router-dom';
    import displayGadgetToSmall from '../functions/displayGadgetToSmall'
   


    export default function CreateRecepyNew2() {
      const { recipeName } = useParams();


    const [getMessage,setMessage]=useState(recipeName||'')
    const[getAiResponse,setAiResponse]=useState([])
    const[getpureResponse,setpureResponse]=useState([])
    const[checkVal,setcheckVal]=useState(0)
    const [getai,setAi]=useState([{}])
    const [IngredData,setIngredData]=useState([{}])
    const [RecepyData,setRecepyData]=useState()
    const[getArrangedData,setArrangedData]=useState([{}])
    const [getIngredCategories,setIngredCategories]=useState([{}])
    const [getSpinnerLoading, setSpinnerLoading] = useState(false)
    const[getButtonKlicked,setButtonKlicked]=useState(false)
    const[showRecepyName,setshowRecepyName]=useState(false)
    const[showRecepy,setshowRecepy]=useState(false)
    const[showSaveRecepyButton,setshowSaveRecepyButton]=useState(false)
    const[expandedRow,setExpandedRow]=useState(null)
    const[chosenIngredid,setchosenIngredid]=useState(0)
    const[showIngredComment,setshowIngredComment]=useState(false)
    const[getIngredComments,setIngredComments]=useState([{}])
    const[getnewIngredientName,setnewIngredientName]=useState('')
    const[getChosenIngredientName,setChosenIngredientName]=useState('')
    const[showAddIngredient,setshowAddIngredient]=useState(false)
    const[getKgPrice,setKgPrice]=useState()
    const[getIngredCategory,setIngredCategory]=useState(0)
    const[getIngredSupplier,setIngredSupplier]=useState([{}])
    const[chosenSupplier,setchosenSupplier]=useState(0)
    const[getNewRecepName,setNewRecepName]=useState('')
    const[getchosenCategory,setchosenCategory]=useState(0) 
    const[getRecepyCategories,setRecepyCategories]=useState([{}])// this is to get the categories for the recepy
    const[getSpinnnerLoadingMessage,setSpinnerLoadingMessage]=useState(false)
    const[getnutrientdata,setnutrientdata]=useState([{}])
    const[gettranslatedName,settranslatedName]=useState('')
    const[getReceivedDataStatus,setReceivedDataStatus]=useState(false)// this is to check if we have received data from the api
    const[getnutritionData,setnutritionData]=useState([{}]) // this is the data from the nutrition api
    const[getIngredNutrients,setIngredNutrients]=useState([{}]) // theese are our own data from the database, no api call
    
    const [getnewDescription,setnewDescription]=useState('') // this is the description from the user
    const[getnewDescriptionSuggestion,setnewDescriptionSuggestion]=useState({})// this is the suggestion from the api
    const [getShowAlert,setShowAlert] = useState(false);
    const [getAlertMessage,setAlertMessage] = useState({});
    const[getAlertCode,setAlertCode]=useState(0)
    const[getOpenSaveMenu,setOpenSaveMenu]=useState(false)
    const[getShowDoneMessage,setShowDoneMessage]=useState(false)
    const[getBackGroundShade,setBackGroundShade]=useState(0.55)
    const[getMenuShade,setMenuShade]=useState(0.95)
    const[getMenuShade2,setMenuShade2]=useState(0.75)
    const[getRenderKey,setRenderKey]=useState(0)
    const[getInstructions,setInstructions]=useState('')
    const [getColorVal,setColorVal]=useState('')
    const [getImageVal,setImageVal]=useState('')
    const [getImageUrl,setImageUrl]=useState('')
    const pageid=8
    const [logtime]=useState(5000)
    const [getFuriganaIngredName,setFuriganaIngredName]=useState('')
    const[getFuriganaRecipeName,setFuriganaRecipeName]=useState('')
    const[getTrackedId,setTrackedId]=useState(0)// id for the tracking of the AI search
    const[getShowYesNoAlert,setShowYesNoAlert]=useState(false)
    const[getYesNoAlertMessage,setYesNoAlertMessage]=useState('')
    const[getchosenIngredientName,setchosenIngredientName]=useState('')
    const[getTitle,setTitle]=useState('')

  
    const [windowWidth,setWindowWith]=useState(0) // this is the window width
    const [windowHeight,setWindowHeight]=useState(0)  // this is the window height
    const [getMobileIsUsed,setMobileIsUsed]=useState(false) // this is the window height
    const[getCountry,setCountry]=useState('se') // this is the country
    const[getAIAlert,setAIAlert]=useState(false) // this is the window height

    //alert(windowHeight)
    useEffect(()=>{
      let country = localStorage.getItem('country')||'en' // this is the country code
      setCountry(country)
    },[])

           // if language is japanese we use the font Zen Old Mincho
           let newFontFamily=''
           if(getCountry==='ja'){
           newFontFamily= "'Zen Old Mincho', Arial, Helvetica, sans-serif"
           }else{
           newFontFamily='Arial, Helvetica, sans-serif'
           }
           document.documentElement.style.setProperty('--font-family', newFontFamily);
       
       
   
    const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal } = usePageSettings(pageid);
    const { imageUrl, alertMessage, alertCode, showAlert } = useFetchImageUrl(getImageVal); // runs at mount, gives ut the image url and fetch image from server

    const navigate = useNavigate();
//alert(windowWidth)

    useEffect(() => {
      function setSize() {
        setWindowWith(window.innerWidth);
        setWindowHeight(window.innerHeight);
      
        if(window.innerWidth < 600){
    
          setMobileIsUsed(true)
        } 
    
      }
  
    
      // Call setSize initially to set the correct state
      setSize();
    
      // Add the event listener
      window.addEventListener('resize', setSize);
    
      // Return a cleanup function to remove the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', setSize);
      };
    }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]); // Dependencies
  
    function rgbToRgba(rgb, alpha) {
      return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
    }
    
  //--bakcground shade settings
  let rgbaVal
  let rgbaVal2
  let val
  
  if(getColorVal && getColorVal.length > 0){
    let rgb = getColorVal;
    let alpha = getMenuShade; // replace with your alpha value
    let alpha2 = getMenuShade2; // replace with your alpha value
    rgbaVal = rgbToRgba(rgb, alpha);
    rgbaVal2 = rgbToRgba(rgb, alpha2);
    val=`rgba(255, 255, 255, ${getBackGroundShade})`
  
    }else{
      rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
      rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
      val=`rgba(255, 255, 255, ${getBackGroundShade})`
    }
  //---
  
  useEffect(() => {
      let root = document.documentElement;
      root.style.setProperty('--rgbaVal', rgbaVal);
      root.style.setProperty('--rgbaVal2', rgbaVal2);
      root.style.setProperty('--val',val);
      root.style.setProperty('--windowHeight',windowHeight+'px');
  
      
    }, [getBackGroundShade,getMenuShade,getMenuShade2]);
  



    // language settings
    const selectedCountry = languages[getCountry];

     let title=''
    if(getMobileIsUsed===false){
      title=selectedCountry.createAirecepy?.title
    }else{
      title=selectedCountry.createAirecepy?.titleMobile
    }

    // aialertTitle={aialertTitle}                       
    // aialertMessage={aialertMessage}
    // aialertMessage2={aialertMessage2}
    const {
      aialertTitle,
      aialertMessage,
      aialertMessage2,
      aialertButtonText
    }
    = selectedCountry.aialert || {};
 
const {
  searchInputLegend,
  sendButtonText,
  sendButtonTextClicked,
  recpyArrivedButtonHeaderText,
  listHeaderId,
  listHeaderIngrediens,
  listHeaderMangd,
  listHeaderSum,
  listHeaderKcals,
  listHeaderBakesoftMatch,
  listHeaderBakesoftMatchMobile,
  titleAddIngredient,
  titleDeleteFromRecipe,
  footerTotalt,
  saveNewIngredientHeader,
  saveIngredientBoxName,
  saveIngredientBoxCategory,
  saveIngredientBoxpPrice,
  saveIngredientBoxSaveButtonText,
  saveIngredientPricePlaceHolder,
  titleAdjustBackground,
  selectAddIngredientFirstOption,
  saveRecepyBoxRecepyNameTitle,
  saveRecepyBoxRecepyCategoryFirstOption,
  saveRecepyBoxSaveRecepyButtonText,
  openSaveRecepyBoxButtonText,
  descriptionHeader,
  descriptionCharacterLength,
  ingredientBoxCommentTitle,
  ingredientBoxPlusButtonText,
  titleIngredientBoxPlusButton,
  ingredientBoxMinusButtonText,
  titleIngredientBoxMinusButton,
  selectIngredientFirstOption,
  ailastSearchMessage,
  deleteingredientfromAiRecepy,
  saveRecepyTitle

} = selectedCountry.createAirecepy || {};

 // error messages
 const selectedError=AppError[localStorage.getItem('country')||'en']
  
 const{
  recepyCreated,
  btnYes,
  btnNo,
  yesnodeleteIngredient,
  fetchingDataInformation,
  recipeCanNotBeSaved,
  aiRecipeSearchVoid,
 }=selectedCountry.confirmationMessages||{}


      useEffect(() => {
        GetSettings();
      }, [GetSettings]);

      // we set the image url here
      useEffect(() => {
        setImageUrl(imageUrl);
        if (alertCode > 0) {
          setAlertMessage(alertMessage);
          setAlertCode(alertCode);
          setShowAlert(showAlert);
        }
      }, [imageUrl]);
    
      // here we set the color values and image values
      useEffect(() => {
        setBackGroundShade(backGroundShade);
        setMenuShade(menuShade);
        setMenuShade2(menuShade2);
        setColorVal(colorVal);
        setImageVal(imageVal);
      }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);






    
    useEffect(()=>{
      async function GetAllIngredData(){
        try{
          let response= await useGetData('getallingredientsAndLatestPrice')
           setIngredData(response.data[0]) // we set the data here
           setIngredCategories(response.data[1]) 
           setIngredSupplier(response.data[2])
           setRecepyCategories(response.data[3])
           setIngredNutrients(response.data[4])
        
        }catch(error){
          setAlertCode(17001)
          setAlertMessage(selectedError[17001])
          setSpinnerLoading(false)
          setShowAlert(true)
 
        }
      }
      GetAllIngredData()
    },[])
    
         
    const RunReportErrHandler = async (errCode) => {
      setSpinnerLoading(true);
      await useReportErr('ErrReport',{errCode});
      setSpinnerLoading(false);
    };
    
    useEffect(() => {
      if (getAlertCode > 0) {
        RunReportErrHandler(getAlertCode);
      }
    }, [getAlertCode]);
    
    const handleCloseAlert = () => {
      setShowAlert(false);
    };

    const handleCloseAlertYes = () => {
      setShowYesNoAlert(false);
      deleteIngredientHandler(getchosenIngredientName,chosenIngredid)
    };
    const handleCloseAlertNo = () => {
      setShowYesNoAlert(false);
    };
    
    
    const SendmessageHandler=async()=>{
        setAiResponse([])
        setpureResponse([])
        setRecepyData([])
        setRecepyData.instructions=''
        setcheckVal(0)
        setSpinnerLoading(true)
        setButtonKlicked(true)
        setshowRecepy(false)
        setshowRecepyName(false)
        setshowSaveRecepyButton(false)
        setshowIngredComment(false)
        setIngredComments([])
        setnewDescriptionSuggestion({})
        setReceivedDataStatus(false)
        setnutritionData([])
        setnewDescription('')
        setTrackedId(0)

    
        
          //here we receive the data from ai 
            let response= await useGetData(`createrecepyWithAi/${getMessage}`)
           if(response.status===201){
            setAlertCode(0)
            setAlertMessage(aiRecipeSearchVoid)
            setShowAlert(true)
            setSpinnerLoading(false)
            return
           }
           // here we receive back a trackid from the database, we need to update this trackid
           // if the response was successful
      
           setTrackedId(response.data.lastInsertedId)
           // here i want to save the response to localstorage
                setpureResponse(response.data.message.content)
      
                localStorage.setItem('airesponse',response.data.message.content)
                let myval=JSON.parse(response.data.message.content)
                // i set the state here for the instructions
                let instructions=myval.instructions
            
               // setInstructions(instructions) 

                setTitle(myval.title)

          //     let Test_instructions = '';
          // if (Array.isArray(myval.instructions)) {
          //     Test_instructions = myval.instructions.join(' ');
          //     Test_instructions += '\n' + new Date().toISOString(); // Add timestamp on a new line
          // } else {
          //     console.error('myval.instructions is not an array');
          // }
          // console.log(Test_instructions)
          // setInstructions(Test_instructions);
                
               
// let Test_instructions = '';
// if (Array.isArray(myval.instructions)) {
//     Test_instructions = myval.instructions.map((instruction, index) => `${index + 1}. ${instruction}`).join('\n');
//     Test_instructions += '\n' + new Date().toISOString().slice(0, 10);
//     setInstructions(Test_instructions);
//   } else {
//     console.error('myval.instructions is not an array');
//     setInstructions(myval.instructions);
// }
let Test_instructions = '';
if (Array.isArray(myval.instructions)) {
    Test_instructions = myval.instructions.map((instruction, index) => {
        // Check if instruction starts with 'number.'
        const startsWithNumber = /^\d+\./.test(instruction);
        // If it does, return the instruction as is, otherwise add the index
        return startsWithNumber ? instruction : `${index + 1}. ${instruction}`;
    }).join('\n');
    Test_instructions += '\n' + new Date().toString().split('GMT')[0];
    setInstructions(Test_instructions);
} else {
    console.error('myval.instructions is not an array');
    setInstructions(myval.instructions);
}


              
          
                //we map and try to match the aidata with our ingredient data in our database
                try {
                  myval.ingredients = myval.ingredients.map((data) => {
                    let ingredid = IngredData.find(ingred => ingred.benaemn.toLowerCase() === data.ingredientName.toLowerCase());
                    if (ingredid) {
                      return {
                        ...data,
                        ingredid: ingredid.ingredid,
                        pris: ingredid.pris,
                        amountInGram: parseInt(data.amountInGram),
                        totalcost: ingredid.pris * data.amountInGram / 1000,
                      };
                    } else {
                      return {
                        ...data,
                        ingredid: 0,
                        pris: 0,
                      };
                    }
                  });
                
                  setSpinnerLoading(false);
                  let audio = new Audio(alertBells);
               
                  audio.play();
                } catch (error) {
                  setAlertCode(17002);
                  setAlertMessage(selectedError[17002])
                  setShowAlert(false);
                  setAIAlert(true)
                  setSpinnerLoading(false);

                  
                }
    
    
                // before we set the state we need to check with data we have in the nutrient array
                // we will check if we have a match with the ingredid and the unit='kcal'
                // if we have a match we will add the value to the object
                myval.ingredients.map((data,index)=>{
                  let nutrientdata=getIngredNutrients.find(nutrient=>nutrient.ingredid===data.ingredid && nutrient.unit==='kcal')
                  if(nutrientdata){   // if we have nutrientdata we will add the value to the object
                    myval.ingredients[index].kaloriekoeff=nutrientdata.quantity/100 // to get the value per gram
                  }else{ // if we do not have nutrientdata we will use the values from the api
                    myval.ingredients[index].kaloriekoeff=myval.ingredients[index].calories/data.amountInGram
                  }
                })
    
                
            
                //setting the matched data to our state
                setRecepyData(myval)
                setAiResponse(myval)
                setcheckVal(1)
                setSpinnerLoading(false)
                setButtonKlicked(false)
                setshowRecepyName(true)
                //here i also want to check if all ingredients have a id, if all have a id i will set the state to true
                //and show the button to save the recepy
                let checkAllIngredientsHaveId=myval.ingredients.every(data=>data.ingredid>0)
                if(checkAllIngredientsHaveId===true){
                  setChosenIngredientName('*')
                  setOpenSaveMenu(true)
                  //setshowSaveRecepyButton(true)
                }
       
    
    }
    
    const setShowRecepyHandler=()=>{
        if(showRecepy===true){
            setshowRecepy(false)
        }else{
      setshowRecepy(true)
    }
    }
    
    const ingredientHandler=(ingredient,noIdName)=>{
      setshowAddIngredient(false)
      setSpinnerLoading(true)
    
      try{
     // here we will copy the array RecepyData and change the value of the ingredientid
      let myNewObjArr=[...RecepyData.ingredients]
      //since we have no id  in the RecepyData we will search a match for the name and get the id
      let ingredid=IngredData.find(data=>data.ingredid==ingredient)
      //we will find the index of the ingredient we want to change
      let newIndex=myNewObjArr.findIndex(data=>data.ingredientName===noIdName)
    
      //we will change the value of the ingredientid
      myNewObjArr[newIndex].ingredid=ingredid.ingredid
      //here i need to add the ingredientname 
      myNewObjArr[newIndex].ingredientName=ingredid.benaemn
      myNewObjArr[newIndex].pris=ingredid.pris
      myNewObjArr[newIndex].totalcost=ingredid.pris*myNewObjArr[newIndex].amountInGram/1000
      //we will set the new array to our state
     
     
      // here i want to check if all ingredients have a id, if all have a id i will set the state to true
      //and show the button to save the recepy
      let checkAllIngredientsHaveId=myNewObjArr.every(data=>data.ingredid>0)
      if(checkAllIngredientsHaveId===true){
       // setshowSaveRecepyButtonIN(true)
       setChosenIngredientName('*')  // trigger the save menu to open 
       setOpenSaveMenu(true)
      
      }
    
    
      //copy recepydata
      let myNewObjArr2={...RecepyData}
      //change the value of the ingredients object
      myNewObjArr2.ingredients=myNewObjArr
    
      //set the new array to our state
    setRecepyData(myNewObjArr2)
    setSpinnerLoading(false)
      }catch(error){
        setAlertCode(17003)
        setAlertMessage(selectedError[17003])
        setSpinnerLoading(false)
        setShowAlert(true)
       
      } 
    
    }
    
    const ingredCommentsHandler=(comment)=>{
      //here we will copy the array RecepyData and add the comment to the ingredient
      let myNewObjArr={...RecepyData}
      //we will find the index of the ingredient we want to change
      let newIndex=myNewObjArr.ingredients.findIndex(data=>data.ingredid===chosenIngredid)
      // we will add the comment to the ingredient
      myNewObjArr.ingredients[newIndex].comment=comment
      //we will set the new array to our state
      setRecepyData(myNewObjArr)
    
     
    }
    
    const handleRowClickIndex = (index,ingredid) => {// we get the index of the row and the ingredid
      setchosenIngredid(ingredid) // here we set the ingredid to our state
      setReceivedDataStatus(false) // here we reset the received data status
      setnutritionData([]) // here we reset the nutrition data
      setshowIngredComment(true) // here we open the component
      setOpenSaveMenu(false) // here we close the save menu
      setchosenCategory(0) // here we reset the chosen category
      
      if (expandedRow === index) { // here we check if the index is the same as the expanded row
        setExpandedRow(null);
      } else {
        setExpandedRow(index);
      }
    };
    const deleteIngredientChoice=(ingredientName,ingredid)=>{
      setchosenIngredid(ingredid)
      setchosenIngredientName(ingredientName)
      setShowYesNoAlert(true)
      setYesNoAlertMessage(selectedCountry.createAirecepy?.deleteIngredientAlert)
    }

    const deleteIngredientHandler=(ingredientName,ingredid)=>{
     
      // here i want to delete the ingredient from the array
      //copy recepydata
      let myNewObjArr={...RecepyData}
      //we might have a ingredid that is 0, if so we need to find the index of the ingredient we want to delete
      //by using the ingredientname
      if(ingredid===0||ingredid===undefined){
        let newIndex=myNewObjArr.ingredients.findIndex(data=>data.ingredientName===ingredientName)
        //we will delete the ingredient from the array
        myNewObjArr.ingredients.splice(newIndex,1)
      }else{
      let newIndex=myNewObjArr.ingredients.findIndex(data=>data.ingredid===ingredid)
      //we will delete the ingredient from the array
      myNewObjArr.ingredients.splice(newIndex,1)
      }
      //we will set the new array to our state
      setRecepyData(myNewObjArr)
    
      let checkAllIngredientsHaveId=myNewObjArr.ingredients.every(data=>data.ingredid>0)
      if(checkAllIngredientsHaveId===true){
       // setshowSaveRecepyButton(true)
       setChosenIngredientName('*')  // trigger the save menu to open
       setOpenSaveMenu(true)
      }
      setshowAddIngredient(false)
    }
    
    const addIngredientsHandler=(ingredientName)=>{
     // toggle the showAddIngredient
      setshowAddIngredient(true)
      setKgPrice(0)
      setIngredCategory(0)
      setChosenIngredientName(ingredientName)
      // if(showAddIngredient===false){
      //   setKgPrice(0)
      //   setIngredCategory(0)
      //   setshowAddIngredient(true)
  
      // }else if(showAddIngredient===true){
      //   setKgPrice(0)
      //   setIngredCategory(0)
      //   setshowAddIngredient(false)
    
      // }

    }
    
    const addnewIngredientsHandler=()=>{
      setFuriganaIngredName('')// we reset the furigana name
     
    //  here i will add the new ingredient to the database
     let newIngredientName=getnewIngredientName
     // here i want to check if the new ingredient name is empty
     //if its empty we will take the value from the chosen ingredient name
      if(newIngredientName.length===0){
        newIngredientName=getChosenIngredientName
      }
    
    
      let newIngredientPrice=getKgPrice
      let newIngredientCategory=getIngredCategory
      //we do no check here
      async function AddIngredientToDatabase(){
        setSpinnerLoading(true)
        // we delete the funtion for supplier we set to 99
        let chosenSupplier=1
        if(getnewIngredientName.length===0){
          newIngredientName=getChosenIngredientName
        }else{
          newIngredientName=getnewIngredientName
        }

        try{
          //let response = await usePostData(`addnewAIingredient`,{newIngredientName,newIngredientPrice,newIngredientCategory})
          let response = await usePostData(`addnewAIingredient`,{newIngredientName,newIngredientPrice,newIngredientCategory,chosenSupplier,getFuriganaIngredName})
          
       //   let response = await useGetData(`addnewAIingredient/${newIngredientName}/${newIngredientPrice}/${newIngredientCategory}/${chosenSupplier}`)
       
          let newingredid=response.data.ingredid
        
        
            // we will add the new ingredient to the array
            let myNewObjArr={...RecepyData}
            // we will not push, we will find the index of the ingredient we want to change
            // here i need to know if we use the new ingredient name or the chosen ingredient name
            let newIndex
            if(getnewIngredientName.length===0){
            newIndex=myNewObjArr.ingredients.findIndex(data=>data.ingredientName===getChosenIngredientName)
            }else{
            newIndex=myNewObjArr.ingredients.findIndex(data=>data.ingredientName===getnewIngredientName)
            }
            //we will change the value of the ingredientid
            myNewObjArr.ingredients[newIndex].ingredid=newingredid
            myNewObjArr.ingredients[newIndex].pris=newIngredientPrice
            myNewObjArr.ingredients[newIndex].totalcost=newIngredientPrice*myNewObjArr.ingredients[newIndex].amountInGram/1000
            //we will set the new array to our state
            setRecepyData(myNewObjArr)
          
            setshowAddIngredient(false)
            // here i want to check if all ingredients have a id, if all have a id i will set the state to true
            //and show the button to save the recepy
            let checkAllIngredientsHaveId=myNewObjArr.ingredients.every(data=>data.ingredid>0)
            if(checkAllIngredientsHaveId===true){
              setshowSaveRecepyButton(true)
            }
    
            //here i want to update the ingredient object setIngredData
            let myNewObjArr2=[...IngredData]
            //we will add the new ingredient to the array
            if(getnewIngredientName.length===0){
            myNewObjArr2.push({ingredid:newingredid,benaemn:getChosenIngredientName,pris:newIngredientPrice})
            }else{
            myNewObjArr2.push({ingredid:newingredid,benaemn:getnewIngredientName,pris:newIngredientPrice})
            }
            //we will set the new array to our state
            setIngredData(myNewObjArr2)
            setSpinnerLoading(false)
            // clean up
            setnewIngredientName('')
            setIngredCategory(0)
            setKgPrice(0)
            setchosenSupplier(0)
            setChosenIngredientName('')
            setshowAddIngredient(false)
    
            
        }catch(error){
          setAlertCode(17004)
          setAlertMessage(selectedError[17004])
          setSpinnerLoading(false)
          setShowAlert(true)
      
        }
    }
    
    
      AddIngredientToDatabase()
    }
    const kgPriceHandler=(price)=>{
      setKgPrice(price)
    }
    const addIngredientCategory=(categoryid)=>{
      setIngredCategory(categoryid)
    
    }
    
    const levHandler=(supplierid)=>{
      setchosenSupplier(supplierid)
    }
    
    const openSaveMenuHandler=()=>{
    //toggle the openSaveMenu
    if(getOpenSaveMenu===true){
      setOpenSaveMenu(false)
      setChosenIngredientName('')
    }else{
      setOpenSaveMenu(true)
      setChosenIngredientName('*')// we make the container smaller
    }
    }
    
    const SaveNewRecepyHandler=async()=>{
  
    //here i want to copy the RecepyData object, and encode it to base64 and to send it to the database
    setSpinnerLoading(true)
    //we will copy the RecepyData object
    let myNewObjArr={...RecepyData}
  

    // here i neeed to add the chosen category to the object
    myNewObjArr.categoryid={["categoryid"]:getchosenCategory}
    myNewObjArr.instructions=getInstructions
    //let myNewObjArr2=JSON.stringify(myNewObjArr)
    //const compressedData = pako.deflate(myNewObjArr2, { to: 'string' });
    //let decodedata=btoa(compressedData)
    // console.log(decodedata)

  

    
    try{
      let response=await usePostData(`saveNewAIRecepy`,{myNewObjArr,getFuriganaRecipeName,getTrackedId})
      if(response.status===200){
      setAlertCode(0)
      setAlertMessage(recepyCreated)
      setShowAlert(true)
      setSpinnerLoading(false)
      setNewRecepName('')
      setshowRecepyName(false)
      setshowRecepy(false)
      setshowSaveRecepyButton(false)
      setRecepyData([])
      setIngredComments([])
      setnewIngredientName('')
      setChosenIngredientName('')
      setKgPrice(0)
      setIngredCategory(0)
      setchosenSupplier(0)
      setOpenSaveMenu(false)
      //setchosenCategory(0)
      setShowDoneMessage(true)
      setFuriganaIngredName('')// we reset the furigana name
      setFuriganaRecipeName('')// we reset the furigana name
      // here we will reset the recipe saved in the local storage, delete the airesponse
      localStorage.removeItem('airesponse')
      
      } else if(response.status===201){
        setAlertCode(0)
        setAlertMessage(recipeCanNotBeSaved)
        setShowAlert(true)
        setSpinnerLoading(false)
      }
    } catch(error){
      setAlertCode(17005)
      setAlertMessage(selectedError[17005])
      setShowAlert(true)
      setSpinnerLoading(false)

    }
    }
    
    
    
    
    const changeNewRecepyNameHandler=(newRecepyName)=>{
    // setNewRecepName(newRecepyName)
    setTitle(newRecepyName)
    //here i want to change the name of the title
    let myNewObjArr={...RecepyData}
    myNewObjArr.title=newRecepyName
    setRecepyData(myNewObjArr)
    
    }
    
    const chosenCategoryHandler=(categoryid)=>{
      setchosenCategory(categoryid)

    }
    
    const addDbOwnerIngredient=(ingredid)=>{
      // here i want to add a new ingredient to the object RecepyData
      //copy recepydata
      let myNewObjArr={...RecepyData}
      //we will add the new ingredient to the array
      // we have to find the benaemn from the IngredData object
      let benaemn=IngredData.find(data=>data.ingredid==ingredid)
    
      // here we must find the pris from the IngredData object
      let pris=IngredData.find(data=>data.ingredid==ingredid)
      // we will push
    
      myNewObjArr.ingredients.push({ingredid:ingredid,ingredientName:benaemn.benaemn,pris:pris.pris,amountInGram:0,totalcost:0,comment:''})
      //we will set the new array to our state
      setRecepyData(myNewObjArr)
      //here i want to check if all ingredients have a id, if all have a id i will set the state to true
      //and show the button to save the recepy
      let checkAllIngredientsHaveId=myNewObjArr.ingredients.every(data=>data.ingredid>0)
      if(checkAllIngredientsHaveId===true){
        setshowSaveRecepyButton(true)
      }
    }
    
    
    const GetFoodInfoContainer=()=>{
    
    // here we check if we have data in the object getIngredNutrients
    // if we have data in this object we will set the state getnutritionData to the data in this object
    let checkIfWeHaveData=getIngredNutrients.filter(data=>data.ingredid===chosenIngredid)
    //if(checkIfWeHaveData.length>0){
    if  (Array.isArray(checkIfWeHaveData) && checkIfWeHaveData.length > 0){
      
      //here we have to toggle the received data status to true
      if(getReceivedDataStatus===true){
        setnutritionData([])
        setReceivedDataStatus(false)
      }else{
      setnutritionData(checkIfWeHaveData)
      setReceivedDataStatus(true)
    
      }
    
    
    }else{
      // here we need to make a request to the api
      GetFoodInfoHandler()
    }
    }
    
    async function GetFoodInfoHandler(){
    //copy data
    let myNewObjArr={...RecepyData}
    //we will find the index of the ingredient we want to change
    let newIndex=myNewObjArr.ingredients.findIndex(data=>data.ingredid===chosenIngredid)
    //find the name of the ingredient
    let benaemn=myNewObjArr.ingredients[newIndex].ingredientName
    let textToTranslate=benaemn
    let language='en'
    
    // here i need to make a request for translation
    setSpinnerLoading(true)
    try{
      let response=await useGetData(`translate/${language}/${textToTranslate}/${chosenIngredid}`)
      let amountofCalories=response.data.calories
      let totalDailyData=response.data.totalNutrients
      // console.log(response.data.totalNutrients)
      const totalDailyArray = Object.keys(totalDailyData).map((key) => ({
        nutrient: totalDailyData[key].label,
        quantity: totalDailyData[key].quantity,
        unit: totalDailyData[key].unit,
      }));
     setnutritionData(totalDailyArray)
      
      // here we need to check if the amount of calories is undefined
      // this function translates the ingredientname to english but also it check the amount of calories
      //and the calories are the most important so we will check if the amount of calories is a number
      if(isNaN(amountofCalories)){
        setAlertCode(17006)
        setAlertMessage(selectedError[17006])
        setShowAlert(true)
        setSpinnerLoading(false)
        return
      }else{
      //amount of calories are in 100 grams, we will divide by 100 to get the amount of calories per gram
      //first we need to get the dataobejct
      let myNewObjArr2={...RecepyData}
      //we will find the index of the ingredient we want to change
      let newIndex2=myNewObjArr2.ingredients.findIndex(data=>data.ingredid===chosenIngredid)
      // we will change the amount of calories
      // first we need to check the amount of grams that we already have
      let amountInGram=myNewObjArr2.ingredients[newIndex2].amountInGram
      // we will multiply the amount of grams with the amount of calories per gram
      myNewObjArr2.ingredients[newIndex2].calories=amountofCalories*amountInGram/100
      //we will set the new array to our state
      setRecepyData(myNewObjArr2)
      setReceivedDataStatus(true)
      setSpinnerLoading(false)
      }
    
    
    }catch(error){
      setAlertCode(17007)
      setAlertMessage(selectedError[17007])
      setShowAlert(true)
      setSpinnerLoading(false)
    
    }
    }
    
    
    
    
    
    
    
    const changeAmountHandler=(amount)=>{
    if(amount===1){
    // here i want to change the amount of the ingredient i want to decrease by 1
    //copy recepydata
    let myNewObjArr={...RecepyData}
    //we will find the index of the ingredient we want to change
    let newIndex=myNewObjArr.ingredients.findIndex(data=>data.ingredid===chosenIngredid)
    // fist find the amount of the ingredient
    let amountInGram=myNewObjArr.ingredients[newIndex].amountInGram
    // we will decrease the amount by 1
    myNewObjArr.ingredients[newIndex].amountInGram=amountInGram-1
    // we will change the totalcost
    myNewObjArr.ingredients[newIndex].totalcost=myNewObjArr.ingredients[newIndex].pris*(amountInGram-1)/1000
    // here we also need to change the amount of calories
    // first we find the total amount of calories
    let totalCalories=myNewObjArr.ingredients[newIndex].calories
    // to find the number of calories per amount of grams we divide the total calories with the amount of grams
    let caloriesPerGram=totalCalories/amountInGram
    // we will multiply the calories per gram with the new amount of grams
    myNewObjArr.ingredients[newIndex].calories=caloriesPerGram*(amountInGram-1)
    //we will set the new array to our state
    setRecepyData(myNewObjArr)

    }else if(amount===3){
    // here i want to change the amount of the ingredient i want to decrease by 1
    //copy recepydata
    let myNewObjArr={...RecepyData}
    //we will find the index of the ingredient we want to change
    let newIndex=myNewObjArr.ingredients.findIndex(data=>data.ingredid===chosenIngredid)
    // fist find the amount of the ingredient
    let amountInGram=myNewObjArr.ingredients[newIndex].amountInGram
    // we will decrease the amount by 1
    myNewObjArr.ingredients[newIndex].amountInGram=amountInGram-10
    // we will change the totalcost
    myNewObjArr.ingredients[newIndex].totalcost=myNewObjArr.ingredients[newIndex].pris*(amountInGram-10)/1000
    // here we also need to change the amount of calories
    // first we find the total amount of calories
    let totalCalories=myNewObjArr.ingredients[newIndex].calories
    // to find the number of calories per amount of grams we divide the total calories with the amount of grams
    let caloriesPerGram=totalCalories/amountInGram
    // we will multiply the calories per gram with the new amount of grams
    myNewObjArr.ingredients[newIndex].calories=caloriesPerGram*(amountInGram-10)
    //we will set the new array to our state
    setRecepyData(myNewObjArr)

    }else if(amount===2){
      // here i want to change the amount of the ingredient i want to increase by 1
    //copy recepydata
    let myNewObjArr={...RecepyData}
    //we will find the index of the ingredient we want to change
    let newIndex=myNewObjArr.ingredients.findIndex(data=>data.ingredid===chosenIngredid)
    // fist find the amount of the ingredient
    let amountInGram=myNewObjArr.ingredients[newIndex].amountInGram
    // we will increase the amount by 1
    myNewObjArr.ingredients[newIndex].amountInGram=amountInGram+1
    // we will change the totalcost
    myNewObjArr.ingredients[newIndex].totalcost=myNewObjArr.ingredients[newIndex].pris*(amountInGram+1)/1000
    // here we also need to change the amount of calories
    // first we find the total amount of calories
    let totalCalories=myNewObjArr.ingredients[newIndex].calories
    // to find the number of calories per amount of grams we divide the total calories with the amount of grams
    let caloriesPerGram=totalCalories/amountInGram
    // we will multiply the calories per gram with the new amount of grams
    myNewObjArr.ingredients[newIndex].calories=caloriesPerGram*(amountInGram+1)
    //we will set the new array to our state
    setRecepyData(myNewObjArr)
    
    }else if(amount===4){
      // here i want to change the amount of the ingredient i want to increase by 1
    //copy recepydata
    let myNewObjArr={...RecepyData}
    //we will find the index of the ingredient we want to change
    let newIndex=myNewObjArr.ingredients.findIndex(data=>data.ingredid===chosenIngredid)
    // fist find the amount of the ingredient
    let amountInGram=myNewObjArr.ingredients[newIndex].amountInGram
    // we will increase the amount by 1
    myNewObjArr.ingredients[newIndex].amountInGram=amountInGram+10
    // we will change the totalcost
    myNewObjArr.ingredients[newIndex].totalcost=myNewObjArr.ingredients[newIndex].pris*(amountInGram+10)/1000
    // here we also need to change the amount of calories
    // first we find the total amount of calories
    let totalCalories=myNewObjArr.ingredients[newIndex].calories
    // to find the number of calories per amount of grams we divide the total calories with the amount of grams
    let caloriesPerGram=totalCalories/amountInGram
    // we will multiply the calories per gram with the new amount of grams
    myNewObjArr.ingredients[newIndex].calories=caloriesPerGram*(amountInGram+10)
    //we will set the new array to our state
    setRecepyData(myNewObjArr)

 
    }
  }


    const newDescriptionHandler=(newDescription)=>{
   
      setnewDescription(newDescription)
    }
    
    const recepyDescriptionHandler=(recepydescription)=>{
    // here i want to change the description of the recepy
    //we will set the description to our state then when we push for save we will save the description'
  
    setInstructions(recepydescription)

    }
    
    const replaceRecepyDescriptionHandler=()=>{
    //here we will replace the description with the new description
    let myNewObjArr={...RecepyData}
    
    myNewObjArr.instructions=getnewDescriptionSuggestion.content
    setRecepyData(myNewObjArr)
    setnewDescription('')
    setnewDescriptionSuggestion({})
    
    }
    


const setnewIngredientNameHandler=(val)=>{
    setnewIngredientName(val)
    setChosenIngredientName(val)

  // here i need to update the object with the new name object is RecepyData.ingredients.ingredientName
  //copy recepydata
  let myNewObjArr={...RecepyData}
  //we will find the index of the ingredient we want to change
  // we have no ingredid so we find by name, and since when the recepy is given back from the api, name is hold by the 
  // getchosingredientname,if user types another name we will use getnewingredientname
  let newIndex
  if(getnewIngredientName.length===0){
  newIndex=myNewObjArr.ingredients.findIndex(data=>data.ingredientName===getChosenIngredientName)
  }else{
  newIndex=myNewObjArr.ingredients.findIndex(data=>data.ingredientName===getnewIngredientName)
  }
  // we will change the name
  myNewObjArr.ingredients[newIndex].ingredientName=val
  //we will set the new array to our state
  setRecepyData(myNewObjArr)


}


// if the user changges the value on the range control we save the value in the database
async function SetBackGroundShadeHandler(val){
  setBackGroundShade(val)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateBackGroundShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }
  }catch(error){
      setAlertCode(17008)
      setAlertMessage(selectedError[17008])
      setSpinnerLoading(false)
      setShowAlert(true)
    
      return;
    }

}

async function SetmenuColorHandler(val){
  setMenuShade(val)
  setMenuShade2(val-0.20)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateMenuShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }

}catch(error){
  setAlertCode(17009)
  setAlertMessage(selectedError[17009])
  setSpinnerLoading(false)
  setShowAlert(true)

  return;
}
}

const closeSaveContainerHandler=()=>{
  setFuriganaIngredName('') // we reset the furigana name
  setshowAddIngredient(false)
  setKgPrice(0)
  setIngredCategory(0)
  setChosenIngredientName('') // we reset the chosen ingredient name
  setOpenSaveMenu(false)// we close the save menu
}


const setFuriganaNameHandler=(val)=>{
  setFuriganaIngredName(val)
}

const furiganaRecipeNameHandler=(val)=>{
  setFuriganaRecipeName(val)
}



 const getLastSearchDataHandler=()=>{
//   let localdata =JSON.parse(localStorage.getItem('airesponse'))
// // here i want to save the response to localstorage
// setpureResponse(localdata)
// let instructions=localdata.instructions
// setInstructions(instructions)

let localdata = JSON.parse(localStorage.getItem('airesponse'));
setpureResponse(localdata);

if (Array.isArray(localdata.instructions)) {
    let Test_instructions = localdata.instructions.map((instruction, index) => `${index + 1}. ${instruction}`).join('\n');
    Test_instructions += '\n' + new Date().toISOString().slice(0, 10);
    setInstructions(Test_instructions);
} else {
    console.error('localdata.instructions is not an array');
    setInstructions(localdata.instructions);
}


//we map and try to match the aidata with our ingredient data in our database
try {
  localdata.ingredients = localdata.ingredients.map((data) => {
    let ingredid = IngredData.find(ingred => ingred.benaemn.toLowerCase() === data.ingredientName.toLowerCase());
    if (ingredid) {
      return {
        ...data,
        ingredid: ingredid.ingredid,
        pris: ingredid.pris,
        amountInGram: parseInt(data.amountInGram),
        totalcost: ingredid.pris * data.amountInGram / 1000,
      };
    } else {
      return {
        ...data,
        ingredid: 0,
        pris: 0,
      };
    }
  });

  setSpinnerLoading(false);
} catch (error) {
  setAlertCode(17002);
  setAlertMessage(selectedError[17013])
  setShowAlert(true);
  setSpinnerLoading(false);
  
}


// before we set the state we need to check with data we have in the nutrient array
// we will check if we have a match with the ingredid and the unit='kcal'
// if we have a match we will add the value to the object
localdata.ingredients.map((data,index)=>{
  let nutrientdata=getIngredNutrients.find(nutrient=>nutrient.ingredid===data.ingredid && nutrient.unit==='kcal')
  if(nutrientdata){   // if we have nutrientdata we will add the value to the object
    localdata.ingredients[index].kaloriekoeff=nutrientdata.quantity/100 // to get the value per gram
  }else{ // if we do not have nutrientdata we will use the values from the api
    localdata.ingredients[index].kaloriekoeff=localdata.ingredients[index].calories/data.amountInGram
  }
})



//setting the matched data to our state
setRecepyData(localdata)
setAiResponse(localdata)
setcheckVal(1)
setSpinnerLoading(false)
setButtonKlicked(false)
setshowRecepyName(true)
//here i also want to check if all ingredients have a id, if all have a id i will set the state to true
//and show the button to save the recepy
let checkAllIngredientsHaveId=localdata.ingredients.every(data=>data.ingredid>0)
if(checkAllIngredientsHaveId===true){
  //setshowSaveRecepyButton(true)
  setChosenIngredientName('*')  // trigger the save menu to open
  setOpenSaveMenu(true)
}

 }

 const aialertCloseHandler=()=>{
  setAIAlert(false)
 }





  return (
    <>
    {windowWidth<360?displayGadgetToSmall():''}
    <div className={getBackGroundShade<1?"xltotalArea":"xltotalArea1"}>

{getBackGroundShade<1?<img src={imageUrl} alt="Bakesoft" className="xlbackgroundImageRecepyNew" />:''}
<div className="xlheaderArea">
<div className={getBackGroundShade<1?"xlCompName":"xlCompName1"}>{title}</div>

</div>

<div className="xlrangeArea">
<input type="range" min="0.0" max="1" value={getMenuShade||0.2} step="0.10" id={2}  
        title={titleAdjustBackground} className="xlrange2" onChange={(e)=>SetmenuColorHandler(e.target.value)}/>

            
    <input type="range" min="0.0" max="1" value={getBackGroundShade||0.2} step="0.10" id={2}  
     title={titleAdjustBackground} className="xlrange1" onChange={(e)=>SetBackGroundShadeHandler(e.target.value)}/>

</div>


        
    
        <div className="xlareaA">
        <div className="xldatatopWrapper">
        <fieldset className="xlsearchFieldset"><legend className="xlsearchLegend">{searchInputLegend}</legend>
       
    <SendAiMessage getai={getai} setMessage={setMessage} getMessage={getMessage}
    SendmessageHandler={SendmessageHandler} getButtonKlicked={getButtonKlicked}
    sendButtonText={sendButtonText} sendButtonTextClicked={sendButtonTextClicked}
    getLastSearchDataHandler={getLastSearchDataHandler} ailastSearchMessage={ailastSearchMessage}
  
    />

      </fieldset>
      </div>
      {showRecepyName===true?<AiReturnedRecepyName getai={getai} 
  RecepyData={RecepyData} setShowRecepyHandler={setShowRecepyHandler}
  recpyArrivedButtonHeaderText={recpyArrivedButtonHeaderText}
  />:''}

    {showRecepy===true?<GetAiMessage
    checkVal={checkVal} RecepyData={RecepyData} IngredData={IngredData}
    ingredientHandler={ingredientHandler} 
    setIngredComments={setIngredComments} 
    ingredCommentsHandler={ingredCommentsHandler} expandedRow={expandedRow} 
    handleRowClickIndex={handleRowClickIndex} chosenIngredid={chosenIngredid}
    deleteIngredientChoice={deleteIngredientChoice} 
    addIngredientsHandler={addIngredientsHandler} setnewIngredientNameHandler={setnewIngredientNameHandler}
    getnewIngredientName={getnewIngredientName} getIngredCategories={getIngredCategories}
    showAddIngredient={showAddIngredient} addnewIngredientsHandler={addnewIngredientsHandler}
    getChosenIngredientName={getChosenIngredientName} kgPriceHandler={kgPriceHandler}
    getKgPrice={getKgPrice} addIngredientCategory={addIngredientCategory} getIngredCategory={getIngredCategory}
      getIngredSupplier={getIngredSupplier} levHandler={levHandler} addDbOwnerIngredient={addDbOwnerIngredient} 
      changeAmountHandler={changeAmountHandler} getFoodInfoContainer={GetFoodInfoContainer} 
      getReceivedDataStatus={getReceivedDataStatus} getnutritionData={getnutritionData}
      getMobileIsUsed={getMobileIsUsed} closeSaveContainerHandler={closeSaveContainerHandler}
      listHeaderId={listHeaderId}
      listHeaderIngrediens={listHeaderIngrediens}
      listHeaderMangd={listHeaderMangd}
      listHeaderSum={listHeaderSum}
      listHeaderKcals={listHeaderKcals}
      listHeaderBakesoftMatch={listHeaderBakesoftMatch}
      listHeaderBakesoftMatchMobile={listHeaderBakesoftMatchMobile}
      titleAddIngredient={titleAddIngredient}
      titleDeleteFromRecipe={titleDeleteFromRecipe}
      descriptionCharacterLength={descriptionCharacterLength}
      ingredientBoxCommentTitle={ingredientBoxCommentTitle}
      ingredientBoxPlusButtonText={ingredientBoxPlusButtonText}
      titleIngredientBoxPlusButton={titleIngredientBoxPlusButton}
      ingredientBoxMinusButtonText={ingredientBoxMinusButtonText}
      titleIngredientBoxMinusButton={titleIngredientBoxMinusButton}
      selectAddIngredientFirstOption={selectAddIngredientFirstOption}
      selectIngredientFirstOption={selectIngredientFirstOption}
      openSaveRecepyBoxButtonText={openSaveRecepyBoxButtonText}
      footerTotalt={footerTotalt}
      saveIngredientBoxName={saveIngredientBoxName}
      saveIngredientBoxCategory={saveIngredientBoxCategory}
      saveIngredientBoxpPrice={saveIngredientBoxpPrice}
      saveIngredientBoxSaveButtonText={saveIngredientBoxSaveButtonText}
      saveNewIngredientHeader={saveNewIngredientHeader}
      saveIngredientPricePlaceHolder={saveIngredientPricePlaceHolder}
      setFuriganaIngredName={setFuriganaIngredName}
      getFuriganaIngredName={getFuriganaIngredName}
      setFuriganaNameHandler={setFuriganaNameHandler}
      getCountry={getCountry}
      deleteingredientfromAiRecepy={deleteingredientfromAiRecepy}
      showSaveRecepyButton={showSaveRecepyButton}
      showRecepy={showRecepy}
      openSaveMenuHandler={openSaveMenuHandler} getOpenSaveMenu={getOpenSaveMenu}
      getRecepyCategories={getRecepyCategories}
      saveRecepyBoxRecepyNameTitle={saveRecepyBoxRecepyNameTitle}
      saveRecepyBoxRecepyCategoryFirstOption={saveRecepyBoxRecepyCategoryFirstOption}
      saveRecepyBoxSaveRecepyButtonText={saveRecepyBoxSaveRecepyButtonText}
      getTitle={getTitle}
      changeNewRecepyNameHandler={changeNewRecepyNameHandler} getNewRecepName={getNewRecepName}
      chosenCategoryHandler={chosenCategoryHandler} getchosenCategory={getchosenCategory}
      SaveNewRecepyHandler={SaveNewRecepyHandler}
      saveRecepyTitle={saveRecepyTitle}
      />:''}
    {getOpenSaveMenu===true && showRecepy?<RecepyReadyToSave 
    openSaveMenuHandler={openSaveMenuHandler} getOpenSaveMenu={getOpenSaveMenu}
     RecepyData={RecepyData} SaveNewRecepyHandler={SaveNewRecepyHandler} 
     changeNewRecepyNameHandler={changeNewRecepyNameHandler} getNewRecepName={getNewRecepName}
      chosenCategoryHandler={chosenCategoryHandler} getchosenCategory={getchosenCategory}
     getRecepyCategories={getRecepyCategories}
     openSaveRecepyBoxButtonText={openSaveRecepyBoxButtonText}
     saveRecepyBoxRecepyNameTitle={saveRecepyBoxRecepyNameTitle}
     saveRecepyBoxRecepyCategoryFirstOption={saveRecepyBoxRecepyCategoryFirstOption}
     saveRecepyBoxSaveRecepyButtonText={saveRecepyBoxSaveRecepyButtonText}
     getCountry={getCountry}
     furiganaRecipeNameHandler={furiganaRecipeNameHandler}
     getFuriganaRecipeName={getFuriganaRecipeName}
     closeSaveContainerHandler={closeSaveContainerHandler}
     saveRecepyTitle={saveRecepyTitle}
     getTitle={getTitle}
     
     />:''}
   {/* {showRecepy===true?<MatchningKlar getai={getai}/>:''} */}
        </div>
      
                              {/* <div>
        <p className="xlspinner-text1">Vi kan inte hitta data..</p></div>
       */}

        <div className="xlareaB">

  {showRecepy===true?<AiRecepyDescription checkVal={checkVal}
  getInstructions={getInstructions}
  newDescriptionHandler={newDescriptionHandler} recepyDescriptionHandler={recepyDescriptionHandler}
  getnewDescriptionSuggestion={getnewDescriptionSuggestion}
  replaceRecepyDescriptionHandler={replaceRecepyDescriptionHandler}
  descriptionHeader={descriptionHeader}
  descriptionCharacterLength={descriptionCharacterLength}
  />:''}



        </div>
 

        <div className="xlSpinnerContainer">
        <div className="xlSpinner">
          <RotatingLines strokeColor="black" strokeWidth="2" animationDuration="0.75" width="50" visible={getSpinnerLoading}/>
        </div>
        {getSpinnerLoading===true?
        <p className="xlspinner-text">{fetchingDataInformation}</p>
        :''}
        </div>
        {getShowAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}/>:''}
        {getShowYesNoAlert===true?<YesNoAlert message={yesnodeleteIngredient}  onCloseYes={handleCloseAlertYes} onCloseNo={handleCloseAlertNo}
  btnYes={btnYes} btnNo={btnNo}
  />:''}

{/*   
  {getAIAlert===true?<AIAlert aialertTitle={aialertTitle}                       
                              aialertMessage={aialertMessage}
                              aialertMessage2={aialertMessage2}
                              aialertClose={aialertCloseHandler}
                              aialertButtontext={aialertButtonText}
                       />:''} */}
                       {getAIAlert===true?<NoDataMessage aialertTitle={aialertTitle}
                        aialertMessage={aialertMessage}
                        aialertMessage2={aialertMessage2}
                        aialertClose={aialertCloseHandler}
                        aialertButtonText={aialertButtonText}
                        aialertCloseHandler={aialertCloseHandler}
                        getCountry={getCountry}
                        />:''}
  

    </div>
  

    </>
  )
}




function SendAiMessage(props){
    let{getai,SendmessageHandler,setMessage,getButtonKlicked,sendButtonText,sendButtonTextClicked,
      getMessage,getLastSearchDataHandler,ailastSearchMessage
    }=props 
  
    // here we display and get the last ai search fro the localstorage
    let recipeTitle
    // here we add a check if object is not empty
    if(localStorage.getItem('airesponse')===null){
      recipeTitle=''
    }else{

    let lastAiSearch = JSON.parse(localStorage.getItem('airesponse'));
    recipeTitle=lastAiSearch.title
 
    }



    return(
        <><div className="xlmessageContainer">
        <input type="text" className="xltextSend"
          onChange={(e) => setMessage(e.target.value)} value={getMessage} />
        <button className={getButtonKlicked?"xlbuttonSendEnabled":"xlbuttonSend"}
          onClick={() => SendmessageHandler()}
        >{getButtonKlicked ? sendButtonTextClicked : sendButtonText}</button>
      </div>
      <div className="xllastSearch" onClick={getLastSearchDataHandler}>
       {ailastSearchMessage}:</div>
       <div className="xllastSearchtext"  
       onClick={getLastSearchDataHandler}
      
       > 
       {recipeTitle}</div></>
    )
  }
  
  
  
  function GetAiMessage(props){
    let{getai,checkVal,
      RecepyData,IngredData,ingredientHandler,
      deleteIngredientChoice,addIngredientsHandler,getChosenIngredientName,getMobileIsUsed,
      listHeaderIngrediens,listHeaderMangd,listHeaderSum,listHeaderKcals,
      listHeaderBakesoftMatch,listHeaderBakesoftMatchMobile,titleAddIngredient,
      selectIngredientFirstOption,openSaveRecepyBoxButtonText,
     footerTotalt,getCountry,
     deleteingredientfromAiRecepy,
     openSaveMenuHandler, getOpenSaveMenu,
     SaveNewRecepyHandler,
     changeNewRecepyNameHandler, getNewRecepName,
      chosenCategoryHandler, getchosenCategory,
     getRecepyCategories,
     saveRecepyBoxRecepyNameTitle,
     saveRecepyBoxRecepyCategoryFirstOption,
     saveRecepyBoxSaveRecepyButtonText,
     furiganaRecipeNameHandler,
     getFuriganaRecipeName,
     showSaveRecepyButton,
      showRecepy,
      getTitle,
      setFuriganaIngredName,
      setFuriganaNameHandler,
      getFuriganaIngredName,
      saveIngredientPricePlaceHolder,
      saveIngredientBoxSaveButtonText,
      saveNewIngredientHeader,
      saveIngredientBoxCategory,
      saveIngredientBoxpPrice,
      closeSaveContainerHandler,
      saveIngredientBoxName,
      levHandler,
      getIngredCategory,
      addIngredientCategory,
      getIngredSupplier,
      getKgPrice,
      kgPriceHandler,
      addnewIngredientsHandler,
      getIngredCategories,
      setnewIngredientNameHandler,
      getnewIngredientName,
      showAddIngredient,
      saveRecepyTitle
      






      }=props
      
      //so we need to check if the value is a number
    //  let totalCost=RecepyData.ingredients.reduce((a,b)=>a+b.totalcost,0)
    let totalCost = RecepyData.ingredients.reduce((a, b) => a + (Number(b.totalcost) || 0), 0);
      //if the totalcost is undefined we will set it to 0
      if(isNaN(totalCost)){
        totalCost=0
      }else{
      totalCost=totalCost||0
      }
  
     // let totalCalories = RecepyData.ingredients.reduce((a, b) => a + (b.calories || 0), 0);
     let totalCalories = RecepyData.ingredients.reduce((a, b) => a + (Number(b.calories) || 0), 0);
      // Round the total calories to the nearest integer
      totalCalories = Math.round(totalCalories);
  
     // Calculate the total amount
 // let totalAmount = RecepyData.ingredients.reduce((a, b) => a + (b.amountInGram || 0), 0);
 let totalAmount = RecepyData.ingredients.reduce((a, b) => a + (Number(b.amountInGram) || 0), 0);
  // Round the total amount to 2 decimal places
  totalAmount = Math.round(totalAmount * 100) / 100;

  const menuRef = React.useRef(null);
  
    return(
      <div>
        <div className="xldataWrapper">
        <div className="xldataContainer">
      <div className="xlheader">
        <button className="xlheaderButtonBenaemn">{listHeaderIngrediens}</button>
        <button className="xlheaderButtonAmount">{listHeaderMangd}</button>
        <button className="xlheaderButton">{listHeaderSum}</button>
        <button className="xlheaderKcals">{listHeaderKcals}</button>
        
        <button className="xlheaderButtonUpdate">
          {getMobileIsUsed===false?listHeaderBakesoftMatch:listHeaderBakesoftMatchMobile}</button>
          <button className="xlheaderInvisible"></button>
        </div>

  
  
      <div className={getChosenIngredientName.length>0?"xlreceivedContainer1":"xlreceivedContainer"}>
     {checkVal>0?RecepyData.ingredients.map((data,index)=>
    <div key={index} className="xldatarows">
    <button className={index%2===0?"xlinfobuttonBenaemn":"xlinfobuttonBenaemn2"} 
    title={deleteingredientfromAiRecepy}
    
    onDoubleClick={()=>deleteIngredientChoice(data.ingredientName,data.ingredid)}>{String(data.ingredientName).substring(0,30)}</button>
    <button className={index%2===0?"xlinfobuttonAmount":"xlinfobuttonAmount2"}>{data.amountInGram ||0}</button>
    <button className={index%2===0?"xlinfobutton":"xlinfobutton2"}>{String(data.totalcost).substring(0,4)||0}</button>
    <button className={index%2===0?"xlkcalsButton":"xlkcalsButton2"}>{String(Number(data.kaloriekoeff)*Number(data.amountInGram)).substring(0,4)||0}</button>
   <button className={index%2===0?"xlinfobuttonChecked":"xlinfobuttonChecked2"}>{data.ingredid===0?<select className="xlSelect" onChange={(e)=>ingredientHandler(e.target.value,data.ingredientName)}>
        <option value="0" hidden>{selectIngredientFirstOption}&nbsp;!&nbsp;</option>
             {IngredData.map((data,key)=><option key={key}value={data.ingredid}>{data.benaemn}</option>)}
      </select>
  :<MatchningKlar getai={getai} index={index} openSaveRecepyBoxButtonText={openSaveRecepyBoxButtonText}/>} </button>
  
  <button className={index%2===0?"xlinfodeleteButton":"xlinfodeleteButton2"}>
     {data.ingredid===0?<BiMessageSquareAdd onClick={()=>addIngredientsHandler(data.ingredientName)}
     onTouchEnd={()=>addIngredientsHandler(data.ingredientName)}  
     className="xlbiMessageSquareAdd" title={titleAddIngredient}/>:''}
     </button>

{/* 
    <button className={index%2===0?"xlinfodeleteButton":"xlinfodeleteButton2"}>
    &nbsp;<RiDeleteBackLine onClick={()=>deleteIngredientHandler(data.ingredientName,data.ingredid)} className="xlrideleteBackLine"
                       title={titleDeleteFromRecipe}/>
    </button> */}
                       

   </div>
    ):''}
  
  
  <div className="xltotalCostContainer">
  <button className="xltotalCostBenaemn">{footerTotalt}</button>
    <button className="xltotalAmountButton">{Number(totalAmount)}</button>
    <button className="xltotalCostButton">{String(totalCost).substring(0,5)}</button>
    <button className="xltotalCaloriesButton">{totalCalories}</button>
    <button className="xltotalinfobuttonChecked"></button>
    <button className="xltotaldeleteButton"></button>
    
    </div>
    {/* {showRecepy&&getOpenSaveMenu===true?<RecepyReadyToSave
    openSaveMenuHandler={openSaveMenuHandler} getOpenSaveMenu={getOpenSaveMenu}
     RecepyData={RecepyData} SaveNewRecepyHandler={SaveNewRecepyHandler} 
     changeNewRecepyNameHandler={changeNewRecepyNameHandler} getNewRecepName={getNewRecepName}
      chosenCategoryHandler={chosenCategoryHandler} getchosenCategory={getchosenCategory}
     getRecepyCategories={getRecepyCategories}
     openSaveRecepyBoxButtonText={openSaveRecepyBoxButtonText}
     saveRecepyBoxRecepyNameTitle={saveRecepyBoxRecepyNameTitle}
     saveRecepyBoxRecepyCategoryFirstOption={saveRecepyBoxRecepyCategoryFirstOption}
     saveRecepyBoxSaveRecepyButtonText={saveRecepyBoxSaveRecepyButtonText}
     getCountry={getCountry}
     furiganaRecipeNameHandler={furiganaRecipeNameHandler}
     getFuriganaRecipeName={getFuriganaRecipeName}
     getTitle={getTitle}
     closeSaveContainerHandler={closeSaveContainerHandler}
     saveRecepyTitle={saveRecepyTitle}
 
     />:''} */}
    
    
    
    </div></div>
    {/* <div className="xlSelectNewIngredientContainer">
    <select className="xlSelectDbOwnerIngredient" onChange={(e)=>addDbOwnerIngredient(e.target.value)}>
        <option value="0" hidden>&nbsp;&nbsp;{selectAddIngredientFirstOption}&nbsp;!&nbsp;</option>
             {IngredData.map((data,key)=><option key={key}value={data.ingredid}>&nbsp;&nbsp;{data.benaemn}</option>)}
      </select>
  
    </div> */}
  
  <>
   {showAddIngredient&&getCountry!=='ja'?<AddIngredient getai={getai} setnewIngredientNameHandler={setnewIngredientNameHandler}
    getnewIngredientName={getnewIngredientName} getIngredCategories={getIngredCategories}
    addnewIngredientsHandler={addnewIngredientsHandler} getChosenIngredientName={getChosenIngredientName}
    kgPriceHandler={kgPriceHandler} getKgPrice={getKgPrice} addIngredientCategory={addIngredientCategory}
    getIngredCategory={getIngredCategory} getIngredSupplier={getIngredSupplier} levHandler={levHandler}
    closeSaveContainerHandler={closeSaveContainerHandler}
    saveIngredientBoxName={saveIngredientBoxName}
    saveIngredientBoxCategory={saveIngredientBoxCategory}
    saveIngredientBoxpPrice={saveIngredientBoxpPrice}
    saveIngredientBoxSaveButtonText={saveIngredientBoxSaveButtonText}
    saveNewIngredientHeader={saveNewIngredientHeader}
    saveIngredientPricePlaceHolder={saveIngredientPricePlaceHolder}
    getFuriganaIngredName={getFuriganaIngredName} setFuriganaIngredName={setFuriganaIngredName}
    setFuriganaNameHandler={setFuriganaNameHandler}
    getCountry={getCountry}

        />:''}

        {showAddIngredient&&getCountry==='ja'?<AddIngredientJAP getai={getai} setnewIngredientNameHandler={setnewIngredientNameHandler}
    getnewIngredientName={getnewIngredientName} getIngredCategories={getIngredCategories}
    addnewIngredientsHandler={addnewIngredientsHandler} getChosenIngredientName={getChosenIngredientName}
    kgPriceHandler={kgPriceHandler} getKgPrice={getKgPrice} addIngredientCategory={addIngredientCategory}
    getIngredCategory={getIngredCategory} getIngredSupplier={getIngredSupplier} levHandler={levHandler}
    closeSaveContainerHandler={closeSaveContainerHandler}
    saveIngredientBoxName={saveIngredientBoxName}
    saveIngredientBoxCategory={saveIngredientBoxCategory}
    saveIngredientBoxpPrice={saveIngredientBoxpPrice}
    saveIngredientBoxSaveButtonText={saveIngredientBoxSaveButtonText}
    saveNewIngredientHeader={saveNewIngredientHeader}
    saveIngredientPricePlaceHolder={saveIngredientPricePlaceHolder}
    getFuriganaIngredName={getFuriganaIngredName} setFuriganaIngredName={setFuriganaIngredName}
    setFuriganaNameHandler={setFuriganaNameHandler}
    getCountry={getCountry}

        />:''}
</>


        
  
      </div>
  
  
      </div>
    )
  }
  
  function AiRecepyDescription(props){
    let{getai,checkVal,recepyDescriptionHandler,getInstructions,
      descriptionHeader,descriptionCharacterLength
    }=props 
  

  
let textLength=getInstructions.length||0

//let textLength = getInstructions.join(' ').length || 0;

    return(
      <>
      
        <div><div className="xlRecepydescHeader">{descriptionHeader}</div>
         <textarea rows="50" cols="50"  className="xlReceivedRecepyDescription"
         value={checkVal>0?getInstructions:''} onChange={(e)=>recepyDescriptionHandler(e.target.value)}/>
      </div>
  
       <div className="xldescriptionlengthWrapper">
      <div className="xldescriptionLength">{descriptionCharacterLength}&nbsp;{checkVal>0?textLength:''}/2500 </div>
      </div>


    </>
    )
  
  }
  
  function AiReturnedRecepyName(props){
    let {getai,RecepyData,setShowRecepyHandler,recpyArrivedButtonHeaderText
    }=props
    if(RecepyData.title.length===0){
      return
    }
  
    return(
      <div className="xlrecepyNameContainer">
        <div className="xlbuttonRecepyNameHeader">{recpyArrivedButtonHeaderText}</div>
      <button onClick={setShowRecepyHandler} className="xlbuttonRecepyName">{RecepyData.title.length>0?RecepyData.title:''}</button>
      </div>
    )
  }
  
  function MatchningKlar(props){
    let {getai,index,openSaveRecepyBoxButtonText
    }=props
    return(
      <button className={index%2===0?"xlMatchOK":"xlMatchOK2"}>
        <MdDoneOutline className="mdDoneOutline"/></button>
    )
  }
  
  function RecepyReadyToSave(props){
  let {openSaveMenuHandler,getOpenSaveMenu,RecepyData,
    changeNewRecepyNameHandler,SaveNewRecepyHandler,getNewRecepName,
    chosenCategoryHandler,getRecepyCategories,getchosenCategory,openSaveRecepyBoxButtonText,
    saveRecepyBoxRecepyNameTitle,saveRecepyBoxRecepyCategoryFirstOption,
    saveRecepyBoxSaveRecepyButtonText,getCountry,furiganaRecipeNameHandler,
    getFuriganaRecipeName,getTitle,closeSaveContainerHandler,saveRecepyTitle,title
    
  }=props




    return(
      <>
      <div className="xlbuttonsaverecepyContainer">
  
  {/* <button className="xlButtonSaveRecepy" onClick={openSaveMenuHandler}>{openSaveRecepyBoxButtonText}</button>
   */}
  </div>
  {getOpenSaveMenu===true&&getCountry!=='ja'?<SaveMenu 
  RecepyData={RecepyData} 
  SaveNewRecepyHandler={SaveNewRecepyHandler} 
  changeNewRecepyNameHandler={changeNewRecepyNameHandler} 
  getchosenCategory={getchosenCategory}
   getNewRecepName={getNewRecepName} 
   getRecepyCategories={getRecepyCategories}
   chosenCategoryHandler={chosenCategoryHandler} 
   saveRecepyBoxRecepyCategoryFirstOption={saveRecepyBoxRecepyCategoryFirstOption}
   saveRecepyBoxRecepyNameTitle={saveRecepyBoxRecepyNameTitle}
   saveRecepyBoxSaveRecepyButtonText={saveRecepyBoxSaveRecepyButtonText}  
   getCountry={getCountry}
   furiganaRecipeNameHandler={furiganaRecipeNameHandler}
   getFuriganaRecipeName={getFuriganaRecipeName}
   getTitle={getTitle}
   closeSaveContainerHandler={closeSaveContainerHandler}
   saveRecepyTitle={saveRecepyTitle}

   
   
   />:''}
   

   {getOpenSaveMenu===true&&getCountry==='ja'?<SaveMenuJAP 
  RecepyData={RecepyData} SaveNewRecepyHandler={SaveNewRecepyHandler} 
  changeNewRecepyNameHandler={changeNewRecepyNameHandler} getchosenCategory={getchosenCategory}
   getNewRecepName={getNewRecepName} getRecepyCategories={getRecepyCategories}
   chosenCategoryHandler={chosenCategoryHandler} 
   saveRecepyBoxRecepyCategoryFirstOption={saveRecepyBoxRecepyCategoryFirstOption}
   saveRecepyBoxRecepyNameTitle={saveRecepyBoxRecepyNameTitle}
   saveRecepyBoxSaveRecepyButtonText={saveRecepyBoxSaveRecepyButtonText}  
   getCountry={getCountry}
   furiganaRecipeNameHandler={furiganaRecipeNameHandler}
   getFuriganaRecipeName={getFuriganaRecipeName}
   getTitle={getTitle}
   closeSaveContainerHandler={closeSaveContainerHandler}
   saveRecepyTitle={saveRecepyTitle}
   
   />:''}

  </>
    )
  }
  
  function IngredComments(props){
    let {getai,ingredCommentsHandler,RecepyData,chosenIngredid,
      changeAmountHandler,getFoodInfoContainer,getReceivedDataStatus,getnutritionData,

      descriptionCharacterLength,
      ingredientBoxCommentTitle,
      ingredientBoxPlusButtonText,
      titleIngredientBoxPlusButton,
      ingredientBoxMinusButtonText,
      titleIngredientBoxMinusButton,

    }=props
    const editor = React.useRef(null)
  
  //here i need to get the comment for the ingredient
  let commentIndex=RecepyData.ingredients.findIndex(data=>data.ingredid===chosenIngredid)
  let comment=RecepyData.ingredients[commentIndex].comment || ''
  // i want to know the length of the text here
  let textLength=comment.length||0
  let textmax=200
  
  
  
    return(
      <>
      <div className="xlTextAreaContainer">
        <textarea className="xlTextareaComments" rows="5" cols="50" placeholder={ingredientBoxCommentTitle}
         onChange={(e)=>ingredCommentsHandler(e.target.value)} value={comment}/> 
        
        <AiOutlinePlusCircle 
        className="xlOutlinePlusCircle"
        onClick={()=>changeAmountHandler(2)}
        onDoubleClick={()=>changeAmountHandler(4)}
        title={titleIngredientBoxPlusButton} 
        /><br></br>

        <AiOutlineMinusCircle 
        className="xlOutlinePlusCircle"  
        onClick={()=>changeAmountHandler(1)}
        onDoubleClick={()=>changeAmountHandler(3)}
        title={titleIngredientBoxMinusButton} 
        />
        
{/*         
          <button className="xladdAmountIngramMinus" onClick={()=>changeAmountHandler(1)} title={titleIngredientBoxMinusButton}>{ingredientBoxMinusButtonText}</button>
          <button className="xladdAmountIngramPlus" onClick={()=>changeAmountHandler(2)} title={titleIngredientBoxPlusButton}>{ingredientBoxPlusButtonText}</button>
           */}
          <div className="xlnumTecken">&nbsp;&nbsp;{descriptionCharacterLength}:&nbsp;{textLength}/{textmax} </div>
 
          
       </div>
     
   
        </>
    )
  }
  
  function AddIngredient(props){
    let {getai,setnewIngredientNameHandler,getnewIngredientName,getIngredCategories,
      addnewIngredientsHandler,getChosenIngredientName,kgPriceHandler,getKgPrice,
      addIngredientCategory,getIngredCategory,getIngredSupplier,levHandler,
      closeSaveContainerHandler,saveIngredientBoxName,saveIngredientBoxCategory,
      saveIngredientBoxpPrice,saveIngredientBoxSaveButtonText,saveNewIngredientHeader,
      saveIngredientPricePlaceHolder,
    
    }=props

  let nameval

  if(getChosenIngredientName.length===0){
    nameval=getnewIngredientName
  }else{
    nameval=getChosenIngredientName
  }
    return(
      <>
        <div className="xladdingredientsContainer">
        <div className="xlAddIngredientHeader">{saveNewIngredientHeader}
        <IoMdClose className="xlCloseSaveContainer" onClick={()=>closeSaveContainerHandler()} />
        </div>
         <div className="xladdingredientWrapper">
        <div className="xlAddIngredient">
       
          <div className="xlAddIngredientName">
            {saveIngredientBoxName}:
         </div>
         
         <div>
      <input className="xlInputAddIngredient" type="text"
        onChange={(e)=>setnewIngredientNameHandler(e.target.value)} value={getnewIngredientName.length>0?getnewIngredientName:getChosenIngredientName}/>
        </div>  
      
         <div className="xlAddCategory">{saveIngredientBoxCategory}:</div>
         
         <div>
         <select className="xlSelectAddIngredient"
              onChange={(e)=>addIngredientCategory(e.target.value)}>
              {getIngredCategories.map((data,key)=><option key={key}value={data.kategoriid}>{data.benaemn}</option>)}
             </select>
        </div>
       
       <div className="xlAddPrice">{saveIngredientBoxpPrice}:</div>
       
       <div>
       <input className="xlInputAddPris" type="text" placeholder={saveIngredientPricePlaceHolder}
       onChange={(e)=>kgPriceHandler(e.target.value)} value={getKgPrice>0?getKgPrice:''}/>
        </div>
      
        <div className="xlAddCategory"></div>
       <div>
       <button className={getKgPrice>0 && getIngredCategory>0?"xlButtonAddIngredient":"xlButtonAddIngredient2"} 
       onClick={addnewIngredientsHandler}>
        {saveIngredientBoxSaveButtonText}</button>
      
          </div>
        </div>
      </div>
    </div>
    </>

    )
  
    }


    function AddIngredientJAP(props){
      let {getai,setnewIngredientNameHandler,getnewIngredientName,getIngredCategories,
        addnewIngredientsHandler,getChosenIngredientName,kgPriceHandler,getKgPrice,
        addIngredientCategory,getIngredCategory,getIngredSupplier,levHandler,
        closeSaveContainerHandler,saveIngredientBoxName,saveIngredientBoxCategory,
        saveIngredientBoxpPrice,saveIngredientBoxSaveButtonText,saveNewIngredientHeader,
        saveIngredientPricePlaceHolder,getFuriganaIngredName,setFuriganaNameHandler
      
      }=props
  
    let nameval
  
    if(getChosenIngredientName.length===0){
      nameval=getnewIngredientName
    }else{
      nameval=getChosenIngredientName
    }
      return(
        <>
          <div className="xladdingredientsContainerJAP">
          <div className="xlAddIngredientHeaderJAP">{saveNewIngredientHeader}
          <IoMdClose className="xlCloseSaveContainerJAP" onClick={()=>closeSaveContainerHandler()} />
          </div>
     
          <div className="xlAddIngredientJAP">
         
            <div className="xlAddIngredientNameJAP">
              {saveIngredientBoxName}:
           </div>
           
           <div>
        <input className="xlInputAddIngredientJAP" type="text"
          onChange={(e)=>setnewIngredientNameHandler(e.target.value)} value={getnewIngredientName.length>0?getnewIngredientName:getChosenIngredientName}/>
        </div>

        <div className="xlAddCategoryJAP">{saveIngredientBoxCategory}:</div>
        <div>
          <input className="xlInputAddFuriganaJAP" type="text"
          onChange={(e)=>setFuriganaNameHandler(e.target.value)} value={getFuriganaIngredName}
          placeholder='ふりがな'
          />
          
          </div>  
        
           <div className="xlAddCategoryJAP">{saveIngredientBoxCategory}:</div>
           
           <div>
           <select className="xlSelectAddIngredientJAP"
                onChange={(e)=>addIngredientCategory(e.target.value)}>
                {getIngredCategories.map((data,key)=><option key={key}value={data.kategoriid}>{data.benaemn}</option>)}
               </select>
          </div>
         
         <div className="xlAddPriceJAP">{saveIngredientBoxpPrice}:</div>
         
         <div>
         <input className="xlInputAddPrisJAP" type="text" placeholder={saveIngredientPricePlaceHolder}
         onChange={(e)=>kgPriceHandler(e.target.value)} value={getKgPrice>0?getKgPrice:''}/>
          </div>
        
         <div className="xlAddCategoryJAP"></div>
          <div> 
         <button className={getKgPrice>0 && getIngredCategory>0&&getFuriganaIngredName.length>0?"xlButtonAddIngredientJAP2":"xlButtonAddIngredientJAP"} 
         onClick={addnewIngredientsHandler}
         disabled={getFuriganaIngredName.length===0||getIngredCategory===0||getKgPrice===0?true:false}
         
         
         >{saveIngredientBoxSaveButtonText}</button>
        </div>
            </div>
          </div>
      </>
  
      )
    
      }
       



    function SaveMenu(props){
      let {RecepyData,SaveNewRecepyHandler,changeNewRecepyNameHandler,
        getNewRecepName,getRecepyCategories,chosenCategoryHandler,getchosenCategory,
        saveRecepyBoxRecepyNameTitle,saveRecepyBoxRecepyCategoryFirstOption,
        saveRecepyBoxSaveRecepyButtonText,
        getCountry,getTitle,closeSaveContainerHandler,saveRecepyTitle
      }=props
    
  let nameval
  if(getNewRecepName && getNewRecepName.length===0){
    nameval=RecepyData.title
  }else{
    nameval=getNewRecepName
  }
  
    return(
      <div className="xlsavemenuWrapper">
        <div className="xlsaveMenuHeader">
          <div className="xlsaveMenuHeaderText">{saveRecepyTitle}</div>
          <div>
        <IoMdClose className="xlCloseSaveContainer" onClick={()=>closeSaveContainerHandler()} />
        </div>
        </div>
   <div className="xlSaveMenuContainer">
    <div className="xlSaveNameContainer">
      <fieldset className="xlsavenameFieldset"><legend className="xlsavenameLegend">{saveRecepyBoxRecepyNameTitle} </legend>
    <input type="text" className="xlInputSaveRecepyName"
    value={getTitle} onChange={(e)=>changeNewRecepyNameHandler(e.target.value)}/>

    <select className="xlSaveRecepySelect" onChange={(e)=>chosenCategoryHandler(e.target.value)}>
      <option value="0" hidden>&nbsp;{saveRecepyBoxRecepyCategoryFirstOption}&nbsp;!&nbsp;</option>
             {getRecepyCategories.map((data,key)=><option key={key}value={data.kategoriId}>&nbsp;&nbsp;{data.Benaemn}</option>)}
      </select>
   <div className="xlsaverecepybuttonContainer">
  <button className={getchosenCategory>0?"xlsaverecepyButton11":"xlsaverecepyButton10"} onClick={SaveNewRecepyHandler}
          disabled={getchosenCategory===0}>{saveRecepyBoxSaveRecepyButtonText}</button>
          </div>

           </fieldset>
           </div>
   </div>
   </div>
    )
  
    }

    function SaveMenuJAP(props){
      let {getai,RecepyData,SaveNewRecepyHandler,changeNewRecepyNameHandler,
        getNewRecepName,getRecepyCategories,chosenCategoryHandler,getchosenCategory,
        saveRecepyBoxRecepyNameTitle,saveRecepyBoxRecepyCategoryFirstOption,
        saveRecepyBoxSaveRecepyButtonText,furiganaRecipeNameHandler,getFuriganaRecipeName,
        getTitle,closeSaveContainerHandler,saveRecepyTitle
      }=props
    
  // let nameval
  // if(getNewRecepName.length===0){
  //   nameval=RecepyData.title
  // }else{
  //   nameval=getNewRecepName
  // }
  
    return(
      <div className="xlsavemenuWrapper">
      <div className="xlsaveMenuHeader">
        <div className="xlsaveMenuHeaderText">{saveRecepyTitle}</div>
        <div>
      <IoMdClose className="xlCloseSaveContainer" onClick={()=>closeSaveContainerHandler()} />
      </div>
      </div>
      
   <div className="xlSaveMenuContainerJapan">
    <div className="xlSaveNameContainerJapan">{saveRecepyBoxRecepyNameTitle}
    <input type="text" className="xlInputSaveRecepyNameJapan"
    value={getTitle} onChange={(e)=>changeNewRecepyNameHandler(e.target.value)}/>

<input type="text" className="xlInputSaveFuriganaNameJapan"
    value={getFuriganaRecipeName} onChange={(e)=>furiganaRecipeNameHandler(e.target.value)}
    placeholder='ふりがな'
    />

    <select className="xlSaveRecepySelectJapan" onChange={(e)=>chosenCategoryHandler(e.target.value)}>
      <option value="0" hidden>&nbsp;{saveRecepyBoxRecepyCategoryFirstOption}&nbsp;!&nbsp;</option>
             {getRecepyCategories.map((data,key)=><option key={key}value={data.kategoriId}>&nbsp;&nbsp;{data.Benaemn}</option>)}
      </select>
   
   <div className="xlsaverecepybuttonContainerJap">
  <button className={getchosenCategory>0&&getFuriganaRecipeName.length>0?"xlsaverecepyButton11Japan":"xlsaverecepyButton10Japan"} onClick={SaveNewRecepyHandler}
          disabled={getchosenCategory===0||getFuriganaRecipeName.length===0}>{saveRecepyBoxSaveRecepyButtonText}</button>
           </div></div>
   </div></div>
    )
  
    }

    function NoDataMessage(props){
      let {aialertTitle,aialertMessage,aialertMessage2,aialertCloseHandler,getCountry,
        aialertButtonText}=props
    

   return(
  
   
      <div className="xlspinner-text1">
          <div className={getCountry!=='ja'?"xlaialertTitle":"xlaialertTitleJAP"}>{aialertTitle}</div>
          <div className={getCountry!=='ja'?"xlaialertMessage":"xlaialertMessageJAP"}>{aialertMessage}<br></br>
          {aialertMessage2} </div>
       
        <button className='xlaialertCloseButton' onClick={aialertCloseHandler}>{aialertButtonText}</button>
  
        
      </div>

   )

    }
  
  
  
  
  export {SendAiMessage,AiRecepyDescription,AiReturnedRecepyName,
          MatchningKlar,RecepyReadyToSave,IngredComments,SaveMenu,SaveMenuJAP,AddIngredient,
          AddIngredientJAP,NoDataMessage}

  