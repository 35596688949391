
import React,{useState,useEffect,useContext, useRef} from 'react'
import languages from '../AppLanguage/AppLanguage';
import AppError from '../AppLanguage/AppError';
import { RotatingLines } from 'react-loader-spinner'
import Alert from '../functions/appAlert'
import useDeviceDetection from '../hooks/useDeviceDetection'
import useReportErr from '../hooks/useReportErr'
import {useNavigate,useLocation} from 'react-router-dom';
import useFetchImageUrl from '../hooks/useFetchImageUrl';
import usePageSettings from '../hooks/usePageSettings';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { CgMenuRound } from "react-icons/cg";
import { AiOutlineClose } from "react-icons/ai";

import {useGetData,useDeleteData,usePostData,usePutData} from '../hooks/useGetData'
import './MyAccount.css'
import '../style/sliders.css'
import { FaFilePdf } from "react-icons/fa";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import klarnaimage from '../images/klarna.png'
export const MyContext = React.createContext();

export default function MyAccount() {

    const[getBackGroundShade,setBackGroundShade]=useState(0.45)
    const[getRenderKey,setRenderKey]=useState(0) // this is the key for the component
    const[getMenuShade,setMenuShade]=useState(0.75)
    const[getMenuShade2,setMenuShade2]=useState(0.45)

    const [windowWidth,setWindowWith]=useState(0) // this is the window width
    const [windowHeight,setWindowHeight]=useState(0)  // this is the window height
    const [getMobileIsUsed,setMobileIsUsed]=useState(false) // this is the window height
    const[getCountry,setCountry]=useState('se') // this is the country
    const[getIpadIsUsed,setIpadIsUsed]=useState(false) // this is the ipad
    const [getColorVal,setColorVal]=useState('')
    const [getImageVal,setImageVal]=useState('')
    const [getImageUrl,setImageUrl]=useState('')
    const[getAccount,setAccount]=useState([{}]) // this is the account
    const[getSpinnerLoading,setSpinnerLoading]=useState(false)
    const [getAlertMessage,setAlertMessage] = useState({});
    const[getAlertCode,setAlertCode]=useState(0)
    const [getShowAlert,setShowAlert] = useState(false);
    const [getButtonClicked,setButtonClicked]=useState(7)
    const[getName,setName]=useState('')
    const[getSurname,setSurname]=useState('')
    const[getAddress,setAddress]=useState('')
    const[getZipcode,setZipcode]=useState('')
    const[getCity,setCity]=useState('')
    const[getRegion,setRegion]=useState('')
    const[getPhone,setPhone]=useState('')
    const[getEmail,setEmail]=useState('')
    const[getPassword,setPassword]=useState('')
    const[getPassword2,setPassword2]=useState('')
    const[getDisposableHeight,setDisposableHeight]=useState(0)
    const[getForetagName,setForetagName]=useState('')
    const[getViewDataUsage,setViewDataUsage]=useState(false)
    const[getUserDataUsage,setUserDataUsage]=useState([{}])
    const[getUserMainData,setUserMainData]=useState([{}])
const[getUserInfo,setUserInfo]=useState([{}])
const[getconsent,setconsent]=useState(false)
const [getOldpass,setOldpass]=useState('')
const [getNewpass,setNewpass]=useState('')
const [getConfirmpass,setConfirmpass]=useState('')
const [getUserAccountBalance,setUserAccountBalance]=useState([{}])  // user account balance
const [getInvoiceNextDueDate,setInvoiceNextDueDate]=useState([{}]) // invoice due date
const [getUserInvoiceInfo,setUserInvoiceInfo]=useState([{}]) // user invoice information & historical
const [getUserBillingAdressInfo,setUserBillingAdressInfo]=useState([{}]) // user billing adress information
const [getTaxId,setTaxId]=useState('')
const [getMaxValueSlider,setMaxValueSlider]=useState(false)
const [getMaxValue,setMaxValue]=useState()
const[getAccountInformation,setAccountInformation]=useState([{}])
const[getServiceInformation,setServiceInformation]=useState([{}])
const[getInvoiceRowInfo,setInvoiceRowInfo]=useState([{}])
const[getServicePriceInformation,setServicePriceInformation]=useState([{}])
const[getCurrencyInfo,setCurrencyInfo]=useState([{}])
const [getServicesInformation,setServicesInformation]=useState([{}])
const[getchosenServiceid,setchosenServiceid]=useState(0)
const[expandedRow,setExpandedRow]=useState(null)
const[getServicesSalesInformation,setServicesSalesInformation]=useState([{}])
const[getBillingData,setBillingData]=useState([])
const[getOwningCompanyInfo,setOwningCompanyInfo]=useState([{}])
const[getchosenInvoiceId,setChosenInvoiceId]=useState(0)
const[getBillingTotalAmount,setBillingTotalAmount]=useState([{}])
const[getUserTotalUsage,setUserTotalUsage]=useState([{}])
const[getSupportCategories,setSupportCategories]=useState([{}])
const[getSupportCategory,setSupportCategory]=useState(0)
const[getSupportMessage,setSupportMessage]=useState('')
const[getSupportTickets,setSupportTickets]=useState([{}])
const[getSupportTicket,setSupportTicket]=useState([{}])
const[getTotalCurrencyInfo,setTotalCurrencyInfo]=useState([{}])
const [menuOpen, setMenuOpen] = useState(false);
const[getUserMonthlyUsageCost,setUserMonthlyUsageCost]=useState([{}])
const [getVatInfo,setVatInfo]=useState([{}])
const[getKlarna,setKlarna]=useState(false)
const[getChosenTicketId,setChosenTicketId]=useState(0)

// klarna payment
const [getchosenKlarnaServiceid,setchosenKlarnaServiceid]=useState(0)
const [getChosenKlarnaServiceName,setChosenKlarnaServiceName]=useState('')
const [getChosenKlarnaTotalSum,setChosenKlarnaTotalSum]=useState(0)
const [getchosenKlarnaInvoiceId,setChosenKlarnaInvoiceId]=useState(0)
const[getChosenKlarnaCurrency,setChosenKlarnaCurrency]=useState('')


//klarna payment status callback
const [paymentStatus, setPaymentStatus] = useState(null);

//klarna session token
const [clientToken, setClientToken] = useState(null);

const pageid=21


    useEffect(()=>{
        let country = localStorage.getItem('country')||'en' // this is the country code
        setCountry(country)
      },[])

      // if language is japanese we use the font Zen Old Mincho
        let newFontFamily=''
        if(getCountry==='ja'){
        newFontFamily= "'Zen Old Mincho', Arial, Helvetica, sans-serif"
        }else{
        newFontFamily='Arial, Helvetica, sans-serif'
        }
        document.documentElement.style.setProperty('--font-family', newFontFamily);

      const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal } = usePageSettings(pageid);
      const { imageUrl, alertMessage, alertCode, showAlert } = useFetchImageUrl(getImageVal); // runs at mount, gives ut the image url and fetch image from server

      const navigate = useNavigate();
      const location = useLocation();

      useEffect(() => {
        // Function to parse query parameters
        const queryParams = new URLSearchParams(location.search);
        const status = queryParams.get('klarnastatus'); 

        if (status) {
            setPaymentStatus(status);
        }
    }, [location.search]); 

      useEffect(() => {
        function setSize() {
          var totalHeight=window.innerHeight

            setDisposableHeight(totalHeight-150)
          setWindowWith(window.innerWidth);
          setWindowHeight(window.innerHeight);
          if(window.innerWidth<600){
            setMobileIsUsed(true)
          }
        }
        setSize();

      }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);

// set the passowrds to void
useEffect(()=>{
    setOldpass('')
    setNewpass('')
    setConfirmpass('')
},[])

// language settings
const selectedCountry = languages[getCountry];

const{
    title,
    websiteCompanyName,
invoiceText,
broughtToYouText,
customerIdText,
serviceText,
nameText,
numText,
maxText,
debitText,
kreditText,
totaltText,
sumNetText,
sumVatText,
attBetalaText,
bankgiroText,
swishText,
termsText,
bankText,
bicText,
ibanText,
userFirstName,
userFamilyName,
userCompany,
userAdress,
userPostalCode,
userCity,
userRegion,
userCountry,
userPhone,
userEmail,
userSaveButtonText1,
userallServices,
userService,
userPrice,
userActivate,
numPageRequestsPerPage,
userPagenr,
userPageComponent,
userPageNum,
userMyData,
userMyDataDescriptionText,
userDataId,
userDataName,
userDataAntal,
userDataIngredientCategories,
userDataRecipeCategories,
userDataProductCategories,
userDataIngredient,
userDataRecipes,
userDataProducts,
userDataProductions,
userDataRecipeImages,
userDataProductImages,
userDataInstructionText,
useDataMyDataButtonText,
paymentPayPal,
paymentSwish,
paymentCreditCard,
paymentInvoice,
accountOverview,
userAccount,
accountTransactionsFor,
accountService,
accountInvoice,
accountDebit,
accountKredit,
accountDate,
accountSystemComment,
accountTotalDebit,
accountTotalKredit,
accountTotalSaldo,
estimatedSaldo,
estimatedSaldoText,
nextPaymenttext,
prePaymentText,
totalAmountUsed,
invoiceHistoryText,
invoiceHistoryInvoice,
invoiceHistorySum,
invoiceHistoryDate,
invoiceHistoryStatus,
chooseInvoiceText,
invoiceRowsText,
allInvoiceRowsText,
invoiceRowsId,
invoiceRowsService,
invoiceRowsMax,
invoiceRowsRequest,
invoiceRowsDebitQty,
invoiceRowsPrice,
invoiceRowsTotal,
invoiceRowsType,
invoiceRowsTotalSum,
passwordValidText,
newPasswordText,
confirmPasswordText,
showPasswordText,
savePasswordButtonText,
iforgotMyPasswordText,
supportText,
settingsHeaderText,
companyAndPersonalText,
addressInstructionText,
taxIdentificationVATText,
taxIdInformationText,
registerTaxIdButtonText,
maxValueWarningText,
actiVateMaxValueText,
maxValueButtonSaveText,
addressInfoButtontext,
servicesButtontext,
dataUsageButtontext,
mydataButtontext,
paymentButtontext,
myAccountButtonText,
myInvoiceButtonText,
passwordButtontext,
supportButtontext,
settingsButtontext,
servicesButtonUsagetext,
userStat,
serviceHeaderText,
  servicePrisHeaderText,
  serviceActivateHeaderText,
  serviceStatusHeaderText,
  debserviceidText,
debserviceBenaemningText,
debserviceQtyText,
debserviceMaxText,
debserviceUsableText,
debserviceStatusText,
supportMText,
supportMessageText,
supportMessageSelectText,
supportMessageSendText,
supportHistoryServiceIdText,
supportHistoryDateText,
supportHistoryStatusText,
supportHistoryCategoryText,
messagesSentToSupportText,
passwordHeaderText,
resetPasswordHeaderText,
overviewText,
overviewInformationText,
overviewInvoiceText,
invoiceSelectFirstOption,
printInvoceButtonText,
userDebitServices,
myinformationText,
paymentKlarna,
supportButtonAnswerText,
supportTitleText,
supportHistoryBenaemnText

}=selectedCountry.MyAccount||{};

const {
    paypalMainHeaderText,
    klarnaMainHeaderText,
    pptotalAmountToPayText,
    ppChosenServiceText,
    ppChosenCurrencyText,
    registerAccountTabText,
    paymentTabText,
    ppBakesoftTermsText,
    ppBakesoftTerms,
    ppSelectInvoiceText,
    ppSelectCurrencyText,

}=selectedCountry.info||{};

const selectedError=AppError[localStorage.getItem('country')||'en']

const {
    emailSent,
    userAddressDataUpdated,
    activateServiceMessage,
    deactivateServiceMessage,
    prescriptionCreatedMessage,
    supportMessageSendText1,
    dowloadpdfOkMessage,
    paypalPaymentConfirmText,
    klarnaPaymentSuccessText,
    klarnaPaymentfailureText,
    clientSideUpdateSupportTicketText,
}= selectedCountry.confirmationMessages||{};



//end language settings

useEffect(() => {
    GetSettings();
  }, [GetSettings]);

  // we set the image url here
  useEffect(() => {
    setImageUrl(imageUrl);
    if (alertCode > 0) {
      setAlertMessage(alertMessage);
      setAlertCode(alertCode);
      setShowAlert(showAlert);
    }
  }, [imageUrl]);

  // here we set the color values and image values
  useEffect(() => {
    setBackGroundShade(backGroundShade);
    setMenuShade(menuShade);
    setMenuShade2(menuShade2);
    setColorVal(colorVal);
    setImageVal(imageVal);
  }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);





function rgbToRgba(rgb, alpha) {
    return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
  }

//--bakcground shade settings
let rgbaVal
let rgbaVal2
let val

if(getColorVal && getColorVal.length > 0){
  let rgb = getColorVal;
  let alpha = getMenuShade; // replace with your alpha value
  let alpha2 = getMenuShade2; // replace with your alpha value
  rgbaVal = rgbToRgba(rgb, alpha);
  rgbaVal2 = rgbToRgba(rgb, alpha2);
  val=`rgba(255, 255, 255, ${getBackGroundShade})`

  }else{
    rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
    rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
    val=`rgba(255, 255, 255, ${getBackGroundShade})`
  }
//---

useEffect(() => {
    let root = document.documentElement;
    root.style.setProperty('--rgbaVal', rgbaVal);
    root.style.setProperty('--rgbaVal2', rgbaVal2);
    root.style.setProperty('--val',val);
    root.style.setProperty('--disposableHeight',getDisposableHeight+'px');
    root.style.setProperty('--windowHeight',windowHeight+'px');

  }, [getBackGroundShade,getMenuShade,getMenuShade2]);

  //-----

  // ErrHandling
  const RunReportErrHandler = async (errCode) => {
    setSpinnerLoading(true);

      await useReportErr('ErrReport',{errCode});
    setSpinnerLoading(false);
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  useEffect(() => {
    if (getAlertCode > 0) {
      RunReportErrHandler(getAlertCode);
    }
  }, [getAlertCode]);


  // if the user changges the value on the range control we save the value in the database
async function SetBackGroundShadeHandler(val){
    setBackGroundShade(val)
    setRenderKey(getRenderKey+1)
    setSpinnerLoading(true)
    try{
      const response = await usePutData(`updateBackGroundShade`,{pageid,val})
      if(response.status===200){
      setSpinnerLoading(false)
      }
    }catch(error){
        setAlertCode(53001)
        setAlertMessage(selectedError[53001])
        setSpinnerLoading(false)
        setShowAlert(true)

        return;
      }

  }

  async function SetmenuColorHandler(val){
    setMenuShade(val)
    setMenuShade2(val-0.20)
    setRenderKey(getRenderKey+1)
    setSpinnerLoading(true)
    try{
      const response = await usePutData(`updateMenuShade`,{pageid,val})
      if(response.status===200){
      setSpinnerLoading(false)
      }

  }catch(error){
    setAlertCode(53002)
    setAlertMessage(selectedError[53002])
    setSpinnerLoading(false)
    setShowAlert(true)

    return;
  }
  }


useEffect(()=>{

FetchUserDataUsage()
},[getViewDataUsage])

async function FetchUserDataUsage(){
    setSpinnerLoading(true)
    try{
        const response = await useGetData(`getUserDataUsage`)

            setUserDataUsage(response.data[0])
            setUserAccountBalance(response.data[1])
            setInvoiceNextDueDate(response.data[2])
            setUserInvoiceInfo(response.data[3])
            setUserBillingAdressInfo(response.data[4])
            setMaxValue(response.data[4][0].invoiceMaxVal)
            setMaxValueSlider(response.data[4][0].invoiceMaxVal>0?true:false)
            setAccountInformation(response.data[5])
            setServiceInformation(response.data[6])
            setInvoiceRowInfo(response.data[7])
            setCurrencyInfo(response.data[8])
            setServicesInformation(response.data[9])
            setServicesSalesInformation(response.data[10])
            setOwningCompanyInfo(response.data[11])
            setUserTotalUsage(response.data[12])
            setSupportCategories(response.data[13])
            setSupportTickets(response.data[14])
            setTotalCurrencyInfo(response.data[15])
            setUserMonthlyUsageCost(response.data[16])
            setVatInfo(response.data[17])

            setSpinnerLoading(false)

    }catch(error){
        setAlertCode(53000)
        setAlertMessage(selectedError[53000])
        setSpinnerLoading(false)
        setShowAlert(true)
        return;
    }
}


const buttonClickedHandler=(val)=>{
    setButtonClicked(val)
    setMenuOpen(false)
    if(val===4){
        if(getViewDataUsage===false){
    setViewDataUsage(true)

        }else{
            setViewDataUsage(false)
        }
    }else if(val===5){
        GetUserMainData()
    }else if(val===1){
        GetUserAdressInfo()
    }

}

const setNameHandler=(val)=>{

    let myNewObjArr=[...getUserInfo]
    myNewObjArr[0].givenName=val
    setUserInfo(myNewObjArr)
}

const setSurNameHandler=(val)=>{
      // here we need to modify the object getUserInfo
        let myNewObjArr=[...getUserInfo]
        myNewObjArr[0].familyName=val
        setUserInfo(myNewObjArr)
}

const setAddressHandler=(val)=>{
      // here we need to modify the object getUserInfo
        let myNewObjArr=[...getUserInfo]
        myNewObjArr[0].address=val
        setUserInfo(myNewObjArr)

}

const setZipCodeHandler=(val)=>{
      // here we need to modify the object getUserInfo
        let myNewObjArr=[...getUserInfo]
        myNewObjArr[0].postalCode=val
        setUserInfo(myNewObjArr)

}

const  setCityHandler=(val)=>{
      // here we need to modify the object getUserInfo
        let myNewObjArr=[...getUserInfo]
        myNewObjArr[0].city=val
        setUserInfo(myNewObjArr)


}

const setPhoneHandler=(val)=>{
      // here we need to modify the object getUserInfo
        let myNewObjArr=[...getUserInfo]
        myNewObjArr[0].phone=val
        setUserInfo(myNewObjArr)

}

const setEmailHandler=(val)=>{
  // here we need to modify the object getUserInfo
    let myNewObjArr=[...getUserInfo]
    myNewObjArr[0].email=val
    setUserInfo(myNewObjArr)
}

const setPasswordHandler=(val)=>{
    setPassword(val)
}

const setPassword2Handler=(val)=>{
    setPassword2(val)
}

const setForetagNameHandler=(val)=>{
    let myNewObjArr=[...getUserInfo]
    myNewObjArr[0].company=val
    setUserInfo(myNewObjArr)
    setForetagName(val)
}

const saveUserAddressInfoHandler=()=>{
    SaveAdressInfo()
}
const setRegionHandler=(val)=>{
      // here we need to modify the object getUserInfo
        let myNewObjArr=[...getUserInfo]
        myNewObjArr[0].region=val
        setUserInfo(myNewObjArr)

}
const setCountryHandler=(val)=>{
      // here we need to modify the object getUserInfo
        let myNewObjArr=[...getUserInfo]
        myNewObjArr[0].country=val
        setUserInfo(myNewObjArr)

}



async function SendUserDataByEmail(){
    setSpinnerLoading(true)

    try{
        const response = await useGetData(`sendUserDataByEmail`)
        if(response.status===200){
            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage(emailSent)
            setShowAlert(true)
        }
    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53004)
        setAlertMessage(selectedError[53004])
        setShowAlert(true)
    }
}

async function GetUserMainData(){
    setSpinnerLoading(true)

    try{
        const response = await useGetData(`getUserMainData`)
       setUserMainData(response.data[0])
            setSpinnerLoading(false)
    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53005)
        setAlertMessage(selectedError[53005])
        setShowAlert(true)
    }
}

async function GetUserAdressInfo(){
    setSpinnerLoading(true)
    try{
        const response=await useGetData(`getUserAdressInfo`)
        setUserInfo(response.data[0])
        setSpinnerLoading(false)

    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53006)
        setAlertMessage(selectedError[53006])
        setShowAlert(true)
    }
}

async function SaveAdressInfo(){
    setSpinnerLoading(true)
    try{
        const response=await usePutData(`updateUserAdressInfo`,{getUserInfo})
        setSpinnerLoading(false)
        setAlertCode(0)
        setAlertMessage(userAddressDataUpdated)
        setShowAlert(true)
    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53007)
        setAlertMessage(selectedError[53007])
        setShowAlert(true)
    }
}

async function SavePrescriptionHandler(prescription){

    setSpinnerLoading(true)
    try{
        const response= await usePostData('savePrescription',{prescription,getconsent})
        if(response.status===200){
            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage(prescriptionCreatedMessage)
            setShowAlert(true)
        }

    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53009)
        setAlertMessage(selectedError[53009])
        setShowAlert(true)
    }
}

const consentHandler=(val)=>{
    setconsent(val)
}
const setNewPasswordHandler=(pass,val)=>{
    if(val===1){
        setOldpass(pass)
    }else if(val===2){
        setNewpass(pass)
    }else if(val===3){
        setConfirmpass(pass)
    }
}


async function SaveNewPassword(reset){
    console.log("save new password")
    setSpinnerLoading(true)
    try{
        const response= await usePutData('saveNewPassword',{getOldpass,getNewpass,getConfirmpass,reset})
        if(response.status===200){
            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage('Password has been updated')
            setShowAlert(true)
        }
    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53010)
        setAlertMessage(selectedError[53010])
        setShowAlert(true)
    }
}

async function SendPassByEmailToUser(){
    console.log("send pass by email")
    setSpinnerLoading(true)
    try{
        const response= await usePostData('sendPassByEmail',{getEmail})
        if(response.status===200){
            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage('Password has been sent to your email')
            setShowAlert(true)
        }
    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53011)
        setAlertMessage(selectedError[53011])
        setShowAlert(true)
    }
}

const taxIdHandler=(val)=>{
    setTaxId(val)
}



async function InvoiceMaxVal(){
    if(getMaxValueSlider===false){
        setMaxValue(0)
    }

        setSpinnerLoading(true)
       try{
        const response = await usePutData('invoiceMaxVal',{getMaxValueSlider,getMaxValue})
        console.log(response)
        if(response.status===200){
            if(getMaxValueSlider===false){
                setMaxValue(0)
                setAlertCode(0)
                setAlertMessage('Max värdes funktionen har inaktiverats')
            }else if(getMaxValueSlider===true){
                setAlertCode(0)
                setAlertMessage('Max värdes funktionen har aktiverats')
            }
            setShowAlert(true)
            setSpinnerLoading(false)
            }
         }catch(error){
            setAlertCode(53012)
            setAlertMessage(selectedError[53012])
            setShowAlert(true)
            setSpinnerLoading(false)
            return;
         }
}



const toggleMaxValueSlider = () => {
    setMaxValueSlider(prevState => {
        if (prevState) {
            setMaxValue(0);
        }
        return !prevState;
    });
};

async function CheckIfBaseService(val,serviceid){
    if(serviceid===127 || serviceid===128 || serviceid===133 || serviceid===134){
        CheckUserAccount(val,serviceid)
    }else{
        ServicesAddHandler(val,serviceid)
    }
}

async function CheckUserAccount(val,serviceid){
    setSpinnerLoading(true)
    try{
        const response = await useGetData(`checkUserAccount/${serviceid}`)
      
        if(response.status===200){
            setSpinnerLoading(false)
            ServicesAddHandler(val,serviceid)
        }else if(response.status===201){
            setAlertCode(0)
            setAlertMessage(response.data.textVal)
            setShowAlert(true)
            setSpinnerLoading(false)
        }
        
        
    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53008)
        setAlertMessage(selectedError[53008])
        setShowAlert(true)
    }

}

const ServicesAddHandler=(val,serviceid)=>{
// here we will write to the object getServicesInformation
let myNewObjArr=[...getServicesInformation]
// find the inedex of the row we want to update
// we have a special case here, if serviceid=127 or 128 we will switch them
// if serviceid =127 and the value is0 we will set 128 to 1 and we will set 127 to 0
// also we need to assure that133 and 134 are switched off to 0
if(serviceid===127 && val===false){
    let myindex = myNewObjArr.findIndex(x => x.serviceid === 128)
    myNewObjArr[myindex].Istatus=1
    myindex = myNewObjArr.findIndex(x => x.serviceid === 127)
    myNewObjArr[myindex].Istatus=0
    // so wee need to find 133 and 134 and set them to 0
    myindex = myNewObjArr.findIndex(x => x.serviceid === 133)
    myNewObjArr[myindex].Istatus=0
    myindex = myNewObjArr.findIndex(x => x.serviceid === 134)
    myNewObjArr[myindex].Istatus=0
    
}else if(serviceid===128 && val===false){
    let myindex = myNewObjArr.findIndex(x => x.serviceid === 127)
    myNewObjArr[myindex].Istatus=1
    myindex = myNewObjArr.findIndex(x => x.serviceid === 128)
    myNewObjArr[myindex].Istatus=0
     // so wee need to find 133 and 134 and set them to 0
     myindex = myNewObjArr.findIndex(x => x.serviceid === 133)
     myNewObjArr[myindex].Istatus=0
     myindex = myNewObjArr.findIndex(x => x.serviceid === 134)
     myNewObjArr[myindex].Istatus=0

}else if(serviceid===127 && val===true){
    let myindex = myNewObjArr.findIndex(x => x.serviceid === 128)
    myNewObjArr[myindex].Istatus=0
    myindex = myNewObjArr.findIndex(x => x.serviceid === 127)
    myNewObjArr[myindex].Istatus=1

     // so wee need to find 133 and 134 and set them to 0
     myindex = myNewObjArr.findIndex(x => x.serviceid === 133)
     myNewObjArr[myindex].Istatus=0
     myindex = myNewObjArr.findIndex(x => x.serviceid === 134)
     myNewObjArr[myindex].Istatus=0

}else if(serviceid===128 && val===true){
    let myindex = myNewObjArr.findIndex(x => x.serviceid === 127)
    myNewObjArr[myindex].Istatus=0
    myindex = myNewObjArr.findIndex(x => x.serviceid === 128)
    myNewObjArr[myindex].Istatus=1

     // so wee need to find 133 and 134 and set them to 0
     myindex = myNewObjArr.findIndex(x => x.serviceid === 133)
     myNewObjArr[myindex].Istatus=0
     myindex = myNewObjArr.findIndex(x => x.serviceid === 134)
     myNewObjArr[myindex].Istatus=0

// here we will also switch between 133 and 134 Bas Företag and Total Företag
}
else if(serviceid===133 && val===false){
    let myindex = myNewObjArr.findIndex(x => x.serviceid === 134)
    myNewObjArr[myindex].Istatus=1
    myindex = myNewObjArr.findIndex(x => x.serviceid === 133)
    myNewObjArr[myindex].Istatus=0

     // so wee need to find 127 and 128 and set them to 0
     myindex = myNewObjArr.findIndex(x => x.serviceid === 127)
     myNewObjArr[myindex].Istatus=0
     myindex = myNewObjArr.findIndex(x => x.serviceid === 128)
     myNewObjArr[myindex].Istatus=0
}else if(serviceid===134 && val===false){
    let myindex = myNewObjArr.findIndex(x => x.serviceid === 133)
    myNewObjArr[myindex].Istatus=1
    myindex = myNewObjArr.findIndex(x => x.serviceid === 134)
    myNewObjArr[myindex].Istatus=0

      // so wee need to find 127 and 128 and set them to 0
      myindex = myNewObjArr.findIndex(x => x.serviceid === 127)
      myNewObjArr[myindex].Istatus=0
      myindex = myNewObjArr.findIndex(x => x.serviceid === 128)
      myNewObjArr[myindex].Istatus=0

}else if(serviceid===133 && val===true){
    let myindex = myNewObjArr.findIndex(x => x.serviceid === 134)
    myNewObjArr[myindex].Istatus=0
    myindex = myNewObjArr.findIndex(x => x.serviceid === 133)
    myNewObjArr[myindex].Istatus=1

      // so wee need to find 127 and 128 and set them to 0
      myindex = myNewObjArr.findIndex(x => x.serviceid === 127)
      myNewObjArr[myindex].Istatus=0
      myindex = myNewObjArr.findIndex(x => x.serviceid === 128)
      myNewObjArr[myindex].Istatus=0

}else if(serviceid===134 && val===true){
    let myindex = myNewObjArr.findIndex(x => x.serviceid === 133)
    myNewObjArr[myindex].Istatus=0
    myindex = myNewObjArr.findIndex(x => x.serviceid === 134)
    myNewObjArr[myindex].Istatus=1

      // so wee need to find 127 and 128 and set them to 0
      myindex = myNewObjArr.findIndex(x => x.serviceid === 127)
      myNewObjArr[myindex].Istatus=0
      myindex = myNewObjArr.findIndex(x => x.serviceid === 128)
      myNewObjArr[myindex].Istatus=0

}else{
let myindex = myNewObjArr.findIndex(x => x.serviceid === serviceid)
myNewObjArr[myindex].Istatus=val
}
  // set the new object
  setServicesInformation(myNewObjArr)
  UpdateServiceStatus(serviceid,val)
}


async function UpdateServiceStatus(serviceid,val){

    let mynewObjarr=[...getServicesInformation]
    let myindex = mynewObjarr.findIndex(x => x.serviceid === serviceid)
    let ServiceName=mynewObjarr[myindex].benaemn

   setSpinnerLoading(true)
 //  console.log('update service status',serviceid,val)
    try{
        const response = await usePutData('updateUserPrescription',{serviceid,val})
        if(response.status===200){
           FetchUserDataUsage()
            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage(`${activateServiceMessage}${" "} ${ServiceName}`)
            setShowAlert(true)
           // navigate('/sig');
           buttonClickedHandler(6)
        }else if(response.status===201){
           FetchUserDataUsage()
            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage(`${deactivateServiceMessage} ${" "} ${ServiceName}`)
            setShowAlert(true)
        }
    }catch(error){
        setAlertCode(53013)
        setAlertMessage(selectedError[53013])
        setShowAlert(true)
        setSpinnerLoading(false)
        return;
    }
}



const handleRowClickIndex = (index,serviceid) => {// we get the index of the row and the serviceid

      setchosenServiceid(serviceid)
    if (expandedRow === index) { // here we check if the index is the same as the expanded row
        setExpandedRow(null);
        setSpinnerLoading(false)
      } else {

        setExpandedRow(index);
        setSpinnerLoading(true)
        setTimeout(() => {
            setSpinnerLoading(false)
        },200);
      }
    };

// async function CreateExcelFile(){        
//     console.log("create excel file")
//         setSpinnerLoading(true)
//         try{
//             const response = await useGetData('createExcelFile')
//             if(response.status===200){
//                 setAlertCode(0)
//                 setAlertMessage('Excel file has been created')
//                 setShowAlert(true)
//                 setSpinnerLoading(false)
//             }
//     }
//     catch(error){
//         setSpinnerLoading(false)
//         setAlertCode(53014)
//         setAlertMessage(selectedError[53014])
//         setShowAlert(true)
//         return;
//     }
//     }


async function FetchBillingData(fakturaid){
    setChosenInvoiceId(fakturaid)
    setSpinnerLoading(true)
    try{
        const response = await useGetData(`getBillingData/${fakturaid}`)
        console.log(response)
        if(response.status===200){
            setBillingData(response.data[0])
            setBillingTotalAmount(response.data[1])
            setSpinnerLoading(false)
          //  setAlertCode(0)
           // setAlertMessage('Billing data has been fetched')
            //setShowAlert(true)

        }

    
      }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53015)
        setAlertMessage(selectedError[53015])
        setShowAlert(true)
    return;
}

}

const supportCategoryHandler=(val)=>{
    setSupportCategory(val)
}

const supportMessageHandler=(val)=>{

    setSupportMessage(val)
}

async function SendSupportMessageHandler(){
    setSpinnerLoading(true)
    try{
        const response = await usePostData('sendSupportMessage',{getSupportCategory,getSupportMessage})
        if(response.status===200){
            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage(supportMessageSendText1)
            setShowAlert(true)
            setSupportCategory(0)
            setSupportMessage('')
            FetchUserDataUsage()

        }
    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53016)
        setAlertMessage(selectedError[53016])
        setShowAlert(true)
    }
}

async function GetSupportTicketHandler(ticketid){
    setChosenTicketId(ticketid)
    
    setSpinnerLoading(true)
    try{
        const response = await useGetData(`getSupportTicket/${ticketid}`)
        if(response.status===200){
            setSupportTicket(response.data[0])
            setSpinnerLoading(false)
        }
    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53017)
        setAlertMessage(selectedError[53017])
        setShowAlert(true)
    }

}


async function DownloadPdfInvoice(fakturaid){
    console.log(fakturaid)
    setSpinnerLoading(true)
    try{
        const token = localStorage.getItem('auth_token');
        const headers = token ? { authorization: `Bearer ${token}` } : {};  
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/downloadPdfInvoice/${fakturaid}`,{ headers, responseType: 'blob' })
        if(response.status===200){
            const blob = new Blob([response.data], {type: 'application/pdf'});
            saveAs(blob, `Bakesoft_invoice_${fakturaid}.pdf`);

            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage(dowloadpdfOkMessage)
            setShowAlert(true)
        }
    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53019)
        setAlertMessage(selectedError[53019])
        setShowAlert(true)
    }
}

async function CustomerPaymentHandler(userid,serviceid,transactionId){
    console.log(transactionId)
    setSpinnerLoading(true)
    try{
        const response = await usePostData('paypalPaymentInsideVerification',{userid,serviceid,transactionId})
        if(response.status===200){
            console.log(response)
            FetchUserDataUsage()// renew user data
            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage(paypalPaymentConfirmText)
            setShowAlert(true)
        
        }
    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53020)
        setAlertMessage(selectedError[53020])
        setShowAlert(true)
    }
}

// const toggleMenu = () => {
//     setMenuOpen(!menuOpen);
// };

  // Function to fetch the Klarna client token from your backend
  const fetchKlarnaPaymentSession = async (invoice,serviceId,serviceName,totalSum,chosenCurrency) => {
    setChosenKlarnaInvoiceId(invoice)
    setchosenKlarnaServiceid(serviceId)
    setChosenKlarnaServiceName(serviceName)
    setChosenKlarnaTotalSum(totalSum)
    setChosenKlarnaCurrency(chosenCurrency)
    setSpinnerLoading(true)

    const totalSum1 = totalSum; // Example value in SEK
    const taxRate = 2500; // 25% , klarna we use only for
    const totalAmount = Math.round(totalSum1 * 100); // Total amount in minor units (e.g., cents)
    const totalTaxAmount = Math.round((totalAmount * taxRate) / (10000 + taxRate)); // Correct tax amount calculation
    

    try {
        const token = localStorage.getItem('auth_token');
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/klarnaPayment`, {
        method: 'POST',
        headers: {
            'authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
 
        body: JSON.stringify({  
            "purchase_country": getUserBillingAdressInfo[0].cc.toUpperCase(),
            "purchase_currency":chosenCurrency.toUpperCase(),
            "locale":getUserBillingAdressInfo[0].klarnaLocale,
            "order_amount": totalAmount,
            "order_tax_amount": totalTaxAmount,
            "order_lines": [{
                "name" :serviceName,
                "quantity" :1,
                "unit_price" :totalAmount,
                "tax_rate" : taxRate,
                "total_amount" : totalAmount,
                "total_discount_amount" : 0,
                "total_tax_amount" : totalTaxAmount,
                "merchant_data":`{"invoice":${invoice},"serviceId":${serviceId}}`
            },
      ],
        "billing_address": {
            "given_name": getUserBillingAdressInfo[0].givenName,
            "family_name": getUserBillingAdressInfo[0].familyName,
            "email": getUserBillingAdressInfo[0].email,
            "phone": getUserBillingAdressInfo[0].phone,
            "street_address": getUserBillingAdressInfo[0].address,
            "postal_code": getUserBillingAdressInfo[0].postalCode,
            "city": getUserBillingAdressInfo[0].city,
            "region": getUserBillingAdressInfo[0].region,
            "country": getUserBillingAdressInfo[0].cc.toUpperCase(),
        },

      }),
        });

      if(response.status===200){
      const data = await response.json();
      setSpinnerLoading(false)
      setClientToken(data.client_token);
      setKlarna(true) // showing  the klarna button
      }

    } catch (error) {
        setSpinnerLoading(false)
        setAlertCode(53021)
        setAlertMessage(selectedError[53021])
        setShowAlert(true)

    }
  };

  const finalizeKlarnaPayment = (authorizationToken,totalSum,totalTaxAmount) => {
    let vatRate=Math.round(getUserBillingAdressInfo[0].vatRate * 100)
    const token = localStorage.getItem('auth_token');
    setSpinnerLoading(true)
    
    fetch(`${process.env.REACT_APP_BACKEND_URL}/klarnaFinal`, {
        method: 'POST',
        headers: {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            service_id: getchosenKlarnaServiceid, // The service id the user pays for
            invoice_id: getchosenKlarnaInvoiceId, // The invoice id the user pays for
            country_code: getUserBillingAdressInfo[0].cc, // The country code of the user
            authorization_token: authorizationToken, // The authorization token
            purchase_country:getUserBillingAdressInfo[0].cc.toUpperCase(),
            purchase_currency:getChosenKlarnaCurrency,
            locale: getUserBillingAdressInfo[0].klarnaLocale,
            order_amount: totalSum, // Total order amount in minor units (e.g., öre for SEK)
            order_tax_amount: totalTaxAmount, // Total tax amount in minor units
            order_lines: [
                {
                    name: getChosenKlarnaServiceName, // Name of the service/item
                    quantity: 1, // Quantity of the service/item
                    unit_price: totalSum, // Unit price in minor units
                    tax_rate: vatRate, // Tax rate in Klarna's expected format (e.g., 2500 for 25%)
                    total_amount: totalSum, // Total amount for this item in minor units
                    total_discount_amount: 0, // Any discounts applied to this item, in minor units
                    total_tax_amount: totalTaxAmount, // Tax amount for this item in minor units
                    merchant_data: `{"invoice":${getchosenKlarnaInvoiceId},"serviceId":${getchosenKlarnaServiceid}}` // Custom merchant data
                }
            ],
            billing_address: {
                given_name: getUserBillingAdressInfo[0].givenName, // The first name of the customer
                family_name: getUserBillingAdressInfo[0].familyName, // The last name of the customer
                email: getUserBillingAdressInfo[0].email, // The email address of the customer
                phone: getUserBillingAdressInfo[0].phone, // The phone number of the customer
                street_address: getUserBillingAdressInfo[0].address, // The street address of the customer
                street_address2:'',
                postal_code: getUserBillingAdressInfo[0].postalCode, // The postal code of the customer
                city:getUserBillingAdressInfo[0].city, // The city where the customer resides
                region: getUserBillingAdressInfo[0].region, // The region where the customer resides
                country:getUserBillingAdressInfo[0].cc.toUpperCase(), // The country where the customer resides
          


            }
        }), 
    })
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            FetchUserDataUsage()
            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage(klarnaPaymentSuccessText)
            setShowAlert(true)
            setKlarna(false)

         // console.log('Payment completed successfully');
            // Handle success (e.g., show confirmation to user)
        } else {
            setSpinnerLoading(false)
            setAlertCode(53022)
            setAlertMessage(selectedError[53022])
            setShowAlert(true)

           // console.error('Payment completion failed', data);
            // Handle error (e.g., show error message to user)
        }
    })
    .catch(error => {
        console.error('Error finalizing payment', error);
    });
};

const UpdatesupportMessageHandler=(val,ticketid)=>{
console.log('im here')
 let myNewObjArr=[...getSupportTicket]
    myNewObjArr[0].benaemn=val
    setSupportTicket(myNewObjArr)


}


//start here   and on 3250
async function UpdateClientSideSupportTicket(ticketid){
    setSpinnerLoading(true)
    try{
        const response = await usePostData('updateClientSideSupportTicket',{ticket:ticketid,getSupportTicket})
        if(response.status===200){
            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage(clientSideUpdateSupportTicketText)
            setShowAlert(true)
        }
    }catch(error){
        setSpinnerLoading(false)
        setAlertCode(53025)
        setAlertMessage(selectedError[53025])
        setShowAlert(true)
    }
}
//start here 


  return (

<div>


<MyContext.Provider value={{
getName:getName||'',
getSurname:getSurname,
getAddress:getAddress,
getZipcode:getZipcode,
getCity:getCity,
getPhone:getPhone,
getEmail:getEmail,
getPassword:getPassword,
getPassword2:getPassword2,
getForetagName:getForetagName,
getUserDataUsage:getUserDataUsage,
getUserMainData:getUserMainData,
getUserInfo:getUserInfo,
getOldpass:getOldpass,
getNewpass:getNewpass,
getConfirmpass:getConfirmpass,
getUserAccountBalance:getUserAccountBalance,
getInvoiceNextDueDate:getInvoiceNextDueDate,
getUserInvoiceInfo:getUserInvoiceInfo,
getUserBillingAdressInfo:getUserBillingAdressInfo,
getTaxId:getTaxId,
getMaxValueSlider:getMaxValueSlider,
getMaxValue:getMaxValue,
getAccountInformation:getAccountInformation,
getServiceInformation:getServiceInformation,
getInvoiceRowInfo:getInvoiceRowInfo,
getServicePriceInformation:getServicePriceInformation,
getCurrencyInfo:getCurrencyInfo,
getServicesInformation:getServicesInformation,
getchosenServiceid:getchosenServiceid,
expandedRow:expandedRow,
getServicesSalesInformation:getServicesSalesInformation,
getMobileIsUsed:getMobileIsUsed,
getBillingData:getBillingData,
getOwningCompanyInfo:getOwningCompanyInfo,
websiteCompanyName:websiteCompanyName,
invoiceText:invoiceText,
broughtToYouText:broughtToYouText,
customerIdText:customerIdText,
serviceText:serviceText,
nameText:nameText,
numText:numText,
maxText:maxText,
debitText:debitText,
kreditText:kreditText,
totaltText:totaltText,
sumNetText:sumNetText,
sumVatText:sumVatText,
attBetalaText:attBetalaText,
bankgiroText:bankgiroText,
swishText:swishText,
termsText:termsText,
bankText:bankText,
bicText:bicText,
ibanText:ibanText,
getchosenInvoiceId:getchosenInvoiceId,
getCountry:getCountry,userFirstName,
userFamilyName:userFamilyName,
userCompany:userCompany,
userAdress:userAdress,
userPostalCode:userPostalCode,
userCity:userCity,
userRegion:userRegion,
userCountry:userCountry,
userPhone:userPhone,
userEmail:userEmail,
userSaveButtonText1:userSaveButtonText1,
userallServices:userallServices,
userService:userService,
userPrice:userPrice,
userActivate:userActivate,
numPageRequestsPerPage:numPageRequestsPerPage,
userPagenr:userPagenr,
userPageComponent:userPageComponent,
userPageNum:userPageNum,
userMyData:userMyData,
userMyDataDescriptionText:userMyDataDescriptionText,
userMyDataDescriptionText:userMyDataDescriptionText,
userMyData:userMyData,
userDataId:userDataId,
userDataName:userDataName,
userDataAntal:userDataAntal,
userDataId:userDataId,
userDataName:userDataName,
userDataAntal:userDataAntal,
userDataIngredientCategories:userDataIngredientCategories,
userDataRecipeCategories:userDataRecipeCategories,
userDataProductCategories:userDataProductCategories,
userDataIngredient:userDataIngredient,
userDataRecipes:userDataRecipes,
userDataProducts:userDataProducts,
userDataProductions:userDataProductions,
userDataRecipeImages:userDataRecipeImages,
userDataProductImages:userDataProductImages,
userDataInstructionText:userDataInstructionText,
useDataMyDataButtonText:useDataMyDataButtonText,
paymentPayPal:paymentPayPal,
paymentSwish:paymentSwish,
paymentCreditCard:paymentCreditCard,
paymentInvoice:paymentInvoice,

accountOverview:accountOverview,
userAccount:userAccount,
accountTransactionsFor:accountTransactionsFor,
accountService:accountService,
accountInvoice:accountInvoice,
accountDebit:accountDebit,
accountKredit:accountKredit,
accountDate:accountDate,
accountSystemComment:accountSystemComment,
accountTotalDebit:accountTotalDebit,
accountTotalKredit:accountTotalKredit,
accountTotalSaldo:accountTotalSaldo,
estimatedSaldo:estimatedSaldo,
estimatedSaldoText:estimatedSaldoText,
nextPaymenttext:nextPaymenttext,
prePaymentText:prePaymentText,
totalAmountUsed:totalAmountUsed,
invoiceHistoryText:invoiceHistoryText,
invoiceHistoryInvoice:invoiceHistoryInvoice,
invoiceHistorySum:invoiceHistorySum,
invoiceHistoryDate:invoiceHistoryDate,
invoiceHistoryStatus:invoiceHistoryStatus,
chooseInvoiceText:chooseInvoiceText,
invoiceRowsText:invoiceRowsText,
allInvoiceRowsText:allInvoiceRowsText,
invoiceRowsId:invoiceRowsId,
invoiceRowsService:invoiceRowsService,
invoiceRowsMax:invoiceRowsMax,
invoiceRowsRequest:invoiceRowsRequest,
invoiceRowsDebitQty:invoiceRowsDebitQty,
invoiceRowsPrice:invoiceRowsPrice,
invoiceRowsTotal:invoiceRowsTotal,
invoiceRowsType:invoiceRowsType,
invoiceRowsTotalSum:invoiceRowsTotalSum,

passwordValidText:passwordValidText,
newPasswordText:newPasswordText,
confirmPasswordText:confirmPasswordText,
showPasswordText:showPasswordText,
savePasswordButtonText:savePasswordButtonText,
iforgotMyPasswordText:iforgotMyPasswordText,
supportText:supportText,
settingsHeaderText:settingsHeaderText,
companyAndPersonalText:companyAndPersonalText,
addressInstructionText:addressInstructionText,
taxIdentificationVATText:taxIdentificationVATText,
taxIdInformationText:taxIdInformationText,
registerTaxIdButtonText:registerTaxIdButtonText,
maxValueWarningText:maxValueWarningText,
actiVateMaxValueText:actiVateMaxValueText,
maxValueButtonSaveText:maxValueButtonSaveText,
addressInfoButtontext:addressInfoButtontext,
servicesButtontext:servicesButtontext,
dataUsageButtontext:dataUsageButtontext,
mydataButtontext:mydataButtontext,
paymentButtontext:paymentButtontext,
myAccountButtonText:myAccountButtonText,
myInvoiceButtonText:myInvoiceButtonText,
passwordButtontext:passwordButtontext,
supportButtontext:supportButtontext,
settingsButtontext:settingsButtontext,
servicesButtonUsagetext:servicesButtonUsagetext,
getBillingData:getBillingData,
getBillingTotalAmount:getBillingTotalAmount,
userStat:userStat,
serviceHeaderText:serviceHeaderText,
servicePrisHeaderText:servicePrisHeaderText,
serviceActivateHeaderText:serviceActivateHeaderText,
serviceStatusHeaderText:serviceStatusHeaderText,
getUserTotalUsage:getUserTotalUsage,
debserviceidText:debserviceidText,
debserviceBenaemningText:debserviceBenaemningText,
debserviceQtyText:debserviceQtyText,
debserviceMaxText:debserviceMaxText,
debserviceUsableText:debserviceUsableText,
debserviceStatusText:debserviceStatusText,
getSupportCategories:getSupportCategories,
supportMText:supportMText,
supportMessageText:supportMessageText,
supportMessageSelectText:supportMessageSelectText,
supportMessageSendText:supportMessageSendText,
getSupportMessage:getSupportMessage,
getSupportTickets:getSupportTickets,
supportHistoryServiceIdText:supportHistoryServiceIdText,
supportHistoryDateText:supportHistoryDateText,
supportHistoryStatusText:supportHistoryStatusText,
getSupportTicket:getSupportTicket,
supportHistoryCategoryText:supportHistoryCategoryText,
messagesSentToSupportText:messagesSentToSupportText,
passwordHeaderText:passwordHeaderText,
resetPasswordHeaderText:resetPasswordHeaderText,
overviewText:overviewText,
overviewInformationText:overviewInformationText,
overviewInvoiceText:overviewInvoiceText,
invoiceSelectFirstOption:invoiceSelectFirstOption,
printInvoceButtonText:printInvoceButtonText,
getCountry:getCountry,
getMobileIsUsed:getMobileIsUsed,



InvoiceMaxVal:InvoiceMaxVal,
taxIdHandler:taxIdHandler,
setNameHandler:setNameHandler,
setSurNameHandler:setSurNameHandler,
setAddressHandler:setAddressHandler,
setZipCodeHandler:setZipCodeHandler,
setCityHandler:setCityHandler,
setPhoneHandler:setPhoneHandler,
setEmailHandler:setEmailHandler,
setPasswordHandler:setPasswordHandler,
setPassword2Handler:setPassword2Handler,
setForetagNameHandler:setForetagNameHandler,
SendUserDataByEmail:SendUserDataByEmail,
saveUserAddressInfoHandler:saveUserAddressInfoHandler,
setRegionHandler:setRegionHandler,
setCountryHandler:setCountryHandler,
savePrescriptionHandler:SavePrescriptionHandler,
consentHandler:consentHandler,
setNewPasswordHandler:setNewPasswordHandler,
saveNewPasswordHandler:SaveNewPassword,
sendPassByEmailToUser:SendPassByEmailToUser,
setMaxValue:setMaxValue,
toggleMaxValueSlider:toggleMaxValueSlider,

// ServicesAddHandler:ServicesAddHandler,
// CheckUserAccount:CheckUserAccount,
CheckIfBaseService:CheckIfBaseService,

handleRowClickIndex:handleRowClickIndex,
setSpinnerLoading:setSpinnerLoading,
FetchBillingData:FetchBillingData,
supportCategoryHandler:supportCategoryHandler,
supportMessageHandler:supportMessageHandler,
SendSupportMessageHandler:SendSupportMessageHandler,
GetSupportTicketHandler:GetSupportTicketHandler,
DownloadPdfInvoice:DownloadPdfInvoice,
getServiceInformation:getServiceInformation,
getCurrencyInfo:getCurrencyInfo,
getTotalCurrencyInfo:getTotalCurrencyInfo,

// paypal
paypalMainHeaderText:paypalMainHeaderText,
pptotalAmountToPayText:pptotalAmountToPayText,
ppChosenServiceText:ppChosenServiceText,
ppChosenCurrencyText:ppChosenCurrencyText,
registerAccountTabText:registerAccountTabText,
paymentTabText:paymentTabText,
ppBakesoftTermsText:ppBakesoftTermsText,
ppBakesoftTerms:ppBakesoftTerms,
paypalPaymentConfirmText:paypalPaymentConfirmText,
CustomerPaymentHandler:CustomerPaymentHandler,
setAlertMessage:setAlertMessage,
setShowAlert:setShowAlert,
ppSelectInvoiceText:ppSelectInvoiceText,
ppSelectCurrencyText:ppSelectCurrencyText,
userDebitServices:userDebitServices,
myinformationText:myinformationText,
getUserMonthlyUsageCost:getUserMonthlyUsageCost,
getVatInfo:getVatInfo,
klarnaimage:klarnaimage,
// getKlarnaSnippet:getKlarnaSnippet,
fetchKlarnaPaymentSession:fetchKlarnaPaymentSession,
clientToken:clientToken,
// loadKlarnaScript:loadKlarnaScript,
klarnaMainHeaderText:klarnaMainHeaderText,
paymentKlarna:paymentKlarna,
getKlarna:getKlarna,
setKlarna:setKlarna,
fetchKlarnaPaymentSession:fetchKlarnaPaymentSession,
finalizeKlarnaPayment:finalizeKlarnaPayment,
getChosenKlarnaServiceName:getChosenKlarnaServiceName,
getchosenKlarnaServiceid:getchosenKlarnaServiceid,
getChosenKlarnaTotalSum:getChosenKlarnaTotalSum,
paymentStatus:paymentStatus,
getCountry:getCountry,
klarnaPaymentfailureText:klarnaPaymentfailureText,
supportButtonAnswerText:supportButtonAnswerText,
UpdatesupportMessageHandler:UpdatesupportMessageHandler,
getChosenTicketId:getChosenTicketId,
UpdateClientSideSupportTicket:UpdateClientSideSupportTicket,
supportHistoryBenaemnText:supportHistoryBenaemnText,





}}>



<div className="xxtotWrapper">    
 <div className={getBackGroundShade<1?"xxatotalArea":"xxatotalArea1"}>


 <div className="xxaTopArea">
        {getBackGroundShade<1?<img src={imageUrl} alt="Bakesoft" className="dabackgroundImageRecepyNew10" />:''}
</div>

         
             <div className="xxaheaderArea">
                <div className={getBackGroundShade<1?"xxacompName":"xxacompName1"}>{title}</div>
             </div>
            

   
        <div className="xxarangeArea">
             <input type="range" min="0.0" max="1" value={getMenuShade||0.2} step="0.10" id={2}
            title={'test'} className="xxarange2" onChange={(e)=>SetmenuColorHandler(e.target.value)}/>

             <input type="range" min="0.0" max="1" value={getBackGroundShade||0.2} step="0.10" id={2}
            title={'itest'} className="xxarange1" onChange={(e)=>SetBackGroundShadeHandler(e.target.value)}/>
          </div>
   



        <div className="areaA">
    
        
            <div className="menuTopWrapper">
            <div className="menuTop">
                <button className={getButtonClicked!==1?"menupersonal":"menupersonal1"} onClick={()=>buttonClickedHandler(1)}>{addressInfoButtontext}</button>
                <button className={getButtonClicked!==2?"menupersonal":"menupersonal1"} onClick={()=>buttonClickedHandler(2)}>{servicesButtontext}</button>
                <button className={getButtonClicked!==12?"menupersonal":"menupersonal1"} onClick={()=>buttonClickedHandler(12)}>{servicesButtonUsagetext}</button>
                <button className={getButtonClicked!==4?"menupersonal":"menupersonal1"} onClick={()=>buttonClickedHandler(4)}>{dataUsageButtontext}</button>
                <button className={getButtonClicked!==5?"menupersonal":"menupersonal1"} onClick={()=>buttonClickedHandler(5)}>{mydataButtontext}</button>
                <button className={getButtonClicked!==6?"menupersonal":"menupersonal1"} onClick={()=>buttonClickedHandler(6)}>{paymentButtontext}</button>
                <button className={getButtonClicked!==7?"menupersonal":"menupersonal1"} onClick={()=>buttonClickedHandler(7)}>{myAccountButtonText}</button>
                <button className={getButtonClicked!==8?"menupersonal":"menupersonal1"} onClick={()=>buttonClickedHandler(8)}>{myInvoiceButtonText}</button>
                <button className={getButtonClicked!==9?"menupersonal":"menupersonal1"} onClick={()=>buttonClickedHandler(9)}>{passwordButtontext}</button>
                <button className={getButtonClicked!==10?"menupersonal":"menupersonal1"} onClick={()=>buttonClickedHandler(10)}>{supportButtontext}</button>
                <button className={getButtonClicked!==11?"menupersonal":"menupersonal1"} onClick={()=>buttonClickedHandler(11)}>{settingsButtontext}</button>

            </div>
            </div>
        
        </div> 
        <div className="areaB">

{getButtonClicked===1 ? <Adressinfo/> : ''}
{getButtonClicked===2 ? <Tjanster/> : ''}
{/* {getButtonClicked===2 && getMobileIsUsed===false ? <Abonemang/> : ''} */}
{/* {getButtonClicked===2 && getMobileIsUsed===true ? <MobileAbonnemang/> : ''} */}
{getButtonClicked===3 ? <Tjanster/> : ''}
{getButtonClicked===4 ? <DataAnvandning/> : ''}
{getButtonClicked===5 ? <MinData/> : ''}
{getButtonClicked===6 ? <Betalning/> : ''}
{getButtonClicked===7 ? <KontoTop/> : ''}
{getButtonClicked===8 ? <MinaFakturor/> : ''}
{getButtonClicked===9 ? <Pass/> : ''}
{getButtonClicked===10? <SupportHeader/> : ''}
{getButtonClicked===11? <InvoiceSettings/> : ''}
{getButtonClicked===12? <TjansterDebitering/> : ''}

            </div>
    </div>
    </div>


{getSpinnerLoading && (
    <div className="spinnerContainer">
        <RotatingLines strokeColor="white" strokeWidth={getMobileIsUsed ? "2" : "2"} animationDuration="1" width="100" visible={true} />
    </div>
)}


  {getShowAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}/>:''}

  </MyContext.Provider>

</div>


  )
}

function Adressinfo(){
const ctx = useContext(MyContext);

let givenName = ctx.getUserInfo && ctx.getUserInfo[0].givenName || ''
let familyName = ctx.getUserInfo && ctx.getUserInfo[0].familyName || ''
let company = ctx.getUserInfo && ctx.getUserInfo[0].company || ''
let address = ctx.getUserInfo && ctx.getUserInfo[0].address || ''
let postalCode = ctx.getUserInfo && ctx.getUserInfo[0].postalCode || ''
let city= ctx.getUserInfo && ctx.getUserInfo[0].city || ''
let region = ctx.getUserInfo && ctx.getUserInfo[0].region || ''
let phone= ctx.getUserInfo && ctx.getUserInfo[0].phone || ''
let email = ctx.getUserInfo && ctx.getUserInfo[0].email || ''
let country = ctx.getUserInfo && ctx.getUserInfo[0].country || ''


    return(
        <>
        <div className="xxaadressinfoHeaderWrapper">
        <div className="xxaadressinfoHeaderText">{ctx.myinformationText}</div>
    </div>
    <div className="xxaInfoTop">
         <div className="xxaInfoWrapper">
    <div className="adressinfoTop">

            <div className="row">
                <div className="column-a">
                    <label htmlFor="inputname" className='lblinputname'>{ctx.userFirstName}:</label>
                </div>
                <div className="column-b">
                    <input type="text" id="namn" className="input" autoComplete=''
                        onChange={(e) => ctx.setNameHandler(e.target.value)}
                        value={givenName || ''} />
                </div>
            </div>

            <div className="row">
                <div className="column-a">
                    <label htmlFor="inputname" className='lblinputname'>{ctx.userFamilyName}:</label>
                </div>
                <div className="column-b">
                    <input type="text" id="efternamn" className="input" autoComplete=''
                        onChange={(e) => ctx.setSurNameHandler(e.target.value)}
                        value={familyName || ''} />
                </div>
            </div>
            <div className="row">
                <div className="column-a">
                    <label htmlFor="inputname" className='lblinputname'>{ctx.userCompany}:</label>
                </div>
                <div className="column-b">
                    <input type="text" id="foretag" className="input" 
                        onChange={(e) => ctx.setForetagNameHandler(e.target.value)}
                        value={company || ''} />
                </div>
            </div>

            <div className="row">
                <div className="column-a">
                    <label htmlFor="inputname" className='lblinputname'>{ctx.userAdress}:</label>
                </div>
                <div className="column-b">
                    <input type="text" id="adress" className="input" autoComplete=''
                        onChange={(e) => ctx.setAddressHandler(e.target.value)}
                        value={address || ''} />
                </div>
            </div>
            <div className="row">
                <div className="column-a">
                    <label htmlFor="inputname" className='lblinputname'>{ctx.userPostalCode}:</label>
                </div>
                <div className="column-b">
                    <input type="text" id="postnr" className="input" autoComplete=''
                        onChange={(e) => ctx.setZipCodeHandler(e.target.value)}
                        value={postalCode || ''} />
                </div>
            </div>

            <div className="row">
                <div className="column-a">
                    <label htmlFor="inputname" className='lblinputname'>{ctx.userCity}:</label>
                </div>
                <div className="column-b">
                    <input type="text" id="stad" className="input" autoComplete='Stad'
                        onChange={(e) => ctx.setCityHandler(e.target.value)}
                        value={city|| ''} />
                </div>
            </div>
            <div className="row">
                <div className="column-a">
                    <label htmlFor="inputname" className='lblinputname'>{ctx.userRegion}:</label>
                </div>
                <div className="column-b">
                    <input type="text" id="region" className="input" autoComplete=''
                        onChange={(e) => ctx.setRegionHandler(e.target.value)}
                        value={region|| ''} />
                </div>
            </div>
            <div className="row">
                <div className="column-a">
                    <label htmlFor="inputname" className='lblinputname'>{ctx.userCountry}:</label>
                </div>
                <div className="column-b">
                    <input type="text" id="region" className="input" autoComplete=''
                        onChange={(e) => ctx.setCountryHandler(e.target.value)}
                        value={country|| ''} />
                </div>
            </div>


            <div className="row">
                <div className="column-a">
                    <label htmlFor="inputname" className='lblinputname'>{ctx.userPhone}:</label>
                </div>
                <div className="column-b">
                    <input type="text" id="telefon" className="input" autoComplete=''
                        onChange={(e) => ctx.setPhoneHandler(e.target.value)}
                        value={phone || ''} />
                </div>
            </div>

            <div className="row">
                <div className="column-a">
                    <label htmlFor="inputname" className='lblinputname'>{ctx.userEmail}:</label>
                </div>
                <div className="column-b">
                    <input type="text" id="email" className="input" autoComplete=''
                    disabled
                    style={{backgroundColor:'lightgrey'}}
                        onChange={(e) => ctx.setEmailHandler(e.target.value)}
                        value={email || ''} />
                </div>
            </div>


            <div className="row">
                <div className="column-a">

                </div>
                <div className="column-b">

                <button className="saveAddressInfoButton"
                onClick={ctx.saveUserAddressInfoHandler}
                >{ctx.userSaveButtonText1}</button>

                </div>
            </div>


         </div>
        </div>
        </div>
        </>

    )
}



function Tjanster(){
    const ctx = useContext(MyContext);
    let vatRate=ctx.getVatInfo[0].vatRate

     // here we will account with currency xchange
     let currencySymbol=ctx.getCurrencyInfo[0].currencySymbol
     let xchangeRate=ctx.getCurrencyInfo[0].xchangeRate
     let salesInfo=ctx.getServicesSalesInformation

     function classPicker(istatus){
        if(istatus===null){
            return("slider2 round")
        }else if(istatus===1){
            return("slider1 round")
        }else if(istatus==0){
            return("slider2 round")
        }else if(istatus===2){
            return("slider2 round")
        }else if(istatus===3){
            return("slider2 round")
        }
     }

     function checkedPicker(istatus){
        if(istatus===null){
            return(false)
        }else if(istatus===1){
            return(true)
        }else if(istatus==0){
            return(false)
        }else if(istatus===2){
            return(true)
        }else if(istatus===3){
            return(false)
        }
     }



            return(
    <div className="tjansterTop">
         <div className="tjansterHeaderWrapper">
                <div className="tjansterHeaderText">{ctx.userallServices}</div>
         </div>
       
            <div className="tjansterWrapper">
            {ctx.getServicesInformation.map((data,index)=>(
                <div key={index} className="tjansterRow">

                       {index>0?
                    <div className="tjansterId" 
                    onClick={()=>ctx.handleRowClickIndex(index,data.serviceid)}
                    >{data.serviceid}</div>
                    :<div className="tjansterId1"></div>}

                    

                     {index>0?
                    <div className="tjansterBenaemn" title='Klicka för att se mer information'
                    onClick={()=>ctx.handleRowClickIndex(index,data.serviceid)}
                    >{data.benaemn}</div>
                    :<div className="tjansterBenaemn1">{ctx.serviceHeaderText}</div>}


                    {index>0?
                    <div className="tjansterPris">{currencySymbol}{' '}

                    {(data.price/xchangeRate < 10) ? ((data.price / xchangeRate) * (1 + vatRate/100)).toFixed(2): Math.floor((data.price / xchangeRate) * (1 + vatRate/100))} /{data.quoteText}
                  </div>
                  :<div className="tjansterPris1">{ctx.servicePrisHeaderText}</div>}



                 {index===0?(
                    <div className="tjansterAdd1">{ctx.serviceActivateHeaderText}</div>
                 ):(
                  index>0 && data.prescriptionControl === 1 && (
                 
                  <div className="tjansterAdd">
                            <label className="switch1">
                                <input type="checkbox"  onChange={(e) => ctx.CheckIfBaseService(e.target.checked, data.serviceid)} checked={checkedPicker(data.Istatus)} />
                                <span className={classPicker(data.Istatus)}></span>
                           
                            </label>
                        </div>
                  )
                    )}

                   {index>0? 
                   <div className="tjansterStat">{data.sysbenaemn}</div>
                   :<div className="tjansterStat1">{ctx.serviceStatusHeaderText}</div>}
                   
                 {ctx.expandedRow === index && (
                        <div className="tjansteranimated">
                            <div className="tjansteranimatedText">
                                {salesInfo.filter(data => data.serviceid === ctx.getchosenServiceid).map((data, index) => (
                                <div key={index} className="tjansteranimatedRow">
                                    <div className="tjansteranimatedBenaemn"><li>{data.benaemn}</li></div>

                               </div>

                                ))}

                            </div>
                    
                </div>)}
              
              </div>
            ))}
         
        </div>
    </div>
   



    )

}


function DataAnvandning(){
    const ctx = useContext(MyContext);
    return(
    <div className="daAnvandningTop">
        <div className="daAnvandningHeader">
            <div className="daAnvandningText">{ctx.numPageRequestsPerPage}</div>
        </div>
{/* 
      <div className="daheaderTopWrapper">
        <div className="daHeaderTop">
            <div className="daHeaderTrackid">{ctx.userPagenr}</div>
            <div className="daHeaderBenaemn">{ctx.userPageComponent}</div>
            <div className="daHeaderAntal">{ctx.userPageNum}</div>
        </div>
        </div>
         <div className='ddrowTopWrapper'>
        <div className="ddRowTop">
            {ctx.getUserDataUsage.map((data,index)=>(
             <div key={index} className="ddRow">
            <div className="daRowTrackid">{data.trackid}</div>
            <div className="daRowBenaemn">{data.benaemn}</div>
            <div className="daRowAntal">{data.antal}</div>
            </div>
            ))}
        </div>
        </div> */}
<div className='ddrowTopWrapper'>
    <div className="ddRowTop">
        {ctx.getUserDataUsage.map((data, index) => (
            <div key={index} className="ddRow">
                {index === 0 && (
                 
                            <><div className="daRowTrackid">{ctx.userPagenr}</div>
                            <div className="daRowBenaemn">{ctx.userPageComponent}</div>
                            <div className="daRowAntal">{ctx.userPageNum}</div></>
              
                )}
                {index > 0 && (
                    <>
                        <div className="daRowTrackid">{data.trackid}</div>
                        <div className="daRowBenaemn">{data.benaemn}</div>
                        <div className="daRowAntal">{data.antal}</div>
                    </>
                )}
            </div>
        ))}
    </div>
</div>



    </div>





    )
}


function MinData(){
    const ctx = useContext(MyContext);

    return(

<div className="minDataTop">
    <div className="minDataHeaderTextWrapper">
        <div className="minDataHeaderText">{ctx.userMyData}</div>
    </div>
    <div className="minaDataInformation">{ctx.userMyDataDescriptionText}</div>
    <div className="minaDataHeaderTopWrapper">
        <div className="minaDataHeaderTop header">
            <div className="minaDataHeaderServicesId1">{ctx.userDataId}</div>
            <div className="minaDataHeaderServicesBenaemn1">{ctx.userDataName}</div>
            <div className="minaDataHeaderServicesAntal1">{ctx.userDataAntal}</div>
        </div>
        {ctx.getUserMainData.map((data, index) => (
            <div key={index} className="minaDataHeaderTop listItem">
                <div className="minaDataHeaderServicesId">{index + 1}</div>
                <div className="minaDataHeaderServicesBenaemn">{data.category}</div>
                <div className="minaDataHeaderServicesAntal">{data.num}</div>
            </div>
        ))}
    </div>
    <div className="minaDataSendInfo">
        <div className="minaDataSendInfoText">{ctx.userDataInstructionText}</div>
        <button onClick={ctx.SendUserDataByEmail} className="minaDataSendInfoButton">
            {ctx.useDataMyDataButtonText}
        </button>
    </div>
</div>




    )
}

function Betalning(){
    const ctx = useContext(MyContext);
    const[getInvoiceTab,setInvoiceTab]=useState(1)
 //   ctx.setKlarna(false)
    return(
        
        <div className="psinvoiceTop">
        <div className="psInvoiceTabTop">
        <button className={`psinvoiceTab psinvoicefirstButton ${getInvoiceTab===1?'active':''}`}
        onClick={()=>setInvoiceTab(1)}>{ctx.paymentPayPal}</button>

        {ctx.getCountry==="se"  ?
        <button className={`mobileTab ${getInvoiceTab===2?'active':''}`}
        // show klarna only to swedish users
        onClick={()=>setInvoiceTab(2)}>{ctx.paymentKlarna}</button>
        :''}


        <button className={`psinvoiceTab psinvoicelastButton ${getInvoiceTab===4?'active':''}`}
        onClick={()=>setInvoiceTab(4)}>{ctx.paymentSwish}</button>

    </div>

        <div className="psinvoiceTabContent">
        {getInvoiceTab===1 ? <BetalningPayPal/> : ''}
        {getInvoiceTab===2 ? 
          <><PaymentHeader /></> : ''}
        {getInvoiceTab===4 ? <BetalningFaktura/> : ''}
        </div></div>
    )
}
function BetalningPayPal(){
    const ctx = useContext(MyContext);

    const[getServiceId,setServiceId]=useState(1)    // serviceid
    const[getTotalSum,setTotalSum]=useState('')   
    const[getChosenInvoice,setChosenInvoice]=useState(0)    // invoice id
    const[getChosenCurrency,setChosenCurrency]=useState(0)   // chosen currency, this is the id of the currency
    const[getChosenCurrencyName,setChosenCurrencyName]=useState('')  // chosen currency name
    const[getServiceName,setServiceName]=useState('')
    const[getChosenXchangeRate,setChosenXchangeRate]=useState(1)
    const[getSumWithChosenCurrency,setSumWithChosenCurrency]=useState('')
    const[getuserid,setuserid]=useState(0)
    const[getLanguageCode,setLanguageCode]=useState('')
    const[getShowSelect,setShowSelect]=useState(true)
    const[getChosenVatRate,setChosenVatRate]=useState(0)

    // all invoice info
     let getUserInvoiceInfo=ctx.getUserInvoiceInfo.filter(data=>data.payStatus===1)
     let serviceInfo=ctx.getServiceInformation


    useEffect(()=>{
        if(getChosenInvoice>0){
            let myNewObjArr = [...getUserInvoiceInfo];
            let myNewIndex = myNewObjArr.findIndex(data => data.fakturaid == getChosenInvoice);
           
            
            if (myNewIndex !== -1) {
                let { totalsum } = myNewObjArr[myNewIndex]; // Ensure the property name matches exactly
                let { serviceid } = myNewObjArr[myNewIndex]; 
                
            setTotalSum(totalsum)
            setServiceId(serviceid)

            let myNewObjArr1 = [...serviceInfo];
            let myNewIndex1 = myNewObjArr1.findIndex(data => data.serviceid == serviceid);
            let { benaemn } = myNewObjArr1[myNewIndex1]; // Ensure the property name matches exactly
            setServiceName(benaemn)
                
            } 

        let userid=ctx.getUserBillingAdressInfo && ctx.getUserBillingAdressInfo.length>0 && ctx.getUserBillingAdressInfo[0].userid
        setuserid(userid)
        setLanguageCode(ctx.getCountry)
        }
        },[getChosenInvoice])

        useEffect(()=>{
        if(getChosenCurrency>0){
        setChosenXchangeRate(ctx.getTotalCurrencyInfo.filter(data=>data.currencyid==getChosenCurrency)?.[0].xchangeRate)
        setChosenCurrencyName(ctx.getTotalCurrencyInfo.filter(data=>data.currencyid==getChosenCurrency)?.[0].currencyName)
        setShowSelect(false)
        setChosenVatRate(ctx.getVatInfo[0].vatRate)
        }
        },[getChosenCurrency])
     

        useEffect(()=>{
            if(getChosenXchangeRate>0){
            let sum=Math.floor(getTotalSum/getChosenXchangeRate)
            //add vat
            // for usd and eur and sek we really dount want math.floor, since if a user shall pau 6.64 usd math.floor will make it 6 usd¨
            // so we only use math.floor for other currencies yen
            if(getChosenCurrencyName==='USD'){
                sum=sum*(1+getChosenVatRate/100)
            }else if(getChosenCurrencyName==='JPY'){
            sum=Math.floor(sum*(1+getChosenVatRate/100))
            }else if (getChosenCurrencyName==='SEK'){
                sum=sum*(1+getChosenVatRate/100)
            }
        

            setSumWithChosenCurrency(sum)
            }

        },[getChosenXchangeRate])



    const initialOptions = {
 clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
   currency:getChosenCurrencyName
     
       };


    return(
        <div className="payPalInsideTop">
            {getShowSelect===true?(
            <><div className="payPalSelectWrapper">
                    <select className="payPalSelect" onChange={(e) => setChosenInvoice(e.target.value)}>
                        <option>{ctx.ppSelectInvoiceText}</option>
                        {getUserInvoiceInfo.map((data, index) => (
                            <option key={index} value={data.fakturaid}>{data.fakturaid}</option>
                        ))}
                    </select>
                </div><div className="payPalSelectWrapper">
                        <select className="payPalSelect" onChange={(e) => setChosenCurrency(e.target.value)}>
                            <option>{ctx.ppSelectCurrencyText}</option>
                            {ctx.getTotalCurrencyInfo.map((data, index) => (
                                <option key={index} value={data.currencyid}>{data.currencyName}</option>
                            ))}
                        </select>
                    </div></>
            ):''}

        
            <div className="payPalWrapperInside">
                {/* {getServiceId>0?
                <div className="payPalBakesoftHeaderWrapperInside">
                <div className="insidepayPalBakesoftHeader">{ctx.paypalMainHeaderText}</div>
            </div>:''} */}
       
       
         <div className="ppBakesoftTextWrapperInside">
            <div className="ppBakesofttext">{ctx.ppChosenServiceText}:&nbsp;{getServiceName}</div>
            <div className="ppBakesofttext">{ctx.pptotalAmountToPayText}:&nbsp;{getSumWithChosenCurrency}&nbsp;{getChosenCurrencyName}</div>
            <div className="ppBakesofttext">{ctx.ppBakesoftTermsText}:&nbsp;{ctx.ppBakesoftTerms}</div>

        </div>
        <div className='ppBakesoftButtonWrapperInside'>
            {getChosenCurrencyName.length>0&&getLanguageCode.length>0&&getSumWithChosenCurrency&&getuserid>0&&getServiceId>0?
            <PayPalButton
            initialOptions={initialOptions}
            getChosenCurrencyName={getChosenCurrencyName}
            getSumWithChosenCurrency={getSumWithChosenCurrency}
            getServiceId={getServiceId}
            userid={getuserid}
            languageCode={getLanguageCode}

            />
            :''}
  
            </div>

        </div>
        </div>
    
    )

}
function PayPalButton(props){
    let {getChosenCurrencyName,getSumWithChosenCurrency,userid,getServiceId,
        languageCode,initialOptions
    }=props
    let ctx=useContext(MyContext)

    const styles = {
        shape: "rect",
        layout: "vertical",
        disableMaxWidth: true,
        label:"pay",
      
      };   

    return(
           
       <div className="payPalButtonWrapperInside">
     
       <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
    
          style={styles}
          createOrder={(data, actions) => {
            return actions.order.create({



              purchase_units: [{
                amount: {
                  currency_code:getChosenCurrencyName,
                  value:getSumWithChosenCurrency,
                },
                custom_id: JSON.stringify({
                 userId:userid,
                 serviceId:getServiceId,
                  languageCode:languageCode,
                  bakesofthookVal:2,
                }),
              }],
              application_context: {
                shipping_preference: 'NO_SHIPPING', // Disable shipping address
              }
            });
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then(details => {
                // transaction id
                const transactionID = data.orderID;
                ctx.CustomerPaymentHandler(userid,getServiceId,transactionID)
             // alert('Transaction completed by ' + details.payer.name.given_name);
            });
          }}
          onError={(err) => {
            console.error("PayPal Button Error:", err);
            ctx.setAlertMessage("An error occurred during the PayPal transaction.");
            ctx.setShowAlert(true);
          }}
        />
      </PayPalScriptProvider>


      </div>
    )
}

function BetalningKlarna(){
    const ctx = useContext(MyContext);
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        // Load the Klarna script
        const script = document.createElement('script');
        script.src = 'https://x.klarnacdn.net/kp/lib/v1/api.js';
        script.async = true;
        document.head.appendChild(script);

        // Initialize Klarna Payments once the script is loaded
        script.onload = () => {
            if (window.Klarna) {
                window.Klarna.Payments.init({
                    client_token:ctx.clientToken, // Replace with your actual client token
                });

                window.Klarna.Payments.load({
                    container: '#klarna_container',
                    payment_method_category: 'pay_over_time',
                }, function (res) {
                    console.log('Load function called');
                    console.debug(res);
                });
            }
        };

        return () => {
            // Cleanup: remove the script when the component unmounts
            document.head.removeChild(script);
        };
    }, [ctx.clientToken]);

    const HandleAuthorize = () => {
        console.log(ctx.getUserBillingAdressInfo[0].vatRate)
        let totalSum=Math.round(ctx.getChosenKlarnaTotalSum*100)
        let vatRate=Math.round(ctx.getUserBillingAdressInfo[0].vatRate * 100)
        let totalVatAmount = Math.round((totalSum * vatRate) / (10000 + vatRate)); 
  
    
        if (window.Klarna) {
            window.Klarna.Payments.authorize({
                payment_method_category: 'pay_over_time',
            }, {
                billing_address: {
                    given_name:ctx.getUserBillingAdressInfo[0].givenName,
                    family_name:ctx.getUserBillingAdressInfo[0].familyName,
                    email: ctx.getUserBillingAdressInfo[0].email,
                    street_address:ctx.getUserBillingAdressInfo[0].address,
                    postal_code:ctx.getUserBillingAdressInfo[0].postalCode,
                    city:ctx.getUserBillingAdressInfo[0].city,
                    region:ctx.getUserBillingAdressInfo[0].region,
                    phone:ctx.getUserBillingAdressInfo[0].phone,
                    country:ctx.getUserBillingAdressInfo[0].cc,
                    
                },
                order_amount:totalSum,
                order_tax_amount:totalVatAmount,
                order_lines: [
                    {
                        type: 'physical',
                        reference:ctx.getchosenKlarnaServiceid,
                        name: ctx.getChosenKlarnaServiceName,
                        quantity: 1,
                        unit_price:totalSum,
                        tax_rate: vatRate,
                        total_amount:totalSum,
                        total_discount_amount: 0,
                        total_tax_amount:totalVatAmount,
                    },
                ],
            }, function (res) {
                if (res.approved) {
                   // console.log('Payment authorized', res.authorization_token);
                    
                    // Send the authorization_token to your backend to finalize the order
                    ctx.finalizeKlarnaPayment(res.authorization_token,totalSum,totalVatAmount)
                } else {
                    ctx.setKlarna(false)
                    ctx.setAlertCode(0)
                    ctx.setAlertMessage(ctx.klarnaPaymentfailureText)
                    ctx.setShowAlert(true)
                    console.error('Payment authorization failed', res);
                }
            });
        }
    };







    return(
    <div style={{ width: '250px', paddingTop: '10px', paddingBottom: '10px' }}>
    <img src="https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.svg" alt="Klarna" style={{ width: '250px', margin: 'auto' }} />
    {/* <div id="klarna_container" style={{ width: '250px', margin: 'auto' }}></div>
    <button onClick={HandleAuthorize} style={{ width: '250px', height: '50px', margin: 'auto', display: 'block' }}>
        Your Buy Button
    </button> */}
    <div id="klarna_container" style={{ width: '250px', margin: 'auto' }}></div>
            <div className="klarnaInitialButton" onClick={HandleAuthorize}>
                <div className="klarnaMessage">Betala med&nbsp;</div>
                <div className="klarnalogoWrapper">
                    <img src={ctx.klarnaimage} alt="Klarna Logo" className="klarnaButtonImage" />
                </div>
            </div>
    

</div>
       
    )
}

function BetalningFaktura(){
    const ctx = useContext(MyContext);
    return(
        <div>{ctx.paymentInvoice}</div>
    )
}
function KontoTop(){
    const ctx = useContext(MyContext);
    const[getInvoiceTab,setInvoiceTab]=useState(1)
    return(
        <div className="psinvoiceTop">
        <div className="psInvoiceTabTop">
        <button className={`psinvoiceTab psinvoicefirstButton ${getInvoiceTab===1?'active':''}`}
        onClick={()=>setInvoiceTab(1)}>{ctx.accountOverview}</button>
        <button className={`mobileTab ${getInvoiceTab===2?'active':''}`}
        onClick={()=>setInvoiceTab(2)}>{ctx.userAccount}</button>


    </div>

        <div className="psinvoiceTabContent">
        {getInvoiceTab===1 ? <InvoiceOverView/> : ''}
        {getInvoiceTab===2 ? <Konto/> : ''}


        </div></div>
    )

}

function Konto(){
    const ctx = useContext(MyContext);
    const[getId,setId]=useState('')

    // here we will account with currency xchange
    let currencySymbol=ctx.getCurrencyInfo[0].currencySymbol
    let xchangeRate=ctx.getCurrencyInfo[0].xchangeRate

    let fullremark = ctx.getAccountInformation.find(service => service.serviceid === getId)?.remark || '';
    let serviceName = ctx.getServiceInformation.find(service => service.serviceid === getId)?.benaemn || '';
    let userNameInfo = ctx.getUserBillingAdressInfo[0].givenName||''+' '+ctx.getUserBillingAdressInfo[0].familyName||''
    let userTotKredit=ctx.getUserAccountBalance[0].kreditsum
    let userTotKreditInLocalCurrency=userTotKredit/xchangeRate
    let serviceBalance=ctx.getUserAccountBalance[0].netbalance   // debit - kredit
    let userPaymentBalance=ctx.getUserAccountBalance[0].userBalance  // userdebit-userkredit
    let saldo=serviceBalance-userPaymentBalance   // debit-kredit-userdebit/userkredit
       saldo = saldo/xchangeRate
    let rowInfo=ctx.getInvoiceRowInfo

    return(
        <div className="kontoTop">
            <div className="kontoHeaderWrapper">
            <div className="kontoHeader">{ctx.userAccount}</div>
            </div>
            <div className="kontoInfoTop">
            <div className="kontoInfoWrapper">
                 {/* // make achange here to reverse the order , set name first then the words
                 // */}
               {ctx.getCountry!=='ja'?
                <div className="kontoInfoHeader">{ctx.accountTransactionsFor}{' '} {userNameInfo} </div>
                :<div className="kontoInfoHeader">{userNameInfo}{' '}{ctx.accountTransactionsFor}</div>}
               
               
                {/* // here will will make a header, for the user to see the information about the account
                serviceid,fakturaid,debit,kredit,createDate,remark */}
                <div className="kontoInfoHeaderWrapper">
                    <div className="kontoInfoService">{ctx.accountService}</div>
                    <div className="kontoInfoFaktura">{ctx.accountInvoice}</div>
                    <div className="kontoInfoDebit">{ctx. accountDebit}</div>
                    <div className="kontoInfoDatum">{ctx.accountDate}</div>
                    <div className="kontoInfoKommentar">{ctx.ccountSystemComment}</div>
                </div>
                <div className="kontoInfoListWrapper">
                    {ctx.getAccountInformation.map((data,index)=>(
                        <div key={index} className="kontoInfoList">
                            <div className="kontolistService"
                            onClick={()=>setId(data.serviceid)}
                            >{data.serviceid}</div>
                            <div className="kontolistfaktura">{data.fakturaid}</div>
                            <div className="kontolistdebit">{(data.debit/xchangeRate).toFixed(2)}</div>
                            <div className="kontolistdate">{String(data.createDate).substring(5,10)}</div>
                            {ctx.getMobileIsUsed===true?
                            <div className="kontolistkommentar">{String(data.remark).substring(0,15)}</div>
                            :<div className="kontolistkommentar">{data.remark}</div>}
                        </div>

                    ))}

                  <div className="footerHeader">
    
                    <div className="footerHeaderSaldo">{ctx.accountTotalSaldo}</div>
                    </div>
                    <div className="footerHeaderList">

                        <div className="footerHeaderSaldo">{saldo.toFixed(2)}</div>


                        </div>
            </div>
            </div>
            <div className="kontoInfoServiceName">{serviceName}</div>
            <div className="kontoInfoServiceName">{fullremark}</div>
        </div>










        </div>
    )

}


function MinaFakturor(){
    const ctx = useContext(MyContext);
    const[getInvoiceTab,setInvoiceTab]=useState(2)
 return(
<div className="psinvoiceTop">
    <div className="psInvoiceTabTop">

        <button className={`mobileTab ${getInvoiceTab===2?'active':''}`}
        onClick={()=>setInvoiceTab(2)}>{ctx.overviewText}</button>

               <button className={`psinvoiceTab psinvoicelastButton ${getInvoiceTab===3?'active':''}`}
        onClick={()=>setInvoiceTab(3)}>{ctx.overviewInformationText}</button>

{/* <button className={`psinvoiceTab psinvoicelastButton ${getInvoiceTab===1?'active':''}`}
        onClick={()=>setInvoiceTab(1)}>{ctx.overviewInvoiceText}</button> */}




    </div>

    <div className="psinvoiceTabContent">

        {/* {getInvoiceTab===1 ? <Faktura/> : ''} */}
        {getInvoiceTab===2 ? <InvoiceHistory/> : ''}
        {getInvoiceTab===3 ? <InvoiceRows/> : ''}

        </div>
</div>

 )
}
// function Faktura(){
//     const ctx = useContext(MyContext);
//     let userInvoiceInfo = ctx.getUserInvoiceInfo
//     let OwnerCompanyInfo = ctx.getOwningCompanyInfo
//     let userBillingName = ctx.getUserBillingAdressInfo[0].givenName
//     let userBillingSurname = ctx.getUserBillingAdressInfo[0].familyName
//     let userName=userBillingName+' '+userBillingSurname
//     let xchangeRate=ctx.getCurrencyInfo[0].xchangeRate
//     let currencySymbol=ctx.getCurrencyInfo[0].currencySymbol
//     let getBillingData=ctx.getBillingData
    
// let netsum = 0;
// let vatSum = 0;
// let vatSumInLocalCurrency = 0;
// let totalSumInLocalCurrency = 0;
// let netSumInLocalCurrency = 0;
// let vatRate = 0;

// if (ctx.getBillingTotalAmount && ctx.getBillingTotalAmount.length >0) {
//     netsum = ctx.getBillingTotalAmount[0].totalsum
// }


//     // here we will account with currency xchange
//     netSumInLocalCurrency=Math.round(netsum/xchangeRate)
//     vatRate=Math.round(ctx.getUserBillingAdressInfo[0].vatRate)
//     console.log('vatRate',vatRate)
//     // vat
//     vatSum=Math.round(netsum*vatRate/100)
//     console.log('vatSum',vatSum)
//     vatSumInLocalCurrency=Math.round(vatSum/xchangeRate)
//     // totalsum
//     totalSumInLocalCurrency=netSumInLocalCurrency+vatSumInLocalCurrency

//     currencySymbol=ctx.getCurrencyInfo[0].currencySymbol








    // // netsum
    // let netsumInLocalCurrency=Math.round(netsum/xchangeRate)
    // let vatRate=Math.round(ctx.getUserBillingAdressInfo[0].vatRate)
    // console.log('vatRate',vatRate)
    // // vat
    // let vatSum=Math.round(netsum*vatRate/100)
    // console.log('vatSum',vatSum)
    // let vatSumInLocalCurrency=Math.round(vatSum/xchangeRate)
    // // totalsum
    // let totalSumInLocalCurrency=netsumInLocalCurrency+vatSumInLocalCurrency

    // let currencySymbol=ctx.getCurrencyInfo[0].currencySymbol

//     const printDocument = (invoiceId) => {
//         window.location.href = `/printInvoice/${invoiceId}`;
//     }
    
  
//     return(
// <>
//     <div className="fakturaTop">
//             <div className="fakturaWrapper">
//                 <select className="fakturaSelect" onChange={(e)=>ctx.FetchBillingData(e.target.value)}>
//                     <option value="0">{ctx.invoiceSelectFirstOption}</option>
//                     {userInvoiceInfo.map((data, index) => (
//                         <option key={index} value={data.fakturaid}>{data.fakturaid}</option>
//                     ))}
//                 </select>
//                 <button onClick={() => printDocument(ctx.getchosenInvoiceId)} 
//                 className="abfakturaPrintButton"
//                 disabled={ctx.getchosenInvoiceId===0}
//                 >{ctx.printInvoceButtonText}</button>
//                 <button onClick={()=>ctx.SendInvoiceBypdf(ctx.getchosenInvoiceId)}>skicka pdf</button>
           
//             </div>
//         </div>
//         <div className="fakturaArea">
//             <div className="fakturaAreaWrapper">
//                 <div className="fakturaAreaGrid">
//                     <div className="fakturaAreaA">
//                         <div className="fakturaHeaderWrapper">
//                         <div className="fakturaHeaderName">{ctx.websiteCompanyName}</div>
//                         <div className={ctx.getCountry!=='ja'?"fakturaHeaderType":"fakturaHeaderTypeJAP"}>{ctx.invoiceText}</div>
//                         </div>
//                         <div className="fakturaHeaderWrapper">
//                         <div className="fakturaHeaderBroughtToYou">{ctx.broughtToYouText}</div>
//                         <div className="fakturaHeaderInvoiceDue">{ctx.getchosenInvoiceId}</div>
//                        </div>
//                     </div>

//     <div className="fakturaAreaB">

//                     <div className="fakturaHeaderWrapper">
//                              <div className='fakturaHeaderLine'></div>
//                     </div>      
//         <div className="fakturaInfoTop">
//             <div className="fakturainfoWrapper">
//                     <div className="fakturacompanyInfo">
//                                 <div className="fakturaOwnerInfo">{OwnerCompanyInfo[0].benaemn}</div>
//                                 <div className="fakturaOwnerInfo">{OwnerCompanyInfo[0].Address}</div>
//                                 <div className="fakturaOwnerInfo">{OwnerCompanyInfo[0].PostalCode}</div>
//                                 <div className="fakturaOwnerInfo">{OwnerCompanyInfo[0].City}</div>
//                                 <div className="fakturaOwnerInfo">{OwnerCompanyInfo[0].Phone}</div>
//                                 <div className="fakturaOwnerInfo">{OwnerCompanyInfo[0].Email}</div>
//                                 <div className="fakturaOwnerInfo">{OwnerCompanyInfo[0].VATID}</div>

//                     </div>
//                     <div className="fakturacustomerInfo">
//                         <div className="fakturaCustomerid"></div>
//                         <div className="fakturacustomerDet">{ctx.customerIdText}{ctx.getUserBillingAdressInfo[0].userid}</div>
//                         <div className="fakturacustomerDet">{userName}</div>
//                         <div className="fakturacustomerDet">{ctx.getUserBillingAdressInfo[0].address}</div>
//                         <div className="fakturacustomerDet">{ctx.getUserBillingAdressInfo[0].postalCode}</div>
//                         <div className="fakturacustomerDet">{ctx.getUserBillingAdressInfo[0].region}</div>
//                         <div className="fakturacustomerDet">{ctx.getUserBillingAdressInfo[0].city}</div>
//                         <div className="fakturacustomerDet">{ctx.getUserBillingAdressInfo[0].country}</div>
//                   </div>    
//            </div>
//         </div>
   
//     </div>
                
//         <div className="fakturaAreaC">

//                     <div className="fakturaHeaderWrapper">
//                              <div className='fakturaHeaderLine'></div>
//                     </div>  
//             <div className="fakturaAreaCTop">
//                     <div className="fakturaAreaCWrapper1">
//                         <div className="fakturaAreaCHeaderService">{ctx.serviceText}</div>
//                         <div className="fakturaAreaCHeaderNamn">{ctx.nameText}</div>
//                         <div className="fakturaAreaCHeaderAntal">{ctx.numText}</div>
//                         <div className="fakturaAreaCHeaderMaxAntal">{ctx.maxText}</div>
//                         <div className="fakturaAreaCHeaderFakturerat">{ctx.debitText}</div>
//                         <div className="fakturaAreaCHeaderTotalt">{ctx.totaltText}</div>
                       

//                 </div>
//            </div>
//                 <div className="fakturaAreaCWrapper">
//                     <div className="fakturaAreaList">
//                         {ctx.getBillingData.map((data,index)=>(
//                             <div key={index} className="fakturaAreaListRow">
//                                 <div className="fakturaAreaListService">{data.serviceid}</div>
//                                 <div className="fakturaAreaListNamn">{data.benaemn}</div>
//                                 <div className="fakturaAreaListAntal">{data.antal>0?data.antal:1}</div>
//                                 <div className="fakturaAreaListMaxAntal">{data.maxantal>0?data.maxval:0}</div>
//                                 <div className="fakturaAreaListFakturerat">
//                                     <div style={{textAlign:'left'}}>
                                    
//                                     {Math.round(data.debit/xchangeRate)}</div></div>
//                                 <div className="fakturaAreaListKredit">{Math.round((data.debit-data.kredit)/xchangeRate)}</div>
//                             </div>
//                         ))}
//                     </div>
//                     </div>

             
//         </div>



//         <div className="fakturaAreaD">
//     <div className="fakturaPayTop">
//                 <div className="fakturaPayWrapper">
//                         <div className="fakturaPayNetSum"></div>
//                         <div className="fakturaPayNetSum">{ctx.sumNetText}{netSumInLocalCurrency}</div>
//                         <div className="fakturaPayTotal"></div>
//         </div>
//             <div className="fakturaPayWrapper">
//                         <div className="fakturaPayNetSum"></div>
//                         <div className="fakturaPayVAT">{ctx.sumVatText}{vatSumInLocalCurrency}</div>
//                         <div className="fakturaPayTotal"></div>
//         </div>
//         <div className="fakturaPayWrapper">
//                         <div className="fakturaPayNetSum"></div>
//                         {currencySymbol==='kr'?
//                         <div className="fakturaPayTotal">{ctx.attBetalaText}{':'}{totalSumInLocalCurrency}{''}{currencySymbol}</div>:
//                         <div className="fakturaPayTotal">{ctx.attBetalaText}{currencySymbol}{totalSumInLocalCurrency}</div>}
//         </div>
    
//     </div>


//         <div className="fakturaHeaderWrapper">
//                              <div className='fakturaHeaderLine'></div>
//                     </div> 
//            <div className="fakturaFooterTop">
//                     <div className="fakturaFooterWrapper">
//                             <div className="fakturaFooterHeadertext">{ctx.bankgiroText}{OwnerCompanyInfo[0].BANKGIRO}</div>
//                             <div className="fakturaFooterHeadertext">{ctx.swishText}{OwnerCompanyInfo[0].SWISH}</div>
//                             <div className="fakturaFooterHeadertext">{ctx.termsText}{ctx.getUserBillingAdressInfo[0].benaemn}</div>
//                     </div>
//                     <div className="fakturaFooterWrapper">
//                             <div className="fakturaFooterHeadertextMid">{ctx.bankText}{OwnerCompanyInfo[0].Bank}</div>
//                             <div className="fakturaFooterHeadertextMid1">{ctx.bicText}{OwnerCompanyInfo[0].BIC}</div>
//                     </div>
//                     <div className="fakturaFooterWrapper">
//                             <div className="fakturaFooterHeadertextIban">{ctx.ibanText}{OwnerCompanyInfo[0].IBAN}</div>
//                     </div>

//             </div>

//         </div>

//                 </div>

//             </div>  
//             </div>
// </>
            
//     )

// }


function InvoiceOverView(){
    const ctx = useContext(MyContext);
    let netSum=ctx.getUserAccountBalance && ctx.getUserAccountBalance[0].netbalance || 0  // debit-kredit

    let nextDueDate = (ctx.getInvoiceNextDueDate[0].dueDate||'Date failed to load').substring(0,10)
    let userBalance=ctx.getUserAccountBalance && ctx.getUserAccountBalance[0].userBalance || 0  //userdebit-userkredit
    // here we will account with currency xchange
    let currencySymbol=ctx.getCurrencyInfo[0].currencySymbol
    let xchangeRate=ctx.getCurrencyInfo[0].xchangeRate
    //net totalsum
    let netsumInLocalCurrency=netSum-userBalance // DEBIT-KREDIT-USERDEBIT/USERKREDIT
    netsumInLocalCurrency=netsumInLocalCurrency/xchangeRate
    // vatRate
    let vatRate=ctx.getVatInfo[0].vatRate
    // user monthly net cost
    let userMonthlyNetCost=ctx.getUserMonthlyUsageCost && ctx.getUserMonthlyUsageCost.length>0 && ctx.getUserMonthlyUsageCost[0].netsum || 0

   // let userMontlyNetCostInLocalCurrency=userMonthlyNetCost/xchangeRate
    let userMontlyNetCostInLocalCurrency = (userMonthlyNetCost / xchangeRate) * (1 + (vatRate / 100));

    return(
        <div className="psinvoiceOverviewTop">
            <div className="psinvoiceOverviewHeaderWrapper">
            <div className="psinvoiceOverviewHeader">{ctx.estimatedSaldo}
            </div>
            <div className="psinvoiceOverviewSum">{currencySymbol}&nbsp;{Math.round(userMontlyNetCostInLocalCurrency)}</div>
            <div className="psexplanationTextWrapper">
            <div className="psexplanationText">{ctx.estimatedSaldoText}</div>
            </div>
            </div>

            <div className="pspaymentChoicesWrapper">
                <div className="pspaymentNextPaymentDue">{ctx.nextPaymenttext}</div>
                <div className="pspaymentNextPaymentDueDate">{nextDueDate}</div>
                {/* <div className="psline"></div>
                <div className="pstotalyUsed">{ctx.totalAmountUsed}</div>
                <div className="pstotalyUsedSum">{currencySymbol}&nbsp;{Math.round(debitSumInLocalCurrency)}</div> */}


            </div>

        </div>
    )
}

function InvoiceHistory(){
    const ctx = useContext(MyContext);
     let userInvoiceInfo = ctx.getUserInvoiceInfo
         // here we will account with currency xchange
    let xchangeRate=ctx.getCurrencyInfo[0].xchangeRate



    //getUserInvoiceInfo
    return(
        <div className="invoiceHistoryTop">
            <div className="invoiceHistoryHeaderWrapper">
                {ctx.getMobileIsUsed!==true?
            <div className="invoiceHistoryHeader">{ctx.invoiceHistoryText}</div>:''}
            </div>
            <div className="invoiceHeaderTop">
            <div className="invoicelistWrapper">
                <div className="invoiceHistoryHeader1">{ctx.invoiceHistoryInvoice}</div>
                <div className="invoiceHistoryHeader1">{ctx.invoiceHistorySum}</div>
                <div className="invoiceHistoryHeader1">{ctx. invoiceHistoryDate}</div>
                <div className="invoiceHistoryHeader1">PDF</div>
                <div className="invoiceHistoryHeader1">Betala</div>




            </div></div>
            <div className="invoiceHistoryListWrapper">
                {userInvoiceInfo.map((data,index)=>(
                    <div key={index} className="invoiceHistoryList">
                        <div className="invoiceHistoryListData">{data.fakturaid}</div>
                        <div className="invoiceHistoryListData">{Math.round(data.nettotal/xchangeRate)}</div>
                        <div className="invoiceHistoryListData">{String(data.createDate).substring(2,10)}</div>
                        <div className="invoiceHistoryListDataPDF"
                        onClick={()=>ctx.DownloadPdfInvoice(data.fakturaid)}
                        ><FaFilePdf style={{ color: 'red', fontSize: '16px' }} /></div>
                          <div className="invoiceHistoryListData">
                            {data.payStatus === 1 ? 'Betala' : data.payStatus === 2 ? 'Betald' : ''}</div>
                        

                    </div>
                ))}
        </div>
        </div>
    )
}

function InvoiceRows(){
    const ctx = useContext(MyContext);

    const[getInvoiceId,setInvoiceId]=useState(0)
    const[getInvoiceServiceId,setInvoiceServiceId]=useState(0)
// here we will account with currency xchange
let xchangeRate=ctx.getCurrencyInfo[0].xchangeRate
let currencySymbol=ctx.getCurrencyInfo[0].currencySymbol

// here we will sum all rows who has a creditType='F'
let invoiceTotalSum=ctx.getInvoiceRowInfo.filter(data => data.invoiceType === 'F').reduce((acc, data) => acc + Math.round(data.summa/xchangeRate), 0)
// same for kredit
let invoiceTotalKredit=ctx.getInvoiceRowInfo.filter(data => data.invoiceType === 'K').reduce((acc, data) => acc + Math.round(data.summa/xchangeRate), 0)
let invoicetotalsum=invoiceTotalSum-invoiceTotalKredit

    return(
     <><div className="invoiceSelectWrapper">
<select className="invoiceSelectList" onChange={(e) => setInvoiceId(Number(e.target.value),setInvoiceServiceId(0))}>
  <option value="0">{ctx.chooseInvoiceText}</option>
{ctx.getAccountInformation
  .filter((value, index, self) => self.findIndex(item => item.fakturaid === value.fakturaid) === index)
  .map(({ fakturaid, createDate }, index) => (
    <option key={index} value={fakturaid}>{`${fakturaid} - ${new Date(createDate).toLocaleDateString()}`}</option>
  ))
}
</select></div>

        <div className="invoiceRowsTopWrapper">
        <div className="invoiceRowsTop">
             {getInvoiceId > 0 ? `${ctx.invoiceRowsText} ${' '} ${getInvoiceId}` :`${ctx.allInvoiceRowsText}`}
       </div>
       </div>

                <div className="invoiceRowsHeaderWrapper">
                    <div className="invoiceRowfakturaid">{ctx.invoiceRowsId}</div>
                    <div className="invoiceRowServiceid">{ctx.invoiceRowsService}</div>
                    {/* <div className="invoiceRowServiceMax">{ctx.invoiceRowsMax}</div>
                    <div className="invoiceRowRequests">{ctx.invoiceRowsRequest}</div>
                    <div className="invoiceRowDebitQty">{ctx.invoiceRowsDebitQty}</div> */}
                    <div className="invoiceRowSumma">{ctx.invoiceRowsPrice}</div>
                    <div className="invoiceRowDate">{ctx.invoiceRowsTotal}</div>
                    <div className="invoiceRowType">{ctx.invoiceRowsType}</div>
                    
                </div>

                <div className="invoiceRowsListWrapper">
                    {ctx.getInvoiceRowInfo
                    .filter(data => getInvoiceId === 0 || data.fakturaid === getInvoiceId)
                    .map((data, index) => (
                        <div key={index} className="invoiceRowsList">
                            <div className="invoiceRowfakturaid">{data.fakturaid}</div>
                            <div className="invoiceRowServiceid"
                            onClick={()=>setInvoiceServiceId(data.serviceid)}
                            >{data.serviceid}</div>
                            {/* <div className="invoiceRowServiceMax">{data.maxRequest}</div>
                            <div className="invoiceRowRequests">{data.totalRequest}</div>
                            <div className="invoiceRowDebitQty">{data.debitQty}</div> */}
                            <div className="invoiceRowDate">{(data.price/xchangeRate).toFixed(2)}</div>
                            {data.invoiceType==='F'?
                            <div className="invoiceRowSumma">{Math.round(data.summa/xchangeRate)}</div>
                            // here we must turn the amount to a minus value
                            :<div className="invoiceRowSumma">{-Math.round(data.summa/xchangeRate)}</div>}

                            <div className="invoiceRowType">{data.invoiceType}</div>

                        </div>
                    ))}
                </div>
                <div className="invoiceServiceNameWrapper">
                    <div className="invoiceRowsTotalSum">{ctx.invoiceRowsTotalSum} &nbsp;&nbsp;{currencySymbol} &nbsp;{invoicetotalsum}


                    </div>
                    </div>




                {getInvoiceServiceId>0?
                <div className='invoiceServiceNameWrapper'>
                <div className="invoiceServiceName">{ctx.getServiceInformation.find(service => service.serviceid === getInvoiceServiceId)?.benaemn || ''}
                </div>
            </div>:''}


            </>
    )
}


function InvoiceSettings(){
    const ctx = useContext(MyContext);
    let sliderVal=ctx.getMaxValueSlider



    let userBillingAdress = ctx.getUserBillingAdressInfo[0].address||''
    let userBillingPostalCode = ctx.getUserBillingAdressInfo[0].postalCode||''
    let userBillingCity = ctx.getUserBillingAdressInfo[0].city||''
    let userBillingRegion = ctx.getUserBillingAdressInfo[0].region||''
    let userBillingCountry = ctx.getUserBillingAdressInfo[0].country||''
    let userBillingCompany = ctx.getUserBillingAdressInfo[0].company||''
    let userNameInfo = (ctx.getUserBillingAdressInfo[0].givenName || '') + ' ' + (ctx.getUserBillingAdressInfo[0].familyName || '');

    let taxId = ctx.getUserBillingAdressInfo[0].taxId||''
    let invoiceMaxVal=ctx.getUserBillingAdressInfo[0].invoiceMaxVal||''
    return(
        <>
        <div className="invoiceSettingsTop">
        <div className="invoiceSettingsHeaderWrapper">
            <div className="invoiceSettingsHeader">{ctx.settingsHeaderText}</div>
        </div>

        <div className="invoiceSettingsHeaderWrapper">
        <div className="invoiceSettingsHeader1">
            <div className="invoiceSettinsHeaderText">{ctx.companyAndPersonalText}</div>
            <div className="invoiceSettingspLine"></div>
        </div>
        <div className="invoiceSettingsListInfo">{ctx.addressInstructionText}</div>
        <div className="invoiceSettingsListInfo"></div>
   </div>
   <div className="invoiceSettingsCustomerAddressWrapper">
        <div className="invoiceSettingsCustomerAddress">{userBillingCompany}</div>
        <div className="invoiceSettingsCustomerAddress">{userNameInfo}</div>
        <div className="invoiceSettingsCustomerAddress">{userBillingAdress}</div>
        <div className="invoiceSettingsCustomerAddress">{userBillingPostalCode}&nbsp;&nbsp;{userBillingCity}</div>
        <div className="invoiceSettingsCustomerAddress">{userBillingRegion}&nbsp;&nbsp;{userBillingCountry}</div>
        
        <div className="invoiceSettingspLine"></div>
        <div className="invoiceSettingsTaxIdWrapper">
            <div className="invoiceSettingsTaxIdHeader">{ctx.taxIdentificationVATText}</div>
            <div className="invoiceSettingsTaxIdText">{ctx.taxIdInformationText}</div>

            <input type="text" className="invoiceSettingsTaxIdInput" value={taxId}
             placeholder={taxId.length<1?"Skriv in ditt skatte id här":""}
            onChange={(e)=>ctx.setTaxIdHandler(e.target.value)}/>
        </div>
        <div className="invoiceSettingsTaxidSaveWrapper">
        <button className="invoiceSettingsTaxidSave">{ctx.registerTaxIdButtonText}</button>
        </div>
        <div className="invoiceSettingspLine1"></div>
        {/* <div className="invoiceSettingsBillingAlertWrapper">
            <div className="invoiceSettingsBillingAlertHeader">{ctx.maxValueWarningText}</div>
            <div className="invoiceSettingsTaxIdText">{ctx.actiVateMaxValueText}</div>
        </div>
        <div className="invoiceSettingsSliderWrapper">
        <input type="text" className="invoiceSettingMaxValueAlertInput"
        value={ctx.getMaxValue}
        onChange={(e)=>ctx.setMaxValue(e.target.value)}
        />

        <label className="switch">
      <input type="checkbox" onChange={ctx.toggleMaxValueSlider}  checked={ctx.getMaxValueSlider}/>
      <span className="slider round"></span>
    </label>
    </div>
    <div className="invoiceSaveMaxValueButtonWrapper">
    <div className="invoiceSaveMaxValueButton"
    onClick={ctx.InvoiceMaxVal}
    disabled={ctx.getMaxValue<1 || ctx.getMaxValue.length<1}
    >{ctx.maxValueButtonSaveText}</div>
    <div className="invoiceEndFooter"></div>
</div></div></div> */}
</div></div>
        </>

    )
}




function Pass(){
    const ctx = useContext(MyContext);
    const [showPassword, setShowPassword] = useState(false);
    const [reset,setReset]=useState(false)
    const passHeader=reset===false?ctx.passwordHeaderText:ctx.resetPasswordHeaderText
    const saveButtonText=reset===false?"Save":"Reset"
    return(
        <div className="psTop">
            <div className="psHeaderWrapper">
            <div className="psPassHeader">{passHeader}</div>
        </div>


        <div className="psInputWrapper">
        <label htmlFor="oldpass" className={reset===false?"pspassLabel":"pspassLabel1"}>{ctx.passwordValidText}</label>
          <input type={showPassword ? "text" : "password"}
          id="oldpass" className={reset===false?"psInput":"psInput1"} value={ctx.getOldpass}
          onChange={(e)=>ctx.setNewPasswordHandler(e.target.value,1)}

          />




        <label htmlFor="newpass" className="pspassLabel">{ctx.newPasswordText}</label>
          <input type={showPassword ? "text" : "password"} id="newpass" className="psInputNew"
          value={ctx.getNewpass}
          onChange={(e)=>ctx.setNewPasswordHandler(e.target.value,2)}
          />




        <label htmlFor="confirmpass" className="pspassLabel">{ctx.confirmPasswordText}</label>
          <input type={showPassword ? "text" : "password"} id="confirmpass" className="psInputConfirm"
          value={ctx.getConfirmpass}
          onChange={(e)=>ctx.setNewPasswordHandler(e.target.value,3)}
          />


          </div>
          <label className="pscheckbox">
                <input type="checkbox" checked={showPassword} onChange={() => setShowPassword(!showPassword)} />
                {ctx.showPasswordText}
            </label>

            <div className="psSubmitbuttonWrapper">
                <button className={ctx.getOldpass.length<1 || ctx.getNewpass.length<1 || ctx.getConfirmpass.length<1?"psSubmitbutton1":"psSubmitbutton"}
                onClick={()=>ctx.saveNewPasswordHandler(reset)}
              disabled={reset ? (ctx.getNewpass.length < 1 || ctx.getConfirmpass.length < 1) : (ctx.getOldpass.length < 1 || ctx.getNewpass.length < 1 || ctx.getConfirmpass.length < 1)}
              >{ctx.savePasswordButtonText}</button>
                </div>

                <div className="psSubmitbuttonWrapper">
                <fieldset className="psEmailFieldset"><legend className="psEmailLegend">{ctx.iforgotMyPasswordText}</legend>
                <input type="checkbox" className="psEmailSendSubmitButto"
                onClick={()=>setReset(prevReset => !prevReset)}

                />
                </fieldset>
                </div>

        </div>
    )
}
// function XtraTop(){

//     const[getInvoiceTab,setInvoiceTab]=useState(1)
//  return(
// <div className="psinvoiceTop">
//     <div className="psInvoiceTabTop">

//         <button className={`mobileTab ${getInvoiceTab===1?'active':''}`}
//         onClick={()=>setInvoiceTab(1)}>Support</button>

//                <button className={`psinvoiceTab psinvoicelastButton ${getInvoiceTab===2?'active':''}`}
//         onClick={()=>setInvoiceTab(2)}>Inställnigar</button>


//     </div>

//     <div className="psinvoiceTabContent">
//         {getInvoiceTab===1 ? <Support/> : ''}
//         {getInvoiceTab===2 ? <InvoiceSettings/> : ''}
//         </div>
// </div>

//  )


// }
function SupportHeader(){
    const[getSupportTab,setSupportTab]=useState(2)
    return(
   <div className="psinvoiceTop">
       <div className="psInvoiceTabTop">
   
           <button className={`mobileTab ${getSupportTab===1?'active':''}`}
           onClick={()=>setSupportTab(1)}>Mina ärenden</button>
   
                  <button className={`psinvoiceTab psinvoicelastButton ${getSupportTab===2?'active':''}`}
           onClick={()=>setSupportTab(2)}>Support message</button>

   
   
   
   
       </div>
   
       <div className="psinvoiceTabContent">
           {getSupportTab===1 ? <SupportHistory/> : ''}
           {getSupportTab===2 ? <Support/> : ''}
   
           </div>
   </div>
   
    )
}
function SupportHistory(){
    const ctx = useContext(MyContext);
    return(
        <div className="supportHistoryTop">
            <div className="supportHistoryHeaderWrapper">
            <div className="supportHistoryHeader">{ctx.supportRequestText}</div>
             </div>

             <div className="supportHistoryListWrapper">
            
{ctx.getSupportTickets.length > 0 && (
  <div className="supportHistoryList">
    <div className="supportHistoryServiceId1">{ctx.supportHistoryServiceIdText}</div>
    <div className="supportHistoryCreateDate1">{ctx.supportHistoryBenaemnText}</div>
    <div className="supportHistoryStatus1">{ctx.supportHistoryStatusText}</div>
  </div>
)}
{ctx.getSupportTickets.map((data, index) => (
  <div key={index} className="supportHistoryList">
    <div className="supportHistoryServiceId">{data.supportid}</div>
    <div className="supportHistoryCreateDate" onClick={()=>ctx.GetSupportTicketHandler(data.supportid)}>
      {data.kategoribenaemn}
    </div>
    <div className="supportHistoryStatus">{data.benaemn}</div>
  </div>
))}


<div className="supportShowMessageTop">
<div className="supportShowMessageWrapper">
    <div className="supportShowMessageHeader">
        <div className="supportShowMessageHeader1">{ctx.supportHistoryCategoryText}:&nbsp;</div>
       <div className="supportShowMessageHeader1">
       {ctx.getSupportTicket && ctx.getSupportTicket[0] && ctx.getSupportTicket[0].benaemn1 || 'no ticket'}</div>
     </div>
     <div className="supportShowMessageWrapper">
        <div className="supportTitleText">{ctx.messagesSentToSupportText}</div>
  
        <textarea className="supportShowMessageTextArea" 
        onChange={(e)=>ctx.UpdatesupportMessageHandler(e.target.value)}
       value={ctx.getSupportTicket && ctx.getSupportTicket[0] && ctx.getSupportTicket[0].benaemn || ''}>
        </textarea>

        <button className="supportShowMessageButton"
        onClick={()=>ctx.UpdateClientSideSupportTicket(ctx.getChosenTicketId)}
        >{ctx.supportButtonAnswerText}</button>


     </div>

</div>
</div>




             </div>
        </div>
      
    )
}


function Support(){
    const ctx = useContext(MyContext);
    return(

            <div className="supportHeaderTop">
                <div className="supportHeaderWrapper">
                     <div className="supportTop">{ctx.supportMText}</div>
                </div>
            
             <div className="supportMessageWrapper">
                    <div className="supportMessageHeader">{ctx.supportMessageText}</div>
                    <div className="supportMessageInputWrapper">
                    
                        <select className="supportMessageSelect" onChange={(e)=>ctx.supportCategoryHandler(e.target.value)}>
                            <option value="0">{ctx.supportMessageSelectText}</option>
                            {ctx.getSupportCategories.map((data,index)=>(
                                <option key={index} value={data.kategoriid}>{data.benaemn}</option>
                            ))}
                     </select>
                        <textarea className="supportMessageTextArea" placeholder={ctx.supportMessagePlaceholderText}
                         onChange={(e)=>ctx.supportMessageHandler(e.target.value)}
                         value={ctx.getSupportMessage}
                        
                        
                        />
                  
                    <div className="supportMessageButtonWrapper">
                        <button className={ctx.getSupportCategory===0 || ctx.getSupportMessage.length<1?"supportMessageButton1":"supportMessageButton"}
                        onClick={()=>ctx.SendSupportMessageHandler()}
                        disabled={ctx.getSupportCategory===0 || ctx.getSupportMessage.length<1}
                        >{ctx.supportMessageSendText}</button>
                    </div>
                    </div>
             </div>

        </div>
    )
}
function TjansterDebitering(){
    const ctx = useContext(MyContext);


    return(
     <div className="tjansterDebTop">
    <div className="tjansterDebWrapper">
        <div className="tjansterDebHeaderText">{ctx.userDebitServices}</div>
    </div>

    <div className="tjansterDebListWrapper">
        {/* Header */}
        <div className="tjansterDebRow">
            <div className="tjansterDebService1">{ctx.debserviceidText}</div>
            <div className="tjansterDebBenaemn1">{ctx.debserviceBenaemningText}</div>
            <div className="tjansterDebQty1">{ctx.debserviceQtyText}</div>
            <div className="tjansterDebMax1">{ctx.debserviceMaxText}</div>
            <div className="tjansterDebUsable1">{ctx.debserviceUsableText}</div>
            <div className="tjansterDebActive1">{ctx.debserviceStatusText}</div>
        </div>

        {/* Data */}
        {ctx.getUserTotalUsage.map((data, index) => (
            <div key={index} className="tjansterDebRow">
                <div className="tjansterDebService">{data.serviceid}</div>
                <div className="tjansterDebBenaemn">{data.benaemn}</div>
                <div className="tjansterDebQty">{data.antal}</div>
                <div className="tjansterDebMax">{data.maxVal}</div>
                <div className="tjansterDebUsable">{Math.max(0, data.maxVal - data.antal)}</div>
                <div className="tjansterDebActive">&nbsp;{data.active}</div>
            </div>
        ))}
    </div>
</div>
    )
   
}

function PaymentHeader(){
    const ctx = useContext(MyContext);

    const[getServiceId,setServiceId]=useState(1)    // serviceid
    const[getTotalSum,setTotalSum]=useState('')   
    const[getChosenInvoice,setChosenInvoice]=useState(0)    // invoice id
    const[getChosenCurrency,setChosenCurrency]=useState(0)   // chosen currency, this is the id of the currency
    const[getChosenCurrencyName,setChosenCurrencyName]=useState('')  // chosen currency name
    const[getServiceName,setServiceName]=useState('')
    const[getChosenXchangeRate,setChosenXchangeRate]=useState(1)
    const[getSumWithChosenCurrency,setSumWithChosenCurrency]=useState('')
    const[getuserid,setuserid]=useState(0)
    const[getLanguageCode,setLanguageCode]=useState('')
    const[getShowSelect,setShowSelect]=useState(true)
    const[getChosenVatRate,setChosenVatRate]=useState(0)
    const isMounted = useRef(false);


    const setChosenCurrencyHandler=(currencyid)=>{
        setChosenCurrency(currencyid)
     //   ctx.fetchKlarnaPaymentSession(getChosenInvoice,getServiceId,getServiceName,getSumWithChosenCurrency)
    }

    // all invoice info
     let getUserInvoiceInfo=ctx.getUserInvoiceInfo.filter(data=>data.payStatus===1)
     let serviceInfo=ctx.getServiceInformation


    useEffect(()=>{
        if(getChosenInvoice>0){
            let myNewObjArr = [...getUserInvoiceInfo];
            let myNewIndex = myNewObjArr.findIndex(data => data.fakturaid == getChosenInvoice);
           
            
            if (myNewIndex !== -1) {
                let { totalsum } = myNewObjArr[myNewIndex]; // Ensure the property name matches exactly
                let { serviceid } = myNewObjArr[myNewIndex]; 
                
            setTotalSum(totalsum)
            setServiceId(serviceid)

            let myNewObjArr1 = [...serviceInfo];
            let myNewIndex1 = myNewObjArr1.findIndex(data => data.serviceid == serviceid);
            let { benaemn } = myNewObjArr1[myNewIndex1]; // Ensure the property name matches exactly
            setServiceName(benaemn)
                
            } 

        let userid=ctx.getUserBillingAdressInfo && ctx.getUserBillingAdressInfo.length>0 && ctx.getUserBillingAdressInfo[0].userid
        setuserid(userid)
        setLanguageCode(ctx.getCountry)
        }
        },[getChosenInvoice])

        useEffect(()=>{
        if(getChosenCurrency>0){
        setChosenXchangeRate(ctx.getTotalCurrencyInfo.filter(data=>data.currencyid==getChosenCurrency)?.[0].xchangeRate)
        setChosenCurrencyName(ctx.getTotalCurrencyInfo.filter(data=>data.currencyid==getChosenCurrency)?.[0].currencyName)
        setShowSelect(false)
        setChosenVatRate(ctx.getVatInfo[0].vatRate)
        }
        },[getChosenCurrency])
     

        useEffect(()=>{
            if(getChosenXchangeRate>0){
            let sum=Math.floor(getTotalSum/getChosenXchangeRate)
            //add vat
            // for usd and eur and sek we really dount want math.floor, since if a user shall pau 6.64 usd math.floor will make it 6 usd¨
            // so we only use math.floor for other currencies yen
            if(getChosenCurrencyName==='USD'){
                sum=sum*(1+getChosenVatRate/100)
            }else if(getChosenCurrencyName==='JPY'){
            sum=Math.floor(sum*(1+getChosenVatRate/100))
            }else if (getChosenCurrencyName==='SEK'){
                sum=sum*(1+getChosenVatRate/100)
            }
            setSumWithChosenCurrency(sum)
            }
        },[getChosenXchangeRate])

        const initiateKlarnaSession = () => {
            // This function ensures Klarna session is initiated only after all necessary states are set
            if (getChosenInvoice && getServiceId && getServiceName && getSumWithChosenCurrency) {
                ctx.fetchKlarnaPaymentSession(getChosenInvoice, getServiceId, getServiceName, getSumWithChosenCurrency,getChosenCurrencyName);
            }
        };

        useEffect(() => {
            initiateKlarnaSession(); 
            // This effect will run every time `getSumWithChosenCurrency` is updated
        }, [getSumWithChosenCurrency]);





    return(
        <div className="payPalInsideTop">
            {getShowSelect===true?(
            <><div className="payPalSelectWrapper">
                    <select className="payPalSelect" onChange={(e) => setChosenInvoice(e.target.value)}>
                        <option>{ctx.ppSelectInvoiceText}</option>
                        {getUserInvoiceInfo.map((data, index) => (
                            <option key={index} value={data.fakturaid}>{data.fakturaid}</option>
                        ))}
                    </select>
                </div><div className="payPalSelectWrapper">
                        <select className="payPalSelect" onChange={(e) => setChosenCurrencyHandler(e.target.value)}>
                            <option>{ctx.ppSelectCurrencyText}</option>
                            {ctx.getTotalCurrencyInfo.map((data, index) => (
                                data.currencyName==="SEK" &&(
                                <option key={index} value={data.currencyid}>{data.currencyName}</option>
                           )
                            ))}
                        </select>
                    </div></>
            ):''}

        
            <div className="payPalWrapperInside">
                {/* {getServiceId>0?
                <div className="payPalBakesoftHeaderWrapperInside">
                <div className="insidepayPalBakesoftHeader">{ctx.klarnaMainHeaderText}</div>
            </div>:''} */}
       
       
         <div className="ppBakesoftTextWrapperInside">
            <div className="ppBakesofttext">{ctx.ppChosenServiceText}:&nbsp;{getServiceName}</div>
            <div className="ppBakesofttext">{ctx.pptotalAmountToPayText}:&nbsp;{getSumWithChosenCurrency}&nbsp;{getChosenCurrencyName}</div>
            <div className="ppBakesofttext">{ctx.ppBakesoftTermsText}:&nbsp;{ctx.ppBakesoftTerms}</div>

        </div>
        <div className='ppBakesoftButtonWrapperInside'>
           {ctx.getKlarna?<BetalningKlarna/>:''}
    
   
            </div>
            </div>
            </div>
            )
    
}


export {MyAccount,Adressinfo,Tjanster,DataAnvandning,MinData,
    Betalning,MinaFakturor,Pass,InvoiceOverView,InvoiceHistory,
    InvoiceSettings,BetalningFaktura,BetalningKlarna,BetalningPayPal,Konto,
    Support,InvoiceRows,KontoTop,TjansterDebitering,PayPalButton,PaymentHeader
}
