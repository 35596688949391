import React,{useState,useEffect} from 'react'
import {useNavigate,Link} from 'react-router-dom';
import {useGetData,usePutData,usePostData,useDeleteData} from '../hooks/useGetData';
import useReportErr from '../hooks/useReportErr';
import './IngredientsNew1.css'
import {BarChart, Bar,LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,Label,Area,AreaChart ,ComposedChart} from 'recharts';
import { RotatingLines} from 'react-loader-spinner';
import{AiOutlinePlusCircle} from 'react-icons/ai'
import{AiOutlineMinusCircle} from 'react-icons/ai'
import Alert from '../functions/appAlert';
import useFormattedDateNow from '../hooks/useFormattedDateNow';
import usePageSettings from '../hooks/usePageSettings';
import useFetchImageUrl from '../hooks/useFetchImageUrl';

import languages from '../AppLanguage/AppLanguage';
import AppError from '../AppLanguage/AppError';
import displayGadgetToSmall from '../functions/displayGadgetToSmall';

export default function IngredientsNew() {

const[ingred,setIngred]=useState([{}])
const[getchosenIngredCategory,setchosenIngredCategory]=useState(0)
const[getAlertCode,setAlertCode]=useState(0)
const[getAlertMessage,setAlertMessage]=useState('')
const[getShowAlert,setShowAlert]=useState(false)
const[getSpinnerLoading,setSpinnerLoading]=useState(false)
const[getKategori,setKategori]=useState([{}])
// const[getLev,setLev]=useState([{}])
const[getIngredData,setIngredData]=useState([{}])
const [activeButton, setActiveButton] = useState(8);
const[getNutrientInfo,setgetNutrientInfo]=useState(false)
const[getIngredName,setIngredName]=useState('')
const [getnutritionData, setnutritionData] = useState([]);
const[getReceivedDataStatus,setReceivedDataStatus]=useState(false)
const[newIndex,setNewIndex]=useState(0)
const [chosenIngredid, setchosenIngredid] = useState(null);
const[getSaveIngredient,setSaveIngredient]=useState(false)
const[getIngredid,setIngredid]=useState(0)
const[expandedRow,setExpandedRow]=useState(null)
const[getLevRowData,setLevRowData]=useState([{}])
const[getRecepies,setRecepies]=useState([{}])
const[getProducts,setProducts]=useState([{}])
const[getRecepyConnection,setRecepyConnection]=useState([{}])
const[getProductConnection,setProductConnection]=useState([{}])
const[getChartData,setChartData]=useState([{}])
const[getChangeIngredObj,setChangeIngredObj]=useState([])
const[getChangeProductObj,setChangeProductObj]=useState([])
const[getNewIngredientInRecepy,setNewIngredientInRecepy]=useState(0)
const[getNewIngredientInProduct,setNewIngredientInProduct]=useState(null)
const[getNewPrice,setNewPrice]=useState(0)
const [getLatestPrice,setLatestPrice] = useState(0)
const[getShowNutrientInfo,setShowNutrientInfo]=useState(false)
const[getShowPris,setShowPris]=useState(false)
const[getNewRowData,setNewRowData]=useState(false)
const[getIngredTotalVolume,setIngredTotalVolume]=useState([{}])
const[getIngredTotal,setIngredTotal]=useState([{}])
const[getIngredPriceHistory,setIngredPriceHistory]=useState([{}])
const[getActiveButtonChart,setActiveButtonChart]=useState(3)
const[getShowDeleteIngredient,setShowDeleteIngredient]=useState(false)
const[getNutritionAndEmpty,setNutritionAndEmpty]=useState(false)
const[getNutritionSaveStatus,setNutritionSaveStatus]=useState(false)
const[getBackGroundShade,setBackGroundShade]=useState(0.65)
const[getRenderKey,setRenderKey]=useState(0)
const[getVolymeInstrument,setVolymeInstrument]=useState('')
const[getConvertedValue,setConvertedValue]=useState(0)
const[getNumVolymes,setNumVolymes]=useState(0)
const[getIngredInformation,setIngredInformation]=useState([])
const [getcheckval,setcheckval]=useState(0)
const[getColorVal,setColorVal]=useState(0)
const[getMenuShade,setMenuShade]=useState(0)
const[getMenuShade2,setMenuShade2]=useState(0)
const[getImageVal,setImageVal]=useState(0)
const[getImageUrl,setImageUrl]=useState('')
const[getNutrientDataSort,setNutrientDataSort]=useState(false)
const[getNutrientNameSort,setNutrientNameSort]=useState(false)
const[getIngredNameUpdated,setIngredNameUpdated]=useState(false)// did we update the ingred name
const pageid=4
const [logtime]=useState(5000)
const[getCountry,setCountry]=useState('se') // this is the country
const[getPriceIsUpdated,setPriceIsUpdated]=useState(false)
const[getKanjiIngredName,setKanjiIngredName]=useState('')  // set the kanji name
const[getFuriganaIngredName,setFuriganaIngredName]=useState('') // set the furigana name
// const [getUpdateKanjiName,setUpdateKanjiName]=useState('')    // update kanji name
// const [getUpdateFuriganaName,setUpdateFuriganaName]=useState('')  // update furigana name
const [getIngredNameFuri,setIngredNameFuri]=useState('') // this is the furigana name
const [intervalId, setIntervalId] = useState(null); // push button to incrrease value interval


    const [windowWidth,setWindowWith]=useState(0) // this is the window width
    const [windowHeight,setWindowHeight]=useState(0)  // this is the window height
    const [mobileIsUsed,setMobileIsUsed]=useState(false) // this is a variable set to true if the mobile layout is used



  const  changeDate=useFormattedDateNow()



  useEffect(()=>{
    let country = localStorage.getItem('country')||'en' // this is the country code
    setCountry(country)
  },[])



  let newFontFamily = '';
  if (getCountry === 'ja') {
     newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
  } else {
    newFontFamily = 'Arial, Helvetica, sans-serif'
    
  //newFontFamily = 'Times New Roman, Times, serif'
  //newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
  }
  document.documentElement.style.setProperty('--font-family', newFontFamily);

  
  const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal } = usePageSettings(pageid);
  const { imageUrl, alertMessage, alertCode, showAlert } = useFetchImageUrl(getImageVal); // runs at mount, gives ut the image url and fetch image from server

  const navigate = useNavigate();


  useEffect(() => {
    function setSize() {
      setWindowWith(window.innerWidth);
      setWindowHeight(window.innerHeight);
    
      if(window.innerWidth < 600){
  
        setMobileIsUsed(true)
      } 
  
    }

  
    // Call setSize initially to set the correct state
    setSize();
  
    // Add the event listener
    window.addEventListener('resize', setSize);
  
    // Return a cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', setSize);
    };
  }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]); // Dependencies

  function rgbToRgba(rgb, alpha) {
    return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
  }
  
//--bakcground shade settings
let rgbaVal
let rgbaVal2
let val

if(getColorVal && getColorVal.length > 0){
  let rgb = getColorVal;
  let alpha = getMenuShade; // replace with your alpha value
  let alpha2 = getMenuShade2; // replace with your alpha value
  rgbaVal = rgbToRgba(rgb, alpha);
  rgbaVal2 = rgbToRgba(rgb, alpha2);
  val=`rgba(255, 255, 255, ${getBackGroundShade})`

  }else{
    rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
    rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
    val=`rgba(255, 255, 255, ${getBackGroundShade})`
  }
//---

useEffect(() => {
    let root = document.documentElement;
    root.style.setProperty('--rgbaVal', rgbaVal);
    root.style.setProperty('--rgbaVal2', rgbaVal2);
    root.style.setProperty('--val',val);
    root.style.setProperty('--windowHeight',windowHeight+'px');

    
  }, [getBackGroundShade,getMenuShade,getMenuShade2]);

  //-----



  
// language settings
  const selectedCountry = languages[getCountry];

   let ingredienslistaTitle=''
  if(mobileIsUsed===false){
    ingredienslistaTitle=selectedCountry.ingredienser?.title
  }else{
    ingredienslistaTitle=selectedCountry.ingredienser?.titleMobile
  }
  const {
    ingredbutton,
    ingredcreatebutton,
    receptkoppbutton,
    produktkoppbutton,
    inkopbutton,
    prisdiabutton,
    nutrientbutton,
    konvertbutton,
    ingredinfobutton,
    chooseingredcategoryFieldset,
    ingredcategoryFirstOption1,
    changeingredNameFieldset,
    changeingredNameSaveButton,
    nutrientHeaderHas,
    nutrientHeaderReceptKopp,
    nutrientHeaderAnd,
    nutrientHeaderProdkopp,
    nutrientHeaderSmall,
    nutrientDeleteInfoButton,
    nutrientlistTopSubject,
    nutrientlistTopAmount,
    nutrientListTopWeight,
    createingredientNameLegend,
    createingredientSaveButton,
    produktkoppHeaderLegendText,
    produktkoppInputHeaderTextLegend,
    produktkoppInputHeaderTextLegend2,
    produktkoppMessageTextLegend,
    produktkoppSaveButtonText,
    inkopsprisChangePriceLegend,
    inkopsprisLatestPriceLegend,
    inkopsprisListHeaderLegend,
    inkopChangePriceSaveButton,
    prisdiagramChoseChartLegend,
    nutrientHeaderLegend,
    nutrientFetchDataButtonText,
    nutrientSaveDataButtonText,
    nutrientListHeaderTextSubject,
    nutrientListHeaderTextAmount,
    nutrientListHeaderTextWeight,
    konvertHeaderTextLegend,
    konvertHeaderTextLegend2,
    konvertSaveButtonText,
    ingredInfoHeaderTextlegend,
    ingredInfoGetInfoButtonText,
    ingredInfoGetNewInfoText,
    ingredcanberemovedText,
    recepyConnectionHeader,
    recepySelectHeaderLegend,
    recepySelectHeaderLegend2,
    recepySelectedStatementLegend,
    recepyConnectionSaveButtonText,
    produktkoppHeaderTextLegend2,
    inkopPriceHeaderBenaemn,
    inkopPriceHeaderPrice,
    prisdiagramHeaderLegend,
    konvertDisclaimerText,
    konvertGramText,
    measureTableSpoon,
    measureTeaSpoon,
    measureDeciliter,
    measureMilliliter,
    measurePinch,
    ingredSelect,
    measureSelect
} = selectedCountry.ingredienser || {};

const {
  ingredientCreated,
  ingredientNameUpdated,
  ingredientUpdated,
  ingredientReplaced,
  priceUpdated,
  ingredientDeleted,
  nutrientsUpdated,
  ingredientVoid,
  ingredientInfoVoid,
  convertVoid,
} = selectedCountry.confirmationMessages||{};

     // error messages
  const selectedError=AppError[localStorage.getItem('country')||'en']
  




  useEffect(() => {
    GetSettings();
  }, [GetSettings]);

  // we set the image url here
  useEffect(() => {
    setImageUrl(imageUrl);
    if (alertCode > 0) {
      setAlertMessage(alertMessage);
      setAlertCode(alertCode);
      setShowAlert(showAlert);
    }
  }, [imageUrl]);

  // here we set the color values and image values
  useEffect(() => {
    setBackGroundShade(backGroundShade);
    setMenuShade(menuShade);
    setMenuShade2(menuShade2);
    setColorVal(colorVal);
    setImageVal(imageVal);
  }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);

  
  useEffect(() => {
    async function FetchAllIngred() {
      setSpinnerLoading(true)
      setnutritionData([])
      
      try {
      
        // i want to send the country code here to get the right language
      
        const response = await useGetData(`getAllIngred`)
        setKategori(response.data[0]);
        setSpinnerLoading(false)
   
      } catch (error) {
        setAlertCode(27001)
        setAlertMessage(selectedError[27001])
        setSpinnerLoading(false)
        setShowAlert(true)
  
      }
   }
    async function GetIngredByCategory(){
      setSpinnerLoading(true)
      try{
        let response=await useGetData(`getIngredByCategory/${getchosenIngredCategory}`)
        setIngredData(response.data[0])
        setSpinnerLoading(false)
      }catch(error){
        setAlertCode(27002)
        setAlertMessage(selectedError[27002])
        setSpinnerLoading(false)
      }

    }
  
     GetIngredByCategory()
     FetchAllIngred();

    // FetchLev()
  }, [getchosenIngredCategory,activeButton]);
  
  useEffect(() => {

    setIngredData([])
    setKategori([])
    setchosenIngredCategory(null)
  }, [activeButton]);
  
  
  
    async function SaveIngredient() {
      setSpinnerLoading(true)
      try {
        const response = await usePostData(`createnewIngred1`,{getIngredName,getchosenIngredCategory}) 
        //const response = await useGetData(`createnewIngred1/${getIngredName}/${getchosenIngredCategory}`) 
        if(response.status===200){
        setIngredid(response.data[0][0].highestingrednumber)
        setchosenIngredCategory(0)
        setSpinnerLoading(false)
        setAlertCode(0)
        setAlertMessage(ingredientCreated)
        setShowAlert(true)
        }else if(response.status===201){
          setAlertCode(0)
          setSpinnerLoading(false)
          setAlertMessage(ingredientVoid)
          setSpinnerLoading(false)
          setShowAlert(true)
        }
      
      } catch (error) {
        setAlertCode(27003)
        setAlertMessage(selectedError[27003])
        setSpinnerLoading(false)
        setShowAlert(true)
      }
  
    
    }
  

  
  
  const RunReportErrHandler = async (errCode) => {
    setSpinnerLoading(true);

    await useReportErr('ErrReport',{errCode});
    setSpinnerLoading(false);
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  
  useEffect(() => {
    if (getAlertCode > 0) {
      RunReportErrHandler(getAlertCode);
    }
  }, [getAlertCode]);
  


  const menuchoiceHandler=(val)=>{
    setIngredid(0)
    setnutritionData([])
    setReceivedDataStatus(false)
    setIngredName('')
    setchosenIngredid(0)
    setLevRowData([])
    setKanjiIngredName('')
    setFuriganaIngredName('')

      if(val===1){
         setActiveButton(1)
         setSpinnerLoading(true)
}
      else if(val===2){
      setActiveButton(2)
      setSpinnerLoading(true)
}
      else if(val===3){
      setActiveButton(3)
      setSpinnerLoading(true)
  }
      else if(val===4){
      setActiveButton(4)
      setSpinnerLoading(true)
      }
      else if(val===5){
      setActiveButton(5)
      setSpinnerLoading(true)
  }
      else if(val===6){
      setActiveButton(6)
      setSpinnerLoading(true)
      setLevRowData([])
  }
      else if(val===7){
      setActiveButton(7)
      setSpinnerLoading(true)
      setChartData([])
    }
      else if(val===8){
      setActiveButton(8)
      setSpinnerLoading(true)
      setShowDeleteIngredient(false)
  } else if(val===9){
    setActiveButton(9)
    setSpinnerLoading(true)
    setShowDeleteIngredient(false)
}else if(val===10){
  setActiveButton(10)
  setSpinnerLoading(true)
  setShowDeleteIngredient(false)
}
else if(val===11){
  setActiveButton(11)
  setSpinnerLoading(true)
  setShowDeleteIngredient(false)
}


    setTimeout(() => {
      setSpinnerLoading(false);
    }, 3000)

  }




const kategoriHandler=(val)=>{
  setchosenIngredCategory(val)
  setExpandedRow(null)
  setNewIngredientInProduct(null)
   setShowDeleteIngredient(true)
  setLevRowData([])
}

const nutrientInfoHandler=()=>{
  setShowNutrientInfo(true)
  GetFoodInfoHandler()
}

useEffect(()=>{
  setSpinnerLoading(true)
  async function GetLevRowData(){
 
    try{
      let response=await useGetData(`getLevRowByIngredidNew/${chosenIngredid}`)
      setLevRowData(response.data[0])
      setLatestPrice(response.data[0][0].pris)
      setSpinnerLoading(false)
   

    }catch(error){
      setAlertCode(27004)
      setAlertMessage(selectedError[27004])
       setShowAlert(true)
      setSpinnerLoading(false)
      // setTimeout(() => {
      //   navigate('/Logout');
      // },logtime);
   
    }
  }

  GetLevRowData()
  async function GetAvailableNutritionData(){
    setSpinnerLoading(true)

    try{
      let response=await useGetData(`getNutritionData/${chosenIngredid}`)
      setnutritionData(response.data[0])
     
      setReceivedDataStatus(true)
    }catch(error){
      setAlertCode(27005)
      setAlertMessage(selectedError[27005])
      setShowAlert(true)
      setSpinnerLoading(false)
    }
  }
  GetAvailableNutritionData()

   
  async function GetProductsRecepies(){
    setSpinnerLoading(true)

                  try{
                      const response=await useGetData(`getProductsAndRecepies/${chosenIngredid}`)
                        setRecepies(response.data[0])
                        setProducts(response.data[1])
                        setRecepyConnection(response.data[2])
                        setProductConnection(response.data[3])
                        setChartData(response.data[4])
                  
                   
                  }catch(error){
                        setAlertCode(27006)
                        setAlertMessage(selectedError[27006])
                        setSpinnerLoading(false)
                        setShowAlert(true)
                   
                  }
}



GetProductsRecepies()
setTimeout(() => {
  setSpinnerLoading(false);
}, 2000);
},[chosenIngredid])

useEffect(()=>{
  setSpinnerLoading(true)
  async function GetLevRowData(){
 
    try{
      let response=await useGetData(`getLevRowByIngredidNew/${chosenIngredid}`)
   
      setLevRowData(response.data[0])
      setLatestPrice(response.data[0][0].pris)
      setNewRowData(false)
      setSpinnerLoading(false)
   

    }catch(error){
      setAlertCode(27007)
      setAlertMessage(selectedError[27007])
      setShowAlert(true)
      setSpinnerLoading(false)
    }

  }

  GetLevRowData()
},[getNewRowData])

useEffect(()=>{
  async function GetAvailableNutritionData(){
    setSpinnerLoading(true)

    try{
      let response=await useGetData(`getNutritionDataAndEmpty/${chosenIngredid}`)
      setnutritionData(response.data[0])
     
      setReceivedDataStatus(true)
      setSpinnerLoading(false)
    }catch(error){
      setAlertCode(27008)
      setAlertMessage(selectedError[27008])
      setShowAlert(true)
      setSpinnerLoading(false)
    }


  }
  GetAvailableNutritionData()
  setNutritionAndEmpty(false)
  setNutritionSaveStatus(false)

},[getNutritionAndEmpty])



async function GetFoodInfoHandler(){


let textToTranslate=getIngredName
let language='en'

// here i need to make a request for translation
setSpinnerLoading(true)
let translateIngredid
if(getIngredid===0){
  translateIngredid=chosenIngredid
}else{
  translateIngredid=getIngredid
}

try{
  let response=await useGetData(`translate/${language}/${textToTranslate}/${chosenIngredid}`)
  if(response.status===200){
  setReceivedDataStatus(true)
  setSpinnerLoading(false)
setnutritionData(response.data)


 
  }else if(response.status===500){
    setAlertCode(27010)
    setAlertMessage(selectedError[27010])
    setShowAlert(true)
    setSpinnerLoading(false)
    return
  }
  }catch(error){
  setAlertCode(27010)
  setAlertMessage(selectedError[27010])
  setShowAlert(true)
  setSpinnerLoading(false)
  }


}




const handleRowClickIndex = (val,index) => {

  setchosenIngredid(val)
  setChangeIngredObj([])
  setChangeProductObj([])
  setNewIngredientInRecepy(0)
  setShowPris(true)
  setNewRowData(true)
  setConvertedValue(0)
  setIngredInformation([])
  setcheckval(0)
  setIngredNameUpdated(false) // when clicking on a new ingred we reset the ingrednameupdated
  setPriceIsUpdated(false)   // reset the price is updated
  setVolymeInstrument(0) // reset the volyme instrument
  setKanjiIngredName('')
  setFuriganaIngredName('')


  setIngredName(getIngredData.find((data)=>data.ingredid===val).benaemn)
  setIngredNameFuri(getIngredData.find((data)=>data.ingredid===val).benaemnfuri)
     if (expandedRow === index) {

       setExpandedRow(null);
    
     } else {
       setExpandedRow(index);

     }


   };

const ingredNameHandler=(val)=>{
  setIngredName(val)
  setIngredNameUpdated(true)
}
const saveHandler=()=>{
SaveIngredient()
}

const saveNameHandler=()=>{
  SaveName()
}

async function SaveName(){
  setSpinnerLoading(true)
  try{
    let response = await usePutData(`updateIngredName`,{chosenIngredid,getIngredName})
    //let response = await usePutData(`updateIngredName/${chosenIngredid}/${getIngredName}`)
    
    if(response.status===200){
      let newObjArr=[...getIngredData]
      let newObjArrIndex=newObjArr.findIndex((data)=>data.ingredid===chosenIngredid)
      newObjArr[newObjArrIndex].benaemn=getIngredName
      setIngredData(newObjArr)

      setAlertCode(0)
      setAlertMessage(ingredientNameUpdated)
      setShowAlert(true)
      setSpinnerLoading(false)
      setExpandedRow(null)
      return
    }
  }catch(error){
    setAlertCode(27011)
    setAlertMessage(selectedError[27011])
    setShowAlert(true)
    setSpinnerLoading(false)
  }
  setSpinnerLoading(false)
}

const changeIngredientOnRecepyHandler=(recepyid)=>{
  let mynewObjArr=[...getChangeIngredObj]
  let myrecepiesObj=[...getRecepies]
  //find the recewpieid in the array
  let myrecepiesObjInfo=myrecepiesObj.findIndex((data)=>data.receptid===recepyid)
let benaemn=myrecepiesObj[myrecepiesObjInfo].benaemn
  // we push the recepyid to the array
  mynewObjArr.push({['receptid']:recepyid,['receptnamn']:benaemn})
  //update the state
  setChangeIngredObj(mynewObjArr)
  //here we need to remove the recepyid from the array getrecepies
  let mynewrecepiesObj=[...getRecepies]
  let mynewrecepiesObjIndex=mynewrecepiesObj.findIndex((data)=>data.receptid===recepyid)
  mynewrecepiesObj.splice(mynewrecepiesObjIndex,1)
  setRecepies(mynewrecepiesObj)


}
const newIngredientInRecepyHandler=(receptid)=>{
  setNewIngredientInRecepy(receptid)

}

async function ReplaceIngredient(){
  setSpinnerLoading(true)
  // object with the chosen recepies
  let mynewObjArr=[...getChangeIngredObj]
 // let encodedObj=btoa(JSON.stringify(mynewObjArr))
  let encodedObj = encodeURIComponent(JSON.stringify(mynewObjArr));
  try{
    let response=await usePutData(`replaceIngredientInRecepy`,{encodedObj,getNewIngredientInRecepy,chosenIngredid})
    //let response=await usePostData(`replaceIngredientInRecepy/${encodedObj}/${getNewIngredientInRecepy}/${chosenIngredid}`)
   
    if(response.status===200){
      setSpinnerLoading(false)
      setAlertCode(0)
      setAlertMessage(ingredientReplaced)
      setShowAlert(true)
    }
}catch(error){
  setAlertCode(27012)
  setAlertMessage(selectedError[27012])
  setShowAlert(true)
  setSpinnerLoading(false)

}
setSpinnerLoading(false)
}

async function ReplaceIngredientOnProductHandlerSave(){
  setSpinnerLoading(true)

  let mynewObjArr=[...getChangeProductObj]
  let encodedObj=btoa(JSON.stringify(mynewObjArr))
  try{
    let response=await usePutData(`replaceIngredientOnProduct`,{encodedObj,getNewIngredientInProduct,chosenIngredid})
   
    if(response.status===200){
      setSpinnerLoading(false)
      setAlertCode(0)
      setAlertMessage(ingredientReplaced)
      setShowAlert(true)
    }


}catch(error){
  setAlertCode(27013)
  setAlertMessage(selectedError[27013])
  setShowAlert(true)
  setSpinnerLoading(false)
  return
}
}



const changeIngredientOnProductHandler=(productid)=>{
  let mynewObjArr=[...getChangeProductObj]
  let myProductsObj=[...getProducts]
  //find the recewpieid in the array
  let myProductsObjInfo=myProductsObj.findIndex((data)=>data.id===productid)
let benaemn=myProductsObj[myProductsObjInfo].benaemn
  // we push the recepyid to the array
  mynewObjArr.push({['produktid']:productid,['benaemn']:benaemn})
  //update the state
  setChangeProductObj(mynewObjArr)
  //here we need to remove the recepyid from the array getrecepies
  let mynewProductsObj=[...getProducts]
  let mynewProductsObjIndex=mynewProductsObj.findIndex((data)=>data.id===productid)
  mynewProductsObj.splice(mynewProductsObjIndex,1)
  setProducts(mynewProductsObj)

}
const newIngredientInProductHandler=(ingredid)=>{
  setNewIngredientInProduct(ingredid)

}

const removeFromListHandler=(productid)=>{
  let mynewObjArr=[...getChangeProductObj]
  let myProductsObj=[...getProducts]
  // we will remove the item from the getchangeproductobj
  let mynewObjArrIndex=mynewObjArr.findIndex((data)=>data.produktid===productid)
  mynewObjArr.splice(mynewObjArrIndex,1)
  setChangeProductObj(mynewObjArr)


}
// const UpdatePriceHandler=(pricetype)=>{
//   if(pricetype===1){
//     setLatestPrice(getLatestPrice+1)
//   }else if(pricetype===2){
//     setLatestPrice(getLatestPrice-1)
//   }
// }
const UpdatePriceHandler = (pricetype) => {
  if (pricetype === 1) {
    setLatestPrice(prevPrice => Number(prevPrice) + 1);
    setPriceIsUpdated(true)
  } else if (pricetype === 2) {
    setLatestPrice(prevPrice => Number(prevPrice) - 1);
  }
};


async function SaveNewPriceHandler(){

  setSpinnerLoading(true)
  try{
    let response=await usePostData(`saveNewPrice`,{chosenIngredid,getLatestPrice})
   // let response=await usePostData(`saveNewPrice/${chosenIngredid}/${getLatestPrice}`)
    
    if(response.status===200){
      setSpinnerLoading(false)
      setAlertCode(0)
      setAlertMessage(priceUpdated)
      setShowAlert(true)
    }
  }catch(error){
    setSpinnerLoading(false)
    setAlertCode(27014)
    setAlertMessage(selectedError[27014])
    setShowAlert(true)
    return
  }
  setSpinnerLoading(false)
  setNewRowData(true)
}

const chartHandler=(val)=>{
  setActiveButtonChart(val)

}

async function DeleteIngredientHandler(){
  try{
    //let response=await useDeleteData(`deleteIngredient`,{chosenIngredid})
   let response=await useDeleteData(`deleteIngredient/${chosenIngredid}`)
  
    if(response.status===200){
      setSpinnerLoading(false)
      setAlertCode(0)
      setAlertMessage(ingredientDeleted)
      setShowAlert(true)
    }
 
  }catch(error){
    setSpinnerLoading(false)
  setAlertCode(27015)
  setAlertMessage(selectedError[27015])
  setShowAlert(true)
  return
}
//here we need to remove the ingredient from the array getingreddata
let mynewIngredData=[...getIngredData]
let mynewIngredDataIndex=mynewIngredData.findIndex((data)=>data.ingredid===chosenIngredid)
mynewIngredData.splice(mynewIngredDataIndex,1)
setIngredData(mynewIngredData)
setSpinnerLoading(false)
setExpandedRow(null)
}

const getNutritionHandler=(ingredid)=>{
  setNutritionAndEmpty(true)
  setchosenIngredid(ingredid)
  setIngredName(getIngredData.find((data)=>data.ingredid===ingredid).benaemn)


}

function ChangeNutrientAmountHandler(nutrient,amount){
  //we copy the nutrientdata
  let mynewObjArr=[...getnutritionData]
  // we find the index of the nutrient
  let mynewObjArrIndex=mynewObjArr.findIndex((data)=>data.nutrient===nutrient)
  // we update the amount
  mynewObjArr[mynewObjArrIndex].quantity=amount
  // we update the state
  setnutritionData(mynewObjArr)
  setNutritionSaveStatus(true)
}


async function SaveNutritionValues(){
  let encodedObj=btoa(JSON.stringify(getnutritionData))
  setSpinnerLoading(true)
  try{
   // let response=await useGetData(`saveNutritionValues/${encodedObj}/${chosenIngredid}`)
    let response=await usePostData(`saveNutritionValues`,{encodedObj,chosenIngredid})

    if(response.status===200){
      setSpinnerLoading(false)
      setAlertCode(0)
      setAlertMessage(nutrientsUpdated)
      setShowAlert(true)
      return
    }
  }catch(error){
    setSpinnerLoading(false)
    setAlertCode(27016)
    setAlertMessage(selectedError[27016])
    setShowAlert(true)
    return
  }
  setSpinnerLoading(false)
  setNutritionSaveStatus(false)
  // setNutritionAndEmpty(true)
}
const setBackGroundShadeHandler=(val)=>{
  setBackGroundShade(val)
  setRenderKey(getRenderKey+1)

}



  async function ConvertToGramHandler(){
    console.log('im here')
    setSpinnerLoading(true)
    try{
      let response=await useGetData(`AIconvertToGram/${chosenIngredid}/${getIngredName}/${getVolymeInstrument}`)
       console.log(response)
        if (response && response.data && response.data.data) {
        setConvertedValue(response.data.data);
    }else if(response.status===201){
      setAlertCode(0)
      setAlertMessage(convertVoid)
      setSpinnerLoading(false)
      setShowAlert(true)
      return;
    }
    
    else {
        // Fallback action, e.g., set a default value or handle error
        setConvertedValue(0); // or handle the error
    }
      
      setSpinnerLoading(false)
    }catch(error){
      setAlertCode(27017)
      setAlertMessage(selectedError[27017])
      setShowAlert(true)
      setSpinnerLoading(false)
  
      return

    }
  }

const choseVolumeInstrumentHandler=(instrument)=>{
  setVolymeInstrument(instrument)

}

const numVolumnsHandler=(val)=>{
  setNumVolymes(val)
}

async function GetAIIngredientInformation(){
  setIngredInformation([])
  setSpinnerLoading(true)
  try{
    let response=await useGetData(`AIgetIngredientInfo/${getIngredName}/${chosenIngredid}/${getcheckval}`)
   // let response=await useGetDataNew(`AIgetIngredientInfo`,{getIngredName,chosenIngredid,getcheckval}) 
  console.log(response)
   if(response.status===200){
    setIngredInformation(response.data)
    setTimeout(() => {
      setSpinnerLoading(false)
    }, 2000); // Delay of 1 second
   return;
    }else if(response.status===201){
      setAlertCode(0)
      setAlertMessage(ingredientInfoVoid)
      setSpinnerLoading(false)
      setShowAlert(true)
    }
  }catch(error){
    setAlertCode(27018)
    setAlertMessage(selectedError[27018])
    setShowAlert(true)
    setSpinnerLoading(false)
    return
  }


}


const checkvalHandler=(val)=>{
  //toggle here
  if(getcheckval===0){
    setcheckval(1)
  }
  else if(getcheckval===1){
    setcheckval(0)
  }

}

// if the user changges the value on the range control we save the value in the database
async function SetBackGroundShadeHandler(val){
  setBackGroundShade(val)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateBackGroundShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }
  }catch(error){
      setAlertCode(27019)
      setAlertMessage(selectedError[27019])
      setSpinnerLoading(false)
      setShowAlert(true)

    }

}

async function SetmenuColorHandler(val){
  setMenuShade(val)
  setMenuShade2(val-0.20)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateMenuShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }

}catch(error){
  setAlertCode(27020)
  setAlertMessage(selectedError[27020])
  setSpinnerLoading(false)
  setShowAlert(true)

}
}

const calculateQuantity = (data) => {
  return parseFloat(data.quantity);
};

const sortNutrientDataHandler = () => {
  if(getNutrientDataSort === false){
    let myNewObjArr = getnutritionData.map(data => ({ quantity: calculateQuantity(data), data }));
    myNewObjArr.sort((a, b) => a.quantity - b.quantity);
    setnutritionData(myNewObjArr.map(obj => obj.data));
    setNutrientDataSort(true)
  } else if(getNutrientDataSort === true){
    let myNewObjArr = getnutritionData.map(data => ({ quantity: calculateQuantity(data), data }));
    myNewObjArr.sort((a, b) => b.quantity - a.quantity);
    setnutritionData(myNewObjArr.map(obj => obj.data));
    setNutrientDataSort(false)
  }
}

const sortNutrientNameHandler = () => {
  if(getNutrientNameSort === false){
    let myNewObjArr = getnutritionData.map(data => ({ quantity: calculateQuantity(data), data }));
    myNewObjArr.sort((a, b) => a.data.nutrient.localeCompare(b.data.nutrient));
    setnutritionData(myNewObjArr.map(obj => obj.data));
    setNutrientNameSort(true)
  } else if(getNutrientNameSort === true){
    let myNewObjArr = getnutritionData.map(data => ({ quantity: calculateQuantity(data), data }));
    myNewObjArr.sort((a, b) => b.data.nutrient.localeCompare(a.data.nutrient));
    setnutritionData(myNewObjArr.map(obj => obj.data));
    setNutrientNameSort(false)
  }

}

const kanjiNameHandler=(val)=>{
  setKanjiIngredName(val)

}

const furiganaNameHandler=(val)=>{
  setFuriganaIngredName(val)
}

const saveJAPIngredientHandler=()=>{
SaveJAPIngredient()
}

async function SaveJAPIngredient(){
  setSpinnerLoading(true)
  try{
    let response=await usePostData(`createnewJAPIngred`,{getKanjiIngredName,getFuriganaIngredName,getchosenIngredCategory})
      setIngredid(response.data[0][0].highestingrednumber)
      setSpinnerLoading(false)
      setAlertCode(0)
      setAlertMessage('Ingrediens uppdaterad')
      setShowAlert(true)
    
  }catch(error){
    setAlertCode(27021)
    setAlertMessage(selectedError[27021])
    setShowAlert(true)
    setSpinnerLoading(false)
    return
  }
  setSpinnerLoading(false)
}

const ingredKanjiNameHandler=(val)=>{
  setIngredName(val)
}
const ingredFuriganaNameHandler=(val)=>{
  setIngredNameFuri(val)
}
const saveJAPNameHandler=()=>{
  // imsaving the information here
UpdateJAPIngredientName()
  
}

async function UpdateJAPIngredientName(){
  setSpinnerLoading(true)
  try{
    let response=await usePutData(`updateIngredJAPName`,{chosenIngredid,getIngredName,getIngredNameFuri})
    if(response.status===200){
      let newObjArr=[...getIngredData]
      let newObjArrIndex=newObjArr.findIndex((data)=>data.ingredid===chosenIngredid)
      newObjArr[newObjArrIndex].benaemn=getIngredName
      // no furigana
      setIngredData(newObjArr)
      setSpinnerLoading(false)
      setAlertCode(0)
      setAlertMessage('Ingrediens namn uppdaterat')
      setShowAlert(true)
      setExpandedRow(null)
      return
    }
  }catch(error){
    setAlertCode(27022)
    setAlertMessage(selectedError[27022])
    setShowAlert(true)
    setSpinnerLoading(false)
    return
  }
  setSpinnerLoading(false)
}


const startUpdatingPrice = (direction) => {
  const id = setInterval(() => {
    UpdatePriceHandler(direction);
  }, 100); // Change the interval as needed

  setIntervalId(id);
  
  // Add event listeners to the document
  document.addEventListener('touchend', stopUpdatingPrice);
  document.addEventListener('touchcancel', stopUpdatingPrice);
};

const stopUpdatingPrice = () => {

  clearInterval(intervalId);
  setIntervalId(null);

    // Remove event listeners from the document
    document.removeEventListener('touchend', stopUpdatingPrice);
    document.removeEventListener('touchcancel', stopUpdatingPrice);
};


let filteredIngredients=getIngredData
    
  return (
<>
{windowWidth<360?displayGadgetToSmall():''}
<div className={getBackGroundShade===1?"xetotalArea1":"xetotalArea"}>
{getBackGroundShade<1?<img src={imageUrl} alt="Bakesoft" className="backgroundImageListIngred" /> :
<div className="backgroundImageListIngred1"/>
}
<div className="xeheaderArea">
  {getCountry!=='ja'?
<div className={getBackGroundShade<1?"xecompName":"xecompName1"}>{ingredienslistaTitle}</div> 
:<div className={getBackGroundShade<1?"xecompNameJAP":"xecompNameJAP1"}>{ingredienslistaTitle}</div> 
}
</div>

<div className="xerangeArea">

<input type="range" min="0.0" max="1" value={getMenuShade||0.2} step="0.10" id={2}  
title="Justera bakgrund" className="xerange2" onChange={(e)=>SetmenuColorHandler(e.target.value)}/>


<input type="range" min="0.0" max="1" value={getBackGroundShade||0.2} step="0.10" id={1}  
        title="Justera bakgrund" className="xerange1" onChange={(e)=>SetBackGroundShadeHandler(e.target.value)}/>
</div>
  


 


<div className="xeareaA">
<div className="xeareaMenuButton">
  <Ingredmenubuttons menuchoiceHandler={menuchoiceHandler} activeButton={activeButton} ingred={ingred}
  ingredbutton={ingredbutton} ingredcreatebutton={ingredcreatebutton} getCountry={getCountry}
  receptkoppbutton={receptkoppbutton} produktkoppbutton={produktkoppbutton}
  inkopbutton={inkopbutton} prisdiabutton={prisdiabutton} nutrientbutton={nutrientbutton}
  konvertbutton={konvertbutton} ingredinfobutton={ingredinfobutton} 

  />

</div>
</div>
<div className="xeareaB">
{activeButton===8?<Ingredientlist ingred={ingred} filteredIngredients={filteredIngredients} 
chosenIngredid={chosenIngredid} handleRowClickIndex={handleRowClickIndex}  chosenIngredcategory={getchosenIngredCategory}
expandedRow={expandedRow} kategoriHandler={kategoriHandler} getKategori={getKategori}
activeButton={activeButton} saveNameHandler={saveNameHandler} ingredNameHandler={ingredNameHandler}
getIngredName={getIngredName} getIngredData={getIngredData} getIngredNameUpdated={getIngredNameUpdated}
chooseingredcategoryFieldset={chooseingredcategoryFieldset} ingredcategoryFirstOption1={ingredcategoryFirstOption1}
changeingredNameFieldset={changeingredNameFieldset} changeingredNameSaveButton={changeingredNameSaveButton}
getCountry={getCountry}
ingredKanjiNameHandler={ingredKanjiNameHandler}
ingredFuriganaNameHandler={ingredFuriganaNameHandler}
saveJAPNameHandler={saveJAPNameHandler} 
getIngredNameFuri={getIngredNameFuri}
/>:''}

{activeButton===4?<IngredientlistSimple ingred={ingred} filteredIngredients={filteredIngredients}
  chosenIngredid={chosenIngredid} handleRowClickIndex={handleRowClickIndex} chosenIngredcategory={getchosenIngredCategory}
  expandedRow={expandedRow} kategoriHandler={kategoriHandler} getKategori={getKategori}
  activeButton={activeButton} saveNameHandler={saveNameHandler} ingredNameHandler={ingredNameHandler}
  chooseingredientCategoryFieldset={chooseingredcategoryFieldset}
  ingredcategoryFirstOption1={ingredcategoryFirstOption1}
  getIngredName={getIngredName}/>:''}




{activeButton===1&&getCountry!=='ja'?<CreateIngredient ingred={ingred} kategoriHandler={kategoriHandler} getKategori={getKategori}
 nutrientInfoHandler={nutrientInfoHandler} getNutrientInfo={getNutrientInfo}
  ingredNameHandler={ingredNameHandler} getnutritionData={getnutritionData}
  getReceivedDataStatus={getReceivedDataStatus} getIngredName={getIngredName}
  saveHandler={saveHandler} getIngredid={getIngredid} getchosenIngredCategory={getchosenIngredCategory}
  createingredientNameLegend={createingredientNameLegend} createingredientSaveButton={createingredientSaveButton}
  chooseingredcategoryFieldset={chooseingredcategoryFieldset} ingredcategoryFirstOption1={ingredcategoryFirstOption1}
  getShowNutrientInfo={getShowNutrientInfo}/>:''}

  
{activeButton===1&&getCountry==='ja'?<CreateJAPIngredient ingred={ingred} kategoriHandler={kategoriHandler} getKategori={getKategori}
 nutrientInfoHandler={nutrientInfoHandler} getNutrientInfo={getNutrientInfo}
  ingredNameHandler={ingredNameHandler} getnutritionData={getnutritionData}
  getReceivedDataStatus={getReceivedDataStatus} getIngredName={getIngredName}
  saveHandler={saveHandler} getIngredid={getIngredid} getchosenIngredCategory={getchosenIngredCategory}
  createingredientNameLegend={createingredientNameLegend} createingredientSaveButton={createingredientSaveButton}
  chooseingredcategoryFieldset={chooseingredcategoryFieldset} ingredcategoryFirstOption1={ingredcategoryFirstOption1}
  getShowNutrientInfo={getShowNutrientInfo}
  furiganaNameHandler={furiganaNameHandler}
  kanjiNameHandler={kanjiNameHandler}
  getKanjiIngredName={getKanjiIngredName}
  getFuriganaIngredName={getFuriganaIngredName}
  saveJAPIngredientHandler={saveJAPIngredientHandler}
  />:''}

{activeButton===5?<IngredientlistSimple ingred={ingred} filteredIngredients={filteredIngredients} 
chosenIngredid={chosenIngredid} handleRowClickIndex={handleRowClickIndex} 
expandedRow={expandedRow} kategoriHandler={kategoriHandler} getKategori={getKategori}
activeButton={activeButton} saveNameHandler={saveNameHandler} ingredNameHandler={ingredNameHandler}
  chooseingredientCategoryFieldset={chooseingredcategoryFieldset}
  ingredcategoryFirstOption1={ingredcategoryFirstOption1}
getIngredName={getIngredName}/>:''}

{activeButton===6?<IngredientlistPrice ingred={ingred} filteredIngredients={filteredIngredients} 
chosenIngredid={chosenIngredid} handleRowClickIndex={handleRowClickIndex} 
expandedRow={expandedRow} kategoriHandler={kategoriHandler} getKategori={getKategori}
activeButton={activeButton} saveNameHandler={saveNameHandler} ingredNameHandler={ingredNameHandler}
getIngredName={getIngredName} updatePriceHandler={UpdatePriceHandler}
SaveNewPriceHandler={SaveNewPriceHandler}
chooseingredcategoryFieldset={chooseingredcategoryFieldset}
ingredcategoryFirstOption1={ingredcategoryFirstOption1}
inkopsprisChangePriceLegend={inkopsprisChangePriceLegend}
inkopChangePriceSaveButton={inkopChangePriceSaveButton}
getPriceIsUpdated={getPriceIsUpdated} startUpdatingPrice={startUpdatingPrice}
stopUpdatingPrice={stopUpdatingPrice}
/>:''}

{activeButton===7?<IngredientlistSimple ingred={ingred} filteredIngredients={filteredIngredients} 
chosenIngredid={chosenIngredid} handleRowClickIndex={handleRowClickIndex} 
expandedRow={expandedRow} kategoriHandler={kategoriHandler} getKategori={getKategori}
activeButton={activeButton} saveNameHandler={saveNameHandler} ingredNameHandler={ingredNameHandler}
getIngredName={getIngredName}
chooseingredientCategoryFieldset={chooseingredcategoryFieldset}
ingredcategoryFirstOption1={ingredcategoryFirstOption1}
/>:''}
{/* {activeButton===7?<ChartChoice  
ingred={ingred} chartHandler={chartHandler} getActiveButtonChart={getActiveButtonChart}
prisdiagramChoseChartLegend={prisdiagramChoseChartLegend}
/>:''} */}

{activeButton===9?<IngredientlistNutrient ingred={ingred} filteredIngredients={filteredIngredients}
  chosenIngredid={chosenIngredid} handleRowClickIndex={handleRowClickIndex}
  expandedRow={expandedRow} kategoriHandler={kategoriHandler} getKategori={getKategori}
  activeButton={activeButton} saveNameHandler={saveNameHandler} ingredNameHandler={ingredNameHandler}
  getIngredName={getIngredName} getNutritionHandler={getNutritionHandler}
  chooseingredcategoryFieldset={chooseingredcategoryFieldset}
ingredcategoryFirstOption1={ingredcategoryFirstOption1}
  />:''}
  

{activeButton===10?<IngredientlistSimple ingred={ingred} filteredIngredients={filteredIngredients} 
chosenIngredid={chosenIngredid} handleRowClickIndex={handleRowClickIndex} getchosenIngredCategory={getchosenIngredCategory}
expandedRow={expandedRow} kategoriHandler={kategoriHandler} getKategori={getKategori}
activeButton={activeButton} saveNameHandler={saveNameHandler} ingredNameHandler={ingredNameHandler}
chooseingredientCategoryFieldset={chooseingredcategoryFieldset}
ingredcategoryFirstOption1={ingredcategoryFirstOption1}
getIngredName={getIngredName}/>:''}

{activeButton===11?<IngredientlistSimple ingred={ingred} filteredIngredients={filteredIngredients} 
chosenIngredid={chosenIngredid} handleRowClickIndex={handleRowClickIndex} getchosenIngredCategory={getchosenIngredCategory}
expandedRow={expandedRow} kategoriHandler={kategoriHandler} getKategori={getKategori}
activeButton={activeButton}
chooseingredientCategoryFieldset={chooseingredcategoryFieldset}
ingredcategoryFirstOption1={ingredcategoryFirstOption1}
getIngredName={getIngredName}/>:''}

</div>



<div className="xeareaC">




  {/* {activeButton===8&&chosenIngredid>0?<InkopsStatistik ingred={ingred} getLevRowData={getLevRowData}
  getIngredName={getIngredName}/>:''} */}
  {activeButton===8&&getShowDeleteIngredient===true?<ProductRecepyConnections ingred={ingred} getRecepyConnection={getRecepyConnection} 
  getProductConnection={getProductConnection} getIngredName={getIngredName} DeleteIngredientHandler={DeleteIngredientHandler}
  nutrientHeaderHas={nutrientHeaderHas} nutrientHeaderReceptKopp={nutrientHeaderReceptKopp} nutrientHeaderAnd={nutrientHeaderAnd}
  nutrientHeaderProdkopp={nutrientHeaderProdkopp} nutrientHeaderSmall={nutrientHeaderSmall} nutrientDeleteInfoButton={nutrientDeleteInfoButton}
  ingredcanberemovedText={ingredcanberemovedText}
  /> :''}

  {/* {activeButton===8&&chosenIngredid>0?
  <div style={ingred.nutrientDataArea}>
  <ViewNutrientData1 ingred={ingred} getnutritionData={getnutritionData} 
  getReceivedDataStatus={getReceivedDataStatus} sortNutrientDataHandler={sortNutrientDataHandler}
  sortNutrientNameHandler={sortNutrientNameHandler}
  />
  </div>:''} */}

  {activeButton===4&&chosenIngredid>0?<IngredientToRecepyConn 
  ingred={ingred} getRecepyConnection={getRecepyConnection} getRecepies={getRecepies}
  getIngredData={getIngredData} changeIngredientOnRecepyHandler={changeIngredientOnRecepyHandler}
  getChangeIngredObj={getChangeIngredObj} getIngredName={getIngredName}
  newIngredientInRecepyHandler={newIngredientInRecepyHandler} 
  getNewIngredientInRecepy={getNewIngredientInRecepy} ReplaceIngredient={ReplaceIngredient}
  recepyConnectionHeader={recepyConnectionHeader}
  recepySelectHeaderLegend={recepySelectHeaderLegend}
  recepySelectHeaderLegend2={recepySelectHeaderLegend2}
  recepySelectedStatementLegend={recepySelectedStatementLegend}
 recepyConnectionSaveButtonText={recepyConnectionSaveButtonText}
 ingredSelect={ingredSelect}
  
  
  />:''}

  {activeButton===5&&chosenIngredid>0?<IngredientToProducts
  ingred={ingred} getProductConnection={getProductConnection} 
  getIngredData={getIngredData}  getIngredName={getIngredName} 
  getProducts={getProducts} getChangeProductObj={getChangeProductObj}
  ReplaceIngredientOnProductHandlerSave={ReplaceIngredientOnProductHandlerSave}
  changeIngredientOnProductHandler={changeIngredientOnProductHandler}
  newIngredientInProductHandler={newIngredientInProductHandler}
  getNewIngredientInProduct={getNewIngredientInProduct}
  removeFromListHandler={removeFromListHandler} 
  produktkoppHeaderTextLegend2={produktkoppHeaderTextLegend2}
  produktkoppHeaderLegendText={produktkoppHeaderLegendText}
  produktkoppInputHeaderTextLegend={produktkoppInputHeaderTextLegend}
  produktkoppMessageTextLegend={produktkoppMessageTextLegend}
  produktkoppSaveButtonText={produktkoppSaveButtonText}
  produktkoppInputHeaderTextLegend2={produktkoppInputHeaderTextLegend2}
  ingredSelect={ingredSelect}

  
  
  
  
  />:''}

  {activeButton===6&&chosenIngredid>0?<Pris ingred={ingred} getIngredName={getIngredName} getIngredid={getIngredid}
  getIngredData={getIngredData} getLatestPrice={getLatestPrice} getShowPris={getShowPris}
  inkopsprisLatestPriceLegend={inkopsprisLatestPriceLegend}
  inkopsprisListHeaderLegend={inkopsprisListHeaderLegend}

  
  />:''}
  {activeButton===6&&chosenIngredid>0?<InkopsStatistikNew 
  ingred={ingred} getLevRowData={getLevRowData} getIngredName={getIngredName}
  inkopsprisListHeaderLegend={inkopsprisListHeaderLegend}
  inkopPriceHeaderBenaemn={inkopPriceHeaderBenaemn}
  inkopPriceHeaderPrice={inkopPriceHeaderPrice}
  
  
  /> :''}


{activeButton===7&&chosenIngredid>0?

    <fieldset className="xeareaChartFieldset"><legend className="xeareaChartLegend">{prisdiagramHeaderLegend}: {getIngredName}</legend></fieldset>:''}

{activeButton===7&&getActiveButtonChart>0&&chosenIngredid>0?
<ChartChoice getActiveButtonChart={getActiveButtonChart} chartHandler={chartHandler}
prisdiagramChoseChartLegend={prisdiagramChoseChartLegend} />
:''}

  {/* {activeButton===7&&getActiveButtonChart===1&&chosenIngredid>0&&mobileIsUsed===false&&ipadIsUsed===false?<GetIngredAreaChart getChartData={getChartData}
  />:''}

{activeButton===7&&getActiveButtonChart===2&&chosenIngredid>0&&mobileIsUsed===false&&ipadIsUsed===false?<GetIngredLineChart getChartData={getChartData}
  />:''}

{activeButton===7&&getActiveButtonChart===3&&chosenIngredid>0&&mobileIsUsed===false&&ipadIsUsed===false?<GetIngredBarChart getChartData={getChartData}
  />:''} */}

{/* // mobile charts */}



{activeButton===7&&getActiveButtonChart===1&&chosenIngredid>0?<MobileGetIngredAreaChart getChartData={getChartData}
  />:''}

{activeButton===7&&getActiveButtonChart===2&&chosenIngredid>0?<MobileGetIngredLineChart getChartData={getChartData}
  />:''}

{activeButton===7&&getActiveButtonChart===3&&chosenIngredid>0?<MobileGetIngredBarChart  getChartData={getChartData}
  />:''}


  {/* // ipad charts
  {activeButton===7&&getActiveButtonChart===1&&chosenIngredid>0&&mobileIsUsed===false&&ipadIsUsed===true?<IpadGetIngredAreaChart ingred={ingred} getChartData={getChartData}
  />:''}

{activeButton===7&&getActiveButtonChart===3&&chosenIngredid>0&&mobileIsUsed===false&&ipadIsUsed===true?<IpadGetIngredBarChart ingred={ingred} getChartData={getChartData}
  />:''}

{activeButton===7&&getActiveButtonChart===2&&chosenIngredid>0&&mobileIsUsed===false&&ipadIsUsed===true?<IpadGetIngredLineChart ingred={ingred} getChartData={getChartData}
  />:''} */}





{activeButton===9&&chosenIngredid>0?

<EditNutrientData ingred={ingred} getnutritionData={getnutritionData}
getIngredName={getIngredName} ChangeNutrientAmountHandler={ChangeNutrientAmountHandler}
getNutritionSaveStatus={getNutritionSaveStatus} SaveNutritionValues={SaveNutritionValues}
GetFoodInfoHandler={GetFoodInfoHandler} sortNutrientNameHandler={sortNutrientNameHandler}
sortNutrientDataHandler={sortNutrientDataHandler}
nutrientHeaderLegend={nutrientHeaderLegend}
nutrientFetchDataButtonText={nutrientFetchDataButtonText}
nutrientSaveDataButtonText={nutrientSaveDataButtonText}
nutrientListHeaderTextSubject={nutrientListHeaderTextSubject}
nutrientListHeaderTextAmount={nutrientListHeaderTextAmount}
nutrientListHeaderTextWeight={nutrientListHeaderTextWeight}
/>
:''}

{activeButton===10&&chosenIngredid>0?<ConvertFromVolume ingred={ingred} getIngredName={getIngredName} getIngredid={getIngredid}
getIngredData={getIngredData}  convertToGramHandler={ConvertToGramHandler} numVolumnsHandler={numVolumnsHandler}
 choseVolumeInstrumentHandler={choseVolumeInstrumentHandler} 
 getVolymeInstrument={getVolymeInstrument}
konvertHeaderTextLegend={konvertHeaderTextLegend}
konvertHeaderTextLegend2={konvertHeaderTextLegend2}
konvertSaveButtonText={konvertSaveButtonText}
measureTableSpoon={measureTableSpoon}
measureTeaSpoon={measureTeaSpoon}
measureDeciliter={measureDeciliter}
measureMilliliter={measureMilliliter}
measurePinch={measurePinch}
measureSelect={measureSelect}

 />:''}

{getConvertedValue>0&&activeButton===10&&chosenIngredid>0?
<ViewConvertedValue ingred={ingred} getConvertedValue={getConvertedValue} getIngredName={getIngredName} 
getIngredid={getIngredid}

konvertGramText={konvertGramText}
/> :''}
{getConvertedValue>0&&activeButton===10&&chosenIngredid>0?<ConvertDisclaimer ingred={ingred}
getCountry={getCountry} konvertDisclaimerText={konvertDisclaimerText}
 mobileIsUsed={mobileIsUsed} 
               />:''}

{activeButton===11&&chosenIngredid>0?<ViewIngredInformation ingred={ingred} 
getIngredName={getIngredName} 
GetAIIngredientInformation={GetAIIngredientInformation} getIngredInformation={getIngredInformation}
getcheckval={getcheckval}  checkHandler={checkvalHandler}
ingredInfoHeaderTextlegend={ingredInfoHeaderTextlegend}
ingredInfoGetInfoButtonText={ingredInfoGetInfoButtonText}
ingredInfoGetNewInfoText={ingredInfoGetNewInfoText}
/> :''}

</div>

<div className="xeingredSpinnerContainer">
        <div className="xeingredSpinner">
          <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="1" width="60" visible={getSpinnerLoading}/>
        </div>
        </div>

   

  {getShowAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}/>:''}


</div>
</>
  )
}


function Ingredmenubuttons(props){
  let {menuchoiceHandler,activeButton,ingred,ingredbutton,ingredcreatebutton,receptkoppbutton,
    produktkoppbutton,inkopbutton, prisdiabutton,nutrientbutton,konvertbutton,getCountry, ingredinfobutton
  } = props
  
   return(
 <div className="xeingredmenubuttonsTop">
   <div className="xeingredmenubuttonsData">
   
  
   <button className={activeButton === 8?"xemenubuttonStyle":"xemenuactivebuttonStyle"} tabIndex={1} onClick={()=>menuchoiceHandler(8)}>{ingredbutton}</button>
   
   <button className={activeButton === 1?"xemenubuttonStyle":"xemenuactivebuttonStyle"} tabIndex={1} onClick={()=>menuchoiceHandler(1)}>{ingredcreatebutton}</button>
  
   <button className={activeButton === 4 ?"xemenubuttonStyle":"xemenuactivebuttonStyle"} tabIndex={1} onClick={()=>menuchoiceHandler(4)}>{receptkoppbutton}</button>
  
   <button className={activeButton === 5 ?"xemenubuttonStyle":"xemenuactivebuttonStyle"} tabIndex={1} onClick={()=>menuchoiceHandler(5)}>{produktkoppbutton}</button>
   
   <button className={activeButton === 6 ?"xemenubuttonStyle":"xemenuactivebuttonStyle"} tabIndex={1} onClick={()=>menuchoiceHandler(6)}>{inkopbutton}</button>
  
   <button className={activeButton === 7 ?"xemenubuttonStyle":"xemenuactivebuttonStyle"} tabIndex={1} onClick={()=>menuchoiceHandler(7)}>{prisdiabutton}</button>
  
   <button className={activeButton === 9 ?"xemenubuttonStyle":"xemenuactivebuttonStyle"} tabIndex={1} onClick={()=>menuchoiceHandler(9)}>{nutrientbutton}</button>
 
   <button className={activeButton === 10 ?"xemenubuttonStyle":"xemenuactivebuttonStyle"} tabIndex={1} onClick={()=>menuchoiceHandler(10)}>{konvertbutton}</button>
 
   <button className={activeButton === 11 ?"xemenubuttonStyle":"xemenuactivebuttonStyle"} tabIndex={1} onClick={()=>menuchoiceHandler(11)}>{ingredinfobutton}</button>
   </div></div>
   )
 }

 function DeleteIngredient(props){
  let {ingred,kategoriHandler,getKategori} = props

  return(
    <div style={ingred.ingredmenubuttonsTop}>
      
      <KategoriSelect ingred={ingred} kategoriHandler={kategoriHandler} getKategori={getKategori}
      />
    </div>
  )
 }

 function KategoriSelect(props){
  let {ingred,kategoriHandler,getKategori,getchosenIngredCategory,chooseingredcategoryFieldset,ingredcategoryFirstOption1,getCountry
  } = props

  return(
    <div className="xeareaKategoriSelect">
    <fieldset className="xekatfieldset"><legend className="xekatlegend">{chooseingredcategoryFieldset}</legend>
  <select className="xekategoriselect"  value={getchosenIngredCategory} onChange={(e)=>kategoriHandler(e.target.value)}>
<option value='0' hidden>&nbsp;{ingredcategoryFirstOption1}&nbsp;!&nbsp;</option>
{getKategori.map((data,index)=>
<option className="option" key={index} value={data.kategoriid}>&nbsp;{data.benaemn}</option>)}
</select>
</fieldset>

  </div>
  )
 }


function CreateIngredient(props){
  let {ingred,kategoriHandler,getKategori,ingredNameHandler,getIngredName,saveHandler,getIngredid,getchosenIngredCategory,
createingredientNameLegend,createingredientSaveButton,chooseingredcategoryFieldset,ingredcategoryFirstOption1
} = props

  return(
    
    <div className="xeingredientAreaData">
      <fieldset className="ingredientFieldset"><legend className="ingredientLegend">{createingredientNameLegend}</legend>
      <input type='text' className="ingredientInput" onChange={(e)=>ingredNameHandler(e.target.value)}  value={getIngredName}/>
      </fieldset>

      <fieldset className="kategoriFieldset"><legend className="ingredientLegend1">{chooseingredcategoryFieldset}</legend>
    <select className="ingredselect" onChange={(e)=>kategoriHandler(e.target.value)}>
<option value='0' hidden>&nbsp;&nbsp;{ingredcategoryFirstOption1}&nbsp;!&nbsp;</option>
{getKategori.map((data,index)=><option className="option" key={index} value={data.kategoriid}>&nbsp;{data.benaemn}</option>)}
</select>
</fieldset>
<div className="xe1saveButtonArea">
<button className={getIngredName.length>0&&getchosenIngredCategory>0?"xesaveButton":"xesaveButton1"} 
onClick={()=>saveHandler()} disabled={!getIngredName||!getchosenIngredCategory}>{createingredientSaveButton}</button>
</div>
    
</div>


  )
}

function CreateJAPIngredient(props){
  let {ingred,kategoriHandler,getKategori,ingredNameHandler,getIngredName,saveHandler,getIngredid,getchosenIngredCategory,
createingredientNameLegend,createingredientSaveButton,chooseingredcategoryFieldset,ingredcategoryFirstOption1,
furiganaNameHandler,kanjiNameHandler,  getKanjiIngredName,getFuriganaIngredName,saveJAPIngredientHandler
} = props

  return(
    
    <div className="xeingredientAreaData">
      <fieldset className="xeingredientFieldsetJAP"><legend className="xeingredientLegendJAP">{createingredientNameLegend}</legend>
      <input type='text' className="kanjiinput" onChange={(e)=>kanjiNameHandler(e.target.value)}  value={getKanjiIngredName}/>
      <input type='text' className="furiganainput" onChange={(e)=>furiganaNameHandler(e.target.value)}
        value={getFuriganaIngredName} placeholder='ふりがな'/>
      
      </fieldset>
      <fieldset className="kategoriFieldsetJAP"><legend className="xeingredientLegendJAP">{chooseingredcategoryFieldset}</legend>
    <select className="ingredselectJAP" onChange={(e)=>kategoriHandler(e.target.value)}>
<option value='0' hidden>&nbsp;&nbsp;{ingredcategoryFirstOption1}&nbsp;!&nbsp;</option>
{getKategori.map((data,index)=><option className="option" key={index} value={data.kategoriid}>&nbsp;{data.benaemn}</option>)}
</select>
</fieldset>
<div className="xe1saveButtonAreaJAP">
<button className={getKanjiIngredName.length>0&&getFuriganaIngredName.length>0&&getchosenIngredCategory>0?"saveButtonJAP":"saveButtonJAP1"} 
onClick={()=>saveJAPIngredientHandler()} disabled={!getKanjiIngredName||!getFuriganaIngredName||!getchosenIngredCategory}>{createingredientSaveButton}</button>
</div>
    
</div>


  )
}

function NutrientInfo(props){
  let {ingred,kategoriHandler,getKategori,nutrientInfoHandler,
  getnutritionData,getReceivedDataStatus,getIngredid,getIngredName,
getShowNutrientInfo} = props

  return(
 <>
<div style={getIngredid>0&&getReceivedDataStatus===false?ingred.nutrientInfoButton:ingred.nutrientInfoButton1} disabled={!getIngredid} onClick={()=>nutrientInfoHandler()}>Hämta näringsvärden</div>

<div style={ingred.nutrientInfoData}>
  {getReceivedDataStatus===true&&getShowNutrientInfo===true?`Näringsämnen för ${getIngredName} är sparade`:''}

<div style={ingred.foodAreaHeader}>

      {getReceivedDataStatus===true&&getShowNutrientInfo===true?
      <><button style={ingred.headerNutrient}>Näringsämne per 100gr</button>
      <button style={ingred.headerQuantity}>Mängd</button>
      <button style={ingred.headerUnit}>Värde</button></>:''}
      </div>
{getShowNutrientInfo===true?<ViewNutrientData ingred={ingred} kategoriHandler={kategoriHandler} getKategori={getKategori}
  getnutritionData={getnutritionData} getReceivedDataStatus={getReceivedDataStatus} />:''}



</div></>

  )

}

function ViewNutrientData(props){
  let {ingred,getnutritionData,
  getReceivedDataStatus} = props

  return(
    <div>
            {getReceivedDataStatus===true?<div style={ingred.foodareaContainer}>
        
  
  
         <div style={ingred.foodArea}>
          {getnutritionData.map((data,index)=>(
            <div key={index+100}>
            <button style={index%2===0?ingred.nutrientValue:ingred.nutrientValue1}>{data.nutrient}</button>
            <button style={index%2===0?ingred.nutrientQuantity:ingred.nutrientQuantity1}>{data.quantity}</button>
            <button style={index%2===0?ingred.nutrientUnit:ingred.nutrientUnit1}>{data.unit}</button>
            </div>
            
          ))}
          
          
       </div>
        </div>:''}
    </div>
      )
  
}

function ViewNutrientData1(props){
  let {ingred,kategoriHandler,getnutritionData,nutrientInfoHandler,getNutrientInfo,
  getReceivedDataStatus,sortNutrientDataHandler,sortNutrientNameHandler} = props
  return(
    <div>
      
      <button style={ingred.headerNutrient1} onClick={()=>sortNutrientNameHandler()}>Näringsämnen per 100gr</button>
      <button style={ingred.headerQuantity1} onClick={()=>sortNutrientDataHandler()}>Mängd</button>
      <button style={ingred.headerUnit1}>Vikt</button>
      
             {getReceivedDataStatus===true?<div style={ingred.foodareaContainer1}>
         <div style={ingred.foodArea1}>
          {getnutritionData.length>0?getnutritionData.map((data,index)=>(
            <div key={index}>
            <button style={index%2===0?ingred.nutrientValue2:ingred.nutrientValue3}>{data.nutrient}</button>
            <button style={index%2===0?ingred.nutrientQuantity2:ingred.nutrientQuantity3}>{String(data.quantity).substring(0,5)}</button>
            <button style={index%2===0?ingred.nutrientUnit2:ingred.nutrientUnit3}>{data.unit}</button>
            </div>
            
          )):<div style={ingred.nutrientMessage}>Inga näringsvärden finns sparande </div>}
          
          
      
       </div></div>:''}
    </div>
      )
  
}

function Ingredientlist(props){
  let {ingred,handleRowClickIndex,expandedRow,
    kategoriHandler,getKategori,activeButton,saveNameHandler,
    ingredNameHandler,getIngredName,getIngredData,getchosenIngredCategory,getIngredNameUpdated,
    chooseingredcategoryFieldset,ingredcategoryFirstOption1,changeingredNameFieldset,changeingredNameSaveButton,getCountry,
    ingredKanjiNameHandler,ingredFuriganaNameHandler,saveJAPNameHandler,getUpdateKanjiName,
    getUpdateFuriganaName,getIngredNameFuri
  }=props

return(

  <div className="xeingredientListTop">
  {activeButton===8||activeButton===4||activeButton===5||activeButton===6?<KategoriSelect ingred={ingred} kategoriHandler={kategoriHandler} getCountry={getCountry}
  getchosenIngredCategory={getchosenIngredCategory} chooseingredcategoryFieldset={chooseingredcategoryFieldset} 
  ingredcategoryFirstOption1={ingredcategoryFirstOption1}
  getKategori={getKategori}/>:''}
    <div className="xeingredientListData2">
    {getIngredData.map((data,index)=>(
      <div key={data.ingredid}>
      <button id={data.ingredid}  className={index%2===0?"xesimpleButton":"xesimpleButton1"} onClick={()=>handleRowClickIndex(data.ingredid,index)}>{data.benaemn}</button>
      
 
      {expandedRow === index && (<div className={getCountry!=='ja'?'xeingredNew1':'xeingredNew2'} key={data.ingredid}>
      
      {getCountry!=='ja'?
        <div className="xeeditIngredientBox1">
          <fieldset className="xxeeditIngredientFieldset1">
            <legend className="xeeditIngredientLegend1">{changeingredNameFieldset}</legend>
          <input type='text' className="xeeditIngredientInput" value={getIngredName} 
          onChange={(e)=>ingredNameHandler(e.target.value)}/>
          <div className="xebuttonplacer">
          <button className={getIngredNameUpdated===true?"editIngredientButton1":"editIngredientButton"} 
          onClick={()=>saveNameHandler()}
          disabled={getIngredNameUpdated===false}
          >{changeingredNameSaveButton}</button></div>
          </fieldset>
        </div>
        :
        <div className="xeeditIngredientBox1JAP">
        <fieldset className="xeeditIngredientFieldset1JAP">
          <legend className="xeeditIngredientLegend1JAP">{changeingredNameFieldset}</legend>
        <input type='text' className="xeeditIngredientKanjiInputJAP" value={getIngredName} 
        onChange={(e)=>ingredKanjiNameHandler(e.target.value)}/>
        <input type='text' className="xeeditIngredientFuriganaInputJAP" value={getIngredNameFuri} 
         onChange={(e)=>ingredFuriganaNameHandler(e.target.value)} placeholder='ふりがな'/>
        
        <button className={getIngredName.length>0&&getIngredNameFuri.length>0?"xeeditIngredientButton1JAP":"xeeditIngredientButtonJAP"} 
        onClick={()=>saveJAPNameHandler()}
        disabled={getIngredName.length===0||getIngredNameFuri.length===0}
        >{changeingredNameSaveButton}</button>
        </fieldset>
      </div>
      }
    
   
</div>
  )}
    </div>
    

    ))}
    </div>
  </div>

)
}

function IngredientlistPrice(props){
  let {ingred,filteredIngredients,handleRowClickIndex,expandedRow,
    kategoriHandler,getKategori,activeButton,saveNameHandler,
    updatePriceHandler,SaveNewPriceHandler,getIngredName,
    chooseingredcategoryFieldset,ingredcategoryFirstOption1,inkopsprisChangePriceLegend,
    inkopChangePriceSaveButton,getPriceIsUpdated,startUpdatingPrice,stopUpdatingPrice
  
  
  
  }=props

return(

  <div className="xeingredientListTop">
  {activeButton===8||activeButton===4||activeButton===5||activeButton===6?<KategoriSelect ingred={ingred} kategoriHandler={kategoriHandler} getKategori={getKategori}
  chooseingredcategoryFieldset={chooseingredcategoryFieldset}
  ingredcategoryFirstOption1={ingredcategoryFirstOption1}
  />:''}
    <div className="xeingredientListData3">
    {filteredIngredients.map((data,index)=>(
      <div key={data.levindex}>
      <button className={index%2===0?"xesimpleButton":"xesimpleButton1"} key={index} onClick={()=>handleRowClickIndex(data.ingredid,index)}>{data.benaemn}</button>
       
 
      {expandedRow === index && (<div className="ingredNew">
      
        <div className="xeeditIngredientBox">
          <fieldset className="xeeditIngredientFieldset2">
            <legend className="xeeditIngredientLegend1">{inkopsprisChangePriceLegend} {getIngredName}</legend>
            <div className="editIngredientPriceBox">
              <div className="xeeditIngredientPrice">
                <AiOutlinePlusCircle className="xeeditIngredientPricePlusIcon" 
                onClick={()=>updatePriceHandler(1)}
                onMouseDown={()=>startUpdatingPrice(1)}
                onMouseUp={stopUpdatingPrice}
                onMouseLeave={stopUpdatingPrice}
                onTouchStart={()=>startUpdatingPrice(1)}
                onTouchEnd={stopUpdatingPrice}
                onTouchCancel={stopUpdatingPrice}
             

                
                />
                <AiOutlineMinusCircle className="xeeditIngredientPriceMinusIcon" 
                onClick={()=>updatePriceHandler(2)}
                onMouseDown={()=>startUpdatingPrice(2)}
                onMouseUp={stopUpdatingPrice}
                onMouseLeave={stopUpdatingPrice}
                onTouchStart={()=>startUpdatingPrice(2)}
                onTouchEnd={stopUpdatingPrice}
                onTouchCancel={stopUpdatingPrice}
                
                />
                <button className={getPriceIsUpdated===false?"xeeditPriceButton":"xeeditPriceButton1"} 
                disabled={getPriceIsUpdated===false}
                onClick={()=>SaveNewPriceHandler()}>{inkopChangePriceSaveButton}</button>
            

              </div>
              
            </div>
       
          </fieldset>
        </div>
    
   
</div>
  )}
    </div>
    

    ))}
    </div>
  </div>

)
}

function IngredientlistSimple(props){
  let {filteredIngredients,handleRowClickIndex,
    kategoriHandler,getKategori,getchosenIngredCategory,
     chooseingredientCategoryFieldset,ingredcategoryFirstOption1
     
    }=props

return(


    <><div className="areaKategoriSelectSimple">
    <fieldset className="xekatfieldset"><legend className="xekatlegend">{chooseingredientCategoryFieldset}</legend>
      <select className="xekategoriselect" value={getchosenIngredCategory} onChange={(e) => kategoriHandler(e.target.value)}>
        <option value='0' hidden>&nbsp;{ingredcategoryFirstOption1}&nbsp;!&nbsp;</option>
        {getKategori.map((data, index) => <option className="option" key={index} value={data.kategoriid}>&nbsp;{data.benaemn}</option>)}
      </select>
    </fieldset>

  </div>
  <div className="xeingredientListTop">
      <div className="axeingredientListData">
        {filteredIngredients.map((data, index) => (

          <button className={index%2===0?"xesimpleButton":"xesimpleButton1"} key={data.ingredid} onClick={() => handleRowClickIndex(data.ingredid, index)}>{data.benaemn}</button>

        ))}
      </div>

    </div></>

)
}

function IngredientlistNutrient(props){
  let {ingred,filteredIngredients,
    kategoriHandler,getKategori,activeButton,getNutritionHandler,
    chooseingredcategoryFieldset,ingredcategoryFirstOption1
    
    
    }=props

return(

  <div className="axbingredientListTop">
  {activeButton===9?<KategoriSelect ingred={ingred} kategoriHandler={kategoriHandler} getKategori={getKategori}
  chooseingredcategoryFieldset={chooseingredcategoryFieldset}
ingredcategoryFirstOption1={ingredcategoryFirstOption1}
  />:''}
    <div className="axbingredientListData2">
    {filteredIngredients.map((data,index)=>(
      <div key={data.ingredid}>
        <button className={index%2===0?"xesimpleButton":"xesimpleButton1"} key={index} onClick={() => getNutritionHandler(data.ingredid)}>{data.benaemn}</button>
     
      </div>
    ))}
    </div>

  </div>

)
}



function InkopsStatistik(props){
  let {ingred,getLevRowData,getIngredName}=props

  return(
    <div style={ingred.inkopsStatistikTop}>
      <fieldset style={ingred.inkopsStatistikFieldset}>
        <legend style={ingred.inkopsStatistikLegend}>Inköps data och näringsämnen för {getIngredName} </legend>
        
        <button style={ingred.inkopHeaderDatum}>Inköpsdatum</button>
        <button style={ingred.inkopHeaderPris}>Pris</button>
      </fieldset>
      <div style={ingred.inkopsStatistikData}>
        {getLevRowData.map((data,index)=>(
          
          <div key={index}>
        <button style={index%2===0?ingred.dataDatum:ingred.dataDatum1}>{String(data.datum).substring(0,10)||0}</button>
        <button style={index%2===0?ingred.dataPris:ingred.dataPris1}>{data.pris}</button> 
        </div>
        ))}
      </div>
    </div>
  )
}


function InkopsStatistikNew(props){
  let {getLevRowData,getIngredName,inkopsprisListHeaderLegend,
    inkopPriceHeaderBenaemn,inkopPriceHeaderPrice
  }=props

  return(
    <div className="xeinkopsStatistikTop">
      <fieldset className="xeinkopsStatistikFieldset">
        <legend className="xeinkopsStatistikLegend">{inkopsprisListHeaderLegend} {getIngredName} </legend>
        </fieldset>
        <button className="xeinkopHeaderDatum">{inkopPriceHeaderBenaemn}</button>
        <button className="xeinkopHeaderPris">{inkopPriceHeaderPrice}</button>
     
      <div className="xeinkopsStatistikData">
        {getLevRowData.map((data,index)=>(
          
          <div key={index}>
  
        <button className={index%2===0?"xedataDatum":"xedataDatum1"}>{String(data.datum).substring(0,10)||0}</button>
        <button className={index%2===0?"xedataPris":"xedataPris1"}>{data.pris}</button> 
        </div>
        ))}
      </div>
    </div>
  )
}

function IngredientToRecepyConn(props){
let {ingred,getRecepyConnection,getRecepies,getIngredData,changeIngredientOnRecepyHandler,
  getChangeIngredObj,getIngredName,newIngredientInRecepyHandler,getNewIngredientInRecepy,
  ReplaceIngredient,recepyConnectionHeader,recepySelectHeaderLegend,recepySelectHeaderLegend2,
  recepySelectedStatementLegend,recepyConnectionSaveButtonText,ingredSelect



}=props

let newIngredientBenaemn=getIngredData.find((data)=>data.ingredid==getNewIngredientInRecepy)
let benaemn=newIngredientBenaemn?newIngredientBenaemn.benaemn:''
let numRecepies=getChangeIngredObj.length||0

return(
  <>
  <div className="xerecepyConnTop">
    <fieldset className="xerecepyConnFieldset"><legend className="xerecepyConnLegend">&nbsp;{recepyConnectionHeader} {getIngredName}</legend>
       <div className="xerecepyConnData">
       {getRecepies.map((data,index)=>(
        <div key={index}>
      <button className={index%2===0?"xe1recepyConnButton":"xe1recepyConnButton1"} onClick={()=>changeIngredientOnRecepyHandler(data.receptid)}>{data.benaemn}</button>
      </div>
    
        ))}
        </div>
        </fieldset>

  </div>
       <div className="axeingredientListTop">
       <fieldset className="axeingredientListFieldset">
         <legend className="axeingredientListLegend">{recepySelectHeaderLegend} &nbsp; {getIngredName} {recepySelectHeaderLegend2} {benaemn} </legend>
         <select className="axeingredientListSelect" onChange={(e)=>newIngredientInRecepyHandler(e.target.value)}>
            <option value='0' hidden>&nbsp;&nbsp;{ingredSelect}&nbsp;</option>
            {getIngredData.map((data,index)=>(
              <option key={data.ingredid} value={data.ingredid}>&nbsp;&nbsp;{data.benaemn}</option>
            ))}
          </select>
         
         </fieldset>

     </div>
     <fieldset className="axechangeIngredientFieldset">
<legend className="axechangeIngredientLegend">&nbsp;&nbsp;{numRecepies} {recepySelectedStatementLegend}</legend>
     <div className="axechangeIngredientArea">
      {getChangeIngredObj.map((data,index)=>(
        <div key={index}>
        <button className={index%2===0?"axechangeIngredientButton":"axechangeIngredientButton11"}>{data.receptnamn}</button>
        </div>
      ))}
     </div>
      </fieldset>
     
      
     <div className="axechangeIngredientArea1">
      <button className={numRecepies&&getNewIngredientInRecepy>0?"axechangeIngredientButton1":"axechangeIngredientButtonDisabled"} 
       onClick={()=>ReplaceIngredient()}
       disabled={numRecepies<1&&getNewIngredientInRecepy<1}
       >{recepyConnectionSaveButtonText}</button>

     </div>
     </>


)

}

function IngredientToProducts(props){
  let {getIngredName,getProducts,getIngredData,getChangeProductObj,ReplaceIngredientOnProductHandlerSave,
  changeIngredientOnProductHandler,newIngredientInProductHandler,getProductConnection,
getNewIngredientInProduct,removeFromListHandler,
  produktkoppHeaderTextLegend2,produktkoppHeaderLegendText,
  produktkoppInputHeaderTextLegend,produktkoppMessageTextLegend,produktkoppSaveButtonText,
  produktkoppInputHeaderTextLegend2,ingredSelect
}=props


  let newIngredientBenaemn=getIngredData.find((data)=>data.ingredid==getNewIngredientInProduct)

let benaemn=newIngredientBenaemn?newIngredientBenaemn.benaemn:''
let numProducts=getChangeProductObj.length||0
let numProductsConnected=getProductConnection[0].antprodukt||0
  return(
    <>
    <div className="xerecepyConnTop">
    <fieldset className="xerecepyConnFieldset"><legend className="xerecepyConnLegend">{numProductsConnected>0?produktkoppHeaderTextLegend2 :produktkoppHeaderLegendText } {getIngredName}</legend>
       <div className="xerecepyConnData">
       {getProducts.map((data,index)=>(
        <div key={index}>
      <button className={index%2===0?"xe1recepyConnButton":"xe1recepyConnButton1"} onClick={()=>changeIngredientOnProductHandler(data.id)}>{data.benaemn}</button>
      </div>
    
        ))}
        </div>
        </fieldset>

  </div>
       <div className="xeingredientListTop">
       <fieldset className="axeingredientListFieldset">
         <legend className="axeingredientListLegend">{produktkoppInputHeaderTextLegend} {getIngredName} {produktkoppInputHeaderTextLegend2} {benaemn} </legend>
         <select className="axeingredientListSelect" onChange={(e)=>newIngredientInProductHandler(e.target.value)}>
            <option value='0' hidden>&nbsp;&nbsp;{ingredSelect}!&nbsp;</option>
            {getIngredData.map((data,index)=>(
              <option key={index} value={data.ingredid}>&nbsp;&nbsp;{data.benaemn}</option>
            ))}
          </select>
         
         </fieldset>

     </div>
     <fieldset className="axechangeIngredientFieldset">
<legend className="axechangeIngredientLegend">&nbsp;&nbsp;{numProducts} {produktkoppMessageTextLegend}</legend>
     <div className="axechangeIngredientArea">
      {getChangeProductObj.map((data,index)=>(
        <div key={index}>
        <button className="axechangeIngredientButton" onClick={()=>removeFromListHandler(data.produktid)}>{data.benaemn}</button>
        </div>
      ))}
     </div>
      </fieldset>
     

     <div className="axechangeIngredientArea1">
      <button className={numProducts&&getNewIngredientInProduct>0?"axechangeIngredientButton1":"axechangeIngredientButtonDisabled"} 
       onClick={()=>ReplaceIngredientOnProductHandlerSave()} disabled={numProducts<1&&getNewIngredientInProduct<1}>{produktkoppSaveButtonText}</button>

     </div>
     </>
  )
}

function Pris(props){
  let {ingred,getIngredData,getLatestPrice,getShowPris,
    inkopsprisLatestPriceLegend
  }=props



  return(
    <div className="xeprisAreaTop">
      <fieldset className="xeprisFieldset"><legend className="xeprisLegend">{inkopsprisLatestPriceLegend}</legend>
      <button className="xeprisButton">{getLatestPrice}</button>
      </fieldset>

    </div>
  )

}

function ChartChoice(props){
  let {getActiveButtonChart,chartHandler,
    prisdiagramChoseChartLegend
  
  }=props
  return(
  
      <div className="xeingredChartChoiceArea">
      
          <fieldset className="xeingredChartChoiceFieldset">
            <legend className="xeingredChartChoiceLegend">{prisdiagramChoseChartLegend}</legend>
            <button
              className={`ingred-katprodchoice-chart-button ${getActiveButtonChart === 1 ? 'active' : ''}`}
              onClick={() => chartHandler(1)}
            >
              Area
            </button>
            <button
              className={`ingred-katprodchoice-chart-button ${getActiveButtonChart === 2 ? 'active' : ''}`}
              onClick={() => chartHandler(2)}
            >
              Line
            </button>
            <button
              className={`ingred-katprodchoice-chart-button ${getActiveButtonChart === 3 ? 'active' : ''}`}
              onClick={() => chartHandler(3)}
            >
              Bar
            </button>
            </fieldset>
      </div>
  )
}
function ProductRecepyConnections(props){
  let{ingred,getRecepyConnection,getProductConnection,getIngredName,DeleteIngredientHandler,
    nutrientHeaderHas,nutrientHeaderReceptKopp,nutrientHeaderAnd,
  nutrientHeaderProdkopp,nutrientHeaderSmall,nutrientDeleteInfoButton,ingredcanberemovedText
  
  }=props

  let recepyConnection=getRecepyConnection[0].antrecept||0
  let productConnection=getProductConnection[0].antprodukt||0
  return(
    <>
    <fieldset className="axeconnRecepyFieldset">
      <legend className="axeconnRecepyLegend">{getIngredName} {nutrientHeaderHas} {recepyConnection} {nutrientHeaderReceptKopp} {nutrientHeaderAnd}  {productConnection} {nutrientHeaderProdkopp} </legend>
      </fieldset>
    


       <div className="axedeleteIngredientTop">
        <DeleteIngredientNew ingred={ingred} productConnection={productConnection}
         recepyConnection={recepyConnection} DeleteIngredientHandler={DeleteIngredientHandler}
         nutrientHeaderSmall={nutrientHeaderSmall} nutrientDeleteInfoButton={nutrientDeleteInfoButton}
         ingredcanberemovedText={ingredcanberemovedText}
         
         />
       </div>
      
      </>
  
  )
}

function DeleteIngredientNew(props){
  let {ingred,recepyConnection,productConnection,DeleteIngredientHandler,
     nutrientHeaderSmall,nutrientDeleteInfoButton,ingredcanberemovedText
  } = props

  return(
    <div className="axedeleteArea">
      <button className={recepyConnection>0||productConnection>0?"axxedeleteMessageButton5":"axxedeleteMessageButton6"}>
        {recepyConnection>0||productConnection>0?nutrientHeaderSmall:ingredcanberemovedText}</button>
      <button className={recepyConnection>0||productConnection>0?"axxeingredDeleteButton4":"axxeingredDeleteButton3"}
      onClick={()=>DeleteIngredientHandler()}
      disabled={recepyConnection>0||productConnection>0}
      >{nutrientDeleteInfoButton}</button>
      
    </div>
  )
 }

 function EditNutrientData(props){
  let {ingred,kategoriHandler,getnutritionData,nutrientInfoHandler,getNutrientInfo,
  getReceivedDataStatus,getIngredName,ChangeNutrientAmountHandler,
  getNutritionSaveStatus,SaveNutritionValues,GetFoodInfoHandler,sortNutrientDataHandler,
  sortNutrientNameHandler,nutrientHeaderLegend,
  nutrientFetchDataButtonText,
  nutrientSaveDataButtonText,
  nutrientListHeaderTextSubject,
  nutrientListHeaderTextAmount,
  nutrientListHeaderTextWeight,
} = props

  return(
    <><div>
      <div className="xenutrientNameArea">
      <div className="xenutrientName">{nutrientHeaderLegend} : {getIngredName}</div><br></br>
      </div>
      <div className="xenutrientButtonBlock">

      <button className="xefetchNutrientButton" onClick={()=>GetFoodInfoHandler()}>{nutrientFetchDataButtonText}</button>
     <br></br></div>
     <div className="xenutrientHeaderTop">
     <div className="xenutrientHeaderArea">
      <button className="xeheaderNutrient1" onClick={()=>sortNutrientNameHandler()}>{nutrientListHeaderTextSubject}</button>
      <button className="xeheaderQuantity1" onClick={()=>sortNutrientDataHandler()}>{nutrientListHeaderTextAmount}</button>
      <button className="xeheaderUnit1">{nutrientListHeaderTextWeight}</button>
    
      </div> </div>
      
            <div className="xedataTopStart">
           <div className="xedataTop">
          {getnutritionData.length>0?getnutritionData.map((data,index)=>(
            <div  className="xefoodrowsarea" key={index+100}>
            <button className={index%2===0?"xenutrientValue2":"xenutrientValue3"}>{data.nutrient}</button>

            <button className={index%2===0?"xenutrientQuantity2":"xenutrientQuantity3"}
             >{parseFloat(parseFloat(data.quantity).toFixed(3))}</button>

            <button className={index%2===0?"xenutrientUnit2":"xenutrientUnit3"}>{data.unit}</button>
        
            
            </div>
            
          )):'Inga näringsvärden finns sparande'}
          
      
    
    </div></div></div>
    </>
      )
  
}

function ConvertFromVolume(props){
 let {ingred,getIngredData,getIngredName,getIngredid,convertToVolumeHandler,numVolumnsHandler,
  getConvertToVolume,convertToGramHandler,getVolymeInstrument,
choseVolumeInstrumentHandler,
konvertHeaderTextLegend,
konvertHeaderTextLegend2,
konvertSaveButtonText,
measureTableSpoon,
measureTeaSpoon,
measureDeciliter,
measureMilliliter,
measurePinch,
measureSelect



}=props


 return(
  <div className="xeconvertFromVolumeTop">
  <fieldset className="xeconvertFromVolumeFieldset"><legend className="xeconvertFromVolumeLegend">{konvertHeaderTextLegend} {getIngredName} {konvertHeaderTextLegend2}</legend>

   
      <select className="xeconvertFromVolumeSelect1" onChange={(e)=>choseVolumeInstrumentHandler(e.target.value)}>
            <option value='0' hidden>&nbsp;{measureSelect}!&nbsp;</option>
            <option value='1'>&nbsp;{measureTableSpoon}&nbsp;</option>
            <option value='2'>&nbsp;{measureTeaSpoon}&nbsp;</option>
            <option value='3'>&nbsp;{measureDeciliter}&nbsp;</option>
            <option value='4'>&nbsp;{measureMilliliter}&nbsp;</option>
            <option value='5'>&nbsp;{measurePinch}&nbsp;</option>
          </select>


          <button className={getVolymeInstrument>0?"xeconvertFromVolumeButton":"xeconvertFromVolumeButton1"} 
          onClick={()=>convertToGramHandler()}
          disabled={!getVolymeInstrument}
          >{konvertSaveButtonText}</button>
         
          
  

  </fieldset>

  </div>
 )
}

function ViewConvertedValue(props){
  let {ingred,getConvertedValue,konvertGramText
  }=props

  return(
    <div className="xeconvertFromVolumeData">
      <div className="xeconvertFromVolumeDataText">{getConvertedValue}<div className="xegramText"> {konvertGramText}</div></div>
    </div>

  )

}

function ConvertDisclaimer(props){
  let {ingred,mobileIsUsed,getCountry}=props

  return(
    <div className="xeconvertFromVolumeDisclaimer">
      {mobileIsUsed===false&&getCountry==='se'?
      <div style={ingred.convertFromVolumeDisclaimerText}>OBS! Konvertering är endast ett ungefärligt värde
      som vi hämtar<br></br> efter sökning på internet, eller hos leverantör. Vi bedömmer att data<br></br>
      som oftast är användbar, men vid extra noggrant behov, ber vi er att<br></br> kontrollera fler källor.</div>
      :''}
      {mobileIsUsed===false&&getCountry==='en'?
          <div className="xeconvertFromVolumeDisclaimerText">ATTENTION! Conversion is only an approximate value
          that we retrieve<br></br>after searching on the internet, or at the supplier. We judge that data<br></br>
          usually is useful, but in case of extra thorough need,we ask you to<br></br> check more sources.</div>
      :''}
      {mobileIsUsed===false&&getCountry==='ja'?
          <div className="xeconvertFromVolumeDisclaimerText">ATTENTION! 単位の変換は、<br></br>インターネットや提供者から得た情報を参考にしており
          わたしたちは,<br></br>これらのデータを使用可能であると判断してはいますが、<br></br>もっと正確な数値が必要な場合には
          より多くの情報を収集してください。</div>
      :''}
      {mobileIsUsed===true&&getCountry==='se'?
          <div className="xeconvertFromVolumeDisclaimerText">OBS! Konvertering är endast ett ungefärligt värde
          som vi hämtar<br></br> efter sökning på internet, eller hos leverantör. Vi bedömmer att data
          som oftast är användbar, men vid extra noggrant behov,<br></br>  ber vi er att kontrollera fler källor.</div>
          :''}
            {mobileIsUsed===true&&getCountry==='en'?
          <div className="xeconvertFromVolumeDisclaimerText">ATTENTION! Conversion is only an approximate value
          that we retrieveafter searching on the internet, or at the supplier.<br></br> We judge that data
          usually is useful, but in case of extra <br></br>thorough need,we ask you to check more sources.</div>
      :''}
      {mobileIsUsed===true&&getCountry==='ja'?
          <div className="xeconvertFromVolumeDisclaimerText">ATTENTION! 単位の変換は、<br></br>インターネットや提供者から得た情報を参考にしており
          <br></br>わたしたちは,これらのデータを使用可能であると判断しては<br></br>いますが、もっと正確な数値が必要な場合には
          より多くの情報を収集してください。</div>
      :''}

</div>


  )

}
function ViewIngredInformation(props){
let {ingred,getIngredName,GetAIIngredientInformation,
  getIngredInformation,getcheckval,checkHandler,
  ingredInfoHeaderTextlegend,
  ingredInfoGetInfoButtonText,
  ingredInfoGetNewInfoText
}=props
  

let getInfo
if(getIngredInformation){
getInfo=getIngredInformation.data
// let getInfo=getIngredInformation.data.data.content
}




return(
  <div className="xeingredInformationTop">
    <fieldset className="xeingredInformationFieldset"><legend className="xeingredInformationLegend">{ingredInfoHeaderTextlegend}: {getIngredName}</legend>
  
    <button className="xeingredInformationButton" onClick={()=>GetAIIngredientInformation()}>{ingredInfoGetInfoButtonText}</button>
    
    <input type="checkbox" className="xeingredInformationCheckbox" value={getcheckval} onClick={()=>checkHandler()} /><label className="xecheckboxlabel">{ingredInfoGetNewInfoText}</label>
    
  
    <div className="xeingredInformationTextArea">{getInfo}</div>
     </fieldset>
  </div>
)
}


function MobileGetIngredBarChart(props){
  let {getChartData}=props

  return (
     
<div className="xechartArea">

<ResponsiveContainer>
      <BarChart
        // width={500}
        // height={400}
        data={getChartData}
        margin={{
          top: 10,
          right: 15,
          left: -10,
          bottom: 75,
        }}
      >
        <CartesianGrid strokeDasharray="2 2" />
        <XAxis dataKey="month_day"  stroke="black" />
        <YAxis datakey="pris"  stroke="black" domain={[0, 'dataMax + 6']}/>
        <Tooltip />
        {/* <Legend /> */}
        <Bar dataKey="pris" fill="#8884d8" />
        <Line type="monotone" dataKey="pris" stroke="#ff7300" />
     
      </BarChart>
    </ResponsiveContainer>
</div>
  );
      }

  function MobileGetIngredLineChart(props){
        let {getChartData}=props
    
      
        return (
          <div className="xechartArea">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={500}
              data={getChartData}
              margin={{
                top: 10,
                right: 15,
                left: -10,
                bottom: 75,
              }}
            > 
              <CartesianGrid strokeDasharray="2 2" stroke="lightgrey"/>
              <XAxis dataKey="month_day" stroke="black">
            
              </XAxis>
              <YAxis datakey="pris"  stroke="black"  domain={[0, 'dataMax + 6']}/>
          
              <Tooltip />
              {/* <Legend  layout="horizontal" verticalAlign="bottom" align="left" iconSize={30}/> */}
            
              <Line type="monotone" dataKey="pris" stroke="#8884d8" activeDot={{ r: 8 }} />
            
            </LineChart>
      
          </ResponsiveContainer>
          </div>
    
        )
          }

          
 function MobileGetIngredAreaChart(props){
  let {getChartData}=props
 
 
     return (
      <div className="xechartArea">
   <ResponsiveContainer width="100%" height="100%">
           <AreaChart
             width={500}
             height={400}
             data={getChartData}
             margin={{
               top: 10,
               right: 15,
               left: -15,
               bottom: 75,
             }}
           >
             <CartesianGrid strokeDasharray="2 2"  stroke="lightgrey"/>
             <XAxis dataKey="month_day" stroke="black" />
             <YAxis dataKey="pris"  stroke="black" domain={[0,15]}/>
             <Tooltip />
             <Area type="monotone" dataKey="pris" stackId="1" stroke="blue" fill="rgb(255, 128, 0)"/>
             {/* <Legend/> */}
             <Tooltip />
           </AreaChart>
         </ResponsiveContainer>
         </div>
     );
   }




export {IngredientsNew,Ingredmenubuttons,KategoriSelect,DeleteIngredient,IngredientToRecepyConn,
      CreateIngredient,NutrientInfo,ViewNutrientData,ViewNutrientData1,ChartChoice,ProductRecepyConnections,
      Ingredientlist,IngredientlistSimple,InkopsStatistik,InkopsStatistikNew,
      IngredientToProducts,Pris,IngredientlistPrice,DeleteIngredientNew,ViewConvertedValue,
      ConvertDisclaimer,ViewIngredInformation,CreateJAPIngredient,
      EditNutrientData,IngredientlistNutrient,ConvertFromVolume,
      MobileGetIngredBarChart,MobileGetIngredLineChart,MobileGetIngredAreaChart
    }