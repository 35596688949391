import React,{useState,useEffect} from 'react'
import './CreateProductNew2.css'
import {useGetData,usePostData,usePutData,useDeleteData} from '../hooks/useGetData';
import useReportErr from '../hooks/useReportErr';
import {RiDeleteBack2Line} from 'react-icons/ri'
import{AiOutlinePlusCircle} from 'react-icons/ai'
import{AiOutlineMinusCircle} from 'react-icons/ai'
import {HiOutlineSaveAs} from 'react-icons/hi'
import {HiOutlineSave} from 'react-icons/hi'
import {FaNutritionix} from 'react-icons/fa'
import { BsTrash } from 'react-icons/bs';
import { RotatingLines } from 'react-loader-spinner';
import Alert from '../functions/appAlert';
import {useNavigate} from 'react-router-dom';
import useFetchImageUrl from '../hooks/useFetchImageUrl';
import usePageSettings from '../hooks/usePageSettings';
import { Base64 } from 'js-base64';
import YesNoAlert from '../functions/YesNoAlert';
import { IoCreateOutline } from "react-icons/io5"
import languages from '../AppLanguage/AppLanguage';
import AppError from '../AppLanguage/AppError';
import displayGadgetToSmall from '../functions/displayGadgetToSmall'
import { CiImageOn } from "react-icons/ci";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import noimagefound1 from '../images/noimagefound1.jpg'
import { RiUploadCloud2Line } from "react-icons/ri"
import { RiDownloadCloud2Line } from "react-icons/ri"
import { IoIosImages } from "react-icons/io"
import { IoDocumentTextOutline } from "react-icons/io5";


export default function CreateProductNew() {

const[getCreateProductNew,setCreateProductNew]=useState([{}])
const [getIngred,setIngred]=useState([{}])
const[getProductId,setProductId]=useState(0)
const[getCategories,setCategories]=useState([{}])
const[getProductObj,setProductObj]=useState([{}])
const[getProductByCategory,setProductByCategory]=useState([{}])
const[getPack,setPack]=useState([{}])
const [getShowAlert, setShowAlert] = useState(false);
const [getAlertCode, setAlertCode] = useState(0);
const[getAlertMessage,setAlertMessage]=useState('')
const[getSpinnerLoading, setSpinnerLoading] = useState(false)
const[getChosenCategory,setChosenCategory]=useState(0)
const[getChosenProductid,setChosenProductid]=useState(0)
const[getProductRows,setProductRows]=useState([{}])
const[expandedRow,setExpandedRow]=useState(null)
const[getReceivedDataStatus,setReceivedDataStatus]=useState(false)
const[getelementId,setelementId]=useState(0)
const[getShowSaveButton,setShowSaveButton]=useState(false)
const[getButtonSaveActive,setButtonSaveActive]=useState(false)
const[getpriceInfo,setpriceInfo]=useState(0)
const[getIdkey,setIdkey]=useState(0)
const[getSaveRecepyMenu,setSaveRecepyMenu]=useState(false)
const[getSaveCategory,setSaveCategory]=useState(0)
const[getShowCreateProduct,setShowCreateProduct]=useState(false)
const[getRecept,setRecept]=useState([{}])
const[getProductName,setProductName]=useState('')
const [getTrashIsHovered, setTrashIsHovered] = useState(false);
const[getYesNoAlertMessage,setYesNoAlertMessage]=useState('')
const[getShowYesNoAlert,setShowYesNoAlert]=useState(false)

// delete images
const[getYesNoAlertMessageImage,setYesNoAlertMessageImage]=useState('')
const[getShowYesNoAlertImage,setShowYesNoAlertImage]=useState(false)

const[getBackGroundShade,setBackGroundShade]=useState(0.75)
const[getRenderKey,setRenderKey]=useState(0)
const[getMenuShade,setMenuShade]=useState(0.85)
const[getMenuShade2,setMenuShade2]=useState(0.65)
const [getColorVal,setColorVal]=useState('')
const [getImageVal,setImageVal]=useState('')
const [getImageUrl,setImageUrl]=useState('')
const [pageid]=useState(10)
const [logtime]=useState(5000)

const [windowWidth,setWindowWith]=useState(0) // this is the window width
const [windowHeight,setWindowHeight]=useState(0)  // this is the window height
const [getMobileIsUsed,setMobileIsUsed]=useState(false) // this is the window height
const[getCountry,setCountry]=useState('se') // this is the country
const[getIpadIsUsed,setIpadIsUsed]=useState(false) // this is the ipad
const[getFuriganaName,setFuriganaName]=useState('') // this is the furigana name
const[getShowFurigana,setShowFurigana]=useState(false) // this is the furigana name
const[getProductDescription,setProductDescription]=useState('') // this is the product description
const[getProductDescriptionActive,setProductDescriptionActive]=useState(false) // this is the product description

    // file handling
    const maxSize = 3 * 1024 * 1024; // 3 Megabytes
    const acceptedFileTypes = ['image/jpeg', 'image/png'];
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);
    // end file handling

    const [getImageText,setImageText]=useState('') // this is the image text
    const[getTabId,setTabId]=useState('image')
    const[getRecipeImage,setRecipeImage]=useState(0)
    const [recipeImages, setRecipeImages] = useState([]);
    const[getChosenImageIndex,setChosenImageIndex]=useState(0)
    const [trigger, setTrigger] = useState(false); 
    const[getTabNr,setTabNr]=useState(1)



useEffect(()=>{
  let country = localStorage.getItem('country')||'en' // this is the country code
  setCountry(country)
},[])

          // if language is japanese we use the font Zen Old Mincho
          let newFontFamily=''
          if(getCountry==='ja'){
          newFontFamily= "'Zen Old Mincho', Arial, Helvetica, sans-serif"
          }else{
          newFontFamily='Arial, Helvetica, sans-serif'
          }
          document.documentElement.style.setProperty('--font-family', newFontFamily);
      
const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal } = usePageSettings(pageid);
const { imageUrl, alertMessage, alertCode, showAlert } = useFetchImageUrl(getImageVal); // runs at mount, gives ut the image url and fetch image from server

const navigate = useNavigate();



useEffect(() => {
  function setSize() {
    setWindowWith(window.innerWidth);
    setWindowHeight(window.innerHeight);
  
    if(window.innerWidth < 600){

      setMobileIsUsed(true)
    } 

  }


  // Call setSize initially to set the correct state
  setSize();

  // Add the event listener
  window.addEventListener('resize', setSize);

  // Return a cleanup function to remove the event listener when the component unmounts
  return () => {
    window.removeEventListener('resize', setSize);
  };
}, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]); // Dependencies

function rgbToRgba(rgb, alpha) {
  return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
}

//--bakcground shade settings
let rgbaVal
let rgbaVal2
let val

if(getColorVal && getColorVal.length > 0){
let rgb = getColorVal;
let alpha = getMenuShade; // replace with your alpha value
let alpha2 = getMenuShade2; // replace with your alpha value
rgbaVal = rgbToRgba(rgb, alpha);
rgbaVal2 = rgbToRgba(rgb, alpha2);
val=`rgba(255, 255, 255, ${getBackGroundShade})`

}else{
  rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
  rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
  val=`rgba(255, 255, 255, ${getBackGroundShade})`
}
//---

useEffect(() => {
  let root = document.documentElement;
  root.style.setProperty('--rgbaVal', rgbaVal);
  root.style.setProperty('--rgbaVal2', rgbaVal2);
  root.style.setProperty('--val',val);
  root.style.setProperty('--windowHeight',windowHeight+'px');

  
}, [getBackGroundShade,getMenuShade,getMenuShade2]);


  useEffect(() => {
    GetSettings();
  }, [GetSettings]);

  // we set the image url here
  useEffect(() => {
    setImageUrl(imageUrl);
    if (alertCode > 0) {
      setAlertMessage(alertMessage);
      setAlertCode(alertCode);
      setShowAlert(showAlert);
    }
  }, [imageUrl]);

  // here we set the color values and image values
  useEffect(() => {
    setBackGroundShade(backGroundShade);
    setMenuShade(menuShade);
    setMenuShade2(menuShade2);
    setColorVal(colorVal);
    setImageVal(imageVal);
  }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);

      // language settings
    const selectedCountry = languages[getCountry];

       let title=''
      if(getMobileIsUsed===false){
        title=selectedCountry.createProduct?.title
      }else{
        title=selectedCountry.createProduct?.titleMobile
      }

    const {
      
        leftSelectLegend,
        leftSelectFirstOption,
        titleCreateNewProduct,
        middleFirstSelectLegend,
        middleSecondSelectLegend,
        middleThirdSelectLegend,
        middleFirstSelectFirstOption,
        middleSecondSelectFirstOption,
        middleThirdSelectFirstOption,
        headerId,
        headerName,
        headerAmount,
        headerTotal,
        headerCalories,
        titleSaveMenuOpenButton,
        rightHeader,
        totalCostLegend,
        prisHeaderLegend,
        marginalHeaderLegend,
        nutrientHeaderLegend,
        caloriesHeaderLegend,
        proteinHeaderLegend,
        fatHeaderLegend,
        carbonHeaderLegend,
        titleAdjustMenu,
        titleAdjustMenu1,
        titleDeleteFromProduct,
        titleTrash,
        titleIncreaseAmount,
        titleDecreaseAmount,
        saveProduktButtonText,
        titleKlickToSave,
        saveProduktBoxMenuHeader,
        namePlaceHolderText,
        middleFirstSelectLegendMobile,
        middleSecondSelectLegendMobile,
        middleThirdSelectLegendMobile,
        recipeIconText,
        imageIconText,
        uploadIconText,
        downloadIconText,
        generateAIimageDisclaimer,
choseImageButtontext,
choseImageUploadText,
recepyMustBeChosenBeforeUploadMessage,
createImageButtonText,
createImageTextPlaceholder,
deleteImageTitle,
produkttitle,
noChosenProdukt,
descriptionIconText,
saveDescriptionButtonText,
placeholderDescriptionText,
  
    
      } = selectedCountry.createProduct || {};

  // error messages
 const selectedError=AppError[localStorage.getItem('country')||'en']
 
 const{
  productDeleted,
  productIsSaved,
  yesNoDeleteProduct,
  nameInfo,
  btnYes,
  btnNo,
  imageSearchTextToLong,
    selectedImageDeleted,
    aiimageIsDone,
    imageDeletQuestion,
    imageUploadedMessage,
    yesnodeleteIngredient,
    aiimageCantBeDone,
    aiImageCantBeUploaded,
    aiSubscriptionVoid,
    productsVoid,
    imageuploadVoid,
    
 }=selectedCountry.confirmationMessages||{}



  useEffect(() =>  {
    async function GetData(){
      setSpinnerLoading(true)
    try{
    let respons=await useGetData('createNewProduct')
    setIngred(respons.data[0]) 
    setRecept(respons.data[1])
    setPack(respons.data[2])
    setProductId(respons.data[3][0].produktid)
    setCategories(respons.data[4])
    setProductObj(respons.data[5])
    setSpinnerLoading(false)
    setTabNr(2) // setting the initial tab

    } catch(error){
        let errcode=20001
        setAlertCode(20001)
        setAlertMessage(selectedError[20001])
        setSpinnerLoading(false)
        ReportErrHandler(errcode)
        setShowAlert(true)
       
        return;
    }

    }
    GetData()
    setTabId('image')
    showTab('image')
  },[])

    useEffect(()=>{
        async function GetProductsByCategory(){
            setSpinnerLoading(true)
            try{
                let respons=await useGetData(`getAllProductByCategory/${getChosenCategory}`)
                setProductByCategory(respons.data[0])
                setSpinnerLoading(false)
             
            }catch(error){
                let errcode=2002
                setAlertCode(20002)
                setAlertMessage(selectedError[20002])
                ReportErrHandler(errcode)
                setShowAlert(true)
                setSpinnerLoading(false)
                setTimeout(() => {
                    navigate('/Logout');
                  },logtime);
                return;
            }
          
        }
        GetProductsByCategory()
    },[getChosenCategory])

useEffect(()=>{
    async function GetProductById(){
        setSpinnerLoading(true)
        try{
            let response=await useGetData(`GetProductRows/${getChosenProductid}`)
        
            // setProductRows(response.data[0])
            // setProductName(response.data[1][0].benaemn)
            // setFuriganaName(response.data[1][0].benaemnfuri)
            // setpriceInfo(response.data[2][0])
            setProductRows(response.data.productRows);
            setProductName(response.data.productName);
            setFuriganaName(response.data.furiganaName);
            setProductDescription(response.data.productDescription);
            setpriceInfo(response.data.priceInfo);
            setImageText(response.data.productName);// setting the name in the generate ai image textarea

            setSaveRecepyMenu(false)
            setButtonSaveActive(false)
            setSaveCategory(0)
            setShowCreateProduct(false)
            setExpandedRow(null);
            setSpinnerLoading(false)
          
     
        }catch(error){
            let errcode=20003
            setAlertCode(20003)
            setAlertMessage(selectedError[20003])
            ReportErrHandler(errcode)
            setShowAlert(true)
            setSpinnerLoading(false)
           
            return;
        }
    }
    GetProductById()
},[getChosenProductid])


  


  async function ReportErrHandler(){

    setSpinnerLoading(true)
    await useReportErr('ErrReport',{getAlertCode})
    setSpinnerLoading(false)
    return
  }
  
  
  const RunReportErrHandler = async (errCode) => {
    setSpinnerLoading(true);
    await useReportErr('ErrReport',{errCode});
    setSpinnerLoading(false);
  };

  
  useEffect(() => {
    if (getAlertCode > 0) {
      RunReportErrHandler(getAlertCode);
    }
  }, [getAlertCode]);

  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  const handleCloseAlertYes = () => {
    setShowYesNoAlert(false);
   DeleteProductHandler()

  };
  const handleCloseAlertNo = () => {
    setShowYesNoAlert(false);
  };

  const handleCloseAlertYesImage = () => {
    setShowYesNoAlertImage(false);
   deleteImageHandler()

  };
  const handleCloseAlertNoImage = () => {
    setShowYesNoAlertImage(false);
  };


const chosenCategoryHandler=(categoryid)=>{
    setChosenCategory(categoryid)
}

const chosenProductHandler=(productid)=>{
    setChosenProductid(productid)
    setShowFurigana(false)
    setProductDescriptionActive(false)
    setProductDescription('')
    setProductName('')
    showTab('image')

}

const deleteingredientHandler=async(elementid,idkey)=>{
    // we copy the object getProductRows
    let myNewObjArray = [...getProductRows];
    // we need to find the right index to delete
    let index = myNewObjArray.findIndex((obj => obj.elementid === elementid && obj.idkey===idkey));
    // we delete the object
    myNewObjArray.splice(index, 1);
    // we set the state
    setProductRows(myNewObjArray)
    // we will show the save button
    setShowSaveButton(true)
    setButtonSaveActive(true)
}

const DeleteProductHandler=async()=>{
    setSpinnerLoading(true)

    try{
        let respons=await useDeleteData(`deactivateProducts/${getChosenProductid}`)
        if(respons.status===200){
            // here we need to update the getProductByCategory object so we can see the change in the list
            // we copy the object getProductByCategory
            let myNewObjArray = [...getProductByCategory];
            // we need to find the right index to delete
            let index = myNewObjArray.findIndex((obj => obj.produktid === getChosenProductid));
            // we delete the object
            myNewObjArray.splice(index, 1);
            // we set the state
            setProductByCategory(myNewObjArray)
            setProductName('')
            setProductId(0)
         

            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage(productDeleted)
            setShowAlert(true)
       
            return;
        }

    }catch(error){
        let errcode=20010
        setAlertCode(20010)
        setAlertMessage(selectedError[20010])
        ReportErrHandler(errcode)
        setShowAlert(true)
        setSpinnerLoading(false)
        return;
    
    }
}


const handleRowClickIndex = (index,id,elementid,idkey) => {// we get the index of the row and the produktid
   // setChosenProductid(id)
  setSaveRecepyMenu(false) // if we click on a row we will close the save menu
   setSaveCategory(0)
    setelementId(elementid) // here we set the ingredid to our state
      setReceivedDataStatus(false) // here we reset the received data status
      setIdkey(idkey)
      //setButtonSaveActive(false)  // we decided when we close the row we will not disable the save button, if true we will disable the save button
 
    //   setshowIngredComment(true) // here we open the component
      if (expandedRow === index) { // here we check if the index is the same as the expanded row
        setExpandedRow(null);
      } else {
        setExpandedRow(index);
      }
    };

    const addamountHandler=(type)=>{
        setButtonSaveActive(false)
        //here we copy the object getProductRows
        //and we need to find the right index to change the amount
        let myNewObjArray = [...getProductRows];
        let index = myNewObjArray.findIndex((obj => obj.elementid === getelementId && obj.idkey===getIdkey));
        // we get the amount
        let amount=myNewObjArray[index].amount
        //we get the koeff
        let koeff=myNewObjArray[index].koeff
        //we get the pris
        let pris=myNewObjArray[index].pris
        //here we change the amount
        //here we need to change value for calories
        let kalories=myNewObjArray[index].kalories
        // here we have the koeff
        let kaloriekoeff=myNewObjArray[index].kaloriekoeff


        if(type===1){
            myNewObjArray[index].amount=amount+1
            //we recalculate the pris
            myNewObjArray[index].pris=myNewObjArray[index].amount*koeff
            // here we will calculate calories
            myNewObjArray[index].kalories=myNewObjArray[index].amount*kaloriekoeff
            //we set the state
            setProductRows(myNewObjArray)

        }else if(type===3){
            myNewObjArray[index].amount=amount+10
            //we recalculate the pris
            myNewObjArray[index].pris=myNewObjArray[index].amount*koeff
            // here we will calculate calories
            myNewObjArray[index].kalories=myNewObjArray[index].amount*kaloriekoeff
            //we set the state
            setProductRows(myNewObjArray)

        }else if(type===4){
            myNewObjArray[index].amount=amount-10
            //we recalculate the pris
            myNewObjArray[index].pris=myNewObjArray[index].amount*koeff
            // here we will calculate calories
            myNewObjArray[index].kalories=myNewObjArray[index].amount*kaloriekoeff
            //we set the state
            setProductRows(myNewObjArray)

        }
        
        else{
            myNewObjArray[index].amount=amount-1
                 //we recalculate the pris
                 myNewObjArray[index].pris=myNewObjArray[index].amount*koeff
                 // here we will calculate calories
                    myNewObjArray[index].kalories=myNewObjArray[index].amount*kaloriekoeff
                 //we set the state
                 setProductRows(myNewObjArray)
        }
        setShowSaveButton(true)
        setButtonSaveActive(true)
    }


    async function IngredIdHandler(e){
        setShowCreateProduct(false)
        setExpandedRow(null); //closing the expanded row
        setSaveRecepyMenu(false) // if we click on a row we will close the save menu
        setSaveCategory(0)
        setSpinnerLoading(true)
        let IngredNamn=getIngred.find(data=>{return data.ingredid==e})
        if(IngredNamn.kaloriekoeff===undefined){
            IngredNamn.kaloriekoeff=0
        }
        try{
            let response= await useGetData(`getpris/${e}`)
            let pris=response.data[0][0].pris||0
         
  
            let newObjArr=[...getProductRows||[]]
            let highIndex=newObjArr.length||0
         
  let val=0
  let value=0
  let idkeyVal=1

    
  newObjArr[highIndex]={["idkey"]:idkeyVal,["elementid"]:e,["id"]:getChosenProductid||0,["namn"]:IngredNamn.benaemn,["pris"]:pris,["total"]:val,["amount"]:value,["koeff"]:pris/1000,['kalories']:IngredNamn.kalories,['kaloriekoeff']:IngredNamn.kaloriekoeff}

       setProductRows(newObjArr)
        setShowSaveButton(true)
        setSpinnerLoading(false)
    }catch(error){
    
        // let errcode=20004
        // setAlertCode(20004)
        // setAlertMessage(selectedError[20004])
        // ReportErrHandler(errcode)
        // setShowAlert(true)
        setSpinnerLoading(false)
     
    }
}


    const saveRecepyHandler = async () => {
        if (getSaveRecepyMenu === true) {
            setSaveRecepyMenu(false);
            setSaveCategory(0)
            setExpandedRow(null);
        } else {
            setSaveRecepyMenu(true);
        }
    };

       async function ChosenRecepyHandler(receptid){
        setShowCreateProduct(false)
        setExpandedRow(null);
        setSaveRecepyMenu(false) // if we click on a row we will close the save menu
        setSaveCategory(0)
        setSpinnerLoading(true)
   
    
        try{
            let respons= await useGetData(`getrecepyKoeff/${receptid}`)
            let kaloriekoeff=respons.data[1][0].kaloriekoeff||0
            let recepykoeff=respons.data[0][0].recepykoeff||0
        
            let receptNamn=getRecept.find(data=>{return data.receptid==receptid})
            
            let newObjArr=[...getProductRows||[]]
            let highIndex=newObjArr.length||0
            let value=0
            let idkeyVal=2

      newObjArr[highIndex]={["id"]:getChosenProductid||0,["kaloriekoeff"]:kaloriekoeff,["kalories"]:0,["idkey"]:idkeyVal,["elementid"]:receptid,["namn"]:receptNamn.benaemn,["pris"]:recepykoeff,["amount"]:value,["koeff"]:recepykoeff}
        setProductRows(newObjArr)
        setSpinnerLoading(false)
        
       }catch(error){
 
        let errcode=20005
        setAlertCode(20005)
        setAlertMessage(selectedError[20005])
        ReportErrHandler(errcode)
        setShowAlert(true)
        setSpinnerLoading(false)
      
       }
    }

    // async function PackHandler(packid){
    //     setSpinnerLoading(true)
    //     setShowCreateProduct(false)
    //     setExpandedRow(null);
    //     setSaveRecepyMenu(false) // if we click on a row we will close the save menu
    //     setSaveCategory(0)
    //     try{
    //         let respons=await useGetData(`getPackKoeff/${packid}`)
    //         let packkoeff=respons.data[0][0].pris||0
    //          let packNamn=getPack.find(data=>{return data.packid==packid})

    //          let newObjArr=[...getProductRows||[]]
    //         let highIndex=newObjArr.length||0
    //         let value=0
    //         let idkeyVal=3
    //         newObjArr[highIndex]={["id"]:getChosenProductid||0,["idkey"]:idkeyVal,["elementid"]:packid,["namn"]:packNamn.benaemn,["pris"]:packkoeff,["amount"]:value,["koeff"]:packkoeff}
    //         setProductRows(newObjArr)
    //         setShowSaveButton(true)
    //         setSpinnerLoading(false)
    //     }catch(error){
    //         let errcode=20006
    //         setAlertCode(20006)
    //         setAlertMessage(selectedError[20006])
    //         ReportErrHandler(errcode)
    //         setShowAlert(true)
    //         setSpinnerLoading(false)
          
    //     }
    // }

    const categoryHandler=(categoryid)=>{
        setSaveCategory(categoryid)
    }

    async function SaveProductHandler(){
      
        setSpinnerLoading(true)
        // first we need to organize the object, we copy the object getProductRows
        let myNewObjArray = [...getProductRows];
        // we need to add the name of the product
        let productname=getProductName
        // add productname to the object
        myNewObjArray[0].productname=productname
        // we need to add the productid
        let productid=getProductId
        // add productid to the object
        myNewObjArray[0].productid=getChosenProductid
        // we need to add the categoryid
        let categoryid=getSaveCategory || getChosenCategory
        // add categoryid to the object
        myNewObjArray[0].categoryid=categoryid
        // now we will do the encryption with myNewObjArray we use b64 and stringify
        let myNewObjArrayString=JSON.stringify(myNewObjArray)
      //  let myNewObjArrayStringEncrypted=btoa(myNewObjArrayString)
        let myNewObjArrayStringEncrypted = Base64.encode(myNewObjArrayString);
        
        try{
     
            let respons=await usePostData(`SaveProduct`,{myNewObjArrayStringEncrypted,getFuriganaName,getProductDescription})
        
            // we create the id in the database  and this is the return
            let newproductid=respons.data.id
            if(respons.status===200){
            setSpinnerLoading(false)
            setSaveRecepyMenu(false)
            setSaveRecepyMenu(false)
            setButtonSaveActive(false)
            setExpandedRow(null)
            setFuriganaName('')
            setProductDescription('')

            //here we will update the getProductByCategory object so we can see the change in the list
            // to do so we need to insert the new product in the getProductByCategory object
            // we copy the object getProductByCategory
            let myNewObjArray = [...getProductByCategory];
            // we need to add the name of the product
            let productname=getProductName
            // we need to add the productid
            let productid=getChosenProductid
            // we need to add the categoryid
            let categoryid=getSaveCategory || getChosenCategory

            // here we need to check if the product has a productid or not, if it has a id its an old item and has to be updated
            // if it has no id its a new item and has to be added to the list
            if(productid>0){
                // we need to find the right index to update the name
                let index = myNewObjArray.findIndex((obj => obj.produktid === productid));
                // we update the name
                myNewObjArray[index].benaemn = productname;
                // we update the category
                myNewObjArray[index].kategoriid = categoryid;
                // we set the state
                setProductByCategory(myNewObjArray)
            }else{
            // now we will add the new product to the object
            myNewObjArray.push({['benaemn']:productname,['produktid']:newproductid,['kategoriid']:categoryid})
            // we set the state
            setProductByCategory(myNewObjArray)

            // but we also need to update the getPoductRows object to be able to load the object without refreshing the page
            // id is set in the database at creation
            // here we must update the getProductRows object  with the new productid so we map over the object and update the id
            let myNewObjArray1 = [...getProductRows];
            // w euse map or for loop
            myNewObjArray1.map((data,index)=>data.id=newproductid)
            // we set the state
            setProductRows(myNewObjArray1)

            }
            // stänger ner
            setProductName('')
            setProductId(0)
            setProductRows([])
            setExpandedRow(null)
            setSaveRecepyMenu(false)
            setSaveCategory(0)
            setShowCreateProduct(false)
            setButtonSaveActive(false)
            setChosenProductid(0)
            
            setAlertCode(0)
            setAlertMessage(productIsSaved)
            setShowAlert(true)
            return;
        
        }else if(respons.status===201){
            setAlertCode(0)
            setSpinnerLoading(false)
            setAlertMessage(productsVoid)
            setShowAlert(true)
            return;
        }
        }catch(error){
            let errcode=20007
            setAlertCode(20007)
            setAlertMessage(selectedError[20007])
            ReportErrHandler(errcode)
            setShowAlert(true)
            setSpinnerLoading(false)
           
            return;
        }
    }


    const productNameHandler = (productname) => {
      setShowFurigana(true)
        if (productname.length <= 45) {
            setProductName(productname);
            setButtonSaveActive(true);
            if(getChosenProductid>0){
            //here we also need to update the name in the getProductByCategory object
            // we copy the object getProductByCategory
            let myNewObjArray = [...getProductByCategory];
            // we need to find the right index to update the name
            let index = myNewObjArray.findIndex((obj => obj.produktid === getChosenProductid));
            // we update the name
            myNewObjArray[index].benaemn = productname;
            // we set the state
            setProductByCategory(myNewObjArray);
            }
            
        } else {
            setAlertCode(0);
            setAlertMessage(nameInfo);
            setShowAlert(true);
        }
    };


// if the user changges the value on the range control we save the value in the database
async function SetBackGroundShadeHandler(val){
    setBackGroundShade(val)
    setRenderKey(getRenderKey+1)
    setSpinnerLoading(true)
    try{
      const response = await usePutData(`updateBackGroundShade`,{pageid,val})
      if(response.status===200){
      setSpinnerLoading(false)
      }
    }catch(error){
        setAlertCode(20008)
        setAlertMessage(selectedError[20008])
        setSpinnerLoading(false)
        setShowAlert(true)
  
      }
  
  }
  
  async function SetmenuColorHandler(val){
    setMenuShade(val)
    setMenuShade2(val-0.20)
    setRenderKey(getRenderKey+1)
    setSpinnerLoading(true)
    try{
      const response = await usePutData(`updateMenuShade`,{pageid,val})
      if(response.status===200){
      setSpinnerLoading(false)
      }
  
  }catch(error){
    setAlertCode(20009)
    setAlertMessage(selectedError[20009])
    setSpinnerLoading(false)
    setShowAlert(true)

  }
  }
  
  const setTrashIsHoveredHandler = () => {
    if(getTrashIsHovered===false){
        setTrashIsHovered(true)

  }else{
    setTrashIsHovered(false)
  }
}

const deleteProductHandlerContainer=()=>{
    setYesNoAlertMessage(yesNoDeleteProduct)
    setShowYesNoAlert(true)
}

const createNewProductEnvHandler=()=>{
    //here i need to refresh the window so we can make a new product
    setProductName('')
    setProductId(0)
    setProductRows([])
    setExpandedRow(null)
    setSaveRecepyMenu(false)
    setSaveCategory(0)
    setShowCreateProduct(true)
    setButtonSaveActive(false)  
    setShowFurigana(false)
    setChosenProductid(0)
    setProductDescription('')
    setProductDescriptionActive(false)
}

const setFuriganaNameHandler=(val)=>{
    setFuriganaName(val)
}

const setFuriganaProductNameHandler=(val)=>{
    setFuriganaName(val)
    setShowFurigana(false)
}

function showTab(tabId,tabNr) {
  setTabId(tabId)
  setTabNr(tabNr)
  //here we want to make a request to the api to get the image
  const tabContents = document.querySelectorAll('.tab-content');
  tabContents.forEach(tabContent => {
    tabContent.style.display = 'none';
  });
  document.getElementById(tabId).style.display = 'block';
}



useEffect(()=>{
  GetImageData()
  },[getChosenProductid,trigger])


  async function GetImageData(){
    setSpinnerLoading(true)
    try{
  const token = localStorage.getItem('auth_token');
  const headers = token ? { authorization: `Bearer ${token}` } : {};  
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/streamBucketImagesProduct/${getChosenProductid}`, { headers });
if(response.status === 200){
    const images = await response.json(); // assuming the images are Base64 encoded strings
    const imageBlobs = images.map(image => {
        const byteCharacters = atob(image);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], {type: 'image/jpeg'}); // adjust the MIME type as needed
    });
   // const imageUrls = imageBlobs.map(blob => URL.createObjectURL(blob));
    const imageUrls = imageBlobs.map(blob => URL.createObjectURL(blob));
    let loadedImagesCount = 0;

imageUrls.forEach((url, index) => {
    const img = new Image();
    img.onload = () => {
        loadedImagesCount++;
        if (loadedImagesCount === imageUrls.length) {
            setSpinnerLoading(false);
           // showTab('info')
        }
    };
    img.src = url;
});

setRecipeImages(imageUrls);
    // setRecipeImages(imageUrls);
    // setSpinnerLoading(false);
} else if (response.status === 404){
    setAlertCode(0);
    setAlertMessage(selectedError[20011]);
    setSpinnerLoading(false);
    setShowAlert(false);
}
    } catch(error){
      setAlertCode(20012)
      setAlertMessage(selectedError[20012])
      setSpinnerLoading(false)
      setShowAlert(true)
    }
  }


async function GenerateaiImage(){
  setSpinnerLoading(true)
  try{
    let response = await usePostData(`generateaiimageProduct`,{getChosenProductid,getImageText})
  
    if(response.status===200){
   // setSpinnerLoading(false)
    setAlertCode(0)
    setAlertMessage(aiimageIsDone)
    setShowAlert(false)
    setSpinnerLoading(false)
    setTrigger(!trigger); 

    }else if(response.status===201){
    
      setAlertCode(0)
      setAlertMessage(aiSubscriptionVoid)
      setShowAlert(true)
      setSpinnerLoading(false)

    }
    else if(response.status===202){
    
      setAlertCode(0)
      setAlertMessage(aiImageCantBeUploaded)
      setShowAlert(true)
      setSpinnerLoading(false)

    }
    else if(response.status===203){
    
      setAlertCode(0)
      setAlertMessage(aiSubscriptionVoid)
      setShowAlert(true)
      setSpinnerLoading(false)

    }
  }catch (error){
    setAlertCode(20013)
    setAlertMessage(selectedError[20013])
    setSpinnerLoading(false)
    setShowAlert(true)
  }


}
const setImageTextHandler=(text)=>{
  if(text.length>700){
    setAlertCode(0)
    setAlertMessage(imageSearchTextToLong)
    setShowAlert(true)
  }else{
    setImageText(text)
  }

}

const choseImageHandler=(image)=>{
  setChosenImageIndex(image)
}

const deleteImageHandlerContainer=()=>{
  setYesNoAlertMessageImage(`${imageDeletQuestion}?`)
  setShowYesNoAlertImage(true)
}


const deleteImageHandler=()=>{


  // here we delete the image from the object first
  let images=[...recipeImages]
  images.splice(getChosenImageIndex,1)

  setRecipeImages(images)
  // we call up the delete image function
 DeleteImage()

}

async function DeleteImage(){
  setSpinnerLoading(true)
  try{
    let response=await useDeleteData(`deleteimagesProduct/${getChosenProductid}/${getChosenImageIndex}`)
    if(response.status===200){
    setSpinnerLoading(false)
    setAlertCode(0)
    setAlertMessage(selectedImageDeleted)
    setShowAlert(true)
    }
  }
   catch(error){
      setAlertCode(20014)
      setAlertMessage(selectedError[20014])
      setSpinnerLoading(false)
      setShowAlert(true)
    }
}

const handleFileChange = (e) => {
  
    let selected = e.target.files[0];

    if (selected) {
      if (selected.size > maxSize) {
        setFile(null);
        setError('File too large. Maximum size is 3MB.');
      } else if (!acceptedFileTypes.includes(selected.type)) {
        setFile(null);
        setError('Invalid file type. Only JPEG and PNG files are accepted.');
      } else {
        setFile(selected);
        setError('');
      }
    }

}

const uploadImage = async () => {
  setSpinnerLoading(true);
  const formData = new FormData();
  formData.append('file', file);
  formData.append('chosenProductid', getChosenProductid);
  
  const token = localStorage.getItem('auth_token');
  const headers = token ? { 
  'Authorization': `Bearer ${token}`
} : {};  

  try {
  //  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/uploadBsimage`, { 
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/uploadBsimageProduct`, { 
      
      method: 'POST',
      headers: headers,
      body: formData
    });
    if (response.status ===200) {
      setSpinnerLoading(false);
      setAlertCode(0)
      setAlertMessage(imageUploadedMessage)
      setShowAlert(true)
      setTrigger(!trigger); 
    } else if(response.status===201){
      setAlertCode(0)
      setAlertMessage(imageuploadVoid)
      setShowAlert(true)
      setSpinnerLoading(false)
    }

  } catch (error) {
     setAlertCode(20015)
      setAlertMessage(selectedError[20015])
      setSpinnerLoading(false)
      setShowAlert(true)
  }
};


const productDescriptionHandler=(descriptiontext)=>{
  setProductDescription(descriptiontext)
  setProductDescriptionActive(true)
}


  return (
<>
<div className="xmtotWrapper">
    {windowWidth<360?displayGadgetToSmall():''}
<div className="xmwindowBackground">
<div className={getBackGroundShade<1?"xmwindowArea1":"xmwindowArea"}>
{getBackGroundShade<1?<img src={imageUrl} alt="Bakesoft" className="xmbackgroundImageRecepyNew" />:''}
     
<div className="xmheaderArea">
<div className={getBackGroundShade<1?"xmcompName":"xmcompName1"}>{title}</div>
</div>
<div className="xmrangeArea">

<input type="range" min="0.0" max="1" value={getMenuShade||0.2} step="0.10" id={1}  
    title={titleAdjustMenu} className="xmrange2" onChange={(e)=>SetmenuColorHandler(e.target.value)}/>
   
    <input type="range" min="0.0" max="1" value={getBackGroundShade||0.2} step="0.10" id={2}  
    title={titleAdjustMenu1} className="xmrange1" onChange={(e)=>SetBackGroundShadeHandler(e.target.value)}/>
    


</div>


<div className="xmwindowAreaA">

    <div className="xmareaATop">
       <div className="xmcreateNewItemWrapper">
    <IoCreateOutline   className={getBackGroundShade<0.9?"xmcreatenewitem":"xmcreatenewitem1"}
    onClick={()=>createNewProductEnvHandler()}
    title={titleCreateNewProduct}/>
    </div>
  

        <fieldset className="xmareaAFieldset"><legend className="xmareaALegend">{leftSelectLegend}</legend>
        <select className="xmareaASelect" onChange={(e)=>chosenCategoryHandler(e.target.value)}>
           {getCategories.map((data,key)=><option key={key}value={data.kategoriid}>&nbsp;&nbsp;{data.benaemn}</option>)}
    </select>
        </fieldset>
    </div>
    <div className="xmareaAMiddle">
      <div className="xmdataAwrapper">
         <div className="xmareaADataArea">
         
                {getProductByCategory.map((data,index)=>
                <button className={index % 2 === 0 ?"xmareaADataButton":"xmareaADataButton1"} 
                onClick={()=>chosenProductHandler(data.produktid)}
               
               key={index}>
                 <div className="xmbuttonContent">
                {data.benaemn}
                {data.image_count > 0 && <IoIosImages className="xmbuttonIcon" />}
                </div>
               </button>)}
         
          </div>
        </div>
    </div>
</div>


<div className={getCountry!=='ja'?"xmwindowAreaB":"xmwindowAreaBJAP"}>

<div className="area-f">
    <div className="xktabs">
      <button className={getTabNr!==1?"xmtab-button1":"xmtab-button2"} onClick={()=>showTab('info',1)}><CiImageOn  className="xkImageIcon"/><div className="xmIconText">{imageIconText}</div></button>
      <button className={getTabNr!==2?"xmtab-button1":"xmtab-button2"} onClick={()=>showTab('image',2)}><TbDeviceDesktopAnalytics className="xkRecipeIcon" /><div className="xmIconText">{produkttitle}</div></button>
      <button className={getTabNr!==5?"xmtab-button1":"xmtab-button2"} onClick={()=>showTab('description',5)}><IoDocumentTextOutline  className="xkimageIconUpload" /><div className="xmIconText">{descriptionIconText}</div></button>
      <button className={getTabNr!==3?"xmtab-button1":"xmtab-button2"} onClick={()=>showTab('upload',3)}><RiUploadCloud2Line className="xkimageIconUpload" /><div className="xmIconText">{uploadIconText}</div></button>
      <button className={getTabNr!==4?"xmtab-button1":"xmtab-button2"} onClick={()=>showTab('download',4)}><RiDownloadCloud2Line className="xkimageIconUpload" /><div className="xmIconText">{downloadIconText}</div></button>
  
    </div>

    <div id="info" className="tab-content">


<div className="xmImageContainerWrapper">
<div className="xkImageContainer">
{recipeImages.length > 0 ?
    Object.entries(recipeImages).map(([index, url]) => (
        <img key={index} src={url} alt={`Recipe ${index}`} className={getChosenImageIndex===index?"s3imagedata1":"s3imagedata"}
         onClick={()=>choseImageHandler(index)}
         onDoubleClick={()=>deleteImageHandlerContainer()}
         title={deleteImageTitle}
        />
    ))
    :
    <img src={noimagefound1} alt="My Image" className="s3imagedataNotFound" />
}
</div>
</div>
</div>


<div id="description" className="tab-content">
  <div className="xmDescriptionTop">
  <textarea className="xmDescriptionTextarea" placeholder={placeholderDescriptionText} 
  value={getProductDescription||''}
  onChange={(e)=>productDescriptionHandler(e.target.value)} />
  <div className="xmButtonWrapper">
    <div className='xmTextlength'>{getProductDescription&&getProductDescription.length}/1000</div>
  {getChosenProductid>0?
<button className={getProductDescriptionActive?"xmSaveProductButton1":"xmSaveProductButton"} 
onClick={()=>SaveProductHandler()}>{saveDescriptionButtonText}</button>
:''}
</div>
</div>
</div>




<div id="upload" className="tab-content">
 <UploadImage handleFileChange={handleFileChange} uploadImage={uploadImage}
 error={error} file={file}  getChosenProductid={getChosenProductid}
   choseImageButtontext={choseImageButtontext} choseImageUploadText={choseImageUploadText}
   recepyMustBeChosenBeforeUploadMessage={recepyMustBeChosenBeforeUploadMessage}
 />
 
    </div>
    
  <div id="download" className="tab-content">
        <div className="xkgenerateImageTop">
      <div className="xkgenerateImageWrapper">
      <textarea className="xmimageinput" placeholder={createImageTextPlaceholder} 
      value={getImageText} onChange={(e)=>setImageTextHandler(e.target.value)} />
     
      <button className={!getImageText?"xkgenerateImageButton":"xkgenerateImageButton1"} onClick={GenerateaiImage}
          disabled={!getImageText||!getChosenProductid}
         >{createImageButtonText}</button>
         <div className="xmnochosenProduktid">{!getChosenProductid?noChosenProdukt:''}</div>
   </div>
  
      </div>
    <div className="xkimageInformationTop">
      <div className="xkimageInformationText">
    {generateAIimageDisclaimer}
      </div>
    </div>
  </div>
  
    
    <div id="image" className="tab-content">
  










<div className="xmbtopWrapper">
<div className="xmareaBTop">

  <div className="xmingredSelectWrapper">
        <fieldset className="xmareaBFieldset"><legend className="xmareaBLegend">{getMobileIsUsed===false?middleFirstSelectLegend:middleFirstSelectLegendMobile}</legend>
        <select className="xmareaBSelect" onChange={(e)=>IngredIdHandler(e.target.value)}>
            <option value='0'>&nbsp;&nbsp;{middleFirstSelectFirstOption}</option>
           {getIngred.map((data,key)=><option key={key}value={data.ingredid}>&nbsp;&nbsp;{data.benaemn}</option>)}
    </select>
        </fieldset>
    </div>
<div className="xmrecipeWrapper">
        <fieldset className="xmareaBFieldset"><legend className="xmareaBLegend">{getMobileIsUsed===false?middleSecondSelectLegend:middleSecondSelectLegendMobile}</legend>
        <select className="xmareaBSelect" onChange={(e)=>ChosenRecepyHandler(e.target.value)}>
            <option value='0'>&nbsp;&nbsp;{middleSecondSelectFirstOption}</option>
           {getRecept.map((data,key)=><option key={key}value={data.receptid}>&nbsp;&nbsp;{data.benaemn}</option>)}
    </select>
        </fieldset>
      </div>
        
        {/* <fieldset className="xmareaBFieldset"><legend className="xmareaBLegend">{getMobileIsUsed===false?middleThirdSelectLegend:middleThirdSelectLegendMobile}</legend>
        <select className="xmareaBSelect" onChange={(e)=>PackHandler(e.target.value)}>
            <option value='0'>&nbsp;&nbsp;{middleThirdSelectFirstOption}</option>
           {getPack.map((data,key)=><option key={key}value={data.packid}>&nbsp;&nbsp;{data.benaemn}</option>)}
    </select>
        </fieldset> */}

    </div>
    <div className="xmareaBMiddle">
        <Nameheader getCreateProductNew={getCreateProductNew} getProductName={getProductName} 
        productNameHandler={productNameHandler}
        getShowFurigana={getShowFurigana}
        setFuriganaNameHandler={setFuriganaNameHandler}
        getFuriganaName={getFuriganaName}
        getIpadIsUsed={getIpadIsUsed}
        getMobileIsUsed={getMobileIsUsed}
        setShowFurigana={setShowFurigana}
        getCountry={getCountry}
        headerName={headerName}
       
        />
    
    </div>
    <div className="xmareaBHeader">

        <button className="xmareaBHeaderBenaemn">{headerName}</button>
        <button className="xmareaBHeaderAmount">{headerAmount}</button>
        <button className="xmareaBHeaderTotalt">{headerTotal}</button>
        <button className="xmareaBHeaderKalorier">{headerCalories}</button>
        <button className="xmareaBHeaderDelete"> <RiDeleteBack2Line className="xmdeleteImage" /></button>
  


        </div>



        <div className="xmareaBDataArea">

{getProductRows  && getProductRows.length>0 && getProductRows.map((data, index) => 

<div key={index} className="xmdatalistRows">

  <button className={index % 2 === 0 ?"xmdatalistBenaemn":"xmdatalistBenaemn1"} title={data.namn} onClick={()=>handleRowClickIndex(index,data.id,data.elementid,data.idkey)}>{data.namn}</button>
  <button className={index % 2 === 0 ?"xmdatalistMangd":"xmdatalistMangd1"}>{data.amount}</button>
  <button className={index % 2 === 0 ?"xmdatalistPris":"xmdatalistPris1"}>{String(data.pris).substring(0,5)}</button>
  <button className={index % 2 === 0 ?"xmdatalistKalorier":"xmdatalistKalorier1"}>{String(data.kalories||0).substring(0,6)}</button>
 <button className={index % 2 === 0 ?"xmdatalistDelete":"xmdatalistDelete1"}>
 <RiDeleteBack2Line className="xmdeleteImage" onClick={() => deleteingredientHandler(data.elementid, data.idkey)} title={titleDeleteFromProduct} />
</button>
<div className="xmingredcommentWrapper">
 {data.idkey>0&&expandedRow === index && (<div className="xmanimateddivCreateProduct">
    <EditProduct getCreateProductNew={getCreateProductNew}addamountHandler={addamountHandler} 
            titleIncreaseAmount={titleIncreaseAmount} titleDecreaseAmount={titleDecreaseAmount}
            
    />
</div>
  )}
</div></div>
)}
{!getChosenProductid?<SaveButton getCreateProductNew={getCreateProductNew} getShowSaveButton={getShowSaveButton} saveRecepyHandler={saveRecepyHandler} 
titleSaveMenuOpenButton={titleSaveMenuOpenButton}
getButtonSaveActive={getButtonSaveActive}/>:getChosenProductid>0?<SaveButtonOldItem getCreateProductNew={getCreateProductNew} getShowSaveButton={getShowSaveButton} saveRecepyHandler={saveRecepyHandler} 
getButtonSaveActive={getButtonSaveActive} SaveProductHandler={SaveProductHandler}
titleKlickToSave={titleKlickToSave}/>:''}




{getSaveRecepyMenu===true&&getChosenProductid<1&&getCountry!=='ja'?<SaveRecepyMenu getCreateProductNew={getCreateProductNew} getCategories={getCategories}
                              getSaveRecepyMenu={getSaveRecepyMenu} getProductName={getProductName}
                              categoryHandler={categoryHandler} getSaveCategory={getSaveCategory}
                              SaveProductHandler={SaveProductHandler} productNameHandler={productNameHandler}
                              saveProduktBoxMenuHeader={saveProduktBoxMenuHeader}
                              namePlaceHolderText={namePlaceHolderText}
                              leftSelectFirstOption={leftSelectFirstOption}
                              saveProduktButtonText={saveProduktButtonText}
                              />:''}

{getSaveRecepyMenu===true&&getChosenProductid<1&&getCountry==='ja'?<SaveRecepyMenuJAP getCreateProductNew={getCreateProductNew} getCategories={getCategories}
                              getSaveRecepyMenu={getSaveRecepyMenu} getProductName={getProductName}
                              categoryHandler={categoryHandler} getSaveCategory={getSaveCategory}
                              SaveProductHandler={SaveProductHandler} productNameHandler={productNameHandler}
                              saveProduktBoxMenuHeader={saveProduktBoxMenuHeader}
                              namePlaceHolderText={namePlaceHolderText}
                              leftSelectFirstOption={leftSelectFirstOption}
                              saveProduktButtonText={saveProduktButtonText}
                              setFuriganaProductNameHandler={setFuriganaProductNameHandler}
                              getFuriganaName={getFuriganaName}
                              />:''}





</div>


    </div>

  </div>
</div>

</div>
<div className="xmwindowAreaC">
    <div className="xmareaCTop">
      <div className="xmareacFieldsetWrapper">
        <fieldset className={getCountry!=='ja'?"xmareaCFieldset":"xmareaCFieldsetJAP"}><legend className="xmareaCLegend">{rightHeader}</legend>
<Produktkalkylinfo getCreateProductNew={getCreateProductNew} getProductRows={getProductRows} getpriceInfo={getpriceInfo}
  totalCostLegend={totalCostLegend}
  prisHeaderLegend={prisHeaderLegend}
  marginalHeaderLegend={marginalHeaderLegend}
/>

<NutrientInfo getCreateProductNew={getCreateProductNew} getProductRows={getProductRows}

nutrientHeaderLegend={nutrientHeaderLegend}
caloriesHeaderLegend={caloriesHeaderLegend}
proteinHeaderLegend={proteinHeaderLegend}
fatHeaderLegend={fatHeaderLegend}
carbonHeaderLegend={carbonHeaderLegend}
/>
        </fieldset>
        </div>

    </div>
    <div className="xmareaBBottom">
      <div className="xmtrashContainer">
        <BsTrash className={getTrashIsHovered===false?"xmTrash":"xmTrashHovered"} title={titleTrash}
        onMouseEnter={()=>setTrashIsHoveredHandler()} onMouseLeave={()=>setTrashIsHoveredHandler()}
        onClick={()=>deleteProductHandlerContainer()}/>
        </div>
    </div>
</div>


</div>
<div className="xmproductnewSpinnerContainer">
        <div className="xmproductNewSpinner">
          <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="0.75" width="60" visible={getSpinnerLoading}/>
        </div>
        {getSpinnerLoading===true?
        <p className="xvspinner-text">Fetching and calculating your product..</p>
        :''}
        </div>
        {getShowAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}/>:''}
        {getShowYesNoAlert===true?<YesNoAlert message={getYesNoAlertMessage}  onCloseYes={handleCloseAlertYes} onCloseNo={handleCloseAlertNo}
        btnYes={btnYes} btnNo={btnNo}
        />:''}

{getShowYesNoAlertImage===true?<YesNoAlert message={getYesNoAlertMessageImage}  onCloseYes={handleCloseAlertYesImage} onCloseNo={handleCloseAlertNoImage}
        btnYes={btnYes} btnNo={btnNo}
        />:''}
      
  </div>
</div>
  </>
  )
}


function EditProduct(props){
    let{getCreateProductNew,addamountHandler,
        titleIncreaseAmount,titleDecreaseAmount
    }=props

    return(

        <div className="xmeditproductTop">
        <div className="xmeditproductArea">
      
            <div className="xmplussign" onClick={()=>addamountHandler(1)} onDoubleClick={()=>addamountHandler(3)} title={titleIncreaseAmount}><AiOutlinePlusCircle className="xmAiOutlinePlusCircle"/></div>
   
        <div className="xmminussign" onClick={()=>addamountHandler(2)} onDoubleClick={()=>addamountHandler(4)} title={titleDecreaseAmount}><AiOutlineMinusCircle  className="xmAiOutlineMinusCircle"/></div></div></div>
    
    )
}

function Nameheader(props){
    let{getCreateProductNew,getProductName,productNameHandler,

    getShowFurigana,setFuriganaNameHandler,getFuriganaName,

    getIpadIsUsed,getMobileIsUsed,setShowFurigana,getCountry,headerName
    }=props



    // let setClassName=''
    // if(getMobileIsUsed===true){
    //   setClassName='xmcreateProductJAPMobile'
    // }else if(getIpadIsUsed===true){
    //   setClassName='xmcreateProductJAPIPAD'
    // }
    // else{
    //   setClassName='createProductJAPWindows'
    // }


    return(

        <div className="xmareaBHeaderTop">
           <input type="text" value={getProductName} 
           onChange={(e)=>productNameHandler(e.target.value)}
           onKeyPress={(e) => {
            if (e.key === 'Enter') {
              setShowFurigana(false);
            }
          }} 
           className="xmnameHeaderInput" 
           placeholder={headerName}/>
          
           {getShowFurigana===true&&getCountry==='ja'?
          <input type="text" className="xmcreateProductJAPMobile"
          value={getFuriganaName}
          onChange={(e)=>setFuriganaNameHandler(e.target.value)} placeholder='ふりがな、作成したデータを管理するために使います'
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              setShowFurigana(false);
            }
          }} />
         :''}
           </div>
       

   
   )
}

function SaveButton(props){
    let {getCreateProductNew,getShowSaveButton,saveRecepyHandler,getButtonSaveActive,
        titleSaveMenuOpenButton
    }=props
    return(
        <div className="xmbuttonTop">
        <button className={getButtonSaveActive===false?"xmsaveproductButton":"xmsaveproductButtonActive"} onClick={saveRecepyHandler} disabled={!getButtonSaveActive} title={titleSaveMenuOpenButton}>
        {getButtonSaveActive===true?<HiOutlineSaveAs className="xmsaveButtonIconActive"/>:<HiOutlineSaveAs className="xmsaveButtonIcon"/>}</button>
      </div>
  
    )
}

function SaveButtonOldItem(props){
    let {getCreateProductNew,getButtonSaveActive,
    SaveProductHandler,titleKlickToSave
}=props
    return(
        <div className="xmbuttonTop">
        <button className={getButtonSaveActive===false?"xmsaveOldproductButton":"xmsaveOldproductButtonActive"}  onClick={()=>SaveProductHandler()} disabled={!getButtonSaveActive} title={getButtonSaveActive ?titleKlickToSave:''}>
        {getButtonSaveActive===true?<HiOutlineSave className="xmsaveButtonOldIcon"/>:<HiOutlineSave className="xmsaveButtonOldIcon"/>}</button>
      </div>
  
    )
}
function Produktkalkylinfo(props){
    let {getCreateProductNew,getProductRows,getpriceInfo,
        totalCostLegend,prisHeaderLegend,marginalHeaderLegend
    }=props

let totalPris=0
if (getProductRows) {
  for (let i = 0; i < getProductRows.length; i++) {
    let pris = parseFloat(getProductRows[i].pris);
    if (!isNaN(pris)) {
        totalPris += pris;
    }
  }
}

// Adjusting for floating point precision if needed
totalPris = Math.round(totalPris * 1000) / 1000;



    return(
        <div className="xmproduktkalkylinfoTop">
        <div className="xmproduktkalkylinfoArea">{totalCostLegend}
        <div className="xmproduktTotalkostnad">{totalPris}</div>
        <div className="xmprisPrislistant">{prisHeaderLegend}<div className="xmproduktPris">
            {getpriceInfo&&getpriceInfo>0?getpriceInfo:0}</div></div>
        <div className="xmproduktMarginal">{marginalHeaderLegend}</div>
        <div className="xmproduktMarginalProcent">
        {getpriceInfo && getpriceInfo > 0
  ? Math.round(Math.abs((totalPris / getpriceInfo) * 100 - 100))
  : '0'}%
</div>
            </div></div>
    )


}
function SaveRecepyMenu(props){
let{getCreateProductNew,getSaveRecepyMenu,getProductName,getSaveCategory,
    categoryHandler,getCategories,SaveProductHandler,productNameHandler,
   
    saveProduktBoxMenuHeader,namePlaceHolderText,leftSelectFirstOption,saveProduktButtonText

    }=props
  
 return(
        <div className="xmsaveRecepyMenuTop">
          
    <fieldset className="xmsaveRecepyMenuFieldset">
    <div className="xmsaveLegendWrapper">
      <legend className="xmsaveRecepyMenuLegend">{saveProduktBoxMenuHeader}</legend>
      </div>
        <div className="xmsaveRecepyMenuArea">
            <input type="text" className="xmsaveRecepyMenuInput2" 
            placeholder={namePlaceHolderText} value={getProductName||''} onChange={(e)=>productNameHandler(e.target.value)}/>
           
                 <select className="xmsaveproductselect" onChange={(e)=>categoryHandler(e.target.value)}>
                <option value="0">&nbsp;&nbsp;{leftSelectFirstOption}</option>
           {getCategories.map((data,key)=><option key={key}value={data.kategoriid}>&nbsp;&nbsp;{data.benaemn}</option>)}
    </select>
    <div className="xmsaveRecepyMenuButtonWrapper">
<button className={getSaveCategory&&getSaveCategory>0&&getProductName.length>0?"xmsaveRecepyMenuButtonActive":"xmsaveRecepyMenuButton"}
          onClick={()=>SaveProductHandler()} disabled={!getSaveCategory || getProductName.length === 0}>{saveProduktButtonText}</button>
    </div>
        </div>
 </fieldset>
        </div>
    )
}

function SaveRecepyMenuJAP (props){
  let{getCreateProductNew,getSaveRecepyMenu,getProductName,getSaveCategory,
      categoryHandler,getCategories,SaveProductHandler,productNameHandler,
     saveProduktBoxMenuHeader,namePlaceHolderText,leftSelectFirstOption,saveProduktButtonText,
     setFuriganaProductNameHandler,getFuriganaName


  
      }=props
    
   return(
          <div className="xmsaveRecepyMenuTopJAP">
      <fieldset className="xmsaveRecepyMenuFieldsetJAP">
        <div className="xmsaveLegendWrapper">
        <legend className="xmsaveRecepyMenuLegendJAP">{saveProduktBoxMenuHeader}</legend>
        </div>
          <div className="xmsaveRecepyMenuAreaJAP">
            <input type="text" className="xmsaveRecepyMenuInput2JAP" 
              placeholder={namePlaceHolderText} value={getProductName||''} onChange={(e)=>productNameHandler(e.target.value)}/>
            
            <input type="text" className="xmsaveRecepyMenuFuriganaInputJAP"
              placeholder='ふりがな、作成したデータを管理するために使います' value={getFuriganaName} onChange={(e)=>setFuriganaProductNameHandler(e.target.value)}/>
            
            
            
                   <select className="xmsaveproductselectJAP" onChange={(e)=>categoryHandler(e.target.value)}>
                  <option value="0">&nbsp;&nbsp;{leftSelectFirstOption}</option>
             {getCategories.map((data,key)=><option key={key}value={data.kategoriid}>&nbsp;&nbsp;{data.benaemn}</option>)}
      </select>
      <div className="xmsaveRecepyMenuButtonWrapper">
  <button className={getSaveCategory&&getSaveCategory>0&&getProductName.length>0&&getFuriganaName.length>0?"xmsaveRecepyMenuButtonActiveJAP":"xmsaveRecepyMenuButtonJAP"}
            onClick={()=>SaveProductHandler()} 
            disabled={!getSaveCategory || getProductName.length === 0 ||getFuriganaName.length===0}>{saveProduktButtonText}</button>
</div>
          </div>
   </fieldset>
          </div>
      )
  }
function NutrientInfo(props){
    let {getCreateProductNew,getProductRows,
        nutrientHeaderLegend,caloriesHeaderLegend,proteinHeaderLegend,fatHeaderLegend,
        carbonHeaderLegend
    }=props

    let carbohydratesum=0
    let fatsum=0
    let proteinsum=0
    let kaloriesum=0



    if(getProductRows&&getProductRows.length>0){
    getProductRows.reduce((total,amount)=>total+amount.carbohydratesum,0)
    fatsum=getProductRows.reduce((total,amount)=>total+amount.fatsum,0)
    if(isNaN(fatsum)){
        fatsum=0
    }
    proteinsum=getProductRows.reduce((total,amount)=>total+amount.proteinsum,0)
    if(isNaN(proteinsum)){
        proteinsum=0
    }
    kaloriesum=getProductRows.reduce((total,amount)=>total+amount.kalories,0)
    if(isNaN(kaloriesum)){
        kaloriesum=0
    }
    carbohydratesum=getProductRows.reduce((total,amount)=>total+amount.carbohydratesum,0)
    if(isNaN(carbohydratesum)){
        carbohydratesum=0
    }
    }

    return(
        <div className="xmnutrientInfoTop">
        <div className="xmnutrientInfoAreaHeader">{nutrientHeaderLegend} <FaNutritionix className="xmnutritionMix"/></div>
        <div className="xmnutrientInfoKalorierHeader">{caloriesHeaderLegend} - kcal</div>
        <div className="xmnutrientInfoKalorier">{kaloriesum.toFixed(2)||0}</div>
        <div className="xmnutrientInfoProteinHeader">{proteinHeaderLegend}</div>
        <div className="xmnutrientInfoProtein">{proteinsum.toFixed(2)||0}&nbsp;g</div>
        <div className="xmnutrientInfoFatHeader">{fatHeaderLegend}</div>
        <div className="xmnutrientInfoFat">{fatsum.toFixed(2)||0}&nbsp;g</div>
        <div className="xmnutrientInfoCarbohydrateHeader">{carbonHeaderLegend}</div>
        <div className="xmnutrientInfoCarbohydrate">{carbohydratesum.toFixed(2)}&nbsp;g</div>
        </div>
        
    )

}

function UploadImage(props) {
  let {handleFileChange,uploadImage,error,file,chosenRecepyId,choseImageButtontext,
  choseImageUploadText,recepyMustBeChosenBeforeUploadMessage,getChosenProductid
  } = props;


  return (
  <>
  <div className="xmimageUploadContainer">
  <div className="xmimageUploadWrapper">
      <input type="file" onChange={handleFileChange} id="file"
      accept=".png, .jpg, .jpeg" 
      className="xminputimageUpload"
      />
        <label htmlFor="file" className="xmfileLabel">{choseImageButtontext}</label>
      {error && <div className="xmerror">{error}</div>}
      {file && <div className="xmfileName">{file.name}</div>}

      </div>
    
      <div className="xmbuttonUploadImageWrapper">
      <button onClick={uploadImage} className={error||!file||!getChosenProductid?"xmbuttonUploadImage":"xmbuttonUploadImage1"}
      disabled={error||!file||!getChosenProductid}>{choseImageUploadText}</button>
    </div></div>
    <div className="xmimageUploadMessage">{!getChosenProductid?recepyMustBeChosenBeforeUploadMessage:''}</div>
    </>
  );
}


export {CreateProductNew,EditProduct,Nameheader,SaveButton,Produktkalkylinfo,SaveRecepyMenuJAP,NutrientInfo,SaveButtonOldItem,UploadImage}