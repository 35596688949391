import React,{useEffect,useState} from 'react';
import './HeaderMenu.css'
import { Link, json } from 'react-router-dom';
// import Baka from '../images/baka.jpg';
import useDeviceDetection from '../hooks/useDeviceDetection';
import useHeaderStyle from '../hooks_styles/useHeaderStyle';
import languages from '../AppLanguage/AppLanguage';
import CryptoJS from 'crypto-js';
import bs from '../images/bs.jpeg'
function Header(props){


const {GetUser,handleSignOut,StatusLevel,statusval}=props
const[userCheck,setuserCheck]=useState(false)
const[headerClass,setheaderClass]=useState([{}])
const [imageLink,setImageLink]=useState(localStorage.getItem('image_link')||'')
const[getCountry,setCountry]=useState('se')
const[user,setUser] = useState(0);



useEffect(()=>{
  let country = localStorage.getItem('country')||'se' // this is the country code
  setCountry(country)
},[])

const { isIpad, isAndroid,isWindows } = useDeviceDetection(); // we detect the device type here
const {navbarStylesIpad10th,navbarStylesAndroid,navbarStylesWindows} = useHeaderStyle(getCountry)


useEffect(() => {
  const handleStorageChange = () => {
    setImageLink(localStorage.getItem('image_link') || '');
  };

  window.addEventListener('storage', handleStorageChange);

  // Clean up the event listener when the component unmounts
  return () => {
    window.removeEventListener('storage', handleStorageChange);
  };
}, []);


const checkUser = () => {
  let storedValue = localStorage.getItem('qba');
  let decryptedData = false;
 
  if (storedValue) {
    let bytes  = CryptoJS.AES.decrypt(storedValue.toString(), '202308112023081120230811');
    decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
   
   }else{
      decryptedData=0
   }
  return decryptedData;
};


useEffect(()=>{
  if(isIpad===true){
      setheaderClass(navbarStylesIpad10th) // we set the styles for the device type here
  }else if(isAndroid===true){
      setheaderClass(navbarStylesAndroid)
    }else if(isWindows===true){
      setheaderClass(navbarStylesWindows)
   }else{
      setheaderClass(navbarStylesWindows)

  }
},[isIpad,isAndroid,isWindows,imageLink,userCheck])





const selectedCountry = languages[getCountry]


const {
  headermenuHome,
  headermenuLogout,
  headermenuIngrediensList,
  headermenuRecipeList,
  headermenuProductList,
  headermenuIngredients,
  headermenuIngredientCategories,
  headermenuRecipeCategories,
  headermenuProductCategories,
  headermenuMyRecipes,
  headermenuCreateReceipemanually,
  headermenuCreateRecipeWithAI,
  headermenuCreateProduct,
  headermenuProductStatistics,
  headermenuWorkWithProduction,
  headermenuTotalNumbers,
  headermenuRecipeSummary,
  headermenuIngredientSummary,
  headermenuPriceList,
  headermenuPageSettings,
  lists,
  InfoAboutUs,
  headermenuproducts,
  headermenuproduction,
  headermenurecipe,
  headermenuUserDataPolicy,
  headermenuRecipeIdeas,
  headermenuMyAccount,
  headermenuRecipeScroll
} = selectedCountry.headermenubar||{};


let countryClass=''
if(getCountry==='ja'){
  countryClass='startlinkJAP'
}else{
  countryClass='startlink'
}



   return(

<div className={'navbar'}>
  
    <div className="dropdown">
  <div className="logo">Bakesoft</div>
  </div>

  <div className="dropdown">
    <button style={headerClass.dropbtn}>{headermenuHome}
      <i className="fa fa-caret-down"></i>
    </button>
    <div className="dropdown-content">

    <div style={headerClass.linkStyle}><Link to='/'>{headermenuHome}</Link></div>
    <div style={headerClass.linkStyle}>{checkUser()>0?<Link to='/Logout'>{headermenuLogout}</Link>:''}</div>
    <div style={headerClass.linkStyle}><Link to='/signUp'>{InfoAboutUs}</Link></div>
    </div>
  </div>

  <div className="dropdown">
    <button style={headerClass.dropbtn}>{lists}
      <i className="fa fa-caret-down"></i>
    </button>
    <div className="dropdown-content" style={headerClass.linkStyle}>
    {checkUser()>0?<Link to='/ListIngred'>{headermenuIngrediensList}</Link>:''}
      {checkUser()>0?<Link to='/ListRecept'>{headermenuRecipeList}</Link>:''}
      {checkUser()>1?<Link to='/Listprodukt'>{headermenuProductList}</Link>:''}
      
    </div>
  </div>

   
<div className="dropdown">
    <button style={headerClass.dropbtn}>{headermenuIngredients}
      <i className="fa fa-caret-down"></i>
    </button>
    <div className="dropdown-content" style={headerClass.linkStyle}>
    {checkUser()>0?<Link to='/ingredNew'>{headermenuIngredients}</Link>:''}
      {checkUser()>0?<Link to='/IngredCategoriesNew'>{headermenuIngredientCategories}</Link>:''}
    
      
      {/* <Link to="bake">Bake<Link> */}
      
    </div>
  </div>
  
  <div className="dropdown">
    <button style={headerClass.dropbtn}>{headermenurecipe}
      <i className="fa fa-caret-down"></i>
    </button>
    <div className="dropdown-content" style={headerClass.linkStyle}>
    {/* <Link to='/SearchRecepy'>Arbeta med recept</Link> */}
    {checkUser()>0?<Link to='/RecipeIdeas'>{headermenuRecipeIdeas}</Link>:''}
    {checkUser()>0?<Link to='/SearchRecepyNew2'>{headermenuMyRecipes}</Link>:''}
    
    {checkUser()>0?<Link to='/CreateManRecepyNew'>{headermenuCreateReceipemanually}</Link>:''}
    {checkUser()>0?<Link to='/createnewrecepy'>{headermenuCreateRecipeWithAI}</Link>:''}
    {checkUser()>0?<Link to="/RecepycategoriesNew">{headermenuRecipeCategories}</Link>:''}
  
    </div>
  </div>



  <div className="dropdown">
    <button style={headerClass.dropbtn}>{headermenuproducts}
      <i className="fa fa-caret-down"></i>
    </button>
    <div className="dropdown-content" style={headerClass.linkStyle}>
    {checkUser()>1?<Link to="/CreateproductNew">{headermenuCreateProduct}</Link>:''}  
      {checkUser()>1? <Link to="/ProductcategoriesNew">{headermenuProductCategories}</Link>:''}
      {checkUser()>1?<Link to="/statisticsbyproductNew">{headermenuProductStatistics}</Link>:''}
    </div>
       </div>



 <div className="dropdown">
    <button style={headerClass.dropbtn}>{headermenuproduction}<i className="fa fa-caret-down"></i></button>
    <div className="dropdown-content" style={headerClass.linkStyle}>
    {checkUser()>1?<Link to="/CreateproductionNew">{headermenuWorkWithProduction}</Link>:''}
       {checkUser()>1?<Link to="/TotalProductionNew">{headermenuTotalNumbers}</Link>:''}
       {checkUser()>1?<Link to="/ProdArbSumNew">{headermenuRecipeSummary}</Link>:''}
       {checkUser()>1?<Link to="/IngrediensTotalerNew">{headermenuIngredientSummary}</Link>:''}
  
    </div>
  </div>



 
<div className="dropdown">
    <button style={headerClass.dropbtn}>{headermenuPriceList}
      <i className="fa fa-caret-down"></i>
    </button>
    <div className="dropdown-content" style={headerClass.linkStyle}>
    {checkUser()>1?<Link to="/PriceListNew">{headermenuPriceList}</Link>:''}
       
    </div>

  </div>

  
 
<div className="dropdown" style={headerClass.linkStyle}>
    <button style={headerClass.dropbtn}>{headermenuPageSettings}
      <i className="fa fa-caret-down"></i>
    </button>
    <div className="dropdown-content" style={headerClass.linkStyle}>
       {checkUser()>0?<Link to="/MySettings">{headermenuPageSettings}</Link>:''}
       {checkUser()>0?<Link to="/MyAccount">{headermenuMyAccount}</Link>:''}
       
       {checkUser()>0?<Link to="/UserDataPolicy">{headermenuUserDataPolicy}</Link>:''}
   
    </div>

  </div>


<div className="imageWrapper">
{imageLink.length>1?<div className="userinfo">
  <img src={imageLink} alt="User Profile" style={headerClass.userPicture}/>
  </div>: <img src={bs} alt="User Profile" className="userinfo"/>}
</div>

  


  {/* <button className='signoutButton' onClick={(e) => handleSignOut(e)}>Log out!</button> */}


{/*  
  {statusval>0 && statusval<5 && !GetUser.email?<><img src={Baka} alt="User Profile" className='user-picture' />
  <button className='signoutButton' onClick={(e) => handleSignOut(e)}>Log out!</button></>:''}  */}


</div>





   )

   
}

export default Header;