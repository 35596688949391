
import React,{useEffect,useState} from 'react'
import axios from 'axios'
import {useGetData,usePutData,usePostData,useDeleteData} from '../hooks/useGetData'
import { RotatingLines } from  'react-loader-spinner'
import Alert from '../functions/appAlert'
import useReportErr from '../hooks/useReportErr'
import { IoCreateOutline } from 'react-icons/io5';
import useAutoHideVisibility from '../hooks/useAutohide'
import './RecepyCategoriesNew2.css'
import {useNavigate} from 'react-router-dom';
import useFetchImageUrl from '../hooks/useFetchImageUrl';
import usePageSettings from '../hooks/usePageSettings';
import YesNoAlert from '../functions/YesNoAlert'
import languages from '../AppLanguage/AppLanguage';
import AppError from '../AppLanguage/AppError';
import displayGadgetToSmall from '../functions/displayGadgetToSmall'



export default function RecepyCategoriesNew2() {


  const [getrecepyCategories,setrecepyCategories]=useState([{}])
  const[getrecepyByCategory, setrecepyByCategory]=useState([])
  const[getChosenCategory,setChosenCategory]=useState(0)
  const[getcategoryName,setcategoryName]=useState('')
  const[getingredId,setingredId]=useState(0)
  const[getMessage,setMessage]=useState('')
  const[getdelButton,setdelButton]=useState(0)
  const[getviewDeleteCategory,setviewDeleteCategory]=useState(0)
  const[ getviewDeleteMessage, setviewDeleteMessage]=useState(0)
  const[getHighestCategoryId,setHighestCategoryId]=useState(0)
  const[getSpinnerLoading,setSpinnerLoading]=useState(false)

  const [getShowAlert,setShowAlert] = useState(false);
  const [getAlertMessage,setAlertMessage] = useState({});
  const[getAlertCode,setAlertCode]=useState(0)
  const[getDraggedItem,setDraggedItem]=useState({receptid:'',benaemn:'',kategoriId:''})
  const[getDroppedCategory,setDroppedCategory]=useState(0)
  const[getDraggedCategory,setDraggedCategory]=useState({kategoriId:''})
  const[getViewCreateCategory,setViewCreateCateory]=useState(false)
  const[getShowYesNoAlert,setShowYesNoAlert]=useState(false)
  const[getYesNoAlertMessage,setYesNoAlertMessage]=useState('')
  const[getRecepyReadyToMove,setRecepyReadyToMove]=useState(false)
  const[getingredientReadyToMove,setIngredientReadyToMove]=useState(false)
  const[getMoveToCategory,setMoveToCategory]=useState(0)
const [getKanjiName,setKanjiName]=useState('') // this is the kanji name
const [getFuriganaName,setFuriganaName]=useState('') // this is the furigana name

    const[getBackGroundShade,setBackGroundShade]=useState(0.55)
    const[getRenderKey,setRenderKey]=useState(0)
    const[getMenuShade,setMenuShade]=useState(0.75)
    const[getMenuShade2,setMenuShade2]=useState(0.55)
    const [getColorVal,setColorVal]=useState('')
    const [getImageVal,setImageVal]=useState('')
    const [getImageUrl,setImageUrl]=useState('')
    const pageid=9;
    const [logtime]=useState(5000)

  //we use this hook to show the createcategory component
  const [isVisible, setIsVisible] = useAutoHideVisibility(false, 30000);

  const [windowWidth,setWindowWith]=useState(0) // this is the window width
  const [windowHeight,setWindowHeight]=useState(0)  // this is the window height
  const [getMobileIsUsed,setMobileIsUsed]=useState(false) // this is the window height
  const[getCountry,setCountry]=useState('se') // this is the country
   let  displayText=''

   useEffect(()=>{
    let country = localStorage.getItem('country')||'se' // this is the country code
    setCountry(country)
  },[])

  let newFontFamily = '';
  if (getCountry === 'ja') {
     newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
  } else {
    newFontFamily = 'Arial, Helvetica, sans-serif'
    
  //newFontFamily = 'Times New Roman, Times, serif'
  //newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
  }
  document.documentElement.style.setProperty('--font-family', newFontFamily);


  const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal } = usePageSettings(pageid);
  const { imageUrl, alertMessage, alertCode, showAlert } = useFetchImageUrl(getImageVal); // runs at mount, gives ut the image url and fetch image from server
  const navigate = useNavigate();



  useEffect(() => {
    function setSize() {
      setWindowWith(window.innerWidth);
      setWindowHeight(window.innerHeight);
    
      if(window.innerWidth < 600){
  
        setMobileIsUsed(true)
      } 
  
    }

  
    // Call setSize initially to set the correct state
    setSize();
  
    // Add the event listener
    window.addEventListener('resize', setSize);
  
    // Return a cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', setSize);
    };
  }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]); // Dependencies


  function rgbToRgba(rgb, alpha) {
    return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
  }
  
//--bakcground shade settings
let rgbaVal
let rgbaVal2
let val

if(getColorVal && getColorVal.length > 0){
  let rgb = getColorVal;
  let alpha = getMenuShade; // replace with your alpha value
  let alpha2 = getMenuShade2; // replace with your alpha value
  rgbaVal = rgbToRgba(rgb, alpha);
  rgbaVal2 = rgbToRgba(rgb, alpha2);
  val=`rgba(255, 255, 255, ${getBackGroundShade})`

  }else{
    rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
    rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
    val=`rgba(255, 255, 255, ${getBackGroundShade})`
  }
//---

useEffect(() => {
    let root = document.documentElement;
    root.style.setProperty('--rgbaVal', rgbaVal);
    root.style.setProperty('--rgbaVal2', rgbaVal2);
    root.style.setProperty('--val',val);
    root.style.setProperty('--windowHeight',windowHeight+'px');

    
  }, [getBackGroundShade,getMenuShade,getMenuShade2]);


    // language settings
    const selectedCountry = languages[getCountry];
  
     let title=''
    if(getMobileIsUsed===false){
      title=selectedCountry.recepycategories?.title
    }else{
      title=selectedCountry.recepycategories?.titleMobile
    }
  let textmessage=''
   if(getCountry==='en'){
    textmessage='Welcome to recipe categories, select a category, select a recipe, move to another category, remove category by double clicking category, if a category has recipes, they are moved to category other and the category is removed. Create new category by pressing the create icon.'
   }else if(getCountry==='se'){
    textmessage='Välkommen till recept kategorier, välj en kategori, välj ett recept, flytta till annan kategori, ta bort kategori genom att dubbelklicka på kategori, om en kategori har recept flyttas dom till kategori övrigt och kategori tas bort. Skapa ny kategori genom att trycka på ikonen skapa'
    }else if(getCountry==='ja'){
      textmessage='レシピのカテゴリーへようこそ！カテゴリーを「選択」し、レシピを「選択」し、別のカテゴリーへ「移動」することができます。カテゴリーを削除するときはダブルクリックしてください。もしも削除するカテゴリーにレシピがある場合は、「その他」のカテゴリーに移動し、対象のカテゴリーは削除されます。新たなカテゴリーを作成するときは、「作成」ボタンを押してください。'
   
    }
    displayText=textmessage

    const categoryHeaderKategori=selectedCountry.ingredcategories?.categoryHeaderKategori
    const  categoryHeaderAntal=selectedCountry.ingredcategories?.categoryHeaderAntal
    const categoryMoveIngredLegend=selectedCountry.ingredcategories?.categoryMoveIngredLegend
    const categorySelectFirstOption=selectedCountry.ingredcategories?.categorySelectFirstOption
    const categoryButtonMoveSubmit=selectedCountry.ingredcategories?.categoryButtonMoveSubmit
    const categoryCreateCategorySubmit=selectedCountry.ingredcategories?.categoryCreateCategorySubmit
   
// error messages
const selectedError=AppError[localStorage.getItem('country')||'en']
  
const{
  categoryDeleted,
  categoryDeleteQuestion,
  categoryNameWrite,
  categoryCreated,
  btnYes,
  btnNo,
  categoriesVoid,
}=selectedCountry.confirmationMessages||{};

  useEffect(() => {
    GetSettings();
  }, [GetSettings]);

  // we set the image url here
  useEffect(() => {
    setImageUrl(imageUrl);
    if (alertCode > 0) {
      setAlertMessage(alertMessage);
      setAlertCode(alertCode);
      setShowAlert(showAlert);
    }
  }, [imageUrl]);

  // here we set the color values and image values
  useEffect(() => {
    setBackGroundShade(backGroundShade);
    setMenuShade(menuShade);
    setMenuShade2(menuShade2);
    setColorVal(colorVal);
    setImageVal(imageVal);
  }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);





useEffect(()=>{

  async function GetRecepyCategories(){
    setSpinnerLoading(true)
   try{
    let response= await useGetData(`getRecepyCategories`)
 
    setrecepyCategories(response.data[0])
    setSpinnerLoading(false)
   }catch(error){
    setAlertCode(30001)
    setAlertMessage(selectedError[30001])
    setSpinnerLoading(false)
    setShowAlert(true)
 
    return;
   }

}
GetRecepyCategories()
},[])

const RunReportErrHandler = async (errCode) => {
  setSpinnerLoading(true);
  await useReportErr('ErrReport',{errCode});
  setSpinnerLoading(false);
};

useEffect(() => {
  if (getAlertCode > 0) {
    RunReportErrHandler(getAlertCode);
  }
}, [getAlertCode]);


const handleCloseAlert = () => {
  setShowAlert(false);
};
const handleCloseAlertYes = () => {
  setShowYesNoAlert(false);
  DeleteRecepyCategory()
};
const handleCloseAlertNo = () => {
  setShowYesNoAlert(false);
};

async function DeleteRecepyCategory(){
  setSpinnerLoading(true)
  try{
    let response=await useDeleteData(`checkRecepyItemsInCategoryAndMove/${getChosenCategory}`)
    if(response.status===200){
      setSpinnerLoading(false)
      setAlertCode(0)
      setAlertMessage(categoryDeleted)
      setShowAlert(true)
        // we need to update the state and deltet the category from the state
        let newObjArr=[...getrecepyCategories]
        let newObjArrIndex=newObjArr.findIndex(data=>data.kategoriId==getChosenCategory)
        //drop index by splice
        newObjArr.splice(newObjArrIndex,1)
        //update state
        setrecepyCategories(newObjArr)
    }
  }catch(error){
    setAlertCode(30009)
    setAlertMessage(selectedError[30009])
    setSpinnerLoading(false)
    setShowAlert(true)
    return;
  }
}


const deleteCategoryHandlerContainer=(kategoriId)=>{
  //here we want to find the name of the category
  let newObjArr=[...getrecepyCategories]
  let newObjArrIndex=newObjArr.findIndex(data=>data.kategoriId==kategoriId)
  let kategoriname=newObjArr[newObjArrIndex].Benaemn
  setYesNoAlertMessage(`${categoryDeleteQuestion} ${kategoriname}?`)
  setShowYesNoAlert(true)
}
//start here again
const categoryHandler=()=>{

  if(getcategoryName.length>0){

    async function InsertnewCategory(){
      setSpinnerLoading(true)
 try{

  let response= await usePostData(`insertNewRecepyCategory`,{getcategoryName})
  if(response.status===200){
    setrecepyCategories(response.data[0])
    setSpinnerLoading(false)
    setAlertCode(0)
    setAlertMessage(categoryCreated)
    setShowAlert(true)
  }
  else if(response.status===201){
    setAlertCode(0)
    setAlertMessage(categoriesVoid)
    setSpinnerLoading(false)
    setShowAlert(true)
  
  }
 } catch(error){
  setAlertCode(30002)
  setAlertMessage(selectedError[30002])
  setSpinnerLoading(false)
  setShowAlert(true)

  return;
 }
    
  }
  InsertnewCategory()
  setcategoryName('')
}else{
  setAlertCode(0)
  setAlertMessage(categoryNameWrite)
  setShowAlert(true)
}
}



const datainfoHandler=(category)=>{
  setRecepyReadyToMove(false) // here we reset the ingredient ready to move
  setMoveToCategory(0) // here we reset the move to category
  async function GetRecepyByCategory(category){
    setSpinnerLoading(true)
    try{
      let response=await useGetData(`recepybycategory/${category}`)
      setrecepyByCategory(response.data[0])
      setSpinnerLoading(false)
      setIsVisible(false)
    }catch(error){
  setAlertCode(30003)
  setAlertMessage(selectedError[30003])
  setSpinnerLoading(false)
  setShowAlert(true)


  return;
    }
   }
   GetRecepyByCategory(category)

  setdelButton(0)
  setChosenCategory(category)
  if(getviewDeleteCategory==0){
  setviewDeleteCategory(1)
 // setviewDeleteCategory(0)
  
  }else{
    setviewDeleteCategory(0)
  }
  
  
  }
  


     const setViewCreateCateoryHandler=()=>{
      setKanjiName('')
      setFuriganaName('')
        if(getViewCreateCategory===false){
          setIsVisible(true)
          setViewCreateCateory(true)
        }else{
          setViewCreateCateory(false)
        }
      }

      
   // if the user changges the value on the range control we save the value in the database
async function SetBackGroundShadeHandler(val){
  setBackGroundShade(val)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateBackGroundShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }
  }catch(error){
      setAlertCode(30007)
      setAlertMessage(selectedError[30007])
      setSpinnerLoading(false)
      setShowAlert(true)
    
      return;
    }

}

async function SetmenuColorHandler(val){
  setMenuShade(val)
  setMenuShade2(val-0.20)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateMenuShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }

}catch(error){
  setAlertCode(30008)
  setAlertMessage(selectedError[30008])
  setSpinnerLoading(false)
  setShowAlert(true)

  return;
}
}

const setChosenRecepyHandler=(receptid)=>{
  // first we will remove this item from the list of ingredients getingredByCategory
  let newObjArr=[...getrecepyByCategory]
  let newObjArrIndex=newObjArr.findIndex(data=>data.receptid==receptid)
  //we will not drop we will add a new property to the object and we will toggle this property
  if(newObjArr[newObjArrIndex].readyTomove==1){
    newObjArr[newObjArrIndex].readyTomove=undefined
  }else{
    newObjArr[newObjArrIndex].readyTomove=1
  }
  //update state
  setrecepyByCategory(newObjArr)
  }

// here i watn to check if we have any recipes that are ready to move
useEffect(()=>{
  let newObjArr=[...getrecepyByCategory]
  let newObjArrIndex=newObjArr.findIndex(data=>data.readyTomove==1)
  if(newObjArrIndex>-1){
    setRecepyReadyToMove(true)
  }
}
,[getrecepyByCategory])

const moveToCategoryHandler=(val)=>{
  setMoveToCategory(val)
}


function moveRecepyToNewCategory(moveToCategory){
  // first we must identify the ingredients ready to move
  let newObjArr=[...getrecepyByCategory]
  // so we filter out the ingredients ready to move and we only need the ingredid

  let newObjArrReadyToMove=newObjArr.filter(data=>data.readyTomove==1)
  //take out all the receptid from this object
  let recepyArr=newObjArrReadyToMove.map(data=>data.receptid)

  
  // first we update the database
  let decodedRecepyData=btoa(JSON.stringify(recepyArr))
  async function UpdateRecepyCategory(){
    setSpinnerLoading(true)
    try{
      let response=await usePutData(`moveRecepyToCategory2`,{decodedRecepyData,moveToCategory,getChosenCategory})
      if(response.status===200){
        setSpinnerLoading(false)
        setAlertCode(0)
        setAlertMessage(`Ingredienser har flyttats till vald kategori`)
        setShowAlert(true)
      }
    }catch(error){
      setAlertCode(30010)
      setAlertMessage(selectedError[30010])
      setSpinnerLoading(false)
      setShowAlert(true)
   
    }
  }
  UpdateRecepyCategory()

  // then we update the state
  // we filter out the recepies not ready to move
  let newObjArrNotReadyToMove=newObjArr.filter(data=>data.readyTomove==undefined)
  // we update the state
  setrecepyByCategory(newObjArrNotReadyToMove)
  // we update the category object
  let newObjCategory=[...getrecepyCategories]
  // find the index of the moveToCategory
  let moveToCategoryIndex=newObjCategory.findIndex(data=>data.kategoriId==moveToCategory)
  //here we need to update the object property antal on the moveToCategory
  // we update antal
  let antal=newObjCategory[moveToCategoryIndex].antal
  // we add as many as we have in newObjArrReadyToMove
  antal=antal+newObjArrReadyToMove.length
  // we update the antal
  newObjCategory[moveToCategoryIndex].antal=antal
  //here we need to update the object property antal on the from category
  //find the index of the from category
  let fromCategoryIndex=newObjCategory.findIndex(data=>data.kategoriId==getChosenCategory)
  // we update antal with - as many as we have in newObjArrReadyToMove
  let antal1=newObjCategory[fromCategoryIndex].antal
  // we minus as many as we have in newObjArrReadyToMove
  antal1=antal1-newObjArrReadyToMove.length
  // we update the antal
  newObjCategory[fromCategoryIndex].antal=antal1
  //update state
  setrecepyCategories(newObjCategory)
  //clean up
  setRecepyReadyToMove(false)
  setMoveToCategory(0)

}

const setKanjiNameHandler=(val)=>{
  setKanjiName(val)
}

const setFuriganaNameHandler=(val)=>{
  setFuriganaName(val)
}

const createCategoryJAPHandler=()=>{
  CreateJAPCategory()
}

async function CreateJAPCategory(){
  setSpinnerLoading(true)
  try{
    let response=await usePostData(`insertNewRecepyCategoryJAP`,{getKanjiName,getFuriganaName})
    if(response.status===200){
    setrecepyCategories(response.data[0])
      setSpinnerLoading(false)
      setAlertCode(0)
      setAlertMessage(categoryCreated)
      setShowAlert(true)
      setViewCreateCateory(false)
    }else if(response.status===201){
      setAlertCode(0)
      setAlertMessage(categoriesVoid)
      setSpinnerLoading(false)
      setShowAlert(true)
    }
    
  }
  catch(error){
    setAlertCode(30011)
    setAlertMessage(selectedError[30011])
    setSpinnerLoading(false)
    setShowAlert(true)
   
  }
}

let catnew=[{}]


      return (
        <>
        {windowWidth<360?displayGadgetToSmall():''}
        <div className={getBackGroundShade==1?"xgtotalArea1":"xgtotalArea"}>
  {getBackGroundShade==1?'':<img src={imageUrl} alt="Bakesoft" className="xgbackgroundImageRecepyNew" />}
    
  <div className="xgheaderArea">
    {getCountry!=='ja'?
  <div className={getBackGroundShade<1?"xgcompName":"xgcompName1"}>{title}</div>
  :<div className={getBackGroundShade<1?"xgcompNameJAP":"xgcompNameJAP1"}>{title}</div>}
  </div>
  <div className="xgrangeArea">
  <input type="range" min="0.0" max="1" value={getMenuShade||0.2} step="0.10" id={1}  
    title="Justera menyfärg" className="xgrange2" onChange={(e)=>SetmenuColorHandler(e.target.value)}/>

    <input type="range" min="0.0" max="1" value={getBackGroundShade||0.2} step="0.10" id={2}  
    title="Justera bakgrund" className="xgrange1" onChange={(e)=>SetBackGroundShadeHandler(e.target.value)}/>
    
  </div>


        <div className="xgareaA">

        <div className="xgCategoryDatalist">
        <div className="xgCategoryTop">
     <button className="xgBenaemnHeader">{categoryHeaderKategori}</button>
     <button className="xgAntalHeader">{categoryHeaderAntal}</button>

<div className="xgrowsdata">
{getrecepyCategories.map((data,index)=>
  <div key={index}   className="xgrows">
<button 
className={index%2===0?"xgdataBenaemn":"xgdataBenaemn1"}  key={index} 
onClick={()=>datainfoHandler(data.kategoriId)}
onDoubleClick={()=>deleteCategoryHandlerContainer(data.kategoriId)}
>{data.Benaemn}</button>

<button className={index%2===0?"xgdataAntal":"xgdataAntal1"}>{data.antal}</button>
</div>

)}  
</div>

</div>
</div>

</div>


<div className="xgareaB">
<div className="xgcreatecatTop">
        <IoCreateOutline  title={categoryCreateCategorySubmit} onClick={()=>setViewCreateCateoryHandler()} className={getBackGroundShade==1?"xgcreateCat1":"xgcreateCat"}/>

{getViewCreateCategory===true&&isVisible&&getCountry!=='ja'?<AddCategory categoryHandler={categoryHandler} catnew={catnew}
setcategoryName={setcategoryName} getcategoryName={getcategoryName}
categoryCreateCategorySubmit={categoryCreateCategorySubmit}
/>:''}

{getViewCreateCategory===true&&isVisible&&getCountry==='ja'?<AddCategoryJAP 
catnew={catnew} setKanjiNameHandler={setKanjiNameHandler} 
setFuriganaNameHandler={setFuriganaNameHandler} createCategoryJAPHandler={createCategoryJAPHandler}
getKanjiName={getKanjiName} getFuriganaName={getFuriganaName} categoryCreateCategorySubmit={categoryCreateCategorySubmit}
/>:''}
</div>

<div className="xgrightdataArea">
{
  getrecepyByCategory.length >0
    ? getrecepyByCategory
    .filter(data=>data.readyTomove===undefined)
        .map((data, key) => (
          <div  
            className={key % 2 === 0 ?"xgcategoryrightButton1":"xgcategoryrightButton"} 
            key={key}
          >
            <div 
              className="xgcategoryrightButtontext"
              onClick={() => setChosenRecepyHandler(data.receptid)}
            >
              {data.benaemn}
            </div>
          </div>
        ))
    : ''
}

</div>
{getRecepyReadyToMove===true?
<div className="xgreceptmoveitemTop">
<fieldset className="xgreceptmoveitemFieldset">
  <legend className="xgreceptmoveitemLegend">{categoryMoveIngredLegend}</legend>

{
  getrecepyByCategory.length > 0 && getRecepyReadyToMove === true
    ? getrecepyByCategory
        .filter(data => data.readyTomove == 1)
        .map((data, key) => (
          <div  
            className={key % 2 === 0 ?"xgcategoryrightButton1":"xgcategoryrightButton"} 
            key={key}
          >
            <div 
              className="xgcategoryrightButtontext"
              onClick={() => setChosenRecepyHandler(data.receptid)}
              >
              {data.benaemn}
            </div>
          </div>
        ))
    : ''
}
</fieldset>
<div className="xgingredmoveTop">
<select className="xgmoveitemSelect" onChange={(e)=>moveToCategoryHandler(e.target.value)}>
<option value="0">&nbsp;{categorySelectFirstOption}</option>
{getrecepyCategories.map((data,index)=>
<option key={index} value={data.kategoriId}>&nbsp;{data.Benaemn}</option>
)}
</select>

<button className={getMoveToCategory>0?"xgmoveitemSaveButton":"xgmoveitemSaveButton1"} disabled={getMoveToCategory===0}
onClick={()=>moveRecepyToNewCategory(getMoveToCategory)}
>{categoryButtonMoveSubmit}</button>
</div>
</div>
:''}




            
        </div>
        <div className="xgareaC">
        {getMobileIsUsed===false?
        <div className="xgtextAreaMessage">
         <TextArea displayText={displayText} cols={55} rows={30}/></div>
          :''}
            </div>
    
        
        <div className="xgSpinnerContainer">
        <div className="xgSpinner">
          <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="0.75" width="60" visible={getSpinnerLoading}/>
        </div>
        </div>
        {getShowAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}/>:''}
        {getShowYesNoAlert===true?<YesNoAlert message={getYesNoAlertMessage}  onCloseYes={handleCloseAlertYes} onCloseNo={handleCloseAlertNo}
        btnYes={btnYes} btnNo={btnNo}
        />:''}
 
        
        
        
        </div>
        </>
      )

}


function TextArea(props){
    let displayText=props.displayText
    return(
      <div>
  
      <textarea
      value={displayText}
    
       readOnly
       className="xgtextAreaMessage"
      ></textarea>
      </div>
    )
  }
  
  function AddCategory(props){
  
    let setcategoryName=props.setcategoryName
    let getcategoryName=props.getcategoryName
    let categoryHandler=props.categoryHandler
    let catnew=props.catnew
    let categoryCreateCategorySubmit=props.categoryCreateCategorySubmit
  
    return(
      <div className="xgingredcatcreateCategoryTop">
      <input onChange={(e)=>setcategoryName(e.target.value)} value={getcategoryName}type="text" className="xgingredcatcreateCategoryInput" />
      <input type="button" onClick={()=>categoryHandler()}className={!getcategoryName?"xgingredcatcreateCategoryButton1":"xgingredcatcreateCategoryButton"} value={categoryCreateCategorySubmit}/></div>
    )
  }

  function AddCategoryJAP(props){
    let {categoryCreateCategorySubmit,setKanjiNameHandler,setFuriganaNameHandler,createCategoryJAPHandler,
           getKanjiName,getFuriganaName
   }=props

  
    return(
      <div className="xgCategoryTopJAP">
      <input onChange={(e)=>setKanjiNameHandler(e.target.value)} value={getKanjiName}type="text" 
      className="xgCategoryInputKanji"
      placeholder='カテゴリ名'
      />

      <input onChange={(e)=>setFuriganaNameHandler(e.target.value)} value={getFuriganaName}type="text" 
      className="xgCategoryInputFurigana" placeholder='ふりがな' />
  
      <input type="button" onClick={()=>createCategoryJAPHandler()}className={getKanjiName.length>0&&getFuriganaName.length>0?"xgcreateCategoryButtonJAP":"xgcreateCategoryButtonJAP1"} 
      value={categoryCreateCategorySubmit}
      disabled={getKanjiName.length>0&&getFuriganaName.length>0?false:true}
      /></div>
    )
  }

export {RecepyCategoriesNew2,TextArea,AddCategory,AddCategoryJAP}