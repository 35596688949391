import React,{useState,useEffect} from 'react'
import {useGetData,usePutData} from '../hooks/useGetData';
import './PriceListNew1.css'
import{AiOutlinePlusCircle} from 'react-icons/ai'
import{AiOutlineMinusCircle} from 'react-icons/ai'
import { RotatingLines } from 'react-loader-spinner';
import Alert from '../functions/appAlert';
import useReportErr from '../hooks/useReportErr';
import {useNavigate} from 'react-router-dom';
import useFetchImageUrl from '../hooks/useFetchImageUrl';
import usePageSettings from '../hooks/usePageSettings';
import languages from '../AppLanguage/AppLanguage';
import AppError from '../AppLanguage/AppError';
import displayGadgetToSmall from '../functions/displayGadgetToSmall'


function PriceListNew() {

const[getlist,setlist]=useState([{}])
const[getchosenCategory,setchosenCategory]=useState(0)
const[getpriceDataCategories,setpriceDataCategories]=useState([{}])
const[getCalculatedPriceandCost,setCalculatedPriceandCost]=useState([{}])
const [getSpinnerLoading, setSpinnerLoading] = useState(false)
const [averagePercentage, setAveragePercentage] = useState(0);
const[expandedRow,setExpandedRow]=useState(null)
const[getChosenProductId,setChosenProductId]=useState(0)
const [getShowAlert,setShowAlert] = useState(false);
const [getAlertMessage,setAlertMessage] = useState({});
const[getAlertCode,setAlertCode]=useState(0)
const[getBackGroundShade,setBackGroundShade]=useState(0.70)
const[getRenderKey,setRenderKey]=useState(0)
const[getMenuShade,setMenuShade]=useState(0.85)
const[getMenuShade2,setMenuShade2]=useState(0.65)
const [getColorVal,setColorVal]=useState('')
const [getImageVal,setImageVal]=useState('')
const [getImageUrl,setImageUrl]=useState('')
const[getBenaemnSort,setBenaemnSort]=useState(false)
const[getSortHandlerPris,setSortHandlerPris]=useState(false)
const[getSortHandlerMargin,setSortHandlerMargin]=useState(false)

const pageid=17
const [logtime]=useState(5000)


const [windowWidth,setWindowWith]=useState(0) // this is the window width
const [windowHeight,setWindowHeight]=useState(0)  // this is the window height
const [getMobileIsUsed,setMobileIsUsed]=useState(false) // this is the window height
const[getCountry,setCountry]=useState('se') // this is the country




let color='white'
var mpercentage = 100;

useEffect(()=>{
  let country = localStorage.getItem('country')||'en' // this is the country code
  setCountry(country)
},[])

let newFontFamily = '';
if (getCountry === 'ja') {
   newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
} else {
  newFontFamily = 'Arial, Helvetica, sans-serif'
  
//newFontFamily = 'Times New Roman, Times, serif'
//newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
}
document.documentElement.style.setProperty('--font-family', newFontFamily);
    
    

    const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal } = usePageSettings(pageid);
    const { imageUrl, alertMessage, alertCode, showAlert } = useFetchImageUrl(getImageVal); // runs at mount, gives ut the image url and fetch image from server
  
 let navigate = useNavigate();





 useEffect(() => {
  function setSize() {
    setWindowWith(window.innerWidth);
    setWindowHeight(window.innerHeight);
  }

  // Set initial window size
  setSize();

  // // Update window size when the window is resized
  // window.addEventListener('resize', setSize);

  // // Remove event listener when the component unmounts
  // return () => window.removeEventListener('resize', setSize);
}, []);

useEffect(() => {
  function setSize() {
    setWindowWith(window.innerWidth);
    setWindowHeight(window.innerHeight);
  
    if(window.innerWidth < 600){

      setMobileIsUsed(true)
    } 

  }


  // Call setSize initially to set the correct state
  setSize();

  // Add the event listener
  window.addEventListener('resize', setSize);

  // Return a cleanup function to remove the event listener when the component unmounts
  return () => {
    window.removeEventListener('resize', setSize);
  };
}, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]); // Dependencies

function rgbToRgba(rgb, alpha) {
  return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
}

//--bakcground shade settings
let rgbaVal
let rgbaVal2
let val

if(getColorVal && getColorVal.length > 0){
let rgb = getColorVal;
let alpha = getMenuShade; // replace with your alpha value
let alpha2 = getMenuShade2; // replace with your alpha value
rgbaVal = rgbToRgba(rgb, alpha);
rgbaVal2 = rgbToRgba(rgb, alpha2);
val=`rgba(255, 255, 255, ${getBackGroundShade})`

}else{
  rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
  rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
  val=`rgba(255, 255, 255, ${getBackGroundShade})`
}
//---

useEffect(() => {
  let root = document.documentElement;
  root.style.setProperty('--rgbaVal', rgbaVal);
  root.style.setProperty('--rgbaVal2', rgbaVal2);
  root.style.setProperty('--val',val);
  root.style.setProperty('--windowHeight',windowHeight+'px');

  
}, [getBackGroundShade,getMenuShade,getMenuShade2]);


    // language settings
    const selectedCountry = languages[getCountry];
  
     let title=''
    if(getMobileIsUsed===false){
      title=selectedCountry.pricelist?.title
    }else{
      title=selectedCountry.pricelist?.titleMobile
    }


      const {
        titleMobile,
        mainSelectLegend,
        mainSelectFirstOption,
        mainHeaderPid,
        mainHeaderItemNumber,
        mainHeaderProductName,
        mainHeaderPrice,
        mainHeaderMargin,
        mainHeaderInclusiveVAT,
        mainHeaderSave,
        mainHeaderSaved,
        titleIncreaseBy10,
        titleIncreaseBy1,
        titleIncreaseBy01,
        titleDecreaseBy10,
        titleDecreaseBy1,
        titleDecreaseBy01,
        titleName,
        titlePrice,
        titleMargin,
        rightHeaderLegend,
        titleAdjustMenu,
        titleAdjustMenu1,
        titleMobileMargin,
        titleMargin1,
        titleMobileVat,
        titleVat,
        noNameGiven,
      } = selectedCountry.pricelist||{};

// error messages
const selectedError=AppError[localStorage.getItem('country')||'en']
 

      useEffect(() => {
        GetSettings();
      }, [GetSettings]);

      // we set the image url here
      useEffect(() => {
        setImageUrl(imageUrl);
        if (alertCode > 0) {
          setAlertMessage(alertMessage);
          setAlertCode(alertCode);
          setShowAlert(showAlert);
        }
      }, [imageUrl]);
    
      // here we set the color values and image values
      useEffect(() => {
        setBackGroundShade(backGroundShade);
        setMenuShade(menuShade);
        setMenuShade2(menuShade2);
        setColorVal(colorVal);
        setImageVal(imageVal);
      }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);

      
useEffect(()=>{
  
    async function GetPriceList(){
        setSpinnerLoading(true)
        try{
      
            let response= await useGetData(`getpriceList`)
             setpriceDataCategories(response.data[1])
             setSpinnerLoading(false)
        
        } catch(error){
            setAlertCode(26001)
            setAlertMessage(selectedError[26001])
            setSpinnerLoading(false)
            setShowAlert(true)
          
            
        }
     
    }

  GetPriceList()

},[])

const RunReportErrHandler = async (errCode) => {
 
    setSpinnerLoading(true);
    await useReportErr('ErrReport',{errCode});
    setSpinnerLoading(false);
  };
  
  useEffect(() => {
    if (getAlertCode > 0) {
      RunReportErrHandler(getAlertCode);
    }
  }, [getAlertCode]);
  
  const handleCloseAlert = () => {
    setShowAlert(false);
  };





useEffect(()=>{

    async function GetPriceListByCategory(){
        setSpinnerLoading(true)
      
        try{
            let responseprice=await useGetData(`productToPricelist/${getchosenCategory}`)
            setCalculatedPriceandCost(responseprice.data[0])
            setSpinnerLoading(false)
        }catch(error){
            setAlertCode(26002)
            setAlertMessage(selectedError[26002])
            setSpinnerLoading(false)
            setShowAlert(true)
           
        }
     
   
    }
    GetPriceListByCategory()

},[getchosenCategory])

const getKatHandler=(category)=>{
    setExpandedRow(null)
setchosenCategory(category)
}

const priceHandler=(produktid,buttonVal)=>{

  // it is here, that when the user clicks the plus or minus button, the price is calculated and updated,
  //but the text on the save button must be changed back to save.
  let myNewObjArr1=[...getCalculatedPriceandCost]
  let newIndex1=myNewObjArr1.findIndex(data=>data.produktid===produktid)
  myNewObjArr1[newIndex1].saved = !myNewObjArr1[newIndex1].saved;
  myNewObjArr1[newIndex1].buttontext = myNewObjArr1[newIndex1].buttontext === mainHeaderSave
  myNewObjArr1[newIndex1].save=false;
  setCalculatedPriceandCost(myNewObjArr1)

// here we calculate the new price
    let myNewObjArr=[...getCalculatedPriceandCost]
    let newIndex=myNewObjArr.findIndex(data=>data.produktid===produktid)
    let oldprice=parseFloat(myNewObjArr[newIndex].pris)

    let newprice=0

    if(buttonVal===1){
        newprice=oldprice+10
    }else if(buttonVal===2){
        newprice=oldprice+1
    }else if(buttonVal===3){
        newprice=oldprice+0.1
    }else if(buttonVal===4){
        newprice=oldprice-10
    }else if(buttonVal===5){
        newprice=oldprice-1
    }else if(buttonVal===6){
        newprice=oldprice-0.1

}

     myNewObjArr[newIndex].pris=newprice
     myNewObjArr[newIndex].save=true
     
     setCalculatedPriceandCost(myNewObjArr)      
    }


        const calculateAveragePercentage = () => {
          const sum = getCalculatedPriceandCost.filter(data=>data.totals >0 && data.pris>0).reduce((accumulator, data) => {
            const percentage = Math.abs((mpercentage / 100) - (data.totals / data.pris)) * 100;
            return accumulator + percentage;
          }, 0);
          return sum / getCalculatedPriceandCost.length;
        };
      
        // Update the state with the calculated average percentage
        useEffect(() => {
          const average = calculateAveragePercentage();
          setAveragePercentage(average);
        }, [getCalculatedPriceandCost]);



const SaveHandler= async (produktid,newprice)=>{

           setSpinnerLoading(true)

        try{
            let response=await usePutData(`updatePriceOnProduct`,{produktid,newprice})
            if(response.status===200){
            let myNewObjArr=[...getCalculatedPriceandCost]
            let newIndex=myNewObjArr.findIndex(data=>data.produktid===produktid)
            myNewObjArr[newIndex].saved = !myNewObjArr[newIndex].saved;
            
            myNewObjArr[newIndex].buttontext = myNewObjArr[newIndex].buttontext === mainHeaderSaved ? mainHeaderSave : mainHeaderSaved;
            myNewObjArr[newIndex].save=false;
            setCalculatedPriceandCost(myNewObjArr)
            }
          
        }catch(error){
            setAlertCode(26003)
            setAlertMessage(selectedError[26003])
            setSpinnerLoading(false)
            setShowAlert(true)
          
            
         
        }
    setSpinnerLoading(false)
    }




const handleRowClickIndex = (produktid,index) => {// we get the index of the row and the produktid
    setChosenProductId(produktid);// we set the chosen productid
       if (expandedRow === index) { // here we check if the index is the same as the expanded row
         setExpandedRow(null);
       } else {
         setExpandedRow(index);
       }
     };


// if the user changges the value on the range control we save the value in the database
async function SetBackGroundShadeHandler(val){
  setBackGroundShade(val)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateBackGroundShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }
  }catch(error){
      setAlertCode(26004)
      setAlertMessage(selectedError[26004])
      setSpinnerLoading(false)
      setShowAlert(true)

    }

}

async function SetmenuColorHandler(val){
  setMenuShade(val)
  setMenuShade2(val-0.20)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateMenuShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }

}catch(error){
  setAlertCode(26005)
  setAlertMessage(selectedError[26005])
  setSpinnerLoading(false)
  setShowAlert(true)

}
}

const SortHandlerBenaemn=(val)=>{
// now we will sort the array by benaemn
if(val===1&&getBenaemnSort===true){
  let myNewObjArr=[...getCalculatedPriceandCost]
  myNewObjArr.sort((a,b)=>a.benaemn.localeCompare(b.benaemn))
  setCalculatedPriceandCost(myNewObjArr)
  setBenaemnSort(false)
}else if(val===1&&getBenaemnSort===false){
  let myNewObjArr=[...getCalculatedPriceandCost]
  myNewObjArr.sort((a,b)=>b.benaemn.localeCompare(a.benaemn))
  setCalculatedPriceandCost(myNewObjArr)
  setBenaemnSort(true)
}
}

const SortHandlerPris=()=>{
  if(getSortHandlerPris===true){
    let myNewObjArr=[...getCalculatedPriceandCost]
    myNewObjArr.sort((a,b)=>a.pris-b.pris)
    setCalculatedPriceandCost(myNewObjArr)
    setSortHandlerPris(false)
  }else if(getSortHandlerPris===false){
    let myNewObjArr=[...getCalculatedPriceandCost]
    myNewObjArr.sort((a,b)=>b.pris-a.pris)
    setCalculatedPriceandCost(myNewObjArr)
    setSortHandlerPris(true)
  }
  }

const sortHandlerMargin=()=>{
  const calculateMargin = (data) => {
    return (data.pris - data.totals) / data.pris;
  };
  
  if(getSortHandlerMargin===true){
    let myNewObjArr = getCalculatedPriceandCost.map(data => ({ margin: calculateMargin(data), data }));
    myNewObjArr.sort((a, b) => a.margin - b.margin);
    setCalculatedPriceandCost(myNewObjArr.map(obj => obj.data));
    setSortHandlerMargin(false)
  
  } else if(getSortHandlerMargin===false){
    let myNewObjArr = getCalculatedPriceandCost.map(data => ({ margin: calculateMargin(data), data }));
    myNewObjArr.sort((a, b) => b.margin - a.margin);
    setCalculatedPriceandCost(myNewObjArr.map(obj => obj.data));
    setSortHandlerMargin(true)
  }
}









  return (
    <>
    <div className="xvtotWrapper">
    {windowWidth<360?displayGadgetToSmall():''}
    <div className={getBackGroundShade<1?"xvtotalArea":"xvtotalArea1"}>
    {getBackGroundShade<1?<img src={imageUrl} alt="Bakesoft" className="xvbackgroundImageRecepyNew" />:''}
 
      <div className="xvheaderArea">
      {getCountry!=='ja'?
    <div className={getBackGroundShade<1?"xvcompName":"xvcompName1"}>{title} </div>
    : <div className={getBackGroundShade<1?"xvcompNameJAP":"xvcompNameJAP1"}>{title} </div>}
    </div>


    <div className="xvrangeArea">
    <input type="range" min="0.0" max="1" value={getMenuShade||0.2} step="0.10" id={1}  
    title={titleAdjustMenu} className="xvrange2" onChange={(e)=>SetmenuColorHandler(e.target.value)}/>

    <input type="range" min="0.0" max="1" value={getBackGroundShade||0.2} step="0.10" id={2}  
    title={titleAdjustMenu1} className="xvrange1" onChange={(e)=>SetBackGroundShadeHandler(e.target.value)}/>


</div>

<div className="xvareaA">

    <div className="xvselectArea">
        <fieldset className="xvselectfieldset"><legend className="xvselectlegend">{mainSelectLegend}</legend>
        <select className="xvselect" onChange={(e)=>getKatHandler(e.target.value)}>
        <option value={0} className="xvoptionclassName">&nbsp;{mainSelectFirstOption}</option>
        {getpriceDataCategories.map((data,index)=>{
            return <option key={index} value={data.kategoriid} className="xvoptionclassName">&nbsp;{data.benaemn}</option>
        })}

        </select>
        </fieldset>

    </div>
<div className="xvheaderWrapper">
<div className="xvheader">
       <button className="xvproduktid1">{mainHeaderPid}</button>
       <button className="xvbenaemn1" title={titleName} onClick={()=>SortHandlerBenaemn(1)}>{mainHeaderProductName}</button>
       <button className="xvpris1" title={titlePrice} onClick={()=>SortHandlerPris()}>{mainHeaderPrice}</button>
       <button className="xvmargin1" title={titleMargin} onClick={()=>sortHandlerMargin()}>{getMobileIsUsed===true?titleMobileMargin:titleMargin1}</button> 
       <button className="xvinkmoms1">{getMobileIsUsed===true?titleMobileVat:titleVat}</button> 
       <button className="xvspara1">{mainHeaderSave}</button> 
       
       </div>
       </div>
       <div className="xvpricelistArea">
       <div className="xvpricelistData">{getCalculatedPriceandCost.map((data,index)=>
    
    <div key={index}>

       <button className={index%2===0?"xvpidbutton":"xvpidbutton1"}>{data.produktid}</button>
       <button className={index%2===0?"xvbenaemnbutton":"xvbenaemnbutton1"} onClick={()=>handleRowClickIndex(data.produktid,index)}>{data.benaemn&&data.benaemn.length>0?data.benaemn:noNameGiven}</button>
    <button className={index%2===0?"xvprisbutton":"xvprisbutton1"}>{Math.floor(data.pris > 0 ? data.pris : 0)}</button>
       <button className={index%2===0?"xvmarginbutton":"xvmarginbutton1"}>{Math.floor(Math.abs((mpercentage/100)-(data.totals/data.pris))*100)}</button>
       <button className={index%2===0?"xvinkmomsbutton":"xvinkmomsbutton1"}>{Math.ceil(data.pris * 1.12)}:-</button>
       <button className={`${index % 2 === 0 ? "xvsparabutton" : "xvsparabutton1"} ${data.save === true ? "xvsparabutton2" : ""}`}
    
                onClick={() => SaveHandler(data.produktid, data.pris)}
                disabled={!data.save}>
              {data.buttontext && data.buttontext.length > 0 ? data.buttontext :mainHeaderSave}
                                                                                      </button>
{expandedRow === index && (<div className="xvanimateddivPricelistNew">
    <EditProduct getlist={getlist} priceHandler={priceHandler}  produktid={getChosenProductId}
    
    titleIncreaseBy10={titleIncreaseBy10}
    titleIncreaseBy1={titleIncreaseBy1}
    titleIncreaseBy01={titleIncreaseBy01}
    titleDecreaseBy10={titleDecreaseBy10}
    titleDecreaseBy1={titleDecreaseBy1}
    titleDecreaseBy01={titleDecreaseBy01}
    
    />
</div>
  )}

       </div>)}
       </div></div>

</div>
<div className="xvareaB">
<div className="xvaverageArea">
<div className="xvaverageText">{rightHeaderLegend}</div>
<div className="xvaveragePercentage">{averagePercentage>0?averagePercentage.toFixed(2)+'%':''}</div>
</div>

</div>

<div className="xvpricelistSpinnerContainer">
        <div className="xvpricelistSpinner">
          <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="0.75" width="60" visible={getSpinnerLoading}/>
        </div>
        </div>

  {getShowAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}/>:''}

</div>
</div>
</>

  )
}


function EditProduct(props){
    let{getlist,priceHandler,produktid,
    
      titleIncreaseBy10,
      titleIncreaseBy1,
      titleIncreaseBy01,
      titleDecreaseBy10,
      titleDecreaseBy1,
      titleDecreaseBy01,
    }=props

    return(

        <div className="xveditproductTop">
        <div className="xveditproductArea">
        <div className="xvplussign" onClick={()=>priceHandler(produktid,1)} title={titleIncreaseBy10}><AiOutlinePlusCircle className="xvAiOutlinePlusCircle"/></div><div className="xvplustext">10</div>
   
        <div className="xvplussign" onClick={()=>priceHandler(produktid,2)} title={titleIncreaseBy1}><AiOutlinePlusCircle className="xvAiOutlinePlusCircle"/></div><div className="xvplustext">1</div>
        <div className="xvplussign" onClick={()=>priceHandler(produktid,3)} title={titleIncreaseBy01}><AiOutlinePlusCircle className="xvAiOutlinePlusCircle"/></div><div className="xvplustext">0.1</div>
      
            <div className="xvminussign" onClick={()=>priceHandler(produktid,6)} title={titleDecreaseBy01}><AiOutlineMinusCircle  className="xvAiOutlineMinusCircle"/></div><div className="xvplustext">0.1</div>
        <div className="xvminussign" onClick={()=>priceHandler(produktid,5)} title={titleDecreaseBy1}><AiOutlineMinusCircle  className="xvAiOutlineMinusCircle"/></div><div className="xvplustext">1</div>
      <div className="xvminussign" onClick={()=>priceHandler(produktid,4)} title={titleDecreaseBy10}><AiOutlineMinusCircle  className="xvAiOutlineMinusCircle"/></div><div className="xvplustext">10</div>
     
    </div></div>
    )
}

export default PriceListNew