import React,{useEffect,useState} from 'react'
import {useGetData,usePutData} from '../hooks/useGetData';
import {ProgressBar, RotatingLines } from 'react-loader-spinner';
import Alert from '../functions/appAlert';
import useReportErr from '../hooks/useReportErr';
import './ListProdukt1.css'
import {useNavigate} from 'react-router-dom';
import useFetchImageUrl from '../hooks/useFetchImageUrl';
import usePageSettings from '../hooks/usePageSettings';
import languages from '../AppLanguage/AppLanguage';
import AppError from '../AppLanguage/AppError';
import displayGadgetToSmall from '../functions/displayGadgetToSmall';
import { ImCheckboxChecked } from "react-icons/im";
import { ImCheckboxUnchecked } from "react-icons/im";

function ListProdukt() {

    const[getKategori,setKategori]=useState([{}])
    const[getSpinnerLoading,setSpinnerLoading]=useState(false)
    const[getAlertCode,setAlertCode]=useState(0)
    const[getAlertMessage,setAlertMessage]=useState('') // this is the message for the alert
    const[getShowAlert,setShowAlert]=useState(false) // this is the alert
    const[getBackGroundShade,setBackGroundShade]=useState(0.75)
    const[getRenderKey,setRenderKey]=useState(0) // this is the key for the component
    const[getMenuShade,setMenuShade]=useState(0.95)
    const[getMenuShade2,setMenuShade2]=useState(0.75)
    const[getShowYesNoAlert,setShowYesNoAlert]=useState(false)
    const[getYesNoAlertMessage,setYesNoAlertMessage]=useState('')
    const[getProduktList,setProduktList]=useState([{}]) // this is the list of ingredients
    const[getchosenCategory,setchosenCategory]=useState(0) // this is the chosen category
    const[produktData,setProduktData]=useState([]) // this is the data for the ingredients
    const[getButtonSavedStatus,setButtonSavedStatus]=useState(false) // this is the button saved status
    const[getChosenRecepy,setChosenRecepy]=useState(0) // this is the chosen recepy
    const[getColorVal,setColorVal]=useState(0) // this is the color value
    const [getImageVal,setImageVal]=useState('')
    const [getImageUrl,setImageUrl]=useState('')
    const pageid=3 // this is the page id for the page
    const [logtime]=useState(5000) // this is the time for the alert to be shown
    const[getCountry,setCountry]=useState('en') // this is the country
    const [getMobileIsUsed,setMobileIsUsed]=useState(false) // this is the mobile is used


    const [windowWidth,setWindowWith]=useState(0) // this is the window width
    const [windowHeight,setWindowHeight]=useState(0)  // this is the window height
    const [getDisposableHeight,setDisposableHeight]=useState(0) // this is the disposable height
    useEffect(()=>{
      let country = localStorage.getItem('country')||'en' // this is the country code
      setCountry(country)
    },[])

        

    let newFontFamily = '';
    if (getCountry === 'ja') {
       newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
    } else {
      newFontFamily = 'Arial, Helvetica, sans-serif'
    //newFontFamily = 'Times New Roman, Times, serif'
    //newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
    }
    document.documentElement.style.setProperty('--font-family', newFontFamily);
  


        const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal } = usePageSettings(pageid);
        const { imageUrl, alertMessage, alertCode, showAlert } = useFetchImageUrl(getImageVal); // runs at mount, gives ut the image url and fetch background image from server

        const navigate = useNavigate();

        useEffect(() => {
          function setSize() {
            var totalHeight=window.innerHeight
     
              setDisposableHeight(totalHeight-150)
            setWindowWith(window.innerWidth);
            setWindowHeight(window.innerHeight);
            if(window.innerWidth<575.98){
              setMobileIsUsed(true)
            }
          }
          setSize();
  
        }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);
  
        function rgbToRgba(rgb, alpha) {
          return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
        }
        
      //--bakcground shade settings
      let rgbaVal
      let rgbaVal2
      let val
      
      if(getColorVal && getColorVal.length > 0){
        let rgb = getColorVal;
        let alpha = getMenuShade; // replace with your alpha value
        let alpha2 = getMenuShade2; // replace with your alpha value
        rgbaVal = rgbToRgba(rgb, alpha);
        rgbaVal2 = rgbToRgba(rgb, alpha2);
        val=`rgba(255, 255, 255, ${getBackGroundShade})`
      
        }else{
          rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
          rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
          val=`rgba(255, 255, 255, ${getBackGroundShade})`
        }
      //---
      
      useEffect(() => {
          let root = document.documentElement;
          root.style.setProperty('--rgbaVal', rgbaVal);
          root.style.setProperty('--rgbaVal2', rgbaVal2);
          root.style.setProperty('--val',val);
          root.style.setProperty('--disposableHeight',getDisposableHeight+'px');
          root.style.setProperty('--windowHeight',windowHeight+'px');
          
        }, [getBackGroundShade,getMenuShade,getMenuShade2]);
      
        //-----

    // language settings
      const selectedCountry = languages[getCountry];
    
       let produktlistaTitle=''
      if(getMobileIsUsed===false){
      produktlistaTitle=selectedCountry.produktlista?.title
      }else{
      produktlistaTitle=selectedCountry.produktlista?.titleMobile
      }
      const kategoriLegend=selectedCountry.produktlista?.kategoriLegend
      const headerId=selectedCountry.produktlista?.headerId
      const headerNamn=selectedCountry.produktlista?.headerNamn
      const headerAktiv=selectedCountry.produktlista?.headerAktiv
      const headerDela=selectedCountry.produktlista?.headerDela
      const headerSpara=selectedCountry.produktlista?.headerSpara
      const ListSaveButton=selectedCountry.produktlista?.ListSaveButton
      const SavedOnButton=selectedCountry.produktlista?.SavedOnButton
      const selectFirstOption=selectedCountry.produktlista?.selectFirstOption
      const titleAdjustMenu=selectedCountry.produktlista?.titleAdjustMenu
      const titleAdjustMenu1=selectedCountry.produktlista?.titleAdjustMenu1
      



        // error messages
        const selectedError=AppError[localStorage.getItem('country')]

          useEffect(() => {
            GetSettings();
          }, [GetSettings]);

          // we set the image url here
          useEffect(() => {
            setImageUrl(imageUrl);
            if (alertCode > 0) {
              setAlertMessage(alertMessage);
              setAlertCode(alertCode);
              setShowAlert(showAlert);
            }
          }, [imageUrl]);
        
          // here we set the color values and image values
          useEffect(() => {
            setBackGroundShade(backGroundShade);
            setMenuShade(menuShade);
            setMenuShade2(menuShade2);
            setColorVal(colorVal);
            setImageVal(imageVal);
          }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);


        

          useEffect(()=>{
            async function FetchAllProdukt() {
                setSpinnerLoading(true)
        
          
                try {
                  const response = await useGetData('getProductCategories')
                  setKategori(response.data[0]);
                  setSpinnerLoading(false)
             
                } catch (error) {
                  setAlertCode(51001)
                  setAlertMessage(selectedError[51001])
                  setSpinnerLoading(false)
                  setShowAlert(true)
                }
           
  
                
          
                }
                FetchAllProdukt();


          },[])

useEffect(()=>{
    async function FetchAllRecepy() {
    setSpinnerLoading(true)
            
              
     try {
         const response = await useGetData(`getproductsByCategoryNew/${getchosenCategory}`)
        setProduktData(response.data[0]);
        setSpinnerLoading(false)
                 
        } catch (error) {
        setAlertCode(51002)
        setAlertMessage(selectedError[51002])
        setSpinnerLoading(false)
        setShowAlert(true)
        }
  
                
        
             
}
FetchAllRecepy()
},[getchosenCategory])

const RunReportErrHandler = async (errCode) => {
    setSpinnerLoading(true);

      await useReportErr('ErrReport',{errCode});
    setSpinnerLoading(false);
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  useEffect(() => {
    if (getAlertCode > 0) {
      RunReportErrHandler(getAlertCode);
    }
  }, [getAlertCode]);
        



const kategoriHandler=(val)=>{
    setchosenCategory(val)
}

const activeHandler=(produktid)=>{
  setChosenRecepy(produktid)
    let mynewObjArray =[...produktData]
    // find the index by ingredid
    let index = mynewObjArray.findIndex(data =>data.produktid===produktid);
    // first we find the value of the active
    let active = mynewObjArray[index].active
    if(active===1){
        active=0
        mynewObjArray[index].buttonStatus=1
    }else{
        active=1
        mynewObjArray[index].buttonStatus=1
  
    }
    mynewObjArray[index].active=active
    // add buttonstatus to the object
    //mynewObjArray[index].buttonStatus=active
   // mynewObjArray[index].buttonStatus=active
    setProduktData(mynewObjArray)

}

async function UpdateActive(produktid){   
    setSpinnerLoading(true)
    // here lets find the active and shared values
    let mynewObjArray =[...produktData]
    // find the index by ingredid
    let index = mynewObjArray.findIndex(data =>data.produktid==produktid);
    // first we find the value of the active
    let active = mynewObjArray[index].active
    let shared = mynewObjArray[index].shared

    try{
        const response = await usePutData(`updateProductActiveShared`,{produktid,active,shared,getchosenCategory})
        if(response.status===200){
        setSpinnerLoading(false)
        //here we must set buttonstatus back to 0
        mynewObjArray[index].buttonStatus=0
        mynewObjArray[index].buttonSaved=1
        setProduktData(mynewObjArray)
        }
    }catch(error){
        setAlertCode(51003)
        setAlertMessage(selectedError[51003])
        setSpinnerLoading(false)
        setShowAlert(true)

    }


}


const sharedHandler=(produktid)=>{
    let mynewObjArray =[...produktData]
    // find the index by ingredid
    let index = mynewObjArray.findIndex(data =>data.produktid===produktid);
    // first we find the value of the active
    let active = mynewObjArray[index].shared
    if(active===1){
        active=0
        mynewObjArray[index].buttonStatus=1
    }else{
        active=1
        mynewObjArray[index].buttonStatus=1
  
    }
    mynewObjArray[index].shared=active
    // add buttonstatus to the object
   // mynewObjArray[index].buttonStatus=active
    setProduktData(mynewObjArray)
}

const saveHandler=(produktid)=>{
    UpdateActive(produktid)
}

// if the user changges the value on the range control we save the value in the database
async function SetBackGroundShadeHandler(val){
  setBackGroundShade(val)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateBackGroundShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }
  }catch(error){
      setAlertCode(51004)
      setAlertMessage(selectedError[51004])
      setSpinnerLoading(false)
      setShowAlert(true)
  }
      

}

async function SetmenuColorHandler(val){
  setMenuShade(val)
  setMenuShade2(val-0.20)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateMenuShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }

}catch(error){
  setAlertCode(51005)
  setAlertMessage(selectedError[51005])
  setSpinnerLoading(false)
  setShowAlert(true)
}
}

  return (

    <>
    {windowWidth<360?displayGadgetToSmall():''}
    {getBackGroundShade<1?<img src={imageUrl} alt="Bakesoft" className="backgroundImageListRecept" /> :
    <div className="backgroundImageListRecept1"/>
    }

    <div className="xdproduktArea1">  
  

  
   

        <div className="xdheaderArea">
          {getCountry!=='ja'?
        <div className={getBackGroundShade<1?"xdcompName":"xdcompName1"}>{produktlistaTitle}</div>
       :<div className={getBackGroundShade<1?"xdcompNameJAP":"xdcompNameJAP1"}>{produktlistaTitle}</div>}
        </div>
      
      <div className="xdrangeArea">

        <input type="range" min="0.0" max="1" value={getMenuShade} step="0.10" id={2}  
       title={titleAdjustMenu} className="xdrange2" onChange={(e)=>SetmenuColorHandler(e.target.value)}/>

        <input type="range" min="0.0" max="1" value={getBackGroundShade} step="0.10" id={2}  
       title={titleAdjustMenu1} className="xdrange1" onChange={(e)=>SetBackGroundShadeHandler(e.target.value)}/>



        </div>


        <div className="xdProduktListB">
            <div className="xdproduktselectTop">
            <fieldset className="xdproduktselectFieldset">
            <legend className={getCountry!=='ja'?"xdproduktselectLegend":"xdproduktselectLegendJAP"}>{kategoriLegend}</legend>
                <select className={getCountry!=='ja'?"xdproduktselectProdukt":"xdproduktselectProduktJAP"} onChange={(e)=>kategoriHandler(e.target.value)}>
                                       <option value='0' hidden>&nbsp;{selectFirstOption}&nbsp;!&nbsp;</option>
               {getKategori.map((data,index)=><option className="option" key={index} 
                                              value={data.kategoriid}>&nbsp;{data.benaemn}</option>)}
               </select>
 </fieldset>
        </div>

   
        <div className="xddatalistTop">
            <button className={getCountry!=='ja'?"xdlistproduktid":"xdlistproduktidJAP"}>{headerId||'Id'}</button>
            <button className={getCountry!=='ja'?"xdlistproduktbenaemn":"xdlistproduktbenaemnJAP"}>{headerNamn||'Namn'}</button>
            <button className={getCountry!=='ja'?"xdlistproduktactive":"xdlistproduktactiveJAP"}>{headerAktiv||'Aktiv'}</button>
            <button className={getCountry!=='ja'?"xdlistproduktSave":"xdlistproduktSaveJAP"}>{headerSpara||'Spara'}</button>
        </div>
        <div className={getCountry!=='ja'?"xddatalistData":"xddatalistDataJAP"}>
    
            {produktData.length>0?produktData.map((data,index)=><div  key={index}>
                <button className={index%2===0?"xditemId":"xditemId1"}>{data.produktid}</button>
                <button className={index%2===0?"xdbenaemn":"xdbenaemn1"}>{data.benaemn}</button>
     
                <button className={index%2===0?"xdactive":"xdactive1"}
                onClick={()=>activeHandler(data.produktid)}
                
                >
                  {data.active===1?<ImCheckboxChecked className="xddataitemChecked"/>:<ImCheckboxUnchecked className="xddataitemUnchecked"/>}</button>
             
     
     
                {getCountry!=='ja'?
                <button className={data.buttonStatus===1?"xdsaveButton1":"xdsaveButton2"}
                onClick={()=>saveHandler(data.produktid)} title={data.buttonStatus===1?ListSaveButton:''}>{data.buttonSaved===1?SavedOnButton:ListSaveButton}</button>
                :   <button className={data.buttonStatus===1?"xdsaveButtonJAP1":"xdsaveButtonJAP2"}
                onClick={()=>saveHandler(data.produktid)} title={data.buttonStatus===1?ListSaveButton:''}>{data.buttonSaved===1?SavedOnButton:ListSaveButton}</button>}
             
            </div>):null}

        </div>
        </div>
        <div className="areaC"></div>

        <div className="xdlistSpinnerContainer">
        <div className="listSpinner">
          <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="1" width="60" visible={getSpinnerLoading}/>
        </div>
        </div>

   

  {getShowAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}/>:''}


    </div>
    </>
  )
}

export default ListProdukt