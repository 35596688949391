import React,{useState,useEffect} from 'react'
import useDeviceDetection from '../hooks/useDeviceDetection';
import IngredTot from '../hooks_styles/ingredTot'
import Alert from '../functions/appAlert';
import {useGetData,usePutData} from '../hooks/useGetData';
import useReportErr from '../hooks/useReportErr';
import { RotatingLines } from 'react-loader-spinner';
import Componentname from '../hooks/Componentname';
import './IngredTotNew1.css'
import { useNavigate,Link } from 'react-router-dom';
import useFetchImageUrl from '../hooks/useFetchImageUrl';
import usePageSettings from '../hooks/usePageSettings';
import languages from '../AppLanguage/AppLanguage';
import AppError from '../AppLanguage/AppError';
import displayGadgetToSmall from '../functions/displayGadgetToSmall'

function IngredTotNew() {


const[ingredTot,setIngredTot]=useState([{}])
const[getproductionData,setproductionData]=useState([{}])
const[getrecepyMangd,setrecepyMangd]=useState(0)

const[getSpinnerLoading,setSpinnerLoading]=useState(false)
const [getShowAlert,setShowAlert] = useState(false);
const [getAlertMessage,setAlertMessage] = useState({});
const[getAlertCode,setAlertCode]=useState(0)
const[sortOrder,setSortOrder]=useState(0)
const[getBackGroundShade,setBackGroundShade]=useState(0)
const[getRenderKey,setRenderKey]=useState(0)
const[getMenuShade,setMenuShade]=useState(0)
const[getMenuShade2,setMenuShade2]=useState(0)
const [getColorVal,setColorVal]=useState('')
const [getImageVal,setImageVal]=useState('')
const [getImageUrl,setImageUrl]=useState('')
const pageid=16
const [logtime]=useState(5000)


const [windowWidth,setWindowWith]=useState(0) // this is the window width
const [windowHeight,setWindowHeight]=useState(0)  // this is the window height
const [getMobileIsUsed,setMobileIsUsed]=useState(false) // this is the window height
const[getCountry,setCountry]=useState('se') // this is the country
    

useEffect(()=>{
  let country = localStorage.getItem('country')||'en' // this is the country code
  setCountry(country)
},[])


useEffect(() => {
let newFontFamily = '';
let marginDef=0
let marginDef2=0
if (getCountry === 'ja') {
   newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
   marginDef=10
   marginDef2=5
} else {
  newFontFamily = 'Arial, Helvetica, sans-serif'
  marginDef=5
//newFontFamily = 'Times New Roman, Times, serif'
//newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
}
document.documentElement.style.setProperty('--font-family', newFontFamily);
document.documentElement.style.setProperty('--margin-def', `${marginDef}px`);
document.documentElement.style.setProperty('--margin-def2', `${marginDef2}px`);

}, [getCountry]);

    const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal } = usePageSettings(pageid);
    const { imageUrl, alertMessage, alertCode, showAlert } = useFetchImageUrl(getImageVal); // runs at mount, gives ut the image url and fetch image from server
  
  let navigate = useNavigate();

  useEffect(() => {
    function setSize() {
      setWindowWith(window.innerWidth);
      setWindowHeight(window.innerHeight);
    
      if(window.innerWidth < 600){
  
        setMobileIsUsed(true)
      } 
  
    }

  
    // Call setSize initially to set the correct state
    setSize();
  
    // Add the event listener
    window.addEventListener('resize', setSize);
  
    // Return a cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', setSize);
    };
  }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]); // Dependencies

      
  function rgbToRgba(rgb, alpha) {
    return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
  }

//--bakcground shade settings
let rgbaVal
let rgbaVal2
let val

if(getColorVal && getColorVal.length > 0){
  let rgb = getColorVal;
  let alpha = getMenuShade; // replace with your alpha value
  let alpha2 = getMenuShade2; // replace with your alpha value
  rgbaVal = rgbToRgba(rgb, alpha);
  rgbaVal2 = rgbToRgba(rgb, alpha2);
  val=`rgba(255, 255, 255, ${getBackGroundShade})`

  }else{
    rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
    rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
    val=`rgba(255, 255, 255, ${getBackGroundShade})`
  }
//---

useEffect(() => {
    let root = document.documentElement;
    root.style.setProperty('--rgbaVal', rgbaVal);
    root.style.setProperty('--rgbaVal2', rgbaVal2);
    root.style.setProperty('--val',val);
    root.style.setProperty('--windowHeight',windowHeight+'px');

    
  }, [getBackGroundShade,getMenuShade,getMenuShade2]);




                // language settings
    const selectedCountry = languages[getCountry];
   
     let title=''
    if(getMobileIsUsed===false){
      title=selectedCountry.ingredtotNew?.title
    }else{
      title=selectedCountry.ingredtotNew?.titleMobile
    }

    const {
      titleMobile,
      listHeaderLegend,
      listHeaderId,
      listHeaderName,
      listHeaderWeight,
      listtHeaderAmount,
      titleHeaderName,
      titleHeaderWeight,
      titleHeaderAmount,
      rightHeaderLegendTotalAmount,
      rightHeaderLegendTotalWeight,
      titleAdjustMenu,
      titleAdjustMenu1
    } = selectedCountry.ingredtotNew || {};

// error messages
const selectedError=AppError[localStorage.getItem('country')||'en']
 



          useEffect(() => {
            GetSettings();
          }, [GetSettings]);

          // we set the image url here
          useEffect(() => {
            setImageUrl(imageUrl);
            if (alertCode > 0) {
              setAlertMessage(alertMessage);
              setAlertCode(alertCode);
              setShowAlert(showAlert);
            }
          }, [imageUrl]);
        
          // here we set the color values and image values
          useEffect(() => {
            setBackGroundShade(backGroundShade);
            setMenuShade(menuShade);
            setMenuShade2(menuShade2);
            setColorVal(colorVal);
            setImageVal(imageVal);
          }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);



          
useEffect(()=>{

    async function GetIngredTotals(){
     setSpinnerLoading(true)
       try{
           let response= await useGetData(`getingredTotals`)
           setproductionData(response.data[0])
           setSpinnerLoading(false)
       }catch(error){
        setAlertCode(24001)
        setAlertMessage(selectedError[24001])
        setSpinnerLoading(false)
        setShowAlert(true)

    
    
       }
    }
    GetIngredTotals()

    async function GetUserSettings(){
      setSpinnerLoading(true)
      try{
        let response =await useGetData(`getuserShadeSettings`)
        setBackGroundShade(response.data[0][0].bval)
        setMenuShade(response.data[1][0].bval)
        setMenuShade2(response.data[1][0].bval-0.20)
        setSpinnerLoading(false)
      }catch(error){
        setAlertCode(24002)
        setAlertMessage(selectedError[24002])
        setSpinnerLoading(false)
        setShowAlert(true)
      }
    } 
    GetUserSettings()


},[])

    
const RunReportErrHandler = async (errCode) => {
  setSpinnerLoading(true);
  await useReportErr('ErrReport',{errCode});
  setSpinnerLoading(false);
};

useEffect(() => {
  if (getAlertCode > 0) {
    RunReportErrHandler(getAlertCode);
  }
}, [getAlertCode]);

const handleCloseAlert = () => {
  setShowAlert(false);
};

const sortHandler=()=>{
  //here we will sort the data in the object getproductionData and we will toggel the sort order  
  if(sortOrder===0){
    setSortOrder(1)
    setproductionData(getproductionData.sort((a,b)=>a.ingrednamn.localeCompare(b.ingrednamn)))
}else{
    setSortOrder(0)
    setproductionData(getproductionData.sort((a,b)=>b.ingrednamn.localeCompare(a.ingrednamn)))
}
}
const weightHandler=()=>{
  //here we will sort the object getproductionData and we will toggel the sort order and we will
  //sort by weight
  if(sortOrder===0){
    setSortOrder(1)
    setproductionData(getproductionData.sort((a,b)=>a.vikt-b.vikt))
}else{
    setSortOrder(0)
    setproductionData(getproductionData.sort((a,b)=>b.vikt-a.vikt))
}
}
const sumHandler=()=>{
  //here we will sort the object getproductionData and we will toggel the sort order and we will
  //sort by sum
  if(sortOrder===0){
    setSortOrder(1)
    setproductionData(getproductionData.sort((a,b)=>a.Summa-b.Summa))

}else{
    setSortOrder(0)
    setproductionData(getproductionData.sort((a,b)=>b.Summa-a.Summa))
}
}



// if the user changges the value on the range control we save the value in the database
async function SetBackGroundShadeHandler(val){
  setBackGroundShade(val)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateBackGroundShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }
  }catch(error){
      setAlertCode(24004)
      setAlertMessage(selectedError[24004])
      setSpinnerLoading(false)
      setShowAlert(true)
 
      return;
    }

}

async function SetmenuColorHandler(val){
  setMenuShade(val)
  setMenuShade2(val-0.20)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateMenuShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }

}catch(error){
  setAlertCode(24005)
  setAlertMessage(selectedError[24005])
  setSpinnerLoading(false)
  setShowAlert(true)


  return;
}
}


//let totalweight = getproductionData.reduce((acc, data) => acc + (data.vikt), 0);
let totalweight = getproductionData.reduce((acc, data) => acc + parseFloat(data.vikt), 0);
//let totalsum=getproductionData.reduce((acc,data)=>acc+data.Summa,0)
let totalsum = getproductionData.reduce((acc, data) => acc + parseFloat(data.Summa), 0);


  return (
    <>
    <div className="xqtotWrapper">
    {windowWidth<360?displayGadgetToSmall():''}
      <div className={getBackGroundShade<1?"xqingredTotalArea1":"xqingredTotalArea"}>
      {getBackGroundShade<1?<img src={imageUrl} alt="Bakesoft" className="xqbackgroundImageRecepyNew" />:''}

<div className="xqheaderArea">
      {getCountry!=='ja'?
      <div className={getBackGroundShade<1?"xqcompName":"xqcompName1"}>{title}</div>
      :<div className={getBackGroundShade<1?"xqcompNameJAP":"xqcompNameJAP1"}>{title}</div>}
   </div>
   
  <div className="xqrangeArea">

    
<input type="range" min="0.0" max="1" value={getMenuShade||0.20} step="0.10" id={2}  
  title={titleAdjustMenu} className="xqrange2" 
  onChange={(e)=>SetmenuColorHandler(e.target.value)}  />

  <input type="range" min="0.0" max="1" value={getBackGroundShade||0.20} step="0.10" id={2}  
  title={titleAdjustMenu1} className="xqrange1"
  onChange={(e)=>SetBackGroundShadeHandler(e.target.value)}/>



  </div>
  

        <div className="xqingredTotalAreaA">
          {getMobileIsUsed===false?
  <div className="xqheadertext">{listHeaderLegend}</div>
         :''}
         <div className="xqingredTop">
            <div className="xqingredHeader">
              <button className="xqingredId" >{listHeaderId}</button>
              <button className="xqingredBenaemn" onClick={()=>sortHandler()} title={titleHeaderName}>{listHeaderName}</button>
              <button className="xqingredVikt" onClick={()=>weightHandler()} title={titleHeaderWeight}>{listHeaderWeight}</button>
              <button className="xqingredSumma" onClick={()=>sumHandler()} title={titleHeaderAmount}>{listtHeaderAmount}</button>
            </div>
            <div className="xqdata">
              {getproductionData.map((data,index)=>
    
    <div key={index} className="xqdatarows">
   
       <button className={index%2===0?"xqtotproduktionid":"xqtotproduktionid1"}>{data.ingredid}</button>
       <button className={index%2===0?"xqtotbenaemn":"xqtotbenaemn1"}>{data.ingrednamn}</button>
       <button className={index%2===0?"xqtotvikt":"xqtotvikt1"}>{data.vikt}</button>
       <button className={index%2===0?"xqtotsum":"xqtotsum1"}>{String(data.Summa).substring(0,5)}</button>
    
       </div>)}
      
            </div>
          </div>
        </div>
        
        <div className="xqingredTotalAreaB">
            <div className="xqingredTotalSummaryBottomAntal">{`${rightHeaderLegendTotalAmount +`:`} ${String(totalsum||0).substring(0,6)}`}</div>
            <div className="xqingredTotalSummary">{`${rightHeaderLegendTotalWeight + `:`} ${(totalweight/1000||0).toFixed(2)} kg`}</div>
    
          </div>
        </div>

        <div className="xqingredTotalSpinnerContainer">
        <div className="xqingredTotalSpinner">
          <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="0.75" width="60" visible={getSpinnerLoading}/>
        </div>
        </div>

  {getShowAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}/>:''}


      </div>
      </>

  );
}

export default IngredTotNew