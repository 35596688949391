
function rgbToRgba(rgb, alpha) {
  return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
}


function MainStyles(val,menuval,menuval2,getColorVal,textareaHeight,getCountry) {

  let rgbaVal
  let rgbaVal2
if(getColorVal && getColorVal.length > 0){
let rgb = getColorVal;
let alpha = menuval; // replace with your alpha value
let alpha2 = menuval2; // replace with your alpha value
rgbaVal = rgbToRgba(rgb, alpha);
rgbaVal2 = rgbToRgba(rgb, alpha2);
}else{
  rgbaVal =`rgba(255, 255, 255, ${val})`;
  rgbaVal2 =`rgba(255, 255, 255, ${val})`;
}



// if language is japanese we use the font Zen Old Mincho
let newFontFamily=''
if(getCountry==='ja'){
  newFontFamily= "'Zen Old Mincho', Arial, Helvetica, sans-serif"
}else{
  newFontFamily='Arial, Helvetica, sans-serif'
}




    const generalStyles ={
          backgroundColor:'rgb(245, 245, 237)',
          fontFamily:newFontFamily,
          fontFamilyTimes:'Times New Roman, Times, serif',
          fontFamilyLobster:'Lobster',
          fontFamilyZenOldMincho: "'Zen Old Mincho', Arial, Helvetica, sans-serif",
          windowsHeight:'92vh',
          width:'100%',
          totalWidth:'100%',
          border:'2px solid darkgrey',
          color:'black',
          colorwhite:'white',
          colorBrown:'brown',
          colorOrange:'orange',
          colorYellow:'yellow',
          colorGrey:'grey',
          colorBeige:'rgb(247, 165, 127)',
          colorLightRed:'rgb(228, 118, 91)',
          colordarkBeige:'rgb(211, 175, 114)',
          borderColor:'grey',
          selectHeight:'30px',
          calculatedTextAreaHeight:textareaHeight,

          colorTransparent:'transparent',
          fieldsetBorderColor:'darkgrey',
          selectBorderColor:'lightgrey',
          selectlistFontSize:'14px',
          menuTextColor:'black',
          menuheaderFontsize:'20px',
          datalistBorderColor:'rgb(223, 212, 212)',
          datalistBorderColorGrey:'grey',
          datalistBorderColorWhite:'white',
          datalistBorderColorBlack:'black',
          spinnerColor:'transparent',
          datalistbackgroundColor:'white',
          transparentBackgroundColor:'rgba(245, 245, 237, 0.6)',
          datalisttextsize:'16px',
          datalistitemColor:'rgb(236, 228, 228)',
          datalistitemColor2:'rgb(248, 244, 244)',
          transparentChangeable:`rgba(255, 255, 255, ${val})`,
          transparentChangeableMenu:`rgba(247, 165, 127, ${menuval})`,
          transparentChangeableMenu2:`rgba(255, 255, 255, ${menuval2})`,
          transparentChangeableMenuCat:`rgba(255,255,255, ${menuval})`,
          transparentChangeableMenuCat2:`rgba(255,255,255, ${menuval2})`,
          transparentChangeableMenu3:`rgba(224,166,193, ${menuval})`,
          transparentChangeableMenu4:`rgba(249,230,244, ${menuval2})`,
          transparentChangeableMenu5:rgbaVal,
          transparentChangeableMenu4:rgbaVal2,
          transparentChangeableMenu6:getColorVal,
          buttonLoginColor:'rgb(225, 176, 68)',
          prefferedBorderStyleThick:'2px solid',
          prefferedBorderStyle:'1px solid',
          prefferedBorderStyleNone:'none',
          prefferedBorderColor:'rgb(223, 212, 212)',
          prefferedHeaderFontsize:'16px',
          prefferedHeaderFontsizeSmall:'14px',
          prefferedHeaderHeight:'30px',
          prefferedListHeight:'30px',
          prefferedBorderSettingStyle:'none',
          prefferedBorderSettingStyle2:'2px solid black',
     
       


        //   datalistitemColor:'transparent',
        //   datalistitemColor2:'trnsparent',
          sixBorderRadius:'6px',
          threeBorderRadius:'3px',
          primarySelectFontsize:'18px',
          legendFontsize:'14px',
          colorblack:'black',
          appWindowsMarginTop:'70px',
          appWindowsMarginLeft:'250px',
          areaMarginTop:'50px',
          buttonklickedColor:'green',
          optionFontSize:'14px',
          //choralColor:'rgb(240,128,128)',
          choralColor:'rgb(249, 230, 244)',
          // pinkDarkColor:'rgb(224, 166, 193)',
          // pinkLightColor:'rgb(249, 230, 244)',
          // pinkColor:'rgb(241, 135, 157)',
          // pinkRed:'rgb(229, 64, 68)',

          pinkDarkColor:'rgb(224, 166, 193)',
          pinkLightColor:'rgb(249, 230, 244)',
          pinkColor:'rgb(241, 135, 157)',
          pinkRed:'rgb(229, 64, 68)',
          pinkerPink:'#c12661',
          greenNatureDarkColor:'#344C11',
          greenNatureColor:'#778D45',
          greenNatureLightColor:'#AEC670',
          greenNatureLightGray:'#AEC09A',

          ActiveButtonColor:'rgb(235, 18, 18)',
          headerColor:'grey',
          headerGreen:'rgb(0,128,0)',
          messageheaderColor:'rgb(240,128,128)',
          fontsizeSmall:'12px',
          fontSizeBig:'14px',
          fontSizeSixteen:'16px',
    }


const ipadStyles = {
    
    borderBottomLeftRadius:'20px',
    borderBottomRightRadius:'20px',
    height:'83.8vh',
    width:'100%',
    backgroundColor:'rgb(245, 245, 237)',
}
return {generalStyles,ipadStyles}
}



export default MainStyles;
