import React,{useState,useEffect} from 'react'
import userDataPolicy from '../hooks_styles/userDataPolicy'
import useDeviceDetection from '../hooks/useDeviceDetection'
import {useNavigate} from 'react-router-dom';
import womaninmarket from '../images/womaninmarket1.jpeg'
function UserDataPolicy() {


    const[getCountry,setCountry] = useState('se')
    const[getPolicy,setPolicy] = useState([{}])
    const[getMobileIsUsed,setMobileIsUsed] = useState(false)
    const[getRenderKey,setRenderKey] = useState(0)
    const[windowWidth,setWindowWith] = useState(0)
    const[windowHeight,setWindowHeight] = useState(0)
    const[getSpinnerLoading,setSpinnerLoading] = useState(false)





    const { isIpad, isAndroid,isWindows } = useDeviceDetection()
    const { datapolicyIpad10th,datapolicyAndroid,datapolicylargeMobileAndroid,datapolicyWindows} = userDataPolicy(windowHeight)

    useEffect(()=>{
      let country = localStorage.getItem('country')||getCountry // this is the country code
      setCountry(country)
    },[getCountry])


    const navigate = useNavigate();




useEffect(() => {
  function setSize() {
    setWindowWith(window.innerWidth);
    setWindowHeight(window.innerHeight);
  }

  // Set initial window size
  setSize();

//   // Update window size when the window is resized
//   window.addEventListener('resize', setSize);

//   // Remove event listener when the component unmounts
//   return () => window.removeEventListener('resize', setSize);
}, []);

useEffect(()=>{
    if (isIpad) {
        setPolicy(datapolicyIpad10th);
        setMobileIsUsed(false)
    } else if (isAndroid) {
      if(windowWidth<420){ // if the width is below 400px we set the layout to the large mobile layout
        setPolicy(datapolicylargeMobileAndroid)
        setMobileIsUsed(true)
          }else{  // else we set the layout to the android layout
            setPolicy(datapolicyAndroid);
            setMobileIsUsed(false)
               }
    } else if (isWindows) {
      
        setPolicy(datapolicyWindows);
        setMobileIsUsed(false)

    }else{
        setPolicy(datapolicyWindows)
        setMobileIsUsed(false)
    
    }
  },[isIpad,isAndroid,isWindows,getRenderKey,windowWidth,windowHeight,getMobileIsUsed])


  return (
    <div style={getPolicy.totalArea}>
      
<img src={womaninmarket} alt="Bakesoft" className="backgroundImage" />
    <div style={getPolicy.totalPolicyArea}>
       

    <div style={getPolicy.policyAreaA}></div>
    <div style={getPolicy.policyAreaB}>

        <div style={getPolicy.messageBox}>
            
               <div style={getPolicy.logoHeader}>
                Bakesoft
                </div>
                <div style={getPolicy.messageHeader}>
                User Data Policy
                </div>
                <div style={getPolicy.message}>
                    <div style={getPolicy.messageSWE}>
                <a href={`${process.env.REACT_APP_BACKEND_URL}/userdatapolicy/se`} 
                style={{ textDecoration: 'none', color:'black' }}
                >User Data Policy - Swedish</a>
                </div>
                <div style={getPolicy.messageJAP}>
                <a href={`${process.env.REACT_APP_BACKEND_URL}/userdatapolicy/ja`}
                style={{ textDecoration: 'none',color:'black' }}
                >ユーザーデータポリシー - 日本語</a>
               </div>
                <div style={getPolicy.messageENG}>
                <a href={`${process.env.REACT_APP_BACKEND_URL}/userdatapolicy/en`}
                style={{ textDecoration: 'none',color:'black' }}
                >User Data Policy -  English</a>
                </div>
                </div>



          

          
         


        </div>

    </div>
    <div style={getPolicy.policyAreaC}></div>

    <div style={getPolicy.policyAreaD}></div>
    <div style={getPolicy.policyAreaE}></div>
    <div style={getPolicy.policyAreaF}></div>
    
    
    
    
    
    
    
    
    
    </div>
    </div>
  )
}

export default UserDataPolicy