import React,{useEffect,useState} from 'react';
import { Link,useNavigate } from 'react-router-dom';
import './HeaderMobile.css'
import { RotatingLines } from 'react-loader-spinner';
import languages from '../AppLanguage/AppLanguage';
import bs from '../images/bs.jpeg'
import CryptoJS from 'crypto-js';
import useGetData from '../hooks/useGetData';
import useReportErr from '../hooks/useReportErr';


function HeaderLargeMobile(props){

const[userCheck,setuserCheck]=useState(false)
const[headerClass,setheaderClass]=useState([{}])
const [imageLink,setImageLink]=useState(localStorage.getItem('image_link')||'')
const [windowWidth,setWindowWith]=useState(0)
const [windowHeight,setWindowHeight]=useState(0)
const [getSpinnerLoading,setSpinnerLoading]=useState(false)
const[getCountry,setCountry]=useState('se')
const[getDecryptValue,setDecryptValue]=useState(0)
const [storageChange, setStorageChange] = useState(0)
const [menuData,setMenuData]=useState([{}])
const [menuOpen, setMenuOpen] = useState(false);
const [buttonClicked, setButtonClicked] = useState(0);


useEffect(()=>{
  let country = localStorage.getItem('country')||'se' // this is the country code
  setCountry(country)
},[])

let newFontFamily = '';
if (getCountry === 'ja') {
   newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
} else {
  newFontFamily = 'Arial, Helvetica, sans-serif'
 // newFontFamily = 'Lobster'
  
//newFontFamily = 'Times New Roman, Times, serif'
//newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
}
document.documentElement.style.setProperty('--font-family', newFontFamily);

const navigate = useNavigate();


useEffect(()=>{

  const handleStorageChange1 = () => {
    let token = localStorage.getItem('auth_token')||'';
    if (token) {
      setuserCheck(true)
    }else{
      setuserCheck(false)
    }
  
  
    setStorageChange(prevState => prevState + 1);
  }

  window.addEventListener('storage', handleStorageChange1);

  // Clean up the event listener when the component unmounts
  return () => {
    window.removeEventListener('storage', handleStorageChange1);
  };
},[storageChange])

useEffect(() => {
  const handleStorageChange = () => {
    setImageLink(localStorage.getItem('image_link') || '');
  };

  window.addEventListener('storage', handleStorageChange);

  // Clean up the event listener when the component unmounts
  return () => {
    window.removeEventListener('storage', handleStorageChange);
  };
}, []);

useEffect(() => {
    function setSize() {
      setWindowWith(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }

    // Set initial window size
    setSize();
  
    // Update window size when the window is resized
    window.addEventListener('resize', setSize);
  
    // Remove event listener when the component unmounts
    return () => window.removeEventListener('resize', setSize);

    // we set the account level access

  }, []);






let encryptedValue=''
let secret="202308112023081120230811"
  function getDecryptedOption() {
    const encryptedValue = localStorage.getItem('qba') || '0';
    const decryptedValue = CryptoJS.AES.decrypt(
      encryptedValue,
      `${secret}`
    ).toString(CryptoJS.enc.Utf8);
    return decryptedValue
  
    if (decryptedValue) {
      setDecryptValue(decryptedValue)
      return decryptedValue
    } else {
      return 0
    }
  }


const selectedCountry = languages[getCountry]

const {
  selectMenu,
  headermenuHome,
  headermenuLogout,
  headermenuIngrediensList,
  headermenuRecipeList,
  headermenuProductList,
  headermenuIngredients,
  headermenuIngredientCategories,
  headermenuRecipeCategories,
  headermenuProductCategories,
  headermenuMyRecipes,
  headermenuCreateReceipemanually,
  headermenuCreateRecipeWithAI,
  headermenuCreateProduct,
  headermenuProductStatistics,
  headermenuWorkWithProduction,
  headermenuTotalNumbers,
  headermenuRecipeSummary,
  headermenuIngredientSummary,
  headermenuPriceList,
  headermenuPageSettings,
  headermenuUserDataPolicy,
  headermenuRecipeIdeas,
  headermenuMyAccount,
  headermenuRecipeScroll
} = selectedCountry.headermenubar||{};







const menyHandler=(e)=>{
              
                // setMenuOpen(false)
   
    if(e==='1'){
        navigate('/WelcomeImage')}
    else if(e==='2'){
        navigate('/Logout');
    }else if(e==='3'){
        navigate('/ListIngred');
    }else if(e==='4'){
        navigate('/ListRecept');
    }else if(e==='5'){
        navigate('/Listprodukt');
    }else if(e==='6'){
        navigate('/ingredNew');
    }else if(e==='7'){
        navigate('/IngredCategoriesNew');
      }else if(e==='9'){
        navigate('/SearchRecepyNew2');
      } else if(e==='12'){
        navigate('/RecepycategoriesNew');
      } else if(e==='14'){
        navigate('/ProductcategoriesNew');
      }   else if(e==='23'){
        navigate('/RecipeIdeas');
      }else if(e==='25'){
        navigate('/recipescroll');
    }else if(e==='10'){
        navigate('/CreateManRecepyNew');
    } else if(e==='11'){
        navigate('/createnewrecepy');

    } else if(e==='13'){
        navigate('/CreateproductNew');

    } else if(e==='15'){
        navigate('/statisticsbyproductNew');
    } else if(e==='16'){
        navigate('/CreateproductionNew');
    } else if(e==='17'){
        navigate('/TotalProductionNew');
    } else if(e==='18'){
        navigate('/ProdArbSumNew');
    } else if(e==='19'){
        navigate('/IngrediensTotalerNew');
    } else if(e==='20'){
        navigate('/PriceListNew');
    } else if(e==='21'){
        navigate('/MySettings');
    } 
    else if(e==='22'){
      navigate('/userDataPolicy');
}else if(e==='24'){
  navigate('/MyAccount');

}

}


   return(

//         <div className="xxaheaderAccountContainer">
//             <button  className={`xxahamburgerButton ${menuOpen ? 'open' : ''}`}
//             onClick={toggleMenu}>
//                 &#9776; {/* Unicode character for hamburger icon */}
//             </button>
//                 <div className={`xxaslidingMenu ${menuOpen ? 'open' : ''}`}>
//                 {menuData.map((item, index) => (
//                      getDecryptValue>=item.statusId?(
//             <button
//             key={index}
//             className={buttonClicked !== item.id ? "xxaamenupersonal" : "xxaamenupersonal1"}
//             onClick={() => menyHandler(item.menyid)}
//         >
//               {item.benaemn}
//              </button>
//     ) : null
// ))}
             
     
//               </div>
//             <div className="xxasearchSpinnerContainer">
//               <div className="xxasearchSpinner">
//                   <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="0.75" width="60" visible={getSpinnerLoading}/>
//               </div>
//             </div>
//       </div>

  //  )

  //          {/* t
  // </div>

  //  )



 <>

  <div className="xxaselectTop">
    <div className="xxalogoWrapper">
    <div><a href="/" className="xxalogo">Bakesoft</a></div>
    <select className="xxaselectMenuSelect" disabled={getDecryptedOption()<1} 
                          name="recepy" id="recepy" onChange={(e)=>menyHandler(e.target.value)}>
                           <option defaultValue="0">&nbsp;&nbsp;{selectMenu}&nbsp;!</option>
                           {getDecryptedOption()>0?<option value="1">&nbsp;&nbsp;{headermenuHome}</option>:''}
                            <option value="2">&nbsp;&nbsp;{headermenuLogout}</option>
                            {getDecryptedOption()>0?<option value="3">&nbsp;&nbsp;{headermenuIngrediensList}</option>:''}
                            {getDecryptedOption()>0?<option value="4">&nbsp;&nbsp;{headermenuRecipeList}</option>:''}
                            {getDecryptedOption()>1?<option value="5">&nbsp;&nbsp;{headermenuProductList}</option>:''}
                            {getDecryptedOption()>0?<option value="6">&nbsp;&nbsp;{headermenuIngredients}</option>:''}
                            {getDecryptedOption()>0?<option value="7">&nbsp;&nbsp;{headermenuIngredientCategories}</option>:''}
                            {getDecryptedOption()>0?<option value="12">&nbsp;&nbsp;{headermenuRecipeCategories}</option>:''}
                            {getDecryptedOption()>1?<option value="14">&nbsp;&nbsp;{headermenuProductCategories}</option>:''}
                            {getDecryptedOption()>0?<option value="23">&nbsp;&nbsp;{headermenuRecipeIdeas}</option>:''}
                            {getDecryptedOption()>0?<option value="9">&nbsp;&nbsp;{headermenuMyRecipes}</option>:''}
                            {getDecryptedOption()>0?<option value="25">&nbsp;&nbsp;{headermenuRecipeScroll}</option>:''}
                            {getDecryptedOption()>0?<option value="10">&nbsp;&nbsp;{headermenuCreateReceipemanually}</option>:''}
                            {getDecryptedOption()>0?<option value="11">&nbsp;&nbsp;{headermenuCreateRecipeWithAI}</option>:''}
                            {getDecryptedOption()>1?<option value="13">&nbsp;&nbsp;{headermenuCreateProduct}</option>:''}
                            {getDecryptedOption()>1?<option value="15">&nbsp;&nbsp;{headermenuProductStatistics}</option>:''}
                            {getDecryptedOption()>1?<option value="16">&nbsp;&nbsp;{headermenuWorkWithProduction}</option>:''}
                            {getDecryptedOption()>1?<option value="17">&nbsp;&nbsp;{headermenuTotalNumbers}</option>:''}
                            {getDecryptedOption()>1?<option value="18">&nbsp;&nbsp;{headermenuRecipeSummary}</option>:''}
                            {getDecryptedOption()>1?<option value="19">&nbsp;&nbsp;{headermenuIngredientSummary}</option>:''}
                            {getDecryptedOption()>1?<option value="20">&nbsp;&nbsp;{headermenuPriceList}</option>:''}
                            {getDecryptedOption()>0?<option value="21">&nbsp;&nbsp;{headermenuPageSettings}</option>:''}
                            {getDecryptedOption()>0?<option value="22">&nbsp;&nbsp;{headermenuUserDataPolicy}</option>:''}
                            {getDecryptedOption()>0?<option value="24">&nbsp;&nbsp;{headermenuMyAccount}</option>:''}

                            
                         </select></div>
    


  <div className="xxaimageWrapper">
{imageLink.length>1?
  <img src={imageLink} alt="User Profile" className="xxauserPicture"/>
  : <img src={bs} alt="User Profile" className="xxauserPicture"/>}
</div>

</div>
</>


   )
   
}

export default HeaderLargeMobile;
