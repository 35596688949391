
export default function GetappUrl(){
    // return `http://192.168.1.146:3001`
//return `http://192.168.8.116:3001`
//return `http://MySecondApp-env.eba-ddbuijbf.eu-north-1.elasticbeanstalk.com`
      //return `http://192.168.126.1:3001`
//return `http://localhost:3001`
 //return `http://192.168.8.116:3001`
//return `https://61bf23ff-3001.euw.devtunnels.ms/`
//return `https://61bf23ff-3001.euw.devtunnels.ms/`
return `${process.env.REACT_APP_BACKEND_URL}`
//return `https://fxgux1eo1m.execute-api.eu-north-1.amazonaws.com/development`
//return `https:Backendv2-env.eba-z9nrqu4u.eu-north-1.elasticbeanstalk.com`

}