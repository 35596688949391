import MainStyles from "./main_styles";

function userDataPolicy(windowHeight,getCountry){
windowHeight=(windowHeight-85)/2
let val=''
let menuval=''
let menuval2=''
let getColorVal=''
let textareaHeight=''

 const {generalStyles,ipadStyles}=MainStyles(val,menuval,menuval2,getColorVal,textareaHeight,getCountry);

const datapolicyIpad10th={
    totalArea:{
    },
    totalPolicyArea:{
        display: 'grid',
        gridTemplateColumns: '1fr 2fr 1fr',
        gridGap: '5px',
        gridTemplateAreas: `
          "a b c"
          "d e f"
        `,
    backgroundColor:'transparent',
    marginTop:'5px',
    },
    policyAreaA:{
        gridArea: 'a',
        backgroundColor:'RGBA(255, 255, 255,0.5)',
        height:`${windowHeight}px`,
        borderBottomRightRadius:'90%',
    },
    policyAreaB:{
        gridArea: 'b',
        backgroundColor:'RGBA(255, 255, 255,0.5)',
        borderBottomRightRadius:'60%',
    },
    policyAreaC:{
        gridArea: 'c',
        backgroundColor:'RGBA(255, 255, 255,0.5)',
        borderBottomLeftRadius:'80%',
       
    },
    policyAreaD:{
        gridArea: 'd',
        backgroundColor:'RGBA(255, 255, 255,0.5)',
        height:`${windowHeight}px`,
       
    },
    policyAreaE:{
        gridArea: 'e',
        backgroundColor:'RGBA(255, 255, 255,0.5)',
        borderTopLeftRadius:'90%',
    },
    policyAreaF:{
        gridArea: 'f',
        backgroundColor:'RGBA(255, 255, 255,0.5)',
        
    },
    messageBox:{
    },
    logoHeader:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        backgroundColor:'transparent',
        marginTop:'2%',
        fontFamily:generalStyles.fontFamilyLobster,
        fontSize:'30px',

    },
    messageHeader:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        backgroundColor:'transparent',
        fontFamily:generalStyles.fontFamily,
        marginTop:'3%',
        fontSize:'20px',
    },
    message:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'flex-start',
        alignItems:'center',
        backgroundColor:'transparent',
        fontFamily:generalStyles.fontFamily,
        marginTop:'3%',
        fontSize:'10px',
        marginTop:'10px',
    },
    messageSWE:{
        marginTop:'10px',
        fontFamily:generalStyles.fontFamily,
        fontSize:'16px',
       
    },
    messageJAP:{
        marginTop:'10px',
        fontFamily:generalStyles.fontFamily,
        fontSize:'16px',
    },
    messageENG:{
        marginTop:'10px',
        fontFamily:generalStyles.fontFamily,
        fontSize:'16px',
    }







}

const datapolicyAndroid={

}

const datapolicylargeMobileAndroid={
    totalArea:{
    },
    totalPolicyArea:{
        display: 'grid',
        gridTemplateColumns: '1fr 2fr 1fr',
        gridGap: '5px',
        gridTemplateAreas: `
          "a b c"
          "d e f"
        `,
    backgroundColor:'transparent',
    marginTop:'5px',
    },
    policyAreaA:{
        gridArea: 'a',
        backgroundColor:'RGBA(255, 255, 255,0.5)',
        height:`${windowHeight}px`,
        borderBottomRightRadius:'90%',
    },
    policyAreaB:{
        gridArea: 'b',
        backgroundColor:'RGBA(255, 255, 255,0.5)',
        borderBottomRightRadius:'60%',
    },
    policyAreaC:{
        gridArea: 'c',
        backgroundColor:'RGBA(255, 255, 255,0.5)',
        borderBottomLeftRadius:'80%',
       
    },
    policyAreaD:{
        gridArea: 'd',
        backgroundColor:'RGBA(255, 255, 255,0.5)',
        height:`${windowHeight}px`,
       
    },
    policyAreaE:{
        gridArea: 'e',
        backgroundColor:'RGBA(255, 255, 255,0.5)',
        borderTopLeftRadius:'90%',
    },
    policyAreaF:{
        gridArea: 'f',
        backgroundColor:'RGBA(255, 255, 255,0.5)',
        
    },
    messageBox:{
    },
    logoHeader:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        backgroundColor:'transparent',
        marginTop:'2%',
        fontFamily:generalStyles.fontFamilyLobster,
        fontSize:'30px',

    },
    messageHeader:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        backgroundColor:'transparent',
        fontFamily:generalStyles.fontFamily,
        marginTop:'3%',
        fontSize:'20px',
    },
    message:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'flex-start',
        alignItems:'center',
        backgroundColor:'transparent',
        fontFamily:generalStyles.fontFamily,
        marginTop:'3%',
        fontSize:'10px',
        marginTop:'10px',
    },
    messageSWE:{
        marginTop:'10px',
        fontFamily:generalStyles.fontFamily,
        fontSize:'12px',
       
    },
    messageJAP:{
        marginTop:'10px',
        fontFamily:generalStyles.fontFamily,
        fontSize:'12px',
    },
    messageENG:{
        marginTop:'10px',
        fontFamily:generalStyles.fontFamily,
        fontSize:'12px',
    }


}

const datapolicyWindows={
   
    totalArea:{
    },
    totalPolicyArea:{
        display: 'grid',
        gridTemplateColumns: '1fr 2fr 1fr',
        gridGap: '5px',
        gridTemplateAreas: `
          "a b c"
          "d e f"
        `,
    backgroundColor:'transparent',
    marginTop:'5px',
    },
    policyAreaA:{
        gridArea: 'a',
        backgroundColor:'RGBA(255, 255, 255,0.5)',
        height:`${windowHeight}px`,
        borderBottomRightRadius:'90%',
    },
    policyAreaB:{
        gridArea: 'b',
        backgroundColor:'RGBA(255, 255, 255,0.5)',
        borderBottomRightRadius:'60%',
    },
    policyAreaC:{
        gridArea: 'c',
        backgroundColor:'RGBA(255, 255, 255,0.5)',
        borderBottomLeftRadius:'80%',
       
    },
    policyAreaD:{
        gridArea: 'd',
        backgroundColor:'RGBA(255, 255, 255,0.5)',
        height:`${windowHeight}px`,
       
    },
    policyAreaE:{
        gridArea: 'e',
        backgroundColor:'RGBA(255, 255, 255,0.5)',
        borderTopLeftRadius:'90%',
    },
    policyAreaF:{
        gridArea: 'f',
        backgroundColor:'RGBA(255, 255, 255,0.5)',
        
    },
    messageBox:{
    },
    logoHeader:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        backgroundColor:'transparent',
        marginTop:'5%',
        fontFamily:generalStyles.fontFamilyLobster,
        fontSize:'30px',

    },
    messageHeader:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        backgroundColor:'transparent',
        fontFamily:generalStyles.fontFamily,
        marginTop:'5%',
        fontSize:'20px',
    },
    message:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'flex-start',
        alignItems:'center',
        backgroundColor:'transparent',
        fontFamily:generalStyles.fontFamily,
        marginTop:'3%',
        fontSize:'10px',
        marginTop:'10px',
    },
    messageSWE:{
        marginTop:'30px',
        fontFamily:generalStyles.fontFamily,
        fontSize:'20px',
       
    },
    messageJAP:{
        marginTop:'30px',
        fontFamily:generalStyles.fontFamily,
        fontSize:'20px',
    },
    messageENG:{
        marginTop:'30px',
        fontFamily:generalStyles.fontFamily,
        fontSize:'20px',
    }


}






 return {datapolicyIpad10th,datapolicyAndroid,datapolicylargeMobileAndroid,datapolicyWindows}
}
export default userDataPolicy;

