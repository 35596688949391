import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePostData } from '../hooks/useGetData';

const Logout = () => {
  const navigate = useNavigate();

  let token = localStorage.getItem('auth_token');

  async function DeleteUserToken(){
    try{
      await usePostData('deleteUserToken',{token})
 
      }catch(error)
      {
      console.log(error)
  }
}


  useEffect(()=>{
    DeleteUserToken()
  },[])


  useEffect(() => {
    // Remove the token
    localStorage.removeItem('auth_token'); // or wherever the token is stored
    localStorage.removeItem('image_link');
    localStorage.removeItem('country');
    localStorage.removeItem('userCheck')
    localStorage.removeItem('qba')
    localStorage.removeItem('tester')
    //localStorage.removeItem('recipeIdeas');

    window.dispatchEvent(new Event('storage'));
    // Redirect to login page
    navigate('/login');
  }, [navigate]);

};

export default Logout;
